import React from 'react'
import { Image, TouchableOpacity, StyleSheet } from 'react-native'

import logoImage from '~/assets/images/logo_color.png'
import { navigate } from '~/utils/navigation'

const TopPageHeaderLeft: React.FC = () => {
  const navigateToHome = () => {
    navigate('topPage')
  }

  return (
    <TouchableOpacity testID="top-page-header-left" onPress={navigateToHome}>
      <Image source={logoImage} style={styles.logo} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  logo: {
    height: 40,
    marginLeft: 50,
    marginRight: 30,
    width: 120,
  },
})

export default TopPageHeaderLeft
