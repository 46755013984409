import { Picker as RNPicker } from '@react-native-picker/picker'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'

import PickerAndroid from '~/components/common/molecules/PickerAndroid'
import PickerIOS from '~/components/common/molecules/PickerIOS'
import Picker from '~/components/workarounds/Picker'
import Text from '~/components/workarounds/Text'
import color, { ColorTheme } from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { sizeStyle } from '~/constants/common/pickerSizes'

export type PickerOption = {
  label: string
  value: ItemValue
}

export type PickerProps = {
  data: PickerOption[]
  selectedValue?: ItemValue
  onValueChange: (value: ItemValue) => void
}

export type Props = {
  title?: string
  colorTheme?: ColorTheme
  size: 'small' | 'medium' | 'large' | 'full' | 'none'
  isWithoutBorder?: boolean
  isFlex?: boolean
} & PickerProps

export const pickerValue = (
  label: string,
  value: string | number
): PickerOption => {
  return {
    label: label,
    value: value,
  }
}

const CustomPicker: React.FC<Props> = (props: Props) => {
  const {
    data,
    onValueChange,
    selectedValue,
    title,
    colorTheme,
    isWithoutBorder,
    isFlex,
  } = props

  const colorStyle =
    colorTheme === ColorTheme.Primary
      ? {
          color: color.primary,
          borderColor: color.primary,
        }
      : colorTheme === ColorTheme.Light
      ? {
          backgroundColor: color.transparent,
          color: color.white,
          borderColor: color.white,
        }
      : {
          backgroundColor: color.white,
          borderColor: color.lightGray,
        }

  const borderStyle = isWithoutBorder
    ? { borderWidth: 0 }
    : { ...commonStyles.borderRadiusAllCorner }

  if (Platform.OS === 'ios') {
    return (
      <PickerIOS
        data={data}
        size={props.size}
        colorTheme={colorTheme}
        onValueChange={onValueChange}
        selectedValue={selectedValue}
      />
    )
  }

  if (Platform.OS === 'android') {
    return (
      <PickerAndroid
        data={data}
        size={props.size}
        colorTheme={colorTheme}
        onValueChange={onValueChange}
        selectedValue={selectedValue}
      />
    )
  }

  return (
    <View
      style={[styles.container, sizeStyle[props.size], isFlex && styles.flex]}
      testID="custom-picker"
    >
      {title && <Text style={styles.title}>{title}</Text>}
      <Picker
        testID="custom-picker-content"
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        style={StyleSheet.flatten([styles.picker, colorStyle, borderStyle])}
      >
        {data.map(({ label, value }) => (
          <RNPicker.Item
            key={value}
            label={label}
            value={value}
            color={color.textGray}
          />
        ))}
      </Picker>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flex: { flex: 1 },
  picker: {
    borderWidth: 1,
    color: color.textGray,
    flexGrow: 1,
    paddingVertical: 4,
    width: '100%',
    fontSize: FontSize.GENERAL,
  },
  title: {
    alignSelf: 'flex-start',
    color: color.darkTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default CustomPicker
