import { inRange } from 'lodash'
import React from 'react'
import { Platform, TextStyle } from 'react-native'

import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export type PhoneNumberInputChangeEventData = {
  value: string
  isValid: boolean
}

type Props = {
  value: string
  label?: string
  onChange: (phoneNumberEvent: PhoneNumberInputChangeEventData) => void
}

const PhoneNumberInput: React.FC<Props> = ({
  value,
  label,
  onChange,
}: Props) => {
  const changePhoneValue = (value: string): void => {
    const isValid =
      inRange(value.length, 10, 20) &&
      inRange(value.replace(/[^0-9]/g, '').length, 10, 15) &&
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(value) // eslint-disable-line no-useless-escape
    onChange && onChange({ value, isValid })
  }

  return (
    <Input
      label={label}
      value={value}
      autoCapitalize="none"
      keyboardType="phone-pad"
      placeholder="0X-XXXX-XXXX"
      inputStyle={styles.input}
      testID="phone-number-input"
      onChangeText={changePhoneValue}
      textContentType="telephoneNumber"
      labelStyle={styles.label as TextStyle}
      inputContainerStyle={styles.inputContainer}
    />
  )
}

const styles = {
  input: {
    fontSize: FontSize.GENERAL,
    paddingLeft: 4,
    paddingTop: 3,
    ...Platform.select({
      web: { outline: 'none' },
    }),
  },
  inputContainer: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    marginTop: 10,
  },
  label: {
    color: color.textGray,
    fontWeight: 'normal',
  },
}

export default PhoneNumberInput
