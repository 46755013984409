import React, { useCallback, useEffect, useState } from 'react'

export type RetryTarget = (
  retried: number,
  retry: () => void
) => React.ReactElement

type Props = {
  base?: number
  maxWait?: number
  retries?: number
  factor?: number
  children: RetryTarget
}

const exponentialBackoff = (
  base: number,
  factor: number,
  maxWait: number,
  attempts: number
): number => {
  const sleep = Math.min(maxWait, base * factor ** attempts)
  return Math.min(maxWait, (sleep - base) * Math.random() + base) // Decorrelated Jitter
}

const Retry: React.FC<Props> = ({
  base = 1000,
  maxWait = 300000,
  retries = 10,
  factor = 2,
  children,
}: Props) => {
  const [retried, setRetried] = useState<number>(0)
  const [child, setChild] = useState<React.ReactElement | null>(null)

  const retryHandler = useCallback(() => {
    if (retries <= retried) {
      return
    }
    const sleepTime = exponentialBackoff(base, factor, maxWait, retried + 1)
    setTimeout(() => {
      setRetried((prev) => prev + 1)
    }, sleepTime)
  }, [setRetried, retries, base, factor, maxWait, retried])

  useEffect(() => {
    setChild(children(retried, retryHandler))
  }, [setChild, children, retried, retryHandler])

  return child
}
export default Retry
