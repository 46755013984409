import { TFunction } from 'i18next'

import { ControlMenuData } from '~/components/common/molecules/ControlMenu'
import { AnswerMenuType } from '~/constants/forum/answer'

export const getAnswerMenuData = (t: TFunction): ControlMenuData[] => [
  {
    id: AnswerMenuType.EDIT,
    title: t('edit'),
  },
  {
    id: AnswerMenuType.DELETE,
    title: t('delete'),
  },
]
