import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const ApplicantAction: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="applicant-action">
      <Button
        titleStyle={styles.messageButtonTitle}
        title={t('company.applicant.sentMessage')}
        buttonStyle={styles.messageButtonContainer}
      />
      <View style={styles.separator} />
      <Button
        title={t('close')}
        buttonStyle={styles.closeButton}
        titleStyle={styles.closeButtonTitle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    minWidth: 67,
    height: 25,
    backgroundColor: color.danger,
  },
  closeButtonTitle: {
    color: color.white,
    fontSize: FontSize.SUB,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  messageButtonContainer: {
    minWidth: 67,
    height: 25,
    borderColor: color.primary,
    borderWidth: 1,
    backgroundColor: color.transparent,
  },
  messageButtonTitle: {
    color: color.primary,
    fontSize: FontSize.SUB,
  },
  separator: {
    marginRight: 10,
  },
})

export default ApplicantAction
