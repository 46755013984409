import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SocialLikeCount from '~/components/common/features/socials/SocialLikeCount'
import Question from '~/interfaces/Question'
import { RootState } from '~/rootReducer'

type Props = {
  question: Question
}

const QuestionLikeCount: React.FC<Props> = ({ question }: Props) => {
  const [likesCount, setLikesCount] = useState(question.likesCount)

  const eventData = useSelector((state: RootState) =>
    state.questions?.eventData?.id === question.id
      ? state.questions?.eventData
      : undefined
  )

  useEffect(() => {
    eventData && setLikesCount(eventData?.likesCount!)
  }, [eventData])

  return likesCount > 0 ? (
    <SocialLikeCount
      likeCount={likesCount}
      likeParams={{ questionId: question.id }}
    />
  ) : (
    <></>
  )
}

export default QuestionLikeCount
