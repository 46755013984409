import camelcaseKeys from 'camelcase-keys'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import { UserQuestionType, UserQuestionSortKey } from '~/api/userQuestions'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import { isReadyNavigationRef, navigationRef } from '~/utils/navigation'

export type ForumDataTypeParams = {
  forumType?: string
  sortBy?: string
}

const UsersForumFilterPopup: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()

  const forumDataTypeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as ForumDataTypeParams

  const [selectedSortBy, setSelectedSortBy] = useState<
    FilterPopupData | undefined
  >(undefined)
  const [selectedFilter, setSelectedFilter] = useState<
    FilterPopupData | undefined
  >(undefined)

  const userQuestionFilterPopupData: FilterPopupData[] = [
    { id: UserQuestionType.QUESTION, name: t('forum.questions') },
    { id: UserQuestionType.ANSWER, name: t('forum.answers') },
  ]

  const userQuestionSortKeyPopupData: FilterPopupData[] = [
    { id: UserQuestionSortKey.BY_CREATED_AT, name: t('mostRecent') },
    { id: UserQuestionSortKey.BY_LIKES_COUNT, name: t('mostLiked') },
  ]

  const getSortDataByParams = (): FilterPopupData => {
    return !forumDataTypeParams.sortBy ||
      forumDataTypeParams.sortBy === UserQuestionSortKey.BY_CREATED_AT
      ? userQuestionSortKeyPopupData[0]
      : userQuestionSortKeyPopupData[1]
  }
  const getFilterDataByParams = (): FilterPopupData => {
    return !forumDataTypeParams.forumType ||
      forumDataTypeParams.forumType === UserQuestionType.QUESTION
      ? userQuestionFilterPopupData[0]
      : userQuestionFilterPopupData[1]
  }

  const changeSelectedSortBy = (item: FilterPopupData): void => {
    setSelectedSortBy(item)
    setQueryParams({ sortBy: item.id as string })
  }
  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    setQueryParams({ forumType: item.id as UserQuestionType })
  }

  useEffect(() => {
    if (isReadyNavigationRef.current) {
      //Set default forum type
      changeSelectedFilter(getFilterDataByParams())
      changeSelectedSortBy(getSortDataByParams())
    }
  }, [
    forumDataTypeParams.forumType,
    isReadyNavigationRef.current,
    forumDataTypeParams.sortBy,
  ])

  return (
    <View testID="users-forum-filter-popup" style={styles.container}>
      <FilterPopupMenu
        selectedItem={selectedSortBy}
        data={userQuestionSortKeyPopupData}
        onSelect={changeSelectedSortBy}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {selectedSortBy?.name}
          </Text>
        }
      />
      <View style={styles.spaceBetween} />
      <FilterPopupMenu
        selectedItem={selectedFilter}
        data={userQuestionFilterPopupData}
        onSelect={changeSelectedFilter}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {selectedFilter?.name}
          </Text>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  selectedFilter: {
    color: color.pressableText,
  },
  spaceBetween: {
    paddingHorizontal: 20,
  },
})

export default UsersForumFilterPopup
