import { BaseAPI } from '~/api/baseAPI'

export type UserQuestionQueryParams = {
  q?: string
  page?: number
  userId?: number
  categories?: string[]
  sortBy?: string
}

export enum UserQuestionSortKey {
  BY_CREATED_AT = 'created_at',
  BY_LIKES_COUNT = 'likes_count',
}

export enum UserQuestionType {
  QUESTION = 'question',
  ANSWER = 'answer',
}

export class UserQuestionsAPI extends BaseAPI {
  configPath(userId: number): UserQuestionsAPI {
    this.path = `/users/${userId}/user_questions`
    return this
  }
}
