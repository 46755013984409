import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { range } from 'lodash'
import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import CustomPicker, {
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const dateObj = new Date()
export const minYear = 1970
export const currentYear = dateObj.getFullYear()

export const getYears = (maxYear = currentYear, fromYear: number) => {
  return range(fromYear, maxYear + 2).map((_, index) => {
    const value = maxYear + 1 - index
    return index === 0
      ? pickerValue('', index)
      : pickerValue(value.toString(), value)
  })
}

type Props = {
  onChange: (value: number) => void
  selectedValue?: number
  title?: string
  maxYear?: number
  fromYear?: number
}

const YearPicker: React.FC<Props> = ({
  selectedValue,
  onChange,
  title,
  maxYear,
  fromYear = minYear,
}: Props) => {
  const [year, setYear] = useState(selectedValue)

  const handleChangeYear = (value: ItemValue): void => {
    setYear(value as number)
    onChange(value as number)
  }

  return (
    <View testID="year-picker" style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View testID="picker-container">
        <CustomPicker
          data={getYears(maxYear, fromYear)}
          size="full"
          selectedValue={year}
          onValueChange={handleChangeYear}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default YearPicker
