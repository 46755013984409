import { HTTPClient } from '~/httpClient'
import Media from '~/interfaces/Media'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'
import { isVideo } from '~/interfaces/Video'

export interface PostsResponse {
  posts: Post[]
  replies: Post[]
}

export type PostRequest = {
  id?: number
  replyToId?: number
  title?: string
  message?: string
  url?: string
  category?: string
  userId?: number
  media?: Media[]
  imageIds?: number[]
  videoIds?: number[]
  communityChannelId?: number
  communityEventId?: number
}

export type PostQueryParams = {
  category?: string
  page?: number
  perPage?: number
  userId?: number
  feed?: string
}

export type PostLikeResponse = {
  post: Post
  user: User
}

export class PostsAPI {
  client: HTTPClient
  private basePath = '/v2/posts'

  constructor(client: HTTPClient) {
    this.client = client
  }

  getBasePath(): string {
    return this.basePath
  }

  setBasePath(path: string): void {
    this.basePath = path
  }

  async get(id: number): Promise<Post> {
    const response = await this.client.get<Post>(`${this.getBasePath()}/${id}`)
    return this.parsePost(response)
  }

  async fetch({
    category = 'all',
    page,
    perPage,
    userId,
    feed = 'main',
  }: PostQueryParams = {}): Promise<Post[]> {
    let path = this.getBasePath()
    const params = []
    params.push(`category=${category}`, `feed=${feed}`) //Category always available
    if (page && page > 0) params.push(`page=${page}`)
    if (perPage && perPage > 0) params.push(`per_page=${perPage}`)
    if (userId) params.push(`user_id=${userId}`)
    path += '?' + params.join('&')
    const response = await this.client.get<PostsResponse>(path)
    return this.parseList(response)
  }

  async create(request: PostRequest): Promise<Post> {
    const post = await this.client.post<Post>(
      this.getBasePath(),
      this.normalizeRequest(request)
    )
    post.comments = []
    return post
  }

  private parsePost(response: Post): Post {
    const subReplies = response.replies.flatMap((i) => i.replies)
    return this.parseList({
      posts: [response],
      replies: [...response.replies, ...subReplies],
    })[0]
  }

  protected parseList(response: PostsResponse): Post[] {
    const postMap = new Map<number, Post>(
      [...response.posts, ...response.replies].map((i) => [
        i.id,
        Object.assign(i, {
          comments: [],
        }),
      ])
    )
    response.replies.forEach((i) => {
      postMap.get(i.replyToId) && postMap.get(i.replyToId)!.comments.push(i)
    })

    response.posts
      .flatMap((i) => i.comments || []) // comments
      .flatMap((i) => i.comments || []) // replies
      .forEach((i) => (i.comments = [])) // ignore deeper replies
    return this.updateCommentLevel(response.posts)
  }

  // level: 1, and level: 2 are corresponding with comments and replies. We don't use deeper replies
  // so it just stops at level = 2
  private updateCommentLevel(posts: Post[]): Post[] {
    return posts.map((post) => ({
      ...post,
      comments: post.comments.map((commentLevel1) => ({
        ...commentLevel1,
        level: 1,
        comments: commentLevel1.comments.map((commentLevel2) => ({
          ...commentLevel2,
          level: 2,
        })),
      })),
    }))
  }

  private normalizeRequest(request: PostRequest): PostRequest {
    if (request.media == null) {
      return request
    }
    const cloned = { ...request, media: undefined }
    const imageIds: number[] = []
    const videoIds: number[] = []
    request.media.forEach((item) => {
      if (isVideo(item)) {
        videoIds.push(item.id)
      } else {
        imageIds.push(item.id)
      }
    })
    cloned.imageIds = [...(request.imageIds || []), ...imageIds]
    cloned.videoIds = [...(request.videoIds || []), ...videoIds]
    return cloned
  }

  async deletePost(postId: number): Promise<void> {
    await this.client.delete<void>(`${this.getBasePath()}/${postId}`, {})
  }

  async update(postData: PostRequest): Promise<Post> {
    return await this.client.patch<Post>(
      `${this.getBasePath()}/${postData.id}`,
      this.normalizeRequest(postData)
    )
  }
}
