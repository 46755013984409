import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  onPressAccept?: () => void
  onPressReject?: () => void
}

const CommunityInvitationButtonGroup: React.FC<Props> = ({
  onPressAccept,
  onPressReject,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="horizontal-container" style={styles.container}>
      <TouchableOpacity
        style={StyleSheet.flatten([styles.button, styles.buttonReject])}
        onPress={onPressReject}
        testID="reject-invitation"
      >
        <Text style={styles.rejectText}>
          {t('community.declineInvitation')}
        </Text>
      </TouchableOpacity>
      <Button
        onPress={onPressAccept}
        testID="accept-invitation"
        title={t('community.acceptInvitation')}
        titleStyle={StyleSheet.flatten([styles.acceptTitle])}
        buttonStyle={StyleSheet.flatten([styles.button, styles.whiteBorder])}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  acceptTitle: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
  button: {
    marginBottom: 6,
    marginHorizontal: 5,
    maxHeight: 32,
  },
  buttonReject: {
    marginHorizontal: 10,
  },
  container: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  rejectText: {
    color: color.bodyText,
    borderBottomWidth: 1,
    borderBottomColor: color.borderSeparator,
    fontSize: FontSize.GENERAL,
  },
  whiteBorder: {
    borderColor: color.white,
    borderWidth: 1,
  },
})
export default CommunityInvitationButtonGroup
