import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import QuestionTemplate from '~/components/forum/templates/QuestionTemplate'

const QuestionShow: React.FC = () => {
  return (
    <DefaultLayout>
      <QuestionTemplate />
    </DefaultLayout>
  )
}

export default QuestionShow
