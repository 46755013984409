import { MediaTypeOptions } from 'expo-image-picker'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import api from '~/api'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import useCustomToast from '~/hooks/useCustomToast'
import ChatThread from '~/interfaces/ChatThread'
import {
  MessageControlMenuType,
  MessageItemControlMenuEvent,
} from '~/interfaces/MessageItemControlMenu'
import { selectMedia, upload } from '~/utils/common/media'

type Props = {
  chatThread: ChatThread
  onSelect?: (event: MessageItemControlMenuEvent) => void
}

const MessageItemControlMenu: React.FC<Props> = ({
  chatThread,
  onSelect,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()

  const isArchived = chatThread?.isArchived

  const markThread = async ({
    isUnread,
  }: {
    isUnread?: boolean
  }): Promise<void> => {
    if (!chatThread.id) {
      return
    }
    try {
      const response = isUnread
        ? await api.chatThreads.markAsUnread(chatThread.id)
        : await api.chatThreads.markAsRead(chatThread.id)
      onSelect?.({
        type: isUnread
          ? MessageControlMenuType.MARK_AS_READ
          : MessageControlMenuType.MARK_AS_UNREAD,
        data: response,
      })
    } catch (error) {
      toast.showError(error)
    }
  }

  const updateThreadImage = useCallback(async () => {
    const uris = await selectMedia(MediaTypeOptions.Images)
    if (uris === null || !chatThread?.id) {
      return
    }
    try {
      const media = await upload(uris, 'chat_thread')
      const response = (await api.chatThreads.update(chatThread.id, {
        chatThread: {
          imageId: media.id,
        },
      })) as ChatThread
      onSelect?.({ type: MessageControlMenuType.CHANGE_IMAGE, data: response })
    } catch (e) {
      toast.showError(e)
    }
  }, [chatThread?.id, onSelect])

  let messageControlMenuData = [
    chatThread.unreadCount && chatThread.unreadCount > 0
      ? {
          id: MessageControlMenuType.MARK_AS_READ,
          title: t('message.menu.markAsRead'),
        }
      : {
          id: MessageControlMenuType.MARK_AS_UNREAD,
          title: t('message.menu.markAsUnread'),
        },
  ]

  !isArchived &&
    messageControlMenuData.push({
      id: MessageControlMenuType.ARCHIVE,
      title: t('message.menu.archiveThread'),
    })

  // Delete thread option available for admin/owner only
  // if (chatThread.isAdmin || chatThread.users?.[0]?.isCurrentUser) {
  messageControlMenuData.push({
    id: MessageControlMenuType.DELETE,
    title: t('message.menu.deleteThread'),
  })
  // }

  // Change chat name and chat photo only available with group of > 2 members
  if (chatThread.isAdmin && chatThread?.users && chatThread.users.length > 2) {
    messageControlMenuData = [
      ...[
        {
          id: MessageControlMenuType.RENAME,
          title: t('message.menu.changeChatName'),
        },
        {
          id: MessageControlMenuType.CHANGE_IMAGE,
          title: t('message.menu.editChatChangePhoto'),
        },
      ],
      ...messageControlMenuData,
    ]
  }

  const onSelectMessageMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case MessageControlMenuType.MARK_AS_READ:
        markThread({ isUnread: false })
        break
      case MessageControlMenuType.MARK_AS_UNREAD:
        markThread({ isUnread: true })
        break
      case MessageControlMenuType.RENAME:
        onSelect?.({ type: MessageControlMenuType.RENAME })
        break
      case MessageControlMenuType.CHANGE_IMAGE:
        updateThreadImage()
        break
      case MessageControlMenuType.DELETE:
        onSelect?.({ type: MessageControlMenuType.DELETE })
        break
      case MessageControlMenuType.ARCHIVE:
        onSelect?.({ type: MessageControlMenuType.ARCHIVE })
        break
      default:
    }
  }

  return (
    <View testID="thread-message-action-menu">
      <ControlMenu
        data={messageControlMenuData}
        onSelect={onSelectMessageMenu}
        preferredPlacement="bottom"
        hasAnchor={false}
      />
    </View>
  )
}

export default MessageItemControlMenu
