import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import DatePicker from '~/components/common/molecules/DatePicker'
import TimePicker, {
  TimeFormat,
} from '~/components/common/molecules/TimePicker'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  minDate?: string
  maxDate?: string
  dateTitle?: string
  timeTitle?: string
  selectedDateTime?: string
  onChange?: (value: string) => void
}

export const mergedDateTime = (date: string, time: string): string => {
  const newDate = new Date(date)
  const dateFromTime = moment(time, TimeFormat, false).toDate()
  newDate.setHours(dateFromTime.getHours())
  newDate.setMinutes(dateFromTime.getMinutes())
  return newDate.toISOString()
}

const DateTime: React.FC<Props> = ({
  minDate,
  maxDate,
  onChange,
  dateTitle,
  timeTitle,
  selectedDateTime,
}: Props) => {
  const [date, setDate] = useState(selectedDateTime)
  const [time, setTime] = useState(moment(selectedDateTime).format(TimeFormat))

  useEffect(() => {
    date && time && onChange && onChange(mergedDateTime(date!, time))
  }, [date, time])

  return (
    <View testID="date-time" style={styles.container}>
      <DatePicker
        title={dateTitle}
        onChange={setDate}
        selectedDate={date}
        size={FontSize.REMARKABLE}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
      />
      <View style={styles.separator} />
      <TimePicker
        minuteSteps={15}
        title={timeTitle}
        onChange={setTime}
        selectedTime={time}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    padding: 10,
    borderColor: color.gray,
    ...commonStyles.borderRadiusAllCorner,
  },
  separator: {
    marginTop: 4,
  },
})

export default DateTime
