import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import CompanyMemberList from '~/components/career/features/company/CompanyMemberList'
import HeaderCompany from '~/components/career/features/company/HeaderCompany'
import CompanySubscription from '~/components/career/organisms/company/CompanySubscription'
import CardContainer from '~/components/common/atoms/CardContainer'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { currentCompanySelector } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { isCompanyAdmin } from '~/utils/career/company'

const CompanyPanelTemplate: React.FC = () => {
  const { isPC } = useDevice()
  const company = useSelector(currentCompanySelector)
  const currentUser = useSelector(currentUserSelector)

  const isAdmin = useMemo(() => {
    return isNil(currentUser) || isNil(company)
      ? false
      : isCompanyAdmin(currentUser, company)
  }, [currentUser?.id, company?.id])

  return (
    <InfiniteScrollContainer>
      <View
        testID="company-panel-template"
        style={isPC ? styles.container : undefined}
      >
        <HeaderCompany hasSection={false} isAdmin={isAdmin} />
        <View style={styles.separator} />
        <CardContainer>
          {company && currentUser && (
            <CompanySubscription currentUser={currentUser} isAdmin={isAdmin} />
          )}
        </CardContainer>
        <View style={styles.separator} />
        <CardContainer style={styles.body}>
          <CompanyMemberList />
        </CardContainer>
      </View>
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: color.white,
    padding: 20,
  },
  container: {
    alignSelf: 'center',
    width: 814,
  },
  separator: {
    marginBottom: 15,
  },
})

export default CompanyPanelTemplate
