import get from 'lodash/get'
import React, { useState } from 'react'

import Answer from '~/interfaces/Answer'
import Media from '~/interfaces/Media'
import Post from '~/interfaces/Post'
import Question from '~/interfaces/Question'

type SelectedEntityProps = Post | Answer | Question

type MediaViewerContextProps = {
  entity: SelectedEntityProps
  setEntity: React.Dispatch<
    React.SetStateAction<SelectedEntityProps | undefined>
  >
  selectedMedia: Media
  setSelectedMedia: React.Dispatch<React.SetStateAction<Media | undefined>>
  media: Media[]
  setMedia: React.Dispatch<React.SetStateAction<Media[]>>
  dismissDialog: () => void
}

const MediaViewerContext = React.createContext<
  Partial<MediaViewerContextProps>
>({})

type Props = {
  children?: React.ReactNode
}

const MediaViewerContextProvider: React.FC<Props> = ({ children }) => {
  const [entity, setEntity] = useState<SelectedEntityProps>()
  const [selectedMedia, setSelectedMedia] = useState<Media>()

  const media: Media[] = [
    ...get(entity, 'images', []),
    ...get(entity, 'videos', []),
  ]

  const dismissDialog = () => {
    setEntity?.(undefined)
    setSelectedMedia?.(undefined)
  }

  return (
    <MediaViewerContext.Provider
      value={{
        entity,
        setEntity,
        selectedMedia,
        setSelectedMedia,
        media,
        dismissDialog,
      }}
    >
      {children}
    </MediaViewerContext.Provider>
  )
}

const useMediaViewerContext = () => React.useContext(MediaViewerContext)

export { useMediaViewerContext, MediaViewerContextProvider }
