import React from 'react'
import {
  Bubble,
  BubbleProps,
  MessageText,
  MessageTextProps,
} from 'react-native-gifted-chat'

import color from '~/constants/common/color'
import Message from '~/interfaces/Message'

export const renderBubble = (props: BubbleProps<Message>): React.ReactNode => {
  const renderMessageText = (props: MessageTextProps<Message>) => {
    return (
      <MessageText
        {...props}
        linkStyle={{
          left: {
            color: color.pressableText,
          },
          right: {
            color: color.pressableText,
          },
        }}
      ></MessageText>
    )
  }
  return (
    <Bubble<Message>
      {...props}
      renderMessageText={renderMessageText}
      textStyle={{
        right: {
          color: color.textGray,
          margin: 0,
        },
        left: {
          color: color.textGray,
          margin: 0,
        },
      }}
      tickStyle={{
        color: color.textGray,
      }}
      wrapperStyle={{
        right: {
          padding: 10,
          backgroundColor: color.primarySecondaryLight,
        },
        left: {
          padding: 10,
          backgroundColor: color.secondLightGray,
        },
      }}
    />
  )
}
