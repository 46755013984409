import camelcaseKeys from 'camelcase-keys'
import { eq, includes } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { QuizResultsQueryParams } from '~/api/quizResults'
import quizCategories from '~/assets/data/quizCategories.json'
import {
  PickerOption,
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import MultipleSelectDialog from '~/components/common/organisms/MultipleSelectDialog'
import ButtonGroup from '~/components/workarounds/ButtonGroup'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import SelectListItem from '~/interfaces/SelectListItem'
import i18n from '~/plugins/i18n'
import { getCategoriesFromRoute, navigationRef } from '~/utils/navigation'

const localCategories: SelectListItem[] = quizCategories.map((item) => ({
  id: item.id,
  name: i18n.language === 'ja' ? item.name : item.enName,
}))

export const getQuizCategoriesData = (translate: Function): PickerOption[] => {
  const data = localCategories.map((item) => pickerValue(item.name, item.id))
  data.unshift(pickerValue(translate('all'), ''))
  return data
}

enum QuizResultTabIndex {
  MOST_RECENT = 0,
  CORRECT = 1,
  INCORRECT = 2,
}

const QuizzesHeaderMobile: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()

  const routeParams = navigationRef.current?.getCurrentRoute()?.params
  const quizResultRouteParams: QuizResultsQueryParams = camelcaseKeys(
    routeParams || {}
  )

  const categories = getCategoriesFromRoute(quizResultRouteParams.categories)

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    QuizResultTabIndex.MOST_RECENT
  )
  const [selectedCategories, setSelectedCategories] = useState<
    SelectListItem[]
  >([])

  const updateSelectedCategories = (categories: SelectListItem[]): void => {
    setSelectedCategories(categories)
    setQueryParams({ categories: categories.map((item) => item.id as string) })
  }

  const quizTabButtons = [t('recent'), t('quiz.correct'), t('quiz.incorrect')]

  const changeQuizType = (index: number): void => {
    //TODO implement with quiz tab type object
    let isCorrect = undefined
    switch (index) {
      case QuizResultTabIndex.MOST_RECENT:
        isCorrect = undefined
        break
      case QuizResultTabIndex.CORRECT:
        isCorrect = true
        break
      case QuizResultTabIndex.INCORRECT:
        isCorrect = false
        break
    }
    setQueryParams({ isCorrect })
    setSelectedTabIndex(index)
  }

  const selectTabByCorrectStatus = (): void => {
    //isCorrect value from route values doesn't work as boolean thought typescript is correct type
    if (
      eq(quizResultRouteParams.isCorrect, true) ||
      eq(quizResultRouteParams.isCorrect, 'true')
    ) {
      setSelectedTabIndex(QuizResultTabIndex.CORRECT)
    } else if (
      eq(quizResultRouteParams.isCorrect, false) ||
      eq(quizResultRouteParams.isCorrect, 'false')
    ) {
      setSelectedTabIndex(QuizResultTabIndex.INCORRECT)
    }
  }

  useEffect(() => {
    quizResultRouteParams.categories &&
      setSelectedCategories(
        localCategories.filter((category) => includes(categories, category.id))
      )
  }, [quizResultRouteParams.categories])

  useEffect(() => {
    quizResultRouteParams.isCorrect !== undefined && selectTabByCorrectStatus()
  }, [quizResultRouteParams.isCorrect])

  return (
    <>
      <View style={styles.categoryFilter} testID="select-dialog-container">
        <MultipleSelectDialog
          isPC={false}
          title={t('categories')}
          data={localCategories}
          selectedItems={selectedCategories}
          onChange={updateSelectedCategories}
        />
      </View>
      <View style={styles.tabContainer} testID="quiz-tab-container">
        <ButtonGroup
          buttons={quizTabButtons}
          onPress={changeQuizType}
          selectedIndex={selectedTabIndex}
          containerStyle={styles.noneBorder}
          innerBorderStyle={innerBorderStyle}
          selectedButtonStyle={styles.selectedTab}
          buttonContainerStyle={styles.noneBorder}
          selectedTextStyle={StyleSheet.flatten([
            styles.tabText,
            { fontWeight: 'bold' },
          ])}
          textStyle={styles.tabText}
        />
      </View>
    </>
  )
}

const innerBorderStyle = { width: 0 }

const styles = StyleSheet.create({
  categoryFilter: {
    backgroundColor: color.white,
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 14,
    paddingVertical: 8,
  },
  noneBorder: {
    borderColor: color.transparent,
    borderWidth: 0,
    marginHorizontal: 0,
    marginVertical: 0,
    padding: 0,
  },
  selectedTab: {
    backgroundColor: color.white,
    borderBottomWidth: 1,
    borderColor: color.primary,
  },
  tabContainer: {
    width: '100%',
  },
  tabText: {
    color: color.primary,
  },
})

export default QuizzesHeaderMobile
