import { useFocusEffect, useNavigationState } from '@react-navigation/native'
import { first, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ListRenderItem,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { API } from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import NotificationsCheckAllButton from '~/components/common/features/notification/NotificationsCheckAllButton'
import EmptyView from '~/components/common/molecules/EmptyView'
import SkeletonListItem from '~/components/common/molecules/SkeletonListItem'
import NotificationItem from '~/components/common/molecules/notification/NotificationItem'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import NotificationsHeader from '~/components/common/organisms/notifications/NotificationsHeader'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Notification from '~/interfaces/Notification'
import { markNotificationAsRead } from '~/slices/common/notifications'
import { getServiceNotification } from '~/utils/common/notification'
import { getServiceByRouteName } from '~/utils/common/service'
import { navigateWithNotification } from '~/utils/navigation'

const keyExtractor = (item: Notification): string => item.id.toString()

const NotificationList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1) //Default page = 1
  const [notifications, setNotifications] = useState<Notification[]>([])
  const shouldShowSkeleton = isLoading && isEmpty(notifications)
  const toast = useCustomToast()
  const routes = useNavigationState((state) => state.routes)

  const fetchNotifications = useAPI(
    async (api: API, page: number): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.notifications.index<
          Notification[],
          BaseQueryParams
        >({ page })
        let newNotifictions: Notification[] = []
        if (page > currentPage) {
          if (!isEmpty(response)) {
            newNotifictions = [...notifications, ...response]
            setCurrentPage(page)
          }
        } else {
          newNotifictions = response
        }
        const service = getServiceByRouteName(first(routes)?.name ?? '')
        setNotifications(getServiceNotification(newNotifictions, service))
      } catch (err) {
        toast.showError(err)
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [setIsLoading, setCurrentPage, currentPage]
  )

  const fetchMoreNotification = (): void => {
    !isLoading && fetchNotifications(currentPage + 1)
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchNotifications(currentPage)
    }, [fetchNotifications])
  )

  const checkNotification = (item: Notification): void => {
    navigateWithNotification(item)
    !item.wasAlreadyRead && dispatch(markNotificationAsRead(item.id))
  }

  const renderItem: ListRenderItem<Notification> = ({
    item,
  }: {
    item: Notification
  }) => (
    <TouchableOpacity
      testID="notification-item-container"
      onPress={(): void => checkNotification(item)}
    >
      <NotificationItem notification={item} isPC={isPC} />
    </TouchableOpacity>
  )

  const updateAllNotificationAsRead = (): void => {
    setNotifications((notifications) =>
      notifications.map((item) => ({ ...item, wasAlreadyRead: true }))
    )
  }

  const header = (
    <NotificationsHeader
      checkAllButton={
        <NotificationsCheckAllButton onCheckAll={updateAllNotificationAsRead} />
      }
    />
  )

  return shouldShowSkeleton ? (
    <SkeletonList
      count={6}
      header={header}
      numColumns={1}
      itemComponent={
        <View style={styles.notificationLoading}>
          <SkeletonListItem />
        </View>
      }
    />
  ) : (
    <InfiniteScrollFlatList
      data={notifications}
      scrollEnabled={!isPC}
      renderItem={renderItem}
      testID="notification-list"
      keyExtractor={keyExtractor}
      onEndReachedThreshold={0.01}
      ListHeaderComponent={header}
      onReachBottom={fetchMoreNotification}
      style={[styles.notificationList, isPC ? styles.fullHeight : undefined]}
      ListEmptyComponent={
        isLoading ? undefined : (
          <EmptyView
            isPC={isPC}
            title={t('notification.emptyNotification')}
            imageSource={require('~/assets/images/prompts/nopostsyet.png')}
          />
        )
      }
      ItemSeparatorComponent={({ leadingItem }): React.ReactElement => (
        <View style={!leadingItem?.wasAlreadyRead && styles.wrapSeparator}>
          <View style={styles.separator} />
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  fullHeight: {
    height: '100%',
  },
  notificationList: {
    backgroundColor: color.white,
  },
  notificationLoading: {
    width: '100%',
  },
  separator: {
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
    marginHorizontal: 16,
  },
  wrapSeparator: {
    backgroundColor: color.primaryLight,
  },
})

export default NotificationList
