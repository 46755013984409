import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Avatar } from 'react-native-elements'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import color from '~/constants/common/color'

type Props = {
  size: ProfileAvatarSize
}

const SkeletonAvatar: React.FC<Props> = ({ size }: Props) => (
  <View testID="skeleton-avatar">
    <Avatar rounded size={size} avatarStyle={styles.avatar} />
  </View>
)

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: color.gray,
  },
})

export default SkeletonAvatar
