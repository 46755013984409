import { BaseAPI } from '~/api/baseAPI'

export class SubscriptionPlansAPI extends BaseAPI {
  configPath(): SubscriptionPlansAPI {
    this.path = `/subscription_plans/company`
    return this
  }

  async create<T, R>(data: T): Promise<R> {
    this.path = `/subscriptions/create`
    return await this.client.post<R>(this.path, data)
  }

  async switchFree<T, R>(data: T): Promise<R> {
    this.path = `/subscriptions/switch_free`
    return await this.client.put<R>(this.path, data)
  }
}
