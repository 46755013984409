import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Company from '~/interfaces/Company'
import User from '~/interfaces/User'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getCompanyNameByLang } from '~/utils/career/company'
import { getUserNameByLanguage } from '~/utils/common/user'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  user?: User
  size?: 'large' | 'medium' | 'small' | 'tiny'
  isText?: boolean
  numberOfLines?: number
  company?: Company
}

const UserName: React.FC<Props> = ({
  user,
  size,
  isText,
  numberOfLines,
  company,
}: Props) => {
  const { i18n } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const textColor: string = isText ? color.white : color.pressableText

  const fontSize = (): number => {
    switch (size) {
      case 'large':
        return FontSize.IMPORTANT
      case 'medium':
        return FontSize.REMARKABLE
      case 'small':
        return FontSize.SUB
      default:
        return FontSize.SUB
    }
  }

  const textStyle = {
    color: textColor,
    fontSize: fontSize(),
    alignSelf: 'baseline',
  } as TextStyle

  let displayName = ''
  if (user) {
    if (company && user.isCompanyAccount)
      displayName = getCompanyNameByLang(company, getCurrentLanguage())
    else displayName = getUserNameByLanguage(i18n.language, user)
  }

  const navigateToUserPage = (): void => {
    user && !user.isCompanyAccount
      ? navigate('userDetail', { id: user.id })
      : navigate('companyShow', { id: company?.id! })
  }

  return (
    <TitleText
      style={textStyle}
      testID="user-name-text"
      numberOfLines={numberOfLines}
      onPress={isText ? undefined : navigateToUserPage}
    >
      {displayName}
    </TitleText>
  )
}

export default UserName
