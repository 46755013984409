import { BaseAPI } from '~/api/baseAPI'

export type DirectUploadBody = {
  filename: string
  byteSize: number
  checksum: string
  contentType: string
}

export class DirectUploadsAPI extends BaseAPI {
  path = '/storage/direct_uploads'
}
