import React from 'react'
import { useTranslation } from 'react-i18next'

import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  isPC: boolean
  job: Partial<Recruitment>
  editable?: boolean
  onSubmit?: (value: string) => void
}

const JobQualifications: React.FC<Props> = ({ job, isPC, ...rest }: Props) => {
  const { t } = useTranslation()

  return (
    <JobInfoBaseLayout
      isPC={isPC}
      content={job.criterion}
      title={t('jobs.jobQualifications')}
      {...rest}
    />
  )
}

export default JobQualifications
