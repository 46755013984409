import { RouteProp, useRoute } from '@react-navigation/native'
import { first, isNil, isUndefined } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import ApplicantList from '~/components/career/features/company/ApplicantList'
import CompanyProfileOverview from '~/components/career/features/company/CompanyProfileOverview'
import HeaderCompany from '~/components/career/features/company/HeaderCompany'
import RecruitmentPostSelectableList from '~/components/career/features/company/RecruitmentPostSelectableList'
import JobsList from '~/components/career/features/jobs/JobsList'
import CompanyRecruitmentsHeader from '~/components/career/molecules/company/CompanyRecruitmentsHeader'
import CompanyRecruitmentsSortMenu from '~/components/career/molecules/company/CompanyRecruitmentsSortMenu'
import CompanyAccountManagement from '~/components/career/organisms/company/CompanyAccountManagement'
import CompanyApplicants from '~/components/career/organisms/company/CompanyApplicants'
import CompanyOverview from '~/components/career/organisms/company/CompanyOverview'
import CompanyProfileMemberList from '~/components/career/organisms/company/CompanyProfileMemberList'
import CompanyProfiles from '~/components/career/organisms/company/CompanyProfiles'
import CompanyRecruitments from '~/components/career/organisms/company/CompanyRecruitments'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import { CompanySection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import {
  MainStackParamList,
  ProfileSectionParam,
} from '~/interfaces/RootStackParamList'
import { currentUserSelector } from '~/slices/common/users'
import { isCompanyAdmin } from '~/utils/career/company'
import { getRouteParams, parseCompanySection } from '~/utils/navigation'

const CompanyTemplate: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const toast = useCustomToast()
  const [company, setCompany] = useState<Company>()
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const route = useRoute<RouteProp<MainStackParamList>>()
  const companyId = (route.params as { id: number })?.id
  const currentCompany = first(currentUser?.profile.companies)?.id

  const fetchCompany = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await api.companies.show<Company>(companyId)
      setCompany(response)
    } catch (e) {
      toast.showError(e)
    } finally {
      api.isAlive && setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCompany()
  }, [companyId])

  const isAdmin = useMemo(() => {
    return isNil(currentUser) || isNil(company)
      ? false
      : isCompanyAdmin(currentUser, company)
  }, [currentUser?.id, company?.id])

  const currentSection = parseCompanySection(
    getRouteParams<ProfileSectionParam>().section
  )

  const isCompanyMode = !isAdmin ? currentCompany === companyId ?? false : false

  const hrUsers = company?.userWorkingCompanies.filter((item) => item.isAdmin)
  const memberUsers = company?.userWorkingCompanies.filter(
    (item) => !item.isAdmin && item.user?.isCompanyAccount
  )

  const getContentComponent = (): React.ReactNode => {
    switch (currentSection) {
      case CompanySection.PROFILES:
        return (
          <>
            <CompanyProfileOverview company={company} isAdmin={isAdmin} />
            <View style={styles.verticalSeparator} />
            <CompanyProfiles
              companyMemberElement={
                !isUndefined(company) ? (
                  <CompanyProfileMemberList
                    title={t('company.memberTitle', {
                      companyName: company?.name,
                    })}
                    companyUser={memberUsers}
                    user={currentUser}
                    isPC={isPC}
                  />
                ) : (
                  <></>
                )
              }
              companyAdminElement={
                !isUndefined(company) ? (
                  <CompanyProfileMemberList
                    title={t('company.hrTitle')}
                    companyUser={hrUsers}
                    user={currentUser}
                    isPC={isPC}
                  />
                ) : (
                  <></>
                )
              }
              //TODO member invitation has been implemented in account detail
              companyInvitationElement={<></>}
            />
          </>
        )
      case CompanySection.RECRUITMENTS:
        return (
          <CompanyRecruitments
            companyJobPostsElement={
              !isUndefined(company) ? (
                <JobsList
                  isCompanyMode={isCompanyMode}
                  header={<CompanyRecruitmentsSortMenu isPC={isPC} />}
                  company={company}
                />
              ) : (
                <></>
              )
            }
            companyRecruitmentsHeaderElement={
              company ? (
                <CompanyRecruitmentsHeader
                  company={company}
                  currentUser={currentUser}
                  isPC={isPC}
                />
              ) : (
                <></>
              )
            }
          />
        )
      case CompanySection.APPLICANTS:
        return (
          <CompanyApplicants
            leftColumn={isPC ? <RecruitmentPostSelectableList /> : undefined}
            rightColumn={<ApplicantList />}
          />
        )
      case CompanySection.OVERVIEW:
        return <CompanyOverview />
      case CompanySection.ACCOUNT_MANAGEMENT:
        return company ? (
          <CompanyAccountManagement
            company={company}
            user={currentUser}
            isPC={isPC}
          />
        ) : (
          <></>
        )
    }
  }

  return (
    <InfiniteScrollContainer>
      <View
        testID="company-template"
        style={isPC ? styles.container : undefined}
      >
        {!isUndefined(company) && (
          <HeaderCompany isAdmin={isAdmin} company={company} />
        )}
        <View style={styles.body}>
          <View style={styles.horizontalContainer}>
            <View testID="company-right-column" style={styles.fullParent}>
              {getContentComponent()}
            </View>
          </View>
        </View>
      </View>
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  container: {
    alignSelf: 'center',
    width: 814,
  },
  fullParent: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
  },
  verticalSeparator: {
    marginBottom: 10,
  },
})

export default CompanyTemplate
