import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import Entry from '~/interfaces/Entry'
import { navigate } from '~/utils/navigation'

type Props = {
  entry: Entry
}

const ApplicantResume: React.FC<Props> = ({ entry }: Props) => {
  const { t } = useTranslation()

  const navigateToResume = () => {
    navigate('resumeShow', { id: entry.userResume?.id })
  }

  return (
    <View testID="applicant-resume" style={styles.container}>
      <Text
        style={styles.title}
        onPress={navigateToResume}
        testID="applicant-resume-text"
      >
        {t('resume.title.applicant')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  title: {
    color: color.pressableText,
    fontWeight: '600',
    marginRight: 4,
    textDecorationLine: 'underline',
  },
})

export default ApplicantResume
