import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ResumeBaseGroup from '~/components/career/molecules/resume/ResumeBaseGroup'
import WorkExperienceGroupItem from '~/components/career/molecules/resume/WorkExperienceGroupItem'
import { TemplateType } from '~/interfaces/Resume'
import WorkExperience from '~/interfaces/WorkExperience'

type Props = {
  templateType: TemplateType
  workExperiences: Partial<WorkExperience>[]
}

const WorkExperienceGroup: React.FC<Props> = ({
  templateType,
  workExperiences,
}: Props) => {
  const { t } = useTranslation()
  const isInSubPage = workExperiences.every((item) => item.isInSubPage === true)

  return isEmpty(workExperiences) ? (
    <></>
  ) : (
    <ResumeBaseGroup
      category={isInSubPage ? undefined : t('resume.workExperienceTitle')}
      templateType={templateType}
    >
      <View testID="work-experience-group" style={styles.container}>
        {workExperiences!.map((workExperience, index) => (
          <View key={index}>
            <WorkExperienceGroupItem
              templateType={templateType}
              workExperience={workExperience}
            />
          </View>
        ))}
      </View>
    </ResumeBaseGroup>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default WorkExperienceGroup
