import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import ResetPasswordTemplate from '~/components/common/templates/auth/ResetPasswordTemplate'
import { setPageTitle } from '~/utils/navigation'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('auth.changePassword')))

  return (
    <DefaultLayout>
      <ResetPasswordTemplate />
    </DefaultLayout>
  )
}

export default ResetPassword
