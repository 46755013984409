import { BaseAPI } from '~/api/baseAPI'

export type CreateInvitationParams = {
  userId: number
}

export class CommunityEventAttendeeInvitationAPI extends BaseAPI {
  configPath(
    communityId: number,
    communityEventId: number
  ): CommunityEventAttendeeInvitationAPI {
    this.path = `/communities/${communityId}/events/${communityEventId}/community_event_attendee_invitations`
    return this
  }
}
