import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  visible: boolean
  message: string
  isDanger?: boolean
  acceptLabel?: string
  cancelLabel?: string
  isOnlyAccept?: boolean
  onPressAccept?: () => void
  onPressCancel?: () => void
}

const ConfirmationDialog: React.FC<Props> = ({
  message,
  visible,
  isDanger,
  acceptLabel,
  cancelLabel,
  isOnlyAccept,
  onPressAccept,
  onPressCancel,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ModalContainer visible={visible} onDismiss={onPressCancel}>
      <View style={styles.container} testID="confirm-dialog">
        <Text style={styles.message} testID="confirmation-dialog-message">
          {message}
        </Text>
        <View style={styles.actionContainer}>
          <Button
            testID="accept-button"
            onPress={onPressAccept}
            title={acceptLabel ? acceptLabel : t('yes')}
            containerStyle={StyleSheet.flatten([styles.button])}
            buttonStyle={
              isDanger ? StyleSheet.flatten([styles.danger]) : undefined
            }
          />
          {!isOnlyAccept && (
            <Button
              buttonStyle={StyleSheet.flatten([
                styles.button,
                { backgroundColor: color.gray },
              ])}
              testID="cancel-button"
              onPress={onPressCancel}
              title={cancelLabel ? cancelLabel : t('cancel')}
            />
          )}
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    width: 260,
  },
  button: {
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 10,
    maxWidth: 150,
    minWidth: 110,
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    maxWidth: 370,
    padding: 40,
  },
  danger: {
    backgroundColor: color.danger,
  },
  message: {
    fontSize: FontSize.REMARKABLE,
    textAlign: 'center',
  },
})

export default ConfirmationDialog
