import { isEmpty, isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { API } from '~/api'
import { getCompanyName } from '~/components/career/features/company/CompanyMemberList'
import RegistrationMessage from '~/components/common/atoms/auth/RegistrationMessage'
import AuthContainerColumn from '~/components/common/features/auth/AuthContainerColumn'
import FacebookAuthButton from '~/components/common/features/auth/FacebookAuthButton'
import SignupForm from '~/components/common/organisms/auth/SignupForm'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import CompanyMemberInvitation from '~/interfaces/CompanyMemberInvitation'
import { getCurrentLanguage } from '~/plugins/i18n'
import { login, resendConfirmation } from '~/slices/common/auth'

type Props = {
  isCompany?: boolean
  invitation?: CompanyMemberInvitation
}

type Errors = {
  fullMessages?: string[]
}

type ServerError = {
  errors?: Errors
}

const SignupColumn: React.FC<Props> = ({ isCompany, invitation }) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowReSignUp, setIsShowReSignUp] = useState(false)
  const { isPC } = useDevice()
  const dispatch = useDispatch()

  const signUpWithPassword = useAPI(
    async (api: API, email: string, password: string): Promise<void> => {
      try {
        setIsLoading(true)
        await api.auth.signUp({
          email,
          password,
          confirmSuccessUrl: '',
          isCompanyAccount: isCompany,
          invitationToken: invitation?.token,
          displayLang: getCurrentLanguage(),
        })
        loginFromInvitation(email, password)
        setError('')
        setSuccess(true)
        setIsShowReSignUp(true)
      } catch (err) {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error: ServerError | any = err
        setError((error?.errors?.fullMessages ?? []).join(', '))
        setIsLoading(false)
      }
    },
    [isCompany, invitation?.token]
  )

  const requestConfirmation = async (email: string): Promise<void> => {
    try {
      await dispatch(resendConfirmation(email))
      setError('')
      setSuccess(true)
    } catch (err) {
      setError(t('auth.resendEmailErrorMessage'))
    }
  }

  const loginFromInvitation = (email: string, password: string) => {
    if (!isNil(invitation?.email) && isCompany) {
      Promise.all([dispatch(login({ email, password }))])
    }
  }

  const renderMessage = (): React.ReactElement | undefined => {
    if (!isEmpty(error)) {
      return <RegistrationMessage message={error} isPC={isPC} />
    } else if (success) {
      return (
        <RegistrationMessage
          isPC={isPC}
          message={t('auth.registrationEmailSent')}
        />
      )
    }
    if (!isNil(invitation)) {
      return (
        <RegistrationMessage
          isPC={isPC}
          message={t('auth.companyMemberInvitationGuide', {
            companyName: getCompanyName(
              getCurrentLanguage(),
              invitation.company
            ),
          })}
        />
      )
    }
  }

  const changeReSignUp = () => {
    setIsLoading(false)
    setIsShowReSignUp(false)
  }

  return (
    <AuthContainerColumn messageView={renderMessage()}>
      <SignupForm
        isPC={isPC}
        isLoading={isLoading}
        onSubmit={signUpWithPassword}
        invitationEmail={invitation?.email}
        signUpFacebookButton={
          <FacebookAuthButton
            redirectPath={`${isCompany ? '/company' : ''}/signup`}
            title={t('auth.signupWithFacebook')}
            isPC={isPC}
          />
        }
        isShowReSignUp={isShowReSignUp}
        onChange={changeReSignUp}
        resendConfirmation={requestConfirmation}
        isCompany={isCompany}
      />
    </AuthContainerColumn>
  )
}

export default SignupColumn
