import i18n from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { CheckBox } from 'react-native-elements'
import { format } from 'timeago.js'

import ChatThreadName from '~/components/career/atoms/messages/ChatThreadName'
import AvatarGroup from '~/components/common/atoms/AvatarGroup'
import ProfileAvatar, {
  ProfileAvatarSize,
} from '~/components/common/atoms/ProfileAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import ChatThread from '~/interfaces/ChatThread'
import User from '~/interfaces/User'

type Props = {
  isSelected?: boolean
  chatThread: ChatThread
  currentUser?: User
  withoutBackground?: boolean
  controlMenu?: React.ReactElement
  onPress?: (thread: ChatThread) => void
  isShowCheckboxThreads?: boolean
}

const InboxItem: React.FC<Props> = ({
  chatThread,
  isSelected,
  currentUser,
  withoutBackground,
  controlMenu,
  onPress,
  isShowCheckboxThreads,
}: Props) => {
  const [isThreadChecked, setIsThreadChecked] = useState(false)

  const recipients =
    currentUser && chatThread.users.length === 1 // Send to myself
      ? [currentUser]
      : chatThread.users.filter((user) => user.id !== currentUser?.id)

  const { t } = useTranslation()

  const selectedContainerStyle =
    chatThread.unreadCount > 0
      ? styles.unread
      : isSelected
      ? styles.selected
      : styles.read

  const getUserDisplayName = (user: User): string => {
    return t('profile.displayFullName', {
      name: user.profile.name,
      enName: user.profile.enName || user.profile.name,
    })
  }

  const getThreadName = (): string =>
    recipients
      .map((recipient) => recipient && getUserDisplayName(recipient))
      .join(', ')

  const lastUpdate = (
    <Text style={styles.date}>
      {format(chatThread.updatedAt, i18n.language)}
    </Text>
  )

  const changeThread = (chatThread: ChatThread) => {
    setIsThreadChecked(!isThreadChecked)
    onPress && onPress(chatThread)
  }

  return (
    <View
      testID="inbox-item"
      style={StyleSheet.flatten([
        styles.container,
        withoutBackground ? undefined : selectedContainerStyle,
      ])}
    >
      <View style={styles.body}>
        {isShowCheckboxThreads && (
          <CheckBox
            checked={isThreadChecked}
            onPress={(): void => changeThread(chatThread)}
          />
        )}
        {chatThread.image ? (
          <ProfileAvatar
            hasBorder
            size={ProfileAvatarSize.MEDIUM}
            source={{ uri: chatThread.image.presignedUrl }}
          />
        ) : (
          <AvatarGroup users={recipients} />
        )}
        <View style={styles.content} testID="chat-thread-name-container">
          <ChatThreadName value={chatThread.name ?? getThreadName()} />
          {chatThread?.latestMessage && (
            <Text testID="latest-message" style={styles.latestMessage}>
              {chatThread.latestMessage}
            </Text>
          )}
          {lastUpdate}
        </View>
        {controlMenu}
        <View />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    padding: 10,
  },
  container: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  content: {
    flex: 1,
    paddingVertical: 6,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  date: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  latestMessage: {
    flexWrap: 'wrap',
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  read: {
    backgroundColor: color.white,
  },
  selected: {
    backgroundColor: color.primaryLight,
  },
  unread: {
    backgroundColor: color.secondaryLight,
  },
})

export default InboxItem
