import { useNavigation, useRoute } from '@react-navigation/native'
import i18next, { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { switchLanguage } from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'
import { RootStackNavigationProp } from '~/utils/navigation'

const TopPageHeaderRight: React.FC = () => {
  const { t } = useTranslation()
  const { name } = useRoute()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const goToSignup = () => {
    navigate('signup')
  }

  const goToLogin = () => {
    navigate('login')
  }

  return (
    <View style={styles.container} testID="top-page-header-right">
      {name === 'topPage' && !accessToken ? (
        <>
          <Button
            title={t('auth.login')}
            type="outline"
            buttonStyle={styles.loginBtn}
            titleStyle={styles.loginBtnText}
            onPress={goToLogin}
            testID="top-page-login-button"
          />
          <Button
            title={t('auth.register')}
            type="solid"
            buttonStyle={styles.registerBtn}
            titleStyle={styles.registerBtnText}
            onPress={goToSignup}
            testID="top-page-register-button"
          />
        </>
      ) : (
        <Button
          type="outline"
          title={t('menu.language')}
          testID="top-page-language-switch-button"
          titleStyle={styles.title}
          buttonStyle={styles.languageBtn}
          onPress={(): Promise<TFunction> => switchLanguage()}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: 20,
  },
  languageBtn: {
    borderColor: color.primary,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 36,
    width: 80,
  },
  loginBtn: {
    borderWidth: 0,
    width: 80,
  },
  loginBtnText: {
    color: color.textGray,
    fontFamily: getMediumFontByLanguage(i18next.language),
    fontSize: FontSize.GENERAL,
  },
  registerBtn: {
    backgroundColor: color.primary,
    width: 80,
  },
  registerBtnText: {
    color: color.white,
    fontFamily: getMediumFontByLanguage(i18next.language),
    fontSize: FontSize.GENERAL,
  },
  title: {
    color: color.primary,
    alignSelf: 'center',
    fontSize: FontSize.GENERAL,
  },
})

export default TopPageHeaderRight
