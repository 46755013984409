type DataType = {
  id: number
}

export function updateItem<T extends DataType>(data: T[], item: T): T[] {
  return data.map((dataItem) =>
    dataItem.id === item.id ? { ...dataItem, ...item } : dataItem
  )
}

export function removeItem<T extends DataType>(data: T[], item: T): T[] {
  return data.filter((dataItem) => dataItem.id !== item.id)
}
