import React from 'react'
import { View } from 'react-native'

type Props = {
  companyJobPostsElement: React.ReactElement
  companyRecruitmentsHeaderElement: React.ReactElement
}

const CompanyRecruitments: React.FC<Props> = ({
  companyJobPostsElement,
  companyRecruitmentsHeaderElement,
}: Props) => (
  <View testID="company-recruitments">
    {companyRecruitmentsHeaderElement}
    {companyJobPostsElement}
  </View>
)

export default CompanyRecruitments
