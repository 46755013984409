import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { View, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title: string
  hasAddAction: boolean
  children: React.ReactNode
  onAddPress?: () => void
  topElement?: React.ReactNode
  isPC: boolean
  containerStyle?: ViewStyle
}

const FormLayout: React.FC<Props> = ({
  title,
  children,
  topElement,
  onAddPress,
  hasAddAction,
  isPC,
  containerStyle: customContainerStyle,
}: Props) => {
  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View style={[containerStyle, customContainerStyle]} testID="form-layout">
      {topElement}
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
        {hasAddAction && (
          <TouchableOpacity onPress={onAddPress} testID="add-button">
            <Icon
              name="plus"
              color={color.primary}
              size={20}
              testID="add-icon"
            />
          </TouchableOpacity>
        )}
      </View>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    elevation: 5,
    paddingHorizontal: 50,
    paddingTop: 50,
    shadowColor: color.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  containerMobile: {
    backgroundColor: color.white,
    padding: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default FormLayout
