import { Picker as RNPicker, PickerProps } from '@react-native-picker/picker'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import React from 'react'

import { getCurrentLanguage } from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

const Picker = React.forwardRef<RNPicker<ItemValue | undefined>, PickerProps>(
  (
    props: PickerProps,
    ref: React.ForwardedRef<RNPicker<ItemValue | undefined>>
  ): JSX.Element => {
    return (
      <RNPicker
        {...props}
        ref={ref as React.LegacyRef<RNPicker<ItemValue>>}
        style={[
          { fontFamily: getMediumFontByLanguage(getCurrentLanguage()) },
          props.style, // eslint-disable-line react/prop-types
        ]}
      />
    )
  }
)

Picker.displayName = 'Picker'

export default Picker
