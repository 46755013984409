import React from 'react'
import { StyleSheet, Switch } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title?: string
  label?: string
  value: boolean
  onValueChange: (value: boolean) => void
}

const HorizontalSwitch: React.FC<Props> = ({
  label,
  title,
  value,
  onValueChange,
}: Props) => (
  <>
    {title && <Text style={styles.title}>{title}</Text>}
    <HorizontalContainer>
      <Text style={styles.label}>{label}</Text>
      <Switch
        value={value}
        onValueChange={onValueChange}
        testID="horizontal-switch"
      />
    </HorizontalContainer>
  </>
)

const styles = StyleSheet.create({
  label: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    marginRight: 6,
  },
  title: {
    color: color.darkTitleText,
    fontSize: FontSize.REMARKABLE,
  },
})

export default HorizontalSwitch
