import React from 'react'
import { useSelector } from 'react-redux'

import AuthContainerColumn from '~/components/common/features/auth/AuthContainerColumn'
import RequestPasswordResetForm from '~/components/common/molecules/auth/RequestPasswordResetForm'
import { RootState } from '~/rootReducer'

const RequestPasswordResetColumn: React.FC = () => {
  const isPC = useSelector((state: RootState) => state.device.isPC)

  return (
    <AuthContainerColumn>
      <RequestPasswordResetForm isPC={isPC} />
    </AuthContainerColumn>
  )
}

export default RequestPasswordResetColumn
