import { useFocusEffect } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import CompanyJobDashboardTemplate from '~/components/career/templates/company/CompanyJobDashboardTemplate'
import useCustomToast from '~/hooks/useCustomToast'
import { fetchCompany } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { setPageTitle } from '~/utils/navigation'

const CompanyJobDashboard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const currentUser = useSelector(currentUserSelector)
  const isCompanyAccount = currentUser?.isCompanyAccount
  const companyId = currentUser?.profile?.companies?.[0]?.id

  useEffect(() => {
    if (isCompanyAccount && companyId) {
      try {
        dispatch(fetchCompany(companyId))
      } catch (error) {
        toast.showError(error)
      }
    }
  }, [])

  useFocusEffect(() => setPageTitle(t('menu.jobsDashboard')))

  return currentUser?.isCompanyAccount ? (
    <DefaultLayout>
      <CompanyJobDashboardTemplate />
    </DefaultLayout>
  ) : (
    <></>
  )
}

export default CompanyJobDashboard
