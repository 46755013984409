import { ResizeMode, Video } from 'expo-av'
import type { Video as VideoEl } from 'expo-av'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'

import videoPlayImage from '~/assets/images/video_play.png'
import color from '~/constants/common/color'

type Props = {
  uri: string
  stretch: 'horizontal' | 'vertical'
  onGetSize?: (width: number, height: number) => void
  onPlayingStatusChanged?: (isPlaying: boolean) => void
  thumbnail?: ImageSourcePropType
}

const VideoPlayer: React.FC<Props> = ({
  uri,
  stretch,
  onGetSize,
  onPlayingStatusChanged,
  thumbnail,
}: Props) => {
  const video = React.useRef<VideoEl>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [started, setStarted] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleReadyForDisplay = useCallback(
    ({ target }) => {
      setHeight(target?.videoHeight)
      setWidth(target?.videoWidth)
    },
    [setHeight, setWidth]
  )

  const handlePlaybackStatusUpdate = useCallback(
    (event) => {
      setIsPlaying(event?.isPlaying)
    },
    [setIsPlaying]
  )

  const handlePress = useCallback(() => {
    setStarted(true)
    video.current?.playAsync()
  }, [setStarted])

  useEffect(() => {
    if (onGetSize != null && width > 0 && height > 0) {
      onGetSize(width, height)
    }
  }, [width, height, onGetSize])

  useEffect(() => {
    if (onPlayingStatusChanged != null) {
      onPlayingStatusChanged(isPlaying)
    }
  }, [isPlaying, onPlayingStatusChanged])

  const mask = started ? null : (
    <TouchableOpacity
      testID="play-button-container"
      style={styles.mask}
      onPress={handlePress}
    >
      {thumbnail && (
        <>
          <Image source={thumbnail} style={styles.thumbnail} />
          <View style={styles.thumbnailOverlay} />
        </>
      )}
      <Image source={videoPlayImage} style={styles.playIcon} />
    </TouchableOpacity>
  )

  return (
    <View
      testID="video-player"
      style={
        stretch === 'horizontal' ? styles.horizontalVideo : styles.verticalVideo
      }
    >
      <Video
        ref={video}
        style={styles.video}
        source={{ uri: uri }}
        useNativeControls={started}
        testID="video-player-video"
        resizeMode={ResizeMode.CONTAIN}
        onReadyForDisplay={handleReadyForDisplay}
        onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
      />
      {mask}
    </View>
  )
}

const mobileVideoStyle = {
  minHeight: 600,
  minWidth: 320,
}

const styles = StyleSheet.create({
  horizontalVideo: {
    height: '100%',
    justifyContent: 'center',
  },
  mask: {
    alignItems: 'center',
    backgroundColor: color.mask,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  playIcon: {
    height: 53,
    width: 46,
  },
  thumbnail: {
    bottom: -1,
    left: -1,
    position: 'absolute',
    right: -1,
    top: -1,
  },
  thumbnailOverlay: {
    backgroundColor: color.black,
    bottom: -1,
    left: -1,
    opacity: 0.2,
    position: 'absolute',
    right: -1,
    top: -1,
  },
  verticalVideo: {
    justifyContent: 'center',
    width: '100%',
  },
  video: {
    flex: 1,
    justifyContent: 'center',
    ...Platform.select({
      ios: mobileVideoStyle,
      android: mobileVideoStyle,
    }),
  },
})

export default VideoPlayer
