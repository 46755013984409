import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import EditResumeMain from '~/components/career/features/resume/EditResumeMain'
import EditResumeHeader from '~/components/career/organisms/resumes/EditResumeHeader'
import color from '~/constants/common/color'

const CreateResumeTemplate: React.FC = () => {
  return (
    <ScrollView testID="create-resume-template" style={styles.container}>
      <EditResumeHeader />
      <EditResumeMain />
    </ScrollView>
  )
}

export const styles = StyleSheet.create({
  container: {
    backgroundColor: color.lightGray,
  },
})

export default CreateResumeTemplate
