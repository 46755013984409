import React from 'react'
import { useTranslation } from 'react-i18next'
import { GestureResponderEvent, StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  disabled?: boolean
  onPress?: (event: GestureResponderEvent) => void
}

const CancelButton: React.FC<Props> = ({ onPress, disabled }: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      type="solid"
      testID="cancel-button"
      title={t('cancel')}
      onPress={onPress}
      disabled={disabled}
      buttonStyle={styles.cancelButton}
      titleStyle={styles.cancelTitle}
    />
  )
}

const styles = StyleSheet.create({
  cancelButton: {
    alignSelf: 'flex-end',
    backgroundColor: color.gray,
  },
  cancelTitle: {
    ...commonStyles.buttonTextSize,
    paddingHorizontal: 15,
  },
})

export default CancelButton
