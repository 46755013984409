import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { RecruimentQueryParams } from '~/api/recruitments'
import LocationPicker from '~/components/common/molecules/LocationPicker'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams, navigationRef } from '~/utils/navigation'

const JobsHeaderRight: React.FC = () => {
  const isReady = navigationRef.isReady()
  const { setQueryParams } = useCustomNavigation()
  const routeParams = getRouteParams<RecruimentQueryParams>()
  const [params, setParams] = useState<RecruimentQueryParams>(routeParams)

  const changeLocation = (location: string): void => {
    setQueryParams({
      ...params,
      location,
    })
    setParams({ ...params, location })
  }

  useEffect(() => {
    isReady && setParams(routeParams)
  }, [isReady])

  return (
    <View testID="jobs-header-right" style={styles.container}>
      <LocationPicker
        onChange={changeLocation}
        selected={params.location}
        invertedText
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})

export default JobsHeaderRight
