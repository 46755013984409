import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title: string
  subtitle?: string
}

const EmptyTitle: React.FC<Props> = ({ title, subtitle }: Props) => (
  <View testID="empty-title">
    <Text style={styles.title}>{title}</Text>
    {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
  </View>
)

const styles = StyleSheet.create({
  subtitle: {
    alignSelf: 'center',
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    lineHeight: 24,
  },
  title: {
    alignSelf: 'center',
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    lineHeight: 24,
    marginTop: 10,
  },
})

export default EmptyTitle
