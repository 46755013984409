import React from 'react'
import { StyleSheet, Platform } from 'react-native'

import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  value?: string
  editable?: boolean
  busyMode?: boolean
  multiline: boolean
  maxLength?: number
  onChangeText?: (text: string) => void
  textSize?: FontSize
}

const TEXTAREA_HEIGHT = 250

const EditableText: React.FC<Props> = (props: Props) => {
  const height = props.multiline ? TEXTAREA_HEIGHT : 'auto'

  return props.editable ? (
    <Input
      testID="editable-text"
      multiline={props.multiline}
      value={props.value || ''}
      style={[styles.editable, { height }]}
      editable={props.editable && !props.busyMode}
      spellCheck={false}
      underlineColorAndroid={color.transparent}
      onChangeText={props.onChangeText}
      maxLength={props.maxLength}
    />
  ) : (
    <Text
      style={[styles.content, { fontSize: props.textSize }]}
      testID="editable-text-content"
    >
      {props.value}
    </Text>
  )
}

const styles = StyleSheet.create({
  content: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  editable: {
    borderColor: color.secondary,
    borderRadius: 2,
    borderWidth: 2,
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    marginTop: 10,
    padding: 6,
    ...Platform.select({
      web: { outlineColor: color.secondary },
    }),
  },
})

export default EditableText
