import { ServiceType } from '~/constants/common/app'

export const getServiceByRouteName = (route: string): ServiceType | null => {
  if (route === 'career') {
    return ServiceType.CAREER
  } else if (route === 'forum') {
    return ServiceType.FORUM
  } else if (route === 'community') {
    return ServiceType.COMMUNITY
  }
  return null
}
