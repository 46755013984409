import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import { format } from 'timeago.js'

import formOfContractData from '~/assets/data/formOfContract.json'
import JobApplicantPopup from '~/components/career/organisms/jobs/JobApplicantPopup'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { Fonts, FontSize } from '~/constants/common/font'
import Company from '~/interfaces/Company'
import Recruitment from '~/interfaces/Recruitment'
import i18n, { getCurrentLanguage } from '~/plugins/i18n'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  job: Recruitment
  isPC: boolean
  company: Company
}

const CompanyJobItemContent: React.FC<Props> = ({
  job,
  isPC,
  company,
}: Props) => {
  const { t } = useTranslation()
  const language = getCurrentLanguage()

  const { navigate } = useNavigation<RootStackNavigationProp>()

  const [isShowApplicantPopup, setIsShowApplicantPopup] = useState(false)

  const toggleJobApplicant = () => {
    setIsShowApplicantPopup(!isShowApplicantPopup)
  }

  useFocusEffect(
    React.useCallback(() => {
      return () => setIsShowApplicantPopup(false)
    }, [])
  )

  return (
    <View style={styles.container} testID="job-item-content">
      <Text
        numberOfLines={2}
        style={styles.subject}
        testID="job-item-subject"
        onPress={(): void => navigate('jobShow', { id: job.id })}
      >
        {job.subject}
      </Text>
      <View>
        <Text style={styles.body} ellipsizeMode="tail" numberOfLines={1}>
          {job.formOfContract
            ?.map((item) =>
              get(formOfContractData, `${item}.${language}.name`, '')
            )
            .join(', ')}
        </Text>
        <Text style={styles.subtitle} ellipsizeMode="tail" numberOfLines={1}>
          {t('posted', {
            time: format(new Date(job.publishedAt), i18n.language),
          })}
          {!isEmpty(job.location) ? ` | ${job.location}` : ''}
        </Text>
      </View>
      <Pressable disabled={!job.entriesCount} onPress={toggleJobApplicant}>
        <Text style={job.entriesCount ? styles.link : styles.linkInactive}>
          {t('company.jobs.applicantCount', { count: job.entriesCount })}
        </Text>
      </Pressable>
      {isShowApplicantPopup && (
        <JobApplicantPopup
          isShow={isShowApplicantPopup}
          job={job}
          hideApplicant={toggleJobApplicant}
          isPC={isPC}
          company={company}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.SUB,
    flexWrap: 'wrap',
    padding: 2,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 10,
    paddingLeft: 20,
  },
  link: {
    color: color.pressableText,
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.SUB,
    textDecorationLine: 'underline',
  },
  linkInactive: {
    color: color.textGray,
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.SUB,
  },
  subject: {
    color: color.pressableText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    padding: 2,
    alignSelf: 'baseline',
  },
  subtitle: {
    fontSize: FontSize.SUB,
    padding: 2,
  },
})

export default CompanyJobItemContent
