import { BaseAPI } from '~/api/baseAPI'

export type EntryBodyParams = {
  recruitmentId: number
  userResumeId: number
  userDocumentId: number
}

export class EntryAPI extends BaseAPI {
  path = '/entries'
}
