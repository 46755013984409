import { useFocusEffect } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import SettingsTemplate from '~/components/common/templates/SettingsTemplate'
import { RootState } from '~/rootReducer'
import { fetchAccount } from '~/slices/common/settings'
import { setPageTitle } from '~/utils/navigation'

const Settings: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector((state: RootState) => state.settings.account)

  useEffect(() => {
    !account && dispatch(fetchAccount())
  }, [])

  useFocusEffect(() => setPageTitle(t('settings')))

  return account ? (
    <DefaultLayout>
      <SettingsTemplate />
    </DefaultLayout>
  ) : (
    <View />
  )
}

export default Settings
