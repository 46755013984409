import React from 'react'
import { StyleSheet, View } from 'react-native'

import ButtonGroup from '~/components/workarounds/ButtonGroup'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

export type TopTabNavigatorData = {
  label: string
  key: string
  element?: React.ReactElement
}

type Props = {
  isPC: boolean
  inverted?: boolean
  data: TopTabNavigatorData[]
  selectedItem?: TopTabNavigatorData
  onSelectTab?: (tab: TopTabNavigatorData) => void
}

const TopTabNavigator: React.FC<Props> = ({
  isPC,
  data,
  inverted,
  onSelectTab,
  selectedItem,
}: Props) => {
  const selectedTextColor = inverted ? color.hightLightGray : color.primary
  const textColor = inverted ? color.textGray : color.primary

  return (
    <View testID="top-tab-navigator" style={styles.container}>
      <ButtonGroup
        containerStyle={styles.noneBorder}
        textStyle={[
          isPC ? styles.tabText : styles.tabTextMobile,
          { color: textColor },
        ]}
        buttons={data.map((item) => (item.element ? item.element : item.label))}
        selectedButtonStyle={styles.selectedTab}
        innerBorderStyle={innerBorderStyle}
        buttonContainerStyle={[
          styles.noneBorder,
          isPC ? styles.buttonContainerPC : {},
        ]}
        selectedTextStyle={StyleSheet.flatten([
          isPC ? { ...styles.tabText, paddingBottom: 3 } : styles.tabTextMobile,
          { color: selectedTextColor },
        ])}
        onPress={(index): void => onSelectTab && onSelectTab(data[index])}
        selectedIndex={data.findIndex((item) => item.key === selectedItem?.key)}
      />
    </View>
  )
}

const innerBorderStyle = {
  color: color.transparent,
  width: 0,
}

const styles = StyleSheet.create({
  buttonContainerPC: {
    flexGrow: 0,
    flexBasis: 'fit-content',
    paddingRight: 40,
  },
  container: {
    flex: 1,
  },
  noneBorder: {
    borderColor: color.transparent,
    borderRadius: 0,
    borderWidth: 0,
    marginHorizontal: 0,
    marginVertical: 0,
    padding: 0,
    ...commonStyles.borderRadiusTopCorner,
  },
  selectedTab: {
    backgroundColor: color.transparent,
    borderBottomWidth: 3,
    borderColor: color.primary,
  },
  tabText: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    paddingBottom: 6,
  },
  tabTextMobile: {
    fontSize: FontSize.SUB,
  },
})

export default TopTabNavigator
