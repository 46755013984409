import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'

import jobHuntingStatusFromAssets from '~/assets/data/jobHuntingStatus.json'
import JobHuntingStatusItem from '~/components/common/molecules/users/profileStatus/JobHuntingStatusItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import JobHunting from '~/interfaces/JobHunting'
import i18n from '~/plugins/i18n'

export const prepareData = (selectedItems: string[]): JobHunting[] =>
  jobHuntingStatusFromAssets.map((item) => ({
    value: item.id,
    title: i18n.language === 'ja' ? item.name : item.en_name,
    checked: selectedItems.includes(item.id.toString()),
  }))

type Props = {
  numColumns?: number
  selectedItems: string[]
  onChange: (selectedItems: string[]) => void
}

const JobHuntingStatusGroup: React.FC<Props> = ({
  onChange,
  numColumns,
  selectedItems,
}: Props) => {
  const { t } = useTranslation()
  const [jobHuntingStatus, setJobHuntingStatus] = useState<JobHunting[]>(
    prepareData(selectedItems)
  )

  const keyExtractor = (item: JobHunting): string => item.value

  const renderItem: ListRenderItem<JobHunting> = ({
    item,
  }: {
    item: JobHunting
  }) => (
    <JobHuntingStatusItem
      jobHunntingItem={item}
      onChange={updateSelectedItems}
    />
  )

  const updateSelectedItems = (jobHuntingItem: JobHunting): void => {
    const isItemSelected = selectedItems.includes(jobHuntingItem.value)
    let newSelectedItems = selectedItems
    if (isItemSelected) {
      newSelectedItems = selectedItems.filter(
        (item) => item !== jobHuntingItem.value
      )
    } else {
      newSelectedItems = isNotYetSearchingItem(jobHuntingItem.value)
        ? [jobHuntingItem.value]
        : [
            ...selectedItems.filter((item) => !isNotYetSearchingItem(item)),
            jobHuntingItem.value,
          ]
    }
    setJobHuntingStatus(prepareData(newSelectedItems))
    onChange(newSelectedItems)
  }

  const isNotYetSearchingItem = (itemValue: string): boolean =>
    itemValue === 'not_yet'

  return (
    <View testID="job-hunting-status-group" style={styles.container}>
      <Text style={styles.title}>{t('jobs.hunting.asking')}</Text>
      <FlatList
        data={jobHuntingStatus}
        testID="job-hunting-flat-list"
        numColumns={numColumns}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  title: {
    color: color.darkTitleText,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginBottom: 4,
  },
})

export default React.memo(JobHuntingStatusGroup)
