import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import CustomPicker, {
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'

type Props = {
  onChange: (value: string) => void
  selected?: string
  size?: FontSize
}

const GenderPicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const genders = [
    pickerValue(t('pleaseSelect'), ''),
    pickerValue(t('profile.male'), 'male'),
    pickerValue(t('profile.female'), 'female'),
    pickerValue(t('profile.other'), 'other'),
  ]
  const [gender, setGender] = useState(props.selected)

  const handleGender = (value: string | number): void => {
    setGender(value.toString())
    props.onChange(value.toString())
  }

  return (
    <View testID="gender-picker" style={styles.container}>
      <Text
        style={StyleSheet.flatten([styles.title, { fontSize: props.size }])}
      >
        {t('profile.gender')}
      </Text>
      <View style={styles.pickerContainer} testID="gender-picker-container">
        <CustomPicker
          data={genders}
          size="medium"
          selectedValue={gender}
          onValueChange={handleGender}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  pickerContainer: {
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default GenderPicker
