import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import RequestPasswordResetTemplate from '~/components/common/templates/auth/RequestPasswordResetTemplate'
import { setPageTitle } from '~/utils/navigation'

const RequestPasswordReset: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('auth.requestPasswordRecovery')))

  return (
    <DefaultLayout>
      <RequestPasswordResetTemplate />
    </DefaultLayout>
  )
}

export default RequestPasswordReset
