import React from 'react'
import { StyleSheet, View } from 'react-native'

import quizCategories from '~/assets/data/quizCategories.json'
import QuizList from '~/components/career/features/quizzes/QuizList'
import QuizzesHeader from '~/components/career/features/quizzes/QuizzesHeader'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import CategoriesFilter from '~/components/common/features/CategoriesFilter'
import { CheckBoxListData } from '~/components/common/organisms/CheckBoxList'
import useDevice from '~/hooks/commons/useDevice'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'

type Props = {
  user?: User
}

const getCheckBoxListData = (): CheckBoxListData[] =>
  quizCategories.map(
    (item) =>
      ({
        value: item.id,
        title: i18n.language === 'ja' ? item.name : item.enName,
      } as CheckBoxListData)
  )

const MyQuiz: React.FC<Props> = ({ user }: Props) => {
  const { isPC } = useDevice()
  const categoryList = getCheckBoxListData()

  return (
    <InfiniteScrollContainer>
      {isPC ? (
        <View testID="my-quiz" style={styles.container}>
          <View style={styles.leftColumn}>
            <CategoriesFilter categoryListData={categoryList} />
          </View>
          <View style={styles.list}>
            <QuizList header={<QuizzesHeader />} userId={user?.id} />
          </View>
        </View>
      ) : (
        <View testID="my-quiz" style={styles.containerMobile}>
          <QuizList header={<QuizzesHeader />} userId={user?.id} />
        </View>
      )}
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  containerMobile: {
    flex: 1,
    paddingVertical: 3,
  },
  leftColumn: {
    marginRight: 20,
    width: 200,
  },
  list: {
    flex: 1,
    maxWidth: 600,
  },
})

export default MyQuiz
