import { isEmpty } from 'lodash'

import { HTTPClient } from '~/httpClient'
import QuizResult from '~/interfaces/QuizResult'

export type QuizResultsQueryParams = {
  q?: string
  page?: number
  userId?: number
  perPage?: number
  categories?: string[]
  isCorrect?: boolean
}

type CreateQuizResultsParams = {
  choice: string
  quizId: number
}

export class QuizResultsAPI {
  client: HTTPClient
  private basePath = '/users'

  constructor(client: HTTPClient) {
    this.client = client
  }

  async index({
    q,
    page,
    userId,
    perPage,
    isCorrect,
    categories,
  }: QuizResultsQueryParams): Promise<QuizResult[]> {
    let path = `${this.basePath}/${userId}/quiz_results`
    const params = []
    if (page) params.push(`page=${page}`)
    if (perPage) params.push(`per_page=${perPage}`)
    if (!isEmpty(categories)) {
      categories?.forEach((item) => item && params.push(`categories[]=${item}`))
    }
    if (isCorrect !== undefined) params.push(`is_correct=${isCorrect}`)
    if (q) params.push(`q=${q}`)
    if (params.length > 0) path += '?' + params.join('&')
    return this.client.get<QuizResult[]>(path)
  }

  async create(
    userId: number,
    data: CreateQuizResultsParams
  ): Promise<QuizResult> {
    return this.client.post<QuizResult>(
      `${this.basePath}/${userId}/quiz_results`,
      data
    )
  }
}
