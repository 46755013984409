import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { MenuProvider } from 'react-native-popup-menu'

import CrossIcon from '~/components/common/atoms/CrossIcon'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import MediaViewer from '~/components/common/molecules/media/MediaViewer'
import Color from '~/constants/common/color'
import { useMediaViewerContext } from '~/contexts/MediaViewerContext'

type Props = {
  isPC: boolean
  children?: React.ReactNode
}

const MediaViewerDialog: React.FC<Props> = ({ isPC, children }) => {
  const {
    media,
    selectedMedia,
    entity,
    dismissDialog,
  } = useMediaViewerContext()

  if (!media?.length || !entity) {
    return null
  }

  return (
    <ModalContainer visible={!!selectedMedia} onDismiss={dismissDialog}>
      <View style={styles.container} testID="media-viewer-dialog">
        <MenuProvider skipInstanceCheck>
          <View
            style={[
              styles.mediaViewerWrapper,
              !isPC && children ? styles.mediaViewerWrapperMobile : undefined,
            ]}
          >
            <View style={styles.closeBtn} testID="media-viewer-close-button">
              {dismissDialog && <CrossIcon onPress={dismissDialog} />}
            </View>
            <MediaViewer
              media={media}
              isPC={isPC}
              selected={selectedMedia ? media.indexOf(selectedMedia) : 0}
              onDismiss={dismissDialog}
            />
            {children && (
              <View style={isPC ? styles.rightSidebar : styles.contentMobile}>
                {children}
              </View>
            )}
          </View>
        </MenuProvider>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  closeBtn: {
    position: 'absolute',
    right: 15,
    top: 5,
    zIndex: 1,
  },
  container: {
    width: '90%',
  },
  contentMobile: {
    backgroundColor: Color.white,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
    overflow: 'hidden',
    paddingTop: 30,
    width: '100%',
  },
  mediaViewerWrapper: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Color.black,
    borderRadius: 14,
    flexDirection: 'row',
    justifyContent: 'center',
    height: Platform.select({
      web: '72vh',
      default: '72%',
    }),
    overflow: 'hidden',
    width: '100%',
  },
  mediaViewerWrapperMobile: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'scroll',
  },
  rightSidebar: {
    backgroundColor: Color.white,
    height: '100%',
    ...Platform.select({
      web: {
        overflowY: 'scroll',
      },
    }),
    paddingTop: 30,
    width: 470,
  },
})

export default MediaViewerDialog
