import { isEmpty } from 'lodash'
import React from 'react'
import { View, StyleSheet, FlatList, ListRenderItem } from 'react-native'
import { shallowEqual } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import ListSeparator from '~/components/common/atoms/ListSeparator'
import TitleText from '~/components/common/atoms/TitleText'
import FriendSuggestionItem from '~/components/common/molecules/friendSuggestion/FriendSuggestionItem'
import color from '~/constants/common/color'
import User from '~/interfaces/User'

export const keyExtractor = (user: User): string => user.id.toString()

export type Props = {
  isPC?: boolean
  title: string
  data: User[]
  onItemDismiss?: (user: User) => Promise<void>
}

const FriendSuggestion: React.FC<Props> = (props: Props) => {
  const renderItem: ListRenderItem<User> = ({ item }: { item: User }) => (
    <FriendSuggestionItem
      isPC={props.isPC}
      user={item}
      key={item.id}
      onDismiss={props.onItemDismiss}
    />
  )

  return isEmpty(props.data) ? (
    <></>
  ) : (
    <CardContainer style={styles.container}>
      <TitleText style={styles.title}>{props.title}</TitleText>
      <View>
        <FlatList
          ItemSeparatorComponent={ListSeparator}
          keyExtractor={keyExtractor}
          data={props.data}
          renderItem={renderItem}
          testID="friend-suggestion-list"
        />
      </View>
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    marginBottom: 10,
    paddingHorizontal: 25,
  },
  title: {
    color: color.unpressableTitleText,
    marginTop: 20,
    marginBottom: 7,
  },
})

export const areEqual = (
  prevProps: Readonly<React.PropsWithChildren<Props>>,
  nextProps: Readonly<React.PropsWithChildren<Props>>
): boolean => {
  return (
    prevProps.isPC === nextProps.isPC &&
    prevProps.title === nextProps.title &&
    shallowEqual(prevProps.data, nextProps.data)
  )
}
export default React.memo(FriendSuggestion, areEqual)
