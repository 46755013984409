import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import PostDetailTemplate from '~/components/career/templates/PostDetailTemplate'

const PostDetail: React.FC = () => {
  return (
    <DefaultLayout>
      <PostDetailTemplate />
    </DefaultLayout>
  )
}

export default PostDetail
