import { Feather } from '@expo/vector-icons'
import { find } from 'lodash'
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import SelectableList, { ID } from '~/components/common/features/SelectableList'
import FilterPopupMenu from '~/components/common/molecules/FilterPopupMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { RootContext } from '~/contexts/RootContext'
import SelectListItem from '~/interfaces/SelectListItem'

export enum EventTabKey {
  EVENT_INFO = 'event-info',
  EVENT_DISCUSSION = 'event-discussion',
}

type Props = {
  onSelect: (eventTab: EventTabKey) => void
}

const EventTabList: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  const eventTabData: SelectListItem[] = [
    {
      id: EventTabKey.EVENT_INFO,
      name: t('events.eventDetail'),
    },
    {
      id: EventTabKey.EVENT_DISCUSSION,
      name: t('events.discussion'),
    },
  ]

  const filterData = eventTabData.map((item) => ({
    id: item.id,
    name: item.name,
  }))

  const [selectedFilter, setSelectedFilter] = useState(filterData[0]) // set default value

  const data = [{ title: t('events.infoTab'), data: eventTabData }]
  const [selectedTabId, setSelectedTabId] = useState<ID>(EventTabKey.EVENT_INFO)

  const changeSelectedTab = (tabId: ID): void => {
    setSelectedTabId(tabId)
    onSelect(tabId as EventTabKey)
    setSelectedFilter(find(filterData, { id: tabId }) ?? selectedFilter)
  }

  return isPC ? (
    <CardContainer style={styles.container} testID="event-tab-list">
      <SelectableList
        data={data}
        onSelect={changeSelectedTab}
        selectedId={selectedTabId ?? ''}
      />
    </CardContainer>
  ) : (
    <View testID="event-tab-list" style={styles.containerMobile}>
      <FilterPopupMenu
        data={filterData}
        selectedItem={selectedFilter}
        onSelect={(item): void => changeSelectedTab(item.id)}
        menuTrigger={
          <HorizontalContainer>
            <Text style={styles.selectedFilter} testID="menu-trigger">
              {selectedFilter?.name}
            </Text>
            <Feather
              name="chevron-down"
              color={color.pressableText}
              size={commonStyles.bodyTextSize.fontSize}
            />
          </HorizontalContainer>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerMobile: {
    alignSelf: 'baseline',
    paddingHorizontal: 10,
  },
  selectedFilter: {
    color: color.pressableText,
    paddingVertical: 6,
    ...commonStyles.bodyTextSize,
  },
})

export default EventTabList
