import React from 'react'
import { StyleSheet, View } from 'react-native'

import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import useDevice from '~/hooks/commons/useDevice'

type Props = {
  children?: React.ReactNode
  onScrollBottom?: () => void
  leftColumn?: React.ReactNode
}

const ForumFrame: React.FC<Props> = ({
  children,
  leftColumn,
  onScrollBottom,
}: Props) => {
  const { isPC } = useDevice()

  return (
    <InfiniteScrollContainer
      onScrollBottom={(): void => onScrollBottom && onScrollBottom()}
    >
      <View
        style={StyleSheet.flatten([
          styles.container,
          isPC ? { marginTop: 20 } : {},
        ])}
        testID="forum-frame"
      >
        {isPC && <View style={styles.leftColumn}>{leftColumn}</View>}
        <View style={styles.centerColumn}>{children}</View>
        {isPC && <View style={styles.rightColumn}></View>}
      </View>
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  centerColumn: {
    flex: 1,
    maxWidth: 600,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  leftColumn: {
    marginRight: 10,
  },
  rightColumn: {
    marginLeft: 10,
    width: 306,
  },
})

export default ForumFrame
