import { CONSTANTS } from 'link-preview-js/build/constants'
import { words } from 'lodash'

export function detectedURL(text: string): string | undefined {
  return (text || '')
    .replace(/\n/g, ' ')
    .split(' ')
    .find((token) => CONSTANTS.REGEX_VALID_URL.test(token))
}

export function currencyFormat(value: number) {
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getErrorMessage(e: unknown): string {
  return e instanceof Error ? e.message : String(e)
}

export function wordCount(str: string) {
  return words(str).length
}
