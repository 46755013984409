import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import CommunityTemplate from '~/components/community/templates/CommunityTemplate'
import { setPageTitle } from '~/utils/navigation'

const Community: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('menu.community')))

  return (
    <DefaultLayout>
      <CommunityTemplate />
    </DefaultLayout>
  )
}

export default Community
