import { Ionicons as Icon } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, TextStyle, View } from 'react-native'

import { API } from '~/api'
import { SavedRecruitsQueryParams } from '~/api/savedRecruits'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Entry from '~/interfaces/Entry'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  job: Recruitment
  isShowIcon?: boolean
}

const JobSave: React.FC<Props> = ({ job, isShowIcon }: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isSaved, setSaved] = useState(job.isSaved)
  const [savedEntry, setSavedEntry] = useState<number | undefined>(undefined)

  useEffect(() => {
    const entry = job?.savedRecruit?.id
    if (entry) setSavedEntry(entry)
  }, [job])

  const saveJob = useAPI(
    async (api: API): Promise<void> => {
      try {
        const entry = await api.savedRecruits.create<
          Partial<SavedRecruitsQueryParams>,
          Entry
        >({
          recruitmentId: job.id,
        })
        setSaved(true)
        setSavedEntry(entry.id)
      } catch (error) {
        toast.showError(error)
      }
    }
  )

  const unSaveJob = useAPI(
    async (api: API): Promise<void> => {
      try {
        savedEntry && (await api.savedRecruits.delete(savedEntry))
        setSaved(false)
        setSavedEntry(undefined)
      } catch (error) {
        toast.showError(error)
      }
    },
    [savedEntry]
  )

  return (
    <View testID="job-save">
      {isShowIcon ? (
        <Pressable
          testID="job-save-icon"
          onPress={isSaved ? unSaveJob : saveJob}
        >
          <Icon
            name={isSaved ? 'md-bookmark' : 'md-bookmark-outline'}
            size={25}
            color={isSaved ? color.primary : color.textGray}
          />
        </Pressable>
      ) : (
        <Text
          testID="job-save-text"
          style={styles.saveText as TextStyle}
          onPress={isSaved ? unSaveJob : saveJob}
        >
          {isSaved ? t('jobs.unSaveJob') : t('jobs.saveJob')}
        </Text>
      )}
    </View>
  )
}

const styles = {
  saveText: {
    textDecorationLine: 'underline',
    color: color.pressableText,
    marginRight: 10,
    fontWeight: 'bold',
  },
}

export default JobSave
