import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { setPageTitle } from '~/utils/navigation'

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('pageNotFoundMessage')))

  return (
    <View style={styles.container} testID="not-found">
      <Image
        source={require('~/assets/images/prompts/404page.png')}
        style={styles.image}
        resizeMode="contain"
      />
      <Text style={styles.message}>{t('pageNotFoundMessage')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 8,
  },
  image: {
    height: 150,
    width: 288,
  },
  message: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    textAlign: 'center',
  },
})

export default NotFound
