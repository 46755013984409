import React, { useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'

import api from '~/api'
import LikeButton from '~/components/common/atoms/socials/LikeButton'
import useCustomToast from '~/hooks/useCustomToast'
import Answer from '~/interfaces/Answer'
import { setAnswer } from '~/slices/forum/answers'

type Props = {
  answer: Answer
}

const AnswerLikeButton: React.FC<Props> = ({ answer }: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useCustomToast()

  const updateLike = (): void => {
    setIsLoading(true)
    if (answer.liked) {
      api.likes
        .unlike({ answerId: answer.id })
        .then(() => {
          answer.liked = false
          answer.likesCount = answer.likesCount - 1
          dispatch(setAnswer({ id: answer.id, likesCount: answer.likesCount }))
        })
        .catch((err) => {
          toast.showError(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      api.likes
        .like({ answerId: answer.id })
        .then(() => {
          answer.liked = true
          answer.likesCount = answer.likesCount + 1
          dispatch(setAnswer({ id: answer.id, likesCount: answer.likesCount }))
        })
        .catch((err) => {
          toast.showError(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <View testID="answer-like-button">
      <LikeButton
        isShowIcon={true}
        disabed={isLoading}
        onPress={updateLike}
        liked={answer.liked}
      />
    </View>
  )
}

export default AnswerLikeButton
