import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
}

const SkeletonCard: React.FC<Props> = ({ isPC }: Props) => (
  <CardContainer
    style={
      isPC
        ? [styles.container, styles.containerSizePC]
        : [styles.container, styles.containerSizeMobile]
    }
    testID="skeleton-card"
  >
    <Image source={{ uri: '' }} resizeMode="cover" style={styles.cover} />
    <View style={styles.separator} />
    <View style={styles.secondRow}>
      <SkeletonTwoBar />
    </View>
  </CardContainer>
)

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    borderRadius: 10,
    marginHorizontal: 5,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  cover: {
    backgroundColor: color.gray,
    height: 135,
    width: '100%',
  },
  secondRow: {
    flex: 1,
    padding: 20,
  },
  separator: {
    marginLeft: 20,
  },
})

export default SkeletonCard
