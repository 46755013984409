import { TFunction } from 'i18next'
import { isEmpty, isNil } from 'lodash'

import Achievement from '~/interfaces/Achievement'
import Education from '~/interfaces/Education'
import Resume from '~/interfaces/Resume'
import ResumeLanguage from '~/interfaces/ResumeLanguage'
import WorkExperience from '~/interfaces/WorkExperience'
import { SupportedLanguage } from '~/plugins/i18n'
import { wordCount } from '~/utils/common/string'

export const MIN_DESCRIPTION = 50
export const MAX_DESCRIPTION_CHAR = 70
export const MAX_DESCRIPTION_WORD = 75
export const RESUME_DATE_FORMAT = 'MMM YYYY'

export const isMinDescriptionValid = (
  inputValue: string,
  language?: string
) => {
  return language === SupportedLanguage.JA
    ? inputValue.length >= MIN_DESCRIPTION
    : wordCount(inputValue) >= MIN_DESCRIPTION
}

export const isMaxDescriptionValid = (
  inputValue: string,
  language?: string
) => {
  return language === SupportedLanguage.JA
    ? inputValue.length <= MAX_DESCRIPTION_CHAR
    : wordCount(inputValue) <= MAX_DESCRIPTION_WORD
}

export const isEducationValid = (education: Partial<Education>): boolean =>
  !isEmpty(education.schoolName) &&
  !isEmpty(education.degree) &&
  !isEmpty(education.major) &&
  !isNil(education?.enrollmentYear) &&
  !isEmpty(education?.enrollmentYear) &&
  (!education.isStudyingHere ? !isEmpty(education.graduationYear) : true)

export const isAchievementValid = (
  achievement: Partial<Achievement>
): boolean =>
  !isEmpty(achievement.name) &&
  !isEmpty(achievement.giver) &&
  !isEmpty(achievement.awardedDate)

export const isWorkExperienceValid = (
  workExperience: Partial<WorkExperience>
): boolean =>
  !isEmpty(workExperience.companyName) &&
  !isEmpty(workExperience.position) &&
  !isEmpty(workExperience.startDate) &&
  (!workExperience.isStudyingHere ? !isEmpty(workExperience.endDate) : true)

export const isResumeLanguageValid = (
  language: Partial<ResumeLanguage>
): boolean => !isEmpty(language.name) && !isEmpty(language.level)

export const getResumeValidationError = (
  index: number, // The index of edit resume page
  resume: Partial<Resume>,
  translate: TFunction
): string => {
  if (isEmpty(resume.familyName)) {
    return translate('resume.validations.firstName')
  } else if (isEmpty(resume.givenName)) {
    return translate('resume.validations.lastName')
  } else if (isEmpty(resume.phone)) {
    return translate('resume.validations.mobile')
  } else if (isEmpty(resume.email)) {
    return translate('resume.validations.email')
  } else if (isEmpty(resume.address)) {
    return translate('resume.validations.address')
  } else if (
    resume?.educations &&
    !isEmpty(resume.educations) &&
    !resume.educations.every(isEducationValid)
  ) {
    return translate('resume.validations.education')
  } else if (
    resume?.achievements &&
    !isEmpty(resume.achievements) &&
    !resume.achievements.every(isAchievementValid)
  ) {
    return translate('resume.validations.achievement')
  } else if (
    resume?.languages &&
    !isEmpty(resume.languages) &&
    !resume.languages.every(isResumeLanguageValid)
  ) {
    return translate('resume.validations.language')
  } else if (
    resume?.workExperiences &&
    !isEmpty(resume.workExperiences) &&
    !resume.workExperiences.every(isWorkExperienceValid)
  ) {
    return translate('resume.validations.workExperience')
  }
  return ''
}

type ResumePageData = {
  educations?: Partial<Education>[]
  achievements?: Partial<Achievement>[]
  workExperiences?: Partial<WorkExperience>[]
}

export type ResumePageSortingData = {
  first?: ResumePageData
  second?: ResumePageData
  third?: ResumePageData
  fourth?: ResumePageData
}

export const resumeLimitation = {
  education: 3,
  workExperience: 9,
  achivement: 6,
}

export const getSubWorkExperiences = (
  workExperiences: Partial<WorkExperience>[],
  prev: Partial<WorkExperience>[]
) => {
  return isEmpty(prev)
    ? workExperiences
    : workExperiences.map((item) => ({ ...item, isInSubPage: true }))
}

export const getSubAchievement = (
  achievementw: Partial<Achievement>[],
  prev: Partial<Achievement>[]
) => {
  return isEmpty(prev)
    ? achievementw
    : achievementw.map((item) => ({ ...item, isInSubPage: true }))
}

export const getResumePageData = (
  resume: Partial<Resume>
): ResumePageSortingData | undefined => {
  return {
    first: {
      educations: resume.educations,
      achievements: resume.achievements,
      workExperiences: resume.workExperiences,
    },
  }
}
