import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { TextProps } from 'react-native-elements'

import Text from '~/components/workarounds/Text'

type Props = {
  children?: React.ReactNode
} & TextProps

const SeeMoreText: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const lengthSeeMore = 200
  const [isShowFull, setIsShowFull] = useState(false)
  const toggleNumberOfLines = () => {
    setIsShowFull(!isShowFull)
  }
  const isLengthMore =
    typeof props.children === 'string' &&
    `${props.children}`.length > lengthSeeMore

  return (
    <View testID="see-more-text">
      <Text {...props}>
        {isLengthMore && !isShowFull
          ? `${`${props.children}`.substring(0, lengthSeeMore)}... `
          : props.children}
        {isLengthMore && !isShowFull ? (
          <Text
            testID="see-more-button"
            style={styles.seeMoreButton}
            onPress={toggleNumberOfLines}
          >
            {t('seeMore')}
          </Text>
        ) : null}
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  seeMoreButton: {
    fontWeight: 'bold',
    marginTop: 8,
  },
})

export default SeeMoreText
