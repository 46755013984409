import { BaseAPI } from '~/api/baseAPI'

export type JoinCommunityParams = {
  userId: number
  communityId: number
}

export class CommunityMemberJoinRequestsAPI extends BaseAPI {
  path = '/community_member_join_requests'
}
