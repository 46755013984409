import moment from 'moment'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
  fontSize?: FontSize
}

const EventTime: React.FC<Props> = ({
  event,
  fontSize = FontSize.GENERAL,
}: Props) => {
  const timeString =
    moment(event.startAt).calendar() + ' ~ ' + moment(event.endAt).calendar()

  return (
    <View testID="event-time" style={styles.container}>
      <Text style={[styles.time, { fontSize }]} numberOfLines={1}>
        {timeString}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 2,
  },
  time: {
    color: color.danger,
    fontWeight: 'bold',
  },
})

export default EventTime
