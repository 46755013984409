import { FontAwesome } from '@expo/vector-icons'
import { Fontisto } from '@expo/vector-icons'
import React, { FC } from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  content: string
  isSelected: boolean
  isCorrect: boolean
  onPress: () => void
  index?: string
  disabled?: boolean
}

const QuizAnswer: FC<Props> = ({
  content,
  isSelected,
  isCorrect,
  onPress,
  index,
  disabled,
}: Props) => {
  const backgroundColor = isSelected
    ? isCorrect
      ? color.primary
      : color.danger
    : color.white
  const borderColor = isSelected
    ? isCorrect
      ? color.primary
      : color.danger
    : color.primary

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        styles.answerContainer,
        {
          backgroundColor,
          borderColor,
        },
      ]}
      onPress={onPress}
      testID="quiz-answer"
    >
      <Text
        testID="answer-content"
        style={isSelected ? styles.answerContentSelected : styles.answerContent}
      >
        {index ? `${index}. ${content}` : content}
      </Text>
      {isSelected ? (
        isCorrect ? (
          <FontAwesome
            testID="correct-icon"
            size={15}
            name="circle-thin"
            color={color.white}
          />
        ) : (
          <Fontisto
            testID="incorrect-icon"
            size={15}
            name="close-a"
            color={color.white}
          />
        )
      ) : null}
    </TouchableOpacity>
  )
}

export default QuizAnswer

const styles = StyleSheet.create({
  answerContainer: {
    alignItems: 'center',
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 1,
  },
  answerContent: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
  answerContentSelected: {
    color: color.white,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginRight: 25,
  },
})
