import { isEmpty, isNil, orderBy, trim } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View, FlatList, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import imageIcon from '~/assets/images/icons/picture.png'
import CommentControlMenu from '~/components/career/features/posts/CommentControlMenu'
import PostCommentForm from '~/components/career/features/posts/PostCommentForm'
import PostLikeButton from '~/components/career/features/posts/PostLikeButton'
import PostLikeCount from '~/components/career/features/posts/PostLikeCount'
import CommentEditor from '~/components/career/molecules/post/CommentEditor'
import CommentItem from '~/components/career/molecules/post/CommentItem'
import ReplyButton from '~/components/common/atoms/socials/ReplyButton'
import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import CommentSocialBar from '~/components/common/molecules/socials/CommentSocialBar'
import CommentBoxMedia, {
  CommentMediaData,
} from '~/components/common/organisms/CommentBoxMedia'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import Post from '~/interfaces/Post'
import { RootState } from '~/rootReducer'
import { setPost } from '~/slices/career/posts'
import { currentUserSelector } from '~/slices/common/users'

class CommentListRef {
  replyTo: ((item: Post) => void) | undefined
}

const keyExtractor = (item: Post): string => item.id.toString()

export type Props = {
  post: Post
  level?: number
  isReadOnly?: boolean
  reference?: CommentListRef
}

export const defaultNumberOfComments = 1

const CommentList: React.FC<Props> = ({
  post,
  level,
  reference,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector)
  const [comments, setComments] = useState(post.comments)
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const [commentToReply, setCommentToReply] = useState<Post>()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const [commentMediaData, setCommentMediaData] = useState<CommentMediaData>({})
  const [currentComment, setCurrentComment] = useState<Post | undefined>(
    undefined
  )
  const [isShowAll, setIsShowAll] = useState(
    post?.comments?.length <= defaultNumberOfComments
  )

  const changedComments = useSelector((state: RootState) => {
    const postData = state.posts.current
    return !isNil(postData?.comments) && postData.id === post.id
      ? postData?.comments
      : undefined
  })

  useEffect(() => {
    if (!isNil(changedComments)) {
      setComments(changedComments)
      post.comments = changedComments
    }
  }, [changedComments])

  const hideComment = (comment: Post): void => {
    const newComments = comments.filter((item) => item.id !== comment.id)
    setComments(newComments)
    dispatch(setPost({ id: post.id, comments: newComments }))
  }

  const updateComment = async (comment: Post): Promise<void> => {
    setComments((comments) => {
      const updatedComments = comments.map((item) =>
        item.id === comment.id ? { ...item, ...comment } : item
      )
      dispatch(setPost({ id: post.id, comments: updatedComments }))
      return updatedComments
    })
    setCurrentComment(() => undefined)
  }

  const addComment = async (message: string): Promise<void> => {
    if (trim(message).length == 0) {
      return
    }
    const response = await api.posts.create({
      message,
      replyToId: post.id,
      category: post.category,
      media: commentMediaData.media,
    })
    addNewComment(response)
  }

  const addNewComment = (comment: Post) => {
    const newComments = [...comments, comment]
    setComments(newComments)
    setCommentToReply(undefined)
    post.comments = newComments
    dispatch(setPost({ id: post.id, comments: newComments }))
  }

  const renderItem: ListRenderItem<Post> = ({ item }: { item: Post }) => {
    const replyList = {} as CommentListRef
    const replyToComment = (): void => {
      if (!level) {
        replyList && replyList.replyTo && replyList.replyTo(item)
      } else {
        setCommentToReply(post)
      }
    }

    return (
      <View testID="comment-item-container">
        <CommentItem
          isPC={isPC}
          comment={item}
          currentUser={currentUser}
          isEditMode={item.id === currentComment?.id}
          socialActivityComponent={
            <CommentSocialBar
              comment={item}
              likeButtonComponent={
                <PostLikeButton post={item} showIcon={false} size="small" />
              }
              likeCountComponent={<PostLikeCount post={item} />}
              replyComponent={<ReplyButton onPress={replyToComment} />}
            />
          }
          postCommentForm={
            <PostCommentForm
              isEdit={true}
              postId={post.id}
              comment={currentComment}
              currentUser={currentUser}
              onUpdate={updateComment}
              onCancel={(): void => setCurrentComment(undefined)}
            />
          }
          controlMenu={
            <CommentControlMenu
              comment={item}
              onDelete={hideComment}
              currentUser={currentUser}
              onSelectEdit={(): void => setCurrentComment(item)}
            />
          }
        />
        {!level && (
          <View style={styles.replyList}>
            <CommentList
              post={item}
              level={(level || 0) + 1}
              reference={replyList}
              isReadOnly={isReadOnly}
            />
          </View>
        )}
      </View>
    )
  }

  const submitComment = async (text: string): Promise<void> => {
    if (currentComment) {
      updateComment({ ...currentComment, message: text })
    } else {
      addComment(text)
    }
    setCommentMediaData({})
  }

  const updateCommentMediaData = (data: Partial<CommentMediaData>): void => {
    setCommentMediaData({ ...commentMediaData, ...data })
  }

  reference && (reference.replyTo = setCommentToReply)

  useEffect(() => {
    setComments(orderBy(post.comments, ['id'], 'asc'))
  }, [post.comments])

  const shownData = isShowAll
    ? comments
    : comments?.slice(-defaultNumberOfComments)

  return (
    <View testID="comment-list" style={styles.container}>
      <FlatList
        style={
          !level &&
          (comments?.length > 0
            ? [styles.commentList, styles.commentListWithData]
            : styles.commentList)
        }
        data={shownData}
        extraData={currentComment}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          isShowAll ? undefined : (
            <Text
              style={styles.viewMore}
              testID="see-more-comment"
              onPress={(): void => setIsShowAll(true)}
            >
              {t('comment.viewMoreComments')}
            </Text>
          )
        }
      />
      <View>
        {!isEmpty(commentMediaData?.media) && (
          <CommentBoxMedia
            data={commentMediaData}
            onSubmit={submitComment}
            currentUser={currentUser}
            onChanged={updateCommentMediaData}
          />
        )}
      </View>
      {!level && !isReadOnly && (
        <CommentEditor
          user={currentUser}
          mediaPicker={
            <ImagePickerButton
              type="clear"
              kind="post"
              loading={isLoadingMedia}
              onLoading={setIsLoadingMedia}
              buttonStyle={styles.mediaPicker}
              onUpload={(media): void => updateCommentMediaData({ media })}
              icon={<Image style={styles.icon} source={imageIcon} />}
            />
          }
          onSubmitEditing={submitComment}
        />
      )}
      {level && commentToReply && !isReadOnly && (
        <PostCommentForm
          comment={{} as Post}
          onCreate={addNewComment}
          currentUser={currentUser}
          postId={commentToReply.id}
          onCancel={(): void => setCommentToReply(undefined)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  commentList: {
    backgroundColor: color.white,
    paddingHorizontal: 20,
  },
  commentListWithData: {
    paddingBottom: 10,
  },
  container: {
    flex: 1,
  },
  icon: {
    height: 15,
    width: 15,
  },
  mediaPicker: {
    marginRight: 8,
    padding: 0,
  },
  replyList: {
    marginLeft: 57,
  },
  viewMore: {
    color: color.pressableText,
    marginTop: 12,
  },
})

export default React.memo(CommentList)
