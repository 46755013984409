import { isEmpty, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextStyle, View } from 'react-native'

import api from '~/api'
import { CompanyBodyParams } from '~/api/companies'
import ListSeparator from '~/components/common/atoms/ListSeparator'
import TextField from '~/components/common/molecules/TextField'
import MultipleSelectDialog from '~/components/common/organisms/MultipleSelectDialog'
import Button from '~/components/workarounds/Button'
import CheckBox from '~/components/workarounds/CheckBox'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Business from '~/interfaces/Business'
import Industry from '~/interfaces/Industry'
import SelectListItem from '~/interfaces/SelectListItem'
import { SupportedLanguage, getCurrentLanguage } from '~/plugins/i18n'

type Props = {
  registrationData: Partial<CompanyBodyParams>
  onUpdateRegistrationData: (data: Partial<CompanyBodyParams>) => void
  onNext: () => void
  isPC?: boolean
}

export const getSelectDataByLanguage = (
  language: string,
  data: Business[] | Industry[]
): SelectListItem[] =>
  data.map((item) => ({
    id: item.id,
    name: language === SupportedLanguage.JA ? item.name : item.enName,
  }))

const CompanyForm: React.FC<Props> = ({
  registrationData,
  onUpdateRegistrationData,
  onNext,
  isPC,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const language = getCurrentLanguage()

  const [company, setCompany] = useState(registrationData.name ?? '')
  const [location, setLocation] = useState(registrationData.location || '')

  const [businesses, setBusinesses] = useState<Business[]>([])
  const [selectedBusiness, setSelectedBusiness] = useState<Business[]>([])
  const [industries, setIndustries] = useState<Industry[]>([])
  const [selectedIndustries, setSelectedIndustries] = useState<Industry[]>([])
  const [isAgreed, setIsAgreed] = useState(false)

  const fetchBusiness = async (): Promise<void> => {
    try {
      const response = await api.busineses.index<Business[], {}>({})
      setBusinesses(response)
    } catch (ex) {
      toast.showError(ex)
    }
  }

  useEffect(() => {
    fetchBusiness()
  }, [])

  const selectBusiness = (data: SelectListItem[]) => {
    const businessData = businesses.filter(
      (item) =>
        !isNil(data.find((selectListItem) => selectListItem.id === item.id))
    )
    setSelectedBusiness(businessData)
    setIndustries(businessData.flatMap((item) => item.industries))
    setSelectedIndustries([])
  }

  const selectIndustries = (data: SelectListItem[]) => {
    const industriesData = industries.filter(
      (item) =>
        !isNil(data.find((selectListItem) => selectListItem.id === item.id))
    )
    setSelectedIndustries(industriesData)
  }

  const updateRegistrationData = (): void => {
    const profileData: Partial<CompanyBodyParams> = {
      name: company,
      enName: company,
      location,
      industryIds: selectedIndustries.map((industry) => industry.id),
    }
    onUpdateRegistrationData(profileData)
    onNext()
  }

  const titleButtonStyle = {
    fontSize: FontSize.GENERAL,
  } as TextStyle

  const changeIsAgreed = () => {
    setIsAgreed(!isAgreed)
  }

  const isValid =
    !isEmpty(company?.trim()) &&
    !isEmpty(location.trim()) &&
    !isEmpty(selectedIndustries) &&
    isAgreed

  return (
    <View testID="company-form" style={styles.container}>
      <View style={isPC ? styles.wrapperPC : undefined} testID="wrapper-view">
        <TextField
          value={company}
          size={FontSize.GENERAL}
          onChangeText={setCompany}
          title={t('profile.companyName')}
        />
        <View style={styles.verticalSeparator} />
        <TextField
          value={location}
          size={FontSize.GENERAL}
          onChangeText={setLocation}
          title={t('profile.officeLocation')}
        />
        <View style={styles.verticalSeparator} />
        <Text style={styles.textFieldTitle}>{t('profile.business')}</Text>
        <MultipleSelectDialog
          isInvert
          isPC={isPC ?? true}
          onChange={selectBusiness}
          title={t('profile.business')}
          data={getSelectDataByLanguage(language, businesses)}
          placeholder={`${t('pleaseSelect')} ${t('profile.business')}`}
          selectedItems={getSelectDataByLanguage(language, selectedBusiness)}
        />
        <View style={styles.verticalSeparator} />
        <Text style={styles.textFieldTitle}>{t('profile.industry')}</Text>
        <MultipleSelectDialog
          isInvert
          isPC={isPC ?? true}
          onChange={selectIndustries}
          title={t('profile.industry')}
          data={getSelectDataByLanguage(language, industries)}
          placeholder={`${t('pleaseSelect')} ${t('profile.industry')}`}
          selectedItems={getSelectDataByLanguage(language, selectedIndustries)}
        />
        <ListSeparator />
      </View>
      <View style={styles.separator} />
      <CheckBox
        checked={isAgreed}
        onPress={changeIsAgreed}
        textStyle={styles.textCheckBox}
        wrapperStyle={styles.checkBoxWrapper}
        containerStyle={styles.checkBoxContainer}
        title={t('company.registrationAgreement')}
      />
      <Button
        title={t('next')}
        disabled={!isValid}
        testID="next-button"
        titleStyle={titleButtonStyle}
        onPress={updateRegistrationData}
        style={StyleSheet.flatten(styles.nextButton)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    borderColor: color.transparent,
    backgroundColor: color.transparent,
    borderWidth: 0,
    marginLeft: 0,
    padding: 0,
    alignSelf: 'baseline',
  },
  checkBoxWrapper: {
    paddingBottom: 10,
  },
  container: {
    marginHorizontal: 10,
  },
  nextButton: {
    marginBottom: 20,
    marginTop: 30,
  },
  separator: {
    marginTop: 30,
    marginBottom: 10,
    borderBottomColor: color.gray,
    borderBottomWidth: 1,
  },
  textCheckBox: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: '400',
  },
  textFieldTitle: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
  },
  verticalSeparator: {
    marginTop: 20,
  },
  wrapperPC: {
    width: '50%',
  },
})

export default CompanyForm
