import { ParamListBase } from '@react-navigation/routers'
import React from 'react'

import NotFound from '~/components/common/pages/NotFound'
import Notifications from '~/components/common/pages/Notifications'
import Settings from '~/components/common/pages/Settings'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'
import { mainCareerRoutes } from '~/utils/career/routes'
import { mainCommunityRoutes } from '~/utils/community/routes'
import { mainForumRoutes } from '~/utils/forum/routes'

export type Route<RouteName extends keyof ParamListBase> = {
  name: RouteName
  component: React.FC
  labelTranslationKey?: string
}

/**
 * Add router for another pages that is not display in Mobile BottomNavigator
 */
export const otherRoutes: Route<keyof OtherStackParamList>[] = [
  { name: 'notfound', component: NotFound },
  { name: 'settings', component: Settings },
  { name: 'notifications', component: Notifications },
]

export const mainRoutes: Route<keyof MainStackParamList>[] = [
  ...mainCareerRoutes,
  ...mainForumRoutes,
  ...mainCommunityRoutes,
]
