import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import {
  CommunityFilterKey,
  CommunityQueryParams,
} from '~/api/communities/communities'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import SearchNotFound from '~/components/common/organisms/SearchNotFound'
import CommunityList from '~/components/community/features/CommunityList'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams } from '~/utils/navigation'

const UserGuestCommunityList: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const queryParams = getRouteParams<CommunityQueryParams>()
  const [selectedFilter, setSelectedFilter] = useState<
    FilterPopupData | undefined
  >(undefined)

  const communityFilterPopupData: FilterPopupData[] = [
    {
      id: CommunityFilterKey.BY_ALL,
      name: t('community.community'),
    },
    {
      id: CommunityFilterKey.BY_SUGGEST,
      name: t('community.aroundMe'),
    },
  ]

  const getFilterDataByParams = (): FilterPopupData => {
    return (
      communityFilterPopupData.find((obj) => obj.id == queryParams.sortBy) ||
      communityFilterPopupData[0]
    )
  }

  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    setQueryParams({ sortBy: item.id as CommunityFilterKey })
  }

  useEffect(() => {
    changeSelectedFilter(getFilterDataByParams())
  }, [queryParams.sortBy])

  return (
    <View testID="landing-community">
      <View style={styles.filterContainer}>
        <View testID="community-filter-popup" style={styles.filterPopup}>
          <FilterPopupMenu
            selectedItem={selectedFilter}
            data={communityFilterPopupData}
            onSelect={changeSelectedFilter}
            menuTrigger={
              <Text style={styles.selectedFilter} testID="menu-trigger">
                {`${t('sortBy')}: ${selectedFilter?.name}`}
              </Text>
            }
          />
        </View>
      </View>
      <View style={styles.header}>
        <CommunityList
          params={{ ...queryParams, type: CommunityFilterKey.BY_ALL }}
          emptyView={
            <SearchNotFound
              title={t('community.searchNotFoundCommunity')}
              subtitle={t('community.searchNotFoundCommunityHint')}
            />
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  filterContainer: {
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
  filterPopup: {
    alignItems: 'flex-end',
    width: 940,
    padding: 10,
  },
  header: {
    marginTop: 5,
  },
  selectedFilter: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
  },
})

export default UserGuestCommunityList
