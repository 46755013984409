import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import AppService from '~/interfaces/AppService'
import User from '~/interfaces/User'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  item: AppService
  user?: User
}

export const SIZE_ICON = 24
const IMG_PC_SIZE = {
  width: SIZE_ICON,
  height: SIZE_ICON,
}

const CompanyServiceItem: React.FC<Props> = ({ item, user }: Props) => {
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const navigateToService = (): void => {
    item.screen === 'userDetail'
      ? navigate(item.screen, { id: user?.id })
      : navigate(item.screen)
  }

  return (
    <TouchableOpacity testID="company-service-item" onPress={navigateToService}>
      <View testID="service-item" style={styles.container}>
        <View style={styles.imageContainer}>
          <Image style={IMG_PC_SIZE} source={item.image} />
        </View>
        <View style={styles.body}>
          <Text
            style={StyleSheet.flatten([
              styles.description,
              styles.descriptionSelectable,
            ])}
          >
            {item.title}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  body: {
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    fontSize: FontSize.REMARKABLE,
    color: color.textGray,
  },
  descriptionSelectable: {
    fontSize: FontSize.SUB,
    paddingTop: 10,
  },
  imageContainer: {
    backgroundColor: color.white,
    alignSelf: 'flex-start',
    marginVertical: 5,
  },
})

export default CompanyServiceItem
