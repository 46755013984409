import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { UserSection } from '~/constants/common/navigation'
import User from '~/interfaces/User'

type Props = {
  isPC?: boolean
  followingsCount: number
  type?: 'header' | 'user-item'
  user?: User
}

const Following: React.FC<Props> = ({
  followingsCount,
  type,
  isPC,
  user,
}: Props) => {
  const { t } = useTranslation()
  const containerStyle = isPC ? styles.pc : styles.mobile
  const textColor = type === 'user-item' ? color.textGray : color.white

  return (
    <View style={containerStyle} testID="following">
      <TitleText
        style={StyleSheet.flatten([
          isPC ? styles.count : styles.countMobile,
          { color: textColor },
        ])}
        testID="following-count"
      >
        {followingsCount}
      </TitleText>
      <Link
        to={{
          screen: 'userDetail',
          params: { id: user?.id, section: UserSection.FOLLOWING },
        }}
      >
        <TitleText
          style={StyleSheet.flatten([
            isPC ? styles.title : styles.titleMobile,
            { color: textColor },
          ])}
        >
          {t('profile.following')}
        </TitleText>
      </Link>
    </View>
  )
}

const styles = StyleSheet.create({
  count: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
  countMobile: {
    color: color.white,
    fontSize: FontSize.SUB,
  },
  mobile: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  pc: {
    flexDirection: 'row',
  },
  title: {
    fontSize: FontSize.GENERAL,
    color: color.white,
    marginLeft: 5,
  },
  titleMobile: {
    fontSize: FontSize.SUB,
    color: color.white,
    marginLeft: 5,
  },
})

export default Following
