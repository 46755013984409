import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import CalendarPicker, {
  CalendarPickerProps,
} from 'react-native-calendar-picker'

import CardContainer from '~/components/common/atoms/CardContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import i18n from '~/plugins/i18n'

moment.suppressDeprecationWarnings = true

type Props = {
  title?: string
  size?: FontSize
  selectedDate?: string
  onChange: (value: string) => void
} & CalendarPickerProps

const DatePicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { title, size, selectedDate, onChange, initialDate } = props
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const selectedDateInMoment = moment(selectedDate).locale(i18n.language)
  const initialDateInMoment = moment(initialDate).locale(i18n.language)

  const changeDate = (day: moment.Moment): void => {
    onChange && onChange(day.toISOString())
    setIsShowDatePicker(false)
  }

  return (
    <View testID="date-picker">
      <Text style={StyleSheet.flatten([styles.title, { fontSize: size }])}>
        {title}
      </Text>
      <Text
        testID="date-picker-value"
        onPress={(): void => setIsShowDatePicker(true)}
        style={StyleSheet.flatten([styles.value, { fontSize: size }])}
      >
        {selectedDate ? selectedDateInMoment.format('L') : t('pleaseSelect')}
      </Text>
      <ModalContainer
        visible={isShowDatePicker}
        onDismiss={(): void => setIsShowDatePicker(false)}
      >
        <View style={styles.calendarLayout}>
          <CardContainer testID="calendar-container" style={styles.calendar}>
            <CalendarPicker
              {...props}
              startFromMonday={true}
              allowRangeSelection={true}
              width={320}
              onDateChange={changeDate}
              initialDate={
                !selectedDate && initialDate
                  ? initialDateInMoment.toDate()
                  : selectedDateInMoment.toDate()
              }
            />
          </CardContainer>
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  calendar: {
    padding: 20,
    backgroundColor: color.white,
    boxShadow: 'none',
  },
  calendarLayout: {
    backgroundColor: color.white,
    borderRadius: 5,
    height: 310,
  },
  title: {
    color: color.darkTitleText,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  value: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderColor: color.gray,
    borderWidth: 1,
    alignSelf: 'baseline',
    ...commonStyles.borderRadiusAllCorner,
  },
})

export default DatePicker
