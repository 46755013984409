import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import PostItemHeader from '~/components/career/molecules/post/PostItemHeader'
import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import DateText from '~/components/common/atoms/DateText'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import TextAvatar from '~/components/common/atoms/TextAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import SeeMoreText from '~/components/common/molecules/SeeMoreText'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Button from '~/components/workarounds/Button'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Share from '~/interfaces/Share'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  share: Share
}

const PostSharedContentBody: React.FC<Props> = ({ share }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const avatar = {
    question: <TextAvatar text="Q" size="small" color="secondary" />,
    post: <UserAvatar user={share.post?.user} size={ProfileAvatarSize.SMALL} />,
    answer: <></>,
  }

  const title = {
    question: (
      <Link
        style={styles.title}
        testID="post-question-title"
        to={`/forum/questions/${share.question?.id}`}
      >
        {share.question?.title}
      </Link>
    ),
    post: <UserName size="medium" user={share.post?.user} />,
    answer: <></>,
  }

  const body = {
    question: (
      <SeeMoreText style={styles.questionBody}>
        {share.question?.body}
      </SeeMoreText>
    ),
    post: (
      <>
        {(share.post?.images || share.post?.videos) && (
          <MediaArea entity={share.post} />
        )}
        <SeeMoreText style={styles.questionBody}>
          {share.post?.message}
        </SeeMoreText>
      </>
    ),
    answer: (
      <SeeMoreText style={styles.questionBody}>
        {share.answer?.body}
      </SeeMoreText>
    ),
  }

  const action = {
    question: (
      <Button
        title={t('forum.answer')}
        buttonStyle={styles.answerButton}
        containerStyle={styles.answerContainer}
        titleStyle={commonStyles.bodyTextSize}
        onPress={(): void =>
          navigate('questionShow', { id: share.question!.id })
        }
      />
    ),
    post: <></>,
    answer: <></>,
  }

  return (
    <View
      testID="post-shared-content-body"
      style={styles.questionBodyContainer}
    >
      <PostItemHeader
        titleView={title[share.type]}
        avatarView={avatar[share.type]}
        subTitleView={
          <DateText
            date={(share.post || share.question || share.answer)!.createdAt}
            fontSize={FontSize.SUB}
          />
        }
      />
      <View style={styles.body}>{body[share.type]}</View>
      {action[share.type]}
    </View>
  )
}

const styles = StyleSheet.create({
  answerButton: {
    height: 30,
  },
  answerContainer: {
    width: 100,
    height: 30,
    marginTop: 10,
  },
  body: {
    paddingVertical: 10,
  },
  questionBody: {
    ...commonStyles.bodyTextSize,
    color: color.darkTitleText,
    marginTop: 4,
  },
  questionBodyContainer: {
    marginHorizontal: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: color.lightGray,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    ...commonStyles.titleTextSize,
    color: color.pressableText,
    fontWeight: 'bold',
    paddingRight: 40,
    alignSelf: 'baseline',
  },
})

export default PostSharedContentBody
