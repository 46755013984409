import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import commonStyles from '~/constants/common/commonStyles'
import { RootContext } from '~/contexts/RootContext'
import {
  EDIT_RESUME_END_FORM_INDEX,
  EDIT_RESUME_START_FORM_INDEX,
} from '~/slices/career/resumes'

type Props = {
  indexOfForm?: number
  onSave?: () => void
  onCancel?: () => void
}

const EditResumeFooter: React.FC<Props> = ({
  onSave,
  onCancel,
  indexOfForm,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  const saveLabel =
    indexOfForm === EDIT_RESUME_END_FORM_INDEX ? t('complete') : t('next')
  const backButtonVisibility = indexOfForm !== EDIT_RESUME_START_FORM_INDEX
  const saveButtonStyle = backButtonVisibility
    ? styles.buttonSize
    : styles.buttonNextSize

  return (
    <View
      testID="edit-resume-footer"
      style={
        isPC
          ? styles.container
          : StyleSheet.flatten([styles.container, styles.containerMobileSize])
      }
    >
      {backButtonVisibility ? (
        <View
          style={styles.buttonContainer}
          testID="edit-resume-footer-cancel-container"
        >
          <Button
            type="outline"
            onPress={onCancel}
            title={t('back')}
            buttonStyle={saveButtonStyle}
            testID="edit-resume-footer-cancel"
          />
          <View style={styles.separator} />
        </View>
      ) : null}
      <View testID="edit-resume-footer-save-container" style={saveButtonStyle}>
        <Button
          type="solid"
          onPress={onSave}
          title={saveLabel}
          buttonStyle={styles.buttonNextSize}
          testID="edit-resume-footer-save"
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginRight: 10,
  },
  buttonNextSize: {
    height: 45,
    width: '100%',
    ...commonStyles.borderRadiusAllCorner,
  },
  buttonSize: {
    height: 45,
    width: 258,
    ...commonStyles.borderRadiusAllCorner,
  },
  container: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    width: '100%',
  },
  containerMobileSize: {
    width: 280,
  },
  separator: {
    marginTop: 10,
    width: 10,
  },
})

export default EditResumeFooter
