import React from 'react'
import {
  Modal,
  Platform,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
} from 'react-native'

import color from '~/constants/common/color'

type Props = {
  visible: boolean
  onDismiss?: () => void
  children: React.ReactNode
}

const ModalContainer: React.FC<Props> = ({
  visible,
  onDismiss,
  children,
}: Props) => (
  <Modal visible={visible} transparent={true} animationType="fade">
    <View style={styles.container} testID="modal-window-container">
      <TouchableWithoutFeedback onPress={onDismiss}>
        <View style={styles.background} testID="modal-window-background" />
      </TouchableWithoutFeedback>
      {children}
    </View>
  </Modal>
)

const styles = StyleSheet.create({
  background: {
    backgroundColor: color.mask,
    height: '100%',
    position: 'absolute',
    width: '100%',
    ...Platform.select({
      web: { cursor: 'inherit' },
    }),
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
})

export default ModalContainer
