import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import TextField from '~/components/common/molecules/TextField'
import color from '~/constants/common/color'
import WebsiteReference from '~/interfaces/WebsiteReference'

type Props = {
  index: number
  onChange: (value: Partial<WebsiteReference>, index: number) => void
  websiteReference: Partial<WebsiteReference>
  onPressClose: () => void
}

const WebsiteReferenceForm: React.FC<Props> = ({
  index,
  onChange,
  websiteReference,
  onPressClose,
}: Props) => {
  const { t } = useTranslation()

  const onChangeWebsite = (value: string): void => {
    onChange({ link: value }, index)
  }

  const onChangeWebsiteDescription = (value: string): void => {
    onChange({ description: value }, index)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onPressClose}
        style={styles.closeButton}
        testID={'website-reference-close-button'}
      >
        <Icon size={20} name="close" color={color.primary} />
      </TouchableOpacity>
      <View testID="website-reference-form">
        <TextField
          title={t('profile.website')}
          value={websiteReference.link}
          onChangeText={onChangeWebsite}
        />
        <TextField
          title={t('profile.websiteDescription')}
          value={websiteReference.description}
          onChangeText={onChangeWebsiteDescription}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    marginBottom: 20,
    flex: 1,
  },
})

export default WebsiteReferenceForm
