import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import CommentEditor from '~/components/career/molecules/post/CommentEditor'
import AnswerCommentControlMenu, {
  AnswerCommentAction,
} from '~/components/forum/features/answers/AnswerCommentControlMenu'
import AnswerCommentItem from '~/components/forum/molecules/answers/AnswerCommentItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useCustomToast from '~/hooks/useCustomToast'
import Comment from '~/interfaces/Comment'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { setAnswer } from '~/slices/forum/answers'

const keyExtractor = (item: Comment): string => item.id.toString()

export type Props = {
  answerId: number
  questionId: number
  answerComments: Comment[]
}

export const defaultNumberOfComments = 1

const AnswerCommentList: React.FC<Props> = ({
  answerId,
  questionId,
  answerComments,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const currentUser = useSelector(currentUserSelector)
  const [comments, setComments] = useState<Comment[]>([])
  const [editingComment, setEditingComment] = useState<Comment>()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const [isShowAll, setIsShowAll] = useState(
    answerComments.length <= defaultNumberOfComments
  )

  useEffect(() => {
    setComments(answerComments)
  }, [answerComments])

  const renderItem: ListRenderItem<Comment> = ({ item }: { item: Comment }) => (
    <AnswerCommentItem
      comment={item}
      commentControlMenu={
        item.user.id === currentUser?.id ? (
          <AnswerCommentControlMenu
            comment={item}
            answerId={answerId}
            questionId={questionId}
            onSelect={handleOnSelectComment}
          />
        ) : undefined
      }
    />
  )

  const handleOnSelectComment = (
    comment: Comment,
    action: AnswerCommentAction
  ): void => {
    switch (action) {
      case AnswerCommentAction.DELETE:
        {
          const newComments = comments.filter((item) => item.id !== comment.id)
          setComments(newComments)
          setEditingComment(undefined)
          dispatch(setAnswer({ id: answerId, comments: newComments }))
        }
        break
      case AnswerCommentAction.EDIT:
        setEditingComment(comment)
        break
    }
  }

  const addComment = async (text: string): Promise<void> => {
    try {
      const response = await api.forumComments.create({
        questionId,
        answerId,
        body: text,
      })
      const newComments = [
        ...comments,
        { ...response, user: currentUser as User },
      ]
      setComments(newComments)
      dispatch(setAnswer({ id: answerId, comments: newComments }))
    } catch (err) {
      toast.showError(err)
    }
  }

  const updateComment = async (text: string): Promise<void> => {
    try {
      //TODO call update api here

      // const response = await api.forumComments.update({
      //   id: editingComment?.id,
      //   body: text,
      // })
      comments.find((c) => c.id === editingComment?.id)!.body = text
      setEditingComment(undefined)
      setComments([...comments])
      dispatch(setAnswer({ id: answerId, comments }))
    } catch (err) {
      toast.showError(err)
    }
  }

  return (
    <>
      <FlatList
        data={isShowAll ? comments : comments.slice(-defaultNumberOfComments)}
        extraData={comments}
        renderItem={renderItem}
        testID="answer-comment-list"
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          isShowAll ? undefined : (
            <Text
              style={styles.viewMore}
              testID="see-more-comment"
              onPress={(): void => setIsShowAll(true)}
            >
              {t('comment.viewMoreComments')}
            </Text>
          )
        }
        disableVirtualization
      />
      {!isEmpty(accessToken) && (
        <CommentEditor
          onSubmitEditing={editingComment?.id ? updateComment : addComment}
          user={currentUser}
          comment={{ message: editingComment?.body } as Post}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  viewMore: {
    color: color.pressableText,
    marginTop: 10,
    paddingHorizontal: 20,
  },
})

export default AnswerCommentList
