import { HTTPClient } from '~/httpClient'
import CompanyMemberInvitation from '~/interfaces/CompanyMemberInvitation'

export type InvitationParams = {
  token?: string
}

export class CompanyMemberInvitationAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async create(
    email: string,
    companyId?: number
  ): Promise<CompanyMemberInvitation> {
    const path = `/companies/${companyId}/member_invitations`
    return await this.client.post<CompanyMemberInvitation>(path, { email })
  }

  async show(
    { token }: InvitationParams,
    companyId?: number
  ): Promise<CompanyMemberInvitation> {
    const path = `/companies/${companyId}/member_invitations/find_invitation?invitation_token=${token}`
    return await this.client.get<CompanyMemberInvitation>(path)
  }
}
