import { RefObject } from 'react'
import Toast, { useToast } from 'react-native-toast-notifications'

import { getErrorMessage } from '~/utils/common/string'

const useCustomToast = () => {
  const toast = useToast()

  const showError = (message: unknown, toastRef?: RefObject<Toast>) => {
    if (message instanceof Error && message.name === 'AbortError') {
      return
    }
    if (toastRef) {
      return toastRef.current?.show(getErrorMessage(message), {
        type: 'danger',
      })
    }
    return toast.show(getErrorMessage(message), {
      type: 'danger',
    })
  }

  const showSuccess = (message: string, toastRef?: RefObject<Toast>) => {
    if (toastRef) {
      return toastRef.current?.show(message, {
        type: 'success',
      })
    }
    return toast.show(message, {
      type: 'success',
    })
  }

  return { showError, showSuccess }
}

export default useCustomToast
