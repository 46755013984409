import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import EditAvatar from '~/components/common/atoms/EditAvatar'
import ProfileAvatar, {
  ProfileAvatarSize,
} from '~/components/common/atoms/ProfileAvatar'
import Community from '~/interfaces/Community'
import Media from '~/interfaces/Media'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  editable?: boolean
  clickable?: boolean
  showBorder?: boolean
  community?: Community
  size: ProfileAvatarSize
  onUpload?: (media: Media[]) => void
}

const CommunityAvatar: React.FC<Props> = ({
  community,
  showBorder,
  size,
  editable,
  onUpload,
  clickable,
}: Props) => {
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const uri = community?.avatar?.urls?.small
  const avatar = uri
    ? { uri }
    : require('~/assets/images/communities/community_default.png')

  const navigateToCommunityPage = (): void => {
    community && navigate('communityDetail', { id: community.id })
  }

  return (
    <TouchableOpacity
      onPress={navigateToCommunityPage}
      testID="community-avatar"
      disabled={!clickable}
    >
      <ProfileAvatar hasBorder={showBorder} source={avatar} size={size} />
      {editable && (
        <View
          style={StyleSheet.flatten([
            {
              width: size,
              height: size,
              borderRadius: size,
            },
            styles.crop,
          ])}
        >
          <EditAvatar onUpload={onUpload} />
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  crop: {
    overflow: 'hidden',
    position: 'absolute',
  },
})

export default CommunityAvatar
