import React from 'react'
import { useTranslation } from 'react-i18next'

import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  isPC: boolean
  job: Partial<Recruitment>
  editable?: boolean
  onSubmit?: (value: string) => void
}

const JobResponsibilities: React.FC<Props> = ({
  job,
  isPC,
  ...rest
}: Props) => {
  const { t } = useTranslation()

  return (
    <JobInfoBaseLayout
      isPC={isPC}
      title={t('jobs.jobResponsibilities')}
      content={job.job}
      {...rest}
    />
  )
}

export default JobResponsibilities
