import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  useFocusEffect,
  useLinkTo,
  useNavigation,
} from '@react-navigation/native'
import React, { useContext, useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import Footer from '~/components/common/organisms/Footer'
import ServiceList from '~/components/common/organisms/ServiceList'
import LandingTemplate from '~/components/common/templates/LandingTemplate'
import { currentRoutePathKey } from '~/constants/common/navigation'
import { RootContext } from '~/contexts/RootContext'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { RootStackNavigationProp } from '~/utils/navigation'

const TopPage: React.FC = () => {
  const { isPC } = useContext(RootContext)
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const currentUser = useSelector(currentUserSelector)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const linkTo = useLinkTo()

  const redirectToCurrentRoute = async () => {
    const currentRoute = await AsyncStorage.getItem(currentRoutePathKey)
    if (currentRoute) {
      linkTo(currentRoute)
      AsyncStorage.removeItem(currentRoutePathKey)
    }
  }

  const redirectToCompanyPanel = () => {
    currentUser?.isCompanyAccount
      ? navigate('careerMain', {
          screen: 'companyPanel',
        })
      : null
  }

  useEffect(() => {
    redirectToCurrentRoute()
  }, [])

  useFocusEffect(() => {
    redirectToCompanyPanel()
  })

  return (
    <DefaultLayout>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        testID="top-page"
      >
        {accessToken ? (
          <View style={styles.container}>
            <ServiceList isTopPage={true} isPC={isPC ?? true} />
          </View>
        ) : (
          <LandingTemplate />
        )}
        <Footer />
      </ScrollView>
    </DefaultLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 60,
  },
})
export default TopPage
