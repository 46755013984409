import React from 'react'
import { StyleSheet, View } from 'react-native'

import PostItem from '~/components/career/organisms/posts/PostItem'
import MediaViewerDialog from '~/components/common/molecules/MediaViewerDialog'
import { useMediaViewerContext } from '~/contexts/MediaViewerContext'
import Post from '~/interfaces/Post'

type Props = {
  isPC: boolean
  renderPostItemControlMenu: (item: Post, cb?: Function) => React.ReactElement
  renderSocialActivity: (item: Post) => React.ReactElement
  renderPostCommentList: (item: Post) => React.ReactElement
}

const PostMediaViewerDialog: React.FC<Props> = ({
  isPC,
  renderPostItemControlMenu,
  renderSocialActivity,
  renderPostCommentList,
}) => {
  const { entity, dismissDialog, media } = useMediaViewerContext()

  if (!media?.length || !entity) {
    return null
  }

  return (
    <View testID="post-media-viewer-dialog">
      <MediaViewerDialog isPC={isPC}>
        <PostItem
          post={{ ...entity, images: [], videos: [] } as Post}
          isPC={isPC}
          controlMenu={renderPostItemControlMenu(entity as Post, dismissDialog)}
        />
        {renderSocialActivity(entity as Post)}
        <View style={styles.commentList}>
          {renderPostCommentList(entity as Post)}
        </View>
      </MediaViewerDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  commentList: {
    paddingHorizontal: 16,
  },
})

export default PostMediaViewerDialog
