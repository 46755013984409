import { FontAwesome as Icon } from '@expo/vector-icons'
import { find } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'

import api from '~/api'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import UsersFilter from '~/components/common/features/UsersFilter'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import User from '~/interfaces/User'

type Props = {
  content: string
  onFinish?: () => void
}

const MessageToUserPopup: React.FC<Props> = ({ content, onFinish }: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [recipients, setRecipients] = useState<User[]>([])

  const shareMessageToUser = useCallback(
    async (user: User) => {
      try {
        await api.chatMessages.directMessage({
          chatMessage: { body: content, userIds: [user.id] },
        })
        setRecipients((recipients) => [...recipients, user])
      } catch (error) {
        toast.showError(error)
      }
    },
    [setRecipients]
  )

  const renderSendButton = (user: User) => {
    const isSent = !!find(recipients, { id: user.id })

    return (
      <Button
        disabled={isSent}
        testID="message-to-user-button"
        title={isSent ? t('sent') : t('send')}
        titleStyle={commonStyles.buttonTextSize}
        onPress={async (): Promise<void> => shareMessageToUser(user)}
      />
    )
  }

  return (
    <ModalContainer visible={true} onDismiss={onFinish}>
      <ScrollView
        style={styles.container}
        testID="message-to-user-popup"
        contentContainerStyle={styles.containerContent}
      >
        <TitleText>{t('post.shareToMessage')}</TitleText>
        <View style={styles.separator} />
        <UsersFilter renderRightButton={renderSendButton} />
        <View style={styles.crossIcon}>
          <Icon color={color.gray} size={20} name="times" onPress={onFinish} />
        </View>
      </ScrollView>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    height: 320,
    maxWidth: 350,
    flex: 1,
    backgroundColor: color.white,
    ...commonStyles.borderRadiusAllCorner,
  },
  containerContent: {
    alignItems: 'center',
  },
  crossIcon: {
    position: 'absolute',
    right: 4,
    top: 0,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: color.gray,
    marginVertical: 20,
  },
})

export default MessageToUserPopup
