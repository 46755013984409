import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ChartData } from 'react-native-chart-kit/dist/HelperTypes'
import { useSelector } from 'react-redux'

import { CompanyApplicantStatisticsParams } from '~/api/companyApplicantStatistic'
import ApplicantStatisticsInfo from '~/components/career/organisms/company/ApplicantStatisticsInfo'
import StatisticsBarChart from '~/components/career/organisms/company/StatisticsBarChart'
import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import YearPicker from '~/components/common/atoms/YearPicker'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import CompanyApplicantStatistic from '~/interfaces/CompanyApplicantStatistic'
import { RootState } from '~/rootReducer'

const START_YEAR = 2018
const chartColors = Array.from({ length: 12 }, () => () => color.primary)

const ApplicantStatistics: React.FC = () => {
  const toast = useCustomToast()
  const currentYear = new Date().getFullYear()
  const currentCompany = useSelector(
    (state: RootState) => state.company.current
  )
  const { isPC } = useDevice()
  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [isLoading, setIsLoading] = useState(false)
  const [statistic, setStatistic] = useState<CompanyApplicantStatistic[]>([])

  const fetchStatistics = useAPI(
    async (api, companyId: number, year: number) => {
      if (isLoading) return
      setIsLoading(true)
      try {
        const response = await api.companyApplicantStatistics
          .configPath(companyId)
          .index<CompanyApplicantStatistic[], CompanyApplicantStatisticsParams>(
            {
              year,
            }
          )
        setStatistic(response)
      } catch (e) {
        toast.showError(e)
      }
      api.isAlive && setIsLoading(false)
    }
  )

  useEffect(() => {
    currentCompany && fetchStatistics(currentCompany!.id, selectedYear)
  }, [currentCompany?.id, selectedYear, fetchStatistics])

  const getChartData = (rawData: CompanyApplicantStatistic[]): ChartData => {
    const monthlyData: number[] = new Array(12).fill(0)
    for (const item of rawData) {
      monthlyData[moment(item.createdAt).month()] += 1
    }
    return {
      labels: monthlyData.map((v, index) => t(`monthName.${index + 1}`)),
      datasets: [
        {
          data: monthlyData,
          colors: chartColors,
        },
      ],
    }
  }

  return (
    <View
      testID="applicant-statistics"
      style={isPC ? {} : styles.containerMobile}
    >
      <CardContainer style={styles.charContainer}>
        <View style={styles.chartHeader}>
          <TitleText style={styles.chartTitle}>
            {t('company.dashboard.applicantsDataMenu')}
          </TitleText>
          <View style={styles.yearContainer}>
            <YearPicker
              fromYear={START_YEAR}
              maxYear={currentYear}
              onChange={setSelectedYear}
              selectedValue={selectedYear}
            />
          </View>
        </View>
        {!isLoading && <StatisticsBarChart data={getChartData(statistic)} />}
      </CardContainer>
      <ApplicantStatisticsInfo statistics={statistic} isPC={isPC} />
    </View>
  )
}

const styles = StyleSheet.create({
  charContainer: {
    backgroundColor: color.white,
  },
  chartHeader: {
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chartTitle: {
    color: color.textGray,
  },
  containerMobile: {
    padding: 20,
  },
  yearContainer: {
    width: 90,
  },
})

export default ApplicantStatistics
