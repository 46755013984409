import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import InterestedGroup from '~/components/career/molecules/resume/InterestedGroup'
import ResumeAvatar from '~/components/career/molecules/resume/templates/ResumeAvatar'
import ResumeUserProfile from '~/components/career/molecules/resume/templates/ResumeUserProfile'
import TemplateBody from '~/components/career/organisms/resumes/TemplateBody'
import TemplateFooter from '~/components/career/organisms/resumes/TemplateFooter'
import TemplateHeader from '~/components/career/organisms/resumes/TemplateHeader'
import AchievementGroup from '~/components/career/organisms/resumes/templates/AchievementGroup'
import ContactGroup from '~/components/career/organisms/resumes/templates/ContactGroup'
import EducationGroup from '~/components/career/organisms/resumes/templates/EducationGroup'
import UserLanguages from '~/components/career/organisms/resumes/templates/UserLanguages'
import UserSkills from '~/components/career/organisms/resumes/templates/UserSkills'
import WorkExperienceGroup from '~/components/career/organisms/resumes/templates/WorkExperienceGroup'
import color from '~/constants/common/color'
import Resume from '~/interfaces/Resume'
import { getResumePageData } from '~/utils/career/resume'

type Props = {
  resume: Partial<Resume>
}

const FormalTemplate: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()

  const resumePageData = getResumePageData(resume)

  const renderFirstPage = () => {
    return (
      <View style={styles.fillParent}>
        {separator}
        <EducationGroup
          templateType={resume.templateType!}
          educations={resumePageData?.first?.educations ?? []}
        />
        {separator}
        <WorkExperienceGroup
          templateType={resume.templateType!}
          workExperiences={resumePageData?.first?.workExperiences ?? []}
        />
        {isEmpty(resumePageData?.first?.achievements) ? null : separator}
        <AchievementGroup
          templateType={resume.templateType!}
          achievements={resumePageData?.first?.achievements ?? []}
        />
        {separator}
        <InterestedGroup
          templateType={resume.templateType!}
          value={resume.interestedIndustry || ''}
          reason={resume.interestedIndustryReason || ''}
          categoryTitle={t('resume.interestFieldsTitle')}
        />
        {separator}
        <InterestedGroup
          templateType={resume.templateType!}
          value={resume.interestedJob || ''}
          reason={resume.interestedJobReason || ''}
          categoryTitle={t('resume.interestJobsTitle')}
        />
      </View>
    )
  }

  const separator = (
    <>
      <View style={styles.separator} />
      <View style={styles.subSeparator} />
    </>
  )

  return (
    <View style={styles.fillParent} testID="formal-template">
      <View style={styles.partContainer}>
        <TemplateHeader
          templateType={resume.templateType!}
          leftColumn={<ResumeAvatar resume={resume} />}
          rightColumn={
            <View style={styles.headerRightColumn}>
              <ResumeUserProfile resume={resume} />
              <ContactGroup resume={resume} />
            </View>
          }
        />
        <TemplateBody
          templateType={resume.templateType!}
          rightColumn={renderFirstPage()}
        />
        <View style={styles.fillParent} />
        <TemplateFooter>
          <View style={styles.footerContent}>
            <UserSkills resume={resume} />
            <UserLanguages resume={resume} />
          </View>
        </TemplateFooter>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  fillParent: {
    flex: 1,
  },
  footerContent: {
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-between',
    paddingHorizontal: 40,
    alignItems: 'baseline',
  },
  headerRightColumn: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  partContainer: {
    width: '100%',
  },
  separator: {
    borderBottomWidth: 2,
    borderBottomColor: color.black,
  },
  subSeparator: {
    borderBottomWidth: 5,
    width: 80,
    borderBottomColor: color.black,
  },
})

export default FormalTemplate
