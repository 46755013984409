import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import api from '~/api'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import SubscriptionSuccess from '~/components/common/molecules/subscriptions/SubscriptionSuccess'
import CheckoutCoverSection from '~/components/common/organisms/subscriptions/CheckoutCoverSection'
import OrderSummarySection from '~/components/common/organisms/subscriptions/OrderSummarySection'
import PaymentMethodSection from '~/components/common/organisms/subscriptions/PaymentMethodSection'
import ReviewOrderSection from '~/components/common/organisms/subscriptions/ReviewOrderSection'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { fetchCurrentUser } from '~/slices/common/users'
import { navigate } from '~/utils/navigation'

type Props = {
  selectedPlan: SubscriptionPlan
  changePlan?: () => void
}

const SecureCheckoutTemplate: React.FC<Props> = ({
  changePlan,
  selectedPlan,
}: Props) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toast = useCustomToast()
  const elements = useElements()
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const [isShowSucessDialog, setIsShowSuccessDialog] = useState(false)

  const hideSubscriptionSuccessDialog = () => {
    setIsShowSuccessDialog(false)
  }

  const submitSubscription = async () => {
    if (isLoading || isNil(stripe) || isNil(elements)) {
      return
    }
    setIsLoading(true)

    try {
      const result = await stripe!.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      })

      if (result?.error) {
        toast.showError(`Payment failed: ${result.error.message}`)
        return
      }
      await api.subscriptionPlans.create({
        paymentMethodId: result.paymentMethod.id,
        planId: selectedPlan.id,
      })
      dispatch(fetchCurrentUser())
      setIsShowSuccessDialog(true)
    } catch (error) {
      toast.showError(`Error processing subscription: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  const navigateToJobsDashboard = () => {
    hideSubscriptionSuccessDialog()
    navigate('careerMain', {
      screen: 'jobsDashboard',
    })
  }

  return (
    <View style={styles.container} testID="secure-checkout-template">
      <Text style={styles.title}>{t('subscriptions.secureCheckoutTitle')}</Text>
      <CheckoutCoverSection isPC={isPC} />
      <View style={styles.separator} />
      <OrderSummarySection
        plan={selectedPlan}
        onPressAnotherOption={changePlan}
      />
      <View style={styles.separator} />
      <PaymentMethodSection isPC={isPC} />
      <View style={styles.separator} />
      <ReviewOrderSection
        plan={selectedPlan}
        submitable={!isLoading}
        onSubmit={submitSubscription}
      />
      <ModalContainer visible={isShowSucessDialog}>
        <View
          style={StyleSheet.flatten([
            styles.dialogContainer,
            isPC ? null : styles.dialogContainerMobile,
          ])}
          testID="subscription-success-dialog"
        >
          <SubscriptionSuccess />
          <Button
            title={t('ok')}
            testID="subscription-done-button"
            onPress={navigateToJobsDashboard}
            buttonStyle={styles.messageDialogButton}
          />
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '90%',
    maxWidth: 920,
    marginBottom: 30,
  },
  dialogContainer: {
    backgroundColor: color.white,
    ...commonStyles.borderRadiusAllCorner,
    padding: 30,
    alignItems: 'center',
  },
  dialogContainerMobile: {
    width: 370,
  },
  messageDialogButton: {
    width: 120,
    marginTop: 20,
    backgroundColor: color.primary,
    ...commonStyles.borderRadiusAllCorner,
  },
  separator: {
    marginBottom: 40,
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    marginBottom: 10,
  },
})

export default SecureCheckoutTemplate
