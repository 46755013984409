import * as Linking from 'expo-linking'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import { ShareBodyParams } from '~/api/shares'
import MessageToUserPopup from '~/components/career/features/messages/MessageToUserPopup'
import PostItemShare, {
  PostItemShareAction,
} from '~/components/career/molecules/post/PostItemShare'
import PostSharingPopup from '~/components/career/molecules/post/PostSharingPopup'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Post from '~/interfaces/Post'
import Share from '~/interfaces/Share'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  post: Post
  onShared?: (share: Share) => void
}

const PostSharing: React.FC<Props> = ({ post, onShared }: Props) => {
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const toast = useCustomToast()
  const [action, setAction] = useState<PostItemShareAction | undefined>()
  const [sharing, setSharing] = useState(false)

  const closePopup = (): void => setAction(undefined)

  const sharePostToTimeline = async (p: Post): Promise<void> => {
    try {
      setSharing(true)
      const response = await api.shares.create<ShareBodyParams, Share>({
        contentId: post.id,
        shareType: 'post',
        message: p.message,
      })
      setSharing(false)
      closePopup()
      if (response?.toPost) {
        response.toPost.comments = []
        onShared && onShared({ ...response, post })
      }
    } catch (error) {
      toast.showError(error)
      setSharing(false)
    }
  }

  return (
    <View testID="post-sharing">
      <PostItemShare onSelect={setAction} />
      {PostItemShareAction.TIMELINE === action && (
        <PostSharingPopup
          isPC={isPC}
          post={post}
          currentUser={currentUser!}
          editable={!sharing}
          onDismiss={closePopup}
          onSubmit={sharePostToTimeline}
        />
      )}
      {PostItemShareAction.AS_MESSAGE === action && (
        <MessageToUserPopup
          content={Linking.createURL(`/posts/${post.id}`)}
          onFinish={closePopup}
        />
      )}
    </View>
  )
}

export default PostSharing
