import { range, uniqueId } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import FormLayout from '~/components/career/molecules/resume/FormLayout'
import LanguageForm from '~/components/common/molecules/LanguageForm'
import color from '~/constants/common/color'
import ResumeLanguage from '~/interfaces/ResumeLanguage'

type Props = {
  isPC: boolean
  onChange: (language: Partial<ResumeLanguage>[]) => void
  languages: Partial<ResumeLanguage & { key?: string }>[]
  resumeLanguage: string
}

const LanguageFormList: React.FC<Props> = ({
  isPC,
  onChange,
  languages,
  resumeLanguage,
}: Props) => {
  const { t } = useTranslation()
  const newItemIndex = languages.findIndex((item) => {
    return !item.id
  })

  const handleChangeLanguageValue = (
    language: Partial<ResumeLanguage>,
    index: number
  ): void => {
    const newLanguage = { ...languages[index], ...language }
    const newLanguages = languages.slice()
    newLanguages.splice(index, 1, newLanguage)
    onChange(newLanguages)
  }

  const addNewLanguageForm = (): void => {
    onChange(languages.concat([{ key: `${uniqueId('language-')}` }]))
  }

  const removeForm = (index: number): void => {
    const newLanguages = languages.slice()
    newLanguages.splice(index, 1)
    onChange(newLanguages)
  }

  const languageForms = range(languages.length).map((index) => {
    const languageKey =
      languages[index].id !== undefined
        ? `${languages[index].id}`
        : `${languages[index].key}`

    return (
      <View key={languageKey}>
        {index === newItemIndex && <View style={styles.separator} />}
        <LanguageForm
          index={index}
          language={languages[index]}
          resumeLanguage={resumeLanguage}
          onChange={handleChangeLanguageValue}
          onPressClose={() => {
            removeForm(index)
          }}
        />
      </View>
    )
  })

  return (
    <FormLayout
      title={t('profile.language')}
      hasAddAction={true}
      isPC={isPC}
      onAddPress={addNewLanguageForm}
    >
      <View style={styles.formContainer}>{languageForms}</View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    paddingBottom: 40,
    paddingTop: 10,
  },
  separator: {
    backgroundColor: color.borderSeparator,
    height: 1,
    marginBottom: 20,
  },
})

export default LanguageFormList
