import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  ImageSourcePropType,
} from 'react-native'

import communityDefaultCoverBusiness from '~/assets/images/communities/business.png'
import communityDefaultCoverHobby from '~/assets/images/communities/hobby.png'
import communityDefaultCoverOrganizations from '~/assets/images/communities/organizations.png'
import communityDefaultCoverSchool from '~/assets/images/communities/school_universities.png'
import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import CommunityItemContent from '~/components/community/molecules/CommunityItemContent'
import CommunityAdminFeedbackDialog from '~/components/community/organisms/CommunityAdminFeedbackDialog'
import CommunityCancelDialog from '~/components/community/organisms/CommunityCancelDialog'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Community, { CommunityCategory } from '~/interfaces/Community'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isPC: boolean
  community: Community
  status?: string
  onCancel?: (communityId: number) => void
}

enum CommunityControlMenuType {
  VIEW = 'view',
  CANCEL = 'cancel',
  VIEW_ADMIN_FEEDBACK = 'view-admin-feedback',
}

const CommunityItem: React.FC<Props> = ({
  isPC,
  community,
  status,
  onCancel,
}: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState<boolean>(
    false
  )
  const [
    shouldAdminFeedbackResultDialog,
    setShouldAdminFeedbackResultDialog,
  ] = useState<boolean>(false)

  const defaultImageByCategoryCommunity = (): ImageSourcePropType => {
    switch (community.category) {
      case CommunityCategory.Business:
        return communityDefaultCoverBusiness
      case CommunityCategory.Hobby:
        return communityDefaultCoverHobby
      case CommunityCategory.Organization:
        return communityDefaultCoverOrganizations
      default:
        return communityDefaultCoverSchool
    }
  }

  const [imageSource, setImageSource] = useState<ImageSourcePropType>(
    community?.cover
      ? { uri: community.cover.urls.original }
      : defaultImageByCategoryCommunity
  )

  const navigateToCommunityPage = (): void => {
    community && navigate('communityDetail', { id: community.id })
  }

  const showDefaultImage = (): void => {
    setImageSource(defaultImageByCategoryCommunity)
  }

  const controlMenuData: ControlMenuData[] =
    status === 'reject'
      ? [
          {
            id: CommunityControlMenuType.VIEW_ADMIN_FEEDBACK,
            title: t('community.viewAdminFeedback'),
          },
        ]
      : [
          {
            id: CommunityControlMenuType.VIEW,
            title: t('community.viewCommunity'),
          },
          {
            id: CommunityControlMenuType.CANCEL,
            title: t('community.cancelCreationOfCommunity'),
          },
        ]

  const onSelectChangeControlMenu = (item: ControlMenuData) => {
    switch (item.id) {
      case CommunityControlMenuType.VIEW:
        navigateToCommunityPage()
        break
      case CommunityControlMenuType.CANCEL:
        setIsCancelDialogVisible(true)
        break
      case CommunityControlMenuType.VIEW_ADMIN_FEEDBACK:
        setShouldAdminFeedbackResultDialog(true)
        break
      default:
    }
  }

  return (
    <View
      style={StyleSheet.flatten([
        styles.container,
        isPC ? styles.containerSizePC : styles.containerSizeMobile,
      ])}
      testID="community-item"
      key={community.id}
    >
      {(status === 'pending' || status === 'reject') && (
        <View style={styles.controlMenu}>
          <ControlMenu
            data={controlMenuData}
            preferredPlacement="left"
            onSelect={onSelectChangeControlMenu}
            menuTrigger={<ControlMenuButton inverted={true} />}
          />
        </View>
      )}
      <TouchableOpacity
        testID="community-image-container"
        onPress={navigateToCommunityPage}
      >
        <Image
          resizeMode="cover"
          source={imageSource}
          testID="community-image"
          onError={showDefaultImage}
          style={StyleSheet.flatten(styles.communityAvatar)}
        />
      </TouchableOpacity>
      <CommunityItemContent community={community} />
      <CommunityCancelDialog
        isVisible={isCancelDialogVisible}
        dismissDialog={() => setIsCancelDialogVisible(false)}
        community={community}
        onCancel={onCancel}
      />
      <CommunityAdminFeedbackDialog
        isVisible={shouldAdminFeedbackResultDialog}
        onPressDismiss={() => setShouldAdminFeedbackResultDialog(false)}
        community={community}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  communityAvatar: {
    backgroundColor: color.gray,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 135,
    width: '100%',
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 5,
    marginHorizontal: 5,
    ...commonStyles.shadow,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
    alignSelf: 'center',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  controlMenu: {
    position: 'absolute',
    top: 5,
    right: 10,
    zIndex: 1,
  },
})

export default CommunityItem
