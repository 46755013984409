export enum ChannelControlMenuType {
  ShowMembers = 'ShowMembers',
  ShowTimeLine = 'ShowTimeline',
  EditChannel = 'EditChannel',
  CreateChannel = 'CreateChannel',
  DeleteChannel = 'DeleteChannel',
}

export type ChannelControlMenuProps = {
  isPC: boolean
  isAdmin?: boolean
  onSelect?: (selected: ChannelControlMenuType) => void
  isPublic?: boolean
}
