import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity } from 'react-native'

import LikeIcon from '~/components/common/atoms/socials/LikeIcon'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  liked?: boolean
  disabed?: boolean
  isShowIcon?: boolean
  onPress?: () => void
  size?: 'medium' | 'small'
}

const LikeButton: React.FC<Props> = ({
  size = 'medium',
  liked,
  onPress,
  disabed,
  isShowIcon,
}: Props) => {
  const { t } = useTranslation()

  const mainColor = liked ? color.primary : color.textGray
  const textStyle = {
    medium: StyleSheet.flatten([
      styles.likeText,
      { color: mainColor, fontSize: FontSize.GENERAL },
    ]),
    small: StyleSheet.flatten([
      styles.likeText,
      { color: mainColor, fontSize: FontSize.SUB },
    ]),
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabed}
      testID="like-button"
      style={styles.container}
    >
      {isShowIcon && <LikeIcon liked={liked} />}
      <Text style={textStyle[size]} testID="like-button-text">
        {liked ? t('post.unlike') : t('post.like')}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 4,
  },
  likeText: {
    ...commonStyles.subTextSize,
    alignSelf: 'center',
    marginLeft: 4,
    fontSize: FontSize.GENERAL,
  },
})

export default LikeButton
