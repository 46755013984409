import { range } from 'lodash'
import React from 'react'
import { View, ViewStyle } from 'react-native'

import CheckBox from '~/components/workarounds/CheckBox'

type Props = {
  data: string[]
  selectedIndex?: number
  orientation: 'horizontal' | 'vertical'
  onSelect: (index: number) => void
}

const RadioGroup: React.FC<Props> = ({
  data,
  onSelect,
  orientation,
  selectedIndex,
}) => (
  <View testID="radio-group" style={styles[orientation] as ViewStyle}>
    {range(0, data.length).map((item) => (
      <CheckBox
        center
        key={item}
        onPress={(): void => onSelect(item)}
        title={data[item]}
        uncheckedIcon="circle-o"
        checkedIcon="dot-circle-o"
        checked={selectedIndex === item}
      />
    ))}
  </View>
)

const styles = {
  horizontal: {
    flexDirection: 'row',
    flex: 1,
  },
  vertical: {
    flex: 1,
  },
}

export default RadioGroup
