import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { currencyFormat } from '~/utils/common/string'
import { getPriceSummary } from '~/utils/common/subscription'

type Props = {
  submitable?: boolean
  onSubmit?: () => void
  plan: SubscriptionPlan
}

const ReviewOrderSection: React.FC<Props> = ({
  plan,
  onSubmit,
  submitable = true,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="review-order-section">
      <Text style={styles.title}>{t('subscriptions.reviewOrderTitle')}</Text>
      <CardContainer style={styles.cardContainer}>
        <Text style={styles.contentText}>
          {`- ${t('subscriptions.secureCheckout.reviewOrderOption1', {
            summaryHeadContent: getPriceSummary(t, plan),
            cost: currencyFormat(plan.price),
          })}`}
        </Text>
        <Text style={styles.contentText} testID="review-over-option2">
          {`- ${t('subscriptions.secureCheckout.reviewOrderOption2', {
            date: moment()
              .add(plan.period === 'month' ? 30 : 365, 'days')
              .format('LL'),
          })}`}
        </Text>
        <View style={styles.separator} />
        <Text style={styles.contentText}>
          {t('subscriptions.secureCheckout.reviewOrderOption3')}
        </Text>
        <Button
          onPress={onSubmit}
          disabled={!submitable}
          loading={!submitable}
          disabledStyle={styles.orderButton}
          buttonStyle={styles.orderButton}
          testID="review-order-submit-button"
          title={t('subscriptions.placeOrderButton')}
        />
      </CardContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'space-between',
    padding: 30,
    backgroundColor: color.white,
  },
  contentText: {
    fontSize: 16,
    marginBottom: 20,
  },
  orderButton: {
    width: 160,
    marginTop: 10,
    backgroundColor: color.primary,
    ...commonStyles.borderRadiusAllCorner,
  },
  separator: {
    borderColor: color.lightGray,
    borderBottomWidth: 1,
    marginVertical: 10,
    marginBottom: 30,
  },
  title: {
    fontSize: 25,
    marginBottom: 24,
    fontWeight: 'bold',
  },
})

export default ReviewOrderSection
