import { BaseAPI } from '~/api/baseAPI'

export type AddChannelMemberParams = {
  userId: number
  isAdmin: boolean
  communityChannelId: number
}

export class CommunityChanelMembersAPI extends BaseAPI {
  path = '/community_channel_members'
}
