import React from 'react'
import { StyleSheet, View, Text } from 'react-native'

import TextAvatar from '~/components/common/atoms/TextAvatar'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Answer from '~/interfaces/Answer'
import i18next from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  answer: Answer
  answerControlMenu?: React.ReactElement
}

const UserAnswerItem: React.FC<Props> = ({
  answer,
  answerControlMenu,
}: Props) => {
  const titleStyles = StyleSheet.flatten([
    styles.title,
    { fontFamily: getMediumFontByLanguage(i18next.language) },
  ])
  return (
    <View testID="user-answer-item" style={styles.container}>
      <TextAvatar text="A" size="medium" color="primary" />
      <View style={styles.questionContent}>
        <Text style={styles.body} testID="answer-body" numberOfLines={3}>
          {answer.body}
        </Text>
        <Link
          to={`/forum/questions/${answer.question?.id}`}
          style={styles.linkContainer}
        >
          <Text
            numberOfLines={2}
            style={titleStyles}
          >{`Q: ${answer.question?.title}`}</Text>
        </Link>
      </View>
      {answerControlMenu && (
        <View style={styles.controlMenu} testID="user-answer-control-menu">
          {answerControlMenu}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    color: color.textGray,
    flexWrap: 'wrap',
    paddingRight: 40,
  },
  container: {
    backgroundColor: color.white,
    flexDirection: 'row',
    marginBottom: 10,
    padding: 20,
    ...commonStyles.borderRadiusAllCorner,
  },
  controlMenu: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  linkContainer: {
    alignSelf: 'baseline',
  },
  questionContent: {
    flex: 1,
    paddingLeft: 20,
  },
  title: {
    color: color.pressableText,
    marginTop: 10,
  },
})

export default UserAnswerItem
