import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import { QuestionQueryParams, QuestionSortKeys } from '~/api/questions'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomNavigation from '~/hooks/useNavigation'
import {
  getRouteParams,
  isReadyNavigationRef,
  navigationRef,
} from '~/utils/navigation'

const QuestionFilterPopup: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const questionQueryParams = getRouteParams<QuestionQueryParams>()

  const questionFilterPopupData: FilterPopupData[] = [
    { id: QuestionSortKeys.BY_CREATED_AT, name: t('mostRecent') },
    { id: QuestionSortKeys.BY_LIKES_COUNT, name: t('mostLiked') },
  ]
  const [selectedFilter, setSelectedFilter] = useState<FilterPopupData>(
    questionFilterPopupData[0]
  )

  const getFilterDataByParams = (): FilterPopupData => {
    return !questionQueryParams.sortBy ||
      questionQueryParams?.sortBy === QuestionSortKeys.BY_CREATED_AT
      ? questionFilterPopupData[0]
      : questionFilterPopupData[1]
  }

  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    setQueryParams({ sortBy: item.id as QuestionSortKeys })
  }

  useEffect(() => {
    navigationRef.current?.getCurrentRoute()?.path?.includes('/forum') &&
      isReadyNavigationRef.current &&
      changeSelectedFilter(getFilterDataByParams())
  }, [
    isReadyNavigationRef.current,
    questionQueryParams.sortBy,
    navigationRef.current?.getCurrentRoute()?.path,
  ])

  return (
    <View testID="question-filter-popup">
      <FilterPopupMenu
        selectedItem={selectedFilter}
        data={questionFilterPopupData}
        onSelect={changeSelectedFilter}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {`${t('sortBy')}: ${selectedFilter.name}`}
          </Text>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  selectedFilter: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
  },
})

export default QuestionFilterPopup
