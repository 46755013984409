import React from 'react'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
  infoComponent?: React.ReactElement
  bannerComponent?: React.ReactElement
  footerLeftComponent?: React.ReactElement
  footerRightComponent?: React.ReactElement
}

const ProfileHeader: React.FC<Props> = ({
  isPC,
  infoComponent,
  bannerComponent,
  footerLeftComponent,
  footerRightComponent,
}: Props) => {
  const childrenComponent: React.ReactNode = (
    <>
      <View style={styles.banner}>{bannerComponent}</View>
      <View style={styles.info}>{infoComponent}</View>
      <View style={styles.footer} testID="profile-header-footer">
        {isPC ? (
          <View style={styles.footerLeftContainer}>
            <View style={styles.separator} />
            {footerLeftComponent}
          </View>
        ) : (
          <View style={styles.footerMobile}>{footerLeftComponent}</View>
        )}
        <View
          style={styles.footerRightContainer}
          testID="profile-footer-right-container"
        >
          {footerRightComponent}
        </View>
      </View>
    </>
  )

  return isPC ? (
    <CardContainer
      testID="profile-header"
      style={StyleSheet.flatten([styles.container, styles.pcWidth])}
    >
      {childrenComponent}
    </CardContainer>
  ) : (
    <View testID="profile-header-mobile" style={styles.container}>
      {childrenComponent}
    </View>
  )
}

const styles = StyleSheet.create({
  banner: {
    height: 65,
  },
  container: {
    backgroundColor: color.white,
    marginBottom: 22,
  },
  footer: {
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  footerLeftContainer: {
    flex: 1,
  },
  footerMobile: {
    flex: 1,
  },
  footerRightContainer: {
    paddingBottom: 10,
    justifyContent: 'flex-end',
  },
  info: {
    flex: 1,
    paddingHorizontal: 30,
  },
  pcWidth: {
    width: 814,
  },
  separator: {
    flex: 1,
  },
})

export default ProfileHeader
