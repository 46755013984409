import { useNavigation } from '@react-navigation/native'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import ServiceItem from '~/components/common/molecules/ServiceItem'
import Text from '~/components/workarounds/Text'
import { ServiceType } from '~/constants/common/app'
import Color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import logo from '~/constants/common/logo'
import AppService from '~/interfaces/AppService'
import { logout as resetAccessToken } from '~/slices/common/auth'
import { reset as resetNotification } from '~/slices/common/notifications'
import { RootStackNavigationProp } from '~/utils/navigation'

export const getServices = (
  translate: TFunction,
  isPC: boolean
): AppService[] => [
  {
    title: translate('appService.career.title'),
    screen: 'careerMain',
    image: isPC
      ? require('~/assets/images/appServices/career.png')
      : require('~/assets/images/appServices/narrow/career.png'),
    description: translate('appService.career.description'),
    type: ServiceType.CAREER,
    logo: logo.career.path,
  },
  {
    title: translate('appService.forum.title'),
    screen: 'forumMain',
    image: isPC
      ? require('~/assets/images/appServices/forum.png')
      : require('~/assets/images/appServices/narrow/forum.png'),
    description: translate('appService.forum.description'),
    type: ServiceType.FORUM,
    logo: logo.forum.path,
  },
  {
    title: translate('appService.community.title'),
    screen: 'communityMain',
    image: isPC
      ? require('~/assets/images/appServices/community.png')
      : require('~/assets/images/appServices/narrow/community.png'),
    description: translate('appService.community.description'),
    type: ServiceType.COMMUNITY,
    logo: logo.community.path,
  },
]

type Props = {
  isPC: boolean
  title?: string
  isSelection?: boolean
  isTopPage?: boolean
  currentService?: ServiceType
}

const ServiceList: React.FC<Props> = ({
  title,
  isPC,
  isSelection,
  currentService,
  isTopPage,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const logout = () => {
    Promise.all([
      dispatch(resetAccessToken()),
      dispatch(resetNotification()),
    ]).then(() => {
      navigate('login')
    })
  }

  return (
    <View
      testID="top-page-services"
      style={[styles.container, isPC ? styles.containerPadding : undefined]}
    >
      <Text style={isTopPage ? styles.titleTopPage : styles.title}>
        {title ?? t('appService.servicesTitle')}
      </Text>
      <View style={isPC ? styles.list : undefined}>
        {getServices(t, isPC ?? true)?.map((item, index) => (
          <ServiceItem
            key={`${index}-${item.title}`}
            item={item}
            isPC={isPC}
            isSelectable={isSelection}
            selected={currentService === item.type}
          />
        ))}
      </View>
      {isTopPage && (
        <Pressable onPress={logout} testID="logout-btn">
          <Text style={styles.logoutText}>{t('auth.logout')}</Text>
        </Pressable>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    paddingBottom: 40,
  },
  containerPadding: {
    paddingBottom: 170,
    paddingTop: 120,
  },
  list: {
    flexDirection: 'row',
    padding: 40,
  },
  logoutText: {
    color: Color.darkGray,
    fontSize: FontSize.REMARKABLE,
    textDecorationLine: 'underline',
  },
  title: {
    fontSize: 16,
    paddingBottom: 13,
  },
  titleTopPage: {
    fontSize: 23,
  },
})

export default ServiceList
