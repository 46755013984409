import { FontAwesome } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import User from '~/interfaces/User'
import i18n, { switchLanguage } from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { fetchCurrentUser } from '~/slices/common/users'

const LanguageSetting: React.FC = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useSelector((state: RootState) => state.users.current)

  const updateUserLanguage = async (): Promise<void> => {
    await switchLanguage()
    try {
      await api.users.update<Partial<User>, User>(currentUser?.id!, {
        displayLang: i18n.language,
      })
      dispatch(fetchCurrentUser())
    } catch (error) {
      toast.showError(error)
    }
  }

  return (
    <Button
      type="clear"
      testID="language-setting"
      titleStyle={styles.title}
      title={t('menu.language')}
      buttonStyle={styles.button}
      onPress={updateUserLanguage}
      icon={<FontAwesome size={25} name="language" color={color.textGray} />}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'flex-start',
    width: 280,
  },
  title: {
    color: color.textGray,
    marginLeft: 10,
    fontSize: FontSize.GENERAL,
  },
})

export default LanguageSetting
