import React from 'react'
import { ViewStyle, StyleSheet, TextStyle } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title: string
  onPress?: () => void
  icon: React.ReactElement
}

const MenuDrawerItem: React.FC<Props> = ({ title, onPress, icon }: Props) => (
  <Button
    icon={icon}
    type="clear"
    title={title}
    onPress={onPress}
    testID="menu-drawer-item"
    buttonStyle={styles.button as ViewStyle}
    titleStyle={
      StyleSheet.flatten([
        styles.title,
        { fontSize: FontSize.GENERAL },
      ]) as TextStyle
    }
  />
)

const styles = {
  button: {
    justifyContent: 'flex-start',
    width: 280,
  },
  title: {
    color: color.textGray,
    marginLeft: 10,
  },
}

export default MenuDrawerItem
