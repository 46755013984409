import type { HTTPClient } from '~/httpClient'
import Comment from '~/interfaces/Comment'

export class ForumCommentsAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async create({
    questionId,
    answerId,
    body,
  }: {
    questionId: number
    answerId: number
    body: string
  }): Promise<Comment> {
    return await this.client.post<Comment>(
      `/questions/${questionId}/answers/${answerId}/comments`,
      {
        comment: { body },
      }
    )
  }

  async delete({
    questionId,
    answerId,
    commentId,
  }: {
    questionId: number
    answerId: number
    commentId: number
  }): Promise<void> {
    return await this.client.delete<void>(
      `/questions/${questionId}/answers/${answerId}/comments/${commentId}`
    )
  }
}
