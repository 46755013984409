import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import ApplicantStatistics from '~/components/career/features/company/ApplicantStatistics'
import RecruitmentStatistics from '~/components/career/features/company/RecruitmentStatistics'
import DashboardMenu, {
  DashboardMenuType,
} from '~/components/career/molecules/company/DashboardMenu'

type Props = {
  isPC?: boolean
}

const CompanyOverview: React.FC<Props> = ({ isPC }) => {
  const [selectedMenu, setSelectedMenu] = useState<DashboardMenuType>(
    DashboardMenuType.RECRUITMENT
  )
  const containerStyle = isPC ? styles.container : undefined

  return (
    <View testID="company-overview" style={containerStyle}>
      <DashboardMenu
        selectedMenu={selectedMenu}
        onSelect={setSelectedMenu}
        isPC={isPC}
      />
      {isPC && <View style={styles.separator} />}
      <View style={styles.rightColumn}>
        {selectedMenu === DashboardMenuType.APPLICANT ? (
          <ApplicantStatistics />
        ) : (
          <RecruitmentStatistics />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  rightColumn: {
    flex: 1,
    paddingBottom: 20,
  },
  separator: {
    marginRight: 20,
  },
})

export default CompanyOverview
