import { MediaTypeOptions } from 'expo-image-picker'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import ImageKind from '~/interfaces/ImageKind'
import Media from '~/interfaces/Media'

type Props = {
  kind?: ImageKind
  onUpload?: (media: Media[]) => void
}

// Currently, expected case is only for MyPage
// It should add size management logic when it's needed
const EditAvatar: React.FC<Props> = ({ onUpload, kind = 'profile' }: Props) => {
  return (
    <View style={styles.container} testID="edit-avatar">
      <ImagePickerButton
        kind={kind}
        title="Edit"
        type="clear"
        onUpload={onUpload}
        allowsMultipleSelection={false}
        mediaTypes={MediaTypeOptions.Images}
        titleStyle={StyleSheet.flatten(styles.button)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
  },
  container: {
    backgroundColor: color.mask,
    bottom: 0,
    marginTop: -35,
    position: 'absolute',
    width: '100%',
  },
})

export default EditAvatar
