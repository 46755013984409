import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'

import PostContent from '~/components/career/features/posts/PostContent'

const PostDetailTemplate: React.FC = () => {
  return (
    <ScrollView testID="post-detail-template">
      <PostContent />
    </ScrollView>
  )
}

export default PostDetailTemplate
