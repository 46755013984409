import { find } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import JobsList from '~/components/career/features/jobs/JobsList'
import HeaderJobs from '~/components/career/organisms/jobs/HeaderJobs'
import OutsiderGuide from '~/components/common/features/auth/OutsiderGuide'
import TopTabNavigator, {
  TopTabNavigatorData,
} from '~/components/common/organisms/TopTabNavigator'
import { JobSection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import useCustomNavigation from '~/hooks/useNavigation'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { getRouteParams } from '~/utils/navigation'

const JobsTemplate: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const { setQueryParams } = useCustomNavigation()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const params = getRouteParams<{ section?: JobSection }>()

  const topTabNavigatorData: TopTabNavigatorData[] = [
    { label: t('jobs.pages.all'), key: JobSection.FEED },
    { label: t('jobs.pages.saved'), key: JobSection.SAVED },
  ]

  if (!currentUser?.isCompanyAccount) {
    topTabNavigatorData.splice(1, 0, {
      label: t('jobs.pages.applied'),
      key: JobSection.APPLIED,
    })
  }

  const [currentJobType, setCurrentJobType] = useState<
    TopTabNavigatorData | undefined
  >(topTabNavigatorData[0])

  const changeJobType = (tab: TopTabNavigatorData): void => {
    setCurrentJobType(tab)
    setQueryParams({ section: tab.key as JobSection })
  }

  useEffect(() => {
    setCurrentJobType(find(topTabNavigatorData, { key: params?.section }))
  }, [params?.section])

  return (
    <>
      {isPC ? (
        <JobsList header={<HeaderJobs currentUser={currentUser} />} />
      ) : (
        <JobsList
          header={
            <>
              <HeaderJobs currentUser={currentUser} />
              <TopTabNavigator
                isPC={isPC}
                data={topTabNavigatorData}
                onSelectTab={changeJobType}
                selectedItem={currentJobType}
              />
            </>
          }
        />
      )}
      {!accessToken && <OutsiderGuide />}
    </>
  )
}

export default JobsTemplate
