import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  areaCode?: string
  addressNumber?: string
  onChange: (value: string) => void
}

const MobileTextField: React.FC<Props> = ({
  onChange,
  addressNumber,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="mobile-text-field">
      <Text style={styles.title}>{`${t('profile.mobile')}*`}</Text>
      <View style={styles.inputContainer}>
        {/*
        Area code has not supported yet
         <Input
          testID="area-code"
          onChangeText={setAreaCode}
          value={areaCodeValue}
          containerStyle={styles.areaCode}
          style={StyleSheet.flatten([styles.areaCode, styles.input])}
          maxLength={4}
        /> */}
        <Input
          value={addressNumber}
          testID="address-number"
          onChangeText={onChange}
          style={StyleSheet.flatten([styles.phoneNumber, styles.input])}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    marginTop: 10,
  },
  input: {
    borderColor: color.gray,
    borderRadius: 4,
    borderWidth: 1,
    padding: 4,
  },
  inputContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  phoneNumber: {
    flex: 1,
    height: 40,
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
  },
})

export default MobileTextField
