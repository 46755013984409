import { isEmpty } from 'lodash'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import BasicInfo from '~/components/common/molecules/users/BasicInfo'
import EducationInfo from '~/components/common/molecules/users/EducationInfo'
import LanguageInfo from '~/components/common/molecules/users/LanguageInfo'
import ProfileStatus from '~/components/common/molecules/users/ProfileStatus'
import SelfIntroduction from '~/components/common/molecules/users/SelfIntroduction'
import SkillInfo from '~/components/common/molecules/users/SkillInfo'
import WebsiteReferencesInfo from '~/components/common/molecules/users/WebsiteReferencesInfo'
import ProfileSummary from '~/components/common/organisms/ProfileSummary'
import color from '~/constants/common/color'
import Skill from '~/interfaces/Skill'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { updateCurrentUser, UserProfile } from '~/slices/common/users'

type Props = {
  user?: User
}

const UserProfileOverview: React.FC<Props> = ({ user }: Props) => {
  const dispatch = useDispatch()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const currentUser = useSelector((state: RootState) => state.users.current)
  const isMe = currentUser?.id === user?.id

  const updateProfile = (profile: UserProfile): void => {
    dispatch(updateCurrentUser(profile))
  }

  const handleSubmitSkills = (skills: Partial<Skill>[]) => {
    if (user) {
      updateProfile({
        userProfile: {
          id: user.id,
          skills,
        },
      })
    }
  }

  const skills = isMe ? currentUser?.profile?.skills : user?.profile?.skills
  const japaneseLevel = isMe
    ? currentUser?.profile?.japaneseLevel
    : user?.profile?.japaneseLevel

  if (!user) {
    return <View testID="empty-profile-overview"></View>
  }

  return (
    <ProfileSummary isPC={isPC}>
      <BasicInfo
        profile={isMe && currentUser ? currentUser.profile : user.profile}
        isMe={isMe}
        onUpdateProfile={updateProfile}
      />

      <CardContainer style={styles.boundary}>
        <ProfileStatus
          isMe={isMe}
          user={isMe && currentUser ? currentUser : user}
        />
      </CardContainer>

      <SelfIntroduction content={user?.profile?.aboutMe} isMe={isMe} />

      <CardContainer style={styles.boundary}>
        <EducationInfo
          isMe={isMe}
          isPC={isPC}
          user={isMe && currentUser ? currentUser : user}
          onUpdateProfile={updateProfile}
        />
      </CardContainer>

      <CardContainer style={styles.boundary}>
        <WebsiteReferencesInfo
          isMe={isMe}
          isPC={isPC}
          user={isMe && currentUser ? currentUser : user}
          onUpdateProfile={updateProfile}
        />
      </CardContainer>

      {!isMe && !japaneseLevel ? undefined : (
        <LanguageInfo
          user={isMe && currentUser ? currentUser : user}
          onUpdateProfile={updateProfile}
          isMe={isMe}
        />
      )}

      {!isMe && isEmpty(skills) ? undefined : (
        <SkillInfo
          isPC={isPC}
          skills={skills ?? []}
          isMe={isMe}
          onSubmit={handleSubmitSkills}
        />
      )}
    </ProfileSummary>
  )
}

const styles = StyleSheet.create({
  boundary: {
    backgroundColor: color.white,
    padding: 20,
  },
})

export default UserProfileOverview
