import i18n, { TFunction } from 'i18next'

import { resources, getCurrentLanguage } from '~/plugins/i18n'

const resumeI18n = i18n.createInstance()
const currentAppLanguage = getCurrentLanguage()

resumeI18n.init({
  resources,
  lng: currentAppLanguage,
  fallbackLng: currentAppLanguage,
  interpolation: {
    escapeValue: false,
  },
})

export const changeLanguage = (lang: string): Promise<TFunction> => {
  return resumeI18n.changeLanguage(lang)
}

export default resumeI18n
