import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import RoundedButton from '~/components/common/atoms/RoundedButton'
import PrimarySearchHeader from '~/components/common/organisms/PrimarySearchHeader'
import QuestionFilterPopup from '~/components/forum/molecules/questions/QuestionFilterPopup'
import ForumHeaderMobile from '~/components/forum/organisms/questions/ForumHeader/ForumHeaderMobile'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  isPC: boolean
}

const ForumHeader: React.FC<Props> = ({ isPC }: Props) => {
  const { t } = useTranslation()

  return isPC ? (
    <>
      <PrimarySearchHeader
        title={t('forum.forumTitle')}
        searchInputPlaceholder={t('forum.searchInputLabel')}
        actionButton={
          <Link to="/forum/questions/new">
            <RoundedButton
              titleStyle={styles.askQuestionTitle}
              containerStyle={styles.askQuestionContainer}
              buttonStyle={styles.askQuestion}
              title={`+ ${t('forum.addQuestion')}`}
            />
          </Link>
        }
      />
      <View style={styles.filterContainer}>
        <QuestionFilterPopup />
      </View>
    </>
  ) : (
    <ForumHeaderMobile />
  )
}

const styles = StyleSheet.create({
  askQuestion: {
    alignSelf: 'baseline',
    backgroundColor: color.white,
    justifyContent: 'center',
    minHeight: 30,
    padding: 0,
    width: '100%',
  },
  askQuestionContainer: {
    width: '100%',
  },
  askQuestionTitle: {
    ...commonStyles.buttonTextSize,
    alignSelf: 'center',
    color: color.primary,
    textAlign: 'center',
  },
  filterContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 10,
  },
  searchHeaderTitleStyle: {
    textAlign: 'center',
  },
})

export default ForumHeader
