import React from 'react'
import { StyleSheet, View } from 'react-native'

import MediaViewerDialog from '~/components/common/molecules/MediaViewerDialog'
import AnswerItem from '~/components/forum/molecules/answers/AnswerItem'
import { useMediaViewerContext } from '~/contexts/MediaViewerContext'
import Answer from '~/interfaces/Answer'

type Props = {
  isPC: boolean
  renderAnswerControlMenu: (
    item: Answer,
    cb?: Function
  ) => React.ReactElement | undefined
  renderSocialActivity: (item: Answer) => React.ReactElement
  renderAnswerCommentList: (item: Answer) => React.ReactElement
}

const AnswerMediaViewerDialog: React.FC<Props> = ({
  isPC,
  renderAnswerControlMenu,
  renderSocialActivity,
  renderAnswerCommentList,
}) => {
  const { media, entity, dismissDialog } = useMediaViewerContext()

  if (!media?.length || !entity) {
    return null
  }

  return (
    <View testID="answer-media-viewer-dialog">
      <MediaViewerDialog isPC={isPC}>
        <AnswerItem
          answer={{ ...entity, images: [] } as Answer}
          isPC={isPC}
          answerControlMenu={renderAnswerControlMenu(
            entity as Answer,
            dismissDialog
          )}
        />
        <View style={styles.socialWrapper}>
          {renderSocialActivity(entity as Answer)}
        </View>
        {renderAnswerCommentList(entity as Answer)}
      </MediaViewerDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  socialWrapper: {
    paddingHorizontal: 15,
  },
})

export default AnswerMediaViewerDialog
