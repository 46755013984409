import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  editable: boolean
  savable?: boolean
  editTitle?: string
  isLoading?: boolean
  onPressEdit?: () => void
  onPressSave?: () => void
  onPressCancel?: () => void
}

const ActionsSettingElement: React.FC<Props> = ({
  savable,
  editable,
  editTitle,
  isLoading,
  onPressEdit,
  onPressSave,
  onPressCancel,
}: Props) => {
  const { t } = useTranslation()

  const buttonSaveTitle = savable
    ? StyleSheet.flatten([styles.buttonTitle, { color: color.white }])
    : styles.buttonTitle

  return (
    <View style={styles.container} testID="actions-setting-element">
      {!editable && (
        <Button
          type="clear"
          onPress={onPressEdit}
          testID="edit-button"
          title={editTitle ? editTitle : t('edit')}
          buttonStyle={styles.buttonContainer}
          titleStyle={styles.buttonTitle}
        />
      )}
      {editable && (
        <View style={styles.editableContainer}>
          <Button
            type="clear"
            title={t('cancel')}
            disabled={isLoading}
            testID="cancel-button"
            onPress={onPressCancel}
            titleStyle={StyleSheet.flatten([
              styles.buttonTitle,
              { marginRight: 10 },
            ])}
            buttonStyle={styles.buttonContainer}
          />
          <Button
            type="solid"
            title={t('save')}
            testID="save-button"
            onPress={onPressSave}
            titleStyle={buttonSaveTitle}
            disabled={!savable || isLoading}
            buttonStyle={styles.buttonSaveContainer}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: 'baseline',
    marginHorizontal: 4,
    padding: 0,
  },
  buttonSaveContainer: {
    alignSelf: 'baseline',
    width: 77,
    height: 25,
    padding: 4,
  },
  buttonTitle: {
    alignSelf: 'flex-end',
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
  },
  container: {
    flexDirection: 'row',
  },
  editableContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default ActionsSettingElement
