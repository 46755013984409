import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import EnjinTemplate from '~/components/career/templates/resume/templates/EnjinTemplate'
import FormalTemplate from '~/components/career/templates/resume/templates/FormalTemplate'
import MinimalTemplate from '~/components/career/templates/resume/templates/MinimalTemplate'
import ShadesTemplate from '~/components/career/templates/resume/templates/ShadesTemplate'
import NotFound from '~/components/common/pages/NotFound'
import color from '~/constants/common/color'
import { TemplateType } from '~/interfaces/Resume'
import resumeI18n, { changeLanguage } from '~/plugins/resume_language'
import { RootState } from '~/rootReducer'
import { setPageTitle } from '~/utils/navigation'
//TODO fix css for mobile app
// import './ResumeTemplate.css'

const ResumeTemplate: React.FC = () => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const resume = useSelector((state: RootState) => state.resumes.current)

  let resumeTemplate = <EnjinTemplate resume={resume} />
  if (resume.templateType === TemplateType.Shades) {
    resumeTemplate = <ShadesTemplate resume={resume} />
  } else if (resume.templateType === TemplateType.Formal) {
    resumeTemplate = <FormalTemplate resume={resume} />
  } else if (resume.templateType === TemplateType.Minimal) {
    resumeTemplate = <MinimalTemplate resume={resume} />
  }

  useEffect(() => {
    if (!isEmpty(resume)) {
      setPageTitle(
        `${t('profile.resumes')} - ${t('profile.displayName', {
          givenName: resume?.givenName,
          familyName: resume?.familyName,
        })}`
      )
    }
    resume.language && changeLanguage(resume.language)
  }, [resume])

  return (
    <I18nextProvider i18n={resumeI18n}>
      <DefaultLayout>
        <View style={styles.container} testID="resume-template">
          {isEmpty(resume) ? (
            <NotFound />
          ) : (
            <>
              {!isPC && (
                <ScrollView horizontal={true} testID="horizontal-scroll">
                  <View style={styles.contentMobile}>{resumeTemplate}</View>
                </ScrollView>
              )}
              {isPC && <View style={styles.content}>{resumeTemplate}</View>}
            </>
          )}
        </View>
      </DefaultLayout>
    </I18nextProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    alignSelf: 'center',
    width: 800,
    backgroundColor: color.white,
  },
  contentMobile: {
    width: 800,
  },
})

export default ResumeTemplate
