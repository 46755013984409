import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import EmailInput from '~/components/common/atoms/auth/EmailInput'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ActionsSettingElement from '~/components/common/molecules/settings/ActionsSettingElement'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { logout } from '~/slices/common/auth'
import { updateEmail } from '~/slices/common/settings'
import { RootStackNavigationProp } from '~/utils/navigation'

const AccountSettingEmail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const { isPC } = useDevice()
  const account = useSelector((state: RootState) => state.settings.account)

  const [email, setEmail] = useState(account?.email || '')
  const [isValid, setIsValid] = useState(false)
  const [editing, setEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isError, setIsError] = useState(false)

  const containerStyle = isPC ? styles.container : styles.containerMobile
  const emailInputStyle = isPC
    ? styles.emailInputContainer
    : styles.emailInputContainerMobile

  const changeEmail = (value: string, isValid: boolean): void => {
    setEmail(value)
    setIsValid(isValid)
  }

  const updateAccountEmail = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await Promise.all([dispatch(updateEmail(email))])
      setShowConfirmDialog(true)
      setEditing(false)
    } catch (error) {
      //TODO show error
      setIsError(true)
    }
    setIsLoading(false)
  }

  const acceptConfirmation = (): void => {
    setShowConfirmDialog(false)
    Promise.all([dispatch(logout())]).then(() => navigate('login'))
  }

  const onCancelEdit = (): void => {
    setEmail(account?.email ?? '')
    setIsError(false)
    setEditing(false)
  }

  return (
    <View style={containerStyle} testID="account-setting-email">
      <View style={styles.emailInfo}>
        <Text style={styles.label}>{t('profile.email')}</Text>
        {editing ? (
          <View style={emailInputStyle}>
            <EmailInput
              onChangeText={changeEmail}
              email={email}
              invisibleLabel
            />
            {isError && (
              <Text style={styles.errorText}>
                {t('auth.updateEmailErrorMessage')}
              </Text>
            )}
          </View>
        ) : (
          <Text style={styles.value} testID="account-setting-email-value">
            {account?.email}
          </Text>
        )}
      </View>
      <ActionsSettingElement
        isLoading={isLoading}
        savable={isValid}
        editable={editing}
        onPressSave={updateAccountEmail}
        onPressCancel={onCancelEdit}
        onPressEdit={(): void => setEditing(true)}
      />
      <ConfirmationDialog
        acceptLabel={t('ok')}
        isOnlyAccept={true}
        visible={isShowConfirmDialog}
        onPressAccept={acceptConfirmation}
        message={t('auth.sentConfirmationMailMessage')}
        onPressCancel={(): void => setShowConfirmDialog(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 6,
  },
  containerMobile: {
    marginBottom: 10,
    marginTop: 6,
  },
  emailInfo: {
    flex: 1,
    flexDirection: 'column',
  },
  emailInputContainer: {
    flex: 1,
    marginRight: 30,
  },
  emailInputContainerMobile: {
    flex: 1,
  },
  errorText: {
    color: color.danger,
    marginLeft: 10,
  },
  label: {
    color: color.black,
    width: 120,
  },
  value: {
    color: color.textGray,
    flex: 1,
    marginTop: 10,
  },
})

export default AccountSettingEmail
