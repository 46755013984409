import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { isEmpty, isEqual } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import { ResumeQueryParams } from '~/api/resumes'
import CreateResumeItem from '~/components/career/molecules/resume/CreateResumeItem'
import ResumeControlMenu, {
  ResumeMenuType,
} from '~/components/career/molecules/resume/ResumeControlMenu'
import ResumeItem from '~/components/career/organisms/resumes/ResumeItem'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import SkeletonCard from '~/components/common/molecules/SkeletonCard'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Resume from '~/interfaces/Resume'
import { RootState } from '~/rootReducer'
import { RootStackNavigationProp, getRouteParams } from '~/utils/navigation'

type Props = {
  hideTitle?: boolean
  selectedResume?: Resume
  mode?: 'selection' | 'normal-list'
  onSelect?: (resume: Resume) => void
}

const ResumesList: React.FC<Props> = ({
  onSelect,
  hideTitle,
  selectedResume,
  mode = 'normal-list',
}: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const toast = useCustomToast()
  const params = getRouteParams<Partial<ResumeQueryParams>>()
  const defaultPage = 1
  const { isPC } = useDevice()
  const numColumns = isPC ? 3 : 1
  const [isLoading, setIsLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const [resumes, setResumes] = useState<Resume[]>([])
  const shouldShowSkeleton = isLoading && isEmpty(resumes)
  const [queryParams, setQueryParams] = useState<Partial<ResumeQueryParams>>({
    page: defaultPage,
  })
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const isSelectable = mode === 'selection'

  const containerStyle = numColumns > 1 ? styles.container : null

  const renderItem: ListRenderItem<Resume> = ({
    item,
    index,
  }: {
    item: Resume
    index: number
  }) =>
    index === 0 ? (
      <CreateResumeItem isPC={isPC} onPress={createResume} />
    ) : (
      <ResumeItem
        isPC={isPC}
        resume={item}
        pressMode={isSelectable ? 'whole-content' : 'partial'}
        isSelected={isEqual(item, selectedResume)}
        onPress={() => (isSelectable ? onSelect?.(item) : undefined)}
        controlMenu={
          <ResumeControlMenu
            onSelect={(type): void => onSelectResumeMenu(type, item)}
          />
        }
      />
    )

  const onSelectResumeMenu = (type: ResumeMenuType, resume: Resume) => {
    if (type === ResumeMenuType.Delete) {
      deleteResume(resume.id)
    } else if (type === ResumeMenuType.Edit) {
      navigate('resumeEdit', { id: resume.id })
    }
  }

  const deleteResume = async (resumeId: number): Promise<void> => {
    try {
      await api.resumes.delete(resumeId)
      setResumes((resumes) => resumes.filter((item) => item.id !== resumeId))
    } catch (err) {
      toast.showError(err)
    }
  }

  const createResume = useAPI(async (api: API): Promise<void> => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    try {
      const response = await api.resumes.create<Partial<Resume>, Resume>({
        headline: t('resumeTitle'),
      })
      navigate('resumeEdit', { id: response.id })
    } catch (err) {
      //TODO show error
    }
    api.isAlive && setIsCreating(false)
  }, [])

  const fetchResumes = useAPI(
    async (api: API, page: number): Promise<void> => {
      setIsLoading(true)
      try {
        const response = await api.resumes.index<
          Resume[],
          Partial<ResumeQueryParams>
        >({
          ...queryParams,
          page,
        })

        if (page === defaultPage && isEmpty(response)) {
          setResumes([{} as Resume])
          return
        }

        if (queryParams.page && page > queryParams.page) {
          if (!isEmpty(response)) {
            setResumes((resumes) => [...resumes, ...response])
            setQueryParams({ ...queryParams, page })
          }
        } else {
          setResumes([{} as Resume, ...response])
        }
      } catch (err) {
        //TODO show error
      }
      api.isAlive && setIsLoading(false)
    },
    [
      setIsLoading,
      setQueryParams,
      setResumes,
      queryParams.q,
      queryParams.templateType,
    ]
  )

  const fetchMoreResumes = (): void => {
    !isLoading && queryParams?.page && fetchResumes(queryParams.page + 1)
  }

  const keyExtractor = (item: Resume): string => item?.id?.toString() ?? ''

  useFocusEffect(
    React.useCallback(() => {
      fetchResumes(queryParams?.page || defaultPage)
    }, [fetchResumes])
  )

  useFocusEffect(
    React.useCallback(() => {
      isBottom && fetchMoreResumes()
    }, [isBottom])
  )

  useFocusEffect(
    React.useCallback(() => {
      setQueryParams({ ...queryParams, ...params, page: defaultPage })
    }, [params.q, params.templateType])
  )
  return (
    <View testID="resumes-list" style={containerStyle}>
      {shouldShowSkeleton ? (
        <View style={styles.loadingView}>
          <SkeletonList
            count={6}
            numColumns={isPC ? 3 : 1}
            itemComponent={<SkeletonCard isPC={isPC} />}
          />
        </View>
      ) : (
        <>
          <View testID="content-container">
            <InfiniteScrollFlatList
              data={resumes}
              style={styles.list}
              extraData={resumes}
              initialNumToRender={6}
              numColumns={numColumns}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
              contentContainerStyle={styles.listWrapper}
              columnWrapperStyle={!isPC ? undefined : styles.columnWrapper}
              ListHeaderComponent={
                <>
                  {!hideTitle && (
                    <View
                      style={
                        !isPC ? styles.titleContainer : styles.columnWrapper
                      }
                    >
                      <Text style={styles.title}>
                        {t('resume.createMainResumeTitle')}
                      </Text>
                      <Text style={styles.subtitle}>
                        {t('resume.createMainResumeSubTitle')}
                      </Text>
                    </View>
                  )}
                </>
              }
            />
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  columnWrapper: {
    alignSelf: 'center',
    width: 940,
  },
  container: {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
  },
  list: {
    height: 'auto',
  },
  listWrapper: {
    paddingBottom: 10,
  },
  loadingView: {
    flex: 1,
  },
  subtitle: {
    borderColor: color.textGray,
    fontSize: FontSize.SUB,
    paddingBottom: 6,
    paddingLeft: 5,
    paddingTop: 4,
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 20,
    padding: 5,
  },
  titleContainer: {
    alignSelf: 'center',
    width: '90%',
  },
})

export default ResumesList
