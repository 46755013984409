import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Badge } from 'react-native-elements'

type Props = {
  value?: number
}

const TabMenuBadge: React.FC<Props> = ({ value }: Props) => {
  return (
    <View style={styles.badgeBody} testID="tab-menu-badge">
      <Badge status="error" value={value} />
    </View>
  )
}

const styles = StyleSheet.create({
  badgeBody: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -4,
    top: 0,
  },
})

export default TabMenuBadge
