import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import SelectableList from '~/components/common/features/SelectableList'
import SelectListItem from '~/interfaces/SelectListItem'
import { RootState } from '~/rootReducer'
import { AdminDashboardType, updateSetting } from '~/slices/community/community'

export const adminDashboardTypes: AdminDashboardType[] = [
  AdminDashboardType.MemberManagement,
  AdminDashboardType.MemberRequests,
  AdminDashboardType.CommunitySettings,
]

const getAdminDashboardDataItem = (
  adminDashboardType: AdminDashboardType,
  index: number,
  translate: Function
): SelectListItem => {
  switch (adminDashboardType) {
    case AdminDashboardType.MemberManagement:
      return { id: index, name: translate('community.admin.memberManagement') }
    case AdminDashboardType.MemberRequests:
      return { id: index, name: translate('community.admin.memberRequests') }
    default:
      return { id: index, name: translate('community.admin.communitySettings') }
  }
}

const AdminDashboardList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const adminDashboardType = useSelector(
    (state: RootState) => state.community.setting.adminDashboardType
  )
  const dashboardData = adminDashboardTypes.map((item, index) =>
    getAdminDashboardDataItem(item, index, t)
  )

  const changeSelectedDashboard = (itemId: number): void => {
    dispatch(updateSetting({ adminDashboardType: adminDashboardTypes[itemId] }))
  }

  return (
    <CardContainer style={styles.container} testID="admin-dashboard-list">
      <SelectableList
        onSelect={(value): void => changeSelectedDashboard(value as number)}
        selectedId={adminDashboardTypes.indexOf(adminDashboardType)}
        data={[
          { title: t('community.admin.adminDashboard'), data: dashboardData },
        ]}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    marginRight: 10,
    overflow: 'hidden',
    width: 200,
  },
})

export default AdminDashboardList
