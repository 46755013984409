import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import CardContainer from '~/components/common/atoms/CardContainer'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ChannelEditDialog from '~/components/community/features/channels/ChannelEditDialog'
import ChannelHeaderMobile from '~/components/community/features/channels/ChannelHeader/ChannelHeaderMobile'
import ChannelHeaderPC from '~/components/community/features/channels/ChannelHeader/ChannelHeaderPC'
import color from '~/constants/common/color'
import useCustomToast from '~/hooks/useCustomToast'
import { ChannelControlMenuType } from '~/interfaces/ChannelControlMenu'
import { ChannelViewMode } from '~/interfaces/Community'
import { RootState } from '~/rootReducer'
import { removeChannel, setChannelMode } from '~/slices/community/community'

const ChannelHeader: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const [isEditChannel, setIsEditChannel] = useState(false)
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowEditChannelDialog, setIsShowEditChannelDialog] = useState(false)
  const community = useSelector((state: RootState) => state.community.current)
  const channel = useSelector(
    (state: RootState) => state.community.channel.current
  )
  const channelMode = useSelector(
    (state: RootState) => state.community.channel.channelMode
  )

  const toggleChannelEditDialog = (): void => {
    setIsShowEditChannelDialog(!isShowEditChannelDialog)
  }

  const removeChannelFromLocal = (channelId: number): void => {
    dispatch(removeChannel(channelId))
  }

  const deleteChannel = async (): Promise<void> => {
    if (!community || !channel) {
      return
    }
    try {
      await api.communityChannel.configPath(community.id).delete(channel.id)
      removeChannelFromLocal(channel.id)
      setIsShowConfirmDialog(false)
      setIsShowEditChannelDialog(false)
    } catch (error) {
      toast.showError(error)
    }
  }

  const handleSelectControlMenu = (
    controlMenuType: ChannelControlMenuType
  ): void => {
    switch (controlMenuType) {
      case ChannelControlMenuType.ShowMembers:
        dispatch(setChannelMode(ChannelViewMode.Member))
        break
      case ChannelControlMenuType.ShowTimeLine:
        dispatch(setChannelMode(ChannelViewMode.Timeline))
        break
      case ChannelControlMenuType.EditChannel:
        setIsEditChannel(true)
        toggleChannelEditDialog()
        break
      case ChannelControlMenuType.CreateChannel:
        setIsEditChannel(false)
        toggleChannelEditDialog()
        break
      case ChannelControlMenuType.DeleteChannel:
        setIsShowConfirmDialog(true)
        break
    }
  }

  useEffect(() => {
    channel?.isPublic &&
      channelMode === ChannelViewMode.Member &&
      handleSelectControlMenu(ChannelControlMenuType.ShowTimeLine)
  }, [channel?.isPublic])

  return (
    <>
      <CardContainer style={styles.container} testID="channel-header">
        <View style={styles.backgroundContainer}>
          {isPC ? (
            <ChannelHeaderPC onSelectControlMenu={handleSelectControlMenu} />
          ) : (
            <ChannelHeaderMobile
              onSelectControlMenu={handleSelectControlMenu}
            />
          )}
        </View>
      </CardContainer>
      <ChannelEditDialog
        isEdit={isEditChannel}
        onDismiss={toggleChannelEditDialog}
        visibleModal={isShowEditChannelDialog}
      />
      {isShowConfirmDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('delete')}
          onPressAccept={deleteChannel}
          visible={isShowConfirmDialog}
          onPressCancel={(): void => setIsShowConfirmDialog(false)}
          message={t('community.channel.deleteChannelConfirmMessage')}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  backgroundContainer: {
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  container: {
    backgroundColor: color.primary,
  },
})

export default ChannelHeader
