import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Platform, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  value?: string
  label?: string
  inverted?: boolean
  onSubmit?: () => void
  placeholderKey?: string
  onChangeText?: (text: string) => void
}

const SearchInput: React.FC<Props> = ({
  value,
  label,
  onSubmit,
  inverted,
  onChangeText,
  placeholderKey,
}: Props) => {
  const mainColor = inverted ? color.white : color.placeholderText
  const { t } = useTranslation()

  const rightIcon = (
    <TouchableOpacity
      onPress={onSubmit}
      testID="search-input-right-icon"
      style={styles.rightIconContainer}
    >
      <Icon name="search" size={18} color={mainColor} />
    </TouchableOpacity>
  )

  return (
    <View testID="search-input">
      <Input
        label={label}
        value={value || ''}
        rightIcon={rightIcon}
        onSubmitEditing={onSubmit}
        onChangeText={onChangeText}
        testID="search-input-text-input"
        placeholderTextColor={mainColor}
        inputContainerStyle={styles.container}
        labelStyle={{ ...styles.label, color: mainColor }}
        placeholder={placeholderKey && t(placeholderKey)}
        inputStyle={{ ...styles.input, color: mainColor }}
      />
    </View>
  )
}

const styles = {
  label: {
    marginBottom: 4,
    fontWeight: 'normal',
  },
  rightIconContainer: {
    paddingHorizontal: 6,
  },
  container: {
    width: '100%',
    height: 32,
    backgroundColor: color.lightMask,
    borderRadius: 6,
    borderWidth: 0,
    borderColor: color.transparent,
  },
  input: {
    borderBottomWidth: 0,
    fontSize: FontSize.GENERAL,
    maxWidth: '100%',
    paddingHorizontal: 10,
    ...Platform.select({
      web: { outline: 'none' },
    }),
  },
}

export default SearchInput
