import * as Factory from 'factory.ts'

import Company from '~/interfaces/Company'

export const companyFactory = Factory.makeFactory<Company>({
  id: Factory.each((i) => i + 1),
  type: '12',
  followersCount: 3,
  following: true,
  name: 'Enjin',
  userWorkingCompanies: [],
})
