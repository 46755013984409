import { useRoute } from '@react-navigation/native'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, ViewStyle } from 'react-native'

import api from '~/api'
import HeaderJob from '~/components/career/organisms/jobs/HeaderJob'
import JobBasicInfo from '~/components/career/organisms/jobs/JobBasicInfo'
import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import JobOverview from '~/components/career/organisms/jobs/JobOverview'
import JobQualifications from '~/components/career/organisms/jobs/JobQualifications'
import JobStatus from '~/components/career/organisms/jobs/JobStatus'
import SkillInfo from '~/components/common/molecules/users/SkillInfo'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Recruitment from '~/interfaces/Recruitment'
import Skill from '~/interfaces/Skill'
import { RouteParamsID } from '~/utils/navigation'

const CompanyJobEditTemplate: React.FC = () => {
  const route = useRoute()
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const [job, setJob] = useState<Partial<Recruitment> | undefined>(undefined)

  const id = (route?.params as RouteParamsID)?.id
  const companyId = (route?.params as { companyId: number })?.companyId

  const updateJob = (data: Partial<Recruitment>) => {
    setJob((item) => ({
      ...item,
      ...data,
    }))
  }

  const saveJob = async (data: Partial<Recruitment>) => {
    if (isNil(id)) {
      return
    }
    try {
      const response = await api.companyRecruitments
        .configPath(companyId)
        .update<Partial<Recruitment>, Recruitment>(id, data)
      updateJob(response)
    } catch (err) {
      toast.showError(err)
    }
  }

  const fetchJob = async () => {
    if (!id) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.companyRecruitments
        .configPath(companyId)
        .show<Recruitment>(id)
      setJob(response)
    } catch (err) {
      if (api.isAlive) {
        setIsLoading(false)
      }
    } finally {
      if (api.isAlive) {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchJob()
  }, [id, companyId])

  if (!job || isLoading) {
    return <View testID="empty-view" />
  }

  const submitJobPartial = async (partial: Partial<Recruitment>) => {
    updateJob(partial)
    saveJob(partial)
  }

  const basicInfoGroup = (
    <>
      <JobBasicInfo
        editable
        job={job}
        isPC={isPC}
        onSubmit={submitJobPartial}
      />
      <SkillInfo
        isMe
        isPC={isPC}
        skills={job.skills ?? []}
        onSubmit={(skills) => submitJobPartial({ skills: skills as Skill[] })}
      />
    </>
  )

  const jobInfoGroup = (
    <>
      <JobOverview
        editable
        job={job}
        isPC={isPC}
        onSubmit={(overview) => submitJobPartial({ overview })}
      />
      <JobQualifications
        editable
        job={job}
        isPC={isPC}
        onSubmit={(criterion) => submitJobPartial({ criterion })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.salary}
        title={t('jobs.salary')}
        onSubmit={(salary) => submitJobPartial({ salary })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.welfare}
        title={t('jobs.benefit')}
        onSubmit={(welfare) => submitJobPartial({ welfare })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.allowance}
        title={t('jobs.jobAllowance')}
        onSubmit={(allowance) => submitJobPartial({ allowance })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.insurance}
        title={t('jobs.jobInsurance')}
        onSubmit={(insurance) => submitJobPartial({ insurance })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.holiday}
        title={t('jobs.jobHoliday')}
        onSubmit={(holiday) => submitJobPartial({ holiday })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.capital}
        title={t('jobs.jobCapital')}
        onSubmit={(capital) => submitJobPartial({ capital })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.headOfficeArea}
        title={t('jobs.jobHeadOffice')}
        onSubmit={(headOfficeArea) => submitJobPartial({ headOfficeArea })}
      />
      <JobInfoBaseLayout
        editable
        isPC={isPC}
        content={job.numOfEmployees}
        title={t('jobs.jobNumOfEmployees')}
        onSubmit={(numOfEmployees) => submitJobPartial({ numOfEmployees })}
      />
    </>
  )

  return (
    <ScrollView testID="company-job-edit-template">
      {!isNil(job.company) && (
        <JobStatus isPC={isPC} job={job as Recruitment} company={job.company} />
      )}
      <HeaderJob
        job={job}
        editable
        onUpdateJob={updateJob}
        onSubmit={submitJobPartial}
      />
      <View
        style={{
          ...((isPC
            ? styles.bodyWithIsPC.true
            : styles.bodyWithIsPC.false) as ViewStyle),
          ...styles.content,
        }}
        testID="company-job-edit-template-content-wrapper"
      >
        {basicInfoGroup}
        {jobInfoGroup}
      </View>
    </ScrollView>
  )
}

const styles = {
  content: {
    marginTop: 16,
    gap: 16,
  },
  jobBodyContainer: {
    flexDirection: 'row',
  },
  subRightColumn: {
    alignSelf: 'baseline',
    width: 200,
  },
  subLeftColumn: {
    flex: 1,
  },
  separator: {
    padding: 5,
  },
  saveText: {
    textDecorationLine: 'underline',
    color: color.pressableText,
    marginRight: 20,
    fontWeight: 'bold',
  },
  bodyWithIsPC: {
    true: {
      width: 814,
      alignSelf: 'center',
    },
    false: {
      flex: 1,
    },
  },
}

export default CompanyJobEditTemplate
