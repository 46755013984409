import { FontAwesome as Icon } from '@expo/vector-icons'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Skill from '~/interfaces/Skill'

export type SkillTextFieldVariant = 'danger' | 'dark' // Add another variant when you need

type Props = {
  title?: string
  subtitle?: string
  subtitleColor?: 'warning'
  variant: SkillTextFieldVariant
  onChange?: (value: string) => void
  onSubmitSkillText: (value: Partial<Skill>) => void
}

const SkillTextField: React.FC<Props> = ({
  title,
  subtitle,
  onChange,
  subtitleColor,
  variant = 'dark',
  onSubmitSkillText,
}: Props) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleChangeText = (value: string): void => {
    setInputValue(value)
    onChange && onChange(value)
  }

  const submitSkillEditing = (): void => {
    if (inputValue.trim()) {
      onSubmitSkillText({ name: inputValue.trim() })
      setInputValue('')
    }
  }

  const iconColorWithInputVariant = {
    danger: color.gray,
    dark: color.primary,
  }

  const rightIcon = (
    <TouchableOpacity
      onPress={submitSkillEditing}
      testID="add-skill-right-icon"
      disabled={variant === 'danger' || isEmpty(inputValue)} //Prevent add button if it is danger
      style={styles.rightIconContainer}
    >
      <Icon
        name="plus"
        size={18}
        color={
          iconColorWithInputVariant[isEmpty(inputValue) ? 'danger' : variant]
        }
      />
    </TouchableOpacity>
  )

  const inputContainerVariantStyle = {
    danger: {
      borderColor: color.danger,
    },
    dark: {
      borderColor: color.gray,
    },
  }

  return (
    <View testID="skill-text-field" style={styles.container}>
      {title && <Text style={styles.title}>{title}</Text>}
      <Input
        placeholder={t('profile.placeholderSkill')}
        label={subtitle}
        value={inputValue}
        style={styles.input}
        rightIcon={rightIcon}
        testID="skill-text-field-input"
        onChangeText={handleChangeText}
        onSubmitEditing={submitSkillEditing}
        labelStyle={StyleSheet.flatten([
          styles.subtitle,
          subtitleColor === 'warning'
            ? { color: color.warning }
            : { color: color.textGray },
        ])}
        inputContainerStyle={StyleSheet.flatten([
          styles.inputContainer,
          inputContainerVariantStyle[variant],
        ])}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    height: 40,
    paddingHorizontal: 4,
    width: '100%',
    ...Platform.select({
      web: { outlineStyle: 'none' },
    }),
  },
  inputContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    width: '100%',
  },
  rightIconContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 6,
  },
  subtitle: {
    fontSize: FontSize.GENERAL,
    fontStyle: 'italic',
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginBottom: 4,
  },
})

export default SkillTextField
