import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import communityEventTypes from '~/assets/data/communityEventTypes.json'
// import EventUploadImage from '~/components/community/features/EventUploadImage'
import CustomPicker, {
  PickerOption,
} from '~/components/common/molecules/CustomPicker'
import RadioGroup from '~/components/common/molecules/RadioGroup'
import TextField from '~/components/common/molecules/TextField'
import EventTimeForm from '~/components/community/molecules/EventTimeForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import CommunityEvent, { CommunityEventType } from '~/interfaces/CommunityEvent'
import { getCurrentLanguage } from '~/plugins/i18n'

type Props = {
  isEdit?: boolean
  data: Partial<CommunityEvent>
  onChange: (data: Partial<CommunityEvent>) => void
}

const eventTypeData = [
  { label: '', value: '' },
  ...Object.keys(communityEventTypes).map((item) => {
    return {
      label:
        communityEventTypes[item as CommunityEventType].localeTitle[
          getCurrentLanguage()
        ],
      value: item,
    } as PickerOption
  }),
]

export const INDEX_OF_VIRTUAL = 0
export const INDEX_OF_REAL_WORLD = 1
const EventEditForm: React.FC<Props> = ({ data, isEdit, onChange }: Props) => {
  const { isPC } = useContext(RootContext)
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="event-edit-form">
      <Text style={styles.title} testID="event-edit-form-title">
        {isEdit ? t('events.updateEventTitle') : t('events.createEventTitle')}
      </Text>
      <View style={styles.separator} />
      <RadioGroup
        orientation={isPC ? 'horizontal' : 'vertical'}
        data={[t('events.virtual'), t('events.realWorld')]}
        onSelect={(index): void =>
          onChange({ isVirtual: index === INDEX_OF_VIRTUAL })
        }
        selectedIndex={data.isVirtual ? INDEX_OF_VIRTUAL : INDEX_OF_REAL_WORLD}
      />
      <View style={styles.separator} />
      <View style={styles.horizontalLine} />
      <View style={styles.separator} />
      {/* TODO Feature yet to come */}
      {/* <EventUploadImage /> */}
      <View style={styles.separator} />
      <TextField
        value={data.title}
        title={t('events.eventTitle')}
        onChangeText={(title): void => onChange({ title })}
      />
      {data.isVirtual && (
        <>
          <View style={styles.separator} />
          <TextField
            value={data.presenter}
            title={t('events.presenterLabel')}
            onChangeText={(presenter): void => onChange({ presenter })}
          />
        </>
      )}
      <View style={styles.separator} />
      <Text>{t('events.eventType')}</Text>
      <CustomPicker
        size="medium"
        data={eventTypeData}
        selectedValue={data.eventType}
        onValueChange={(eventType): void =>
          onChange({ eventType: eventType as CommunityEventType })
        }
      />
      <View style={styles.separator} />
      <EventTimeForm data={data} onChange={onChange} />
      <View style={styles.separator} />
      <TextField
        value={data.location}
        onChangeText={(location): void => onChange({ location })}
        subtitle={data.isVirtual ? t('events.eventLinkGuide') : undefined}
        title={
          data.isVirtual ? t('events.eventLink') : t('events.eventLocation')
        }
      />
      <View style={styles.separator} />
      <TextField
        value={data.description}
        title={t('events.eventDescription')}
        numberOfLines={5}
        onChangeText={(description): void => onChange({ description })}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: color.gray,
  },
  separator: {
    marginBottom: 10,
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default EventEditForm
