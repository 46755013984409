import { useEffect, useRef } from 'react'
import type { RefObject } from 'react'

const useUnmountedRef = (): RefObject<boolean> => {
  const unmounted = useRef(false)
  useEffect(
    () => (): void => {
      unmounted.current = true
    },
    []
  )
  return unmounted
}
export default useUnmountedRef
