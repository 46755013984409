import { useFocusEffect } from '@react-navigation/native'
import { isEmpty, isEqual } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import UploadDocumentItem from '~/components/career/features/documents/UploadDocumentItem'
import DocumentControlMenu, {
  DocumentMenuType,
} from '~/components/career/molecules/documents/DocumentControlMenu'
import DocumentItem from '~/components/career/organisms/documents/DocumentItem'
import TitleText from '~/components/common/atoms/TitleText'
import SkeletonCard from '~/components/common/molecules/SkeletonCard'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import UserDocument from '~/interfaces/UserDocument'
import { openURL } from '~/utils/navigation'

type Props = {
  hideTitle?: boolean
  subTitle?: string
  selectedDocument?: UserDocument
  mode?: 'selection' | 'normal-list'
  onSelect?: (document: UserDocument) => void
}

const DocumentList: React.FC<Props> = ({
  onSelect,
  hideTitle,
  subTitle,
  selectedDocument,
  mode = 'normal-list',
}) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const numColumns = isPC ? 3 : 1
  const [isLoading, setIsLoading] = useState(false)
  const [documents, setDocuments] = useState<UserDocument[]>([])
  const shouldShowSkeleton = isLoading && isEmpty(documents)
  const isSelectable = mode === 'selection'

  const renderItem: ListRenderItem<UserDocument> = ({
    item,
    index,
  }: {
    item: UserDocument
    index: number
  }) =>
    index === 0 ? (
      <View testID="upload-document-item-container">
        <UploadDocumentItem onUpload={addDocument} />
      </View>
    ) : (
      <DocumentItem
        isPC={isPC}
        userDocument={item}
        isSelected={isEqual(item, selectedDocument)}
        pressMode={isSelectable ? 'whole-content' : 'partial'}
        onPress={() => onSelect?.(item)}
        controlMenu={
          <DocumentControlMenu
            onSelect={(type: DocumentMenuType): void =>
              onSelectDocumentMenu(type, item)
            }
          />
        }
      />
    )

  const addDocument = async (item: UserDocument): Promise<void> =>
    setDocuments((documents) => [item, ...documents])

  const deleteDocument = useAPI(
    async (api: API, id: number): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        await api.userDocuments.delete(id)
        setDocuments((documents) => documents.filter((item) => item.id !== id))
      } catch (err) {
        toast.showError(err)
      }
      api.isAlive && setIsLoading(false)
    },
    [setIsLoading, setDocuments]
  )

  const onSelectDocumentMenu = (
    type: DocumentMenuType,
    userDocument: UserDocument
  ): void => {
    switch (type) {
      case DocumentMenuType.DELETE:
        deleteDocument(userDocument.id)
        break
      case DocumentMenuType.DOWNLOAD:
        openURL(userDocument.file.url)
        break
    }
  }

  const fetchDocuments = useAPI(
    async (api: API): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.userDocuments.index<
          UserDocument[],
          BaseQueryParams
        >({})
        setDocuments(response)
      } catch (err) {
        toast.showError(err)
      }
      api.isAlive && setIsLoading(false)
    },
    [setIsLoading, setDocuments]
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchDocuments()
    }, [fetchDocuments])
  )

  const keyExtractor = (item: UserDocument): string =>
    item?.id?.toString() ?? ''

  return (
    <View testID="document-list" style={styles.container}>
      {shouldShowSkeleton ? (
        <View style={styles.loadingView}>
          <SkeletonList
            count={6}
            numColumns={isPC ? 3 : 1}
            itemComponent={<SkeletonCard isPC={isPC} />}
          />
        </View>
      ) : (
        <View testID="content-container">
          {!hideTitle && (
            <View style={!isPC ? styles.titleContainer : styles.columnWrapper}>
              <TitleText>
                {t('document.title')}
                {subTitle && (
                  <Text style={styles.subTitle}>{` (${subTitle})`}</Text>
                )}
              </TitleText>
            </View>
          )}
          <FlatList
            style={styles.list}
            extraData={documents}
            initialNumToRender={6}
            numColumns={numColumns}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            data={[{} as UserDocument, ...documents]}
            contentContainerStyle={styles.listWrapper}
            columnWrapperStyle={!isPC ? undefined : styles.columnWrapper}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  columnWrapper: {
    alignSelf: 'center',
    width: 940,
  },
  container: {
    paddingBottom: 30,
  },
  list: {
    height: 'auto',
  },
  listWrapper: {
    paddingBottom: 10,
  },
  loadingView: {
    flex: 1,
  },
  subTitle: {
    color: color.textGray,
    fontWeight: 'normal',
  },
  titleContainer: {
    width: '90%',
    alignSelf: 'center',
  },
})

export default DocumentList
