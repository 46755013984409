import React from 'react'

import RequestPasswordResetColumn from '~/components/common/features/auth/RequestPasswordResetColumn'
import AuthContainerTemplate from '~/components/common/templates/auth/AuthContainerTemplate'

const RequestPasswordResetTemplate: React.FC = () => (
  <AuthContainerTemplate>
    <RequestPasswordResetColumn />
  </AuthContainerTemplate>
)

export default RequestPasswordResetTemplate
