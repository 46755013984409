import { some } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

import { localCategories } from '~/api/questions'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Category from '~/interfaces/Category'
import i18n from '~/plugins/i18n'

type Props = {
  values: Category[]
  onChange: (values: Category[]) => void
}

const CategoryForm: React.FC<Props> = ({ onChange, values }: Props) => {
  const { t } = useTranslation()
  const selectCategory = (category: Category): void => {
    onChange([...values, category])
  }

  const unSelectCategory = (category: Category): void => {
    onChange(values.filter((item) => item.name !== category.name))
  }

  const isSelected = (category: Category): boolean => some(values, category)

  const selectedBackgroundColor = (category: Category): string => {
    const existingCategory = values.find((item) => item.name === category.name)

    if (existingCategory) {
      return existingCategory.color
    }

    return color.primary
  }

  const renderCategoryItem = (
    key: string,
    category: Category,
    isSelected: boolean
  ): React.ReactNode => {
    return (
      <TouchableOpacity
        testID="category-item"
        key={key}
        onPress={(): void =>
          isSelected ? unSelectCategory(category) : selectCategory(category)
        }
      >
        <Text
          testID={`category-item-title-${category.name}`}
          style={StyleSheet.flatten([
            styles.category,
            isSelected
              ? StyleSheet.flatten([
                  styles.selected,
                  {
                    backgroundColor: selectedBackgroundColor(category),
                  },
                ])
              : {},
          ])}
        >
          {i18n.language === 'ja' ? category.title : category.enTitle}
        </Text>
      </TouchableOpacity>
    )
  }

  const renderCategories = (
    <View style={styles.categoriesContainer} testID="categories-group">
      {localCategories.map((item, index) =>
        renderCategoryItem(`${item.name}${index}`, item, isSelected(item))
      )}
    </View>
  )

  return (
    <>
      <Text style={styles.title}>{t('forum.selectCategory')}</Text>
      <View testID="category-form">
        <View style={styles.categoryContainer}>{renderCategories}</View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  categoriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  category: {
    backgroundColor: color.gray,
    borderRadius: 4,
    fontSize: FontSize.SUB,
    marginRight: 10,
    marginTop: 4,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  categoryContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  selected: {
    backgroundColor: color.primary,
    borderWidth: 0,
    color: color.white,
  },
  title: {
    ...commonStyles.bodyTextSize,
    color: color.textGray,
    marginBottom: 4,
  },
})

export default CategoryForm
