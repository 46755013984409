import type { HTTPClient } from '~/httpClient'
import type Image from '~/interfaces/Image'
import type ImageKind from '~/interfaces/ImageKind'
import type Policy from '~/interfaces/Policy'
import type Video from '~/interfaces/Video'

export class UploadAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async createImagePolicy(
    blob: Blob,
    name: string,
    kind: ImageKind
  ): Promise<Policy<Image>> {
    return this.createPolicy<Image>(blob, { name, kind })
  }

  async createVideoPolicy(blob: Blob): Promise<Policy<Video>> {
    return this.createPolicy<Video>(blob)
  }

  private async createPolicy<Media extends Image | Video>(
    blob: Blob,
    params: { name?: string; kind?: ImageKind } = {}
  ): Promise<Policy<Media>> {
    return await this.client.post<Policy<Media>>('/upload/policies', {
      media: {
        contentType: blob.type,
        size: blob.size,
        ...params,
      },
    })
  }
}
