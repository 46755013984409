import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
  isAdmin?: boolean
  onPressInvite?: () => void
}

const HeaderCommunityAction: React.FC<Props> = ({
  isPC,
  isAdmin,
  onPressInvite,
}: Props) => {
  const { t } = useTranslation()

  const buttonGroup = (
    <>
      {isAdmin && (
        <Text
          onPress={onPressInvite}
          style={styles.actionText}
          testID="invite-member-button"
        >
          {t('community.inviteMembers')}
        </Text>
      )}
    </>
  )

  return (
    <>
      {isPC ? (
        <HorizontalContainer>{buttonGroup}</HorizontalContainer>
      ) : (
        buttonGroup
      )}
    </>
  )
}

const styles = StyleSheet.create({
  actionText: {
    color: color.textGray,
    textDecorationLine: 'underline',
    marginHorizontal: 5,
    marginTop: 12,
    fontWeight: 'bold',
  },
})
export default HeaderCommunityAction
