import { useRoute } from '@react-navigation/native'
import * as Facebook from 'expo-auth-session/providers/facebook'
import * as WebBrowser from 'expo-web-browser'
import React, { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { useDispatch } from 'react-redux'

import FacebookButton from '~/components/common/atoms/FacebookButton'
import { loginSocialSuccess } from '~/slices/common/auth'
import {
  getFacebookAuthRequestConfig,
  getFacebookAuthURL,
} from '~/utils/common/facebook'

WebBrowser.maybeCompleteAuthSession()

type Props = {
  title: string
  redirectPath: '/signup' | '/login' | '/company/signup' | '/company/login'
  isPC: boolean
}

const FacebookAuthButton: React.FC<Props> = ({
  title,
  redirectPath,
  isPC,
}: Props) => {
  const dispatch = useDispatch()
  const route = useRoute()
  const authRequestConfig = getFacebookAuthRequestConfig(redirectPath)
  const [request, response, promptAsync] = Facebook.useAuthRequest(
    authRequestConfig
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    response?.type === 'success' &&
      response.authentication?.accessToken &&
      login(response.authentication?.accessToken)
  }, [response])

  const login = (accessToken: string): void => {
    dispatch(loginSocialSuccess({ accessToken }))
  }

  const promptAuthentication = async (): Promise<void> => {
    setIsLoading(true)
    await promptAsync({
      url: getFacebookAuthURL(authRequestConfig.redirectUri, request?.state),
    })
    setIsLoading(false)
  }

  const accessToken =
    route.params && (route.params as { access_token?: string }).access_token
  if (
    Platform.OS === 'web' &&
    window.opener &&
    window.opener !== window &&
    accessToken
  ) {
    try {
      //When we are unable to answer parent window by `WebBrowser.maybeCompleteAuthSession()`
      //Try login with access token then refresh parent window
      login(accessToken)
      window.opener.location.reload()
    } finally {
      //Force close popup to avoid blocking login workflow
      window.close()
    }
  }

  return (
    <View testID="facebook-auth-button">
      <FacebookButton
        title={title}
        loading={isLoading}
        onPress={promptAuthentication}
        isPC={isPC}
      />
    </View>
  )
}

export default FacebookAuthButton
