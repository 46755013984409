import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { ThemeProvider } from 'react-native-elements'
import { useSelector } from 'react-redux'

import { getCurrentLanguage } from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import themeConfig from '~/utils/common/themeConfig'
import { navigate } from '~/utils/navigation'

const DefaultLayout: React.FC = ({ children }) => {
  const currentUser = useSelector((state: RootState) => state.users.current)

  useEffect(() => {
    if (isEqual(true, currentUser?.subscription?.expired)) {
      setTimeout(() => {
        navigate('subscriptionPlan')
      }, 500)
    }
  }, [currentUser?.id])

  return (
    <ThemeProvider theme={themeConfig(getCurrentLanguage())}>
      <View style={styles.container}>{children}</View>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default DefaultLayout
