import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import DismissButton from '~/components/common/atoms/friendSuggestion/DismissButton'
import FollowButton from '~/components/common/atoms/users/FollowButton'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

export type Props = {
  isPC?: boolean
  user: User
  onDismiss?: (user: User) => Promise<void>
}

const FriendSuggestionItem: React.FC<Props> = ({
  isPC,
  user,
  onDismiss,
}: Props) => {
  const [isLoadingDismiss, setIsLoadingDismiss] = useState(false)

  const dismiss = async (): Promise<void> => {
    try {
      setIsLoadingDismiss(true)
      onDismiss && (await onDismiss(user))
    } catch {
      // TODO show notification
      setIsLoadingDismiss(false)
    }
  }

  const currentPosition = user.profile ? user.profile.position : ''
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const actionStyle = isPC ? styles.action : styles.actionMobile

  return (
    <View style={containerStyle} testID="friend-suggestion-item">
      <View style={styles.userAvatar}>
        <UserAvatar user={user} size={ProfileAvatarSize.MEDIUM} />
      </View>
      <View style={styles.userInfo}>
        <UserName size="medium" user={user} numberOfLines={1} />
        {currentPosition && (
          <Text style={styles.currentPosition} numberOfLines={1}>
            {currentPosition}
          </Text>
        )}
        <View style={actionStyle}>
          <View style={styles.follow}>
            <FollowButton user={user} />
          </View>
          <DismissButton loading={isLoadingDismiss} onPress={dismiss} />
        </View>
      </View>
      <View></View>
    </View>
  )
}

const styles = StyleSheet.create({
  action: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingRight: 10,
    marginTop: 3,
    alignItems: 'center',
  },
  actionMobile: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
    paddingRight: 10,
    marginLeft: 10,
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    paddingBottom: 14,
    paddingTop: 9,
  },
  containerMobile: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  currentPosition: {
    color: color.textGray,
    flexWrap: 'wrap',
    flex: 1,
    fontSize: FontSize.SUB,
    lineHeight: 17,
    paddingRight: 4,
  },
  follow: {
    marginRight: 10,
  },
  userAvatar: {
    alignSelf: 'center',
  },
  userInfo: {
    flexWrap: 'wrap',
    flex: 1,
    marginLeft: 15,
  },
})

export const areEqual = (
  prevProps: Readonly<React.PropsWithChildren<Props>>,
  nextProps: Readonly<React.PropsWithChildren<Props>>
): boolean => {
  return (
    prevProps.isPC === nextProps.isPC &&
    prevProps.user.id === nextProps.user.id &&
    prevProps.user.following === nextProps.user.following
  )
}
export default React.memo(FriendSuggestionItem, areEqual)
