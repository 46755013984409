import React from 'react'
import { View, ViewStyle } from 'react-native'

import CategoryTitle from '~/components/career/molecules/resume/CategoryTitle'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  category?: string
  children: React.ReactNode
  templateType: TemplateType
}

const ResumeBaseGroup: React.FC<Props> = ({
  children,
  category,
  templateType,
}: Props) => {
  const containerStyle = {
    enjin: undefined,
    shades: undefined,
    formal: { flexDirection: 'row' },
    minimal: undefined,
  }

  const contentStyle = {
    enjin: baseContentStyle,
    shades: { ...baseContentStyle, marginBottom: 0 },
    formal: { ...baseContentStyle, marginLeft: 10 },
    minimal: baseContentStyle,
  }

  const titleContainerStyle = {
    enjin: undefined,
    shades: { marginBottom: 10 },
    formal: { width: 200 },
    minimal: undefined,
  }

  return (
    <View style={containerStyle[templateType] as ViewStyle}>
      {category && (
        <View style={titleContainerStyle[templateType] as ViewStyle}>
          <CategoryTitle title={category || ''} templateType={templateType} />
        </View>
      )}
      <View
        style={contentStyle[templateType] as ViewStyle}
        testID="resume-base-group"
      >
        {children}
      </View>
    </View>
  )
}

const baseContentStyle = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  flex: 1,
}

export default ResumeBaseGroup
