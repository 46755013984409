import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import CancelButton from '~/components/common/atoms/CancelButton'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import ChatThread from '~/interfaces/ChatThread'

type Props = {
  visible: boolean
  onFinish?: () => void
  chatThread?: ChatThread
  onUpdate?: (chatThread: ChatThread) => void
}

const ThreadNameEditDialog: React.FC<Props> = ({
  chatThread,
  visible,
  onUpdate,
  onFinish,
}: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState(chatThread?.name ?? '')

  const submit = async (): Promise<void> => {
    if (!chatThread?.id || isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const response = (await api.chatThreads.update(chatThread?.id, {
        name,
      })) as ChatThread
      onUpdate?.(response)
      onFinish?.()
    } catch (error) {
      setIsLoading(false)
      toast.showError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalContainer visible={visible} onDismiss={onFinish}>
      <View testID="thread-edit-form" style={styles.container}>
        <TitleText>{t('message.editChatNameTitle')}</TitleText>
        <View style={styles.separator} />
        <TextField maxLength={50} onChangeText={setName} value={name} />
        <View style={styles.footer}>
          <CancelButton onPress={onFinish} />
          <Button
            onPress={submit}
            title={t('save')}
            containerStyle={styles.submit}
            testID="thread-edit-form-submit"
            titleStyle={commonStyles.buttonTextSize}
            disabled={isEmpty(name) || isLoading || name === chatThread?.name}
          />
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    maxWidth: 700,
    padding: 40,
    maxHeight: 200,
    ...commonStyles.borderRadiusAllCorner,
  },
  footer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  separator: {
    marginTop: 10,
  },
  submit: {
    width: 120,
    alignSelf: 'flex-end',
    marginLeft: 10,
  },
})

export default ThreadNameEditDialog
