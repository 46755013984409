import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'

import CrossIcon from '~/components/common/atoms/CrossIcon'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import Color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  visible: boolean
  onPressDismiss: () => void
}

const JobApplyResultDialog: React.FC<Props> = ({ visible, onPressDismiss }) => {
  const { t, i18n } = useTranslation()
  const textStyles = [
    styles.text,
    { fontFamily: getMediumFontByLanguage(i18n.language) },
  ]

  return (
    <ModalContainer visible={visible} onDismiss={onPressDismiss}>
      <View testID="job-apply-result-dialog" style={styles.container}>
        <TouchableOpacity style={styles.closeButton}>
          <CrossIcon onPress={onPressDismiss} />
        </TouchableOpacity>
        <Image
          style={styles.image}
          source={require('~/assets/images/job-applied.png')}
        />
        <View style={styles.contentBox}>
          <Text style={textStyles}>{t('jobs.successfullyApplied')}</Text>
          <Text style={textStyles}>{t('jobs.messageThreadOpened')}</Text>
          <View style={styles.navigation}>
            {i18n.language === 'ja' ? (
              <>
                <Link to="/career/messages" onPress={onPressDismiss}>
                  <Text style={styles.link}>
                    {t('jobs.jobApplyResultDialog.target')}
                  </Text>
                </Link>
                <Text style={textStyles}>
                  {`${t('jobs.jobApplyResultDialog.goTo')} ${t(
                    'jobs.jobApplyResultDialog.toDo'
                  )}`}
                </Text>
              </>
            ) : (
              <>
                <Text style={textStyles}>
                  {t('jobs.jobApplyResultDialog.goTo')}
                </Text>
                <Link to="/career/messages" onPress={onPressDismiss}>
                  <Text style={styles.link}>
                    {t('jobs.jobApplyResultDialog.target')}
                  </Text>
                </Link>
                <Text style={textStyles}>
                  {t('jobs.jobApplyResultDialog.toDo')}
                </Text>
              </>
            )}
          </View>
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
  },
  container: {
    backgroundColor: Color.white,
    borderRadius: 4,
    flex: 1,
    padding: 36,
    maxWidth: 400,
  },
  contentBox: {
    marginTop: 20,
  },
  image: {
    height: 180,
    resizeMode: 'contain',
    width: 'auto',
  },
  link: {
    color: Color.pressableText,
    textDecorationLine: 'underline',
    marginHorizontal: 2,
  },
  navigation: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    fontSize: FontSize.SUB,
    paddingBottom: 8,
    textAlign: 'center',
  },
})

export default JobApplyResultDialog
