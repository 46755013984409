import React from 'react'
import { CheckBox as RNECheckBox, CheckBoxProps } from 'react-native-elements'

import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'

const CheckBox: React.FC<CheckBoxProps> = (props: CheckBoxProps) => (
  <RNECheckBox
    {...props}
    textStyle={[
      { fontFamily: getFontByLanguage(getCurrentLanguage()) },
      props.textStyle,
    ]}
    size={24}
  />
)

export default CheckBox
