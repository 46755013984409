export enum QuizCategoryType {
  KEIGO = 'keigo',
  BUSSINESS_MANNER = 'business_manner',
  COMMON_SENSE = 'common_sense',
  KANJI = 'kanji',
  JAPANESE = 'japanese',
  CURRENT_PROBLEMS = 'current_problems',
  GEOGRAPHY = 'geography',
}

export default interface QuizCategory {
  id: string
  name: string
}
