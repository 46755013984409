import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import ChannelEditForm from '~/components/community/features/channels/ChannelEditForm'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'

type Props = {
  isEdit?: boolean
  visibleModal: boolean
  onDismiss: () => void
}

const ChannelEditDialog: React.FC<Props> = ({
  isEdit,
  onDismiss,
  visibleModal,
}: Props) => {
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const modalStyle = isPC ? styles.pcModalBody : styles.mobileModalBody
  const community = useSelector((state: RootState) => state.community.current)

  return (
    <ModalContainer onDismiss={onDismiss} visible={visibleModal}>
      <View style={modalStyle} testID="channel-edit-dialog">
        <ChannelEditForm
          isEdit={isEdit}
          onBack={onDismiss}
          communityId={community?.id || 0}
        />
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  mobileModalBody: {
    alignSelf: 'center',
    width: '100%',
  },
  pcModalBody: {
    backgroundColor: color.white,
    width: 504,
  },
})

export default ChannelEditDialog
