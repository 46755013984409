import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import ResumeTitlePopup from '~/components/career/features/resume/ResumeTitlePopup'
import EditResumeTemplate from '~/components/career/templates/resume/EditResumeTemplate'
import { ResumeContextProvider } from '~/contexts/ResumeContext'
import resumeI18n, { changeLanguage } from '~/plugins/resume_language'
import { RootState } from '~/rootReducer'
import {
  currentResumeSelector,
  fetchResumeDetail,
} from '~/slices/career/resumes'
import { RouteParamsID } from '~/utils/navigation'

const ResumeEdit: React.FC = () => {
  const route = useRoute()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const resume = useSelector(currentResumeSelector)

  const resumeId = (route?.params as RouteParamsID)?.id
  const [isLoadingResume, setLoading] = useState(false)
  const showTitlePopup = useSelector(
    (state: RootState) => state.resumes.showTitlePopup
  )

  const fetchResume = (): void => {
    setLoading(true)
    resumeId &&
      Promise.all([dispatch(fetchResumeDetail(resumeId))]).finally(() =>
        setLoading(false)
      )
  }

  useEffect(() => {
    resumeId && fetchResume()
  }, [dispatch, resumeId])

  useEffect(() => {
    navigation.setOptions({ title: resumeI18n.t('resume.title.edit') })
  }, [])

  useEffect(() => {
    resume.language && changeLanguage(resume.language)
  }, [resume.language])

  return (
    <I18nextProvider i18n={resumeI18n}>
      <ResumeContextProvider language={resume.language}>
        <DefaultLayout>
          {isLoadingResume ? <View testID="loading" /> : <EditResumeTemplate />}
          {showTitlePopup && <ResumeTitlePopup type="update" />}
        </DefaultLayout>
      </ResumeContextProvider>
    </I18nextProvider>
  )
}

export default ResumeEdit
