import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'

import CommunityName from '~/components/community/atoms/CommunityName'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import Community from '~/interfaces/Community'

type Props = {
  community: Community
}

const CommunityItemContent: React.FC<Props> = ({ community }: Props) => {
  const { isPC } = useContext(RootContext)

  return (
    <View style={styles.descriptionContainer} testID="community-item-content">
      <View style={styles.description}>
        <CommunityName size={isPC ? 'medium' : 'small'} community={community} />
        <Text style={styles.subject} ellipsizeMode="tail" numberOfLines={1}>
          {community.description.replace(/(\r\n|\n|\r)/gm, '')}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    alignSelf: 'baseline',
    flex: 1,
  },
  descriptionContainer: {
    flexDirection: 'row',
    flex: 1,
    padding: 20,
  },
  subject: {
    fontSize: FontSize.SUB,
    fontWeight: '300',
    paddingVertical: 2,
  },
})

export default CommunityItemContent
