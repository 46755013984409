import React from 'react'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import color from '~/constants/common/color'

type Props = {
  companyMemberElement: React.ReactElement
  companyAdminElement: React.ReactElement
  companyInvitationElement: React.ReactElement
}

const CompanyProfiles: React.FC<Props> = ({
  companyMemberElement,
  companyAdminElement,
  companyInvitationElement,
}: Props) => (
  <View testID="company-profiles">
    <CardContainer testID="member-company-profiles" style={styles.container}>
      {companyMemberElement}
    </CardContainer>
    <CardContainer testID="admin-company-profiles" style={styles.container}>
      {companyAdminElement}
      {companyInvitationElement}
    </CardContainer>
  </View>
)

const styles = StyleSheet.create({
  container: {
    padding: 28,
    backgroundColor: color.white,
    marginBottom: 20,
  },
})

export default CompanyProfiles
