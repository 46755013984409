import { filter } from 'lodash'
import React, { useState } from 'react'
import { ListRenderItem, StyleSheet, View } from 'react-native'

import SearchInput from '~/components/common/atoms/SearchInput'
import TitleText from '~/components/common/atoms/TitleText'
import FlatList from '~/components/workarounds/FlatList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  title?: string
  data: string[]
  onPressItem?: (value: string, index: number) => void
}

const SearchableList: React.FC<Props> = ({
  data,
  title,
  onPressItem,
}: Props) => {
  const [visibleData, setVisibleData] = useState(data)
  const [searchWord, setSearchWord] = useState('')

  const renderItem: ListRenderItem<string> = ({
    item,
    index,
  }: {
    item: string
    index: number
  }) => (
    <Text
      style={styles.item}
      testID="searchable-list-item"
      onPress={() => onPressItem?.(item, index)}
    >
      {item}
    </Text>
  )

  const keyExtractor = (item: string): string => item.toString()

  const updateVisibleData = (filterValue: string) => {
    setSearchWord(filterValue)
    setVisibleData(
      filter(data, (item) =>
        item.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
      )
    )
  }

  return (
    <View testID="searchable-list" style={styles.container}>
      {title && <TitleText style={styles.title}>{title}</TitleText>}
      <View style={styles.searchContainer} testID="search-input-container">
        <SearchInput value={searchWord} onChangeText={updateVisibleData} />
      </View>
      <FlatList
        data={visibleData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        scrollEnabled={true}
        initialNumToRender={20}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    paddingVertical: 4,
  },
  searchContainer: {
    paddingBottom: 10,
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
  },
  title: {
    alignSelf: 'center',
    marginBottom: 10,
  },
})

export default SearchableList
