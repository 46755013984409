import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  onPress?: () => void
}

const ReplyButton: React.FC<Props> = ({ onPress }: Props) => {
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
      testID="reply-button"
    >
      <Text style={styles.actionReply}>{t('comment.reply')}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  actionReply: {
    fontSize: FontSize.SUB,
    color: color.pressableText,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default ReplyButton
