import React from 'react'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  GestureResponderEvent,
} from 'react-native'

import ResumeItemContent from '~/components/career/molecules/resume/ResumeItemContent'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import SelectedMask from '~/components/common/atoms/SelectedMask'
import { resumeDefaultImages } from '~/constants/career/resumes'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Resume from '~/interfaces/Resume'
import { openURL } from '~/utils/navigation'

type Props = {
  isPC: boolean
  resume: Resume
  isSelected?: boolean
  controlMenu?: React.ReactElement
  pressMode?: 'partial' | 'whole-content'
  onPress?: (event: GestureResponderEvent) => void
}

const ResumeItem: React.FC<Props> = ({
  isPC,
  resume,
  onPress,
  isSelected,
  controlMenu,
  pressMode = 'partial',
}: Props) => {
  const pressResumeItem = (): void => {
    openURL(`/resumes/${resume.id}`)
  }

  const resumeContentView = (
    <View
      pointerEvents={pressMode === 'whole-content' ? 'none' : 'auto'}
      style={StyleSheet.flatten([
        styles.container,
        isPC ? styles.containerSizePC : styles.containerSizeMobile,
      ])}
      key={resume.id}
      testID="resume-item"
    >
      <TouchableOpacity testID="resume-image" onPress={pressResumeItem}>
        <Image
          resizeMode="cover"
          source={resumeDefaultImages[resume.templateType]}
          style={StyleSheet.flatten(styles.resumeAvatar)}
        />
      </TouchableOpacity>
      <HorizontalContainer>
        <ResumeItemContent resume={resume} />
        {pressMode !== 'whole-content' && (
          <View
            style={styles.controlMenuContainer}
            testID="resume-control-menu-container"
          >
            {controlMenu}
          </View>
        )}
      </HorizontalContainer>
      {isSelected && <SelectedMask />}
    </View>
  )

  return pressMode === 'whole-content' ? (
    <TouchableOpacity onPress={onPress} testID="resume-item-touchable">
      {resumeContentView}
    </TouchableOpacity>
  ) : (
    resumeContentView
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    borderRadius: 10,
    marginHorizontal: 5,
    ...commonStyles.shadow,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  controlMenuContainer: {
    marginRight: 20,
  },
  resumeAvatar: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 135,
    width: '100%',
  },
})

export default ResumeItem
