import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Skill from '~/interfaces/Skill'

type Props = {
  onClickRemoveIcon: () => void
  skill: Partial<Skill>
}

const SkillText: React.FC<Props> = ({ onClickRemoveIcon, skill }: Props) => {
  return (
    <View testID="skill-text" style={styles.container}>
      <Text style={styles.name}>{skill.name}</Text>
      <TouchableOpacity
        onPress={onClickRemoveIcon}
        testID="remove-icon-container"
      >
        <Text style={styles.removeIcon}>✕</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 2,
    marginTop: 4,
    padding: 6,
    ...commonStyles.borderRadiusAllCorner,
  },
  name: {
    alignSelf: 'center',
    color: color.white,
    flexWrap: 'wrap',
    marginRight: 6,
  },
  removeIcon: {
    alignSelf: 'center',
    color: color.white,
    fontSize: 20,
  },
})

export default SkillText
