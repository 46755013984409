import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import DatePicker from '~/components/common/molecules/DatePicker'
import CountryPicker from '~/components/common/molecules/users/CountryPicker'
import FullNameInput from '~/components/common/molecules/users/FullNameInput'
import GenderPicker from '~/components/common/molecules/users/GenderPicker'
import Profile from '~/interfaces/Profile'

type Props = {
  profile: Partial<Profile>
  isPC?: boolean
}

const BasicInfoForm: React.FC<Props> = ({ profile }: Props) => {
  const { t } = useTranslation()

  const [givenName, setGivenName] = useState(profile.givenName)
  const [familyName, setFamilyName] = useState(profile.familyName)
  const [birthDate, setBirthDate] = useState(profile.birthday)

  const handleChange = (
    value: string,
    name: string,
    setter?: Function
  ): void => {
    Object.assign(profile, { [name]: value })
    if (setter) {
      setter(value)
    }
  }

  return (
    <View testID="basic-info-form">
      <FullNameInput
        isPC={false}
        givenName={givenName}
        familyName={familyName}
        onChangeLastName={(value): void =>
          handleChange(value, 'givenName', setGivenName)
        }
        onChangeFirstName={(value): void =>
          handleChange(value, 'familyName', setFamilyName)
        }
      />
      <CountryPicker
        onChange={(value): void => handleChange(value, 'country')}
        selected={profile.country}
        size="full"
        title={t('profile.nationality')}
      />
      <DatePicker
        title={t('profile.birthdate')}
        onChange={(value): void =>
          handleChange(value, 'birthday', setBirthDate)
        }
        selectedDate={birthDate}
        initialDate={moment('1997-01-01', 'YYYY-MM-DD').toDate()}
      />
      <GenderPicker
        onChange={(value): void => handleChange(value, 'sex')}
        selected={profile.sex}
      ></GenderPicker>
    </View>
  )
}

export default BasicInfoForm
