import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  content: React.ReactNode
}

const Badge: React.FC<Props> = ({ content }: Props) => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.content}>{content}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    color: color.textGray,
    fontSize: 14,
    lineHeight: 20,
  },
  wrapper: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: color.gray,
  },
})

export default Badge
