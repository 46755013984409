import * as Factory from 'factory.ts'

import { imageFactory } from '~/factories/image'
import Achievement from '~/interfaces/Achievement'

const image = imageFactory.build()

export const achievementFactory = Factory.makeFactory<Achievement>({
  id: Factory.each((i) => i),
  title: 'Poster: Affective Haptic Furniture',
  category: 'UBICOMP Conference',
  time: 'Aug 2018',
  image: image,
  name: 'English Communication',
  giver: 'UbiComp Conference',
  awardedDate: '08-2018',
  description: 'Designed and developed a new affective haptic furniture',
  userResumeId: 2,
})
