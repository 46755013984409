import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import React from 'react'
import { View, StyleSheet } from 'react-native'

import TabMenuBadge from '~/components/common/atoms/TabMenuBadge'
import color from '~/constants/common/color'

type Props = {
  name: string
  focused: boolean
  badgeCount?: number
}

export const getIconName = (routeName: string): string => {
  switch (routeName) {
    case 'home':
      return 'home'
    case 'career':
      return 'home'
    case 'forum':
      return 'home'
    case 'community':
      return 'home'
    case 'mypage':
      return 'account'
    case 'communities':
      return 'account-group'
    case 'questions':
      return 'forum'
    case 'jobs':
    case 'jobsDashboard':
      return 'briefcase'
    case 'messages':
      return 'email'
    case 'resumeIndex':
      return 'comment-multiple'
    case 'companyPanel':
      return 'view-agenda'
    default:
      return ''
  }
}

const TabMenuButton: React.FC<Props> = (props: Props) => {
  const { name, focused, badgeCount } = props

  return (
    <View
      testID={`bottom-tab-button-${name}${focused ? '-focused' : ''}`}
      style={styles.body}
    >
      <Icon
        name={`${getIconName(name)}-outline`}
        style={focused ? styles.iconFocused : styles.icon}
        size={24}
      />
      {badgeCount ? <TabMenuBadge value={badgeCount} /> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    color: color.menuIcon,
  },
  iconFocused: {
    color: color.primary,
  },
})

export default TabMenuButton
