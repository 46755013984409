import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ControlMenu from '~/components/common/molecules/ControlMenu'
import {
  ChannelControlMenuProps,
  ChannelControlMenuType,
} from '~/interfaces/ChannelControlMenu'

const ChannelControlMenuPC: React.FC<ChannelControlMenuProps> = ({
  onSelect,
}) => {
  const { t } = useTranslation()

  return (
    <View testID="channel-control-menu-pc">
      <ControlMenu
        data={[
          {
            id: ChannelControlMenuType.EditChannel,
            title: t('community.channel.editChannel'),
          },
        ]}
        onSelect={(item): void =>
          onSelect && onSelect(item.id as ChannelControlMenuType)
        }
        menuTrigger={<ControlMenuButton inverted={true} />}
      />
    </View>
  )
}

export default ChannelControlMenuPC
