import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import DatePicker from '~/components/common/molecules/DatePicker'
import CountryPicker from '~/components/common/molecules/users/CountryPicker'
import GenderPicker from '~/components/common/molecules/users/GenderPicker'
import { FontSize } from '~/constants/common/font'
import Profile from '~/interfaces/Profile'

type Props = {
  onBirthdateChange: (value: string) => void
  onGenderChange: (value: string) => void
  onCountryChange: (value: string) => void
  registrationData: Partial<Profile>
  isPC?: boolean
}

const PickerGroup: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="picker-group">
      <DatePicker
        title={t('profile.birthdate')}
        size={FontSize.GENERAL}
        onChange={props.onBirthdateChange}
        selectedDate={props.registrationData.birthday}
        maxDate={new Date()}
        initialDate={moment('1997-01-01', 'YYYY-MM-DD').toDate()}
      />
      <GenderPicker
        size={FontSize.GENERAL}
        onChange={props.onGenderChange}
        selected={props.registrationData.sex}
      />
      <View style={styles.countryPicker}>
        <CountryPicker
          isPC={props.isPC}
          title={t('profile.nationality')}
          onChange={props.onCountryChange}
          selected={props.registrationData.country}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  countryPicker: {
    marginTop: 20,
  },
})

export default PickerGroup
