import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import MenuIcon from '~/components/common/molecules/MenuIcon'
import NotificationIcon from '~/components/common/molecules/NotificationIcon'
import OutsiderGuideLinks from '~/components/common/molecules/auth/OutsiderGuideLinks'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { DrawerType, openDrawer } from '~/slices/common/drawer'
import { fetchNotifications } from '~/slices/common/notifications'
import { isCommunityNotification } from '~/utils/common/notification'
import { closeDrawer } from '~/utils/navigation'

const CommunityHeaderRight: React.FC = () => {
  const dispatch = useDispatch()
  const currentDrawerType = useSelector(
    (state: RootState) => state.drawer.currentDrawerType
  )
  const { isPC } = useDevice()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const isDrawerOpen = useSelector(
    (state: RootState) => state.drawer.isDrawerOpen
  )
  const showDrawer = (drawerType: DrawerType, isOpened: boolean): void => {
    if (!isOpened) {
      closeDrawer()
    } else {
      dispatch(openDrawer(drawerType))
    }
  }

  const isActiveNotificationIcon =
    isDrawerOpen && currentDrawerType === DrawerType.Notifications

  const unreadCount = useSelector(
    (state: RootState) => state.notifications.unreadCount
  )

  useEffect(() => {
    accessToken &&
      dispatch(
        fetchNotifications((notification) =>
          isCommunityNotification(notification)
        )
      )
  }, [accessToken])

  const menuView = (
    <View style={styles.container} testID="community-header-right">
      <NotificationIcon
        onPress={(): void => {
          showDrawer(DrawerType.Notifications, !isActiveNotificationIcon)
        }}
        isActive={isActiveNotificationIcon}
        unreadNotificationCount={unreadCount.unreadNotificationsCount}
      />
      <MenuIcon />
    </View>
  )

  if (isPC) {
    return menuView
  }

  return accessToken ? menuView : <OutsiderGuideLinks />
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: 20,
  },
})

export default CommunityHeaderRight
