import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, SectionList, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import {
  EventFilterKeys,
  EventQueryApiParams,
  EventQueryParams,
} from '~/api/communities/communityEvents'
import emptyEventsImage from '~/assets/images/prompts/noeventsyet.png'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import EmptyView from '~/components/common/molecules/EmptyView'
import CreateEventButton from '~/components/community/atoms/CreateEventButton'
import EventEditDialog from '~/components/community/features/EventEditDialog'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import EventControlMenu, {
  EventMenuType,
} from '~/components/community/molecules/EventControlMenu'
import EventFilterPopup from '~/components/community/molecules/EventFilterPopup'
import EventItemInfo from '~/components/community/molecules/EventItemInfo'
import EventItem from '~/components/community/organisms/EventItem'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityEvent from '~/interfaces/CommunityEvent'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { getRouteParams } from '~/utils/navigation'

type Props = {
  communityId: number
  onSelect?: (item: CommunityEvent) => void
}

type EventData = {
  title?: string
  data: CommunityEvent[]
}

const EventList: React.FC<Props> = ({ onSelect, communityId }: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { isPC } = useDevice()
  const todayMomentInput = moment(new Date())
  const [currentEvent, setCurrentEvent] = useState<CommunityEvent | undefined>(
    undefined
  )
  const [communityEvents, setCommunityEvents] = useState<CommunityEvent[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const [isShowEventDialog, setIsShowEventDialog] = useState(false)
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<
    CommunityEvent | undefined
  >(undefined)
  const [isVirtualEvent, setIsVirtualEvent] = useState<boolean | null>(null)
  const isCommunityMember = useSelector(
    (state: RootState) => state.community.isCommunityMember
  )
  const currentUser = useSelector(currentUserSelector)

  const happeningNowEvents = communityEvents.filter(
    (item) =>
      moment(item.startAt).isBefore(todayMomentInput) &&
      moment(item.endAt).isAfter(todayMomentInput)
  )

  const eventQueryParams = getRouteParams<EventQueryParams>()

  let events: EventData[] = []

  if (!isEmpty(happeningNowEvents)) {
    events = [
      {
        title: t('events.happeningNow'),
        data: happeningNowEvents,
      },
      {
        title: t('events.allEvents'),
        data: communityEvents,
      },
    ]
  } else {
    events = [
      {
        title: t('events.allEvents'),
        data: communityEvents,
      },
    ]
  }

  const renderItem: ListRenderItem<CommunityEvent> = useCallback(
    ({ item }: { item: CommunityEvent; index: number }): JSX.Element => (
      <EventItem
        event={item}
        controlMenu={
          currentUser?.id === item.createdUser.id ? (
            <EventControlMenu
              onSelect={(type): void => executeCommunityAction(type, item)}
            />
          ) : undefined
        }
        eventInfoView={
          <EventItemInfo
            isPC={isPC}
            event={item}
            onPressEventName={(): void => onSelect && onSelect(item)}
          />
        }
      />
    ),
    [isPC]
  )

  const changeVirtualEvent = () => {
    switch (eventQueryParams?.filterBy) {
      case EventFilterKeys.VIRTUAL_EVENT:
        setIsVirtualEvent(true)
        break
      case EventFilterKeys.REAL_WORLD_EVENT:
        setIsVirtualEvent(false)
        break
      default:
        setIsVirtualEvent(null)
        break
    }
  }

  const fetchEvents = useAPI(
    async (api: API, page: number, isVirtual: boolean): Promise<void> => {
      if (!communityId || isLoading) {
        return
      }

      setIsLoading(true)
      try {
        const response = await api.communityEvents
          .configPath(communityId)
          .index<CommunityEvent[], EventQueryApiParams>({
            page,
            isVirtual: isVirtual?.toString(),
          })
        if (!isEmpty(response)) {
          if (page > currentPage) {
            setCurrentPage(page)
            setCommunityEvents((communityEvents) => [
              ...communityEvents,
              ...response,
            ])
          } else {
            setCommunityEvents(response)
          }
        }
      } catch (error) {
        toast.showError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [communityId, setIsLoading, setCommunityEvents, setCurrentPage]
  )

  const fetchMore = async (): Promise<void> => fetchEvents(currentPage + 1)

  useFocusEffect(
    React.useCallback(() => {
      fetchEvents(currentPage, isVirtualEvent)
    }, [fetchEvents, isVirtualEvent])
  )

  useFocusEffect(
    React.useCallback(() => {
      isBottom && fetchMore()
    }, [isBottom])
  )

  useFocusEffect(
    React.useCallback(() => {
      changeVirtualEvent()
    }, [eventQueryParams?.filterBy])
  )

  const executeCommunityAction = (
    type: EventMenuType,
    event: CommunityEvent
  ) => {
    switch (type) {
      case EventMenuType.Edit:
        showEditEventDialog(event)
        break
      case EventMenuType.Delete:
        showConfirmationDialog(event)
        break
    }
  }

  const showConfirmationDialog = (event: CommunityEvent): void => {
    setSelectedEvent(event)
    setIsShowConfirmDialog(true)
  }

  const hideConfirmDialog = (): void => {
    setSelectedEvent(undefined)
    setIsShowConfirmDialog(false)
  }

  const hideCreateEventDialog = (): void => {
    setIsShowEventDialog(false)
    setCurrentEvent(undefined)
  }

  const showEditEventDialog = (event: CommunityEvent): void => {
    setIsShowEventDialog(true)
    setCurrentEvent(event)
  }

  const addCommunityEvent = (communityEvent: CommunityEvent): void => {
    setCommunityEvents((events) => [communityEvent, ...events])
    hideCreateEventDialog()
  }

  const updateEvent = (communityEvent: CommunityEvent): void => {
    setCommunityEvents(
      communityEvents.map((event) =>
        event.id === communityEvent.id ? { ...event, ...communityEvent } : event
      )
    )
    hideCreateEventDialog()
  }

  const deleteEvent = async (): Promise<void> => {
    if (!selectedEvent) {
      return
    }
    try {
      await api.communityEvents.configPath(communityId).delete(selectedEvent.id)
      setCommunityEvents(
        communityEvents.filter((event) => event.id !== selectedEvent.id)
      )
      hideConfirmDialog()
    } catch (error) {
      //TODO show error
    }
  }

  return (
    <View
      testID="event-list"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <SectionList
        style={styles.containerList}
        sections={events}
        renderItem={renderItem}
        extraData={currentEvent}
        ListHeaderComponent={
          <>
            {!isPC && <HeaderCommunityDetail />}
            {isCommunityMember && (
              <View style={styles.header}>
                <CreateEventButton
                  onPress={(): void => setIsShowEventDialog(true)}
                />
              </View>
            )}
          </>
        }
        keyExtractor={(item, index): string => `${item.id}-${index}`}
        renderSectionHeader={({ section }): React.ReactElement => (
          <View
            style={[
              styles.headerSection,
              !isPC ? styles.headerSectionMobile : undefined,
            ]}
          >
            <Text style={styles.title}>{section.title}</Text>
            {section.title === events[0].title && <EventFilterPopup />}
          </View>
        )}
        renderSectionFooter={({ section: { data } }): JSX.Element | null =>
          isEmpty(data) && !isLoading ? (
            <EmptyView
              isPC={isPC}
              title={t('events.empty')}
              imageSource={emptyEventsImage}
            />
          ) : null
        }
      />
      {isShowEventDialog && (
        <EventEditDialog
          event={currentEvent}
          onUpdate={updateEvent}
          onCreated={addCommunityEvent}
          onFinish={hideCreateEventDialog}
        />
      )}
      {isShowConfirmDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('delete')}
          onPressAccept={deleteEvent}
          visible={isShowConfirmDialog}
          onPressCancel={hideConfirmDialog}
          message={t('events.confirmDeleteEvent')}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
  },
  containerList: {
    paddingBottom: 2,
  },
  containerMobile: {
    flex: 1,
    paddingBottom: 20,
  },
  header: {
    paddingHorizontal: 40,
    paddingTop: 20,
  },
  headerSection: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  headerSectionMobile: {
    marginHorizontal: 20,
  },
  title: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
})
export default EventList
