import { useLinkTo } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Category from '~/interfaces/Category'
import i18n from '~/plugins/i18n'

type Props = {
  categories: Category[]
}

const CategoriesGroup: React.FC<Props> = ({ categories }: Props) => {
  const linkTo = useLinkTo()

  const goToQuestions = (item: Category): void => {
    linkTo(`/forum/questions?categories=${item.name}`)
  }

  return (
    <View style={styles.container} testID="categories-group">
      {categories.map((item, index) => (
        <Text
          testID="category"
          style={StyleSheet.flatten([
            styles.category,
            { backgroundColor: item.color },
          ])}
          key={`${item.name}${index}`}
          onPress={(): void => goToQuestions(item)}
        >
          {i18n.language === 'ja' ? item.title : item.enTitle}
        </Text>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  category: {
    color: color.white,
    fontSize: FontSize.SUB,
    marginRight: 5,
    marginTop: 4,
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 10,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

export default CategoriesGroup
