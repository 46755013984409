import React from 'react'
import { StyleSheet, View, ViewStyle, ImageBackground } from 'react-native'

import color from '~/constants/common/color'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  leftColumn?: React.ReactElement
  rightColumn?: React.ReactElement
  templateType: TemplateType
}

const TemplateHeader: React.FC<Props> = ({
  leftColumn,
  rightColumn,
  templateType,
}: Props) => {
  const containerStyle = {
    enjin: {
      ...containerStyleObject,
      backgroundColor: color.primary,
    },
    shades: {
      ...containerStyleObject,
      backgroundColor: color.textGray,
    },
    formal: containerStyleObject,
    minimal: containerStyleObject,
  }

  const backgroundSource = {
    enjin: {},
    shades: require('~/assets/images/resumes/shades_header.png'),
    formal: {},
    minimal: {},
  }

  const leftColumnStyle = {
    enjin: baseLeftColumn,
    shades: baseLeftColumn,
    formal: {
      width: 280,
      justifyContent: 'center',
      paddingRight: 20,
    },
    minimal: baseLeftColumn,
  }

  return (
    <ImageBackground
      testID="template-header"
      source={backgroundSource[templateType]}
      style={containerStyle[templateType] as ViewStyle}
    >
      <View
        testID="template-header-left-column"
        style={leftColumnStyle[templateType] as ViewStyle}
      >
        {leftColumn}
      </View>
      <View testID="template-header-right-column" style={styles.rightColumn}>
        {rightColumn}
      </View>
    </ImageBackground>
  )
}

const containerStyleObject = {
  height: 218,
  flexDirection: 'row',
  paddingHorizontal: 40,
}

const baseLeftColumn = {
  width: 280,
  justifyContent: 'center',
}

const styles = StyleSheet.create({
  rightColumn: {
    justifyContent: 'center',
    flex: 1,
  },
})

export default TemplateHeader
