export enum UserSection {
  INFO = 'info',
  FORUM = 'forum',
  TIMELINE = 'timeline',
  QUIZ = 'my_quiz',
  FOLLOWERS = 'followers',
  FOLLOWING = 'following',
  ANSWERS = 'answer',
  QUESTIONS = 'questions',
}

export enum JobSection {
  FEED = '',
  APPLIED = 'applied',
  SAVED = 'saved',
}

export enum CommunitySection {
  TALK = 'talk',
  EVENTS = 'events',
  INFO = 'information',
  MEMBERS = 'members',
  ADMIN = 'admin-panel',
}

export enum CompanySection {
  PROFILES = 'profiles',
  JOB_POSTS = 'job-posts',
  RECRUITMENTS = 'recruitments',
  APPLICANTS = 'applicants',
  OVERVIEW = 'overview',
  ACTIVE_JOBS = 'active',
  ENDED_JOBS = 'ended',
  ACCOUNT_MANAGEMENT = 'account-management',
}

export const currentRoutePathKey = 'current-route-path-key'
