import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Item, {
  ItemMode,
} from '~/components/common/molecules/media/MediaPreview/Item'
import Media from '~/interfaces/Media'

type Props = {
  media: Media[]
  retry: boolean
  onPress?: (media?: Media) => void
  onRemove?: (media?: Media) => void
}

const getMode = (length: number, index: number): ItemMode => {
  switch (length) {
    case 1:
      return 'single'
    case 2:
      return 'twin'
    default:
      switch (index) {
        case 0:
          return 'main'
        case 1:
          return 'topRight'
        default:
          return 'remaining'
      }
  }
}

type EvenLayoutItemProps = {
  media: Media[]
  index: number
  removeIconSize?: number
  retry: boolean
  onPress?: (media?: Media) => void
  onRemove?: (media?: Media) => void
}

const useMediaCallback = (
  handler?: (media?: Media) => void,
  media?: Media
): ((media?: Media) => void) =>
  useCallback(() => {
    if (handler) {
      handler(media)
    }
  }, [handler, media?.id, media?.type])

const EvenLayoutItem: React.FC<EvenLayoutItemProps> = ({
  media,
  index,
  retry,
  onPress,
  removeIconSize,
  onRemove,
}: EvenLayoutItemProps) => {
  const item = media[index]
  const pressHandler = useMediaCallback(onPress, item)
  const removeHandler = useMediaCallback(onRemove, item)

  return (
    <>
      {index > 0 && <View style={styles.verticalSeparator}></View>}
      <Item
        media={item}
        mode={getMode(media.length, index)}
        retry={retry}
        onPress={onPress ? pressHandler : undefined}
        removeIconSize={removeIconSize}
        onRemove={removeHandler}
      />
    </>
  )
}

const EvenLayout: React.FC<Props> = ({
  media,
  retry,
  onPress,
  onRemove,
}: Props) => {
  const removeIconSize = onRemove ? (media.length > 1 ? 22 : 28) : undefined
  const mediaViews = media.map((item, index) => {
    return (
      <EvenLayoutItem
        key={`${item.type}-${item.id}-${index}`}
        media={media}
        index={index}
        retry={retry}
        onPress={onPress}
        removeIconSize={removeIconSize}
        onRemove={onRemove}
      />
    )
  })

  return (
    <View testID="even-media-preview" style={styles.container}>
      {mediaViews}
    </View>
  )
}

const CompositeLayout: React.FC<Props> = ({
  media,
  retry,
  onPress,
  onRemove,
}: Props) => {
  const [first, second, ...remaining] = media
  const third = remaining[0]
  const firstPressHandler = useMediaCallback(onPress, first)
  const secondPressHandler = useMediaCallback(onPress, second)
  const remainingPressHandler = useMediaCallback(onPress, third)
  const removeHandler = useMediaCallback(onRemove)
  const [height, setHeight] = useState(0)

  return (
    <View
      testID="composite-media-preview"
      style={styles.container}
      onLayout={({ nativeEvent }) => setHeight(nativeEvent.layout.height)}
    >
      <Item
        key={`${first.type}-${first.id}-0`}
        media={first}
        mode={getMode(media.length, 0)}
        retry={retry}
        onPress={onPress ? firstPressHandler : undefined}
      />
      {media.length > 1 && <View style={styles.verticalSeparator}></View>}
      <View
        testID="right-side"
        style={StyleSheet.flatten([styles.rightSide, { height }])}
      >
        <Item
          key={`${second.type}-${first.id}-1`}
          media={second}
          mode={getMode(media.length, 1)}
          retry={retry}
          onPress={onPress ? secondPressHandler : undefined}
          removeIconSize={onRemove ? 26 : undefined}
          onRemove={removeHandler}
        />
        {media.length > 2 && <View style={styles.horizontalSeparator}></View>}
        <Item
          key={`${third.type}-${third.id}-2`}
          media={third}
          mode={getMode(media.length, 2)}
          maskText={remaining.length > 1 ? `+${remaining.length}` : undefined}
          retry={retry}
          onPress={onPress ? remainingPressHandler : undefined}
        />
      </View>
    </View>
  )
}

const MediaPreview: React.FC<Props> = ({ media, ...props }: Props) => {
  switch (media.length) {
    case 0:
      return null
    case 1:
    case 2:
      return <EvenLayout media={media} {...props} />
    default:
      return <CompositeLayout media={media} {...props} />
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 0,
  },
  horizontalSeparator: {
    height: 6,
  },
  rightSide: {
    flex: 1,
  },
  verticalSeparator: {
    width: 6,
  },
})

export default MediaPreview
