import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import InterestedFieldsForm from '~/components/career/molecules/resume/InterestedFieldsForm'
import InterestedJobsForm from '~/components/career/molecules/resume/InterestedJobsForm'
import useDevice from '~/hooks/commons/useDevice'
import Resume from '~/interfaces/Resume'
import {
  currentResumeSelector,
  updateCurrentResume,
} from '~/slices/career/resumes'

const EditResumeMainSecond: React.FC = () => {
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const resume = useSelector(currentResumeSelector)

  const handleChangeResume = (data: Partial<Resume>): void => {
    dispatch(updateCurrentResume(data))
  }

  return (
    <View testID="edit-resume-main-second">
      <View style={styles.separator} />
      <InterestedFieldsForm
        isPC={isPC}
        onChange={handleChangeResume}
        interestedIndustry={resume.interestedIndustry}
        interestedIndustryReason={resume.interestedIndustryReason}
      />
      <View style={styles.separator} />
      <InterestedJobsForm
        isPC={isPC}
        onChange={handleChangeResume}
        interestedJob={resume.interestedJob}
        interestedJobReason={resume.interestedJobReason}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  separator: {
    marginTop: 15,
  },
})
export default EditResumeMainSecond
