import React from 'react'

import QuestionEditForm from '~/components/forum/features/questions/QuestionEditForm'
import ForumFrame from '~/components/forum/templates/ForumFrame'

const QuestionNewTemplate: React.FC = () => {
  return (
    <ForumFrame>
      <QuestionEditForm />
    </ForumFrame>
  )
}

export default QuestionNewTemplate
