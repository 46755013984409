import { FontAwesome as Icon } from '@expo/vector-icons'
import { find, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import { View, StyleSheet } from 'react-native'

import countries from '~/assets/data/country.json'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import {
  PickerOption,
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import SearchableList from '~/components/common/molecules/SearchableList'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import i18n from '~/plugins/i18n'

export const countriesData = (lang: string): PickerOption[] => {
  const dataFromCountries: PickerOption[] = countries.map((item) => {
    return {
      label: lang === 'ja' ? item.name : item.en_name,
      value: item.id,
    }
  })
  dataFromCountries.unshift(pickerValue('', ''))
  return dataFromCountries
}

type Props = {
  onChange: (value: string) => void
  selected?: string
  isPC?: boolean
  size?: 'small' | 'medium' | 'large' | 'full'
  title?: string
  invertedText?: boolean
}

const CountryPicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [country, setCountry] = useState(props.selected)
  const [isShowDialog, setIsShowDialog] = useState(false)
  const countries = countriesData(i18n.language)
  const isPC = props.isPC
  const textColor = props.invertedText ? color.white : color.textGray

  const changeSelectedCountry = (value: string): void => {
    const selectedCountry =
      find(countries, { label: value })?.value.toString() ?? ''
    setCountry(selectedCountry)
    props.onChange(selectedCountry)
    setIsShowDialog(false)
  }

  return (
    <View testID="country-picker">
      {!isEmpty(props.title) && (
        <Text
          style={StyleSheet.flatten([
            styles.title,
            { fontSize: FontSize.GENERAL },
          ])}
        >
          {props.title}
        </Text>
      )}
      <View style={styles.pickerContainer} testID="country-picker-container">
        <Button
          onPress={() => setIsShowDialog(true)}
          title={
            find(countries, { value: country })?.label ?? t('selectCountry')
          }
          type="outline"
          buttonStyle={styles.button}
          testID="country-picker-button"
          titleStyle={StyleSheet.flatten([
            styles.buttonTitle,
            { color: textColor },
          ])}
          iconRight
          icon={
            <Icon
              name="caret-down"
              color={textColor}
              size={FontSize.GENERAL}
              style={styles.buttonIcon}
            />
          }
        />
      </View>
      <ModalContainer
        visible={isShowDialog}
        onDismiss={() => setIsShowDialog(false)}
      >
        <View
          style={isPC ? styles.searchDialog : styles.searchDialogMobile}
          testID="country-list-container"
        >
          <SearchableList
            title={t('selectCountry')}
            data={countries.map((item) => item.label)}
            onPressItem={changeSelectedCountry}
          />
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderColor: color.lightGray,
    ...Platform.select({
      ios: {
        paddingVertical: 10,
      },
      android: {
        paddingVertical: 10,
      },
      default: {
        // other platforms, web for example
        paddingVertical: 4,
      },
    }),
  },
  buttonIcon: {
    marginLeft: 8,
  },
  buttonTitle: {
    fontSize: FontSize.REMARKABLE,
  },
  pickerContainer: {
    flexDirection: 'row',
  },
  searchDialog: {
    padding: 20,
    backgroundColor: color.white,
    width: 420,
    height: 500,
    ...commonStyles.borderRadiusAllCorner,
  },
  searchDialogMobile: {
    padding: 10,
    backgroundColor: color.white,
    width: 300,
    height: 400,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    color: color.darkTitleText,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default CountryPicker
