import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import PrimarySearchHeader from '~/components/common/organisms/PrimarySearchHeader'

const QuizzesHeaderPC: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View testID="quizzes-header-pc">
      <PrimarySearchHeader
        title={t('quiz.myQuizHeaderTitle')}
        searchInputPlaceholder={t('quiz.quizSearchPlaceholder')}
      />
    </View>
  )
}

export default QuizzesHeaderPC
