import { TFunction } from 'i18next'
import { isNil } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CompanyServiceItem from '~/components/common/molecules/CompanyServiceItem'
import logo from '~/constants/common/logo'
import AppService from '~/interfaces/AppService'
import User from '~/interfaces/User'
import { isPaid } from '~/utils/common/subscription'

export const getServices = (
  translate: TFunction,
  isSubscribed: boolean
): (AppService | null)[] => [
  {
    title: translate('appService.accountDetail.title'),
    screen: 'companyPanel',
    image: require('~/assets/images/appServices/company_manager.png'),
    description: '',
    type: 'companyPanel',
    logo: logo.career.path,
  },
  !isSubscribed
    ? {
        title: translate('appService.premium.title'),
        screen: 'subscriptionPlan',
        image: require('~/assets/images/appServices/company_manager.png'),
        description: '',
        type: 'subscriptionPlan',
        logo: logo.career.path,
      }
    : null,
]

type Props = {
  user?: User
}

const CompanyServiceList: React.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="top-page-services" style={styles.container}>
      <View>
        {getServices(t, isPaid(user))
          ?.filter((item) => !isNil(item))
          ?.map((item, index) => {
            return (
              <CompanyServiceItem
                user={user}
                item={item!}
                key={`${index}-${item!.title}`}
              />
            )
          })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'flex-start',
    paddingBottom: 260,
  },
})

export default CompanyServiceList
