import React from 'react'

type ResumeContextProps = {
  language?: string
}

const ResumeContext = React.createContext<Partial<ResumeContextProps>>({})

type Props = {
  language?: string
  children?: React.ReactNode
}

const ResumeContextProvider: React.FC<Props> = ({
  language,
  children,
}: Props) => (
  <ResumeContext.Provider value={{ language }}>
    {children}
  </ResumeContext.Provider>
)

export { ResumeContext, ResumeContextProvider }
