import { CONSTANTS } from 'link-preview-js/build/constants'
import { isEqual, isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { View, StyleSheet } from 'react-native'

import PostItemTitle from '~/components/career/molecules/post/PostItemTitle'
import PostMessage from '~/components/career/molecules/post/PostMessage'
import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import URLPreview from '~/components/common/molecules/URLPreview'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Post from '~/interfaces/Post'
import { openURL } from '~/utils/navigation'

type Props = {
  post: Post
  isPC: boolean
  controlMenu?: React.ReactElement
}

const PostItem: React.FC<Props> = ({ post, controlMenu }: Props) => {
  let detectedURL = post.message
    ? post.message
        .replace(/\n/g, ' ')
        .split(' ')
        .find((token) => CONSTANTS.REGEX_VALID_URL.test(token))
    : ''
  if (!isEmpty(post.url)) {
    detectedURL = post.url
  }

  const urlPreviewHandler = useCallback(openURL, [])

  return (
    <View style={styles.container} testID="post-item">
      <View style={styles.body}>
        <View style={styles.header}>
          <PostItemTitle user={post.user} date={post.createdAt} />
          {controlMenu}
        </View>
      </View>
      <PostMessage
        message={post.message ?? ''}
        links={post.links ?? []}
        isComment={false}
      />
      {post.images?.length || post.videos?.length ? (
        <MediaArea entity={post} />
      ) : (
        detectedURL && (
          <URLPreview url={detectedURL} onPress={urlPreviewHandler} />
        )
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    backgroundColor: color.white,
    paddingTop: 10,
    ...commonStyles.borderRadiusAllCorner,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    width: '100%',
  },
})

export const areEqual = (
  prevProps: Readonly<React.PropsWithChildren<Props>>,
  nextProps: Readonly<React.PropsWithChildren<Props>>
): boolean =>
  prevProps.isPC === nextProps.isPC && isEqual(prevProps.post, nextProps.post)

export default React.memo(PostItem, areEqual)
