import { FontAwesome } from '@expo/vector-icons'
import { Fontisto } from '@expo/vector-icons'
import React, { FC } from 'react'
import { View, StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isCorrect: boolean
  title: string
  subtitle: string
}

const QuizResult: FC<Props> = ({ isCorrect, title, subtitle }: Props) => {
  const resultColor = isCorrect ? color.primary : color.badgeColor

  return (
    <View style={styles.resultContainer} testID="quiz-result">
      <View style={styles.resultBackground} testID="result-background" />
      <View style={styles.resultContent} testID="result-content">
        {isCorrect ? (
          <FontAwesome
            testID="correct-icon"
            size={40}
            name="circle-thin"
            color={resultColor}
          />
        ) : (
          <Fontisto
            testID="incorrect-icon"
            size={40}
            name="close-a"
            color={resultColor}
          />
        )}
        <Text
          testID="result-title"
          style={[styles.resultTitle, { color: resultColor }]}
        >
          {title}
        </Text>
        <Text
          testID="result-subtitle"
          style={[styles.resultSubtitle, { color: resultColor }]}
        >
          {subtitle}
        </Text>
      </View>
    </View>
  )
}

export default QuizResult

const styles = StyleSheet.create({
  resultBackground: {
    backgroundColor: color.white,
    bottom: 0,
    left: 0,
    opacity: 0.85,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 0,
  },
  resultContainer: {
    minHeight: 134,
    width: '100%',
  },
  resultContent: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    zIndex: 1,
  },
  resultSubtitle: {
    fontSize: FontSize.GENERAL,
    fontWeight: '500',
    marginTop: 5,
    //TODO: Wait api to implement
    opacity: 0,
    textAlign: 'center',
  },
  resultTitle: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginTop: 15,
    textAlign: 'center',
  },
})
