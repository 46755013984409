import React from 'react'
import {
  StyleSheet,
  View,
  Image,
  GestureResponderEvent,
  TouchableOpacity,
} from 'react-native'

import SelectedMask from '~/components/common/atoms/SelectedMask'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import UserDocument from '~/interfaces/UserDocument'

type Props = {
  isPC: boolean
  isSelected?: boolean
  userDocument: UserDocument
  controlMenu?: React.ReactElement
  pressMode?: 'partial' | 'whole-content'
  onPress?: (event: GestureResponderEvent) => void
}

const DocumentItem: React.FC<Props> = ({
  isPC,
  onPress,
  pressMode,
  isSelected,
  controlMenu,
  userDocument,
}: Props) => {
  const contentView = (
    <View
      pointerEvents={pressMode === 'whole-content' ? 'none' : 'auto'}
      style={StyleSheet.flatten([
        styles.container,
        isPC ? styles.containerSizePC : styles.containerSizeMobile,
      ])}
      key={userDocument.id}
      testID="document-item"
    >
      <Image
        resizeMode="cover"
        style={StyleSheet.flatten(styles.avatar)}
        source={require('~/assets/images/resumes/user_document.png')}
      />
      <View style={styles.body}>
        <TitleText style={styles.title} numberOfLines={2} ellipsizeMode="tail">
          {userDocument.name}
        </TitleText>
        {pressMode !== 'whole-content' && (
          <View
            style={styles.controlMenuContainer}
            testID="document-control-menu-container"
          >
            {controlMenu}
          </View>
        )}
      </View>
      {isSelected && <SelectedMask />}
    </View>
  )

  return (
    <TouchableOpacity onPress={onPress} testID="document-item-touchable">
      {contentView}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  avatar: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 172,
    width: '100%',
  },
  body: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    borderRadius: 10,
    marginHorizontal: 5,
    ...commonStyles.shadow,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  controlMenuContainer: {
    marginRight: 20,
  },
  title: {
    paddingHorizontal: 20,
  },
})

export default DocumentItem
