import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import React, { useContext, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import snakecaseKeys from 'snakecase-keys'

import { RecruimentQueryParams } from '~/api/recruitments'
import JobFilterPopup from '~/components/career/molecules/jobs/JobFilterPopup'
import JobsHeaderLeft from '~/components/career/molecules/jobs/JobsHeaderLeft'
import JobsHeaderRight from '~/components/career/molecules/jobs/JobsHeaderRight'
import SearchInput from '~/components/common/atoms/SearchInput'
import HeaderFilters from '~/components/common/organisms/HeaderFilters'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import User from '~/interfaces/User'
import { navigationRef } from '~/utils/navigation'

const HeaderJobs: React.FC<{ currentUser?: User }> = ({ currentUser }) => {
  const { isPC } = useContext(RootContext)
  const routeParams = camelcaseKeys(
    useRoute()?.params || {}
  ) as RecruimentQueryParams
  const [q, setQuery] = useState(routeParams.q)

  const updateSearchWordParams = (): void => {
    navigationRef.current &&
      navigationRef.current.setParams(snakecaseKeys({ q }))
  }

  return (
    <>
      <View
        style={StyleSheet.flatten([
          styles.container,
          isPC ? { alignItems: 'center' } : undefined,
        ])}
        testID="header-jobs"
      >
        <HeaderFilters
          leftFilters={
            <JobsHeaderLeft
              key="jobsHeaderLeft"
              currentUser={currentUser}
              searchInput={
                <SearchInput
                  value={q}
                  inverted
                  onChangeText={setQuery}
                  placeholderKey="jobs.search"
                  onSubmit={updateSearchWordParams}
                />
              }
            />
          }
          rightFilters={<JobsHeaderRight />}
        />
      </View>
      <View style={styles.filterContainer}>
        <View style={isPC ? styles.filterPopup : styles.filterPopupMobile}>
          <JobFilterPopup />
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    justifyContent: 'center',
  },
  filterContainer: {
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filterPopup: {
    alignItems: 'flex-end',
    width: 900,
    padding: 10,
  },
  filterPopupMobile: {
    alignItems: 'flex-end',
    width: '100%',
    padding: 10,
  },
})

export default HeaderJobs
