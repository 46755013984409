import React from 'react'
import { View, ViewStyle } from 'react-native'

import color from '~/constants/common/color'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  leftColumn?: React.ReactElement
  rightColumn?: React.ReactElement
  templateType: TemplateType
}

const TemplateBody: React.FC<Props> = ({
  leftColumn,
  rightColumn,
  templateType,
}: Props) => {
  const leftColumnStyle = {
    enjin: {
      ...styles.baseLeftColumnStyle,
      backgroundColor: color.secondaryLight,
    },
    shades: { ...styles.baseLeftColumnStyle, backgroundColor: color.darkGray },
    formal: undefined,
    minimal: { ...styles.baseLeftColumnStyle },
  }

  const rightColumnStyle = {
    enjin: styles.baseRightColumn,
    shades: {
      ...styles.baseRightColumn,
      paddingTop: 20,
      paddingBottom: 10,
      paddingHorizontal: 20,
    },
    formal: {
      ...styles.baseRightColumn,
      paddingVertical: 20,
      paddingHorizontal: 40,
    },
    minimal: {
      ...styles.baseRightColumn,
      paddingTop: 20,
      paddingBottom: 10,
      paddingHorizontal: 10,
    },
  }

  return (
    <View testID="template-body" style={styles.container as ViewStyle}>
      <View
        testID="template-body-left-column"
        style={leftColumnStyle[templateType]}
      >
        {leftColumn}
      </View>
      <View
        testID="template-body-right-column"
        style={rightColumnStyle[templateType] as ViewStyle}
      >
        {rightColumn}
      </View>
    </View>
  )
}

const styles = {
  baseLeftColumnStyle: {
    width: 280,
    paddingHorizontal: 20,
    padding: 40,
  },
  baseRightColumn: {
    justifyContent: 'center',
    flex: 1,
    padding: 40,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
}

export default TemplateBody
