import { PostRequest } from '~/api/posts'
import type { HTTPClient } from '~/httpClient'
import Answer from '~/interfaces/Answer'
import Comment from '~/interfaces/Comment'
import Media from '~/interfaces/Media'

export type AnswerQueryParams = {
  q?: string
  page?: number
  perPage?: number
  questionId?: number
}

export type AnswerFormData = {
  id?: number
  media?: Media[]
  body?: string
}

export class AnswersAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async fetch({
    page,
    perPage,
    questionId,
  }: AnswerQueryParams): Promise<Answer[]> {
    let path = `/questions/${questionId}/answers`
    const params = []
    if (page) params.push(`page=${page}`)
    if (perPage) params.push(`per_page=${perPage}`)
    if (params.length > 0) path += '?' + params.join('&')
    return await this.client.get<Answer[]>(path)
  }

  private normalizeRequest(request: PostRequest): PostRequest {
    if (request.media == null) {
      return request
    }
    const imageIds: number[] = request.media.map((item) => item.id)
    return {
      ...request,
      media: undefined,
      imageIds: [...(request.imageIds || []), ...imageIds],
    }
  }

  async create(questionId: number, answer: Partial<Answer>): Promise<Answer> {
    return await this.client.post<Answer>(`/questions/${questionId}/answers`, {
      answer: this.normalizeRequest(answer),
    })
  }

  async update(questionId: number, answer: Partial<Answer>): Promise<Answer> {
    return await this.client.patch<Answer>(
      `/questions/${questionId}/answers/${answer.id}`,
      {
        answer: this.normalizeRequest(answer),
      }
    )
  }

  async delete(questionId: number, answerId: number): Promise<void> {
    return await this.client.delete<void>(
      `/questions/${questionId}/answers/${answerId}`
    )
  }

  async createComment({
    questionId,
    answerId,
    body,
  }: {
    questionId: number
    answerId: number
    body: string
  }): Promise<Comment> {
    return await this.client.post<Comment>(
      `/questions/${questionId}/answers/${answerId}/comments`,
      {
        comment: { body },
      }
    )
  }
}
