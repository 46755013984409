import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  answer: string
  explanation: string
  goBack: () => void
  index?: string
}

const QuizExplanation: FC<Props> = ({
  answer,
  goBack,
  index,
  explanation,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.explanationContainer} testID="quiz-explanation">
      <Text style={styles.answerContent} testID="explanation-answer">
        {index ? `${index}. ${answer}` : answer}
      </Text>
      <View style={styles.explanationContent} testID="explanation-content">
        <Text style={styles.answerContent}>
          {t('quiz.explanation')}: {explanation}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.backButton}
        onPress={goBack}
        testID="explanation-go-back"
      >
        <Text style={styles.back}>{t('back')}</Text>
      </TouchableOpacity>
    </View>
  )
}

export default QuizExplanation

const styles = StyleSheet.create({
  answerContent: {
    color: color.black,
    fontSize: FontSize.GENERAL,
    fontWeight: '500',
  },
  back: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 20,
    textDecorationLine: 'underline',
  },
  backButton: {
    width: 50,
  },
  explanationContainer: {
    marginBottom: 15,
    paddingHorizontal: 20,
  },
  explanationContent: {
    marginVertical: 20,
  },
})
