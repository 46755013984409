import { FontAwesome as Icon } from '@expo/vector-icons'
import { find } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import SearchableList from '~/components/common/molecules/SearchableList'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { getLanguages } from '~/utils/common/languagePickers'

type Props = {
  onChange: (value: string) => void
  selected?: string
  isPC?: boolean
  size?: 'small' | 'medium' | 'large' | 'full'
  title?: string
  invertedText?: boolean
  language: string
}

const LanguagePicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [languageCode, setLanguageCode] = useState(props.selected)
  const [isShowDialog, setIsShowDialog] = useState(false)
  const languages = getLanguages(props.language)
  const isPC = props.isPC
  const textColor = props.invertedText ? color.white : color.textGray

  const changeSelectedCountry = (value: string): void => {
    const selectedLanguage =
      find(languages, { label: value })?.value.toString() ?? ''
    setLanguageCode(selectedLanguage)
    props.onChange(selectedLanguage)
    setIsShowDialog(false)
  }

  return (
    <View testID="language-picker" style={styles.container}>
      {props.title && (
        <Text
          style={StyleSheet.flatten([
            styles.title,
            { fontSize: FontSize.GENERAL },
          ])}
        >
          {props.title}
        </Text>
      )}
      <Button
        onPress={() => setIsShowDialog(true)}
        title={
          find(languages, { value: languageCode })?.label ?? t('pleaseSelect')
        }
        type="outline"
        buttonStyle={styles.button}
        testID="language-picker-button"
        titleStyle={StyleSheet.flatten([
          styles.buttonTitle,
          { color: textColor },
        ])}
        iconRight
        icon={
          <Icon
            name="caret-down"
            color={textColor}
            size={FontSize.GENERAL}
            style={styles.buttonIcon}
          />
        }
      />
      <ModalContainer
        visible={isShowDialog}
        onDismiss={() => setIsShowDialog(false)}
      >
        <View
          style={isPC ? styles.searchDialog : styles.searchDialogMobile}
          testID="language-list-container"
        >
          <SearchableList
            title={t('selectLanguage')}
            data={languages.map((item) => item.label)}
            onPressItem={changeSelectedCountry}
          />
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderColor: color.lightGray,
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginLeft: 8,
  },
  buttonTitle: {
    fontSize: FontSize.REMARKABLE,
  },
  container: {
    flex: 1,
  },
  searchDialog: {
    padding: 20,
    backgroundColor: color.white,
    width: 420,
    height: 500,
    ...commonStyles.borderRadiusAllCorner,
  },
  searchDialogMobile: {
    padding: 10,
    backgroundColor: color.white,
    width: 300,
    height: 400,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    color: color.darkTitleText,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default LanguagePicker
