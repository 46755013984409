import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
const BackToLogin: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View testID="back-to-login">
      <Link to="/login">
        <Text style={styles.questionText}>
          {t('auth.alreadyHaveAnAccountQuestion')}{' '}
        </Text>
        <Text style={styles.loginText}>{t('auth.login')}</Text>
      </Link>
    </View>
  )
}

const styles = StyleSheet.create({
  loginText: {
    color: color.textGray,
    fontWeight: 'bold',
    fontSize: FontSize.GENERAL,
  },
  questionText: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
})

export default BackToLogin
