import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'

import ApplicantItem from '~/components/career/molecules/company/ApplicantItem'
import PrimarySearchHeader from '~/components/common/organisms/PrimarySearchHeader'
import FlatList from '~/components/workarounds/FlatList'
import { applicantFactory } from '~/factories/applicant'
import Applicant from '~/interfaces/Applicant'

const ApplicantList: React.FC = () => {
  const { t } = useTranslation()
  const applicants = applicantFactory.buildList(5)

  const renderItem: ListRenderItem<Applicant> = ({
    item,
  }: {
    item: Applicant
  }) => <ApplicantItem applicant={item} />

  const keyExtractor = (item: Applicant): string => item.id.toString()

  return (
    <View testID="applicant-list" style={styles.container}>
      <FlatList
        ListHeaderComponent={
          <View style={styles.headerContainer}>
            <PrimarySearchHeader
              title={t('company.applicant.headerTitle')}
              searchInputPlaceholder={t(
                'company.applicant.searchApplicantPlaceholder'
              )}
            />
          </View>
        }
        data={applicants}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    marginBottom: 20,
  },
})

export default ApplicantList
