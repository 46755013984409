import React from 'react'
import { StyleSheet, View } from 'react-native'

import CustomCheckBox from '~/components/common/atoms/CustomCheckBox'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export type CheckBoxListData = {
  value: string
  title: string
}

type Props = {
  title?: string
  data: CheckBoxListData[]
  selectedItems: CheckBoxListData[]
  onSelectItem?: (item: CheckBoxListData) => void
}

const CheckBoxList: React.FC<Props> = ({
  data,
  title,
  onSelectItem,
  selectedItems,
}: Props) => (
  <View testID="check-box-list" style={styles.container}>
    <TitleText style={styles.title}>{title}</TitleText>
    {data.map((item) => (
      <CustomCheckBox
        key={item.value}
        size={16}
        onPress={(): void => onSelectItem && onSelectItem(item)}
        checked={
          selectedItems.find(
            (selectedItem) => item.value === selectedItem.value
          ) !== undefined
        }
        title={item.title}
        containerStyle={styles.checkbox}
        textStyle={styles.checkboxTitle}
        titleProps={{ testID: 'check-box-list-title' }}
      />
    ))}
  </View>
)

const styles = StyleSheet.create({
  checkbox: {
    backgroundColor: color.transparent,
    borderWidth: 0,
    marginLeft: 5,
    padding: 0,
  },
  checkboxTitle: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: '300',
    marginHorizontal: 3,
  },
  container: {
    backgroundColor: color.white,
    padding: 20,
  },
  title: {
    color: color.unpressableTitleText,
    marginBottom: 5,
  },
})

export default CheckBoxList
