import React from 'react'
import { StyleSheet, View } from 'react-native'

import SkeletonView from '~/components/common/atoms/SkeletonView'

const SkeletonTwoBar: React.FC = () => (
  <View testID="skeleton-two-bar">
    <SkeletonView />
    <View style={styles.separator} />
    <SkeletonView style={styles.secondRow} />
  </View>
)

const styles = StyleSheet.create({
  secondRow: {
    width: '80%',
  },
  separator: {
    marginTop: 10,
  },
})

export default SkeletonTwoBar
