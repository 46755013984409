import camelcaseKeys from 'camelcase-keys'
import { find, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { CompanyRecruitmentsSortType } from '~/api/companyRecruitments'
import { RecruimentQueryParams } from '~/api/recruitments'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomNavigation from '~/hooks/useNavigation'
import { navigationRef } from '~/utils/navigation'

type Props = {
  isPC?: boolean
}

const CompanyRecruitmentsSortMenu: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as RecruimentQueryParams

  const [selectedItem, setSelectedItem] = useState<
    ControlMenuData | undefined
  >()

  const menuData = [
    {
      id: CompanyRecruitmentsSortType.ACTIVE,
      title: t('company.recruitments.sortMenu.active'),
    },
    {
      id: CompanyRecruitmentsSortType.INACTIVE,
      title: t('company.recruitments.sortMenu.inactive'),
    },
    {
      id: CompanyRecruitmentsSortType.RECENTLY,
      title: t('company.recruitments.sortMenu.recently'),
    },
    {
      id: CompanyRecruitmentsSortType.ENDED,
      title: t('company.recruitments.sortMenu.endJob'),
    },
  ]

  const updateSortByParam = (controlMenuData: ControlMenuData): void => {
    setQueryParams({ sortBy: controlMenuData.id.toString(), status: '' })
    setSelectedItem(controlMenuData)
  }

  useEffect(() => {
    const selectedItem = find(menuData, {
      id: routeParams.sortBy,
    }) as ControlMenuData
    if (!isNil(routeParams.sortBy) && !isNil(selectedItem)) {
      updateSortByParam(selectedItem)
    } else {
      updateSortByParam(menuData[0])
    }
  }, [t])

  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View testID="company-recruitments-sort-menu" style={containerStyle}>
      <ControlMenu
        data={menuData}
        onSelect={updateSortByParam}
        menuTrigger={
          <Text style={styles.menuTriggerText}>{`${t('sortBy')}: ${
            selectedItem?.title
          }`}</Text>
        }
        preferredPlacement="bottom"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
  containerMobile: {
    alignSelf: 'flex-end',
    marginTop: 20,
    paddingHorizontal: 20,
  },
  menuTriggerText: {
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
  },
})

export default CompanyRecruitmentsSortMenu
