import { isEmpty } from 'lodash'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import { CompanyBodyParams } from '~/api/companies'
import EditableText from '~/components/common/atoms/EditableText'
import TitleText from '~/components/common/atoms/TitleText'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'

type Props = {
  company: Company
  editable?: boolean
  onChange: (about: string) => void
}

export const LIMIT_OF_ABOUT_CONTENT = 500

const CompanyAbout: React.FC<Props> = ({
  editable,
  company,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [newContent, setNewContent] = useState(company.aboutUs ?? '')

  const containerStyle = styles.container
  const toast = useCustomToast()

  const isValid = useMemo(() => newContent.length < LIMIT_OF_ABOUT_CONTENT, [
    newContent,
  ])

  const errorMessageDisplay = useMemo(
    () =>
      isValid ? '' : `${t('maxLength', { maxLength: LIMIT_OF_ABOUT_CONTENT })}`,
    [isValid]
  )

  const updateCompanyAbout = async () => {
    if (isLoading || !isValid) {
      return
    }
    setIsLoading(true)
    try {
      await api.companies.update<Partial<CompanyBodyParams>, Company>(
        company.id,
        {
          aboutUs: newContent,
        }
      )
      onChange(newContent)
      setIsEditing(false)
    } catch (error) {
      toast.showError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View style={containerStyle} testID="company-about">
      <View style={styles.rowContainerStyle}>
        <TitleText testID="company-about-title" style={styles.title}>
          {t('jobs.companyDescriptionTitle', {
            companyName: company.name,
          })}
        </TitleText>
        {isEditing && (
          <Text
            testID="company-about-valid-character"
            style={[
              styles.textLengthStyle,
              { color: isValid ? color.textGray : color.danger },
            ]}
          >
            {t('character', {
              limit: `${
                !isEmpty(newContent) ? newContent.length : 0
              }/${LIMIT_OF_ABOUT_CONTENT}`,
              count: !isEmpty(newContent) ? newContent.length : 0,
            })}
          </Text>
        )}
      </View>

      <View style={styles.introductionContainer}>
        <EditableText
          multiline
          value={newContent}
          editable={isEditing}
          busyMode={isLoading}
          onChangeText={setNewContent}
        />
      </View>

      {isEditing && (
        <Text style={styles.error} testID="error-message">
          {errorMessageDisplay}
        </Text>
      )}
      <View style={styles.fill} />
      {editable && (
        <View
          style={styles.textInputButtonAction}
          testID="text-input-action-container"
        >
          <TextInputAction
            isEdit={true}
            isDisabled={!isValid}
            isSaveLoading={isLoading}
            isNormalMode={!isEditing}
            onSavePress={updateCompanyAbout}
            onEditPress={() => setIsEditing(true)}
            onCancelPress={() => setIsEditing(false)}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 20,
    flex: 1,
  },
  error: {
    color: color.badgeColor,
    fontSize: FontSize.SUB,
    paddingTop: 4,
  },
  fill: {
    flex: 1,
  },
  introductionContainer: {
    marginTop: 10,
  },
  rowContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInputButtonAction: {
    alignSelf: 'flex-end',
  },
  textLengthStyle: {
    fontSize: FontSize.SUB,
  },
  title: {
    color: color.darkTitleText,
  },
})

export default CompanyAbout
