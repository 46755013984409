import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
}

const EventPresenter: React.FC<Props> = ({ event }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="event-presenter" style={styles.container}>
      <Text style={styles.presenter}>{t('events.presenterLabel')}:</Text>
      <Text
        numberOfLines={1}
        testID="presenter"
        style={[styles.presenter, { color: color.pressableText }]}
      >
        {event.presenter}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  presenter: {
    color: color.black,
    fontSize: FontSize.GENERAL,
    marginRight: 4,
  },
})

export default EventPresenter
