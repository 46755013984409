import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color, { ColorTheme } from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title?: string
  disabled?: boolean
  onPress?: () => void
  colorTheme?: ColorTheme
}

const DropdownButton: React.FC<Props> = ({
  title,
  onPress,
  disabled,
  colorTheme,
}: Props) => {
  const primaryColor =
    colorTheme === ColorTheme.Primary
      ? color.primary
      : colorTheme === ColorTheme.Light
      ? color.white
      : color.textGray

  return (
    <Button
      title={title}
      type="outline"
      iconRight={true}
      onPress={onPress}
      disabled={disabled}
      testID="dropdown-button"
      titleStyle={StyleSheet.flatten([styles.title, { color: primaryColor }])}
      icon={<Icon size={20} name="caret-down" color={primaryColor} />}
      buttonStyle={StyleSheet.flatten([
        styles.dropdown,
        { borderColor: primaryColor },
      ])}
    />
  )
}

const styles = StyleSheet.create({
  dropdown: {
    borderWidth: 1,
    justifyContent: 'space-between',
    padding: 10,
  },
  title: {
    marginRight: 8,
    fontSize: FontSize.GENERAL,
  },
})

export default DropdownButton
