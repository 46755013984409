import React from 'react'
import { StyleSheet } from 'react-native'
import { Image } from 'react-native-elements'

type Props = {
  isPC?: boolean
}

const CheckoutCoverSection: React.FC<Props> = ({ isPC }: Props) => (
  <Image
    width={0}
    height={120}
    testID="checkout-cover-section"
    style={isPC ? styles.coverImg : styles.coverImgMobile}
    source={require('~/assets/images/subscriptions/secure_banner.png')}
  />
)

const styles = StyleSheet.create({
  coverImg: {
    width: 920,
    height: 120,
  },
  coverImgMobile: {
    width: '100%',
    height: 120,
    minWidth: 375,
  },
})

export default CheckoutCoverSection
