import { PathConfigMap } from '@react-navigation/native'

import { CommunitySection } from '~/constants/common/navigation'
import { CommunityStackParamList } from '~/interfaces/RootStackParamList'

export const parseCommunitySection = (value?: string): CommunitySection => {
  return Object.values(CommunitySection).includes(value as CommunitySection)
    ? (value as CommunitySection)
    : CommunitySection.TALK
}

export const communityConfigPath: PathConfigMap<CommunityStackParamList> = {
  community: '/',
  communities: {
    path: 'communities',
  },
}

/**
 * Add linking router for another pages that is not display in Mobile BottomNavigator
 */
export const otherCommunityConfigPath = {
  communityDetail: {
    path: 'communities/:id/:section?',
    parse: {
      id: Number,
      section: parseCommunitySection,
    },
  },
}
