import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle, ViewStyle } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { switchLanguage } from '~/plugins/i18n'

const AuthenticationHeaderRight: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Button
      type="outline"
      testID="authentication-header-right"
      buttonStyle={styles.container as ViewStyle}
      title={t('menu.language')}
      onPress={(): Promise<TFunction> => switchLanguage()}
      titleStyle={styles.title as TextStyle}
    />
  )
}

const styles = {
  container: {
    borderColor: color.textGray,
    flexDirection: 'row',
    marginRight: 20,
    justifyContent: 'center',
    height: 36,
    width: 80,
  },
  title: {
    color: color.textGray,
    alignSelf: 'center',
    fontSize: FontSize.GENERAL,
  },
}

export default AuthenticationHeaderRight
