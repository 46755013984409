import { Picker } from '@react-native-picker/picker'
import { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import FormAction from '~/components/common/atoms/users/FormAction'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Community, { CommunityCategory } from '~/interfaces/Community'

type Props = {
  community?: Community
  onSubmit?: (
    communityName: string,
    communityCategory: CommunityCategory,
    callback?: Function
  ) => void
}

const GeneralSetting: React.FC<Props> = ({ onSubmit, community }: Props) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)

  const [communityName, setCommunityName] = useState(community?.name || '')
  const [communityType, setCommunityType] = useState<CommunityCategory>(
    community?.category || CommunityCategory.None
  )

  const handleSaveSetting = async (): Promise<void> => {
    if (
      onSubmit &&
      (communityName !== community?.name ||
        communityType !== community?.category)
    ) {
      onSubmit(communityName, communityType, (): void => setEditing(false))
    } else {
      setEditing(false)
    }
  }

  return (
    <View style={styles.container} testID="general-setting">
      <Text style={styles.title}>{t('community.admin.generalSetting')}</Text>
      {editing ? (
        <View testID="edit-field-container">
          <TextField
            value={communityName}
            title={t('community.communityName')}
            onChangeText={setCommunityName}
          />
          {/* TODO refactor me */}
          <Text style={styles.category}>{t('community.communityType')}</Text>
          <Picker
            style={styles.picker}
            selectedValue={communityType.toString()}
            onValueChange={(itemValue): void => {
              setCommunityType(itemValue as CommunityCategory)
            }}
          >
            {Object.values(CommunityCategory).map((community) => (
              <Picker.Item
                testID="picker-item"
                key={community}
                label={community}
                value={community}
              />
            ))}
          </Picker>
          <FormAction
            disabled={false}
            isLoading={false}
            onSubmit={handleSaveSetting}
            onCancel={(): void => setEditing(false)}
          />
        </View>
      ) : (
        <>
          <Text style={styles.category}>{t('community.communityName')}</Text>
          <Text style={styles.grayText}>{communityName}</Text>
          <Text style={styles.category}>{t('community.communityType')}</Text>
          <Text style={styles.grayText}>
            {capitalize(communityType.toString())}
          </Text>
        </>
      )}
      {!editing && (
        <Button
          type="solid"
          testID="edit-button"
          title={t('edit')}
          onPress={(): void => setEditing(true)}
          titleStyle={StyleSheet.flatten(styles.editTitle)}
          buttonStyle={StyleSheet.flatten(styles.editButton)}
          containerStyle={StyleSheet.flatten(styles.editButtonContainer)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  category: {
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
  },
  container: {
    backgroundColor: color.white,
    marginTop: 10,
    padding: 20,
  },
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 90,
  },
  editButtonContainer: {
    marginTop: 25,
  },
  editTitle: {
    fontSize: FontSize.SUB,
  },
  grayText: {
    color: color.pressableText,
  },
  picker: {
    backgroundColor: color.white,
    borderColor: color.gray,
    borderRadius: 4,
    height: 32,
    width: '100%',
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
  },
})

export default GeneralSetting
// TODO split this component
