import APIClient from '~/api/APIClient'
import { AccountAPI } from '~/api/account'
import { AdsAPI } from '~/api/ads'
import { AnswersAPI } from '~/api/answers'
import { AuthAPI } from '~/api/auth'
import { BusinessAPI } from '~/api/businesses'
import { ChannelPostsAPI } from '~/api/channelPosts'
import { ChatMessagesAPI } from '~/api/chatMessages'
import { ChatThreadsAPI } from '~/api/chatThreads'
import { CommunitiesAPI } from '~/api/communities/communities'
import { CommunityChanelMembersAPI } from '~/api/communities/communityChanelMembers'
import { CommunityChannelAPI } from '~/api/communities/communityChannels'
import { CommunityEventAttendeeInvitationAPI } from '~/api/communities/communityEventAttendeeInvitation'
import { CommunityEventAttendeesAPI } from '~/api/communities/communityEventAttendees'
import { CommunityEventPostsAPI } from '~/api/communities/communityEventPosts'
import { CommunityEventsAPI } from '~/api/communities/communityEvents'
import { CommunityMemberInvitationsAPI } from '~/api/communities/communityMemberInvitations'
import { CommunityMemberJoinRequestsAPI } from '~/api/communities/communityMemberJoinRequests'
import { CommunityMembersAPI } from '~/api/communities/communityMembers'
import { CompaniesAPI } from '~/api/companies'
import { CompanyApplicantStatisticsAPI } from '~/api/companyApplicantStatistics'
import { CompanyMemberInvitationAPI } from '~/api/companyMemberInvitation'
import { CompanyMembersAPI } from '~/api/companyMembers'
import { CompanyRecruitmentEntriesAPI } from '~/api/companyRecruitmentEntries'
import { CompanyRecruitmentStatisticsAPI } from '~/api/companyRecruitmentStatistics'
import { CompanyRecruitmentsAPI } from '~/api/companyRecruitments'
import { EntryAPI } from '~/api/entry'
import { FollowAPI } from '~/api/follow'
import { ForumCommentsAPI } from '~/api/forumComments'
import { LikesAPI } from '~/api/likes'
import { NotificationAPI } from '~/api/notifications'
import { PostsAPI } from '~/api/posts'
import { ProfileAttributesAPI } from '~/api/profileAttributes'
import { QuestionsAPI } from '~/api/questions'
import { QuizResultsAPI } from '~/api/quizResults'
import { QuizzesAPI } from '~/api/quizzes'
import { RecruitmentsAPI } from '~/api/recruitments'
import { ReportsAPI } from '~/api/reports'
import { ResumesAPI } from '~/api/resumes'
import { SavedRecruitsAPI } from '~/api/savedRecruits'
import { SharesAPI } from '~/api/shares'
import { DirectUploadsAPI } from '~/api/storage/directUploads'
import { SubscriptionPlansAPI } from '~/api/subscriptionPlans'
import { UploadAPI } from '~/api/upload'
import { URLInspectionsAPI } from '~/api/urlInspections'
import { UserAnswersAPI } from '~/api/userAnswers'
import { UserDocumentAPI } from '~/api/userDocument'
import { UserProfileAPI } from '~/api/userProfile'
import { UserQuestionsAPI } from '~/api/userQuestions'
import { UserQuickRepliesAPI } from '~/api/userQuickReplies'
import { UsersAPI } from '~/api/users'

export class API {
  public reports: ReportsAPI
  public auth: AuthAPI
  public posts: PostsAPI
  public follow: FollowAPI
  public upload: UploadAPI
  public users: UsersAPI
  public userProfile: UserProfileAPI
  public recruitments: RecruitmentsAPI
  public notifications: NotificationAPI
  public resumes: ResumesAPI
  public account: AccountAPI
  public profileAttributes: ProfileAttributesAPI
  public urlInspections: URLInspectionsAPI
  public channelPosts: ChannelPostsAPI
  public communityEventPosts: CommunityEventPostsAPI
  public questions: QuestionsAPI
  public answers: AnswersAPI
  public forumComments: ForumCommentsAPI
  public quizzes: QuizzesAPI
  public likes: LikesAPI
  public quizResults: QuizResultsAPI
  public userAnswers: UserAnswersAPI
  public userQuestions: UserQuestionsAPI
  public userQuickReplies: UserQuickRepliesAPI
  public savedRecruits: SavedRecruitsAPI
  public busineses: BusinessAPI
  public subscriptionPlans: SubscriptionPlansAPI

  //Community
  public communities: CommunitiesAPI
  public communityEvents: CommunityEventsAPI
  public communityChannel: CommunityChannelAPI
  public communityMembers: CommunityMembersAPI
  public communityChannelMembers: CommunityChanelMembersAPI
  public communityMemberInvitations: CommunityMemberInvitationsAPI
  public communityMemberJoinRequest: CommunityMemberJoinRequestsAPI
  public communityEventAttendees: CommunityEventAttendeesAPI
  public communityEventAttendeeInvitations: CommunityEventAttendeeInvitationAPI
  public ads: AdsAPI
  public shares: SharesAPI
  public entries: EntryAPI
  public chatThreads: ChatThreadsAPI
  public chatMessages: ChatMessagesAPI
  public directUploads: DirectUploadsAPI
  public companies: CompaniesAPI
  public userDocuments: UserDocumentAPI

  // Company
  public companyMembers: CompanyMembersAPI
  public companyRecruitments: CompanyRecruitmentsAPI
  public companyMemberInvitation: CompanyMemberInvitationAPI
  public companyApplicantStatistics: CompanyApplicantStatisticsAPI
  public companyRecruitmentStatistics: CompanyRecruitmentStatisticsAPI
  public companyRecruitmentEntries: CompanyRecruitmentEntriesAPI

  public abort: () => void
  public isAlive = true

  public constructor() {
    const client = new APIClient()

    this.reports = new ReportsAPI(client)
    this.auth = new AuthAPI(client)
    this.posts = new PostsAPI(client)
    this.follow = new FollowAPI(client)
    this.upload = new UploadAPI(client)
    this.users = new UsersAPI(client)
    this.userProfile = new UserProfileAPI(client)
    this.recruitments = new RecruitmentsAPI(client)
    this.savedRecruits = new SavedRecruitsAPI(client)
    this.notifications = new NotificationAPI(client)
    this.resumes = new ResumesAPI(client)
    this.account = new AccountAPI(client)
    this.profileAttributes = new ProfileAttributesAPI(client)
    this.urlInspections = new URLInspectionsAPI(client)
    this.channelPosts = new ChannelPostsAPI(client)
    this.questions = new QuestionsAPI(client)
    this.answers = new AnswersAPI(client)
    this.forumComments = new ForumCommentsAPI(client)
    this.quizzes = new QuizzesAPI(client)
    this.likes = new LikesAPI(client)
    this.quizResults = new QuizResultsAPI(client)
    this.userAnswers = new UserAnswersAPI(client)
    this.userQuestions = new UserQuestionsAPI(client)
    this.userQuickReplies = new UserQuickRepliesAPI(client)
    this.communities = new CommunitiesAPI(client)
    this.communityChannel = new CommunityChannelAPI(client)
    this.communityMembers = new CommunityMembersAPI(client)
    this.communityChannelMembers = new CommunityChanelMembersAPI(client)
    this.communityMemberInvitations = new CommunityMemberInvitationsAPI(client)
    this.communityMemberJoinRequest = new CommunityMemberJoinRequestsAPI(client)
    this.communityEventPosts = new CommunityEventPostsAPI(client)
    this.communityEventAttendees = new CommunityEventAttendeesAPI(client)
    this.communityEventAttendeeInvitations = new CommunityEventAttendeeInvitationAPI(
      client
    )
    this.ads = new AdsAPI(client)
    this.communityEvents = new CommunityEventsAPI(client)
    this.shares = new SharesAPI(client)
    this.communityEventPosts = new CommunityEventPostsAPI(client)
    this.entries = new EntryAPI(client)
    this.chatThreads = new ChatThreadsAPI(client)
    this.chatMessages = new ChatMessagesAPI(client)
    this.directUploads = new DirectUploadsAPI(client)
    this.companies = new CompaniesAPI(client)
    this.companyMembers = new CompanyMembersAPI(client)
    this.userDocuments = new UserDocumentAPI(client)
    this.companyRecruitments = new CompanyRecruitmentsAPI(client)
    this.companyApplicantStatistics = new CompanyApplicantStatisticsAPI(client)
    this.companyRecruitmentStatistics = new CompanyRecruitmentStatisticsAPI(
      client
    )
    this.companyRecruitmentEntries = new CompanyRecruitmentEntriesAPI(client)
    this.companyMemberInvitation = new CompanyMemberInvitationAPI(client)
    this.busineses = new BusinessAPI(client)
    this.subscriptionPlans = new SubscriptionPlansAPI(client)

    this.abort = (): void => {
      this.isAlive = false
      client.abort()
    }
  }
}

export default new API()
