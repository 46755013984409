import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputContentSizeChangeEventData,
} from 'react-native'
import { Input as RNElementsInput, InputProps } from 'react-native-elements'

import color from '~/constants/common/color'

const THRESHOLD_OF_HEIGHT = 400
const Input = React.forwardRef<TextInput, InputProps>(
  (props: InputProps, ref: React.ForwardedRef<TextInput>): JSX.Element => {
    const rangeHeight = 23 // Calculate height when TextInput is multiline (1 multiline ~ 23px)
    const minHeight = (props!.numberOfLines || 0) * rangeHeight
    const [maxHeight, setMaxHeight] = useState(minHeight)
    const textInput = props as React.ComponentPropsWithRef<typeof TextInput>
    const inputProps = props as InputProps

    const onContentSizeChange = (
      event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
    ) => {
      const { height } = event.nativeEvent.contentSize
      if (Math.abs(height - maxHeight) < rangeHeight) return
      if (minHeight > 0) {
        setMaxHeight(
          height < THRESHOLD_OF_HEIGHT ? height : THRESHOLD_OF_HEIGHT
        )
      }
    }

    useEffect(() => {
      if (isEmpty(textInput.value)) {
        setMaxHeight(minHeight)
      }
    }, [textInput.value])

    return (
      <RNElementsInput
        {...props}
        labelStyle={[inputProps.labelStyle]}
        errorStyle={[inputProps.errorStyle]}
        inputStyle={[inputProps.inputStyle]}
        disabledInputStyle={[inputProps.disabledInputStyle]}
        containerStyle={[styles.noHorizontalPadding, inputProps.containerStyle]}
        inputContainerStyle={[
          styles.noneBorder,
          inputProps.inputContainerStyle,
        ]}
        ref={ref}
        style={[
          inputProps.style,
          minHeight > 0
            ? {
                height: Math.max(minHeight, maxHeight),
              }
            : {},
          styles.defaultInputStyle,
        ]}
        onContentSizeChange={
          inputProps.onContentSizeChange ?? onContentSizeChange
        }
      />
    )
  }
)

const styles = {
  defaultInputStyle: {
    paddingLeft: 13,
  },
  noneBorder: {
    borderColor: color.transparent,
    borderWidth: 0,
  },
  noHorizontalPadding: {
    paddingHorizontal: 0,
  },
}

Input.displayName = 'Input'

export default Input
