import * as Factory from 'factory.ts'
import faker from 'faker'

import Image from '~/interfaces/Image'

const imageUrl = faker.image.animals(300, 100)

export const imageFactory = Factory.makeFactory<Image>({
  id: Factory.each((i) => i),
  type: 'image',
  name: 'image name',
  presignedUrl: imageUrl,
  urls: {
    original: imageUrl,
    medium: imageUrl,
    small: imageUrl,
  },
})
