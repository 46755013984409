import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import QuestionNewTemplate from '~/components/forum/templates/QuestionNewTemplate'

const QuestionNew: React.FC = () => {
  return (
    <DefaultLayout>
      <QuestionNewTemplate />
    </DefaultLayout>
  )
}

export default QuestionNew
