import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageBackground, StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isRegistering?: boolean
  onSubmit: () => void
}

const PCColumn: React.FC<Props> = ({ isRegistering, onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <ImageBackground
      style={StyleSheet.flatten([styles.container, styles.shadow])}
      source={require('~/assets/images/welcome.png')}
      resizeMode="stretch"
      testID="registration-complete-column-pc"
    >
      <View style={styles.content}>
        <Text style={styles.title}>{t('profile.registrationMessage')}</Text>
        <Button
          testID="start-button"
          buttonStyle={StyleSheet.flatten(styles.startButton)}
          title={t('profile.letStart')}
          loading={isRegistering}
          disabled={isRegistering}
          onPress={onSubmit}
        />
      </View>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    height: 360,
    margin: 'auto',
    right: '30%',
    width: 900,
  },
  content: {
    borderColor: color.transparent,
    borderWidth: 0,
    bottom: 0,
    paddingBottom: 40,
    paddingRight: 50,
    position: 'absolute',
    right: 0,
    width: 620,
  },
  shadow: {
    borderRadius: 4,
    elevation: 3,
    shadowColor: color.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
  },
  startButton: {
    alignSelf: 'flex-end',
    borderRadius: 4,
    height: 40,
    width: 260,
  },
  title: {
    alignSelf: 'flex-end',
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
    marginBottom: 90,
  },
})

export default PCColumn
