import {
  Consumer,
  ChannelNameWithParams,
  Mixin,
  Subscription,
} from '@rails/actioncable'
import { useFocusEffect } from '@react-navigation/native'
import { DependencyList, useCallback, useState } from 'react'

const useStreamSubscription = ({
  stream,
  channelName,
  mixin,
  deps,
}: {
  stream?: Consumer
  channelName: string | ChannelNameWithParams
  mixin?: Mixin
  deps?: DependencyList
}): (Subscription<Consumer> & Mixin) | null => {
  const [channel, setChannel] = useState<
    (Subscription<Consumer> & Mixin) | null
  >(null)

  useFocusEffect(
    useCallback(() => {
      channel && channel.unsubscribe()

      if (stream) {
        const newChannel = stream.subscriptions.create(channelName, mixin)
        setChannel(newChannel)
      }
      return () => {
        channel && channel.unsubscribe()
      }
    }, [stream, ...(deps || [])])
  )

  return channel
}

export default useStreamSubscription
