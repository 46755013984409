import React, { useContext, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import HeaderFilters from '~/components/common/organisms/HeaderFilters'
import CommunityHeaderFilters from '~/components/community/molecules/CommunityHeaderFilters'
import CommunityHeaderLeft from '~/components/community/molecules/CommunityHeaderLeft'
import CommunityFormDialog from '~/components/community/organisms/CommunityFormDialog'
import CommunityResultDialog from '~/components/community/organisms/CommunityResultDialog'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'

const HeaderCommunity: React.FC = () => {
  const { isPC } = useContext(RootContext)
  const [isFormDialogVisible, setIsFormDialogVisible] = useState<boolean>(false)
  const [shouldShowResultDialog, setShouldShowResultDialog] = useState<boolean>(
    false
  )

  return (
    <View
      testID="header-community"
      style={StyleSheet.flatten([
        styles.container,
        isPC ? { alignItems: 'center' } : undefined,
      ])}
    >
      <HeaderFilters
        leftFilters={<CommunityHeaderLeft key="communityHeaderLeft" />}
        rightFilters={
          <CommunityHeaderFilters
            key="communityFilters"
            onClickCreate={() => setIsFormDialogVisible(true)}
          />
        }
      />
      <CommunityFormDialog
        isVisible={isFormDialogVisible}
        onSubmit={() => setShouldShowResultDialog(true)}
        dismissDialog={() => setIsFormDialogVisible(false)}
      />
      <CommunityResultDialog
        isVisible={shouldShowResultDialog}
        onPressDismiss={() => setShouldShowResultDialog(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    justifyContent: 'center',
  },
})

export default HeaderCommunity
