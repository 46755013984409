import { Link, useNavigation } from '@react-navigation/native'
import { isNil } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { format } from 'timeago.js'

import ProfileAvatar, {
  ProfileAvatarSize,
} from '~/components/common/atoms/ProfileAvatar'
import { getPrefecturesData } from '~/components/common/molecules/LocationPicker'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Recruitment from '~/interfaces/Recruitment'
import i18n from '~/plugins/i18n'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  job: Recruitment
  isPC: boolean
}

const JobItemContent: React.FC<Props> = ({ job, isPC }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const prefecturesData = getPrefecturesData(i18n.language, t)
  const companyJob = job.company
  const perfecture = prefecturesData.find((item) => item.value === job.location)

  return (
    <View style={styles.container} testID="job-item-content">
      <Text
        numberOfLines={1}
        style={styles.subject}
        testID="job-item-subject"
        onPress={(): void => navigate('jobShow', { id: job.id })}
      >
        {job.subject}
      </Text>
      <Text style={styles.skills} numberOfLines={1}>
        {(job.skills ?? []).map((skill) => skill.name).join(' ')}
      </Text>
      <Text style={styles.location} ellipsizeMode="tail" numberOfLines={1}>
        {perfecture?.label}
      </Text>
      <Text style={styles.postedTime}>
        {t('posted', {
          time: format(new Date(job.publishedAt), i18n.language),
        })}
      </Text>
      {isPC && (
        <Text numberOfLines={2} testID="job-overview" style={styles.overview}>
          {job.overview}
        </Text>
      )}
      <View style={styles.separatorSpace} />
      <View style={styles.footer}>
        <Link
          to={{ screen: 'companyShow', params: { id: job.companyId } }}
          testID="company-profile-button"
        >
          <View style={styles.profile}>
            <ProfileAvatar
              size={ProfileAvatarSize.MICRO}
              source={
                !isNil(companyJob?.image?.urls.small)
                  ? { uri: companyJob?.image?.urls.small }
                  : require('~/assets/images/companyicon2x.png')
              }
            />
            <Text style={styles.companyName}>
              {job.companyName ||
                (i18n.language === 'en' && companyJob?.enName
                  ? companyJob?.enName
                  : companyJob?.name)}
            </Text>
          </View>
        </Link>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  companyName: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
    marginLeft: 6,
    padding: 2,
  },
  container: {
    padding: 10,
    paddingLeft: 20,
    flex: 1,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  location: {
    flexWrap: 'wrap',
    fontSize: FontSize.SUB,
    padding: 2,
  },
  overview: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    marginVertical: 10,
    flexWrap: 'wrap',
  },
  postedTime: {
    fontSize: FontSize.SUB,
    padding: 2,
  },
  profile: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separatorSpace: {
    flex: 1,
  },
  skills: {
    flexWrap: 'wrap',
    fontSize: FontSize.GENERAL,
    padding: 2,
  },
  subject: {
    color: color.pressableText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    padding: 2,
    alignSelf: 'baseline',
  },
})

export default JobItemContent
