import React from 'react'
import { View, StyleSheet } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import FollowButton from '~/components/common/atoms/users/FollowButton'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import ProfileFollow from '~/components/common/molecules/users/ProfileFollow'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

type Props = {
  user?: User
  isPC?: boolean
  isMe?: boolean
  currentUser?: User
}

const UserResultItem: React.FC<Props> = ({
  user,
  isPC,
  isMe,
  currentUser,
}: Props) => {
  return (
    <View
      style={isPC ? styles.container : styles.containerMobile}
      testID="user-result-item"
    >
      <UserAvatar
        user={user}
        size={isPC ? ProfileAvatarSize.LARGE : ProfileAvatarSize.MEDIUM}
      />
      <View style={isPC ? styles.profileInfo : styles.profileInfoMobile}>
        <View>
          <UserName size="medium" user={user} />
        </View>
        <Text style={styles.description} numberOfLines={2}>
          {user?.profile?.title}
        </Text>
        {user?.profile?.residence && (
          <Text style={styles.description} numberOfLines={2}>
            {user?.profile?.residence}
          </Text>
        )}
        <ProfileFollow isPC={isPC} type="user-item" user={user} />
        {!isPC && !currentUser?.isCompanyAccount && (
          <View style={styles.followButtonMobile}>
            <FollowButton user={user} size="small" />
          </View>
        )}
      </View>
      {isPC && !currentUser?.isCompanyAccount && !isMe && (
        <View style={styles.followButton}>
          <FollowButton user={user} size="small" />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%',
  },
  containerMobile: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    flex: 1,
    padding: 10,
  },
  description: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.SUB,
  },
  followButton: {
    marginLeft: 18,
  },
  followButtonMobile: {
    paddingTop: 5,
  },
  profileInfo: {
    marginLeft: 20,
    width: 200,
  },
  profileInfoMobile: {
    marginLeft: 13,
    marginRight: 20,
    width: '40%',
  },
})

export default UserResultItem
