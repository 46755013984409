import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import imageSource from '~/assets/images/admin.png'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'

type Props = {
  visible: boolean
  onDismiss?: () => void
  promptMessage: string
}

const UploadAvatarPrompt: React.FC<Props> = ({
  visible,
  onDismiss,
  promptMessage,
}: Props) => {
  const { isPC } = useDevice()
  const imageStyle = [styles.image, isPC ? styles.imagePC : styles.imageMobile]
  const titleStyle = [styles.title, isPC ? styles.titlePC : styles.titleMobile]
  const containerStyle = [
    styles.container,
    isPC ? styles.containerPC : styles.containerMobile,
  ]

  return (
    <ModalContainer visible={visible} onDismiss={onDismiss}>
      <View style={containerStyle} testID={'upload-avatar-prompt'}>
        <TouchableOpacity
          onPress={onDismiss}
          style={styles.closeButton}
          testID={'prompt-close-button'}
        >
          <Icon size={20} name="close" color={color.textGray} />
        </TouchableOpacity>
        <Image style={imageStyle} source={imageSource} />
        <Text style={titleStyle}>{promptMessage}</Text>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  closeButton: { alignItems: 'flex-end' },
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    padding: 10,
    ...commonStyles.borderRadiusAllCorner,
  },
  containerMobile: {
    height: 290,
    width: 370,
  },
  containerPC: {
    height: 352,
    width: 420,
  },
  image: {
    alignSelf: 'center',
  },
  imageMobile: {
    width: 100,
    height: 180,
  },
  imagePC: {
    width: 180,
    height: 244,
  },
  title: {
    color: color.darkTitleText,
    textAlign: 'center',
  },
  titleMobile: {
    fontSize: FontSize.GENERAL,
    paddingTop: 20,
  },
  titlePC: {
    fontSize: FontSize.GENERAL,
    paddingTop: 20,
  },
})

export default UploadAvatarPrompt
