import { useFocusEffect } from '@react-navigation/native'
import { isEmpty, orderBy } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { CommunityQueryParams } from '~/api/communities/communities'
import TitleText from '~/components/common/atoms/TitleText'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import SkeletonCard from '~/components/common/molecules/SkeletonCard'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import CreateCommunity from '~/components/community/molecules/CreateCommunity'
import CommunityItem from '~/components/community/organisms/CommunityItem'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Community from '~/interfaces/Community'
import { RootState } from '~/rootReducer'

type Props = {
  header?: React.ReactElement
  params: Partial<CommunityQueryParams>
  hasLoadMoreButton?: boolean
  emptyView?: React.ReactElement
}

const CommunityList: React.FC<Props> = ({
  header,
  params,
  emptyView,
  hasLoadMoreButton,
}: Props) => {
  const defaultPage = 1
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const numColumns = isPC ? 3 : 1
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [isLoading, setIsLoading] = useState(true)
  const [communities, setCommunities] = useState<Community[]>([])
  const shouldShowSkeleton = isLoading && isEmpty(communities)
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)

  const renderItem: ListRenderItem<Community> = ({
    item,
  }: {
    item: Community
  }) => (
    <CommunityItem
      community={item}
      isPC={isPC}
      status={params?.status}
      onCancel={reloadCommunities}
    />
  )

  const fetchCommunities = useAPI(
    async (api: API, page: number): Promise<void> => {
      setIsLoading(true)
      try {
        const response = await api.communities
          .configPath(params?.type)
          .index<Community[], CommunityQueryParams>({
            ...params,
            q: params.q,
            page,
          })
        const sortedResponse = orderBy(response, ['id'], 'desc')
        if (page > currentPage) {
          !isEmpty(sortedResponse) &&
            setCommunities((communities) => [...communities, ...sortedResponse])
        } else {
          setCommunities(sortedResponse)
        }
      } catch (err) {
        toast.showError(err)
      }
      api.isAlive && setIsLoading(false)
    },
    [currentPage, setIsLoading, params?.category, params?.q, params?.type]
  )

  const fetchMoreCommunities = (): void => {
    !isLoading && fetchCommunities(currentPage + 1)
  }

  const reloadCommunities = (communityId: number) => {
    setCommunities((prevState) =>
      prevState.filter((community) => community.id !== communityId)
    )
  }

  const keyExtractor = (item: Community): string => item.id.toString()

  useFocusEffect(
    React.useCallback(() => {
      fetchCommunities(currentPage)
    }, [fetchCommunities])
  )

  useFocusEffect(
    React.useCallback(() => {
      isBottom && !hasLoadMoreButton && fetchMoreCommunities()
    }, [isBottom])
  )

  useFocusEffect(
    React.useCallback(() => {
      setCurrentPage(defaultPage)
    }, [params?.category, params?.q, params?.type])
  )

  return (params?.status === 'pending' || params?.status === 'reject') &&
    isEmpty(communities) ? (
    <></>
  ) : (
    <View
      testID="community-list"
      style={isPC && params?.type === 'manage' ? styles.header : null}
    >
      {shouldShowSkeleton ? (
        <View style={styles.loadingView}>
          <SkeletonList
            count={9}
            header={header}
            numColumns={isPC ? 3 : 1}
            itemComponent={<SkeletonCard isPC={isPC} />}
          />
        </View>
      ) : (
        <InfiniteScrollFlatList
          data={communities}
          scrollEnabled={!isPC}
          initialNumToRender={10}
          numColumns={numColumns}
          renderItem={renderItem}
          extraData={communities}
          keyExtractor={keyExtractor}
          ListFooterComponent={
            hasLoadMoreButton && !isEmpty(communities) ? (
              <View style={isPC ? styles.extraContainer : undefined}>
                <TitleText
                  onPress={fetchMoreCommunities}
                  style={styles.loadMoreButton}
                  testID="see-more"
                >
                  {t('seeMore')}
                </TitleText>
              </View>
            ) : undefined
          }
          ListHeaderComponent={
            <View style={isPC ? styles.extraContainer : undefined}>
              {header}
              {!isPC &&
                params?.type === 'manage' &&
                params?.status === 'approved' && (
                  <CreateCommunity isPC={isPC} />
                )}
            </View>
          }
          columnWrapperStyle={!isPC ? undefined : styles.wrapper}
          ListEmptyComponent={isLoading ? undefined : emptyView}
          style={
            isPC ? styles.flatListContainer : styles.flatListContainerMobile
          }
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  extraContainer: {
    paddingHorizontal: 8,
    alignSelf: 'center',
    width: 940,
  },
  flatListContainer: {
    flex: 1,
  },
  flatListContainerMobile: {
    paddingBottom: 90,
  },
  header: {
    marginTop: 20,
  },
  loadMoreButton: {
    width: 200,
    marginVertical: 10,
    color: color.pressableText,
    textDecorationLine: 'underline',
  },
  loadingView: {
    flex: 1,
    width: 940,
    alignSelf: 'center',
    paddingHorizontal: 8,
  },
  wrapper: {
    alignSelf: 'center',
    width: 940,
  },
})

export default CommunityList
