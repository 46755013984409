import React from 'react'
import { StyleSheet } from 'react-native'
import { MenuOption } from 'react-native-popup-menu'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  title: string
  onSelect?: () => void
  hasSeparator?: boolean
}

const MenuItem: React.FC<Props> = ({
  title,
  onSelect,
  hasSeparator,
}: Props) => (
  <MenuOption
    onSelect={onSelect}
    style={hasSeparator ? styles.separator : undefined}
  >
    <Text numberOfLines={1} style={styles.optionText}>
      {title}
    </Text>
  </MenuOption>
)

const styles = StyleSheet.create({
  optionText: {
    // FIXME
    // Normally when there is a children, it should render children instead of React Native Text.
    // And fontFamily should be changed base on children component.
    // Somehow it doesn't work correctly.
    // See. https://github.com/instea/react-native-popup-menu/blob/master/src/MenuOption.js#L52 (Version: 0.15.11)
    fontFamily: getFontByLanguage(getCurrentLanguage()),
    textAlign: 'center',
    fontSize: FontSize.GENERAL,
  },
  separator: {
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
  },
})
export default MenuItem
