import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'

import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const EnjinTermsAndPolicy: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity>
          <Link testID="privacy" to="/privacy" style={styles.link}>
            {t('footer.menu.privacy')}
          </Link>
        </TouchableOpacity>
        <Text style={styles.verticalSeparate}>|</Text>
        <TouchableOpacity>
          <Link testID="terms" to="/terms" style={styles.link}>
            {t('footer.menu.terms')}
          </Link>
        </TouchableOpacity>
        <Text style={styles.verticalSeparate}>|</Text>
        <Text testID="enjin" style={styles.link}>
          {t('footer.menu.enjin')}
        </Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 20,
  },
  link: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  verticalSeparate: {
    color: color.textGray,
    marginLeft: 5,
    marginRight: 5,
  },
})

export default EnjinTermsAndPolicy
