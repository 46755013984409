import { range, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, StyleSheet, TouchableOpacity, View } from 'react-native'

import { UserProfile } from '~/api/users'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import TitleText from '~/components/common/atoms/TitleText'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import ProfileCard from '~/components/common/molecules/users/ProfileCard'
import WebsiteReferenceForm from '~/components/common/molecules/users/WebsiteReferenceForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'
import WebsiteReference from '~/interfaces/WebsiteReference'

type Props = {
  user?: User
  onUpdateProfile?: (profile: UserProfile) => void
  isMe?: boolean
  isPC: boolean
}

const WebsiteReferencesInfo: React.FC<Props> = ({
  user,
  isMe,
  onUpdateProfile,
  isPC,
}: Props) => {
  const { t } = useTranslation()

  const currentWebsiteReferences: WebsiteReference[] =
    user && Array.isArray(user?.profile.websiteReferences)
      ? user.profile.websiteReferences
      : []
  const [editing, setEditing] = useState(false)
  const [websiteReferences, setWebsiteReferences] = useState<
    Partial<WebsiteReference & { key?: string }>[]
  >(currentWebsiteReferences)

  useEffect(() => {
    setWebsiteReferences(currentWebsiteReferences)
  }, [currentWebsiteReferences])

  const startEdit = (): void => {
    setEditing(true)
  }

  const cancelEdit = (): void => {
    setWebsiteReferences(currentWebsiteReferences)
    setEditing(false)
  }

  const save = async (): Promise<void> => {
    const dataSaveWebsiteReferences = websiteReferences.filter(
      (item) => item.link
    )
    onUpdateProfile &&
      onUpdateProfile({
        userProfile: {
          id: user?.id,
          websiteReferences: dataSaveWebsiteReferences,
        },
      } as UserProfile)
    setEditing(false)
  }

  const removeForm = (index: number): void => {
    const newWebsiteReferences = websiteReferences.slice()
    newWebsiteReferences.splice(index, 1)
    setWebsiteReferences(newWebsiteReferences)
  }

  const changeWebsiteReferenceValue = (
    website: Partial<WebsiteReference>,
    index: number
  ): void => {
    const newWebsite = { ...websiteReferences[index], ...website }
    const newWebsiteReferences = websiteReferences.slice()
    newWebsiteReferences.splice(index, 1, newWebsite)
    setWebsiteReferences(newWebsiteReferences)
  }

  const addNewWebsiteReferenceForm = (): void => {
    setWebsiteReferences(
      websiteReferences.concat([{ key: `${uniqueId('website-reference-')}` }])
    )
  }

  const websiteReferenceForms = range(websiteReferences.length).map((index) => {
    const websiteKey =
      websiteReferences[index].id !== undefined
        ? `${websiteReferences[index].id}`
        : `${websiteReferences[index].key}`

    return (
      <View key={websiteKey}>
        <WebsiteReferenceForm
          index={index}
          websiteReference={websiteReferences[index]}
          onChange={changeWebsiteReferenceValue}
          onPressClose={() => {
            removeForm(index)
          }}
        />
        <View style={styles.separator} />
      </View>
    )
  })

  const openWebsite = (url: string) => {
    Linking.openURL(url)
  }

  const websiteReferenceInfoForms = range(websiteReferences.length).map(
    (index) => {
      const websiteKey =
        websiteReferences[index].id !== undefined
          ? `${websiteReferences[index].id}`
          : `${websiteReferences[index].key}`

      return (
        <TouchableOpacity
          testID="website-references-item"
          onPress={() => {
            const websiteLink = websiteReferences[index].link
            if (websiteLink !== undefined) {
              openWebsite(websiteLink)
            }
          }}
          key={websiteKey}
        >
          <View style={styles.list}>
            <ProfileCard
              icon=""
              iconSize="medium"
              title={websiteReferences[index].description || ''}
              description={websiteReferences[index].link || ''}
            />
          </View>
        </TouchableOpacity>
      )
    }
  )

  return (
    <View testID="website-references-info">
      {editing ? (
        <FormLayout
          title={t('profile.links')}
          hasAddAction={true}
          containerStyle={styles.editForm}
          isPC={isPC}
          onAddPress={addNewWebsiteReferenceForm}
        >
          <View style={styles.content}>{websiteReferenceForms}</View>
        </FormLayout>
      ) : (
        <>
          <TitleText
            testID="website-references-info-title"
            style={styles.title}
          >
            {t('profile.links')}
          </TitleText>
          {websiteReferenceInfoForms}
        </>
      )}
      {(isMe || editing) && (
        <View
          style={styles.textInputButtonAction}
          testID="website-references-action"
        >
          <TextInputAction
            onCancelPress={cancelEdit}
            onSavePress={save}
            onEditPress={startEdit}
            isSaveLoading={false}
            isEdit={true}
            isNormalMode={!editing}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    marginLeft: 10,
    padding: 10,
  },
  editForm: {
    paddingHorizontal: 0,
    paddingTop: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
  },
  list: {
    marginTop: 16,
    gap: 16,
  },
  separator: {
    backgroundColor: color.borderSeparator,
    height: 1,
    marginBottom: 20,
  },
  textInputButtonAction: {
    alignSelf: 'flex-end',
  },
  title: {
    color: color.textGray,
    marginTop: 10,
  },
})

export default WebsiteReferencesInfo
