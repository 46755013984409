import React from 'react'
import { Time, TimeProps } from 'react-native-gifted-chat'

import color from '~/constants/common/color'
import Message from '~/interfaces/Message'

export const renderTime = (props: TimeProps<Message>): React.ReactNode => {
  return (
    <Time
      {...props}
      timeTextStyle={{
        left: {
          color: color.textGray,
        },
        right: {
          color: color.textGray,
        },
      }}
    />
  )
}
