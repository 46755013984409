import Media from '~/interfaces/Media'
import { QuizCategoryType } from '~/interfaces/QuizCategory'

export const quizChoiceLabels: string[] = ['A', 'B', 'C', 'D']

export default interface Quiz {
  id: number
  question: string
  explanation: string
  createdAt: string
  updatedAt: string
  choices: string[]
  answer: string
  category: QuizCategoryType
  image?: Media
}
