import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  checkAllButton?: React.ReactElement
}

const NotificationsHeader: React.FC<Props> = ({ checkAllButton }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="notifications-header">
      <Text style={styles.title}>{t('notification.notifications')}</Text>
      {checkAllButton}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.backgroundNotification,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: color.darkTitleText,
    ...commonStyles.titleTextSize,
    fontWeight: 'bold',
  },
})

export default NotificationsHeader
