import AsyncStorage from '@react-native-async-storage/async-storage'
import camelcaseKeys from 'camelcase-keys'
import * as DocumentPicker from 'expo-document-picker'

import { HTTPError } from '~/httpClient'

export async function uploadFile<T>(path: string, body: BodyInit): Promise<T> {
  const headers = await AsyncStorage.getItem('access_token').then(
    (accessToken) => ({
      Authorization: `Bearer ${accessToken}`,
    })
  )

  const response = await fetch(path, {
    method: 'POST',
    body,
    headers,
  })

  if (!response.ok) {
    const error = new HTTPError(response)
    throw error
  }

  let parsedBody: unknown = {}
  try {
    // may error if there is no body
    const json = await response.json()
    parsedBody = camelcaseKeys(json, { deep: true })
  } catch (ex) {
    console.error(ex)
  }

  return parsedBody as T
}

export const selectFile = (): Promise<DocumentPicker.DocumentResult> =>
  DocumentPicker.getDocumentAsync({
    type: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
    ],
  })
