enum IconName {
  COMMUNITY_TALK = 'comments',
  COMMUNITY_EVENT = 'history',
  COMMUNITY_INFORMATION = 'id-badge',
  COMMUNITY_ADMIN = 'cogs',
  COMMUNITY_MEMBER = 'users',
  USER_INFO = 'id-badge',
  USER_FORUM = 'comments',
  USER_TIMELINE = 'history',
  MY_QUIZZ = 'question',
  DRAWER_SETTING = 'gear',
  DRAWER_HELP_SUPPORT = 'question-circle',
  DRAWER_SIGNOUT = 'sign-out',
  DRAWER_RESUME = 'file-text',
}

export default IconName
