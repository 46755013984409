import { captureException, setExtra } from '@sentry/minimal'
import { init } from 'sentry-expo'

const sndDev =
  'https://d442e800c167420996827dfebd1e2df4@o967296.ingest.sentry.io/5918359'

export const initSentry = (): void => {
  const isDev = process.env.NODE_ENV !== 'production'
  if (isDev) {
    return
  }
  try {
    init({
      dsn: isDev ? sndDev : process.env.SENTRY_DNS,
      enableInExpoDevelopment: true,
      debug: isDev,
    })
  } catch (error) {
    //TODO nothing
  }
}

export const logException = (error: Error, extra: unknown): void => {
  if (process.env.NODE_ENV !== 'production') return
  try {
    setExtra('captureException', extra)
    captureException(error)
  } catch (error) {
    // TODO nothing
  }
}
