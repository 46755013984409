import { RouteProp, useRoute } from '@react-navigation/native'
import { isUndefined } from 'lodash'
import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import CompanyTemplate from '~/components/career/templates/company/CompanyTemplate'
import { MainStackParamList } from '~/interfaces/RootStackParamList'
import { RouteParamsID } from '~/utils/navigation'

const CompanyShow: React.FC = () => {
  const route = useRoute<RouteProp<MainStackParamList>>()
  const companyId = (route?.params as RouteParamsID)?.id

  if (isUndefined(companyId)) return null

  return (
    <DefaultLayout>
      <CompanyTemplate />
    </DefaultLayout>
  )
}

export default CompanyShow
