import React from 'react'
import { StyleSheet, TextProps, View } from 'react-native'

import Text from '~/components/workarounds/Text'

type Props = {
  value: string
  icon?: React.ReactElement
} & TextProps

const TextWithIcon: React.FC<Props> = (props: Props) => (
  <View style={styles.container} testID="text-with-icon">
    {props.icon}
    <Text {...props}>{props.value}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 4,
    alignItems: 'baseline',
  },
})

export default TextWithIcon
