import React from 'react'
import { Text as RNElementsText, TextProps } from 'react-native-elements'

type Props = {
  children?: React.ReactNode
} & TextProps

const Text: React.FC<Props> = (props: Props) => (
  //eslint-disable-next-line react-native/no-single-element-style-arrays
  <RNElementsText {...props} style={[props.style]}>
    {props.children}
  </RNElementsText>
)

export default Text
