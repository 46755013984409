import React, { useEffect, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import api from '~/api'
import { PostRequest } from '~/api/posts'
import imageIcon from '~/assets/images/icons/picture.png'
import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import PostCommentInputArea, {
  PostCommentAreaData,
} from '~/components/common/organisms/PostCommentInputArea'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Media from '~/interfaces/Media'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'

type Props = {
  postId: number
  comment?: Post
  isEdit?: boolean
  currentUser?: User
  onCancel?: () => void
  onCreate?: (post: Post) => void
  onUpdate?: (post: Post) => void
}

const PostCommentForm: React.FC<Props> = ({
  isEdit,
  postId,
  comment,
  onCancel,
  onCreate,
  onUpdate,
  currentUser,
}: Props) => {
  const toast = useCustomToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const [postData, setPostData] = useState<PostCommentAreaData>({})

  const updatePostData = (data: Partial<PostCommentAreaData>): void => {
    setPostData({ ...postData, ...data })
  }

  const updateMedia = (media: Media[]): void => {
    updatePostData({ media })
  }

  const submit = (): void => {
    const bodyData: PostRequest = {
      id: comment?.id,
      message: postData.message || '',
      media: postData.media,
      replyToId: postId,
    }
    setIsLoading(true)
    isEdit ? updatePost(bodyData) : createPost(bodyData)
  }

  const createPost = async (postRequest: PostRequest): Promise<void> => {
    try {
      const post = await api.posts.create(postRequest)
      setPostData({})
      setIsLoading(false)
      onCreate && onCreate(post)
    } catch (error) {
      toast.showError(error)
      setIsLoading(false)
    }
  }

  const updatePost = async (postRequest: PostRequest): Promise<void> => {
    try {
      const post = await api.posts.update(postRequest)
      setPostData({})
      setIsLoading(false)
      onUpdate && onUpdate(post)
    } catch (error) {
      toast.showError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPostData({
      message: comment?.message,
      media: [...(comment?.images || []), ...(comment?.videos || [])],
    })
  }, [comment])

  return (
    <View style={styles.container} testID="post-comment-form">
      <PostCommentInputArea
        data={postData}
        submit={submit}
        cancel={onCancel}
        isLoading={isLoading}
        currentUser={currentUser}
        onChanged={updatePostData}
        isEdit={isEdit}
        mediaPicker={
          <ImagePickerButton
            type="clear"
            kind="post"
            onUpload={updateMedia}
            loading={isLoadingMedia}
            disabled={isLoadingMedia}
            onLoading={setIsLoadingMedia}
            titleStyle={StyleSheet.flatten(styles.imagePickerButton)}
            icon={<Image style={styles.icon} source={imageIcon} />}
          />
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
  },
  icon: {
    height: 15,
    width: 15,
  },
  imagePickerButton: {
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginLeft: 4,
  },
})

export default PostCommentForm
