import { FontAwesome } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  onPress?: () => void
}

const CreateEventButton: React.FC<Props> = ({ onPress }: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      type="outline"
      onPress={onPress}
      iconPosition="left"
      testID="create-event-button"
      containerStyle={styles.container}
      titleStyle={commonStyles.buttonTextSize}
      title={t('events.createNewEvent')}
      icon={
        <FontAwesome
          name="calendar-plus-o"
          color={color.primary}
          style={styles.createEventIcon}
          size={commonStyles.buttonTextSize.fontSize}
        />
      }
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    borderRadius: 0,
  },
  createEventIcon: {
    marginRight: 6,
  },
})

export default CreateEventButton
