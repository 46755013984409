import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import api from '~/api'
import BackToLogin from '~/components/common/atoms/auth/BackToLogin'
import EmailInput from '~/components/common/atoms/auth/EmailInput'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC?: boolean
}

const RequestPasswordResetForm: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(false)

  const changeEmail = (value: string, isValid: boolean): void => {
    setEmail(value)
    setEmailValid(isValid)
  }

  const submit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await api.auth.requestPasswordReset(email)
      setMessage(response.message)
    } catch (error) {
      setMessage(t('auth.requestPasswordResetError'))
      setIsLoading(false)
    }
  }

  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View style={containerStyle} testID="request-password-reset-form">
      <Text style={styles.message} testID="request-password-reset-message">
        {message}
      </Text>
      <EmailInput onChangeText={changeEmail} />
      <View style={styles.separator} />
      <View style={styles.backToLogin}>
        <BackToLogin />
      </View>
      <Button
        testID="submit"
        onPress={submit}
        disabled={!emailValid || isLoading}
        titleStyle={commonStyles.buttonTextSize}
        title={t('auth.requestPasswordRecovery')}
        buttonStyle={StyleSheet.flatten(styles.submit)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  backToLogin: {
    alignSelf: 'center',
    marginBottom: 12,
  },
  container: {
    marginTop: 20,
  },
  containerMobile: {
    paddingHorizontal: 35,
  },
  message: {
    alignSelf: 'center',
    color: color.warning,
    paddingBottom: 20,
    textAlign: 'center',
    fontSize: FontSize.GENERAL,
  },
  separator: {
    backgroundColor: color.textGray,
    height: 1,
    margin: 20,
  },
  submit: {
    marginHorizontal: 10,
    marginTop: 10,
    paddingVertical: 10,
  },
})

export default RequestPasswordResetForm
