import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import JobsTemplate from '~/components/career/templates/jobs/JobsTemplate'
import { setPageTitle } from '~/utils/navigation'

const Jobs: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('jobs.jobs')))

  return (
    <DefaultLayout>
      <JobsTemplate />
    </DefaultLayout>
  )
}

export default Jobs
