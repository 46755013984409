import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import EditableText from '~/components/common/atoms/EditableText'
import TitleText from '~/components/common/atoms/TitleText'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import { RootState } from '~/rootReducer'
import { updateCurrentUser } from '~/slices/common/users'

type Props = {
  content?: string
  isMe?: boolean
}

type SelfIntroductionHook = {
  content?: string
  setContent: (text?: string) => void
  editable: boolean
  setEditable: (editable: boolean) => void
  isValid: boolean
}

const LIMIT_CHARACTERS = 1000

export const useSelfIntroduction = (
  initContent?: string
): SelfIntroductionHook => {
  const [content, setContent] = useState(initContent)
  const [editable, setEditable] = useState(false)
  const [isValid, setIsValid] = useState<boolean>(true)

  useEffect(() => {
    if (content) {
      setIsValid(content.length <= LIMIT_CHARACTERS)
    }
  }, [content])

  useEffect(() => {
    setContent(initContent)
  }, [initContent])

  return {
    content,
    setContent,
    editable,
    setEditable,
    isValid,
  }
}

const SelfIntroduction: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.users.isLoading)

  const { editable, setEditable } = useSelfIntroduction()
  const { content, setContent, isValid } = useSelfIntroduction(props.content)
  const containerStyle = styles.container
  const toast = useCustomToast()

  const editContent = (): void => {
    setEditable(true)
  }

  const cancelEdit = (): void => {
    setEditable(false)
    setContent(props.content)
  }

  const submitUserProfile = async (): Promise<void> => {
    try {
      await Promise.all([
        dispatch(updateCurrentUser({ userProfile: { aboutMe: content } })),
      ])
      setEditable(false)
    } catch (error) {
      toast.showError(error)
    }
  }

  const errorMessageDisplay = (): string => {
    if (!isValid) {
      return `${t('maxLength', { maxLength: LIMIT_CHARACTERS })}`
    }

    return ''
  }

  return (
    <CardContainer style={containerStyle}>
      <View style={styles.rowContainerStyle}>
        <TitleText testID="self-introduction-title" style={styles.title}>
          {t('profile.selfIntroductionTitle')}
        </TitleText>
        {editable && (
          <Text
            testID="self-introduction-valid-character"
            style={[
              styles.textLengthStyle,
              { color: isValid ? color.textGray : color.danger },
            ]}
          >
            {t('character', {
              limit: `${content ? content.length : 0}/${LIMIT_CHARACTERS}`,
              count: content ? content.length : 0,
            })}
          </Text>
        )}
      </View>

      <View style={styles.introductionContainer}>
        <EditableText
          multiline
          value={content}
          editable={editable}
          busyMode={isLoading}
          onChangeText={setContent}
        />
      </View>

      <Text style={styles.error} testID="error-message">
        {errorMessageDisplay()}
      </Text>
      {props.isMe && (
        <View style={styles.textInputButtonAction}>
          <TextInputAction
            isEdit={true}
            onCancelPress={cancelEdit}
            onSavePress={submitUserProfile}
            onEditPress={editContent}
            isSaveLoading={isLoading}
            isNormalMode={!editable}
            isDisabled={!isValid}
          />
        </View>
      )}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 20,
  },
  error: {
    color: color.badgeColor,
    fontSize: FontSize.SUB,
    paddingTop: 4,
  },
  introductionContainer: {
    marginTop: 10,
  },
  rowContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInputButtonAction: {
    alignSelf: 'flex-end',
  },
  textLengthStyle: {
    fontSize: FontSize.SUB,
  },
  title: {
    color: color.textGray,
  },
})

export default SelfIntroduction
