import React from 'react'
import { TouchableOpacity } from 'react-native'
import { SocialIcon } from 'react-native-elements'

import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  title?: string
  loading?: boolean
  onPress?: () => void
  isPC: boolean
}

const FacebookButton: React.FC<Props> = ({
  title,
  loading,
  onPress,
  isPC,
}: Props) => (
  <TouchableOpacity testID="facebook-button" onPress={onPress}>
    <SocialIcon
      raised={false}
      iconSize={isPC ? 32 : 20}
      title={title}
      button={true}
      type="facebook"
      loading={loading}
      iconType="facebook"
      style={styles.button}
      iconColor={color.white}
    />
  </TouchableOpacity>
)

const styles = {
  button: {
    borderRadius: 2,
    height: 46,
    ...commonStyles.buttonTextSize,
    fontFamily: getMediumFontByLanguage(getCurrentLanguage()),
  },
}

export default FacebookButton
