import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet, TextStyle, View } from 'react-native'

import CategoryTitle from '~/components/career/molecules/resume/CategoryTitle'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  value: string
  reason: string
  categoryTitle: string
  templateType: TemplateType
}

const InterestedGroup: React.FC<Props> = ({
  value,
  reason,
  templateType,
  categoryTitle,
}: Props) => {
  const titleStyles = {
    enjin: {
      fontSize: FontSize.GENERAL,
      fontWeight: 'bold',
    },
    shades: {
      fontSize: FontSize.GENERAL,
      fontWeight: 'bold',
    },
    formal: { color: color.black },
    minimal: {
      fontSize: FontSize.GENERAL,
      fontWeight: 'bold',
      paddingBottom: 7,
    },
  }

  const achievementNameIcon = {
    enjin: (
      <Icon name="circle" size={14} color={color.primary} style={styles.icon} />
    ),
    shades: (
      <Icon name="circle" size={14} color={color.black} style={styles.icon} />
    ),
    formal: undefined,
    minimal: undefined,
  }

  const renderFormal = () => {
    return (
      <>
        <View style={styles.title}>
          <CategoryTitle
            inverted
            title={categoryTitle}
            templateType={templateType}
          />
        </View>
        <View style={styles.description}>
          <Text style={styles.name}>{value}</Text>
          <Text>{reason}</Text>
        </View>
      </>
    )
  }

  const renderOther = () => {
    return (
      <>
        <CategoryTitle
          inverted
          title={categoryTitle}
          templateType={templateType}
        />
        <HorizontalContainer>
          {achievementNameIcon[templateType]}
          <Text style={titleStyles[templateType] as TextStyle}>{value}</Text>
        </HorizontalContainer>
        <Text>{reason}</Text>
      </>
    )
  }

  return (
    <View
      style={[
        styles.container,
        templateType === 'formal' ? styles.formal : undefined,
      ]}
      testID="interested-group"
    >
      {templateType === 'formal' ? renderFormal() : renderOther()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  description: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 10,
  },
  formal: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 6,
  },
  name: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    paddingVertical: 6,
  },
  title: {
    width: 200,
  },
})

export default InterestedGroup
