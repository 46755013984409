import React from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'

import PostInput from '~/components/career/atoms/post/PostInput'
import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import Media from '~/interfaces/Media'

type Props = {
  text: string
  media: Media[]
  onChangeText?: (text: string) => void
  onRemove?: (media?: Media) => void
  detectedURL?: string
  onChangePreview?: (isShow: boolean) => void
  isShowPreviewPost?: boolean
}

const Input: React.FC<Props> = ({
  text,
  media,
  onChangeText,
  onRemove,
  detectedURL,
  onChangePreview,
  isShowPreviewPost,
}: Props) => (
  <KeyboardAvoidingView
    behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
    keyboardVerticalOffset={Platform.OS == 'ios' ? 0 : 20}
    enabled={Platform.OS === 'ios' ? true : false}
  >
    <ScrollView style={styles.container}>
      <PostInput
        value={text}
        onChangeText={onChangeText}
        detectedURL={detectedURL}
        onChangePreview={onChangePreview}
        isShowPreviewPost={isShowPreviewPost}
      />
      {media.length > 0 ? (
        <>
          <View style={styles.separator} />
          <MediaPreview media={media} retry={true} onRemove={onRemove} />
        </>
      ) : null}
    </ScrollView>
  </KeyboardAvoidingView>
)

const styles = StyleSheet.create({
  container: {
    maxHeight: 460,
  },
  separator: {
    marginTop: 10,
  },
})

export default Input
