import { BaseAPI } from '~/api/baseAPI'

export type CompanyBodyParams = {
  tel: string
  name: string
  enName: string
  aboutUs?: string
  location: string
  homepage: string
  industryIds: number[]
}

export class CompaniesAPI extends BaseAPI {
  path = '/companies'
}
