import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import MonthPicker from '~/components/common/atoms/MonthPicker'
import YearPicker from '~/components/common/atoms/YearPicker'
import TextField from '~/components/common/molecules/TextField'
import color from '~/constants/common/color'
import Achievement from '~/interfaces/Achievement'

type Props = {
  index: number
  onChange: (value: Partial<Achievement>, index: number) => void
  achievement: Partial<Achievement>
  onPressClose: () => void
}

const AchievementForm: React.FC<Props> = ({
  index,
  onChange,
  achievement,
  onPressClose,
}: Props) => {
  const { t } = useTranslation()

  const awardedDateInfo = achievement.awardedDate
    ? new Date(achievement.awardedDate || '')
    : undefined
  const [month, setMonth] = useState<number | undefined>(
    awardedDateInfo ? awardedDateInfo.getMonth() + 1 : undefined
  )
  const [year, setYear] = useState<number | undefined>(
    awardedDateInfo?.getFullYear() || undefined
  )

  const changeName = (value: string): void => {
    onChange({ name: value }, index)
  }

  const changeGiver = (value: string): void => {
    onChange({ giver: value }, index)
  }

  const changeMonth = (month: number): void => {
    setMonth(month)
    year &&
      onChange({ awardedDate: new Date(year, month, 0).toDateString() }, index)
  }

  const changeYear = (year: number): void => {
    setYear(year)
    month &&
      onChange({ awardedDate: new Date(year, month, 0).toDateString() }, index)
  }

  const changeDescription = (value: string): void => {
    onChange({ description: value }, index)
  }

  return (
    <View style={styles.container}>
      {index !== 0 && (
        <TouchableOpacity
          onPress={onPressClose}
          style={styles.closeButton}
          testID={'achievement-close-button'}
        >
          <Icon size={20} name="close" color={color.primary} />
        </TouchableOpacity>
      )}
      <View testID="achievement-form">
        <TextField
          placeholder={t('profile.placeholderAchievementName')}
          value={achievement.name}
          onChangeText={changeName}
          title={`${t('profile.achievementName')}*`}
        />
        <TextField
          placeholder={t('profile.placeholderAchievementGiver')}
          value={achievement.giver}
          onChangeText={changeGiver}
          title={`${t('profile.achievementGiver')}*`}
        />

        <View style={styles.timeGroup}>
          <MonthPicker
            selectedValue={month}
            onChange={changeMonth}
            title={`${t('profile.achievementMonth')}*`}
          />
          <View style={styles.separator} />
          <YearPicker
            selectedValue={year}
            onChange={changeYear}
            title={`${t('profile.achievementYear')}*`}
          />
        </View>

        <TextField
          numberOfLines={4}
          title={t('profile.description')}
          value={achievement.description}
          onChangeText={changeDescription}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    marginBottom: 40,
  },
  separator: {
    width: 40,
  },
  timeGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
})

export default AchievementForm
