import React from 'react'
import { TextProps } from 'react-native-elements'

import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'

const TitleText: React.FC<TextProps> = (props: TextProps) => {
  const language = getCurrentLanguage()
  const fontWeight = 'bold'

  return (
    <Text
      {...props}
      style={[
        {
          fontSize: FontSize.REMARKABLE,
        },
        props.style,
        {
          fontFamily: getFontByLanguage(language),
          fontWeight,
        },
      ]}
    />
  )
}

export default TitleText
