import { FontAwesome as Icon } from '@expo/vector-icons'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Send, SendProps } from 'react-native-gifted-chat'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Message from '~/interfaces/Message'

const ChatSendButton: React.FC<
  Readonly<SendProps<Message>> & Readonly<{ children?: React.ReactNode }>
> = (props) => {
  const shouldDisabled = isEmpty(props.text?.trim()) || props.disabled
  const { t } = useTranslation()
  const sendButtonStyle = StyleSheet.flatten([
    styles.sendButton,
    { backgroundColor: shouldDisabled ? color.baseBackground : color.white },
  ])
  const textColor = shouldDisabled ? color.textGray : color.primary

  return (
    <Send
      {...props}
      containerStyle={styles.container}
      disabled={shouldDisabled}
    >
      <View testID="chat-send-button" style={sendButtonStyle}>
        <Icon name="paper-plane" size={20} color={textColor} />
        <Text
          style={StyleSheet.flatten([styles.sendTitle, { color: textColor }])}
        >
          {t('message.send')}
        </Text>
      </View>
    </Send>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginBottom: 4,
    marginLeft: 10,
    padding: 0,
  },
  sendButton: {
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: color.white,
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: 10,
  },
  sendTitle: {
    color: color.primary,
    fontSize: FontSize.GENERAL,
    paddingLeft: 10,
    paddingRight: 10,
  },
})

export default ChatSendButton
