import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '~/rootReducer'
import { openDrawer as open } from '~/utils/navigation'

type DrawerState = {
  currentDrawerType: DrawerType
  isDrawerOpen: boolean
}

export enum DrawerType {
  Notifications = 'notifications',
  Menu = 'menu',
  Search = 'search',
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    currentDrawerType: DrawerType.Menu,
    isDrawerOpen: false,
  } as DrawerState,
  reducers: {
    openDrawer: {
      reducer(
        state: DrawerState,
        action: PayloadAction<{ drawerType: DrawerType }>
      ): void {
        state.currentDrawerType = action.payload.drawerType
      },
      prepare(drawerType: DrawerType): { payload: { drawerType: DrawerType } } {
        open()
        return { payload: { drawerType } }
      },
    },
    drawerOpened(state: DrawerState): void {
      state.isDrawerOpen = true
    },
    drawerClosed(state: DrawerState): void {
      state.isDrawerOpen = false
    },
  },
})

export const drawerTypeSelector = (state: RootState): DrawerType =>
  state.drawer.currentDrawerType

export const { openDrawer, drawerOpened, drawerClosed } = drawerSlice.actions

export default drawerSlice.reducer
