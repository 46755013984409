import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  communityEvent: CommunityEvent
}

const EventType: React.FC<Props> = ({ communityEvent }: Props) => {
  const { t } = useTranslation()

  return (
    <Text style={styles.eventType} testID="event-type">
      {communityEvent.isVirtual ? t('events.virtual') : t('events.realWorld')}
    </Text>
  )
}

const styles = StyleSheet.create({
  eventType: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
})

export default EventType
