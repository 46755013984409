import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'

export enum CommunityUserControlMenuType {
  MakeAdmin = 'MakeAdmin',
  MakeChannelAdmin = 'MakeChannelAdmin',
  RemoveChannelAdmin = 'RemoveChannelAdmin',
  RemoveMember = 'RemoveMember',
  RemoveFromAdmin = 'RemoveFromAdmin',
}

export type CommunityUserControlMenuData = {
  title: string
  visible: boolean
  needConfirm?: boolean
  type: CommunityUserControlMenuType
}

type Props = {
  items: CommunityUserControlMenuData[]
  onSelect?: (type: CommunityUserControlMenuType) => void
}

const CommunityUserControlMenu: React.FC<Props> = ({
  items,
  onSelect,
}: Props) => {
  const { t } = useTranslation()
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false)
  const [currentType, setCurrentType] = useState<CommunityUserControlMenuType>()
  const data = items.filter((item) => item.visible)

  const handleSelectItem = (controlMenuData: ControlMenuData): void => {
    const selectedItem = data[controlMenuData.id as number]
    if (selectedItem.needConfirm) {
      setCurrentType(selectedItem.type)
      setShowConfirmDialog(true)
    } else {
      onSelect && onSelect(selectedItem.type)
    }
  }

  return (
    <View testID="community-user-control-menu">
      <ControlMenu
        data={data.map((item, index) => ({ id: index, title: item.title }))}
        onSelect={handleSelectItem}
      />
      {isShowConfirmDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('message.remove')}
          onPressAccept={(): void =>
            currentType && onSelect && onSelect(currentType)
          }
          onPressCancel={(): void => setShowConfirmDialog(false)}
          visible={isShowConfirmDialog}
          message={t('community.removeMemberConfirmMessage')}
        />
      )}
    </View>
  )
}

export default CommunityUserControlMenu
