import { MediaTypeOptions } from 'expo-image-picker'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import ProfileBanner, {
  ProfileBannerAction,
} from '~/components/common/molecules/ProfileBanner'
import useImagePicker from '~/hooks/useImagePicker'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'

type Props = {
  user: User
  isMe?: boolean
  onUpdateBanner: (media: Media[]) => void
}

const ProfileUserBanner: React.FC<Props> = ({
  isMe,
  user,
  onUpdateBanner,
}: Props) => {
  const { t } = useTranslation()
  const imagePath = get(user, 'profile.banner.urls.original')
  const pickBanner = useImagePicker({
    isDirectUpload: true,
    kind: 'banner',
    mediaTypes: MediaTypeOptions.Images,
    allowsMultipleSelection: false,
    onUpload: onUpdateBanner,
  })

  const onSelectBannerMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case ProfileBannerAction.REMOVE:
        onUpdateBanner([])
        break
      case ProfileBannerAction.CHANGE:
        pickBanner()
    }
  }

  const bannerMenuData: ControlMenuData[] = imagePath
    ? [
        {
          id: ProfileBannerAction.CHANGE,
          title: t('banner.changeBanner'),
        },
        {
          id: ProfileBannerAction.REMOVE,
          title: t('banner.removeBanner'),
        },
      ]
    : [
        {
          id: ProfileBannerAction.CHANGE,
          title: t('banner.changeBanner'),
        },
      ]

  return (
    <View testID="profile-user-banner">
      <ProfileBanner
        imagePath={imagePath}
        controlMenu={
          // <></>
          isMe ? (
            <ControlMenu
              data={bannerMenuData}
              onSelect={onSelectBannerMenu}
              menuTrigger={<ControlMenuButton inverted />}
            />
          ) : (
            <></>
          )
        }
      />
    </View>
  )
}

export default ProfileUserBanner
