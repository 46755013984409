import { range, uniqueId } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import EducationForm from '~/components/career/molecules/resume/EducationForm'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import color from '~/constants/common/color'
import Education from '~/interfaces/Education'
import { resumeLimitation } from '~/utils/career/resume'

type Props = {
  isPC: boolean
  onChange: (education: Partial<Education>[]) => void
  educations: Partial<Education & { key?: string }>[]
}

const EducationFormList: React.FC<Props> = ({
  isPC,
  onChange,
  educations,
}: Props) => {
  const { t } = useTranslation()
  const newItemIndex = educations.findIndex((item) => {
    return !item.id
  })

  const handleChangeEducationValue = (
    education: Partial<Education>,
    index: number
  ): void => {
    const newEducation = { ...educations[index], ...education }
    const newEducationDataList = educations.slice()
    newEducationDataList.splice(index, 1, newEducation)
    onChange(newEducationDataList)
  }

  const addNewEducationForm = (): void => {
    onChange(educations.concat([{ key: `${uniqueId('education-')}` }]))
  }

  const removeForm = (index: number): void => {
    const newEducationDataList = educations.slice()
    newEducationDataList.splice(index, 1)
    onChange(newEducationDataList)
  }

  const educationForms = range(educations.length).map((index) => {
    const educationKey =
      educations[index].id !== undefined
        ? `${educations[index].id}`
        : `${educations[index].key}`

    return (
      <View key={educationKey}>
        {index === newItemIndex && <View style={styles.separator} />}
        <EducationForm
          index={index}
          education={educations[index]}
          onChange={handleChangeEducationValue}
          isPC={isPC}
          onPressClose={() => {
            removeForm(index)
          }}
        />
      </View>
    )
  })

  return (
    <FormLayout
      title={t('profile.education')}
      hasAddAction={educations.length < resumeLimitation.education}
      isPC={isPC}
      onAddPress={addNewEducationForm}
    >
      <View style={styles.formContainer}>{educationForms}</View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    paddingTop: 10,
  },
  separator: {
    backgroundColor: color.borderSeparator,
    height: 1,
    marginBottom: 20,
  },
})

export default EducationFormList
