import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import DailyQuiz from '~/components/career/features/quizzes/DailyQuiz'
import FriendSuggestion from '~/components/common/molecules/friendSuggestion/FriendSuggestion'
import SuggestedJobs from '~/components/common/molecules/suggestedJobs/SuggestedJobs'
import useDevice from '~/hooks/commons/useDevice'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import {
  fetchFriendSuggestions,
  getAllFriendSuggestions,
  removeOne,
} from '~/slices/common/friendSuggestions'

const HomeRightColumn: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const friendSuggestions = useSelector((state: RootState) =>
    getAllFriendSuggestions(state)
  )

  const currentUser = useSelector((state: RootState) => state.users.current)
  const isCompanyAccount = currentUser?.isCompanyAccount

  useEffect(() => {
    if (accessToken && friendSuggestions.length === 0) {
      dispatch(fetchFriendSuggestions())
    }
  }, [accessToken, dispatch])

  const onItemDismiss = async (user: User): Promise<void> => {
    dispatch(removeOne(user.id))
  }

  return (
    <View testID="home-right-column">
      {!isCompanyAccount && (
        <View testID="home-daily-quiz">
          <DailyQuiz />
        </View>
      )}
      <View testID="home-friend-suggestion">
        <FriendSuggestion
          isPC={isPC}
          title={t('friendSuggestion.suggestionTitle')}
          data={friendSuggestions}
          onItemDismiss={onItemDismiss}
        />
      </View>
      <View testID="home-suggested-job">
        <SuggestedJobs isPC={isPC} title={t('jobs.suggestedJobTitle')} />
      </View>
    </View>
  )
}

export default HomeRightColumn
