import { useFocusEffect } from '@react-navigation/native'
import { pick } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import OverlayLoadingView from '~/components/common/organisms/OverlayLoadingView'
import RegistrationCompanyTemplate from '~/components/common/templates/RegistrationCompanyTemplate'
import RegistrationTemplate from '~/components/common/templates/RegistrationTemplate'
import { RootState } from '~/rootReducer'
import { setPageTitle } from '~/utils/navigation'

const Registration: React.FC = () => {
  const { t } = useTranslation()
  const { isFetchingUser, current } = useSelector((state: RootState) =>
    pick(state.users, ['isFetchingUser', 'current'])
  )
  useFocusEffect(() => setPageTitle(t('profile.setProfileTitle')))

  return isFetchingUser ? (
    <OverlayLoadingView />
  ) : (
    <DefaultLayout>
      {current?.isCompanyAccount ? (
        <RegistrationCompanyTemplate />
      ) : (
        <RegistrationTemplate />
      )}
    </DefaultLayout>
  )
}

export default Registration
