import { CONSTANTS } from 'link-preview-js/build/constants'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Input from '~/components/common/organisms/PostInputArea/Input'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Media from '~/interfaces/Media'

export type PostAreaData = {
  media?: Media[]
  postText?: string
}

type Props = {
  submit: () => void
  isLoading?: boolean
  data?: PostAreaData
  submitButtonTitle?: string
  mediaPicker?: React.ReactNode
  onChanged?: (data: Partial<PostAreaData>) => void
  onChangePreview?: (isShow: boolean) => void
  isShowPreviewPost?: boolean
}

const PostInputArea: React.FC<Props> = ({
  data,
  submit,
  isLoading,
  onChanged,
  mediaPicker,
  submitButtonTitle,
  onChangePreview,
  isShowPreviewPost,
}: Props) => {
  const [detectedURL, setDetectedURL] = useState('')
  const changePostText = (value: string): void => {
    const urlValid = value
      ? value
          .replace(/\n/g, ' ')
          .split(' ')
          .reverse()
          .find((token) => CONSTANTS.REGEX_VALID_URL.test(token))
      : ''
    !isEmpty(urlValid) ? setDetectedURL(urlValid!) : setDetectedURL('')
    onChanged && onChanged({ postText: value })
  }

  const removeMedia = (media?: Media): void => {
    if (media == null) {
      onChanged && onChanged({ media: [] })
    } else {
      const previousMedia = data?.media ?? []
      !isEmpty(previousMedia) &&
        onChanged &&
        onChanged({
          media: previousMedia.filter((item: Media) => item != media),
        })
    }
  }

  return (
    <View style={styles.container} testID="post-input-area">
      <Input
        media={data?.media ?? []}
        text={data?.postText ?? ''}
        onChangeText={changePostText}
        onRemove={removeMedia}
        detectedURL={detectedURL}
        onChangePreview={onChangePreview}
        isShowPreviewPost={isShowPreviewPost}
      />
      <View style={styles.footer}>
        <View testID="action-area" style={styles.action}>
          {mediaPicker}
        </View>
        <Button
          type="solid"
          onPress={submit}
          title={submitButtonTitle}
          testID="post-input-submit"
          titleStyle={[styles.submitButton, { fontSize: FontSize.GENERAL }]}
          disabled={
            (data?.media?.length === 0 && data?.postText?.trim() === '') ||
            isLoading
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  action: {
    flex: 1,
    flexDirection: 'row',
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    padding: 20,
  },
  footer: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  submitButton: {
    height: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
})

export default PostInputArea
