import { ParamListBase } from '@react-navigation/routers'
import React from 'react'

import Community from '~/components/community/pages/Community'
import CommunityDetail from '~/components/community/pages/CommunityDetail'
import CommunityUser from '~/components/community/pages/CommunityUser'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'

export type Route<RouteName extends keyof ParamListBase> = {
  name: RouteName
  component: React.FC
  labelTranslationKey?: string
}

/**
 * Add router for another pages that is not display in Mobile BottomNavigator
 */
export const otherCommunityRoutes: Route<keyof OtherStackParamList>[] = [
  { name: 'communityDetail', component: CommunityDetail },
  { name: 'userDetail', component: CommunityUser },
  {
    name: 'mypage',
    component: CommunityUser,
    labelTranslationKey: 'menu.mypage',
  },
]

export const mainCommunityRoutes: Route<keyof MainStackParamList>[] = [
  { name: 'community', component: Community, labelTranslationKey: 'menu.home' },
  {
    name: 'communities',
    component: Community,
    labelTranslationKey: 'menu.community',
  },
]
