import React from 'react'
import { ImageSourcePropType, StyleSheet, View } from 'react-native'
import { Avatar } from 'react-native-elements'

import color from '~/constants/common/color'

export enum ProfileAvatarSize {
  GIGANTIC = 125,
  HUGE = 78,
  LARGE = 54,
  MEDIUM = 50,
  SMALL = 45,
  TINY = 36,
  MICRO = 28,
}

type Props = {
  hasBorder?: boolean
  onPress?: () => void
  size: ProfileAvatarSize
  source?: ImageSourcePropType
}

const ProfileAvatar: React.FC<Props> = ({
  size,
  source,
  onPress,
  hasBorder,
}: Props) => {
  const borderWidth = size < ProfileAvatarSize.MEDIUM ? 2 : 4
  const avatarBorder = hasBorder
    ? StyleSheet.flatten([styles.border, { borderWidth }])
    : {}

  return (
    <View testID="profile-avatar">
      <Avatar
        rounded
        size={size}
        source={source}
        onPress={onPress}
        overlayContainerStyle={avatarBorder}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  border: {
    backgroundColor: color.white,
    borderColor: color.white,
  },
})

export default ProfileAvatar
