import CryptoJS from 'crypto-js'
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker'
import { isNil } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import api from '~/api'
import { DirectUploadBody } from '~/api/storage/directUploads'
import ImageKind from '~/interfaces/ImageKind'
import Media from '~/interfaces/Media'
import Attachment from '~/interfaces/uploads/Attachment'
import { uploadImage, uploadVideo, uriToBlob } from '~/uploader'
import blobToBuffer from '~/uploader/blobToBuffer'

export const selectMedia = async (
  mediaTypes?: MediaTypeOptions
): Promise<string | null> => {
  // For iOS, this requires a permission.
  // See: https://docs.expo.io/versions/latest/sdk/imagepicker/#imagepickerrequestcamerarollpermissionsasync
  const result = await launchImageLibraryAsync({
    mediaTypes: mediaTypes || MediaTypeOptions.All,
    allowsEditing: true,
    quality: 1,
  })

  if (result.cancelled) {
    return null
  }

  return result.uri
}

export const selectMultipleMedia = async (
  mediaTypes?: MediaTypeOptions
): Promise<string[]> => {
  // For iOS, this requires a permission.
  // See: https://docs.expo.io/versions/latest/sdk/imagepicker/#imagepickerrequestcamerarollpermissionsasync
  const result = await launchImageLibraryAsync({
    mediaTypes: mediaTypes || MediaTypeOptions.All,
    allowsEditing: true,
    allowsMultipleSelection: true,
    quality: 1,
  })

  if (result.cancelled) {
    return []
  }
  return result.selected.map((file) => file.uri)
}

export const upload = async (uri: string, kind: ImageKind): Promise<Media> => {
  const blob = await uriToBlob(uri)

  switch (blob.type.split('/', 2)[0]) {
    case 'image':
      return uploadImage(blob, uuidv4(), kind, uri)
    case 'video':
      return uploadVideo(blob, uri)
    default:
      throw new Error(`Unexpected content type: ${blob.type}`)
  }
}

export const uploadMultiple = (
  uris: string[],
  kind: ImageKind
): Promise<Media[]> =>
  uris.length > 0
    ? Promise.all(uris.map((uri) => upload(uri, kind)))
    : Promise.resolve([])

export const uploadToS3 = async (
  attachment: Attachment,
  blob: Blob
): Promise<{}> => {
  const directUpload = attachment.directUpload
  if (!directUpload) {
    return Promise.reject()
  }

  const headers = {
    'Content-Type': directUpload.headers.contentType,
    'Content-MD5': directUpload.headers.contentMd5,
    'Content-Disposition': directUpload.headers.contentDisposition,
  }

  return await fetch(directUpload.url, {
    method: 'PUT',
    headers,
    body: blob,
  })
}

const getChecksum = (buffer: Buffer) =>
  // FIXME The type mismatch with buffer and number[] though
  // It can't get correct value without passing plain buffer
  CryptoJS.MD5(CryptoJS.lib.WordArray.create(buffer)).toString(
    CryptoJS.enc.Base64
  )

export const uploadMedia = async (uri: string): Promise<Media> => {
  const blob = await uriToBlob(uri)

  try {
    const response = await api.directUploads.create<DirectUploadBody, Media>({
      byteSize: blob.size,
      filename: `${uuidv4()}_${blob.type.replace('/', '.')}`,
      checksum: getChecksum(await blobToBuffer(blob)),
      contentType: blob.type,
    })

    await uploadToS3(response, blob)
    return Promise.resolve({ ...response, uri })
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

export const uploadMultipleMedia = (uris: string[]): Promise<Media[]> =>
  uris.length > 0
    ? Promise.all(uris.map((uri) => uploadMedia(uri)))
    : Promise.resolve([])

export const filterImageIds = (original: Media[], target?: Media): number[] =>
  isNil(target)
    ? original.map((item) => item.id)
    : original.filter((item) => item.id === target.id).map((item) => item.id)
