import React from 'react'
import { StyleSheet, View } from 'react-native'

import TextField from './TextField'

import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  title?: string
  value?: string
  isEditing?: boolean
  onChange: (value: string) => void
}

const TextForm: React.FC<Props> = ({
  title,
  value,
  onChange,
  isEditing,
}: Props) => (
  <View testID="text-form">
    {title && (
      <TitleText testID="text-form-title" style={styles.title}>
        {title}
      </TitleText>
    )}
    {isEditing ? (
      <View testID="text-form-editing">
        <TextField value={value} onChangeText={onChange} numberOfLines={2} />
      </View>
    ) : (
      <View testID="text-form-view">
        <Text>{value}</Text>
      </View>
    )}
  </View>
)

const styles = StyleSheet.create({
  title: {
    color: color.darkTitleText,
    marginTop: 10,
  },
})

export default TextForm
