import { FontAwesome as Icon } from '@expo/vector-icons'
import { first, isEmpty, isNil, orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import SelectableList, {
  SelectableListData,
} from '~/components/common/features/SelectableList'
import ChannelEditDialog from '~/components/community/features/channels/ChannelEditDialog'
import ChannelFilter from '~/components/community/molecules/ChannelFilter'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import CommunityChannel from '~/interfaces/CommunityChannel'
import { RootState } from '~/rootReducer'
import {
  channelSelector,
  communitySelector,
  fetchChannels,
  isCommunityAdmin,
  setCurrentChannel,
} from '~/slices/community/community'

const ChannelList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const [data, setData] = useState<SelectableListData[]>([
    { title: '', data: [] },
  ])
  const [visibleModal, setVisibleModal] = useState(false)
  const currentUserId = useSelector(
    (state: RootState) => state.users.current?.id
  )
  const community = useSelector(communitySelector)
  const channels = community?.channels ?? []
  const currentChannel = useSelector(channelSelector)
  const isAdmin = isCommunityAdmin(currentUserId, community?.communityMembers)

  const prefixChannel = (
    channel: CommunityChannel,
    selectedId?: number
  ): React.ReactNode => {
    const isSelected = selectedId === channel.id
    const prefixChannelColor = isSelected ? color.white : color.textGray

    return (
      !channel.isPublic && (
        <Icon
          size={12}
          name="lock"
          color={prefixChannelColor}
          style={styles.privateIcon}
        />
      )
    )
  }

  useEffect(() => {
    community && dispatch(fetchChannels(community?.id))
  }, [community?.id])

  useEffect(() => {
    isNil(currentChannel) && !isEmpty(channels) && select(first(channels)!.id)
    setData([
      {
        title: t('community.channel.channels'),
        data: orderBy(channels, ['isPublic'], ['desc']).map((item) => ({
          id: item.id,
          icon: prefixChannel(item, currentChannel?.id),
          isPublic: item.isPublic,
          name: item.name,
        })),
      },
    ])
  }, [
    currentChannel?.id,
    channels.length,
    currentChannel?.name,
    currentChannel?.isPublic,
  ])

  const select = (id: number | string): void => {
    if (currentChannel?.id === id) return
    const channel = channels.find((channel) => channel.id == id)
    channel && dispatch(setCurrentChannel(channel))
  }

  const showChannelEditForm = (): void => {
    setVisibleModal(!visibleModal)
  }

  return isPC ? (
    <CardContainer style={styles.container} testID="channel-list">
      {community?.id && (
        <ChannelEditDialog
          isEdit={false}
          visibleModal={visibleModal}
          onDismiss={showChannelEditForm}
        />
      )}
      <SelectableList
        data={data}
        onSelect={select}
        selectedId={currentChannel?.id || 0}
        headerRightIcon={
          isAdmin && (
            <View testID="plus-icon-container">
              <Icon
                size={20}
                name="plus"
                testID="plus-icon"
                color={color.white}
                onPress={showChannelEditForm}
              />
            </View>
          )
        }
      />
    </CardContainer>
  ) : (
    <ChannelFilter
      onChange={select}
      channels={channels}
      currentChannel={currentChannel}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    backgroundColor: color.white,
    marginRight: 10,
    overflow: 'hidden',
    width: 200,
  },
  privateIcon: {
    padding: 4,
  },
  publicIcon: {
    fontWeight: 'bold',
    marginRight: 4,
  },
})

export default ChannelList
