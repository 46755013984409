import React from 'react'

import ResetPasswordColumn from '~/components/common/features/auth/ResetPasswordColumn'
import AuthContainerTemplate from '~/components/common/templates/auth/AuthContainerTemplate'

const ResetPasswordTemplate: React.FC = () => (
  <AuthContainerTemplate>
    <ResetPasswordColumn />
  </AuthContainerTemplate>
)

export default ResetPasswordTemplate
