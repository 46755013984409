import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native'

import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import AppService from '~/interfaces/AppService'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isPC: boolean
  item: AppService
  selected?: boolean
  isSelectable?: boolean
}

export const ITEM_WIDTH = 258
const IMG_PC = {
  width: ITEM_WIDTH,
  height: 162,
}

const IMG_MOBILE = {
  height: 95,
  width: 330,
}

const SELECTABLE_WIDTH = 260

const ServiceItem: React.FC<Props> = ({
  item,
  isPC,
  selected,
  isSelectable,
}: Props) => {
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const navigateToService = (): void => {
    navigate(item.screen)
  }

  return (
    <TouchableOpacity onPress={navigateToService} disabled={isSelectable}>
      {isPC ? (
        <View
          testID="service-item"
          style={StyleSheet.flatten([styles.container, styles.containerPC])}
        >
          <Image style={IMG_PC} source={item.image} />
          <View style={styles.body}>
            <Image
              source={item.logo}
              resizeMode="center"
              testID="service-logo-img"
              style={styles.logo}
            />
            <Text style={styles.description}>{item.description}</Text>
          </View>
        </View>
      ) : (
        <View
          testID="service-item"
          style={StyleSheet.flatten([styles.container, styles.containerMobile])}
        >
          <View style={styles.imageContainer}>
            <Image
              style={
                isSelectable
                  ? {
                      height: IMG_MOBILE.height,
                      width: SELECTABLE_WIDTH,
                    }
                  : IMG_MOBILE
              }
              source={item.image}
            />
          </View>
          <View style={styles.body}>
            {isSelectable ? (
              <Button
                title={item.title}
                disabled={selected}
                testID="service-button"
                titleStyle={styles.title}
                onPress={navigateToService}
                containerStyle={styles.serviceButtonMobile}
              />
            ) : (
              <Image
                source={item.logo}
                style={styles.logo}
                resizeMode="center"
                testID="service-logo-img"
              />
            )}
            <Text
              style={StyleSheet.flatten([
                styles.description,
                isSelectable
                  ? styles.descriptionSelectable
                  : styles.descriptionMobile,
              ])}
            >
              {item.description}
            </Text>
          </View>
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    paddingBottom: 6,
    paddingHorizontal: 32,
    paddingTop: 15,
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 10,
    margin: 10,
    overflow: 'hidden',
    ...commonStyles.shadow,
  },
  containerMobile: {
    borderWidth: 1,
    marginVertical: 4,
    borderColor: color.secondLightGray,
  },
  containerPC: {
    flex: 1,
    margin: 10,
    width: ITEM_WIDTH,
  },
  description: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: FontSize.REMARKABLE,
    color: color.textGray,
  },
  descriptionMobile: {
    marginBottom: 16,
  },
  descriptionSelectable: {
    fontSize: FontSize.SUB,
    marginBottom: 10,
  },
  imageContainer: {
    width: '100%',
    backgroundColor: color.serviceImageBackground,
    alignItems: 'center',
  },
  logo: {
    height: 45,
    width: '100%',
  },
  serviceButtonMobile: {
    width: '100%',
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    // fontWeight: 'bold',
  },
})

export default ServiceItem
