import { find } from 'lodash'
import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import EventContainerTemplate from '~/components/common/templates/EventContainerTemplate'
import AdminDashboard from '~/components/community/features/AdminDashboard'
import CommunityOverview from '~/components/community/features/CommunityOverview'
import CommunityTalk from '~/components/community/features/CommunityTalk'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import { CommunitySection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import { ProfileSectionParam } from '~/interfaces/RootStackParamList'
import { currentUserSelector } from '~/slices/common/users'
import {
  channelSelector,
  communitySelector,
  fetchChannelDetail,
} from '~/slices/community/community'
import { getRouteParams, parseCommunitySection } from '~/utils/navigation'

const CommunityDetailTemplate: React.FC = () => {
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const section = parseCommunitySection(
    getRouteParams<ProfileSectionParam>().section
  )
  const currentUser = useSelector(currentUserSelector)
  const community = useSelector(communitySelector)
  const currentChannel = useSelector(channelSelector)
  const isAdmin =
    community?.communityMembers &&
    !!find(community.communityMembers, {
      isAdmin: true,
      userId: currentUser?.id,
    })
  const isMember = !!find(community?.communityMembers, {
    userId: currentUser?.id,
  })

  const loadChannelDetail = async (): Promise<void> => {
    if (community && currentChannel) {
      await Promise.all([
        dispatch(fetchChannelDetail(community.id, currentChannel.id)),
      ])
    }
  }

  useEffect(() => {
    if (community && currentChannel) {
      // Only members who can fetch channel detail success
      isMember && loadChannelDetail()
    }
  }, [currentChannel?.id])

  const renderSectionComponent = (): React.ReactNode => {
    switch (section) {
      case CommunitySection.TALK:
        return <CommunityTalk />
      case CommunitySection.EVENTS:
        return (
          community && <EventContainerTemplate communityId={community?.id} />
        )
      case CommunitySection.INFO:
        return <CommunityOverview isAdmin={isAdmin} />
      case CommunitySection.ADMIN:
        return <AdminDashboard />
      case CommunitySection.MEMBERS:
        return <AdminDashboard />
    }
  }

  return (
    <View testID="community-detail-template" style={styles.container}>
      {isPC ? (
        <InfiniteScrollContainer>
          <HeaderCommunityDetail />
          <View style={styles.bodyPC} testID="section-container">
            <View style={styles.section}>{renderSectionComponent()}</View>
          </View>
        </InfiniteScrollContainer>
      ) : (
        <View style={styles.bodyMobile} testID="section-container">
          <View style={styles.section}>{renderSectionComponent()}</View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  bodyMobile: {
    flex: 1,
  },
  bodyPC: {
    alignSelf: 'center',
    flexDirection: 'row',
    width: 814,
  },
  container: {
    flex: 1,
  },
  section: {
    flex: 1,
  },
})

export default CommunityDetailTemplate
