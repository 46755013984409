import React from 'react'
import { ListRenderItem, StyleSheet, View } from 'react-native'

import CompanyUserItem from '~/components/career/molecules/company/CompanyUserItem'
import FlatList from '~/components/workarounds/FlatList'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'
import CompanyUser from '~/interfaces/CompanyUser'
import User from '~/interfaces/User'

type Props = {
  title: string
  companyUser?: CompanyUser[]
  user?: User
  isPC: boolean
}

const CompanyProfileMemberList: React.FC<Props> = ({
  title,
  companyUser,
  user,
  isPC,
}: Props) => {
  const keyExtractor = (item: CompanyUser, index: number): string =>
    `${item.id}-${index}`

  const renderItem: ListRenderItem<CompanyUser> = ({
    item,
  }: {
    item: CompanyUser
  }): JSX.Element => (
    <View style={styles.itemContainer}>
      <CompanyUserItem
        isPC={isPC}
        user={item.user}
        isMe={user?.id === item.id}
        currentUser={user}
      />
    </View>
  )

  return (
    <View testID="company-profile-member-list">
      <FlatList
        data={companyUser}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        testID="member-filter-list"
        ListHeaderComponent={<Text style={styles.title}>{title}</Text>}
        numColumns={2}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default CompanyProfileMemberList
