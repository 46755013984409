import { Fontisto as Icon } from '@expo/vector-icons'
import React from 'react'
import { Linking, StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
}

const EventLocation: React.FC<Props> = ({ event }: Props) => (
  <View testID="event-location" style={styles.container}>
    <Icon name="map-marker-alt" size={14} color={color.textGray} />
    <Text
      testID="event-location-text"
      style={styles.text}
      onPress={() => event.isVirtual && Linking.openURL(event.location)}
    >
      {event.location}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    color: color.textGray,
    marginLeft: 10,
  },
})

export default EventLocation
