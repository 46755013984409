import api from '~/api'
import type Image from '~/interfaces/Image'
import type ImageKind from '~/interfaces/ImageKind'
import type Policy from '~/interfaces/Policy'
import Uploader from '~/uploader/Uploader'

class ImageUploader extends Uploader<Image> {
  name: string
  kind: ImageKind

  constructor(blob: Blob, name: string, kind: ImageKind, uri?: string) {
    super(blob, uri)
    this.name = name
    this.kind = kind
  }

  async createPolicy(): Promise<Policy<Image>> {
    return api.upload.createImagePolicy(this.blob, this.name, this.kind)
  }
}
export default ImageUploader
