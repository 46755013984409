import { BaseAPI } from '~/api/baseAPI'

export type UnreadCount = {
  unreadMessagesCount: number
  unreadNotificationsCount: number
  total: number
}

type IdType = {
  id: number
}

export class NotificationAPI extends BaseAPI {
  path = '/notifications'

  async check(data: IdType): Promise<void> {
    return await this.client.patch<void>(`/notifications/${data.id}/check`, {})
  }

  async checkAll(): Promise<void> {
    return await this.client.patch<void>('/notifications/check_all', {})
  }

  async getUnreadCount(): Promise<UnreadCount> {
    return await this.client.get<UnreadCount>('/notifications/unread_count')
  }
}
