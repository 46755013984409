import { useFocusEffect } from '@react-navigation/native'
import { debounce, find } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'

import { API } from '~/api'
import { UsersParams } from '~/api/users'
import SearchInput from '~/components/common/atoms/SearchInput'
import TitleText from '~/components/common/atoms/TitleText'
import UserItem from '~/components/common/molecules/UserItem'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useAPI from '~/hooks/useAPI'
import User from '~/interfaces/User'

type Props = {
  onChange?: (users: User[]) => void
  selectedUsers?: User[]
  title?: string
  renderRightButton?: (user: User) => React.ReactElement
}

const UsersFilter: React.FC<Props> = ({
  title,
  onChange,
  renderRightButton,
  selectedUsers = [],
}: Props) => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('')
  const [users, setUsers] = useState<User[]>([])

  const fetchUsers = useAPI(
    async (api: API, name: string): Promise<void> => {
      try {
        const response = await api.users.index<User[], UsersParams>({
          name,
        })
        setUsers(response)
      } catch (err) {
        // Show error
      }
    },
    [setUsers]
  )

  const fetchUsersWithDelay = useCallback(
    debounce((keyword) => {
      fetchUsers(keyword)
    }, 500),
    [fetchUsers]
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchUsersWithDelay(keyword)
    }, [keyword, fetchUsersWithDelay])
  )

  const selectUser = (user: User): void => {
    onChange && onChange([...selectedUsers, user])
  }

  const unselectUser = (user: User): void => {
    onChange && onChange(selectedUsers.filter((item) => item.id !== user.id))
  }

  const renderItem: ListRenderItem<User> = ({ item }: { item: User }) => {
    const isSelected = find(selectedUsers, { id: item.id }) !== undefined

    return (
      <View style={styles.itemContainer} testID="users-filter-item">
        <UserItem user={item} />
        {renderRightButton ? (
          renderRightButton(item)
        ) : (
          <Button
            onPress={(): void =>
              isSelected ? unselectUser(item) : selectUser(item)
            }
            style={styles.selectButton}
            testID="users-filter-button"
            title={isSelected ? t('unselect') : t('select')}
            buttonStyle={isSelected ? styles.userSelected : undefined}
            titleStyle={
              isSelected ? styles.buttonSelectedTitle : styles.buttonTitle
            }
          />
        )}
      </View>
    )
  }

  const keyExtractor = (item: User): string => item.id.toString()

  return (
    <View testID="users-filter" style={styles.container}>
      {title && (
        <TitleText style={styles.title} testID="users-filter-title">
          {title}
        </TitleText>
      )}
      <SearchInput
        value={keyword}
        onChangeText={setKeyword}
        placeholderKey={t('search.searchKeywords')}
      />
      <FlatList
        data={users}
        listKey="users-filter"
        renderItem={renderItem}
        style={styles.userList}
        keyExtractor={keyExtractor}
        testID="users-filter-list"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonSelectedTitle: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  buttonTitle: {
    fontSize: FontSize.GENERAL,
  },
  container: {
    width: '100%',
    flex: 1,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  selectButton: {
    width: 80,
  },
  title: {
    marginBottom: 15,
  },
  userList: {
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
  userSelected: {
    backgroundColor: color.lightGray,
  },
})
export default UsersFilter
