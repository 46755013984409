import { find, isEmpty, isNil } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { ListItem } from 'react-native-elements'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import MyQuizButton from '~/components/career/atoms/quiz/MyQuizButton'
import QuizChoicesList from '~/components/career/molecules/quizzes/QuizChoisesList'
import QuizResultChoicesList from '~/components/career/molecules/quizzes/QuizResultChoisesList'
import DailyQuizItem from '~/components/career/organisms/quizzes/DailyQuizItem'
import CardContainer from '~/components/common/atoms/CardContainer'
import SkeletonView from '~/components/common/atoms/SkeletonView'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import Quiz from '~/interfaces/Quiz'
import QuizResult from '~/interfaces/QuizResult'
import { RootState } from '~/rootReducer'

type Props = {
  shouldHideMyQuiz?: boolean
  emptyView?: React.ReactElement
  onPressDismissButton?: () => void
}

const DailyQuiz: FC<Props> = ({
  emptyView,
  shouldHideMyQuiz,
  onPressDismissButton,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useDevice()

  const [quizzes, setQuizzes] = useState<Quiz[]>([])
  const [quizResults, setQuizResults] = useState<QuizResult[]>([])
  const currentUser = useSelector((state: RootState) => state.users.current)
  const [isLoading, setIsLoading] = useState(true) // loading = true when screen open
  const shouldShowSkeleton = isLoading && isEmpty(quizzes)

  const [expanded, setExpanded] = React.useState(true)

  const generateDailyQuizzes = useAPI(async (api: API): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await api.quizzes.generateDailyQuizzes()
      response.splice(1)
      setQuizzes(response)
    } catch (err) {
      //TODO show error
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    generateDailyQuizzes()
  }, [generateDailyQuizzes])

  const submitAnswer = async (
    quizId: number,
    choice: string
  ): Promise<void> => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.quizResults.create(currentUser?.id!, {
        quizId,
        choice,
      })
      setQuizResults((results) => [...results, response])
      api.isAlive && setIsLoading(false)
    } catch (error) {
      api.isAlive && setIsLoading(false)
    }
  }

  const renderItem = ({ item }: { item: Quiz }) => {
    const quizResult = find(quizResults, { quiz: { id: item.id } })
    return (
      <DailyQuizItem
        quiz={item}
        isPC={isPC}
        quizResult={quizResult}
        dailyQuizAnswerList={
          !isNil(quizResult) ? (
            <QuizResultChoicesList
              quizResult={quizResult}
              myQuizButton={
                shouldHideMyQuiz ? undefined : (
                  <MyQuizButton user={currentUser} />
                )
              }
            />
          ) : (
            <QuizChoicesList
              disabled={isLoading}
              isLoading={isLoading}
              choices={item.choices}
              onSelect={(choice): Promise<void> =>
                submitAnswer(item.id, choice)
              }
            />
          )
        }
      />
    )
  }

  const pressQuizAccordion = () => {
    if (onPressDismissButton) {
      onPressDismissButton()
    } else {
      setExpanded(!expanded)
    }
  }

  if (shouldShowSkeleton && isPC) {
    return <SkeletonView style={styles.skeletonView} />
  }

  return !isEmpty(quizzes) ? (
    <CardContainer
      testID="daily-quiz"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <ListItem.Accordion
        testID="daily-quiz-list"
        containerStyle={styles.headerContainer}
        icon={{
          type: 'font-awesome',
          name: shouldHideMyQuiz ? 'close' : 'angle-up',
          color: 'white',
        }}
        content={
          <ListItem.Content testID="daily-quiz-header-title">
            <ListItem.Title style={styles.headerTitle}>
              {t('quiz.dailyQuizHeaderTitle')}
            </ListItem.Title>
          </ListItem.Content>
        }
        isExpanded={expanded}
        onPress={pressQuizAccordion}
        hasTVPreferredFocus={false}
        tvParallaxProperties={undefined}
      >
        {expanded ? (
          quizzes.map((item, i) => (
            <ListItem
              key={i}
              containerStyle={styles.noPadding}
              hasTVPreferredFocus={false}
              tvParallaxProperties={undefined}
            >
              {renderItem({ item })}
            </ListItem>
          ))
        ) : (
          <></>
        )}
      </ListItem.Accordion>
    </CardContainer>
  ) : (
    // Use <></> because component should return View or null, not return undefined
    <>{emptyView}</>
  )
}

export default DailyQuiz

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  containerMobile: {
    marginBottom: 20,
  },
  headerContainer: {
    backgroundColor: color.primary,
  },
  headerTitle: {
    color: color.white,
    fontWeight: 'bold',
  },
  noPadding: {
    padding: 0,
  },
  skeletonView: {
    backgroundColor: color.white,
    height: 300,
  },
})
