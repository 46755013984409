import { HTTPClient } from '~/httpClient'
import Profile from '~/interfaces/Profile'
import User from '~/interfaces/User'

export type UserProfile = {
  userProfile: Partial<Profile>
}

export class UserProfileAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async currentUser(): Promise<User> {
    return await this.client.get<User>('/profile')
  }

  async create(data: UserProfile): Promise<User> {
    return await this.client.post<User>('/profile', data)
  }

  async update(data: UserProfile): Promise<User> {
    return await this.client.patch<User>('/profile', data)
  }
}
