import React from 'react'
import { View, StyleSheet } from 'react-native'

import Follower from '~/components/common/atoms/users/Follower'
import Following from '~/components/common/atoms/users/Following'
import color from '~/constants/common/color'
import User from '~/interfaces/User'

type Props = {
  user?: User
  isPC?: boolean
  type?: 'user-item' | 'header'
}

const ProfileFollow: React.FC<Props> = ({ user, isPC, type }: Props) => (
  <View style={styles.container} testID="profile-follow">
    <Follower
      isPC={isPC}
      type={type}
      followersCount={user?.followersCount || 0}
      user={user}
    />
    {isPC ? (
      <View
        style={
          type === 'user-item'
            ? StyleSheet.flatten([
                styles.separator,
                { backgroundColor: color.textGray },
              ])
            : StyleSheet.flatten([
                styles.separator,
                { backgroundColor: color.white },
              ])
        }
        testID="vertical-separator"
      />
    ) : (
      <View testID="transparent-separator" style={styles.separator} />
    )}
    <Following
      isPC={isPC}
      type={type}
      followingsCount={user?.followingsCount || 0}
      user={user}
    />
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  separator: {
    marginBottom: 2,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2,
    width: 1,
  },
})

export default ProfileFollow
