import { BaseAPI } from '~/api/baseAPI'
import Quiz from '~/interfaces/Quiz'

export type QuizQueryParams = {
  categories?: string[]
  type?: string
  userId?: number
  isCorrect?: boolean
}

export class QuizzesAPI extends BaseAPI {
  path = '/quizzes'

  async generateDailyQuizzes(): Promise<Quiz[]> {
    return this.client.post<Quiz[]>(`${this.path}/daily`, {})
  }
}
