import React from 'react'
import { View } from 'react-native'

import EventName from '~/components/community/atoms/EventName'
import EventTime from '~/components/community/atoms/EventTime'
import EventLocation from '~/components/community/molecules/EventLocation'
import Text from '~/components/workarounds/Text'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  isPC: boolean
  event: CommunityEvent
  onPressEventName?: () => void
}

const EventItemInfo: React.FC<Props> = ({
  isPC,
  event,
  onPressEventName,
}: Props) => {
  const infoContainerStyle = isPC
    ? stylesObj.infoContainer
    : stylesObj.infoContainerMobile

  const rowFirstStyle = isPC ? stylesObj.rowFirst : stylesObj.rowFirstMobile
  return (
    <>
      <View style={infoContainerStyle} testID="event-item-info">
        <View style={rowFirstStyle}>
          <EventName event={event} onPress={onPressEventName} />
          <EventTime event={event} />
          <Text>{`${event.description.slice(0, 120)}...`}</Text>
          <View style={stylesObj.separator} />
          {event.location &&
            ((event.isJoined && event.isVirtual) || !event.isVirtual) && (
              <EventLocation event={event} />
            )}
        </View>
      </View>
    </>
  )
}

const stylesObj = {
  infoContainer: {
    flex: 1,
    paddingLeft: 40,
    paddingRight: 10,
    paddingVertical: 20,
  },
  infoContainerMobile: {
    paddingBottom: 10,
    paddingHorizontal: 20,
  },
  rowFirst: {
    flex: 1,
  },
  rowFirstMobile: {
    paddingTop: 10,
  },
  separator: {
    padding: 4,
  },
}

export default EventItemInfo
