import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import Answer from '~/interfaces/Answer'
import { RootState } from '~/rootReducer'

export type AnswerData = { id: number } & Partial<Answer>

export type AnswerState = {
  current: AnswerData
}

const answersSlice = createSlice({
  name: 'answers',
  initialState: {} as AnswerState,
  reducers: {
    setAnswer(state: AnswerState, action: PayloadAction<AnswerData>): void {
      state.current = action.payload
    },
  },
})

export const { setAnswer } = answersSlice.actions

export const answersSelector = (state: RootState): AnswerData | undefined =>
  state.answers.current

export default answersSlice.reducer
