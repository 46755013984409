import { BaseAPI } from '~/api/baseAPI'

export type MemberInvitationParams = {
  userId: number
  communityId: number
  isAdmin: boolean
}

export class CommunityMemberInvitationsAPI extends BaseAPI {
  path = '/community_member_invitations'
}
