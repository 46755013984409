import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { range } from 'lodash'
import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import CustomPicker, {
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export const months = range(0, 13).map((_, index) => {
  return index === 0
    ? pickerValue('', index)
    : pickerValue(index.toString(), index)
})

type Props = {
  onChange: (value: number) => void
  selectedValue?: number
  title?: string
}

const MonthPicker: React.FC<Props> = ({
  selectedValue,
  onChange,
  title,
}: Props) => {
  const [year, setYear] = useState(selectedValue)

  const handleChangeYear = (value: ItemValue): void => {
    setYear(value as number)
    onChange(value as number)
  }

  return (
    <View testID="month-picker" style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View testID="picker-container">
        <CustomPicker
          data={months}
          size="full"
          selectedValue={year}
          onValueChange={handleChangeYear}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default MonthPicker
