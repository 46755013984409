import { useNavigation } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import EditResumeMainFirst from '~/components/career/features/resume/EditResumeMainFirst'
import EditResumeMainSecond from '~/components/career/features/resume/EditResumeMainSecond'
import EditResumeFooter from '~/components/career/molecules/resume/EditResumeFooter'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import {
  currentEditResumeIndexSelector,
  currentResumeSelector,
  EDIT_RESUME_END_FORM_INDEX,
  EDIT_RESUME_START_FORM_INDEX,
  nextEditResumeIndex,
  previousEditResumeIndex,
  updateResume,
} from '~/slices/career/resumes'
import { getResumeValidationError } from '~/utils/career/resume'
import { back, RootStackNavigationProp } from '~/utils/navigation'

const EditResumeMain: React.FC = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const { isPC } = useDevice()
  const resume = useSelector(currentResumeSelector)
  const currentEditResumeIndex = useSelector(currentEditResumeIndexSelector)
  const containerStyle = isPC ? styles.containerPC : styles.containerMobile

  const nextPage = (): void => {
    dispatch(nextEditResumeIndex())
  }

  const prevPage = (): void => {
    dispatch(updateResume(resume))
    dispatch(previousEditResumeIndex())
  }

  const save = async (): Promise<void> => {
    const resumeValidationError = getResumeValidationError(
      currentEditResumeIndex,
      resume,
      t
    )
    if (!isEmpty(resumeValidationError)) {
      toast.showError(resumeValidationError)
      return
    }
    await Promise.all([dispatch(updateResume(resume))])
    if (currentEditResumeIndex === EDIT_RESUME_END_FORM_INDEX) {
      navigate('resumeIndex', {})
    } else {
      nextPage()
    }
  }

  const editResumeForms: React.ReactNode[] = [
    <EditResumeMainFirst key={0} />,
    <EditResumeMainSecond key={1} />,
  ]

  return (
    <View style={containerStyle} testID="edit-resume-main">
      {editResumeForms[currentEditResumeIndex]}
      <EditResumeFooter
        onCancel={(): void =>
          currentEditResumeIndex === EDIT_RESUME_START_FORM_INDEX
            ? back()
            : prevPage()
        }
        onSave={save}
        indexOfForm={currentEditResumeIndex}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerMobile: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 30,
    flex: 1,
  },
  containerPC: {
    alignSelf: 'center',
    paddingBottom: 30,
    width: 814,
  },
})

export default EditResumeMain
