import { useNavigation } from '@react-navigation/native'
import React, { useContext } from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'

import logo from '~/constants/common/logo'
import { RootContext } from '~/contexts/RootContext'
import { RootStackNavigationProp } from '~/utils/navigation'

const ForumHeaderLeft: React.FC = () => {
  const { isPC } = useContext(RootContext)
  const logoStyle = isPC ? styles.logo : styles.logoMobile
  const { navigate } = useNavigation<RootStackNavigationProp>()

  return (
    <View style={styles.container} testID="forum-header-left">
      <TouchableOpacity onPress={() => navigate('forum')}>
        <Image source={logo.forum.path} style={logoStyle} testID="logo-img" />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  logo: {
    height: 45,
    marginLeft: 50,
    marginRight: 30,
    width: 170,
  },
  logoMobile: {
    height: 45,
    marginLeft: 20,
    marginRight: 30,
    width: 170,
  },
})

export default ForumHeaderLeft
