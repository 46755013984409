import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import CommentCount from '~/components/common/molecules/socials/CommentCount'
import Post from '~/interfaces/Post'
import { RootState } from '~/rootReducer'

type Props = {
  post: Post
}

const PostCommentCount: React.FC<Props> = ({ post }: Props) => {
  const [commentCount, setCommentCount] = useState(post?.comments?.length)
  const comments = useSelector((state: RootState) =>
    state.posts?.current?.id === post.id
      ? state.posts?.current?.comments
      : undefined
  )

  useEffect(() => {
    comments && setCommentCount(comments.length)
  }, [comments])

  return commentCount > 0 ? (
    <View testID="post-comment-count">
      <CommentCount value={commentCount} type="comment" />
    </View>
  ) : (
    <></>
  )
}

export default PostCommentCount
