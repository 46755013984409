import React from 'react'
import { View } from 'react-native'

import Text from '~/components/workarounds/Text'

const PinnedPostFeed: React.FC = () => (
  <View testID="pinned-post-feed">
    <Text>PinnedPostFeed</Text>
  </View>
)

export default PinnedPostFeed
