import React from 'react'
import { StyleSheet, View } from 'react-native'

import PostMessage from '~/components/career/molecules/post/PostMessage'
import PostSharedContentBody from '~/components/career/molecules/post/PostSharedContentBody'
import SharedContentHeader from '~/components/career/molecules/post/SharedContentHeader'
import color from '~/constants/common/color'
import Post from '~/interfaces/Post'

type Props = {
  post: Post
  controlMenu?: React.ReactElement
}

const PostSharedContent: React.FC<Props> = ({ post, controlMenu }: Props) => (
  <View testID="post-shared-content" style={styles.container}>
    <View style={styles.headerContainer}>
      <SharedContentHeader post={post} />
      {controlMenu}
    </View>
    <PostMessage message={post?.message ?? ''} isComment={false} />
    <PostSharedContentBody share={post.share!} />
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingBottom: 20,
  },
  headerContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default PostSharedContent
