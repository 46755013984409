import { PathConfigMap } from '@react-navigation/native'

import { CompanySection, JobSection } from '~/constants/common/navigation'
import { CareerStackParamList } from '~/interfaces/RootStackParamList'

export const parseCompanySection = (value?: string): CompanySection => {
  return Object.values(CompanySection).includes(value as CompanySection)
    ? (value as CompanySection)
    : CompanySection.PROFILES
}

export const parseJobSection = (value?: string): JobSection => {
  return Object.values(JobSection).includes(value as JobSection)
    ? (value as JobSection)
    : JobSection.FEED
}

export const careerConfigPath: PathConfigMap<CareerStackParamList> = {
  career: '/',
  subscriptionPlan: 'subscriptions',
  jobs: {
    path: 'jobs',
  },
  messages: 'messages',
  jobsDashboard: 'jobs-dashboard',
}

export const otherCareerConfigPath = {
  companyPanel: {
    path: 'companies/company-panel',
  },
  messages: {
    path: 'messages/:id?',
    parse: {
      id: Number,
    },
  },
  resumeNew: 'resumes/new',
  resumeIndex: 'resumes',
  resumeEdit: {
    path: 'resumes/:id/edit',
    parse: {
      id: Number,
    },
  },
  postDetails: {
    path: 'posts/:id',
    parse: {
      id: Number,
    },
  },
  jobShow: {
    path: 'jobs/:id',
    parse: {
      id: Number,
    },
  },
  companyShow: {
    path: 'companies/:id/:section?',
    parse: {
      id: Number,
    },
  },
  companyJobCreate: {
    path: 'companies/:companyId/jobs/new',
    parse: {
      companyId: Number,
    },
  },
  companyJobEdit: {
    path: 'companies/:companyId/jobs/:id/edit',
    parse: {
      id: Number,
      companyId: Number,
    },
  },
}
