import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import CardContainer from '~/components/common/atoms/CardContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import UserResultItem from '~/components/common/molecules/users/UserResultItem'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import User from '~/interfaces/User'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  type: 'followers' | 'following'
  user: User
}

const FollowersList: React.FC<Props> = ({ type, user }: Props) => {
  const toast = useCustomToast()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [users, setUsers] = useState<User[]>([])

  const fetchUsers = async (page: number): Promise<void> => {
    if (isLoading) {
      return
    }
    setIsLoading(true)

    try {
      const response =
        type === 'followers'
          ? await api.follow.fetchFollowers(user.id, page)
          : await api.follow.fetchFollowing(user.id, page)
      if (page > currentPage) {
        if (!isEmpty(response)) {
          setUsers((users) => [...users, ...response])
          setCurrentPage(page)
        }
      } else {
        setUsers(response)
      }
      setIsLoading(false)
    } catch (ex) {
      toast.showError(ex)
      setIsLoading(false)
    }
  }

  const fetchMore = (): Promise<void | undefined> => fetchUsers(currentPage + 1)

  useFocusEffect(
    React.useCallback(() => {
      fetchUsers(currentPage)
    }, [type, user.id])
  )

  const renderItem: ListRenderItem<User> = ({
    item,
    index,
  }: {
    item: User
    index: number
  }) => (
    <CardContainer
      testID="follower-container"
      style={[
        styles.cardContainer,
        isPC ? styles.cardContainerPC : undefined,
        isPC && index % 2 === 0 ? styles.rightSpace : undefined,
      ]}
    >
      <UserResultItem
        user={item}
        isPC={isPC}
        isMe={currentUser?.id === item.id}
        currentUser={currentUser}
      />
    </CardContainer>
  )
  const keyExtractor = (item: User): string => item.id.toString()

  return (
    <View style={styles.container} testID="followers-list">
      <InfiniteScrollFlatList
        data={users}
        renderItem={renderItem}
        onReachBottom={fetchMore}
        numColumns={isPC ? 2 : 1}
        keyExtractor={keyExtractor}
        style={styles.followerList}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: color.white,
    flex: 1,
    marginBottom: 14,
    overflow: 'hidden',
  },
  cardContainerPC: {
    maxWidth: 400,
  },
  container: {
    flex: 1,
  },
  followerList: {
    height: '100%',
  },
  rightSpace: {
    marginRight: 14,
  },
})

export default FollowersList
