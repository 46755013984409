import React from 'react'
import { TextStyle } from 'react-native'

import RounedButton from '~/components/common/atoms/RoundedButton'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export type TagData = {
  key: string
  name: string
  isSelected?: boolean
  isHidden?: boolean
}

type Props = {
  onSelected?: (isSelected: boolean) => void
  data: TagData
}

const Tag: React.FC<Props> = ({ onSelected, data }: Props) => {
  const buttonStyle = data.isSelected ? styles.buttonSelected : styles.button
  const containerStyle = data.isHidden
    ? styles.containerHidden
    : styles.container
  const titleStyle = data.isSelected ? styles.titleSelected : styles.title

  const selectTag = (): void => {
    data.isSelected = !data.isSelected
    onSelected && onSelected(data.isSelected)
  }

  return (
    <RounedButton
      disabled={data.isHidden}
      testID="tag"
      title={data.name}
      titleStyle={titleStyle as TextStyle}
      buttonStyle={buttonStyle}
      containerStyle={containerStyle}
      onPress={selectTag}
    />
  )
}

const commonButtonStyles = {
  container: {
    flex: 1,
  },
  button: {
    backgroundColor: color.primary,
    borderWidth: 1,
    borderColor: color.white,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  title: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
}
const styles = {
  container: commonButtonStyles.container,
  containerHidden: {
    ...commonButtonStyles.container,
    opacity: 0,
    zIndex: -1,
    height: 1,
  },
  button: commonButtonStyles.button,
  buttonSelected: {
    ...commonButtonStyles.button,
    backgroundColor: color.white,
    borderColor: color.white,
  },
  title: commonButtonStyles.title,
  titleSelected: {
    ...commonButtonStyles.title,
    color: color.primary,
  },
}

export default Tag
