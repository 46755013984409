import { HTTPClient } from '~/httpClient'
import User from '~/interfaces/User'

export const authFacebookPath = '/auth/users/facebook'

type SignupParams = {
  email: string
  password: string
  confirmSuccessUrl: string
  isCompanyAccount?: boolean
  invitationToken?: string
  displayLang: string
}

type LoginParams = {
  email: string
  password: string
}

type LoginWithFacebookParams = {
  provider: string
  accessToken: string
}

type ResendParams = {
  email: string
}

type ResendResponse = {}

type RequestPasswordResetResponse = {
  message: string
  success: boolean
}

type ResetPasswordRequest = {
  password: string
  resetPasswordToken: string
  passwordConfirmation: string
}

type ResetPasswordResponse = {
  message: string
  success: boolean
  data: User
}

export type ConfirmationParams = {
  confirmationToken: string
}

type ConfirmationRequest = {
  confirmationToken: string
  redirectUrl: string
}

type Errors = {
  fullMessages: string[]
}

type SignupResponse = {
  status: string
  data?: User
  errors?: Errors
}

type LoginResponse = {
  accessToken?: string
  websocketToken?: string
  preparation?: string
  error?: Error
}

export class AuthAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async signUp(params: SignupParams): Promise<SignupResponse> {
    return await this.client.post<SignupResponse>('/auth/users', params)
  }

  async resendConfirmation(params: ResendParams): Promise<ResendResponse> {
    return await this.client.post<ResendResponse>(
      '/auth/users/resend_email',
      params
    )
  }

  async login(params: LoginParams): Promise<LoginResponse> {
    return await this.client.post<LoginResponse>('/auth/password', params)
  }

  async loginWithFacebook(
    params: LoginWithFacebookParams
  ): Promise<LoginResponse> {
    return await this.client.post<LoginResponse>('/auth/facebook', params)
  }

  async confirm(params: ConfirmationRequest): Promise<void> {
    const { confirmationToken, redirectUrl } = params
    return await this.client.put('/auth/users/confirmation', {
      confirmationToken,
      redirectUrl,
    })
  }

  async requestPasswordReset(
    email: string
  ): Promise<RequestPasswordResetResponse> {
    return await this.client.post<RequestPasswordResetResponse>(
      '/auth/users/passwords',
      { email }
    )
  }

  async resetPassword(
    params: ResetPasswordRequest
  ): Promise<ResetPasswordResponse> {
    return await this.client.patch<ResetPasswordResponse>(
      '/auth/users/passwords',
      params
    )
  }
}
