import { find, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Badge from '~/components/common/atoms/Badge'
import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import SkillForm from '~/components/common/molecules/SkillForm'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Skill from '~/interfaces/Skill'

type Props = {
  skills: Partial<Skill>[]
  isMe?: boolean
  isPC?: boolean
  onSubmit: (skills: Partial<Skill>[]) => void
}

const SkillInfo: React.FC<Props> = ({
  skills: currentSkills,
  isMe,
  isPC,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [inputSkillText, setInputSkillText] = useState('')
  const maxSkillLength = 5
  const startEdit = (): void => {
    setEditing(true)
  }

  const isDisableDoneButton: boolean =
    isEmpty(inputSkillText) ||
    currentSkills.length === maxSkillLength ||
    !isEmpty(find(currentSkills, { name: inputSkillText }))

  const submitEdit = (): void => {
    setEditing(false)
    setInputSkillText('')
    if (isDisableDoneButton) return
    const editSkill = { name: inputSkillText }
    if (!find(currentSkills, editSkill)) {
      const newSkills = currentSkills.slice()
      newSkills.push(editSkill)
      onSubmit(newSkills)
    }
  }

  const cancelEdit = (): void => {
    setEditing(false)
    setInputSkillText('')
  }

  const handleChangeSkill = (value: Partial<Skill>[]) => {
    onSubmit(value)
  }

  const handleChangeInputSkill = (value: string) => {
    setInputSkillText(value)
  }

  const renderMainContent = (): React.ReactElement => {
    if (editing) {
      return (
        <SkillForm
          isPC={isPC}
          onSkillInputChange={handleChangeInputSkill}
          onChange={handleChangeSkill}
          skills={currentSkills || []}
          editMode="list"
          maxItemLength={maxSkillLength}
        />
      )
    }
    return (
      <View style={styles.skillList} testID="skill-list">
        {currentSkills.map((item, index) => (
          <Badge key={index} content={item.name} />
        ))}
      </View>
    )
  }

  const renderActionBtn = () => {
    if (!isMe) return null
    if (editing) {
      return (
        <View style={styles.action}>
          {!isEmpty(inputSkillText) && (
            <Button
              type="solid"
              testID="cancel-button"
              title={t('cancel')}
              onPress={cancelEdit}
              buttonStyle={StyleSheet.flatten(styles.cancelButton)}
              titleStyle={StyleSheet.flatten(styles.cancelTitle)}
            />
          )}
          <Button
            testID="done-button"
            type="solid"
            title={t('done')}
            disabled={isDisableDoneButton}
            onPress={submitEdit}
            buttonStyle={StyleSheet.flatten(styles.doneButton)}
            titleStyle={StyleSheet.flatten(styles.doneTitle)}
          />
        </View>
      )
    }
    return (
      <Button
        testID="edit-button"
        type="solid"
        title={t('edit')}
        onPress={startEdit}
        containerStyle={StyleSheet.flatten(styles.editButtonContainer)}
        buttonStyle={StyleSheet.flatten(styles.editButton)}
        titleStyle={StyleSheet.flatten(styles.editTitle)}
      />
    )
  }

  return (
    <CardContainer style={styles.container} testID="skill-info-card-container">
      <TitleText style={styles.title}>{t('profile.skills')}</TitleText>
      <View style={styles.content}>{renderMainContent()}</View>
      {renderActionBtn()}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 25,
  },
  cancelButton: {
    alignSelf: 'flex-end',
    backgroundColor: color.gray,
    height: 30,
    marginRight: 10,
    width: 85,
  },
  cancelTitle: {
    color: color.black,
    fontSize: FontSize.SUB,
  },
  container: {
    backgroundColor: color.white,
    marginTop: 10,
    padding: 20,
  },
  content: {
    flexDirection: 'column',
  },
  doneButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 65,
  },
  doneTitle: {
    fontSize: FontSize.SUB,
  },
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 90,
  },
  editButtonContainer: {
    marginTop: 25,
  },
  editTitle: {
    fontSize: FontSize.GENERAL,
  },
  skillList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  title: {
    color: color.textGray,
    marginVertical: 10,
  },
})

export default SkillInfo
