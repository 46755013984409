import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { AnswerQueryParams } from '~/api/answers'
import OutsiderGuide from '~/components/common/features/auth/OutsiderGuide'
import SocialActivity from '~/components/common/molecules/socials/SocialActivity'
import AnswerList from '~/components/forum/features/answers/AnswerList'
import QuestionControlMenu from '~/components/forum/features/questions/QuestionControlMenu'
import QuestionLikeButton from '~/components/forum/features/questions/QuestionLikeButton'
import QuestionLikeCount from '~/components/forum/features/questions/QuestionLikeCount'
import QuestionSharing from '~/components/forum/features/questions/QuestionSharing'
import QuestionContent from '~/components/forum/organisms/questions/QuestionContent'
// import ForumFrame from '~/components/forum/templates/ForumFrame'
import ForumFrame from '~/components/forum/templates/ForumFrame'
import Text from '~/components/workarounds/Text'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import Question from '~/interfaces/Question'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import {
  RootStackNavigationProp,
  RouteParamsID,
  setPageTitle,
} from '~/utils/navigation'

const QuestionTemplate: React.FC = () => {
  const route = useRoute()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const questionId = (route?.params as RouteParamsID)?.id
  const [error, setError] = useState('')
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const [question, setQuestion] = useState<Question | undefined>(undefined)
  const [answerQueryParams, setAnswerQueryParams] = useState<AnswerQueryParams>(
    { questionId, page: 1 }
  )

  const fetchQuestion = useAPI(
    async (api: API): Promise<void> => {
      if (!questionId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.questions.fetchDetail(questionId)
        setQuestion(response)
      } catch (err) {
        if (api.isAlive) {
          setError(JSON.stringify(err))
        }
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [questionId, setIsLoading]
  )

  useFocusEffect(
    useCallback(() => {
      fetchQuestion()
      setAnswerQueryParams({ ...answerQueryParams, questionId })
    }, [fetchQuestion, questionId])
  )

  useFocusEffect(() => setPageTitle(question?.title))

  const renderAnswerListHeader = () => {
    if (!question) return undefined
    return (
      <QuestionContent
        isPC={isPC}
        question={question}
        socialActivityComponent={
          <SocialActivity
            isHideBottomLine
            likeButton={
              <QuestionLikeButton
                isShowIcon={true}
                question={question}
                isShowCountLabel={false}
              />
            }
            shareButton={<QuestionSharing question={question} />}
            likeCountComponent={<QuestionLikeCount question={question} />}
          />
        }
        questionControlMenu={
          currentUser?.id === question.user.id ? (
            <QuestionControlMenu
              question={question}
              onDelete={(): void => navigate('forum')}
            />
          ) : undefined
        }
      />
    )
  }

  const mainContent = (
    <>
      <Text testID="question-template-error">{error.toString()}</Text>
      {(!question || isLoading) && <View testID="loading-view" />}
      {question && (
        <View testID="question-detail-column" style={styles.container}>
          {questionId && (
            <AnswerList
              questionId={questionId}
              renderListHeader={renderAnswerListHeader}
            />
          )}
        </View>
      )}
    </>
  )

  if (isPC) {
    return (
      <>
        <ForumFrame>{mainContent}</ForumFrame>
        {!accessToken && isPC && <OutsiderGuide />}
      </>
    )
  }

  return (
    <>
      <View style={styles.container}>{mainContent}</View>
      {!accessToken && isPC && <OutsiderGuide />}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default QuestionTemplate
