import { useFocusEffect } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty, orderBy } from 'lodash'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  ListRenderItem,
  StyleSheet,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import { AnswerQueryParams } from '~/api/answers'
import CardContainer from '~/components/common/atoms/CardContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu from '~/components/common/molecules/ControlMenu'
import EmptyView from '~/components/common/molecules/EmptyView'
import SocialActivity from '~/components/common/molecules/socials/SocialActivity'
import AnswerBlueView from '~/components/forum/features/answers/AnswerBlurView'
import AnswerCommentCount from '~/components/forum/features/answers/AnswerCommentCount'
import AnswerCommentList from '~/components/forum/features/answers/AnswerCommentList'
import AnswerForm from '~/components/forum/features/answers/AnswerForm'
import AnswerLikeButton from '~/components/forum/features/answers/AnswerLikeButton'
import AnswerLikeCount from '~/components/forum/features/answers/AnswerLikeCount'
import AnswerItem from '~/components/forum/molecules/answers/AnswerItem'
import AnswerMediaViewerDialog from '~/components/forum/molecules/answers/AnswerMediaViewerDialog'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { AnswerMenuType } from '~/constants/forum/answer'
import { MediaViewerContextProvider } from '~/contexts/MediaViewerContext'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Answer from '~/interfaces/Answer'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { answersSelector } from '~/slices/forum/answers'
import { getAnswerMenuData } from '~/utils/forum/answer'
import { navigationRef } from '~/utils/navigation'

type Props = {
  questionId: number
  renderListHeader?: () => React.ReactNode
}

const AnswerList: React.FC<Props> = ({
  questionId,
  renderListHeader,
}: Props) => {
  const defaultPage = 1
  const toast = useCustomToast()
  const { t } = useTranslation()
  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as AnswerQueryParams

  const [isLoading, setIsLoading] = useState(false)
  const [answers, setAnswers] = useState<Answer[]>([])
  const [isShowEditDialog, setIsShowEditDialog] = useState(false)
  const [currentAnswer, setCurrentAnswer] = useState<Answer | undefined>()
  const [isShowConfirmationDialog, setIsShowConfirmDialog] = useState(false)
  const [queryParams, setQueryParams] = useState<AnswerQueryParams>({
    page: 1,
    questionId,
  })

  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const changedAnswer = useSelector(answersSelector)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const fetchAnswers = useAPI(
    async (api: API, page: number): Promise<void> => {
      setIsLoading(true)
      try {
        let response = await api.answers.fetch({ ...queryParams, page })
        response = orderBy(response, ['id'], 'asc')

        if (queryParams.page && page > queryParams.page) {
          if (!isEmpty(response)) {
            setAnswers((answers) => [...answers, ...response])
            setQueryParams({ ...queryParams, page })
          }
        } else {
          setAnswers(response)
        }
      } catch (error) {
        toast.showError(error)
      }
      setIsLoading(false)
    },
    [queryParams.searchWord, queryParams.questionId, setIsLoading]
  )

  const deleteAnswer = async (): Promise<void> => {
    if (!currentAnswer) {
      return
    }
    try {
      await api.answers.delete(questionId, currentAnswer.id)
      setAnswers(answers.filter((item) => currentAnswer.id !== item.id))
      hideConfirmDialog()
    } catch (error) {
      toast.showError(error)
    }
  }

  const addAnswer = (answer: Answer): void => {
    setAnswers((answers) => [...answers, answer])
  }

  const updateAnswer = (answer: Answer): void => {
    setAnswers((answers) =>
      answers.map((item) =>
        answer.id === item.id ? { ...item, ...answer } : item
      )
    )

    hideEditDialog()
  }

  const hideConfirmDialog = (): void => {
    setIsShowConfirmDialog(false)
    setCurrentAnswer(undefined)
  }

  const hideEditDialog = (): void => {
    setIsShowEditDialog(false)
    setCurrentAnswer(undefined)
  }

  const onSelectAnswerMenu = (
    type: AnswerMenuType,
    answer: Answer,
    cb?: Function
  ): void => {
    switch (type) {
      case AnswerMenuType.DELETE:
        setCurrentAnswer(answer)
        setIsShowConfirmDialog(true)
        cb?.()
        break
      case AnswerMenuType.EDIT:
        setCurrentAnswer(answer)
        setIsShowEditDialog(true)
        cb?.()
        break
    }
  }

  const fetchMoreAnswers = (): void => {
    !isLoading &&
      accessToken &&
      queryParams?.page &&
      answers.length > 0 &&
      fetchAnswers(queryParams.page + 1)
  }

  useFocusEffect(
    React.useCallback(() => {
      questionId &&
        accessToken &&
        fetchAnswers(queryParams?.page || defaultPage)
    }, [accessToken, fetchAnswers])
  )

  useFocusEffect(
    React.useCallback(() => {
      setQueryParams({
        ...queryParams,
        page: routeParams.page
          ? parseInt(routeParams.page.toString())
          : defaultPage,
        searchWord: routeParams.searchWord,
        questionId,
      })
    }, [routeParams.searchWord, routeParams.page, questionId])
  )

  useEffect(() => {
    changedAnswer &&
      setAnswers((answers) =>
        answers.map((item) =>
          item.id === changedAnswer.id
            ? ({
                ...item,
                ...changedAnswer,
              } as Answer)
            : item
        )
      )
  }, [changedAnswer])

  const keyExtractor = (item: Answer): string => item.id.toString()

  const renderAnswerControlMenu = useCallback(
    (item: Answer, cb?: Function) => {
      return currentUser?.id === item?.user?.id ? (
        <ControlMenu
          data={getAnswerMenuData(t)}
          onSelect={(type): void =>
            onSelectAnswerMenu(type.id as AnswerMenuType, item, cb)
          }
        />
      ) : undefined
      // return currentUser?.id === item?.user?.id ? (
      //   <AnswerControlMenu
      //     answer={item}
      //     onDelete={(answer: Answer) => {
      //       cb?.()
      //       deleteAnswer(answer)
      //     }}
      //     questionId={questionId}
      //   />
      // ) : undefined
    },
    [currentUser, deleteAnswer, questionId]
  )

  const renderSocialActivity = useCallback(
    (item: Answer) => (
      <SocialActivity
        likeButton={<AnswerLikeButton answer={item} />}
        likeCountComponent={<AnswerLikeCount answer={item} />}
        commentCountComponent={<AnswerCommentCount answer={item} />}
        isHideBottomLine={!item.comments.length}
      />
    ),
    []
  )

  const renderAnswerCommentList = useCallback(
    (item: Answer) => (
      <AnswerCommentList
        answerId={item.id}
        answerComments={item.comments}
        questionId={questionId}
      />
    ),
    [questionId]
  )

  const renderItem: ListRenderItem<Answer> = ({ item }: { item: Answer }) => {
    return (
      <CardContainer style={styles.answerItem}>
        <AnswerItem
          answer={item}
          isPC={isPC}
          answerControlMenu={renderAnswerControlMenu(item)}
          answerCommentList={renderAnswerCommentList(item)}
          answerSocialActivity={renderSocialActivity(item)}
        />
      </CardContainer>
    )
  }

  const ListHeaderComponent = (
    <View>
      {renderListHeader?.()}
      <View style={styles.verticalSeparator} />
      <View style={styles.answersHeader} testID="answer-detail-column">
        <Text style={commonStyles.titleTextStyle} testID="answer-list-count">
          {answers.length}{' '}
          {t('forum.answerCountLabel', {
            pluralCharacter: answers.length > 1 ? 's' : '',
          })}
        </Text>
        {/* TODO feature yet to support */}
        {/* <View style={styles.header}>
              <Text style={styles.sort}>{t('sortBy')}: </Text>
              <Text style={styles.sort}>{t('mostRecent')}</Text>
            </View> */}
      </View>
    </View>
  )

  const ListFooterComponent = (
    <View testID="answer-list-footer">
      <View style={styles.verticalSeparator} />
      {answers.length > 0 && (
        <View style={[styles.separator, styles.verticalSeparator]} />
      )}
      <View style={styles.answersHeader}>
        <Text style={commonStyles.titleTextStyle}>
          {t('forum.yourAnswerTitle')}
        </Text>
      </View>
      <AnswerForm
        onCreate={addAnswer}
        onUpdate={updateAnswer}
        questionId={questionId}
      />
    </View>
  )

  const renderList = () => {
    return (
      <View style={styles.container}>
        <MediaViewerContextProvider>
          <>
            <InfiniteScrollFlatList
              showsVerticalScrollIndicator
              scrollEnabled={!isPC}
              ListHeaderComponent={ListHeaderComponent}
              ListFooterComponent={ListFooterComponent}
              data={answers}
              renderItem={renderItem}
              style={styles.answerList}
              keyExtractor={keyExtractor}
              onReachBottom={fetchMoreAnswers}
              ItemSeparatorComponent={(): ReactElement => (
                <View style={styles.flatListSeparator} />
              )}
              ListEmptyComponent={
                isLoading || !accessToken ? undefined : (
                  <EmptyView
                    isPC={isPC}
                    title={t('forum.emptyAnswerTitle')}
                    subtitle={t('forum.firstAnswerTitle')}
                    imageSource={require('~/assets/images/prompts/nopostsyet.png')}
                  />
                )
              }
            />
            {isLoading && (
              <ActivityIndicator style={StyleSheet.absoluteFillObject} />
            )}
            {!accessToken && <AnswerBlueView />}
          </>
          <AnswerMediaViewerDialog
            isPC={isPC}
            renderAnswerControlMenu={renderAnswerControlMenu}
            renderSocialActivity={renderSocialActivity}
            renderAnswerCommentList={renderAnswerCommentList}
          />
        </MediaViewerContextProvider>
      </View>
    )
  }

  return (
    <View testID="answer-list" style={styles.container}>
      {renderList()}
      {isShowConfirmationDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('delete')}
          onPressAccept={deleteAnswer}
          onPressCancel={hideConfirmDialog}
          visible={isShowConfirmationDialog}
          message={t('forum.confirmDeleteAnswer')}
        />
      )}
      <ModalContainer visible={isShowEditDialog} onDismiss={hideEditDialog}>
        <View
          testID="answer-edit-form-container"
          style={styles.answerEditFormContainer}
        >
          <AnswerForm
            questionId={questionId}
            currentAnswer={currentAnswer}
            onUpdate={updateAnswer}
          />
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  answerEditFormContainer: {
    flex: 1,
    maxWidth: 590,
  },
  answerItem: {
    backgroundColor: color.white,
  },
  answerList: {
    flex: 1,
  },
  answersHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  container: {
    flex: 1,
  },
  flatListSeparator: {
    borderBottomColor: color.transparent,
    borderBottomWidth: 10,
  },
  // TODO style yet to use
  // header: {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-end',
  // },
  // sort: {
  //   color: color.pressableText,
  // },
  separator: {
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
  },
  verticalSeparator: {
    height: 20,
  },
})

export default AnswerList
