import React from 'react'

import MediaViewerDialog from '~/components/common/molecules/MediaViewerDialog'
import QuestionContentMobile from '~/components/forum/organisms/questions/QuestionContent/QuestionContentMobile'
import QuestionContentPC from '~/components/forum/organisms/questions/QuestionContent/QuestionContentPC'
import { MediaViewerContextProvider } from '~/contexts/MediaViewerContext'
import Question from '~/interfaces/Question'

export type QuestionContent = {
  question: Question
  questionControlMenu?: React.ReactElement
  socialActivityComponent?: React.ReactElement
}

type Props = {
  isPC: boolean
} & QuestionContent

const QuestionContent: React.FC<Props> = ({
  isPC,
  question,
  questionControlMenu,
  socialActivityComponent,
}: Props) => {
  return (
    <MediaViewerContextProvider>
      {isPC ? (
        <QuestionContentPC
          question={question}
          questionControlMenu={questionControlMenu}
          socialActivityComponent={socialActivityComponent}
        />
      ) : (
        <QuestionContentMobile
          question={question}
          questionControlMenu={questionControlMenu}
          socialActivityComponent={socialActivityComponent}
        />
      )}
      <MediaViewerDialog isPC={isPC} />
    </MediaViewerContextProvider>
  )
}

export default QuestionContent
