import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Platform } from 'react-native'

import CommentEditorInputAction from '~/components/career/molecules/post/CommentEditorInputAction'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  message?: string
  setMessage: (text: string) => void
  mediaPicker?: React.ReactNode
  onSubmitEditing?: (text: string) => void
}

const CommentEditorInput: React.FC<Props> = ({
  message,
  setMessage,
  mediaPicker,
  onSubmitEditing,
}: Props) => {
  const { t } = useTranslation()
  const defaultCommentHeight = 26
  const [height, setHeight] = useState(defaultCommentHeight)
  const handleSubmitEditing = (): void => {
    if (onSubmitEditing && message) {
      onSubmitEditing(message)
      setMessage('')
      setHeight(defaultCommentHeight)
    }
  }

  const handleMessage = (text: string): void => {
    if (text !== message) {
      setMessage(text)
    }

    if (text.trim() === '') {
      setHeight(defaultCommentHeight)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.inputViewContainer}>
        <Input
          multiline
          blurOnSubmit
          value={message}
          testID="comment-input"
          textAlignVertical="center"
          onChangeText={handleMessage}
          errorStyle={styles.noMargin}
          placeholder={t('postForm.writeAComment')}
          inputContainerStyle={styles.inputContainer}
          placeholderTextColor={color.placeholderText}
          onSubmitEditing={(): void => handleSubmitEditing()}
          inputStyle={{ ...StyleSheet.flatten(styles.input), height }}
          onContentSizeChange={(e): void =>
            setHeight(e.nativeEvent.contentSize.height)
          }
        />
      </View>
      <View style={styles.action}>
        <CommentEditorInputAction mediaPicker={mediaPicker} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  action: {
    alignSelf: 'center',
  },
  container: {
    backgroundColor: color.textInputBackground,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    ...commonStyles.borderRadiusAllCorner,
  },
  input: {
    borderColor: color.transparent,
    borderWidth: 0,
    flex: 1,
    fontSize: FontSize.GENERAL,
    minHeight: 30,
    paddingHorizontal: 2,
    paddingTop: 4,
    ...Platform.select({
      web: { outlineColor: color.transparent },
    }),
  },
  inputContainer: {
    paddingHorizontal: 2,
    paddingVertical: 4,
  },
  inputViewContainer: {
    flex: 1,
    marginRight: 6,
  },
  noMargin: {
    margin: 0,
  },
})

export default CommentEditorInput
