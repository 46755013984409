import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  onChangeText: (value: string) => void
  title?: string
  subtitle?: string
  size?: FontSize
  numberOfLines?: number
  value?: string
  unit?: string
  currentCount?: number
  maxLength?: number
  placeholder?: string
  shouldShowCounter?: boolean
}

const TextField: React.FC<Props> = ({
  onChangeText,
  subtitle,
  size,
  value,
  title,
  unit,
  numberOfLines,
  maxLength,
  currentCount,
  placeholder,
  shouldShowCounter,
}: Props) => {
  const { t } = useTranslation()
  const count = currentCount ?? (value == undefined ? 0 : value.length)

  return (
    <View testID="text-field" style={styles.container}>
      {title && (
        <Text
          style={StyleSheet.flatten([styles.title, { fontSize: size }])}
          testID="text-field-title"
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text style={styles.subtitle} testID="text-field-subtitle">
          {subtitle}
        </Text>
      )}
      <View style={styles.inputWrapper}>
        <Input
          testID="text-field-input"
          onChangeText={onChangeText}
          value={value || ''}
          errorStyle={styles.noMargin}
          numberOfLines={numberOfLines}
          multiline={(numberOfLines || 1) > 1}
          inputStyle={
            (numberOfLines || 1) > 1
              ? styles.input
              : StyleSheet.flatten([styles.input, { height: 32 }])
          }
          inputContainerStyle={styles.inputContainer}
          placeholder={placeholder}
          placeholderTextColor={color.placeholderText}
        />
      </View>
      <View style={styles.textLimit}>
        {maxLength && (
          <>
            {shouldShowCounter && (
              <Text style={styles.counter}>
                {t('inputCount', {
                  count,
                  limit: maxLength,
                  unit,
                })}
              </Text>
            )}
            <Text style={styles.errorMessage} testID="text-field-maxlength">
              {count > maxLength
                ? t('maxLength', {
                    maxLength: maxLength,
                  })
                : ''}
            </Text>
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    position: 'relative',
  },
  counter: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    textAlign: 'right',
    paddingRight: 10,
    paddingVertical: 5,
  },
  errorMessage: {
    color: color.danger,
  },
  input: {
    ...Platform.select({
      web: { outlineStyle: 'none' },
    }),
    padding: 10,
    ...commonStyles.borderRadiusAllCorner,
    ...commonStyles.bodyTextSize,
    borderColor: color.transparent,
  },
  inputContainer: {
    borderColor: color.transparent,
    borderWidth: 0,
  },
  inputWrapper: {
    borderColor: color.borderSeparatorDark,
    borderWidth: 1,
    ...commonStyles.borderRadiusAllCorner,
    ...commonStyles.bodyTextSize,
  },
  noMargin: {
    margin: 0,
  },
  subtitle: {
    ...commonStyles.subTextSize,
    color: color.textGray,
    fontWeight: '400',
    marginBottom: 4,
  },
  textLimit: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
  },
})

export default TextField
