import BaseHttpData from '~/interfaces/BaseHttpData'
import CommunityChannel from '~/interfaces/CommunityChannel'
import CommunityMemberJoinRequest from '~/interfaces/CommunityMemberJoinRequest'
import CommunityUser from '~/interfaces/CommunityUser'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'

export enum ChannelViewMode {
  Member = 'member',
  Pinned = 'pinned',
  Timeline = 'timeline',
}

export enum CommunityCategory {
  None = '',
  School = 'school',
  Business = 'business',
  Organization = 'organization',
  Npo = 'npo',
  Hobby = 'hobby',
}

export default interface Community extends BaseHttpData {
  id: number
  avatar: Media
  type: string
  name: string
  description: string
  purpose: string
  category: CommunityCategory
  isPrivate: false
  isApproved: true
  user: User
  channels: CommunityChannel[]
  communityMembers: CommunityUser[]
  communityMemberJoinRequests?: CommunityMemberJoinRequest[]
  createdAt: string
  communityMemberInvitations?: CommunityUser[]
  cover: Media
  avatarId: number
  coverId?: number | string
  feedback: string
}
