import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import Button from '~/components/workarounds/Button'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  visible: boolean
  reason?: string
  onPressCancel?: () => void
  onPressAccept?: () => void
  onChangeReason?: (value: string) => void
}

const ReportDialog: React.FC<Props> = ({
  visible,
  reason,
  onPressAccept,
  onPressCancel,
  onChangeReason,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ModalContainer visible={visible} onDismiss={onPressCancel}>
      <View style={styles.container} testID="report-dialog">
        <TitleText>{t('report.dialogTitle')}</TitleText>
        <Input
          multiline
          value={reason}
          maxLength={200}
          numberOfLines={5}
          onChangeText={onChangeReason}
          inputStyle={styles.reasonInput}
          placeholder={t('report.dialogHintReason')}
        />
        <View style={styles.actionContainer}>
          <Button
            title={t('cancel')}
            testID="cancel-button"
            onPress={onPressCancel}
            buttonStyle={StyleSheet.flatten([
              styles.button,
              { backgroundColor: color.gray },
            ])}
          />
          <Button
            title={t('send')}
            testID="send-button"
            onPress={onPressAccept}
            buttonStyle={styles.send}
            containerStyle={styles.button}
          />
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: 260,
  },
  button: {
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 10,
    maxWidth: 150,
    minWidth: 110,
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    maxWidth: 370,
    padding: 20,
  },
  reasonInput: {
    fontSize: FontSize.GENERAL,
    borderWidth: 1,
    borderColor: color.lightGray,
    borderRadius: 4,
    paddingVertical: 4,
    marginVertical: 10,
  },
  send: {
    backgroundColor: color.primary,
  },
})

export default ReportDialog
