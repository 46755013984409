import {
  DrawerContentComponentProps,
  useDrawerStatus,
} from '@react-navigation/drawer'
import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import DrawerContent from '~/components/community/features/drawer/DrawerContent'
import { drawerClosed, drawerOpened } from '~/slices/common/drawer'

const DrawerTemplate: React.FC<DrawerContentComponentProps> = () => {
  const dispatch = useDispatch()

  const drawerStatus = useDrawerStatus()

  useEffect(() => {
    if (drawerStatus === 'open') {
      dispatch(drawerOpened())
    } else {
      dispatch(drawerClosed())
    }
  }, [dispatch, drawerStatus])

  useEffect(() => {
    return (): void => {
      dispatch(drawerClosed())
    } // On unmount
  }, [dispatch])

  return (
    <DefaultLayout>
      <View testID="drawer-column" style={styles.container}>
        <DrawerContent />
      </View>
    </DefaultLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default DrawerTemplate
