import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import jobHuntingOccupations from '~/assets/data/jobHuntingOccupations.json'
import RegistrationIndicator from '~/components/common/atoms/users/RegistrationIndicator'
import CustomPicker, {
  PickerOption,
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import JobHuntingStatusGroup from '~/components/common/molecules/users/profileStatus/JobHuntingStatusGroup'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Profile from '~/interfaces/Profile'
import i18n from '~/plugins/i18n'

type Props = {
  jobHuntingData: Partial<Profile>
  onPreviousColumn: () => void
  onUpdateJobHuntingData: (data: Partial<Profile>) => void
  error?: Error
  isRegistering?: boolean
  onNextColumn: () => void
  isPC?: boolean
}

const getOccupationListData = (): PickerOption[] =>
  jobHuntingOccupations.map((item) =>
    pickerValue(i18n.language === 'ja' ? item.name : item.enName, item.id)
  )

const JobHuntingForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [isDataValid, setIsDataValid] = useState(false)
  const [jobHuntingStatus, setJobHuntingStatus] = useState<string[]>(
    props.jobHuntingData.jobHuntingStatus || []
  )
  const [career, setCareer] = useState(props.jobHuntingData.career || '')

  const occupationListData = getOccupationListData()

  const errorMessage = t('failedToUpdate')

  useEffect(() => {
    validate()
  }, [jobHuntingStatus, career])

  const onUpdateJobHuntingData = (): void => {
    const jobHuntingData = {
      career,
      jobHuntingStatus: jobHuntingStatus,
    }
    props.onUpdateJobHuntingData(jobHuntingData)
  }

  const back = (): void => {
    onUpdateJobHuntingData()
    props.onPreviousColumn()
  }

  const nextColumn = (): void => {
    onUpdateJobHuntingData()
    props.onNextColumn()
  }

  const validate = (): void => {
    if (career && jobHuntingStatus && jobHuntingStatus.length > 0) {
      setIsDataValid(true)
    } else {
      setIsDataValid(false)
    }
  }

  const changeCareer = (value: string | number): void => {
    setCareer(value.toString())
  }

  return (
    <View testID="job-hunting-form">
      <RegistrationIndicator index={1} isPC={props.isPC} />
      <View style={styles.occupation}>
        <Text style={styles.title}>{t('jobs.hunting.currentOccupation')}</Text>
        <View testID="occupation-picker">
          <CustomPicker
            data={occupationListData}
            size="large"
            selectedValue={career}
            onValueChange={changeCareer}
          />
        </View>
      </View>
      <JobHuntingStatusGroup
        numColumns={props.isPC ? 2 : 1}
        selectedItems={jobHuntingStatus}
        onChange={(items): void => setJobHuntingStatus([...items])}
      />
      <Text style={styles.error} testID="error-message">
        {props.error ? errorMessage : ''}
      </Text>
      <Button
        style={StyleSheet.flatten(styles.nextButton)}
        title={t('next')}
        testID="next-button"
        loading={props.isRegistering}
        disabled={!isDataValid || props.isRegistering}
        onPress={nextColumn}
      />
      <Button
        style={StyleSheet.flatten(styles.backButton)}
        title={t('back')}
        disabled={props.isRegistering}
        type="outline"
        testID="back-button"
        onPress={back}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  backButton: {
    marginTop: 10,
  },
  error: {
    alignSelf: 'center',
    color: color.badgeColor,
    fontSize: FontSize.SUB,
    marginTop: 20,
  },
  nextButton: {
    marginTop: 30,
  },
  occupation: {
    marginTop: 20,
  },
  title: {
    color: color.darkTitleText,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginBottom: 6,
  },
})

export default JobHuntingForm
