import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit'

import api from '~/api'
import { UsersParams } from '~/api/users'
import Profile from '~/interfaces/Profile'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

type UsersState = {
  isLoading: boolean
  current?: User
  error: string | null
  page: number
  isFetchingUser: boolean
}

export type UserProfile = {
  userProfile: Partial<Profile>
}

export const defaultState: UsersState = {
  isFetchingUser: false,
  isLoading: false,
  error: null,
  page: 1,
}

export const usersAdapter = createEntityAdapter<User>()

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(defaultState),
  reducers: {
    setAll: usersAdapter.setAll,
    addMany: usersAdapter.addMany,
    userRequestStart(state): void {
      state.isLoading = true
      state.error = null
    },
    userRequestSuccess(state): void {
      state.isLoading = false
      state.error = null
    },
    nextPage(state): void {
      state.page = state.page + 1
    },
    previousPage(state): void {
      if (state.page > 1) state.page = state.page - 1
    },
    userRequestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
    currentUserProfileFetched(state, action: PayloadAction<User>): void {
      const user = action.payload
      state.current = user
    },
    currentUserProfileUpdated(
      state,
      action: PayloadAction<Partial<Profile>>
    ): void {
      if (!state.current) {
        return
      }
      const updatedProfile = {
        ...state.current.profile,
        ...action.payload,
      }
      const updatedUser = { ...state.current, profile: updatedProfile }
      state.current = updatedUser
    },
    setIsFetchingUser(state, action: PayloadAction<boolean>): void {
      state.isFetchingUser = action.payload
    },
  },
})

export const {
  setAll,
  addMany,
  nextPage,
  previousPage,
  userRequestStart,
  setIsFetchingUser,
  userRequestSuccess,
  userRequestFailure,
  currentUserProfileFetched,
  currentUserProfileUpdated,
} = usersSlice.actions

export const fetchCurrentUser = (): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(setIsFetchingUser(true))
    const response = await api.userProfile.currentUser()
    dispatch(currentUserProfileFetched(response))
    dispatch(setIsFetchingUser(false))
  } catch (err) {
    dispatch(setIsFetchingUser(false))
    dispatch(userRequestFailure(String(err.error?.detail)))
    throw err
  }
}

export const createCurrentUser = (data: UserProfile): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(userRequestStart())
    const user = await api.userProfile.create(data)
    dispatch(currentUserProfileFetched(user))
    dispatch(userRequestSuccess())
  } catch (err) {
    dispatch(userRequestFailure(String(err.error?.detail)))
  }
}

export const updateCurrentUser = (data: UserProfile): AppThunk => async (
  dispatch
): Promise<User | void> => {
  try {
    dispatch(userRequestStart())
    const user = await api.userProfile.update(data)
    dispatch(currentUserProfileUpdated(user.profile))
    dispatch(userRequestSuccess())
    return user
  } catch (err) {
    dispatch(userRequestFailure(String(err.error?.detail ?? err)))
  }
}

export const fetchUsers = (params: Partial<UsersParams>): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(userRequestStart())
    const response = await api.users.index<User[], Partial<UsersParams>>(params)
    dispatch(setAll(response))
    dispatch(userRequestSuccess())
  } catch (err) {
    dispatch(userRequestFailure(String(err.error.detail)))
    throw err
  }
}

export const fetchMoreUsers = (
  params: Partial<UsersParams>
): AppThunk => async (dispatch, getState): Promise<User[]> => {
  try {
    const currentPage = getState().users.page
    const nextPage = currentPage + 1
    const response = await api.users.index<User[], Partial<UsersParams>>({
      ...params,
      ...{ page: nextPage },
    })
    dispatch(addMany(response))
    return response
  } catch (err) {
    dispatch(userRequestFailure(String(err.error.detail)))
    throw err
  }
}

export const getAllUsers = (state: RootState): User[] =>
  usersAdapter.getSelectors().selectAll(state.users)

const selectUsers = (state: RootState): UsersState => state.users
export const currentUserSelector = createSelector(
  selectUsers,
  (users: UsersState): User | undefined => users.current
)

export default usersSlice.reducer
