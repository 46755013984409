import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import camelcaseKeys from 'camelcase-keys'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import {
  CommunityQueryParams,
  CommunityType,
} from '~/api/communities/communities'
import SearchInput from '~/components/common/atoms/SearchInput'
import CustomPicker from '~/components/common/molecules/CustomPicker'
import { ColorTheme } from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import useCustomNavigation from '~/hooks/useNavigation'
import { navigationRef } from '~/utils/navigation'

const CommunityHeaderLeft: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const { setQueryParams } = useCustomNavigation()
  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as CommunityQueryParams
  const searchParams = camelcaseKeys(routeParams || {}) as CommunityQueryParams
  const [query, setQuery] = useState(searchParams.q ?? '')
  const [type, setType] = useState('all')

  const typeOptions = [
    { label: t('community.community'), value: 'all' },
    { label: t('community.pages.joined'), value: 'joined' },
    { label: t('community.pages.manage'), value: 'manage' },
  ]

  const updateSearchQuery = (): void => {
    searchParams.q = query?.length > 0 ? query : undefined
    setQueryParams(searchParams)
  }

  const changeCategory = (value: ItemValue): void => {
    const val = value.toString() as CommunityType
    if (searchParams.type !== val) {
      setType(val)
      setQueryParams({
        ...searchParams,
        type: val,
      })
    }
  }

  useEffect(() => {
    setQuery(searchParams.q ?? '')
    setType(searchParams?.type ?? 'all')
  }, [searchParams.q, searchParams?.type])

  return (
    <View testID="community-subheader-left" style={styles.container}>
      <View style={styles.pickerContainer} testID="community-type-picker">
        {isPC && (
          <CustomPicker
            size="full"
            data={typeOptions}
            selectedValue={type}
            colorTheme={ColorTheme.Light}
            onValueChange={changeCategory}
            isWithoutBorder={true}
          />
        )}
      </View>
      <View
        testID="community-subheader-search"
        style={isPC ? searchInputContainer.pc : searchInputContainer.mobile}
      >
        <SearchInput
          inverted
          value={query}
          onChangeText={setQuery}
          onSubmit={updateSearchQuery}
          placeholderKey="community.search"
        />
      </View>
    </View>
  )
}

const searchInputContainer = {
  pc: {
    width: 352,
    marginLeft: 20,
    marginTop: 10,
  },
  mobile: {
    width: 300,
  },
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: 5,
    alignItems: 'center',
  },
  pickerContainer: {
    width: 'auto',
  },
})

export default CommunityHeaderLeft
