import { isEmpty } from 'lodash'
import snakecaseKeys from 'snakecase-keys'

import type { HTTPClient } from '~/httpClient'

export type BaseQueryParams = {
  page?: number
  perPage?: number
  sortBy?: string
  q?: string
  categories?: string[] | number[]
}

export class BaseAPI {
  protected client: HTTPClient
  path = ''

  constructor(client: HTTPClient) {
    this.client = client
  }

  private parseObjectToQueryString(
    key: string,
    value: string[] | number[] | object
  ): string {
    let urlParams = ''
    if (Array.isArray(value)) {
      const queryArray: string[] = []
      value.forEach((item) => {
        !isEmpty(item) && queryArray.push(`${key}[]=${item}`)
      })
      urlParams = queryArray.join('&')
    } else {
      urlParams = `${key}=${value}`
    }
    return urlParams
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getQueryString(queryParams: { [key: string]: any }): string {
    const queryParamsString = ''
    const params = snakecaseKeys(queryParams)
    const keys = Object.keys(params)
    if (!isEmpty(keys)) {
      return keys
        .map((key) =>
          params[key] ? this.parseObjectToQueryString(key, params[key]) : ''
        )
        .filter((item) => !isEmpty(item))
        .join('&')
    }
    return queryParamsString
  }

  private isValid(): boolean {
    if (isEmpty(this.path)) {
      console.error('URL path is required.')
      return false
    }
    return true
  }

  async index<R, Q extends BaseQueryParams>(queryParams: Q): Promise<R> {
    if (!this.isValid()) {
      return Promise.reject()
    }
    return await this.client.get<R>(
      `${this.path}?${this.getQueryString(queryParams)}`
    )
  }

  async show<R>(id: number): Promise<R> {
    if (!this.isValid()) {
      return Promise.reject()
    }
    return await this.client.get<R>(`${this.path}/${id}`)
  }

  async create<T, R>(data: T): Promise<R> {
    if (!this.isValid()) {
      return Promise.reject()
    }
    return await this.client.post<R>(this.path, data)
  }

  async update<T, R>(id: number, data: T): Promise<R> {
    if (!this.isValid()) {
      return Promise.reject()
    }
    return await this.client.patch<R>(`${this.path}/${id}`, data)
  }

  async delete<T, R>(id: number, data?: T): Promise<R> {
    if (!this.isValid()) {
      return Promise.reject()
    }
    return await this.client.delete<R>(`${this.path}/${id}`, data)
  }
}
