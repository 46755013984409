import React, { useState } from 'react'
import { LayoutChangeEvent } from 'react-native'
import { Button as RNElementsButton, ButtonProps } from 'react-native-elements'

const RoundedButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const [buttonHeight, setButtonHeight] = useState<number>(0)

  const onLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout
    setButtonHeight(height)
  }

  return (
    <RNElementsButton
      {...props}
      buttonStyle={[props.buttonStyle, { borderRadius: buttonHeight / 2 }]}
      titleStyle={[props.titleStyle]}
      onLayout={onLayout}
    />
  )
}

export default RoundedButton
