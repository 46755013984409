import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import NotificationInfo from '~/components/common/features/notification/NotificationInfo'
import NotificationControlMenu from '~/components/common/molecules/notification/NotificationControlMenu'
import color from '~/constants/common/color'
import Notification from '~/interfaces/Notification'

type Props = {
  isMobile: boolean
  notifications: Notification[]
  markAsRead: (notification: Notification) => void
  navigateToContent: (notification: Notification) => void
}

const NotificationDrawer: React.FC<Props> = ({
  isMobile,
  notifications,
  markAsRead,
  navigateToContent,
}: Props) => {
  const { t } = useTranslation()
  const containerStyle = isMobile ? styles.containerMobile : styles.container
  const headerStyle = isMobile ? styles.headerMobile : styles.header
  const titleStyle = isMobile ? styles.titleMobile : styles.title

  const handlePressItem = (notification: Notification): void => {
    markAsRead(notification)
    navigateToContent(notification)
  }

  return (
    <View testID="notification-drawer" style={containerStyle}>
      <View style={headerStyle}>
        <TitleText style={titleStyle} testID="notification-drawer-title">
          {t('notification.notifications')}
        </TitleText>
        <NotificationControlMenu
          markAsRead={markAsRead}
          notifications={notifications}
        />
      </View>
      <View style={styles.notification}>
        <NotificationInfo
          notifications={notifications}
          onPressItem={handlePressItem}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
  },
  containerMobile: {
    backgroundColor: color.white,
    flex: 1,
  },
  header: {
    backgroundColor: color.white,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 46,
    paddingBottom: 33,
    paddingHorizontal: 32,
    maxHeight: 115,
  },
  headerMobile: {
    backgroundColor: color.white,
    flexDirection: 'row',
    paddingTop: 21,
    paddingHorizontal: 32,
    justifyContent: 'space-between',
    paddingBottom: 16,
  },
  notification: {
    backgroundColor: color.white,
    position: 'relative',
  },
  title: {
    backgroundColor: color.white,
    lineHeight: 22,
    width: '100%',
  },
  titleMobile: {
    alignSelf: 'center',
    backgroundColor: color.white,
    lineHeight: 14,
  },
})

export default NotificationDrawer
