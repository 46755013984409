import React from 'react'
import {
  ScrollView,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '~/rootReducer'
import { setIsBottom } from '~/slices/common/scroll'

type Props = {
  children: React.ReactNode
  onScrollBottom?: () => void
}

const InfiniteScrollContainer: React.FC<Props> = ({
  children,
  onScrollBottom,
}: Props) => {
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const dispatch = useDispatch()

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>): void => {
    const { contentSize, contentOffset, layoutMeasurement } = e.nativeEvent

    const remaining = contentSize.height - contentOffset.y
    const remainingRatio = remaining / layoutMeasurement.height

    if (remainingRatio <= 1) {
      !isBottom && dispatch(setIsBottom(true))
      onScrollBottom && onScrollBottom()
    } else {
      isBottom && dispatch(setIsBottom(false))
    }
  }

  return (
    <ScrollView
      onScroll={handleScroll}
      testID="infinite-scroll-container"
      scrollEventThrottle={100}
    >
      {children}
    </ScrollView>
  )
}

export default InfiniteScrollContainer
