import { CardElement } from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  isPC?: boolean
}

const stripeCardOptions = {
  style: {
    base: {
      fontSize: '20px',
      color: color.primary,
      '::placeholder': {
        color: color.gray,
      },
    },
    invalid: {
      color: color.danger,
    },
  },
}

const PaymentMethodSection: React.FC<Props> = ({ isPC }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="payment-method-section">
      <Text style={styles.title}>{t('subscriptions.paymentMethodTitle')}</Text>
      <CardContainer
        testID="card-container"
        style={StyleSheet.flatten([
          styles.cardContainer,
          isPC ? { flexDirection: 'row' } : undefined,
        ])}
      >
        <View style={styles.stripeCardContainer}>
          <CardElement options={stripeCardOptions} />
        </View>
      </CardContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 30,
    backgroundColor: color.white,
  },
  stripeCardContainer: {
    width: '100%',
  },
  title: {
    fontSize: 35,
    marginBottom: 24,
    fontWeight: 'bold',
  },
})

export default PaymentMethodSection
