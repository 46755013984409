import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isEdit?: boolean
  isVertical?: boolean
  isDisabled?: boolean
  isNormalMode?: boolean
  isSaveLoading?: boolean
  onSavePress?: () => void
  onEditPress?: () => void
  onCancelPress?: () => void
}

const TextInputAction: React.FC<Props> = ({
  isVertical,
  onSavePress,
  onEditPress,
  isNormalMode,
  onCancelPress,
  isSaveLoading,
  isEdit = false,
  isDisabled = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="text-input-action">
      {isNormalMode ? (
        <Button
          type="solid"
          testID="edit-button"
          title={t('edit')}
          onPress={onEditPress}
          buttonStyle={StyleSheet.flatten(styles.editButton)}
          titleStyle={StyleSheet.flatten(styles.editTitle)}
        />
      ) : isVertical ? (
        <View style={styles.verticalEditAction} testID="vertical-container">
          <Button
            type="solid"
            testID="save-button"
            title={isEdit ? t('save') : t('postForm.post')}
            loading={isSaveLoading}
            onPress={onSavePress}
            buttonStyle={StyleSheet.flatten(styles.saveButton)}
            titleStyle={StyleSheet.flatten(styles.buttonTitle)}
            disabled={isDisabled}
          />
          <View style={styles.separator} />
          <Button
            type="solid"
            testID="cancel-button"
            title={t('cancel')}
            disabled={isSaveLoading}
            onPress={onCancelPress}
            buttonStyle={StyleSheet.flatten(styles.cancelButton)}
            titleStyle={StyleSheet.flatten(styles.cancelTitle)}
          />
        </View>
      ) : (
        <>
          <Button
            type="solid"
            testID="cancel-button"
            title={t('cancel')}
            disabled={isSaveLoading}
            onPress={onCancelPress}
            buttonStyle={StyleSheet.flatten(styles.cancelButton)}
            titleStyle={StyleSheet.flatten(styles.cancelTitle)}
          />
          <Button
            type="solid"
            testID="save-button"
            title={isEdit ? t('save') : t('postForm.post')}
            loading={isSaveLoading}
            onPress={onSavePress}
            buttonStyle={StyleSheet.flatten(styles.saveButton)}
            titleStyle={StyleSheet.flatten(styles.buttonTitle)}
            disabled={isDisabled}
          />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonTitle: {
    fontSize: FontSize.SUB,
  },
  cancelButton: {
    alignSelf: 'flex-end',
    backgroundColor: color.gray,
    height: 30,
    width: 90,
  },
  cancelTitle: {
    alignSelf: 'center',
    color: color.black,
    fontSize: FontSize.SUB,
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
  },
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 90,
  },
  editTitle: {
    alignSelf: 'center',
    fontSize: FontSize.GENERAL,
  },
  saveButton: {
    alignSelf: 'flex-end',
    height: 30,
    marginLeft: 10,
    width: 90,
  },
  separator: {
    marginBottom: 6,
  },
  verticalEditAction: {
    flex: 1,
    alignItems: 'flex-end',
  },
})

export default TextInputAction
