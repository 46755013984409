import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, SectionList, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import noMemberImage from '~/assets/images/prompts/nomembersyet.png'
import EmptyView from '~/components/common/molecules/EmptyView'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import RequestedUserActions from '~/components/community/features/RequestedUserActions'
import RequestedUser from '~/components/community/organisms/RequestedUser'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Community from '~/interfaces/Community'
import CommunityMemberJoinRequest from '~/interfaces/CommunityMemberJoinRequest'
import CommunityUser from '~/interfaces/CommunityUser'
import { RootState } from '~/rootReducer'
import { setCurrentCommunity } from '~/slices/community/community'

type MemberData = {
  title: string
  data: CommunityMemberJoinRequest[]
}

const RequestedUserList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const community = useSelector((state: RootState) => state.community.current)

  const containerStyle = isPC
    ? StyleSheet.flatten([styles.container, styles.paddingContainer])
    : styles.container

  const memberData: MemberData[] = [
    {
      title: t('community.admin.requestedUsers'),
      data: community?.communityMemberJoinRequests ?? [],
    },
  ]

  const deleteMemberJoinRequest = (
    member: CommunityMemberJoinRequest
  ): void => {
    dispatch(
      setCurrentCommunity({
        ...community,
        communityMemberJoinRequests: community?.communityMemberJoinRequests?.filter(
          (r) => r.id !== member.id
        ),
      } as Community)
    )
  }

  const changeMemberJoinRequestToMember = (
    joinRequest: CommunityMemberJoinRequest
  ): void => {
    dispatch(
      setCurrentCommunity({
        ...community,
        communityMemberJoinRequests: community?.communityMemberJoinRequests?.filter(
          (r) => r.id !== joinRequest.id
        ),
        communityMembers: [
          ...(community?.communityMembers || []),
          { ...joinRequest, isAdmin: false } as CommunityUser,
        ],
      } as Community)
    )
  }

  const renderItem: ListRenderItem<CommunityMemberJoinRequest> = useCallback(
    ({ item }: { item: CommunityMemberJoinRequest }): JSX.Element => (
      <RequestedUser
        isPC={isPC}
        memberJoinRequest={item}
        requestUserActions={
          <RequestedUserActions
            memberJoinRequest={item}
            onDismiss={(): void => deleteMemberJoinRequest(item)}
            onAccept={(): void => changeMemberJoinRequestToMember(item)}
          />
        }
      />
    ),
    [isPC]
  )

  const keyExtractor = (
    item: CommunityMemberJoinRequest,
    index: number
  ): string => (item.id + index).toString()

  return (
    <View testID="requested-user-list" style={containerStyle}>
      <SectionList
        sections={memberData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        SectionSeparatorComponent={(): JSX.Element => (
          <View style={styles.separator} />
        )}
        renderSectionHeader={({ section: { title } }): JSX.Element => (
          <>
            {!isPC && <HeaderCommunityDetail />}
            <Text style={styles.header}>{title}</Text>
          </>
        )}
        renderSectionFooter={({ section: { data } }): JSX.Element | null => {
          if (data.length === 0) {
            return (
              <EmptyView
                isPC={isPC}
                title={t('community.emptyPages.memberRequest.title')}
                imageSource={noMemberImage}
              />
            )
          }

          return null
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
  },
  header: {
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginTop: 20,
    paddingLeft: 40,
  },
  paddingContainer: {
    paddingHorizontal: 40,
    paddingTop: 20,
  },
  separator: {
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    marginTop: 20,
  },
})

export default RequestedUserList
