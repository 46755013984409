import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import HomeTemplate from '~/components/career/templates/HomeTemplate'
import OverlayLoadingView from '~/components/common/organisms/OverlayLoadingView'
import { RootState } from '~/rootReducer'
import { setPageTitle } from '~/utils/navigation'

const Home: React.FC = () => {
  useFocusEffect(() => setPageTitle('Enjin'))
  const isFetchingUser = useSelector(
    (state: RootState) => state.users.isFetchingUser
  )

  return isFetchingUser ? (
    <OverlayLoadingView />
  ) : (
    <DefaultLayout>
      <HomeTemplate />
    </DefaultLayout>
  )
}

export default Home
