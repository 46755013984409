import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import FloatButton from '~/components/common/atoms/FloatButton'
import RoundedButton from '~/components/common/atoms/RoundedButton'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import Recruitment from '~/interfaces/Recruitment'
import { currentCompanySelector } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { navigate } from '~/utils/navigation'

const JobAdd: React.FC = () => {
  const { t } = useTranslation()
  const currentUser = useSelector(currentUserSelector)
  const currentCompany = useSelector(currentCompanySelector)
  const { isPC } = useDevice()

  const [isShowedDialog, setIsShowedDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [job, setJob] = useState<Partial<Recruitment> | undefined>(undefined)

  const checkLimitPost = () => {
    const subscription = currentUser && currentUser.subscription
    if (!subscription) {
      showDialog()
      return
    }

    const maxPosts = subscription.subscriptionPlan?.maxPosts || 0
    const consumedPosts = subscription.consumedPost
    if (consumedPosts >= maxPosts) {
      showDialog()
    } else {
      createRecruitment(job)
    }
  }
  const showDialog = () => setIsShowedDialog(true)
  const closeDialog = () => setIsShowedDialog(false)
  const redirectToSubscriptionPlanPage = () => navigate('subscriptionPlan')
  const acceptToUpgradePlan = () => {
    redirectToSubscriptionPlanPage()
    closeDialog()
  }

  useEffect(() => {
    const newJobInitPayload: Partial<Recruitment> = {
      businesses: '',
      companyName: currentCompany?.name ?? '',
      formOfContract: [],
      hiring: [],
      industries: [],
      insurance: '',
      location: '',
      position: '',
      subject: t('company.jobs.untitledJob'),
      workTime: '',
      companyDescription: '',
      overview: '',
      job: '',
      criterion: '',
      salary: '',
      welfare: '',
      workLocation: '',
      allowance: '',
      holiday: '',
      capital: '',
      headOfficeArea: '',
      numOfEmployees: '',
      skills: [],
    }
    setJob(newJobInitPayload)
  }, [currentCompany])

  const createRecruitment = useAPI(
    async (api: API, data: Recruitment): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.companyRecruitments
          .configPath(currentCompany?.id as number)
          .create<Recruitment, Recruitment>(data)
        navigate('companyJobEdit', {
          id: response.id,
          companyId: currentCompany?.id,
        })
      } catch (err) {
        //TODO show error
      }
      api.isAlive && setIsLoading(false)
    },
    [currentCompany]
  )

  return (
    <View testID="job-add">
      {isPC ? (
        <RoundedButton
          testID="create-job-post-button"
          buttonStyle={styles.createJob}
          titleStyle={styles.createJobTitle}
          containerStyle={styles.createJobContainer}
          title={`+ ${t('company.jobs.createJobPostButton')}`}
          onPress={checkLimitPost}
        />
      ) : (
        <FloatButton onPress={checkLimitPost} />
      )}
      <ConfirmationDialog
        visible={isShowedDialog}
        message={t('jobs.limitedPosts')}
        acceptLabel={t('subscriptions.goPremium')}
        onPressAccept={acceptToUpgradePlan}
        onPressCancel={closeDialog}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  createJob: {
    alignSelf: 'baseline',
    backgroundColor: color.white,
    justifyContent: 'center',
    minHeight: 30,
    padding: 0,
    width: '100%',
  },
  createJobContainer: {
    width: '100%',
  },
  createJobTitle: {
    ...commonStyles.buttonTextSize,
    alignSelf: 'center',
    color: color.primary,
    textAlign: 'center',
  },
})

export default JobAdd
