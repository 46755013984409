import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import i18n from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  shouldHideImage?: boolean
  isCompany?: boolean
}

const IntroductionColumn: React.FC<Props> = ({
  shouldHideImage,
  isCompany,
}) => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const containerStyle = isPC ? styles.pc : styles.mobile

  const titleStyles = isPC
    ? styles.introductionTitle
    : styles.introductionTitleMobile
  const subtitleStyles = isPC
    ? styles.introductionSubTitle
    : styles.introductionSubTitleMobile

  const renderTexts = () => {
    return (
      <>
        <Text style={titleStyles}>
          {isCompany
            ? t('tutorial.introductionCompanyTitle') + ' '
            : t('tutorial.introductionTitle') + ' '}
          <Text style={[titleStyles, styles.introductionService]}>
            {isCompany
              ? t('tutorial.introductionCompanyTitleCareer')
              : t('tutorial.introductionTitleCareer')}
          </Text>
        </Text>
        <Text style={subtitleStyles}>
          {isCompany
            ? t('tutorial.introductionCompanySubTitle')
            : t('tutorial.introductionSubTitle')}
        </Text>
      </>
    )
  }

  return (
    <View style={styles.container} testID="introduction-column">
      <View style={containerStyle}>
        {!isPC && !shouldHideImage && (
          <Image
            source={require('~/assets/images/introduction_landscape.png')}
            style={styles.introductionLandscape}
            testID="introduction-landscape"
          />
        )}
        {renderTexts()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  introductionLandscape: {
    height: 80,
    marginBottom: 28,
    marginTop: 34,
    width: 'auto',
  },
  introductionService: {
    color: color.black,
  },
  introductionSubTitle: {
    fontSize: 26,
    textAlign: 'center',
  },
  introductionSubTitleMobile: {
    fontSize: FontSize.GENERAL,
    paddingBottom: 10,
    textAlign: 'center',
  },
  introductionTitle: {
    color: color.primary,
    fontSize: 35,
    fontFamily: getMediumFontByLanguage(i18n.language),
    textAlign: 'center',
  },
  introductionTitleMobile: {
    color: color.primary,
    textAlign: 'center',
    fontFamily: getMediumFontByLanguage(i18n.language),
    fontSize: 23,
    fontWeight: 'bold',
  },
  mobile: {
    paddingHorizontal: 35,
  },
  pc: {
    flex: 1,
  },
})

export default IntroductionColumn
