import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import ConfirmPasswordInput from '~/components/common/atoms/auth/ConfirmPasswordInput'
import PasswordInput from '~/components/common/atoms/auth/PasswordInput'
import ActionsSettingElement from '~/components/common/molecules/settings/ActionsSettingElement'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

const AccountSettingPassword: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const containerStyle = !isPC ? styles.containerVertical : styles.container
  const inputStyle = isPC ? styles.inputWrapper : styles.inputWrapperMobile

  const [editing, setEditing] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [currentPasswordValid, setCurrentPasswordValid] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const [isConfirmationPasswordValid, setConfirmationPasswordValid] = useState(
    false
  )

  const startEdit = (): void => {
    setEditing(true)
  }

  const cancelEdit = (): void => {
    setEditing(false)
  }

  const handleChangeCurrentPassword = (
    text: string,
    isValid: boolean
  ): void => {
    setCurrentPassword(text)
    setCurrentPasswordValid(isValid)
  }

  const handleChangePassword = (text: string, isValid: boolean): void => {
    setPassword(text)
    setPasswordValid(isValid)
  }

  const save = async (): Promise<void> => {
    try {
      await api.account.updatePassword({
        currentPassword,
        password,
        passwordConfirmation: password,
      })
      setEditing(false)
    } catch (e) {
      //TODO show error message
    }
  }

  return (
    <View testID="account-setting-password" style={containerStyle}>
      {editing ? (
        <>
          <View style={styles.passwordInfo}>
            <Text style={styles.label}>{t('auth.password')}</Text>
            <View style={inputStyle} testID="password-container">
              <PasswordInput
                title={t('auth.currentPassword')}
                onChangeText={handleChangeCurrentPassword}
              />
              <PasswordInput onChangeText={handleChangePassword} />
              <ConfirmPasswordInput
                originalPassword={password}
                verifyChanged={setConfirmationPasswordValid}
              />
            </View>
          </View>
          <ActionsSettingElement
            editable={editing}
            onPressSave={save}
            onPressEdit={startEdit}
            onPressCancel={cancelEdit}
            savable={
              currentPasswordValid &&
              passwordValid &&
              isConfirmationPasswordValid
            }
          />
        </>
      ) : (
        <>
          <View style={styles.passwordInfo}>
            <Text style={styles.label}>{t('auth.password')}</Text>
            <Text style={styles.value}>****************</Text>
          </View>
          <ActionsSettingElement
            editable={editing}
            onPressSave={save}
            onPressEdit={startEdit}
            onPressCancel={cancelEdit}
          />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerVertical: {
    justifyContent: 'center',
  },
  inputWrapper: {
    flex: 1,
    marginTop: 10,
  },
  inputWrapperMobile: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  label: {
    color: color.black,
    marginRight: 20,
    width: 120,
  },
  passwordInfo: {
    flex: 1,
    flexDirection: 'column',
  },
  value: {
    color: color.textGray,
    flex: 1,
    marginTop: 10,
  },
})

export default AccountSettingPassword
