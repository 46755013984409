import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  isPC?: boolean
  leftColumn?: ReactNode
  rightColumn?: ReactNode
}

const ProfileOverview: React.FC<Props> = ({
  isPC,
  leftColumn,
  rightColumn,
}: Props) => (
  <View style={styles.container} testID="profile-overview">
    {isPC ? (
      <View style={styles.containerPC}>
        <View style={styles.leftColumn} testID="left-column">
          {leftColumn}
        </View>
        <View style={styles.rightColumn} testID="right-column">
          {rightColumn}
        </View>
      </View>
    ) : (
      <>
        {leftColumn}
        {rightColumn}
      </>
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerPC: {
    flexDirection: 'row',
    flex: 1,
  },
  leftColumn: {
    flex: 1,
    marginRight: 10,
  },
  rightColumn: {
    width: 200,
  },
})

export default ProfileOverview
