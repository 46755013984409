import React from 'react'
import { View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'

type Props = {
  leftColumn?: React.ReactElement
  rightColumn?: React.ReactElement
}

const CompanyApplicants: React.FC<Props> = ({
  leftColumn,
  rightColumn,
}: Props) => (
  <View testID="company-applicants">
    <HorizontalContainer>
      {leftColumn}
      {rightColumn}
    </HorizontalContainer>
  </View>
)

export default CompanyApplicants
