import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import QuizImage from '~/components/career/atoms/quiz/QuizImage'
import QuizResultView from '~/components/career/molecules/quizzes/QuizResult'
import Quiz from '~/interfaces/Quiz'
import QuizResult from '~/interfaces/QuizResult'

type Props = {
  quiz: Quiz
  quizResult?: QuizResult
}

const DailyQuizItemHeader: React.FC<Props> = ({ quiz, quizResult }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="daily-quiz-item-header">
      <QuizImage quiz={quiz} />
      {quizResult && (
        <View style={styles.resultContainer}>
          <QuizResultView
            isCorrect={quizResult.isCorrect}
            title={
              quizResult.isCorrect ? t('quiz.correct') : t('quiz.incorrect')
            }
            subtitle={
              quizResult.isCorrect
                ? t('quiz.plusPoint', { number: 5 })
                : t('quiz.noPoints', { number: 0 })
            }
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  resultContainer: {
    height: 200,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default DailyQuizItemHeader
