import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { includes } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import {
  localCategories,
  QuestionQueryParams,
  QuestionSortKeys,
} from '~/api/questions'
import SearchInput from '~/components/common/atoms/SearchInput'
import MultipleSelectDialog from '~/components/common/organisms/MultipleSelectDialog'
import TopTabNavigator, {
  TopTabNavigatorData,
} from '~/components/common/organisms/TopTabNavigator'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import SelectListItem from '~/interfaces/SelectListItem'
import i18n from '~/plugins/i18n'
import { getCategoriesFromRoute, getRouteParams } from '~/utils/navigation'

type SelectableCategory = {
  id: string
  name: string
}

type RouteParams = { q?: string }

const ForumHeaderMobile: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const questionQueryParams = getRouteParams<QuestionQueryParams>()
  const topTabQuestion = [
    { label: t('mostRecent'), key: QuestionSortKeys.BY_CREATED_AT },
    { label: t('mostLiked'), key: QuestionSortKeys.BY_LIKES_COUNT },
  ]

  const [selectedQuestionTab, setSelectedQuestionTab] = useState<
    TopTabNavigatorData
  >()

  const categories = getCategoriesFromRoute(questionQueryParams.categories)

  const [selectedCategories, setSelectedCategories] = useState<
    SelectListItem[]
  >([])

  const selectableCategoriesData: SelectableCategory[] = localCategories.map(
    (item) => ({
      id: item.name,
      name: i18n.language === 'ja' ? item.title : item.enTitle,
    })
  )

  const route = useRoute()
  const searchWordFromRoute =
    (camelcaseKeys(route.params ?? {}) as RouteParams).q ?? ''
  const [searchWord, setSearchWord] = useState(searchWordFromRoute)

  const submitSearch = (): void => {
    setQueryParams({ q: searchWord })
  }

  const updateSelectedCategories = (categories: SelectListItem[]): void => {
    setSelectedCategories(categories)
    setQueryParams({
      categories: categories.map((item) => item.id.toString()),
    })
  }

  const changeSelectedQuestionTab = (item: TopTabNavigatorData): void => {
    setSelectedQuestionTab(item)
    setQueryParams({ ...questionQueryParams, sortBy: item.key, page: 1 })
  }

  useEffect(() => {
    questionQueryParams.categories &&
      setSelectedCategories(
        selectableCategoriesData.filter((category) =>
          includes(categories, category.id)
        )
      )
  }, [questionQueryParams?.categories])

  useEffect(() => {
    questionQueryParams?.sortBy === topTabQuestion[1].key
      ? setSelectedQuestionTab(topTabQuestion[1])
      : setSelectedQuestionTab(topTabQuestion[0])
  }, [questionQueryParams.sortBy])

  return (
    <View testID="forum-header-mobile">
      <View style={styles.container}>
        <View style={styles.authGuide}>
          <SearchInput
            value={searchWord}
            onSubmit={submitSearch}
            onChangeText={setSearchWord}
            placeholderKey={t('forum.searchInputLabel')}
          />
        </View>
      </View>
      <View style={styles.categoryFilter} testID="filter-container">
        <MultipleSelectDialog
          isPC={false}
          title={t('categories')}
          data={selectableCategoriesData}
          selectedItems={selectedCategories}
          onChange={updateSelectedCategories}
        />
      </View>
      <TopTabNavigator
        isPC={false}
        data={topTabQuestion}
        selectedItem={selectedQuestionTab}
        onSelectTab={changeSelectedQuestionTab}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  authGuide: {
    padding: 24,
    width: '100%',
  },
  categoryFilter: {
    backgroundColor: color.white,
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 15,
    paddingHorizontal: 24,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.primary,
    flexDirection: 'row',
    height: 90,
  },
})

export default ForumHeaderMobile
