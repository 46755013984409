import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'

import CheckIcon from '~/components/common/atoms/CheckIcon'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { getCurrentLanguage, SupportedLanguage } from '~/plugins/i18n'

export interface PricingCompare {
  id: string
  title: string
  checks?: boolean[]
}

export enum PricingCompareType {
  MESSAGING_CANDIDATES = 'messaging_candidates',
  JOB_LISTING = 'job_listings',
  CANDIDATE_RECOMMENDATION = 'candidate_recommendation',
  ENJIN_JOB_POSTING_SERVICE = 'enjin_job_posting_service',
  ENJIN_OFF_NETWORKING = 'enjin_offline_networking',
}

type Props = {
  pricingCards?: SubscriptionPlan[]
  isPC: boolean
  currentPlan?: number
}

export const SubscriptionPlanCompare = ({
  pricingCards,
  isPC,
  currentPlan,
}: Props) => {
  const { t } = useTranslation()

  const marginHorizontal = isPC ? styles.marginHorizontal : undefined

  const pricingCompareData: PricingCompare[] = [
    {
      id: PricingCompareType.MESSAGING_CANDIDATES,
      title: t('subscriptions.messagingCandidates'),
    },
    {
      id: PricingCompareType.JOB_LISTING,
      title: t('subscriptions.jobListings'),
    },
    {
      id: PricingCompareType.CANDIDATE_RECOMMENDATION,
      title: t('subscriptions.candidateRecommendations'),
      checks: [false, false, true, true],
    },
    {
      id: PricingCompareType.ENJIN_JOB_POSTING_SERVICE,
      title: t('subscriptions.enjinJobPostingService'),
      checks: [false, false, false, true],
    },
    {
      id: PricingCompareType.ENJIN_OFF_NETWORKING,
      title: t('subscriptions.enjinOfflineNetworking'),
      checks: [false, false, false, true],
    },
  ]

  return (
    <View
      style={[styles.container, styles.paddingBottom]}
      testID="pricing-compare-detail"
    >
      <View style={styles.container}>
        <Text style={styles.title}>
          {t('subscriptions.exploreAndCompareYourOptions')}
        </Text>
      </View>
      <ScrollView
        horizontal={true}
        contentContainerStyle={styles.scrollViewContainer}
      >
        <View style={styles.table}>
          <View style={[styles.row, marginHorizontal]}>
            <Text style={styles.header}></Text>
            {pricingCards?.map((item, index) => (
              <Text style={styles.header} key={index}>
                <Text>
                  {getCurrentLanguage() === SupportedLanguage.JA
                    ? item.name
                    : item.enName}
                </Text>
                {item.id === currentPlan && (
                  <Text style={styles.currentPlan}>
                    {t('pricingCard.currentPlan')}
                  </Text>
                )}
              </Text>
            ))}
          </View>
          {pricingCompareData.map((item, key) => {
            switch (item.id) {
              case PricingCompareType.MESSAGING_CANDIDATES:
                return (
                  <View
                    style={[
                      styles.row,
                      styles.bgColorLightGray,
                      marginHorizontal,
                    ]}
                    key={key}
                  >
                    <Text style={styles.cellFirst}>{item.title}</Text>
                    {pricingCards?.map((item, index) => (
                      <Text style={styles.cellText} key={index}>
                        {item.maxMessages === 0
                          ? t('subscriptions.unlimited')
                          : `${item.maxMessages}${' '}${t('menu.messages')}`}
                      </Text>
                    ))}
                  </View>
                )
              case PricingCompareType.JOB_LISTING:
                return (
                  <View style={[styles.row, marginHorizontal]} key={key}>
                    <Text style={styles.cellFirst}>{item.title}</Text>
                    {pricingCards?.map((item, index) => (
                      <Text style={styles.cellText} key={index}>
                        {item.maxPosts}/
                        {item.period === 'month'
                          ? t('pricingCard.month')
                          : t('pricingCard.year')}
                      </Text>
                    ))}
                  </View>
                )
              default:
                return (
                  <View
                    style={[
                      styles.row,
                      key % 2 === 0 ? styles.bgColorLightGray : undefined,
                      marginHorizontal,
                    ]}
                    key={key}
                  >
                    <Text style={styles.cellFirst}>{item.title}</Text>
                    {item.checks?.map((isCheck, index) => {
                      return (
                        <Text style={styles.cell} key={index}>
                          {isCheck ? <CheckIcon /> : <></>}
                        </Text>
                      )
                    })}
                  </View>
                )
            }
          })}
        </View>
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  bgColorLightGray: {
    backgroundColor: color.lightGray,
  },
  cell: {
    flex: 1,
    padding: 10,
    textAlign: 'center',
    height: 60,
  },
  cellFirst: {
    flex: 1,
    padding: 10,
    fontWeight: 'bold',
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cellText: {
    flex: 1,
    padding: 10,
    textAlign: 'center',
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: color.white,
    paddingVertical: 20,
  },
  currentPlan: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    fontWeight: 'bold',
  },
  header: {
    flex: 1,
    padding: 10,
    fontWeight: 'bold',
    height: 60,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  marginHorizontal: {
    marginHorizontal: 100,
  },
  paddingBottom: {
    paddingBottom: 60,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollViewContainer: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  table: {
    backgroundColor: color.white,
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})
