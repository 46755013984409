import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  visible: boolean
  onPressOk?: () => void
  onPressUpgrade?: () => void
}

const ExpirationDialog: React.FC<Props> = ({
  visible,
  onPressOk,
  onPressUpgrade,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ModalContainer visible={visible}>
      <View style={styles.container} testID="expiration-dialog">
        <TitleText>{t('company.subscription.expiration_title')}</TitleText>
        <Text style={styles.message} testID="confirmation-dialog-message">
          {t('company.subscription.expiration_content')}
        </Text>
        <View style={styles.actionContainer}>
          <Button
            title={t('ok')}
            testID="ok-button"
            onPress={onPressOk}
            containerStyle={StyleSheet.flatten([styles.button])}
          />
          <Button
            buttonStyle={StyleSheet.flatten([
              styles.button,
              { backgroundColor: color.secondary },
            ])}
            testID="upgrade-button"
            onPress={onPressUpgrade}
            title={t('company.subscription.upgrade_button')}
          />
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    width: 280,
  },
  button: {
    alignSelf: 'center',
    marginLeft: 15,
    marginRight: 15,
    maxWidth: 150,
    minWidth: 110,
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    flex: 1,
    maxWidth: 600,
    alignItems: 'center',
    padding: 40,
  },
  message: {
    marginTop: 20,
    flexWrap: 'wrap',
    flex: 1,
    fontSize: FontSize.REMARKABLE,
  },
})

export default ExpirationDialog
