import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import CareerHeaderLeft from '~/components/career/molecules/headers/CareerHeaderLeft'
import CareerHeaderRight from '~/components/career/molecules/headers/CareerHeaderRight'
import AuthenticationHeaderLeft from '~/components/common/molecules/headers/AuthenticationHeaderLeft'
import AuthenticationHeaderRight from '~/components/common/molecules/headers/AuthenticationHeaderRight'
import TopPageHeaderLeft from '~/components/common/molecules/headers/TopPageHeaderLeft'
import TopPageHeaderRight from '~/components/common/molecules/headers/TopPageHeaderRight'
import CommunityHeaderLeft from '~/components/community/molecules/headers/CommunityHeaderLeft'
import CommunityHeaderRight from '~/components/community/molecules/headers/CommunityHeaderRight'
import ForumHeaderLeft from '~/components/forum/molecules/headers/ForumHeaderLeft'
import ForumHeaderRight from '~/components/forum/molecules/headers/ForumHeaderRight'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

const Stack = createStackNavigator()

const defaultScreenOptions = {
  headerStyle: {
    backgroundColor: color.white,
    ...commonStyles.shadow,
  },
  headerTintColor: color.textGray,
  headerTitle: null,
  headerShown: true,
  animationEnabled: false,
  title: '',
}

const careerHeaderLeft = (): React.ReactNode => <CareerHeaderLeft />
const careerHeaderRight = (): React.ReactNode => <CareerHeaderRight />
const forumHeaderLeft = (): React.ReactNode => <ForumHeaderLeft />
const forumHeaderRight = (): React.ReactNode => <ForumHeaderRight />
const communityHeaderLeft = (): React.ReactNode => <CommunityHeaderLeft />
const communityHeaderRight = (): React.ReactNode => <CommunityHeaderRight />
const topPageHeaderLeft = (): React.ReactNode => <TopPageHeaderLeft />
const topPageHeaderRight = (): React.ReactNode => <TopPageHeaderRight />

const authenticationHeaderRight = (): React.ReactNode => (
  <AuthenticationHeaderRight />
)

const authenticationHeaderLeft = (): React.ReactNode => (
  <AuthenticationHeaderLeft />
)

export const appScreenOptions = {
  career: {
    ...defaultScreenOptions,
    headerLeft: careerHeaderLeft,
    headerRight: careerHeaderRight,
  },
  forum: {
    ...defaultScreenOptions,
    headerLeft: forumHeaderLeft,
    headerRight: forumHeaderRight,
  },
  community: {
    ...defaultScreenOptions,
    headerLeft: communityHeaderLeft,
    headerRight: communityHeaderRight,
  },
  authentication: {
    ...defaultScreenOptions,
    headerLeft: authenticationHeaderLeft,
    headerRight: authenticationHeaderRight,
  },
  topPage: {
    ...defaultScreenOptions,
    headerLeft: topPageHeaderLeft,
    headerRight: topPageHeaderRight,
  },
}

type Props = {
  initialRouteName: string
  children: React.ReactNode
}

const Navigator: React.FC<Props> = ({ initialRouteName, children }: Props) => (
  <Stack.Navigator initialRouteName={initialRouteName}>
    {children}
  </Stack.Navigator>
)

export default { Navigator, Screen: Stack.Screen, Group: Stack.Group }
