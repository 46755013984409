import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  value?: string
  isInvert?: boolean
  onPress?: () => void
}

const MultipleSelectionTriggerButton: React.FC<Props> = ({
  value,
  onPress,
  isInvert,
}: Props) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        isInvert ? { borderColor: color.borderSeparatorDark } : undefined,
      ]}
      testID="multiple-selection-trigger-button"
    >
      <Text
        style={[
          styles.value,
          isInvert ? { color: color.borderSeparatorDark } : undefined,
        ]}
        testID="trigger-button-value"
        numberOfLines={1}
      >
        {value}
      </Text>
      <Icon
        size={15}
        name="sliders"
        testID="filter-icon"
        style={styles.rightIcon}
        color={isInvert ? color.borderSeparatorDark : color.primary}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderWidth: 1,
    ...commonStyles.borderRadiusAllCorner,
    borderColor: color.primary,
    paddingHorizontal: 14,
    paddingVertical: 8,
  },
  rightIcon: {
    alignSelf: 'center',
  },
  value: {
    alignSelf: 'center',
    color: color.primary,
    flexWrap: 'wrap',
    flex: 1,
    paddingRight: 4,
  },
})

export default MultipleSelectionTriggerButton
