import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC?: boolean
}

const FooterAddress: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()

  const textStyles = isPC ? styles.text : styles.textMobile
  const titleStyles = isPC ? styles.title : styles.titleMobile

  return (
    <View testID="footer-address">
      <TitleText style={titleStyles}>{t('footer.addressTitle')}</TitleText>
      <Text style={StyleSheet.flatten([textStyles, styles.withBold])}>
        Tokyo Office
      </Text>
      <Text style={textStyles}>〒150-0021 Tokyo,</Text>
      <Text style={textStyles}>Shibuya City, Ebisunishi,</Text>
      <Text style={textStyles}>1 Chrome-33番6号 1F 2F Noie</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    marginTop: 12,
  },
  textMobile: {
    color: color.white,
    marginTop: 6,
  },
  title: {
    color: color.white,
    fontSize: 27,
    marginTop: 12,
  },
  titleMobile: {
    color: color.white,
    fontSize: 22,
    marginTop: 12,
  },
  withBold: {
    fontWeight: 'bold',
  },
})

export default FooterAddress
