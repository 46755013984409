import { some } from 'lodash'
import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

import CheckBox from '~/components/workarounds/CheckBox'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import SelectListItem from '~/interfaces/SelectListItem'

type Props = {
  data: SelectListItem[]
  selectedItems: SelectListItem[]
  onSelect: (items: SelectListItem[]) => void
}

const MultipleSelectList: React.FC<Props> = ({
  data,
  onSelect,
  selectedItems,
}: Props) => {
  const toggleSelectedItem = (item: SelectListItem): void => {
    const newSelectedItems = some(selectedItems, { id: item.id })
      ? selectedItems.filter((selectedItem) => item.id !== selectedItem.id)
      : [...selectedItems, item]
    onSelect && onSelect(newSelectedItems)
  }

  const renderItem = (item: SelectListItem): React.ReactNode => (
    <TouchableOpacity
      key={item.id}
      testID="multiple-select-list-item"
      onPress={(): void => toggleSelectedItem(item)}
    >
      <CheckBox
        disabled
        size={16}
        key={item.id}
        title={item.name}
        containerStyle={styles.checkbox}
        textStyle={styles.checkboxTitle}
        checked={some(selectedItems, { id: item.id })}
      />
      <View style={styles.bottomLine} />
    </TouchableOpacity>
  )

  return (
    <View testID="multiple-select-list">
      {data.map((item) => renderItem(item))}
    </View>
  )
}

const styles = StyleSheet.create({
  bottomLine: {
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
  },
  checkbox: {
    backgroundColor: color.transparent,
    borderWidth: 0,
  },
  checkboxTitle: {
    fontSize: FontSize.GENERAL,
    fontWeight: '300',
  },
})

export default MultipleSelectList
