import { first, isEmpty, isNil, map } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import formOfContractData from '~/assets/data/formOfContract.json'
import hiringData from '~/assets/data/jobHiring.json'
import JobBaseSubLayout from '~/components/career/molecules/jobs/JobBaseSubLayout'
import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import FormAction from '~/components/common/atoms/users/FormAction'
import LocationPicker, {
  getPrefecturesData,
} from '~/components/common/molecules/LocationPicker'
import TextField from '~/components/common/molecules/TextField'
import ProfileCard from '~/components/common/molecules/users/ProfileCard'
import MultipleSelectDialog from '~/components/common/organisms/MultipleSelectDialog'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Recruitment, { FormOfContract, Hiring } from '~/interfaces/Recruitment'
import SelectListItem from '~/interfaces/SelectListItem'
import i18n, { getCurrentLanguage } from '~/plugins/i18n'

type Props = {
  isPC: boolean
  job: Partial<Recruitment>
  editable?: boolean
  onSubmit?: (value: Partial<Recruitment>) => void
}

const JobBasicInfo: React.FC<Props> = ({
  job,
  isPC,
  editable,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const language = getCurrentLanguage()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedHiring, setSelectedHiring] = useState<SelectListItem[]>([])
  const [formOfContracts, setFormOfContracts] = useState<SelectListItem[]>([])
  const [workHour, setWorkHour] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [selectedLocationLabel, setSelectedLocationLabel] = useState<string>('')
  const locations = getPrefecturesData(i18n.language, t)

  const selectFormOfContract = useCallback(
    (data: Pick<SelectListItem, 'id'>[]) => {
      const selectedItems = Object.values(formOfContractData).filter(
        (item) =>
          !isNil(
            data.find(
              (selectListItem) => selectListItem.id === item[language].id
            )
          )
      )
      setFormOfContracts(selectedItems.map((item) => item[language]))
    },
    []
  )

  const selectHiring = useCallback((data: Pick<SelectListItem, 'id'>[]) => {
    const selectedItems = Object.values(hiringData).filter(
      (item) =>
        !isNil(
          data.find((selectListItem) => selectListItem.id === item[language].id)
        )
    )
    setSelectedHiring(selectedItems.map((item) => item[language]))
  }, [])

  useEffect(() => {
    if (editable) {
      setWorkHour(job.workTime ?? '')
      selectFormOfContract(map(job.formOfContract, (id) => ({ id })) ?? [])
      selectHiring(map(job.hiring, (id) => ({ id })) ?? [])
      const location = locations.filter((item) => item.value === job.location)
      setSelectedLocationLabel(first(location)?.label.toString() ?? '')
      setSelectedLocation(first(location)?.value.toString() ?? '')
    }
  }, [job, editable, selectFormOfContract, selectHiring])

  const submit = async (): Promise<void> => {
    onSubmit?.({
      hiring: selectedHiring.map((item) => item.id) as Hiring[],
      location: selectedLocation,
      formOfContract: formOfContracts.map(
        (item) => item.id
      ) as FormOfContract[],
      workTime: workHour,
    })
    setIsEditing(false)
  }

  const changeLocation = (location: string): void => {
    setSelectedLocation(location)
  }

  return (
    <JobInfoBaseLayout isPC={isPC} title={t('profile.basicInfo')}>
      <View
        style={isPC ? styles.wrapper : styles.wrapperMobile}
        testID="job-basic-info"
      >
        {(!isEmpty(job.hiring) || editable) && (
          <>
            {isEditing ? (
              <JobBaseSubLayout
                content={selectedLocationLabel}
                title={t('jobs.workLocation')}
                contentComponent={
                  isEditing && (
                    <View style={styles.inputWrapper}>
                      <LocationPicker
                        onChange={changeLocation}
                        selected={selectedLocation}
                      />
                    </View>
                  )
                }
              />
            ) : (
              <ProfileCard
                icon=""
                title={t('jobs.workLocation')}
                description={selectedLocationLabel}
              />
            )}
          </>
        )}
        {(!isEmpty(job.hiring) || editable) && (
          <>
            {isEditing ? (
              <JobBaseSubLayout
                content={
                  job.hiring
                    ?.map((item) => hiringData[item][language].name)
                    .join('\n') ?? ''
                }
                title={t('jobs.jobHiring')}
                contentComponent={
                  isEditing && (
                    <View style={styles.inputWrapper}>
                      <MultipleSelectDialog
                        isInvert
                        isPC={isPC ?? true}
                        onChange={selectHiring}
                        title={t('jobs.jobHiring')}
                        data={Object.values(hiringData).map((item) => ({
                          id: item[language].id,
                          name: item[language].name,
                        }))}
                        placeholder={`${t('pleaseSelect')} ${t(
                          'jobs.jobHiring'
                        )}`}
                        selectedItems={selectedHiring}
                      />
                    </View>
                  )
                }
              />
            ) : (
              <ProfileCard
                icon=""
                title={t('jobs.jobHiring')}
                description={
                  job.hiring
                    ?.map((item) => hiringData[item][language].name)
                    .join(isPC ? '\n' : ', ') ?? ''
                }
              />
            )}
          </>
        )}
        {(!isEmpty(job.formOfContract) || editable) && (
          <>
            {isEditing ? (
              <JobBaseSubLayout
                content={
                  job.formOfContract
                    ?.map((item) => formOfContractData?.[item]?.[language].name)
                    .join('\n') ?? ''
                }
                title={t('jobs.jobType')}
                contentComponent={
                  isEditing && (
                    <View style={styles.inputWrapper}>
                      <MultipleSelectDialog
                        isInvert
                        isPC={isPC ?? true}
                        onChange={selectFormOfContract}
                        title={t('jobs.jobType')}
                        data={Object.values(formOfContractData).map((item) => ({
                          id: item[language].id,
                          name: item[language].name,
                        }))}
                        placeholder={`${t('pleaseSelect')} ${t(
                          'jobs.jobType'
                        )}`}
                        selectedItems={formOfContracts}
                      />
                    </View>
                  )
                }
              />
            ) : (
              <ProfileCard
                icon=""
                title={t('jobs.jobType')}
                description={
                  job.formOfContract
                    ?.map((item) => formOfContractData?.[item]?.[language].name)
                    .join(isPC ? '\n' : ', ') ?? ''
                }
              />
            )}
          </>
        )}
        {(job.workTime || editable) && (
          <>
            {isEditing ? (
              <JobBaseSubLayout
                content={job.workTime}
                title={t('jobs.workHour')}
                contentComponent={
                  isEditing && (
                    <View style={styles.inputWrapper}>
                      <TextField
                        value={workHour}
                        onChangeText={setWorkHour}
                        placeholder={t('jobs.7amTo8pm')}
                      />
                    </View>
                  )
                }
              />
            ) : (
              <ProfileCard
                icon=""
                title={t('jobs.workHour')}
                description={job.workTime || ''}
              />
            )}
          </>
        )}
      </View>
      {editable && (
        <View style={styles.btnSection} testID="action-button-section">
          {isEditing ? (
            <FormAction
              onCancel={() => setIsEditing(false)}
              isLoading={false}
              disabled={!isEditing}
              onSubmit={submit}
            />
          ) : (
            <Button
              type="solid"
              title={t('edit')}
              onPress={(): void => setIsEditing(true)}
              buttonStyle={[
                styles.btnContainer,
                { backgroundColor: color.primary },
              ]}
              titleStyle={[commonStyles.buttonTextSize, { color: color.white }]}
            />
          )}
        </View>
      )}
    </JobInfoBaseLayout>
  )
}

const styles = StyleSheet.create({
  btnContainer: {
    height: 30,
    width: 90,
  },
  btnSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputWrapper: {
    marginTop: 4,
  },
  wrapper: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
    flexWrap: 'wrap',
  },
  wrapperMobile: {
    marginTop: 16,
    flexDirection: 'column',
    gap: 16,
  },
})

export default JobBasicInfo
