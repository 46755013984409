import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  secureTextEntry: boolean
  onPress?: () => void
}

type PasswordVisibilityIcon = 'eye-slash' | 'eye'

const PasswordVisibilitySwitch: React.FC<Props> = (props: Props) => {
  let iconName: PasswordVisibilityIcon = props.secureTextEntry
    ? 'eye-slash'
    : 'eye'

  useEffect(() => {
    iconName = props.secureTextEntry ? 'eye-slash' : 'eye'
  }, [props.secureTextEntry])

  return (
    <TouchableOpacity onPress={props.onPress} testID="password-right-icon">
      <Icon
        size={20}
        testID="icon"
        name={iconName}
        style={styles.icon}
        color={color.textGray}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginRight: 10,
  },
})

export default PasswordVisibilitySwitch
