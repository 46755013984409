import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import EditableText from '~/components/common/atoms/EditableText'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Community from '~/interfaces/Community'

type Props = {
  community: Community
  isAdmin?: boolean
  isPC: boolean
  isLoading?: boolean
  isSuccess?: boolean
  error?: string | null
  onUpdateCommunity?: (data: Partial<Community>) => void
}

type SelfIntroductionHook = {
  content?: string
  setContent: (text?: string) => void
  editable: boolean
  setEditable: (editable: boolean) => void
}

export const useSelfIntroduction = (
  community?: Community
): SelfIntroductionHook => {
  const [content, setContent] = useState(community?.description)
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    setContent(community?.description)
  }, [community?.id])

  return {
    content,
    setContent,
    editable,
    setEditable,
  }
}

const SelfIntroduction: React.FC<Props> = ({
  community,
  isAdmin,
  isPC,
  isLoading,
  isSuccess,
  error,
  onUpdateCommunity,
}: Props) => {
  const { t } = useTranslation()

  const errorMessage = t('failedToUpdate')

  const { editable, setEditable } = useSelfIntroduction()
  const { content, setContent } = useSelfIntroduction(community)

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setEditable(false)
    }
  }, [isLoading, isSuccess])

  const containerStyle = isPC ? styles.container : styles.containerMobile

  const editContent = (): void => {
    setContent(community?.description)
    setEditable(true)
  }

  const cancelEdit = (): void => {
    setEditable(false)
    setContent(community?.description)
  }

  const submitCommunityDetail = async (): Promise<void> => {
    const updateData = { ...community, description: content }
    onUpdateCommunity && onUpdateCommunity(updateData)
  }

  const selfIntroductionTitle = t('community.information.about', {
    name: community?.name,
  })

  return (
    <CardContainer style={containerStyle}>
      <Text style={styles.title}>{selfIntroductionTitle}</Text>
      <View style={styles.introductionContainer}>
        <EditableText
          multiline
          value={content}
          editable={editable}
          busyMode={isLoading}
          onChangeText={setContent}
        />
      </View>

      <Text style={styles.error} testID="error-message">
        {error ? errorMessage : ''}
      </Text>
      {isAdmin && (
        <View
          style={styles.textInputButtonAction}
          testID="text-input-action-container"
        >
          <TextInputAction
            onCancelPress={cancelEdit}
            onEditPress={editContent}
            isSaveLoading={isLoading}
            isNormalMode={!editable}
            isEdit={true}
            onSavePress={submitCommunityDetail}
          />
        </View>
      )}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 30,
    paddingVertical: 20,
    height: '100%',
    marginTop: 10,
  },
  containerMobile: {
    backgroundColor: color.white,
    marginTop: 10,
    padding: 10,
  },
  error: {
    color: color.badgeColor,
    fontSize: FontSize.SUB,
    paddingTop: 4,
  },
  introductionContainer: {
    marginTop: 10,
  },
  textInputButtonAction: {
    alignSelf: 'flex-end',
  },
  title: {
    color: color.unpressableTitleText,
    fontWeight: 'bold',
  },
})

export default SelfIntroduction
