import { RouteProp, useRoute } from '@react-navigation/native'
import { isNil, isUndefined } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CompanyAvatar from '~/components/career/molecules/company/CompanyAvatar'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import EditableTextForm from '~/components/common/molecules/EditableTextForm'
import HeaderSectionMenu from '~/components/common/molecules/HeaderSectionMenu'
import ProfileBanner from '~/components/common/molecules/ProfileBanner'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'
import SkeletonProfileHeader from '~/components/common/organisms/SkeletonProfileHeader'
import { TopTabNavigatorData } from '~/components/common/organisms/TopTabNavigator'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { CompanySection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import Company from '~/interfaces/Company'
import Media from '~/interfaces/Media'
import { MainStackParamList } from '~/interfaces/RootStackParamList'
import {
  currentCompanySelector,
  updateCurrentCompanyInfo,
} from '~/slices/career/company'
const STATUS_MAX_LENGTH = 220

type Props = {
  hasSection?: boolean
  isAdmin: boolean
  company?: Company
}

const HeaderCompany: React.FC<Props> = ({
  hasSection = true,
  isAdmin,
  company,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const currentCompany = !isUndefined(company)
    ? company
    : useSelector(currentCompanySelector)
  const route = useRoute<RouteProp<MainStackParamList>>()
  const companyId = (route.params as { id: number })?.id

  const updateAvatar = (media: Media[]): void => {
    currentCompany?.id &&
      dispatch(
        updateCurrentCompanyInfo(currentCompany.id, {
          company: { imageId: media[0].id },
        })
      )
  }

  const updateStatus = (value: string): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      Promise.all([
        dispatch(
          updateCurrentCompanyInfo(currentCompany!.id, {
            company: { status: value },
          })
        ),
      ])
        .then(() => resolve())
        .catch((error) => reject(error))
    })

  const sectionData: TopTabNavigatorData[] =
    !isAdmin && currentCompany?.id === companyId
      ? [
          {
            label: t('company.section.companyProfile'),
            key: CompanySection.PROFILES,
            element: undefined,
          },
          {
            label: t('company.section.recruitments'),
            key: CompanySection.RECRUITMENTS,
            element: undefined,
          },
        ]
      : route.name === 'jobsDashboard'
      ? [
          {
            label: t('company.section.jobPosts'),
            key: CompanySection.JOB_POSTS,
            element: undefined,
          },
          {
            label: t('company.section.overview'),
            key: CompanySection.OVERVIEW,
            element: undefined,
          },
        ]
      : [
          {
            label: t('company.section.companyProfile'),
            key: CompanySection.PROFILES,
            element: undefined,
          },
          {
            label: t('company.section.recruitments'),
            key: CompanySection.RECRUITMENTS,
            element: undefined,
          },
          // Hide unfinished features
          // {
          //   label: t('company.section.applicants'),
          //   key: CompanySection.APPLICANTS,
          //   element: undefined,
          // },
          {
            label: t('company.section.overview'),
            key: CompanySection.OVERVIEW,
            element: undefined,
          },
          {
            label: t('company.section.accountManagement'),
            key: CompanySection.ACCOUNT_MANAGEMENT,
            element: undefined,
          },
        ]

  if (isNil(currentCompany)) {
    return <SkeletonProfileHeader isPC={isPC ?? true} />
  }

  if (!currentCompany) {
    return null
  }

  const infoComponent = (
    <>
      <ProfileInfo
        isPC={isPC ?? true}
        avatarComponent={
          <CompanyAvatar
            company={currentCompany}
            viewable={true}
            showBorder={true}
            editable={isAdmin}
            size={ProfileAvatarSize.GIGANTIC}
            onUpload={updateAvatar}
          />
        }
        rightComponent={
          <View style={styles.rightComponentContainer}>
            <Text style={styles.companyTitle}>{currentCompany.name}</Text>
            <EditableTextForm
              value={currentCompany.status}
              editable={isAdmin}
              onSubmit={updateStatus}
              textSize={FontSize.SUB}
              maxLength={STATUS_MAX_LENGTH}
              hintText={t('banner.noneStatus')}
            />
          </View>
        }
      />
    </>
  )

  return (
    <View testID="header-company" style={isPC ? styles.container : null}>
      <ProfileHeader
        isPC={isPC ?? true}
        bannerComponent={<ProfileBanner />}
        infoComponent={infoComponent}
        footerLeftComponent={
          <View>
            {hasSection ? (
              <HeaderSectionMenu
                data={sectionData}
                isPC={isPC ?? true}
                defaultSection={sectionData[0].key as CompanySection}
              />
            ) : null}
          </View>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  companyTitle: {
    fontSize: FontSize.IMPORTANT,
    color: color.pressableText,
  },
  container: {
    alignSelf: 'center',
    marginTop: 10,
    width: 814,
  },
  rightComponentContainer: {
    flex: 1,
    marginTop: -10,
  },
})

export default HeaderCompany
