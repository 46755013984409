import { useNavigation } from '@react-navigation/native'
import { MediaTypeOptions } from 'expo-image-picker'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Image } from 'react-native'

import api from '~/api'
import { QuestionFormData } from '~/api/questions'
import imageIcon from '~/assets/images/icons/picture.png'
import CardContainer from '~/components/common/atoms/CardContainer'
import CategoryForm from '~/components/common/molecules/CategoryForm'
import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import TextField from '~/components/common/molecules/TextField'
import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Media from '~/interfaces/Media'
import Question from '~/interfaces/Question'
import { filterImageIds } from '~/utils/common/media'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isEdit?: boolean
  question?: Question
}

const QuestionEditForm: React.FC<Props> = ({ isEdit, question }: Props) => {
  const { t } = useTranslation()
  const titleMaxLength = 140
  const bodyMaxLength = 1500
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const toast = useCustomToast()
  const [isValidData, setIsValidData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const [questionData, setQuestionData] = useState<Partial<QuestionFormData>>(
    { ...question, media: question?.images } || {}
  )

  const updateQuestionData = (data: Partial<QuestionFormData>): void => {
    setQuestionData({ ...questionData, ...data })
  }

  useEffect(() => {
    !isEmpty(questionData.title) &&
    questionData.title &&
    questionData.title.length <= titleMaxLength &&
    !isEmpty(questionData.body) &&
    questionData.body &&
    questionData.body.length <= bodyMaxLength
      ? setIsValidData(true)
      : setIsValidData(false)
  }, [questionData.title, questionData.body])

  const submit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      let response
      questionData.categories = questionData.categories ?? []
      if (isEdit) {
        response = await api.questions.update(questionData)
      } else {
        response = await api.questions.create(questionData)
      }
      setQuestionData({})

      setIsLoading(false)
      navigate('questionShow', { id: response.id })
    } catch (error) {
      toast.showError(error)
      setIsLoading(false)
    }
  }

  const updateMedia = (media: Media[]): void => {
    updateQuestionData({ media })
  }

  const removeMedia = (media?: Media): void => {
    updateQuestionData({
      removedImageIds: filterImageIds(questionData?.media ?? [], media),
      media: media
        ? questionData.media!.filter((item: Media) =>
            media.id ? item.id !== media.id : item.signedId != media.signedId
          )
        : [],
    })
  }

  return (
    <CardContainer style={styles.cardContainer} testID="question-edit-form">
      <Text testID="question-form-header" style={styles.header}>
        {isEdit ? t('forum.updateQuestion') : t('forum.addQuestion')}
      </Text>
      <View style={styles.verticalSpace} />
      <TextField
        maxLength={titleMaxLength}
        value={questionData.title}
        title={t('forum.questionTitleLabel')}
        placeholder={t('forum.questionTitlePlaceholder')}
        onChangeText={(value): void => updateQuestionData({ title: value })}
      />
      <View style={styles.verticalSpace} />
      <TextField
        maxLength={bodyMaxLength}
        numberOfLines={7}
        value={questionData.body}
        title={t('forum.questionBodyLabel')}
        placeholder={t('forum.questionBodyPlaceholder')}
        onChangeText={(value): void => updateQuestionData({ body: value })}
      />
      <View style={styles.verticalSpace} />
      {!!questionData?.media?.length && (
        <MediaPreview
          retry={true}
          onRemove={removeMedia}
          media={questionData?.media ?? []}
        />
      )}
      <ImagePickerButton
        type="clear"
        isDirectUpload
        kind="question"
        title={t('forum.addImage')}
        onUpload={updateMedia}
        loading={isLoadingMedia}
        onLoading={setIsLoadingMedia}
        allowsMultipleSelection={true}
        mediaTypes={MediaTypeOptions.Images}
        containerStyle={styles.imagePickerButton}
        titleStyle={styles.imagePickerButtonTitle}
        icon={<Image style={styles.icon} source={imageIcon} />}
      />
      <View style={styles.verticalSpace} />
      <CategoryForm
        values={questionData.categories || []}
        onChange={(categories): void => updateQuestionData({ categories })}
      />
      <View style={styles.verticalSpace} />
      <View style={styles.verticalSpace} />
      <Button
        onPress={submit}
        titleStyle={styles.submitTitle}
        testID="question-edit-form-submit"
        disabled={!isValidData || isLoading}
        buttonStyle={StyleSheet.flatten([styles.submit])}
        title={isEdit ? t('forum.saveQuestion') : t('forum.postQuestion')}
      />
    </CardContainer>
  )
}
const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: color.white,
    padding: 50,
  },
  header: {
    ...commonStyles.titleTextSize,
    color: color.unpressableTitleText,
    fontWeight: 'bold',
  },
  icon: {
    height: 15,
    width: 15,
  },
  imagePickerButton: {
    alignSelf: 'flex-start',
  },
  imagePickerButtonTitle: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
    marginLeft: 6,
  },
  submit: {
    backgroundColor: color.primary,
  },
  submitTitle: {
    ...commonStyles.buttonTextSize,
  },
  verticalSpace: {
    height: 16,
  },
})

export default QuestionEditForm
