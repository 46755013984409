import { Picker as RNPicker } from '@react-native-picker/picker'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import DropdownButton from '~/components/common/atoms/DropdownButton'
import { PickerProps } from '~/components/common/molecules/CustomPicker'
import Picker from '~/components/workarounds/Picker'
import color, { ColorTheme } from '~/constants/common/color'

type Props = {
  colorTheme?: ColorTheme
  size: 'small' | 'medium' | 'large' | 'full' | 'none'
} & PickerProps

const PickerAndroid: React.FC<Props> = ({
  data,
  colorTheme,
  onValueChange,
  selectedValue,
}: Props) => {
  const { t } = useTranslation()
  const primaryColor =
    colorTheme === ColorTheme.Primary
      ? color.primary
      : colorTheme === ColorTheme.Light
      ? color.white
      : color.textGray

  const pickerRef = React.useRef<RNPicker<ItemValue>>()

  const showPicker = (): void => {
    pickerRef.current && pickerRef.current.focus()
  }

  const defaultLabel = data.find((item) => item.value === selectedValue)
    ? data.find((item) => item.value === selectedValue)?.label
    : !isEmpty(data)
    ? data[0].label
    : t('pleaseSelect')

  return (
    <View
      testID="picker-android"
      style={{
        ...styles.container,
        borderColor: primaryColor,
      }}
    >
      <DropdownButton
        onPress={showPicker}
        colorTheme={colorTheme}
        title={defaultLabel}
      />
      {!isEmpty(data) && (
        <Picker
          style={{
            ...styles.picker,
            color: primaryColor,
          }}
          ref={
            pickerRef as React.Ref<RNPicker<ItemValue | undefined>> | undefined
          }
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          testID="picker-android-content"
        >
          {data.map(({ label, value }) => (
            <RNPicker.Item key={value} label={label} value={value} />
          ))}
        </Picker>
      )}
    </View>
  )
}

const styles = {
  container: {
    marginVertical: 4,
  },
  picker: {
    height: 0,
    width: 0,
    opacity: 0,
  },
}

export default PickerAndroid
