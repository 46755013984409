import { isEmpty } from 'lodash'
import React from 'react'
import { StyleSheet, Platform, View } from 'react-native'

import PostButton from '~/components/career/atoms/post/PostButton'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'

export type CommentMediaData = {
  media?: Media[]
  messageText?: string
}

type Props = {
  currentUser?: User
  data?: CommentMediaData
  mediaPicker?: React.ReactNode
  onSubmit?: (text: string) => void
  onChanged?: (data: Partial<CommentMediaData>) => void
}

const CommentBoxMedia: React.FC<Props> = ({
  currentUser,
  data,
  onSubmit,
  onChanged,
}: Props) => {
  const changeMessageText = (value: string): void => {
    onChanged && onChanged({ messageText: value })
  }

  const handleSubmitComment = (): void => {
    if (onSubmit && data?.messageText) {
      onSubmit(data?.messageText)
    }
  }

  const removeMedia = (media?: Media): void => {
    if (media == null) {
      onChanged && onChanged({ media: [] })
    } else {
      const previousMedia = data?.media ?? []
      !isEmpty(previousMedia) &&
        onChanged &&
        onChanged({
          media: previousMedia.filter((item: Media) => item != media),
        })
    }
  }

  return (
    <View style={styles.container} testID="comment-box-media">
      <UserAvatar user={currentUser} size={ProfileAvatarSize.TINY} />
      <View style={styles.fillView}>
        <View>
          <Input
            multiline
            testID="comment-box-media-input"
            style={styles.input}
            value={data?.messageText}
            onChangeText={changeMessageText}
          />
          {!isEmpty(data?.media) ? (
            <View style={styles.media} testID="media-preview-container">
              <MediaPreview
                media={data?.media ?? []}
                retry={true}
                onRemove={removeMedia}
              />
            </View>
          ) : null}
        </View>
        <View style={styles.footer} testID="comment-box-media-post">
          <PostButton onSubmit={handleSubmitComment} />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: color.white,
  },
  fillView: {
    flex: 1,
    marginLeft: 16,
  },
  footer: {
    alignSelf: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  input: {
    borderColor: color.gray,
    borderRadius: 4,
    borderWidth: 1,
    marginBottom: 23,
    padding: 10,
    ...Platform.select({
      web: { outlineColor: color.gray },
    }),
  },
  media: {
    maxHeight: 100,
    maxWidth: 100,
    ...Platform.select({
      web: {
        maxWidth: 150,
        maxHeight: 150,
      },
    }),
  },
})

export default CommentBoxMedia
