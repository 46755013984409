import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  value: number
  onPress?: () => void
}

const SharedCount: React.FC<Props> = ({ onPress, value }: Props) => {
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      onPress={onPress}
      testID="shared-count"
      style={styles.container}
    >
      {/* TODO: if value >= 1000, format me: 1k, 1k2, etc. */}
      <Text testID="shared-count-value" style={styles.sharedCountValue}>
        {value}
      </Text>
      <Text testID="shared-count-label" style={styles.sharedCountValue}>
        {t('sharedCount', { count: value })}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  sharedCountValue: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    marginHorizontal: 2,
  },
})

export default SharedCount
