import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import JobShowTemplate from '~/components/career/templates/jobs/JobShowTemplate'

const JobShow: React.FC = () => (
  <DefaultLayout>
    <JobShowTemplate />
  </DefaultLayout>
)

export default JobShow
