import api from '~/api'
import type Policy from '~/interfaces/Policy'
import type Video from '~/interfaces/Video'
import Uploader from '~/uploader/Uploader'

class VideoUploader extends Uploader<Video> {
  async createPolicy(): Promise<Policy<Video>> {
    return api.upload.createVideoPolicy(this.blob)
  }
}
export default VideoUploader
