import { FontAwesome as Icon } from '@expo/vector-icons'
import moment from 'moment'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { TemplateType } from '~/interfaces/Resume'
import WorkExperience from '~/interfaces/WorkExperience'
import { RESUME_DATE_FORMAT } from '~/utils/career/resume'

type Props = {
  templateType: TemplateType
  workExperience: Partial<WorkExperience>
}

const WorkExperienceGroupItem: React.FC<Props> = ({
  templateType,
  workExperience,
}: Props) => {
  const companyNameIcon = {
    enjin: (
      <Icon name="circle" size={14} color={color.primary} style={styles.icon} />
    ),
    shades: (
      <Icon name="circle" size={14} color={color.black} style={styles.icon} />
    ),
    formal: undefined,
    minimal: undefined,
  }

  const commonView = (
    <>
      <HorizontalContainer>
        {companyNameIcon[templateType]}
        <Text style={styles.title}>{workExperience.position}</Text>
      </HorizontalContainer>
      <Text style={styles.title}>{workExperience.companyName}</Text>
      <Text style={styles.date}>
        {moment(workExperience.startDate).format(RESUME_DATE_FORMAT)}
        {workExperience.endDate
          ? `- ${moment(workExperience.endDate).format(RESUME_DATE_FORMAT)}`
          : ''}
      </Text>
      <Text style={styles.description}>{workExperience.responsibilities}</Text>
    </>
  )

  const content = {
    enjin: commonView,
    shades: commonView,
    formal: commonView,
    minimal: commonView,
  }

  return (
    <View style={styles.container} testID="work-experience-group-item">
      {content[templateType]}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 14,
  },
  date: {
    color: color.textGray,
  },
  description: {
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: 6,
  },
  title: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginBottom: 2,
  },
})

export default WorkExperienceGroupItem
