import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  children?: React.ReactNode
}

const TemplateFooter: React.FC<Props> = ({ children }: Props) => (
  <View testID="template-footer" style={styles.container}>
    {children}
  </View>
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 18,
    backgroundColor: color.black,
  },
})

export default TemplateFooter
