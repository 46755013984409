import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
  phoneSetting: React.ReactElement
  emailSetting: React.ReactElement
  passwordSetting: React.ReactElement
}

const GeneralAccountSettings: React.FC<Props> = ({
  phoneSetting,
  emailSetting,
  passwordSetting,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="general-account-settings">
      <Text style={styles.title}>{t('general')}</Text>
      {emailSetting}
      {phoneSetting}
      <View style={styles.element}>{passwordSetting}</View>
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 10,
  },
  element: {
    marginBottom: 10,
    marginTop: 6,
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.IMPORTANT,
    marginBottom: 10,
  },
})

export default GeneralAccountSettings
