import React from 'react'

import AppDrawer from '~/components/common/pages/RootPage/AppDrawer'
import { ServiceType } from '~/constants/common/app'
import { otherRoutes } from '~/utils/common/routes'
import { mainForumRoutes, otherForumRoutes } from '~/utils/forum/routes'

const PCLayout: React.FC = () => (
  <AppDrawer.Navigator initialRouteName="forum" serviceType={ServiceType.FORUM}>
    {[...mainForumRoutes, ...otherForumRoutes, ...otherRoutes].map((route) => (
      <AppDrawer.Screen key={route.name} {...route} />
    ))}
  </AppDrawer.Navigator>
)

export default PCLayout
