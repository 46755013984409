import { isNil } from 'lodash'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TextInput } from 'react-native'

import BackToLogin from '~/components/common/atoms/auth/BackToLogin'
import ConfirmPasswordInput from '~/components/common/atoms/auth/ConfirmPasswordInput'
import EmailInput from '~/components/common/atoms/auth/EmailInput'
import PasswordInput from '~/components/common/atoms/auth/PasswordInput'
import TermsAndPolicy from '~/components/common/molecules/auth/TermsAndPolicy'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
  isLoading: boolean
  invitationEmail?: string
  onSubmit: (email: string, password: string) => void
  signUpFacebookButton?: React.ReactElement
  isShowReSignUp?: boolean
  onChange?: () => void
  resendConfirmation?: (email: string) => void
  isCompany?: boolean
}

const SignupForm: React.FC<Props> = ({
  isPC,
  onSubmit,
  isLoading,
  invitationEmail,
  isShowReSignUp,
  signUpFacebookButton,
  onChange,
  resendConfirmation,
  isCompany,
}: Props) => {
  const { t } = useTranslation()
  const passwordRef = useRef<TextInput>() as RefObject<TextInput>
  const confirmPasswordRef = useRef<TextInput>() as RefObject<TextInput>
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false)

  const containerStyle = isPC ? styles.container : styles.containerMobile

  const handleChangeEmail = (value: string, isValid: boolean): void => {
    setEmailValid(isValid)
    setEmail(value)
  }

  const handleChangePassword = (value: string, isValid: boolean): void => {
    setPasswordValid(isValid)
    setPassword(value)
  }

  useEffect(() => {
    !isNil(invitationEmail) && handleChangeEmail(invitationEmail, true)
  }, [invitationEmail])

  return (
    <View style={containerStyle}>
      <EmailInput
        email={email}
        onChangeText={handleChangeEmail}
        onSubmitEditing={(): void => passwordRef.current?.focus()}
      />
      <PasswordInput
        onChangeText={handleChangePassword}
        onSubmitEditing={(): void => confirmPasswordRef.current?.focus()}
        ref={passwordRef}
      />
      <ConfirmPasswordInput
        originalPassword={password}
        verifyChanged={setConfirmPasswordValid}
        reference={confirmPasswordRef}
        onSubmitEditing={(): void => {
          emailValid &&
            passwordValid &&
            confirmPasswordValid &&
            onSubmit(email, password)
        }}
      />
      {isShowReSignUp && (
        <View testID="re-signup">
          <Text style={styles.fontSizeText}>
            {t('auth.confirmationLink', {
              email,
            })}
          </Text>
          <View style={styles.confirmation}>
            <Text
              testID="resend-email"
              style={styles.link}
              onPress={() => resendConfirmation?.(email)}
            >
              {t('auth.resendTheLink')}
            </Text>
            <Text> {t('or')} </Text>
            <Text style={styles.link} onPress={onChange} testID="change-email">
              {t('auth.changeEmail')}
            </Text>
          </View>
        </View>
      )}
      <Button
        buttonStyle={StyleSheet.flatten(styles.signupButton)}
        title={t('auth.signup')}
        titleStyle={commonStyles.buttonTextSize}
        onPress={(): void => onSubmit(email, password)}
        disabled={
          !(emailValid && passwordValid && confirmPasswordValid) || isLoading
        }
        testID="signup-form-submit"
      />
      <View style={isCompany ? styles.separatorCompany : styles.separator} />
      <View style={styles.backToLogin}>
        <BackToLogin />
      </View>
      {signUpFacebookButton}
      <TermsAndPolicy isCompany={isCompany} />
    </View>
  )
}

const styles = StyleSheet.create({
  backToLogin: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  confirmation: {
    flexDirection: 'row',
  },
  container: {
    marginTop: 20,
    width: 415,
  },
  containerMobile: {
    padding: 35,
    width: '100%',
  },
  fontSizeText: {
    fontSize: FontSize.SUB,
  },
  link: {
    textDecorationLine: 'underline',
    color: color.textGray,
    fontWeight: 'bold',
    fontSize: FontSize.SUB,
  },
  separator: {
    backgroundColor: color.lightGray,
    height: 1,
    marginHorizontal: 10,
    marginVertical: 48,
  },
  separatorCompany: {
    backgroundColor: color.lightGray,
    height: 1,
    marginHorizontal: 10,
    marginTop: 25,
    marginBottom: 15,
  },
  signupButton: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
})

export default SignupForm
