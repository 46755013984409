import * as Factory from 'factory.ts'
import faker from 'faker'

import { companyFactory } from '~/factories/company'
import { imageFactory } from '~/factories/image'
import { resumeFactory } from '~/factories/resume'
import User from '~/interfaces/User'

const companies = companyFactory.buildList(1)

export const userFactory = Factory.makeFactory<User>({
  id: Factory.each((i) => i),
  type: '12',
  isCurrentUser: false,
  followingsCount: 3,
  followersCount: 3,
  following: true,
  followed: true,
  resume: resumeFactory.build(),
  doneTutorial: true,
  isCompanyAccount: false,
  displayLang: 'en',
  zelfiumId: 'string',
  profile: {
    id: 1,
    familyName: 'Laura',
    givenName: 'Martiny',
    country: 'NOR',
    sex: 'male',
    birthday: 'April 4, 1995',
    isGraduated: true,
    jobHuntingStatus: [],
    title: 'Women empowerment | UI Designer',
    aboutMe:
      'Enjoying design thinking process and creative media creation. Loving outdoor activities, cultural-exchange activities, volunteerism, and music/video creation. Enthusiast about futurism (robotics), tech-pop culture, marketing, media, photography, and kids development. Experienced in brand development, UI/UX, project management, event videography, and familiar with start-ups environment / creative community in Tokyo, Japan. Actively involved in Indonesia Student Association for Kanto region, as the lead of Creative Design Department period 2018-2019. Open to any collaboration project, especially between Indonesia and Japan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue ipsum consequat est tincidunt, ultrices fermentum metus semper. Proin placerat sollicitudin orci, a commodo tellus iaculis at. Pellentesque sit amet rhoncus ante. Vestibulum facilisis libero venenatis malesuada maximus. Morbi ac nibh vitae diam tempus egestas. Morbi convallis velit eget justo lacinia elementum. Donec purus ante, ornare id convallis vehicula, egestas eget tellus. Vivamus luctus augue sed magna blandit viverra. Cras ac blandit quam. Aliquam dui massa, efficitur sed enim at, tempor egestas sapien.',
    skills: [
      {
        id: 1,
        name: 'Japanese N4',
        type: 'skill',
      },
      {
        id: 2,
        name: 'Photoshop',
        type: 'skill',
      },
      {
        id: 3,
        name: 'Illustrator',
        type: 'skill',
      },
    ],
    langProfiles: [
      {
        id: 2,
        code: 'ace',
        type: 'languageProfile',
      },
    ],
    email: 'enjin@example.com',
    website: 'enjin.com.jp',
    type: 'string',
    companies: companies,
    results: [],
    jobs: [],
    contacts: [],
    image: imageFactory.build(),
    enName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    name: `${faker.name.lastName()} ${faker.name.firstName()}`,
    position: faker.lorem.words(3),
    websiteReferences: [],
  },
  createdAt: '2021-09-20T10:52:19.230+09:00',
})
