import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SocialLikeCount from '~/components/common/features/socials/SocialLikeCount'
import Answer from '~/interfaces/Answer'
import { RootState } from '~/rootReducer'

type Props = {
  answer: Answer
}

const AnswerLikeCount: React.FC<Props> = ({ answer }: Props) => {
  const [likesCount, setLikesCount] = useState(answer.likesCount)

  const answerLikeCount = useSelector((state: RootState) =>
    answer.id === state.answers?.current?.id
      ? state.answers?.current.likesCount
      : undefined
  )

  useEffect(() => {
    answerLikeCount && setLikesCount(answerLikeCount)
  }, [answerLikeCount])

  return likesCount > 0 ? (
    <SocialLikeCount
      likeCount={likesCount}
      likeParams={{ answerId: answer.id }}
    />
  ) : (
    <></>
  )
}

export default React.memo(AnswerLikeCount)
