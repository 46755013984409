import type { HTTPClient } from '~/httpClient'
import User from '~/interfaces/User'

export type FollowRequest = {
  followableId: number
  followableType: string
}

export type Params = {
  page?: number
}

export class FollowAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  create(followRequest: FollowRequest): Promise<User> {
    return this.client.post<User>('/profile/followings', followRequest)
  }

  delete(followRequest: FollowRequest): Promise<User> {
    return this.client.delete<User>('/profile/followings', followRequest)
  }

  async fetchFollowers(userId: number, page: number): Promise<User[]> {
    const path = `/users/${userId}/followers?page=${page}`
    return await this.client.get<User[]>(path)
  }

  async fetchFollowing(userId: number, page: number): Promise<User[]> {
    const path = `/users/${userId}/followings?page=${page}`
    return await this.client.get<User[]>(path)
  }
}
