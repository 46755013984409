import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

type Props = {
  user: User
}

const CommunityUserResult: React.FC<Props> = ({ user }: Props) => {
  return (
    <View style={styles.container} testID="community-user-result">
      <UserAvatar user={user} size={ProfileAvatarSize.MEDIUM} />
      <View style={styles.userInfo}>
        <UserName size="medium" user={user} />
        <Text style={styles.userTitle} numberOfLines={1}>
          {user?.profile?.title}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userInfo: {
    flex: 1,
    paddingHorizontal: 10,
  },
  userTitle: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.GENERAL,
    paddingVertical: 4,
  },
})

export default CommunityUserResult
