import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  GestureResponderEvent,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'

import JobSelectionTitle from '~/components/career/atoms/jobs/JobSelectionTitle'
import CancelButton from '~/components/common/atoms/CancelButton'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { Fonts, FontSize } from '~/constants/common/font'
import Recruitment from '~/interfaces/Recruitment'

type SelectionData = {
  title: string
  description?: string
  selectionView: React.ReactElement
}

type Props = {
  data: SelectionData[]
  targetJob: Recruitment
  isDisabledNext?: boolean
  onPressNext?: (event: GestureResponderEvent) => void
  onPressCancel?: (event: GestureResponderEvent) => void
}

const JobApplyResumeSelection: React.FC<Props> = ({
  data,
  targetJob,
  isDisabledNext,
  onPressNext,
  onPressCancel,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ScrollView testID="job-apply-resume-selection">
      <Text style={styles.title}>
        {t('jobs.applyTitle', {
          subject: targetJob.subject,
          companyName: targetJob.companyName,
        })}
      </Text>
      {data.map((item) => (
        <View
          key={item.title}
          style={styles.selectionContainer}
          testID="job-selection-title-container"
        >
          <JobSelectionTitle
            title={item.title}
            description={item?.description}
          />
          <View style={styles.selectionView} testID="selection-view-container">
            {item.selectionView}
          </View>
        </View>
      ))}
      <View style={styles.footer}>
        <CancelButton onPress={onPressCancel} />
        <Button
          title={t('next')}
          testID="next-button"
          onPress={onPressNext}
          disabled={isDisabledNext}
          containerStyle={styles.actionBtn}
          titleStyle={commonStyles.buttonTextSize}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  actionBtn: {
    marginLeft: 15,
    width: 92,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
  selectionContainer: {
    marginTop: 20,
  },
  selectionView: {
    borderWidth: 1,
    borderColor: color.gray,
    ...commonStyles.borderRadiusAllCorner,
    maxHeight: 290,
    padding: 10,
    paddingBottom: 20,
    ...Platform.select({
      default: {
        overflow: 'scroll',
      },
      web: {
        overflowY: 'scroll',
      },
    }),
  },
  title: {
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.REMARKABLE,
  },
})

export default JobApplyResumeSelection
