import { TFunction } from 'i18next'
import { User as ChatUser } from 'react-native-gifted-chat'

import ChatMessageCable from '~/interfaces/ChatMessageCable'
import Message from '~/interfaces/Message'
import User from '~/interfaces/User'

export const parseMessageFromCable = (
  cableData: ChatMessageCable,
  users: User[],
  translate: TFunction
): Message | undefined => {
  const user = users.find((item) => item.id === cableData.userId)

  return user
    ? {
        _id: cableData.id,
        id: cableData.id,
        body: cableData.body,
        text: cableData.body,
        createdAt: new Date(cableData.createdAt),
        user: parseGiftedUserFromUser(user, translate),
        chatThreadId: cableData.chatThread.id,
        userId: cableData.userId,
      }
    : undefined
}

export const formatMessage = (
  message: Message,
  translate: TFunction,
  users?: User[]
): Message => {
  const user = users?.find((item) => item.id === message.userId)

  return user
    ? {
        ...message,
        _id: message.id,
        text: message.body,
        createdAt: new Date(message.createdAt),
        user: parseGiftedUserFromUser(user, translate),
      }
    : message
}

export const parseGiftedUserFromUser = (
  user: User,
  translate: TFunction
): ChatUser => {
  return {
    _id: user.id,
    name: translate('profile.displayFullName', {
      name: user.profile.name,
      enName: user.profile.enName || user.profile.name,
    }),
    avatar: user.profile.image?.urls?.small,
  }
}
