import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Image } from 'react-native'

import { ResumeTemplateType } from '~/components/career/molecules/resume/ResumeTemplateList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import Resume from '~/interfaces/Resume'

type Props = {
  resume: Resume
  data: ResumeTemplateType
}

const ResumeTemplateItem: React.FC<Props> = ({ data, resume }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const imageContainerStyle = isPC ? styles.imagePC : styles.imageMobile
  const isSelected = data.type === resume.templateType

  return (
    <View testID="resume-template-item" style={styles.container}>
      <View style={imageContainerStyle}>
        <Image source={data.imageSource} style={styles.image} />
        {isSelected && (
          <View
            style={[styles.image, styles.overlay]}
            testID="resume-template-item-selected"
          >
            <Text style={styles.overlayTitle}>
              {t('resume.selectedTemplate')}
            </Text>
          </View>
        )}
      </View>
      <Text style={styles.name}>{data.name}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    flex: 1,
    ...commonStyles.borderRadiusAllCorner,
  },
  image: {
    flex: 1,
    overflow: 'hidden',
  },
  imageMobile: {
    flex: 1,
    overflow: 'hidden',
    ...commonStyles.borderRadiusTopCorner,
  },
  imagePC: {
    flex: 1,
    overflow: 'hidden',
    ...commonStyles.borderRadiusTopCorner,
  },
  name: {
    color: color.black,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 15,
    marginLeft: 15,
    marginTop: 10,
  },
  overlay: {
    backgroundColor: color.mask,
    position: 'absolute',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  overlayTitle: {
    color: color.white,
    alignSelf: 'center',
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default ResumeTemplateItem
