import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isRegistering?: boolean
  onSubmit: () => void
}

const MobileColumn: React.FC<Props> = ({ isRegistering, onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="registration-complete-column-mobile">
      <View style={StyleSheet.flatten([styles.content, styles.shadow])}>
        <Text style={styles.title}>{t('profile.registrationMessage')}</Text>
        <Text style={styles.congratulationText}>{t('congratulation')}</Text>
        <Image
          source={require('~/assets/images/welcome-mobile.png')}
          resizeMode="stretch"
          style={styles.welcomeImage}
          testID="registration-complete-column"
        />
      </View>
      <Button
        testID="start-button"
        loading={isRegistering}
        containerStyle={StyleSheet.flatten(styles.startButtonContainer)}
        buttonStyle={StyleSheet.flatten(styles.startButton)}
        title={t('profile.letStart')}
        disabled={isRegistering}
        onPress={onSubmit}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  congratulationText: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
    marginTop: 4,
  },
  container: {
    flex: 1,
  },
  content: {
    alignSelf: 'center',
    backgroundColor: color.primary,
    height: 330,
    marginTop: '30%',
    width: 300,
  },
  shadow: {
    borderRadius: 4,
    elevation: 3,
    shadowColor: color.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
  },
  startButton: {
    alignSelf: 'center',
    borderRadius: 4,
    height: 40,
    width: 260,
  },
  startButtonContainer: {
    alignSelf: 'center',
    marginTop: 60,
  },
  title: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
    marginTop: 40,
  },
  welcomeImage: {
    bottom: 0,
    height: 200,
    left: 0,
    position: 'absolute',
    width: 300,
  },
})

export default MobileColumn
