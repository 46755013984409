import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const ResumeLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Link
      testID="resume-link"
      to={{
        screen: 'resumeIndex',
      }}
    >
      <Text style={styles.link}>{t('profile.resumes')}</Text>
    </Link>
  )
}

const styles = StyleSheet.create({
  link: {
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
})

export default ResumeLink
