import React from 'react'
import { StyleSheet, View } from 'react-native'

import DateText from '~/components/common/atoms/DateText'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Post from '~/interfaces/Post'

type Props = {
  comment: Post
  replyComponent?: React.ReactElement
  likeCountComponent?: React.ReactElement
  likeButtonComponent?: React.ReactElement
}

const CommentSocialBar: React.FC<Props> = ({
  comment,
  replyComponent,
  likeCountComponent,
  likeButtonComponent,
}: Props) => (
  <View style={styles.container} testID="comment-social-bar">
    <HorizontalContainer>
      {likeButtonComponent}
      {likeCountComponent}
    </HorizontalContainer>
    <View style={styles.actionSeparator} />
    {replyComponent}
    <View style={styles.actionSeparator} />
    <DateText date={comment.createdAt} fontSize={FontSize.SUB} />
  </View>
)

const styles = StyleSheet.create({
  actionSeparator: {
    backgroundColor: color.gray,
    marginHorizontal: 6,
    paddingVertical: 6,
    width: 1,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    paddingLeft: 10,
    paddingTop: 4,
  },
})

export default CommentSocialBar
