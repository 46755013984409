import {
  BottomTabBarButtonProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs'
import type { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import type { RouteProp } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import { useSelector } from 'react-redux'

import TabMenuButton from '~/components/common/molecules/TabMenuButton'
import AppDrawer from '~/components/common/pages/RootPage/AppDrawer'
import { ServiceType } from '~/constants/common/app'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'
import { messageUnreadCountSelector } from '~/slices/common/notifications'
import { currentUserSelector } from '~/slices/common/users'
import {
  mainCareerRoutes,
  mainCareerRoutesCompany,
  otherCareerRoutes,
} from '~/utils/career/routes'
import { otherRoutes } from '~/utils/common/routes'

const Tab = createBottomTabNavigator<MainStackParamList & OtherStackParamList>()

type Route = RouteProp<Record<string, object | undefined>, string>

const tabBarIcon = (
  { focused, badgeCount }: { focused: boolean; badgeCount: number },
  route: Route
): JSX.Element => (
  <TabMenuButton
    name={route.name}
    focused={focused}
    badgeCount={route.name === 'messages' ? badgeCount : 0}
  />
)

const screenOptions = ({
  route,
  navigation,
  messageUnreadCount,
}: {
  route: Route
  navigation: { isFocused: Function; emit: Function; navigate: Function }
  messageUnreadCount: number
}): BottomTabNavigationOptions => {
  const tabBarButton =
    route.name === 'messages'
      ? (props: BottomTabBarButtonProps) => (
          <Pressable
            {...props}
            onPress={(event) => {
              if (
                !event.defaultPrevented &&
                (route.params || !navigation.isFocused())
              ) {
                navigation.navigate({ name: route.name, merge: false })
              }
            }}
          />
        )
      : undefined
  return {
    tabBarItemStyle: {
      borderTopColor: navigation.isFocused()
        ? color.primary
        : color.transparent,
      borderTopWidth: 3,
    },
    tabBarIcon: ({ focused }: { focused: boolean }): JSX.Element =>
      tabBarIcon({ focused, badgeCount: messageUnreadCount }, route),
    headerShown: false,
    tabBarShowLabel: true,
    tabBarLabelStyle: { fontSize: FontSize.SUB },
    tabBarButton,
  }
}

const MobileTab: React.FC = () => {
  const { t } = useTranslation()
  const messageUnreadCount = useSelector(messageUnreadCountSelector)
  const currentUser = useSelector(currentUserSelector)

  return (
    <Tab.Navigator
      initialRouteName="career"
      screenOptions={({ route, navigation }) =>
        screenOptions({ route, navigation, messageUnreadCount })
      }
    >
      {(currentUser?.isCompanyAccount
        ? mainCareerRoutesCompany
        : mainCareerRoutes
      ).map((route) => (
        <Tab.Screen
          key={route.name}
          {...route}
          options={{
            tabBarLabel: route.labelTranslationKey
              ? t(route.labelTranslationKey)
              : '',
          }}
        />
      ))}
      {[...otherCareerRoutes, ...otherRoutes].map((route) => (
        <Tab.Screen
          key={route.name}
          {...route}
          options={{
            tabBarItemStyle: { display: 'none' },
            tabBarIcon: () => null,
          }}
        />
      ))}
    </Tab.Navigator>
  )
}

const MobileLayout: React.FC = () => (
  <AppDrawer.Navigator
    initialRouteName="mobiletab"
    serviceType={ServiceType.CAREER}
  >
    <AppDrawer.Screen name="mobiletab" component={MobileTab} />
  </AppDrawer.Navigator>
)

export default MobileLayout
