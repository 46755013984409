import { useFocusEffect } from '@react-navigation/native'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { LikeParams } from '~/api/likes'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import ReactionList from '~/components/common/features/ReactionList'
import LikeCount from '~/components/common/molecules/socials/LikeCount'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  likeCount: number
  likeParams: Partial<LikeParams>
}

const SocialLikeCount: React.FC<Props> = ({ likeCount, likeParams }: Props) => {
  const [isShowReactorsModal, setIsShowReactorsModal] = useState(false)

  const hideReactorModal = (): void => {
    setIsShowReactorsModal(false)
  }

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setIsShowReactorsModal(false)
      }
    }, [])
  )

  return (
    <View testID="social-like-count">
      <LikeCount
        value={likeCount}
        onPress={(): void => setIsShowReactorsModal(true)}
      />
      {isShowReactorsModal && (
        <ModalContainer
          visible={isShowReactorsModal}
          onDismiss={hideReactorModal}
        >
          <View
            testID="reaction-list-container"
            style={styles.reactionListContainer}
          >
            <ReactionList params={likeParams} onPressClose={hideReactorModal} />
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  reactionListContainer: {
    ...commonStyles.borderRadiusAllCorner,
    overflow: 'hidden',
  },
})

export default SocialLikeCount
