import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import PostButton from '~/components/career/atoms/post/PostButton'
import CancelButton from '~/components/common/atoms/CancelButton'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  message?: string
  disabled?: boolean
  onSubmit: () => void
  onDismiss?: () => void
  header?: React.ReactElement
  sharingContent?: React.ReactElement
  onChange?: (message: string) => void
}

const SharingPopup: React.FC<Props> = ({
  header,
  message,
  onSubmit,
  onChange,
  disabled,
  onDismiss,
  sharingContent,
}: Props) => {
  const { t } = useTranslation()
  const [height, setHeight] = useState(45)

  return (
    <ModalContainer onDismiss={onDismiss} visible={true}>
      <ScrollView style={styles.contentContainer} testID="sharing-popup">
        <View style={styles.header}>{header}</View>
        <Input
          multiline
          value={message}
          style={styles.message}
          inputStyle={{ height }}
          onChangeText={onChange}
          testID="sharing-message-input"
          placeholder={t('post.shareMessageHint')}
          placeholderTextColor={color.placeholderText}
          onContentSizeChange={(e): void => {
            setHeight(e.nativeEvent.contentSize.height)
          }}
        />
        <View style={styles.sharingContent}>{sharingContent}</View>
        <View style={styles.actions}>
          <CancelButton onPress={onDismiss} />
          <View style={styles.postAction}>
            <PostButton disabled={disabled} onSubmit={onSubmit} />
          </View>
        </View>
      </ScrollView>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  contentContainer: {
    backgroundColor: color.white,
    flexDirection: 'column',
    maxHeight: '90%',
    maxWidth: '90%',
    minWidth: 370,
    paddingBottom: 30,
    paddingLeft: 37,
    paddingRight: 27,
    paddingTop: 16,
    ...commonStyles.borderRadiusAllCorner,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
  },
  message: {
    fontSize: FontSize.GENERAL,
    marginTop: 20,
    padding: 10,
  },
  postAction: {
    marginLeft: 10,
  },
  sharingContent: {
    ...commonStyles.borderRadiusAllCorner,
    borderColor: color.borderSeparator,
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
  },
})

export default SharingPopup
