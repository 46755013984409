import AsyncStorage from '@react-native-async-storage/async-storage'
import camelcaseKeys from 'camelcase-keys'
import Constants from 'expo-constants'
import snakecaseKeys from 'snakecase-keys'

import { logException } from '~/utils/common/sentry'
import { navigate, saveCurrentRouteAsyncStore } from '~/utils/navigation'

const isDev = process.env.NODE_ENV !== 'production'
const isTest = process.env.NODE_ENV === 'test'

const { manifest } = Constants
const protocol = isDev ? 'http' : 'https'
const hostname = isTest
  ? 'test.enjin.world'
  : isDev
  ? (manifest?.debuggerHost?.split(`:`)?.shift() ?? 'localhost').concat(`:3000`)
  : window?.location?.hostname ?? 'api.enjin.world'

export const baseURL = protocol + '://' + hostname + '/api'

const getHeaders = async (): Promise<HeadersInit> =>
  AsyncStorage.getItem('access_token').then((accessToken) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  }))

export class HTTPError extends Error {
  name = 'HTTPError'
  response: Response

  constructor(response: Response) {
    super(response.statusText)
    this.response = response
  }
}

export class HTTPClient {
  private abortController: AbortController

  public constructor() {
    this.abortController = new AbortController()
  }

  public async get<T>(
    path: string,
    args: RequestInit = { method: 'GET' }
  ): Promise<T> {
    return await this.httpRequest<T>(baseURL + path, {
      ...args,
      headers: await getHeaders(),
    })
  }

  public async post<T>(
    path: string,
    body: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    args: RequestInit = {
      method: 'POST',
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
    }
  ): Promise<T> {
    return await this.httpRequest<T>(baseURL + path, {
      ...args,
      headers: await getHeaders(),
    })
  }

  public async put<T>(
    path: string,
    body: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    args: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
    }
  ): Promise<T> {
    return await this.httpRequest<T>(baseURL + path, {
      ...args,
      headers: await getHeaders(),
    })
  }
  public async patch<T>(
    path: string,
    body: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    args: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
    }
  ): Promise<T> {
    return await this.httpRequest<T>(baseURL + path, {
      ...args,
      headers: await getHeaders(),
    })
  }

  public async delete<T>(
    path: string,
    body?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    args: RequestInit = {
      method: 'DELETE',
      body: body
        ? JSON.stringify(snakecaseKeys(body, { deep: true }))
        : undefined,
    }
  ): Promise<T> {
    return await this.httpRequest<T>(baseURL + path, {
      ...args,
      headers: await getHeaders(),
    })
  }

  public abort(): void {
    this.abortController.abort()
  }

  async httpRequest<T extends {}>(
    url: string,
    request: RequestInit
  ): Promise<T> {
    const response: Response = await fetch(url, {
      ...request,
      signal: this.abortController.signal,
    })
    if (response.status === 401) {
      saveCurrentRouteAsyncStore()
      navigate('login')
      throw new HTTPError(response)
    }

    if (!response.ok) {
      const error = new HTTPError(response)
      logException(error, request.body)
      throw error
    }

    let parsedBody: unknown = {}
    try {
      // may error if there is no body
      const json = await response.json()
      parsedBody = camelcaseKeys(json, { deep: true })
    } catch (ex) {
      logException(
        new Error('There is not response from return data'),
        request.body
      )
    }

    return parsedBody as T
  }
}

// Request body can have flexible any type
export const client = new HTTPClient()
