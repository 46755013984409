import React from 'react'
import { StyleSheet, View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import SkeletonAvatar from '~/components/common/atoms/SkeletonAvatar'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import color from '~/constants/common/color'

const SkeletonListItem: React.FC = () => (
  <View
    style={[styles.itemContainer, styles.skeletonView]}
    testID="skeleton-list-item"
  >
    <HorizontalContainer>
      <SkeletonAvatar size={ProfileAvatarSize.SMALL} />
      <View style={styles.rightColumn}>
        <SkeletonTwoBar />
      </View>
    </HorizontalContainer>
  </View>
)

const styles = StyleSheet.create({
  itemContainer: {
    overflow: 'hidden',
  },
  rightColumn: {
    flex: 1,
    marginLeft: 10,
    paddingRight: 20,
  },
  skeletonView: {
    backgroundColor: color.white,
    paddingBottom: 40,
    padding: 20,
  },
})

export default SkeletonListItem
