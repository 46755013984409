import React from 'react'
import { StyleSheet, View } from 'react-native'

import DateText from '~/components/common/atoms/DateText'
import UserName from '~/components/common/atoms/users/UserName'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

type Props = {
  user: User
  createdAt: string
  isPC?: boolean
}

const QuestionCreatedInfo: React.FC<Props> = ({
  user,
  createdAt,
  isPC,
}: Props) => (
  <View style={styles.container} testID="question-created-info">
    <View style={styles.nameContainer}>
      <UserName size={isPC ? 'tiny' : 'small'} user={user} />
    </View>
    <DateText date={createdAt} fontSize={FontSize.SUB} />
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
  },
  nameContainer: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
})

export default QuestionCreatedInfo
