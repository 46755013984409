import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import DateTime from '~/components/common/molecules/DateTime'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  data: Partial<CommunityEvent>
  onChange: (data: Partial<CommunityEvent>) => void
}

const EventTimeForm: React.FC<Props> = ({ data, onChange }: Props) => {
  const { t } = useTranslation()
  const [isShowWarning, setIsShowWarning] = useState(false)

  const updateStartTime = (timeStr: string): void => {
    onChange({ startAt: timeStr })
    data.endAt && setIsShowWarning(!isValidTime(timeStr, data.endAt))
  }

  const updateEndTime = (timeStr: string): void => {
    onChange({ endAt: timeStr })
    data.startAt && setIsShowWarning(!isValidTime(data.startAt, timeStr))
  }

  const isValidTime = (startTime: string, endTime: string): boolean => {
    const start = moment(startTime)
    const end = moment(endTime)
    return moment(start).isBefore(end)
  }

  return (
    <View testID="event-time-form">
      <DateTime
        maxDate={data.endAt}
        onChange={updateStartTime}
        selectedDateTime={data.startAt}
        dateTitle={t('events.startDate')}
        timeTitle={t('events.startTime')}
      />
      <View style={styles.verticalSeparator} />
      {data.startAt && (
        <DateTime
          minDate={data.startAt}
          onChange={updateEndTime}
          selectedDateTime={data.endAt}
          dateTitle={t('events.endDate')}
          timeTitle={t('events.endTime')}
        />
      )}
      {isShowWarning && (
        <Text testID="event-time-warning" style={styles.warning}>
          {t('events.eventTimeWarning')}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  verticalSeparator: {
    marginTop: 10,
  },
  warning: {
    color: color.secondary,
    fontStyle: 'italic',
  },
})

export default EventTimeForm
