import { isEmpty } from 'lodash'

import User from '~/interfaces/User'

export const getUserNameByLanguage = (
  language: string,
  user?: User
): string => {
  const name =
    language === 'ja'
      ? user?.profile?.name
      : !isEmpty(user?.profile?.enName)
      ? user?.profile?.enName
      : user?.profile?.name
  return name ?? ''
}
