import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu'

import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'

export type ControlMenuData = {
  id: number | string
  title: string
  hasSeparator?: boolean
}

const { Popover } = renderers

type Props = {
  data: ControlMenuData[]
  menuTrigger?: React.ReactElement
  onSelect?: (item: ControlMenuData) => void
  preferredPlacement?: 'top' | 'bottom' | 'left' | 'right'
  hasAnchor?: boolean
}

const ControlMenu: React.FC<Props> = ({
  data,
  onSelect,
  menuTrigger,
  hasAnchor = true,
  preferredPlacement = 'top',
}: Props) => (
  <Menu
    renderer={Popover}
    rendererProps={{
      preferredPlacement,
      anchorStyle: hasAnchor ? styles.anchor : styles.noneAnchor,
    }}
  >
    <MenuTrigger testID="menu-trigger">
      {menuTrigger ?? <ControlMenuButton />}
    </MenuTrigger>
    <MenuOptions optionsContainerStyle={styles.menuOptions}>
      {data.map((item) => (
        <MenuOption
          key={item.id}
          style={item.hasSeparator ? styles.separator : undefined}
          onSelect={(): void => onSelect && onSelect(item)}
        >
          <Text numberOfLines={1} style={styles.optionText}>
            {item.title}
          </Text>
        </MenuOption>
      ))}
    </MenuOptions>
  </Menu>
)

const styles = StyleSheet.create({
  anchor: {
    backgroundColor: color.gray,
  },
  menuOptions: {
    backgroundColor: color.gray,
    padding: 10,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 3,
  },
  noneAnchor: {
    backgroundColor: color.transparent,
  },
  optionText: {
    // FIXME
    // Normally when there is a children, it should render children instead of React Native Text.
    // And fontFamily should be changed base on children component.
    // Somehow it doesn't work correctly.
    // See. https://github.com/instea/react-native-popup-menu/blob/master/src/MenuOption.js#L52 (Version: 0.15.11)
    fontFamily: getFontByLanguage(getCurrentLanguage()),
    textAlign: 'center',
    fontSize: FontSize.GENERAL,
  },
  separator: {
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
  },
})
export default ControlMenu
