import { useFocusEffect, useRoute } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import CommentList from '~/components/career/features/posts/CommentList'
import PostItem from '~/components/career/organisms/posts/PostItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useAPI from '~/hooks/useAPI'
import Post from '~/interfaces/Post'
import i18n from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { RouteParamsID, setPageTitle } from '~/utils/navigation'

const PostContent: React.FC = () => {
  const route = useRoute()
  const postId = (route?.params as RouteParamsID)?.id
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [post, setPost] = useState<Post | undefined>(undefined)
  const isPC = useSelector((state: RootState) => state.device.isPC)

  const fetchPost = useAPI(
    async (api: API): Promise<void> => {
      if (!postId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.posts.get(postId)
        setPost(response)
      } catch (err) {
        if (api.isAlive) {
          setError(err as string)
        }
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [postId, setIsLoading]
  )

  useFocusEffect(
    useCallback(() => {
      fetchPost()
    }, [fetchPost, postId])
  )

  useFocusEffect(() =>
    setPageTitle(
      post?.title ??
        (i18n.language === 'ja'
          ? post?.user?.profile?.name
          : post?.user?.profile?.enName ?? post?.user?.profile?.name)
    )
  )

  return (
    <View
      testID="post-content"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <Text testID="post-detail-column-error">{error}</Text>
      {(!post || isLoading) && <View testID="loading-view" />}
      {post && (
        <View style={styles.postContainer}>
          <PostItem isPC={isPC} post={post} />
          <CommentList post={post} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    paddingBottom: 20,
    width: 600,
  },
  containerMobile: {
    flex: 1,
    paddingBottom: 20,
  },
  postContainer: {
    backgroundColor: color.white,
    marginTop: 20,
  },
})

export default PostContent
