import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Platform, View, TextInput } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'

export type PostCommentAreaData = {
  media?: Media[]
  message?: string
}

type Props = {
  currentUser?: User
  submit: () => void
  cancel?: () => void
  isLoading?: boolean
  isEdit?: boolean
  data?: PostCommentAreaData
  mediaPicker: React.ReactNode
  onChanged?: (data: Partial<PostCommentAreaData>) => void
}

const PostCommentInputArea: React.FC<Props> = ({
  data,
  submit,
  cancel,
  onChanged,
  isLoading,
  currentUser,
  isEdit,
  mediaPicker,
}: Props) => {
  const { t } = useTranslation()
  const inputRef = useRef<TextInput>(null)
  const [height, setHeight] = useState(45)
  const changeMessageText = (message: string): void => {
    onChanged && onChanged({ message })
  }

  const removeMedia = (media?: Media): void => {
    if (media == null) {
      onChanged && onChanged({ media: [] })
    } else {
      const previousMedia = data?.media ?? []
      !isEmpty(previousMedia) &&
        onChanged &&
        onChanged({
          media: previousMedia.filter((item: Media) => item != media),
        })
    }
  }

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [data])

  return (
    <View style={styles.container} testID="post-comment-input-area">
      <UserAvatar user={currentUser} size={ProfileAvatarSize.TINY} />
      <View style={styles.fillView}>
        <View>
          <Input
            ref={inputRef}
            multiline
            inputStyle={{ ...StyleSheet.flatten(styles.input), height }}
            value={data?.message}
            placeholder={t('comment.writeComment')}
            testID="post-comment-input"
            onChangeText={changeMessageText}
            onContentSizeChange={(e): void => {
              setHeight(e.nativeEvent.contentSize.height)
            }}
          />
          <View style={styles.mediaPicker} testID="post-comment-media-picker">
            {mediaPicker}
          </View>
        </View>
        {!isEmpty(data?.media) && (
          <View style={styles.media}>
            <MediaPreview
              media={data?.media ?? []}
              retry={true}
              onRemove={removeMedia}
            />
          </View>
        )}
        <View style={styles.footer}>
          <TextInputAction
            onSavePress={submit}
            onCancelPress={cancel}
            isSaveLoading={isLoading}
            isEdit={isEdit}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
  },
  fillView: {
    flex: 1,
    marginLeft: 10,
  },
  footer: {
    alignSelf: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
  },
  input: {
    backgroundColor: color.lightGray,
    padding: 13,
    paddingRight: 30,
    fontSize: FontSize.GENERAL,
    ...Platform.select({
      web: { outlineColor: color.transparent },
    }),
    ...commonStyles.borderRadiusAllCorner,
  },
  media: {
    marginTop: 8,
    maxHeight: 100,
    maxWidth: 100,
    ...Platform.select({
      web: {
        maxWidth: 150,
        maxHeight: 150,
      },
    }),
  },
  mediaPicker: {
    position: 'absolute',
    right: 5,
    top: 8,
  },
})

export default PostCommentInputArea
