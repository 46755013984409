import React, { ReactNode } from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'

import introductionImage from '~/assets/images/introduction.png'
import introductionCompanyImage from '~/assets/images/introduction_company.png'
import IntroductionColumn from '~/components/common/features/IntroductionColumn'
import Footer from '~/components/common/organisms/Footer'
import Snippet from '~/components/common/organisms/Snippet'
import PricingCardTemplate from '~/components/common/templates/PricingCardTemplate'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'

type Props = {
  children: ReactNode
  isCompany?: boolean
}

const AuthContainerTemplate: React.FC<Props> = ({
  children,
  isCompany,
}: Props) => {
  const isPC = useSelector((state: RootState) => state.device.isPC)

  const bodyStyle = !isCompany ? styles.paddingBottom : undefined

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      style={styles.container}
      testID="auth-container-template"
    >
      <View style={[isPC ? styles.body : styles.bodyMobile, bodyStyle]}>
        {isPC && (
          <>
            <View
              style={
                isCompany ? styles.imageWrapperCompany : styles.imageWrapper
              }
            >
              <Image
                source={
                  isCompany ? introductionCompanyImage : introductionImage
                }
                style={isCompany ? styles.imageCompany : styles.image}
              />
            </View>
            <View style={styles.separator} />
          </>
        )}
        <View style={styles.form}>
          {isPC && <IntroductionColumn isCompany={isCompany} />}
          {children}
        </View>
      </View>
      {isCompany && (
        <>
          <Snippet />
          <PricingCardTemplate />
        </>
      )}
      <Footer />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: color.white,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 20,
    marginVertical: 50,
  },
  bodyMobile: {
    backgroundColor: color.white,
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    flex: 1,
  },
  form: {
    justifyContent: 'center',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  imageCompany: {
    flex: 1,
    resizeMode: 'cover',
  },
  imageWrapper: {
    height: 726,
    width: 401,
  },
  imageWrapperCompany: {
    height: 650,
    width: 401,
  },
  paddingBottom: {
    paddingBottom: 20,
  },
  separator: {
    width: 32,
  },
})

export default AuthContainerTemplate
