import React from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  isPC: boolean
  rightComponent?: React.ReactElement
  avatarComponent?: React.ReactElement
}

const ProfileInfo: React.FC<Props> = ({
  isPC,
  rightComponent,
  avatarComponent,
}: Props) => (
  <View style={isPC ? styles.container : undefined} testID="profile-info">
    <View
      testID="profile-info-avatar-container"
      style={isPC ? styles.avatar : styles.avatarMobile}
    >
      {avatarComponent}
    </View>
    <View style={styles.separator} />
    <View style={isPC ? styles.rightComponent : styles.rightComponentMobile}>
      {rightComponent}
    </View>
  </View>
)

const styles = StyleSheet.create({
  avatar: {
    marginTop: -30,
  },
  avatarMobile: {
    marginTop: -39,
  },
  container: {
    flexDirection: 'row',
  },
  rightComponent: {
    flex: 1,
    marginLeft: 20,
    marginTop: 30,
  },
  rightComponentMobile: {
    marginTop: 10,
  },
  separator: {
    marginRight: 10,
  },
})

export default ProfileInfo
