import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu'

import ShareButton from '~/components/common/atoms/socials/ShareButton'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

const { Popover } = renderers

export enum PostItemShareAction {
  TIMELINE,
  AS_MESSAGE,
}

type Props = {
  onSelect?: (action: PostItemShareAction) => void
}

const PostItemShare: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation()

  return (
    <Menu
      renderer={Popover}
      rendererProps={{ preferredPlacement: 'top', anchorStyle: styles.anchor }}
      onSelect={onSelect}
    >
      <MenuTrigger testID="post-item-share">
        <ShareButton />
      </MenuTrigger>
      <MenuOptions
        testID="post-item-options"
        optionsContainerStyle={styles.menuOptions}
      >
        <MenuOption value={PostItemShareAction.TIMELINE}>
          <Text style={styles.optionText}>{t('post.shareOnTimeline')}</Text>
        </MenuOption>
        <MenuOption value={PostItemShareAction.AS_MESSAGE}>
          <Text style={styles.optionText}>{t('post.shareToMessage')}</Text>
        </MenuOption>
      </MenuOptions>
    </Menu>
  )
}

const styles = StyleSheet.create({
  anchor: {
    backgroundColor: color.gray,
  },
  menuOptions: {
    backgroundColor: color.gray,
    padding: 10,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 3,
  },
  optionText: {
    textAlign: 'center',
  },
})

export default PostItemShare
