import React from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  saveButton?: React.ReactElement
  previewButton?: React.ReactElement
}

const EditResumeHeaderActContainer: React.FC<Props> = ({
  saveButton,
  previewButton,
}: Props) => (
  <View style={styles.container} testID="edit-resume-header-act-container">
    {saveButton}
    <View style={styles.separator} />
    {previewButton}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 18,
    width: 265,
  },
  separator: {
    width: 15,
  },
})

export default EditResumeHeaderActContainer
