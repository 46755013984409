import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import CompanyMemberList from '~/components/career/features/company/CompanyMemberList'
import CompanySubscription from '~/components/career/organisms/company/CompanySubscription'
import CardContainer from '~/components/common/atoms/CardContainer'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import color from '~/constants/common/color'
import Company from '~/interfaces/Company'
import User from '~/interfaces/User'
import { isCompanyAdmin } from '~/utils/career/company'

type Props = {
  company: Company
  user: User | undefined
  isPC: boolean
}

const CompanyAccountManagement = ({ company, user, isPC }: Props) => {
  const isAdmin = useMemo(() => {
    return isNil(user) || isNil(company) ? false : isCompanyAdmin(user, company)
  }, [user?.id, company?.id])

  return (
    <InfiniteScrollContainer>
      <View
        testID="company-account-management"
        style={isPC ? styles.container : undefined}
      >
        <CardContainer>
          {company && user && (
            <CompanySubscription currentUser={user} isAdmin={isAdmin} />
          )}
        </CardContainer>
        <View style={styles.separator} />
        <CardContainer style={styles.body}>
          <CompanyMemberList />
        </CardContainer>
      </View>
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: color.white,
    padding: 20,
  },
  container: {
    alignSelf: 'center',
    width: 814,
  },
  separator: {
    marginBottom: 15,
  },
})

export default CompanyAccountManagement
