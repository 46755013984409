import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import MultipleSelectionTriggerButton from '~/components/common/molecules/MultipleSelectionTriggerButton'
import MultipleSelectList from '~/components/common/organisms/MultipleSelectList'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import SelectListItem from '~/interfaces/SelectListItem'

type Props = {
  isPC: boolean
  title?: string
  isInvert?: boolean
  placeholder?: string
  data: SelectListItem[]
  selectedValue?: string
  selectedItems: SelectListItem[]
  onChange?: (items: SelectListItem[]) => void
}

const MultipleSelectDialog: React.FC<Props> = ({
  data,
  isPC,
  title,
  isInvert,
  onChange,
  placeholder,
  selectedItems,
}: Props) => {
  const { t } = useTranslation()
  const [isShowingDialog, setIsShowingDialog] = useState(false)
  const [triggerButtonTitle, setTriggerButtonTitle] = useState('')
  const [selectedData, setSelectedData] = useState<SelectListItem[]>(
    selectedItems
  )

  const submit = (): void => {
    onChange && onChange(selectedData)
    setIsShowingDialog(false)
  }

  const cancel = (): void => {
    setSelectedData([])
    setIsShowingDialog(false)
  }

  const showSelectDialog = (): void => {
    setSelectedData(selectedItems)
    setIsShowingDialog(true)
  }

  useEffect(() => {
    setTriggerButtonTitle(selectedItems.map((item) => item.name).join(', '))
  }, [selectedItems])

  return (
    <View testID="multiple-select-dialog" style={styles.container}>
      <MultipleSelectionTriggerButton
        isInvert={isInvert}
        onPress={showSelectDialog}
        value={
          !triggerButtonTitle
            ? placeholder ?? t('categories')
            : triggerButtonTitle
        }
      />
      <ModalContainer onDismiss={cancel} visible={isShowingDialog}>
        <SafeAreaView
          testID="dialog-view"
          style={isPC ? styles.dialog : styles.dialogMobile}
        >
          <View style={styles.modalHeader}>
            {!isPC && (
              <Icon
                size={24}
                onPress={cancel}
                name="chevron-left"
                style={styles.backIcon}
              />
            )}
            <Text style={styles.title}>{title}</Text>
            {isPC && (
              <Icon
                size={24}
                onPress={cancel}
                name="close"
                style={styles.closeIcon}
              />
            )}
            <View />
          </View>
          <ScrollView style={isPC ? styles.scrollView : undefined}>
            <MultipleSelectList
              data={data}
              onSelect={setSelectedData}
              selectedItems={selectedData}
            />
          </ScrollView>
          <Button
            type="solid"
            onPress={submit}
            title={t('done')}
            testID="submit-button"
            buttonStyle={styles.submit}
            titleStyle={styles.submitTitle}
          />
        </SafeAreaView>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  backIcon: {
    alignSelf: 'center',
    color: color.primary,
    paddingLeft: 10,
  },
  closeIcon: {
    color: color.primary,
    marginRight: 10,
  },
  container: {
    flex: 1,
  },
  dialog: {
    backgroundColor: color.white,
    minWidth: 300,
    padding: 20,
    ...commonStyles.borderRadiusAllCorner,
  },
  dialogMobile: {
    backgroundColor: color.white,
    height: '100%',
    width: '100%',
  },
  modalHeader: {
    flex: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    maxHeight: 64,
    borderBottomWidth: 1,
    borderBottomColor: color.gray,
  },
  scrollView: {
    maxHeight: 400,
  },
  submit: {
    marginTop: 25,
  },
  submitTitle: {
    ...commonStyles.buttonTextSize,
  },
  title: {
    alignSelf: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
    ...commonStyles.titleTextSize,
  },
})

export default MultipleSelectDialog
