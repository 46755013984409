import React from 'react'
import { useSelector } from 'react-redux'

import MobileColumn from '~/components/common/features/registration/RegistrationComplete/MobileColumn'
import PCColumn from '~/components/common/features/registration/RegistrationComplete/PCColumn'
import { RootState } from '~/rootReducer'

type Props = {
  isRegistering?: boolean
  onSubmit: () => void
}

const RegistrationCompleteColumn: React.FC<Props> = ({
  isRegistering,
  onSubmit,
}: Props) => {
  const isPC = useSelector((state: RootState) => state.device.isPC)

  return isPC ? (
    <PCColumn isRegistering={isRegistering} onSubmit={onSubmit} />
  ) : (
    <MobileColumn isRegistering={isRegistering} onSubmit={onSubmit} />
  )
}

export default RegistrationCompleteColumn
