import { BaseAPI } from '~/api/baseAPI'

export class CommunityEventAttendeesAPI extends BaseAPI {
  configPath(
    communityId: number,
    communityEventId: number
  ): CommunityEventAttendeesAPI {
    this.path = `/communities/${communityId}/events/${communityEventId}/community_event_attendees`
    return this
  }
}
