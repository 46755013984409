import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import CompanyAvatar from '~/components/career/molecules/company/CompanyAvatar'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Company from '~/interfaces/Company'
import User from '~/interfaces/User'

type Props = {
  user?: User
  company?: Company
}

const MenuDrawerUserInfo: React.FC<Props> = ({ user, company }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.userInfo} testID="menu-drawer-user-info">
      {!user?.isCompanyAccount ? (
        <UserAvatar size={ProfileAvatarSize.MEDIUM} user={user} />
      ) : (
        <CompanyAvatar
          company={company}
          viewable={true}
          showBorder={true}
          size={ProfileAvatarSize.MEDIUM}
        />
      )}
      <View style={styles.userProfile}>
        {!user?.isCompanyAccount ? (
          <>
            <UserName user={user} size="medium" />
            <Link
              style={styles.seeProfile}
              to={{
                screen: 'mypage',
              }}
              testID="menu-drawer-link-profile"
            >
              {t('profile.seeYourProfile')}
            </Link>
          </>
        ) : (
          <>
            <UserName user={user} size="medium" company={company} />
            <Link
              style={styles.seeProfile}
              to={`/career/companies/${company?.id}`}
              testID="menu-drawer-link-profile"
            >
              {t('profile.seeYourProfile')}
            </Link>
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  seeProfile: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  userInfo: {
    flexDirection: 'row',
    paddingTop: 20,
  },
  userProfile: {
    alignSelf: 'center',
    flex: 1,
    paddingLeft: 20,
  },
})

export default MenuDrawerUserInfo
