import { PostsAPI } from '~/api/posts'

export class CommunityEventPostsAPI extends PostsAPI {
  build(communityId: number, eventId: number): CommunityEventPostsAPI {
    this.setBasePath(
      `/communities/${communityId}/events/${eventId}/community_event_posts`
    )
    return this
  }
}
