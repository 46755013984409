import React from 'react'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

export type StatisticCardItemData = {
  title: string
  value: string | number
  isActive?: boolean
}

type Props = {
  item: StatisticCardItemData
}

const StatisticCardItem: React.FC<Props> = ({ item }: Props) => (
  <CardContainer style={styles.container} testID="statistic-card-item">
    <TitleText style={styles.title}>{item.title}</TitleText>
    <View style={styles.separator} />
    <Text
      style={StyleSheet.flatten([
        styles.itemValue,
        { color: item.isActive ? color.primary : color.textGray },
      ])}
      testID="statistic-card-item-value"
    >
      {item.value}
    </Text>
  </CardContainer>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    marginTop: 13,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.white,
  },
  itemValue: {
    fontSize: 50,
    paddingVertical: 10,
  },
  separator: {
    marginVertical: 10,
    borderBottomWidth: 1,
    width: '100%',
    borderBottomColor: color.gray,
  },
  title: {
    color: color.textGray,
  },
})

export default StatisticCardItem
