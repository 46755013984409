import color from '~/constants/common/color'
import { Fonts } from '~/constants/common/font'
import { getCurrentLanguage, SupportedLanguage } from '~/plugins/i18n'

export const getFontByLanguage = (language: string): string =>
  language === SupportedLanguage.JA ? Fonts.JapaneseFont : Fonts.NormalFont

export const getMediumFontByLanguage = (language: string): string =>
  language === SupportedLanguage.JA ? Fonts.JapaneseFont : Fonts.MediumFont

export const getFontWeightByLanguage = (
  language: SupportedLanguage
): 'bold' | undefined =>
  language === SupportedLanguage.JA ? 'bold' : undefined

export const fontFamilyStyle = {
  fontFamily: getFontByLanguage(getCurrentLanguage()),
}

export default (language: SupportedLanguage) => {
  const textStyle = {
    fontFamily: getFontByLanguage(language),
  }

  const buttonTextStyle = {
    fontFamily: getMediumFontByLanguage(language),
    fontWeight: getFontWeightByLanguage(language),
  }

  return {
    colors: { primary: color.primary, secondary: color.secondary },
    Text: {
      style: [textStyle],
    },
    CheckBox: {
      textStyle: [textStyle],
    },
    Button: {
      titleStyle: [buttonTextStyle],
      disabledTitleStyle: [buttonTextStyle],
    },
    Input: {
      labelStyle: [textStyle],
      inputStyle: [textStyle],
      errorStyle: [textStyle],
      disabledInputStyle: [textStyle],
    },
    ButtonGroup: {
      textStyle: [textStyle],
      selectedTextStyle: [textStyle],
      disabledTextStyle: [textStyle],
      disabledSelectedTextStyle: [textStyle],
    },
  }
}
