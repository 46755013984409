import { FontAwesome as Icon } from '@expo/vector-icons'
import { isNil } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { CheckBox } from 'react-native-elements'

import MonthPicker from '~/components/common/atoms/MonthPicker'
import YearPicker from '~/components/common/atoms/YearPicker'
import TextField from '~/components/common/molecules/TextField'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Education from '~/interfaces/Education'

type Props = {
  index: number
  education: Partial<Education>
  onChange: (value: Partial<Education>, index: number) => void
  isPC: boolean
  onPressClose: () => void
}

const EducationForm: React.FC<Props> = ({
  index,
  onChange,
  education,
  onPressClose,
}: Props) => {
  const { t } = useTranslation()

  const [isStudyingHere, setIsStudyingHere] = useState(false)
  const [isHideGraduationDate, setIsHideGraduationDate] = useState(false)

  const enrollmentDate = new Date(education.enrollmentYear || '')

  const graduationDate = new Date(education.graduationYear || '')

  const [enrollmentMonth, setEnrollmentMonth] = useState<number | undefined>(
    enrollmentDate ? enrollmentDate.getMonth() + 1 : undefined
  )
  const [enrollmentYear, setEnrollmentYear] = useState<number | undefined>(
    enrollmentDate?.getFullYear() || undefined
  )

  const [graduationMonth, setGraduationMonth] = useState<number | undefined>(
    graduationDate ? graduationDate.getMonth() + 1 : undefined
  )
  const [graduationYear, setGraduationYear] = useState<number | undefined>(
    graduationDate?.getFullYear() || undefined
  )

  const changeSchool = (value: string): void => {
    onChange({ schoolName: value }, index)
  }

  const changeDegree = (value: string): void => {
    onChange({ degree: value }, index)
  }

  const changeMajor = (value: string): void => {
    onChange({ major: value }, index)
  }

  const changeEnrollmentYear = (year: number): void => {
    const newYear = year != 0 ? year : undefined
    setEnrollmentYear(newYear)
    onChange(
      {
        enrollmentYear: isNil(newYear)
          ? undefined
          : enrollmentMonth
          ? new Date(newYear, enrollmentMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeEnrollmentMonth = (month: number): void => {
    const newMonth = month != 0 ? month : undefined
    setEnrollmentMonth(newMonth)
    onChange(
      {
        enrollmentYear: isNil(newMonth)
          ? undefined
          : enrollmentYear
          ? new Date(enrollmentYear, newMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeGraduationYear = (year: number): void => {
    const newYear = year != 0 ? year : undefined
    setGraduationYear(newYear)
    onChange(
      {
        graduationYear: isNil(newYear)
          ? undefined
          : graduationMonth
          ? new Date(newYear, graduationMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeGraduationMonth = (month: number): void => {
    const newMonth = month != 0 ? month : undefined
    setGraduationMonth(newMonth)
    onChange(
      {
        graduationYear: isNil(newMonth)
          ? undefined
          : graduationYear
          ? new Date(graduationYear, newMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeStudyingHere = () => {
    const newIsStudyingHere = !isStudyingHere
    setIsStudyingHere(newIsStudyingHere)
    setIsHideGraduationDate(newIsStudyingHere)
    const newGraduationYear =
      !newIsStudyingHere && graduationYear && graduationMonth
        ? new Date(graduationYear, graduationMonth, 0).toDateString()
        : ''
    onChange(
      {
        graduationYear: newGraduationYear,
        isStudyingHere: newIsStudyingHere,
      },
      index
    )
  }

  const changeDescription = (value: string): void => {
    onChange({ description: value }, index)
  }

  useEffect(() => {
    setIsHideGraduationDate(education.isStudyingHere ?? false)
    setIsStudyingHere(education.isStudyingHere ?? false)
  }, [])

  return (
    <View style={styles.container}>
      {index !== 0 && (
        <TouchableOpacity
          onPress={onPressClose}
          style={styles.closeButton}
          testID={'education-close-button'}
        >
          <Icon size={20} name="close" color={color.primary} />
        </TouchableOpacity>
      )}
      <View testID="education-form">
        <TextField
          title={`${t('profile.schoolName')}*`}
          value={education.schoolName}
          onChangeText={changeSchool}
        />
        <View style={styles.timeGroup}>
          <View style={styles.textInputContainer}>
            <TextField
              title={`${t('profile.degree')}*`}
              value={education.degree}
              onChangeText={changeDegree}
            />
          </View>
          <View style={styles.separator} />
          <View style={styles.textInputContainer}>
            <TextField
              title={`${t('profile.major')}*`}
              value={education.major}
              onChangeText={changeMajor}
            />
          </View>
        </View>
        <CheckBox
          title={t('profile.isCurrentlyStillStudyingHere')}
          containerStyle={styles.checkBoxContainer}
          textStyle={styles.textCheckBox}
          wrapperStyle={styles.checkBoxWrapper}
          checked={isStudyingHere}
          onPress={changeStudyingHere}
        />
        <View style={styles.timeGroup}>
          <MonthPicker
            onChange={changeEnrollmentMonth}
            selectedValue={enrollmentMonth}
            title={`${t('profile.startMonth')}*`}
          />
          <View style={styles.separator} />
          <YearPicker
            onChange={changeEnrollmentYear}
            selectedValue={enrollmentYear}
            title={`${t('profile.startYear')}*`}
          />
        </View>
        <View
          style={[
            styles.timeGroup,
            isHideGraduationDate
              ? styles.hideGraduationDate
              : styles.showGraduationDate,
          ]}
          testID="end-date-view"
        >
          <MonthPicker
            onChange={changeGraduationMonth}
            selectedValue={graduationMonth}
            title={`${t('profile.endMonth')}*`}
          />
          <View style={styles.separator} />
          <YearPicker
            onChange={changeGraduationYear}
            selectedValue={graduationYear}
            title={`${t('profile.endYear')}*`}
          />
        </View>
        <TextField
          numberOfLines={4}
          value={education.description}
          onChangeText={changeDescription}
          title={`${t('profile.educationDescription')}`}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    borderColor: color.transparent,
    backgroundColor: color.transparent,
    borderWidth: 0,
    marginLeft: 0,
    padding: 0,
    alignSelf: 'baseline',
  },
  checkBoxWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    marginBottom: 40,
  },
  hideGraduationDate: {
    display: 'none',
  },
  separator: {
    width: 40,
  },
  showGraduationDate: {
    display: 'flex',
  },
  textCheckBox: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: '400',
  },
  textInputContainer: {
    flex: 1,
  },
  timeGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
})

export default EducationForm
