import { useFocusEffect } from '@react-navigation/native'
import { find } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import PostList from '~/components/career/features/posts/PostList'
import CardContainer from '~/components/common/atoms/CardContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import EmptyView from '~/components/common/molecules/EmptyView'
import EventAttendanceButton from '~/components/community/atoms/EventAttendanceButton'
import EventAttendeeInvitation from '~/components/community/features/EventAttendeeInvitation'
import EventAttendeeList from '~/components/community/features/EventAttendeeList'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import EventHeader from '~/components/community/molecules/EventHeader'
import EventAttendance from '~/components/community/organisms/EventAttendance'
import EventDetail from '~/components/community/organisms/EventDetail'
import EventTabList, {
  EventTabKey,
} from '~/components/community/organisms/EventTabList'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import CommunityEvent from '~/interfaces/CommunityEvent'
import CommunityEventAttendee from '~/interfaces/CommunityEventAttendee'
import { currentUserSelector } from '~/slices/common/users'
import { communitySelector } from '~/slices/community/community'

type Props = {
  eventId: number
  onBack?: () => void
}

const EventShowTemplate: React.FC<Props> = ({ eventId, onBack }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const community = useSelector(communitySelector)
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const [event, setEvent] = useState<CommunityEvent | undefined>(undefined)
  const [isShowInvitationDialog, setIsShowInvitationDialog] = useState(false)
  const [eventTab, setEventTab] = useState<EventTabKey | undefined>(undefined)
  const [isShowRequireMemberDialog, setIsShowRequireMemberDialog] = useState(
    false
  )

  const isMember =
    find(community?.communityMembers, { userId: currentUser?.id }) !== undefined

  const fetchEvent = useAPI(
    async (api: API): Promise<void> => {
      if (!eventId || isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.communityEvents
          .configPath(community!.id)
          .show<CommunityEvent>(eventId)
        setEvent(response)
      } catch (err) {
        //TODO Show error
      } finally {
        setIsLoading(false)
      }
    },
    [community?.id, eventId, setIsLoading]
  )

  const joinEvent = useAPI(
    async (api: API): Promise<void> => {
      if (!eventId || isLoading || !community) {
        return
      }
      setIsLoading(true)
      try {
        await api.communityEventAttendees
          .configPath(community!.id, eventId)
          .create<{}, CommunityEventAttendee>({})
        setEvent((event) => (event ? { ...event, isJoined: true } : event))
      } catch (err) {
        //TODO Show error
      } finally {
        setIsLoading(false)
      }
    },
    [community?.id, eventId, setIsLoading]
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchEvent()
    }, [fetchEvent])
  )

  return event ? (
    <ScrollView testID="event-show-template">
      {!isPC && <HeaderCommunityDetail />}
      <View
        testID="event-show-container"
        style={isPC ? styles.container : styles.containerMobile}
      >
        {!isPC && <EventTabList onSelect={setEventTab} />}
        {isPC && (
          <View style={styles.leftColumn}>
            <EventTabList onSelect={setEventTab} />
            <EventAttendance
              event={event}
              onInvite={(): void => setIsShowInvitationDialog(true)}
              eventAttendeeList={<EventAttendeeList communityEvent={event} />}
            />
          </View>
        )}
        <View style={styles.separator} />
        <View style={isPC ? styles.rightColumn : undefined}>
          {eventTab === EventTabKey.EVENT_DISCUSSION ? (
            <PostList
              hideForm={!isMember}
              communityEventId={eventId}
              communityId={community!.id}
              listEmptyComponent={
                <EmptyView
                  isPC={isPC}
                  title={t('community.emptyPages.eventDiscussion.title')}
                  imageSource={require('~/assets/images/prompts/nopostsyet.png')}
                />
              }
            />
          ) : (
            <>
              <View style={styles.infoContainer} testID="event-info-container">
                <EventDetail
                  event={event}
                  eventHeader={<EventHeader isPC={isPC} event={event} />}
                />
                <EventAttendanceButton
                  communityEvent={event}
                  onPress={(): void => {
                    isMember ? joinEvent() : setIsShowRequireMemberDialog(true)
                  }}
                />
                <Button
                  type="clear"
                  onPress={onBack}
                  style={styles.backButton}
                  title={t('events.backToEventList')}
                  titleStyle={styles.backButtonTitle}
                />
              </View>
              {!isPC && (
                <EventAttendance
                  event={event}
                  onInvite={(): void => setIsShowInvitationDialog(true)}
                  eventAttendeeList={
                    <EventAttendeeList communityEvent={event} />
                  }
                />
              )}
            </>
          )}
        </View>
      </View>
      {isShowInvitationDialog && (
        <ModalContainer
          visible
          onDismiss={(): void => setIsShowInvitationDialog(false)}
        >
          <CardContainer style={styles.invitationDialog}>
            <EventAttendeeInvitation communityEvent={event} />
          </CardContainer>
        </ModalContainer>
      )}
      <ConfirmationDialog
        isOnlyAccept
        visible={isShowRequireMemberDialog}
        message={t('events.joinCommunityToJoinEvent')}
        onPressAccept={(): void => setIsShowRequireMemberDialog(false)}
      />
    </ScrollView>
  ) : (
    <View testID="event-show-template-skeleton" />
  )
}

const styles = StyleSheet.create({
  backButton: {
    alignSelf: 'baseline',
  },
  backButtonTitle: {
    color: color.textGray,
    textDecorationLine: 'underline',
    ...commonStyles.buttonTextSize,
  },
  container: {
    alignSelf: 'center',
    flexDirection: 'row',
    paddingHorizontal: 4,
    paddingVertical: 20,
    width: 814,
  },
  containerMobile: {
    flex: 1,
    paddingVertical: 10,
  },
  infoContainer: {
    backgroundColor: color.white,
    paddingHorizontal: 30,
  },
  invitationDialog: {
    maxHeight: 600,
    maxWidth: 375,
  },
  leftColumn: {
    alignSelf: 'baseline',
    width: 200,
  },
  rightColumn: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  separator: {
    marginRight: 16,
    marginTop: 16,
  },
})

export default EventShowTemplate
