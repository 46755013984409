import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import Question from '~/interfaces/Question'

export type EventData = { id: number } & Partial<Question>

type QuestionState = {
  eventData: EventData
}

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {} as QuestionState,
  reducers: {
    setEventData(state: QuestionState, action: PayloadAction<EventData>): void {
      state.eventData = action.payload
    },
  },
})

export const { setEventData } = questionsSlice.actions

export default questionsSlice.reducer
