import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

export type Props = {
  onPress: () => void
}

const CrossIcon: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <View style={styles.container} testID="cross-icon">
      <Icon color={color.gray} size={20} name="times" onPress={onPress} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 4,
  },
})

export default CrossIcon
