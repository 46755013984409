import { FontAwesome as Icon } from '@expo/vector-icons'
import moment from 'moment'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Achievement from '~/interfaces/Achievement'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  achievement: Partial<Achievement>
  templateType: TemplateType
}

const AchievementGroupItem: React.FC<Props> = ({
  achievement,
  templateType,
}: Props) => {
  const achievementNameIcon = {
    enjin: (
      <Icon name="circle" size={14} color={color.primary} style={styles.icon} />
    ),
    shades: (
      <Icon name="circle" size={14} color={color.black} style={styles.icon} />
    ),
    formal: undefined,
    minimal: undefined,
  }

  const awardedDateString = achievement?.awardedDate
    ? moment().format('MMM YYYY')
    : ''

  return (
    <View style={styles.container} testID="achievement-group-item">
      <View style={styles.achievementNamContainer}>
        <HorizontalContainer>
          {achievementNameIcon[templateType]}
          <Text style={styles.title}>{achievement.name}</Text>
        </HorizontalContainer>
        <Text>{awardedDateString}</Text>
      </View>
      <Text style={styles.title}>{achievement.giver}</Text>
      <Text>{achievement.description}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  achievementNamContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  container: {
    marginBottom: 20,
  },
  icon: {
    marginRight: 6,
  },
  title: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
})

export default AchievementGroupItem
