import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Divider } from 'react-native-elements'

import { UserProfile } from '~/api/users'
import TitleText from '~/components/common/atoms/TitleText'
import HorizontalSwitch from '~/components/common/molecules/HorizontalSwitch'
import TextField from '~/components/common/molecules/TextField'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import ProfileCard from '~/components/common/molecules/users/ProfileCard'
import color from '~/constants/common/color'
import User from '~/interfaces/User'

type Props = {
  user?: User
  onUpdateProfile?: (profile: UserProfile) => void
  isMe?: boolean
  isPC?: boolean
}

const EducationInfo: React.FC<Props> = ({
  user,
  isMe,
  isPC,
  onUpdateProfile,
}: Props) => {
  const { t } = useTranslation()
  const currentSchool = user?.profile.school
  const [editing, setEditing] = useState(false)
  const [school, setSchool] = useState<string | undefined>(currentSchool)
  const [isGraduated, setIsGraduated] = useState(user?.profile.isGraduated)

  useEffect(() => {
    setSchool(currentSchool)
  }, [currentSchool])

  const startEdit = (): void => {
    setEditing(true)
  }

  const cancelEdit = (): void => {
    setSchool(currentSchool)
    setEditing(false)
  }

  const save = async (): Promise<void> => {
    if (school !== currentSchool || isGraduated !== user?.profile.isGraduated) {
      onUpdateProfile &&
        onUpdateProfile({
          userProfile: { id: user?.id, school, isGraduated },
        } as UserProfile)
    }
    setEditing(false)
  }

  return (
    <View testID="education-info">
      <TitleText testID="education-info-title" style={styles.title}>
        {t('profile.education')}
      </TitleText>
      {editing ? (
        <View style={styles.content}>
          <TextField
            title={t('profile.schoolName')}
            subtitle={t('profile.schoolNameEg')}
            value={school}
            onChangeText={setSchool}
          />
          <HorizontalSwitch
            title={t('profile.studyStatus')}
            label={isGraduated ? t('profile.graduated') : t('profile.student')}
            value={isGraduated || false}
            onValueChange={setIsGraduated}
          />
        </View>
      ) : (
        <View style={isPC ? styles.viewContent : styles.viewContentMobile}>
          <ProfileCard
            icon=""
            iconSize="medium"
            title={t('profile.schoolName')}
            description={user?.profile.school || ''}
          />
          {!isPC && <Divider />}
          <ProfileCard
            icon=""
            iconSize="medium"
            title={t('profile.studyStatus')}
            description={
              user?.profile.isGraduated
                ? t('profile.graduated')
                : t('profile.student')
            }
          />
          {!isPC && <Divider />}
        </View>
      )}
      {(isMe || editing) && (
        <View
          style={styles.textInputButtonAction}
          testID="education-info-action"
        >
          <TextInputAction
            onCancelPress={cancelEdit}
            onSavePress={save}
            onEditPress={startEdit}
            isSaveLoading={false}
            isEdit={true}
            isNormalMode={!editing}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    marginLeft: 10,
    padding: 10,
  },
  textInputButtonAction: {
    alignSelf: 'flex-end',
    marginTop: 16,
  },
  title: {
    color: color.textGray,
    marginTop: 10,
  },
  viewContent: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
  },
  viewContentMobile: {
    marginTop: 16,
    gap: 16,
  },
})

export default EducationInfo
