import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import CancelButton from '~/components/common/atoms/CancelButton'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import EmailInput from '~/components/common/atoms/auth/EmailInput'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import CompanyMemberInvitation from '~/interfaces/CompanyMemberInvitation'

type Props = {
  isShow: boolean
  company: Company
  onCancelInviteDialog: () => void
}

const CompanyInvitePopup = ({
  isShow,
  company,
  onCancelInviteDialog,
}: Props) => {
  const { isPC } = useDevice()
  const { t } = useTranslation()
  const toast = useCustomToast()

  const [email, setEmail] = useState('')
  const [isInviteSuccess, setIsInviteSuccess] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [companyMemberInvitation, setCompanyMemberInvitation] = useState<
    CompanyMemberInvitation
  >()

  const changeEmail = (value: string, isValid: boolean) => {
    setEmailValid(isValid)
    setEmail(value)
    setIsInviteSuccess(false)
  }

  const inviteMember = async (): Promise<void> => {
    try {
      const response = await api.companyMemberInvitation.create(
        email,
        company.id
      )
      setCompanyMemberInvitation(response)
      setIsInviteSuccess(true)
      setEmail('')
      setEmailValid(false)
    } catch (error) {
      toast.showError(error)
    }
  }

  const cancelInvitationMember = () => {
    setIsInviteSuccess(false)
    onCancelInviteDialog()
  }

  return (
    <ModalContainer visible={isShow}>
      <View
        testID="company-invite-dialog"
        style={[styles.container, isPC ? styles.pc : styles.mobile]}
      >
        <Text style={styles.title}>{t('company.inviteHRButton')}</Text>
        {isInviteSuccess && (
          <Text style={styles.message}>
            {t('company.setMemberInviteMessage', {
              email: companyMemberInvitation?.email,
            })}
          </Text>
        )}
        <EmailInput onChangeText={changeEmail} email={email} />
        <View style={styles.separator} />
        <View style={styles.horizontalContainer}>
          <CancelButton onPress={cancelInvitationMember} />
          <Button
            title={t('company.inviteHRTitle')}
            style={styles.inviteButton}
            testID="invite-member-button"
            titleStyle={[commonStyles.bodyTextSize, styles.inviteSeparator]}
            onPress={inviteMember}
            disabled={!emailValid}
          />
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    ...commonStyles.borderRadiusAllCorner,
    backgroundColor: color.white,
    height: 'auto',
    padding: 40,
    alignItems: 'center',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },
  inviteButton: {
    alignItems: 'center',
    marginHorizontal: 15,
  },
  inviteSeparator: {
    paddingHorizontal: 25,
  },
  message: {
    color: color.primaryVariant,
    paddingBottom: 15,
  },
  mobile: {
    maxWidth: 375,
  },
  pc: {
    width: 500,
  },
  separator: {
    marginTop: 20,
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    paddingBottom: 20,
  },
})

export default CompanyInvitePopup
