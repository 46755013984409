import { combineReducers } from '@reduxjs/toolkit'

import device from '~/features/commons/deviceSlice'
import company from '~/slices/career/company'
import posts from '~/slices/career/posts'
import resumes from '~/slices/career/resumes'
import auth from '~/slices/common/auth'
import drawer from '~/slices/common/drawer'
import drawerSearch from '~/slices/common/drawerSearch'
import friendSuggestions from '~/slices/common/friendSuggestions'
import notifications from '~/slices/common/notifications'
import scroll from '~/slices/common/scroll'
import settings from '~/slices/common/settings'
import users from '~/slices/common/users'
import community from '~/slices/community/community'
import answers from '~/slices/forum/answers'
import questions from '~/slices/forum/questions'

const rootReducer = combineReducers({
  auth,
  device,
  friendSuggestions,
  drawer,
  drawerSearch,
  users,
  notifications,
  resumes,
  settings,
  community,
  scroll,
  posts,
  questions,
  answers,
  company,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
