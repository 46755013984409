import { useFocusEffect, useRoute } from '@react-navigation/native'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import { InvitationParams } from '~/api/companyMemberInvitation'
import DefaultLayout from '~/components/DefaultLayout'
import SignupTemplate from '~/components/common/templates/auth/SignupTemplate'
import CompanyMemberInvitation from '~/interfaces/CompanyMemberInvitation'
import { setPageTitle } from '~/utils/navigation'

const CompanySignup: React.FC = () => {
  const { t } = useTranslation()
  const { params } = useRoute()
  const invitationParams = params as InvitationParams
  const [isLoading, setIsLoading] = useState(false)
  const [invitation, setInvitation] = useState<
    CompanyMemberInvitation | undefined
  >(undefined)

  useFocusEffect(() => setPageTitle(t('auth.companySignUp')))

  const fetchCompanyInvitation = async (): Promise<void> => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.companyMemberInvitation.show(invitationParams)
      setInvitation({ ...response, token: invitationParams.token })
      setIsLoading(false)
    } catch (ex) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    !isNil(invitationParams?.token) && fetchCompanyInvitation()
  }, [invitationParams?.token])

  return (
    <DefaultLayout>
      <View testID="company-signup" style={styles.container}>
        <SignupTemplate isCompany invitation={invitation} />
      </View>
    </DefaultLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default CompanySignup
