import { useNavigation } from '@react-navigation/native'
import { BlurView } from 'expo-blur'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import LoginColumn from '~/components/common/features/auth/LoginColumn'
import AnswerItem from '~/components/forum/molecules/answers/AnswerItem'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import Answer from '~/interfaces/Answer'
import { RootStackNavigationProp } from '~/utils/navigation'

const AnswerBlueView: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const [isShowLogin, setIsShowLogin] = useState(false)

  //Dummy data for showing blur answer items.
  const answer: Answer = {
    id: -1,
    body: t('outsider.answerBlurMessage'),
    user: { profile: { name: 'Enjin System' } },
  } as Answer

  useEffect(() => {
    return (): void => {
      setIsShowLogin(false)
    }
  }, [])

  const showLoginView = (): void => {
    isPC ? setIsShowLogin(true) : navigate('login')
  }

  return (
    <>
      <View style={styles.container} testID="answer-blur-view">
        <AnswerItem answer={answer} isPC={isPC} />
        <AnswerItem answer={answer} isPC={isPC} />
        <BlurView
          intensity={10}
          tint="light"
          testID="blur-view"
          style={[StyleSheet.absoluteFill, styles.blurView]}
        >
          <Button
            onPress={showLoginView}
            testID="login-guide-button"
            style={styles.loginButton}
            title={t('outsider.loginGuide')}
            titleStyle={styles.loginButtonText}
          />
        </BlurView>
      </View>
      {isShowLogin && (
        <ModalContainer
          visible={isShowLogin}
          onDismiss={(): void => setIsShowLogin(false)}
        >
          <LoginColumn />
        </ModalContainer>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  blurView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: color.white,
    flex: 1,
    marginBottom: 130,
    marginTop: 20,
  },
  loginButton: {
    alignSelf: 'baseline',
    backgroundColor: color.primary,
    paddingHorizontal: 40,
    ...commonStyles.borderRadiusAllCorner,
    ...commonStyles.shadow,
  },
  loginButtonText: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
  },
})

export default AnswerBlueView
