import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  onPress?: () => void
  disabled?: boolean
  loading?: boolean
}

const DismissButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      testID="dismiss-button"
      type="outline"
      title={t('friendSuggestion.dismiss')}
      buttonStyle={styles.button}
      titleStyle={styles.title as TextStyle}
      onPress={props.onPress}
      loading={props.loading}
      disabled={props.disabled || props.loading}
    />
  )
}

const styles = {
  button: {
    height: 25,
    width: 90,
    padding: 5,
  },
  title: {
    ...commonStyles.buttonTextSize,
    color: color.primary,
    lineHeight: 19,
    paddingTop: 0,
    paddingBottom: 0,
  },
}

export default DismissButton
