import { range } from 'lodash'

import languages from '~/assets/data/lang.json'
import languageLevels from '~/assets/data/languageLevel.json'
import {
  PickerOption,
  pickerValue,
} from '~/components/common/molecules/CustomPicker'

export const getLanguages = (language: string): PickerOption[] =>
  range(0, languages.length + 1).map((_, index) => {
    return index === 0
      ? pickerValue('', '')
      : pickerValue(
          language === 'ja'
            ? languages[index - 1].name
            : languages[index - 1].en_name,
          languages[index - 1].code
        )
  })

export const getLanguageLevels = (language: string): PickerOption[] =>
  range(0, languageLevels.length + 1).map((_, index) => {
    return index === 0
      ? pickerValue('', '')
      : pickerValue(
          language === 'ja'
            ? languageLevels[index - 1].name.toString()
            : languageLevels[index - 1].en_name.toString(),
          languageLevels[index - 1].id.toString()
        )
  })
