import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TextField from '~/components/common/molecules/TextField'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'

type Props = {
  currentPosition: string
  onChangeText: (text: string) => void
}

const EditCurrentPosition: React.FC<Props> = ({
  currentPosition,
  onChangeText,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="current-position-edit">
      <Text style={styles.title}>{t('profile.currentPosition')}</Text>
      <View style={styles.input}>
        <TextField
          value={currentPosition}
          maxLength={500}
          onChangeText={onChangeText}
          numberOfLines={1}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    marginTop: 5,
  },
  title: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginTop: 10,
  },
})

export default EditCurrentPosition
