import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { getCurrentLanguage } from '~/plugins/i18n'
import {
  getFontWeightByLanguage,
  getMediumFontByLanguage,
} from '~/utils/common/themeConfig'

const commonStyles = {
  shadow: {
    elevation: 1,
    shadowColor: color.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  borderRadiusAllCorner: {
    borderRadius: 4,
  },
  borderRadiusBottomCorner: {
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
  },
  borderRadiusTopCorner: {
    borderTopEndRadius: 4,
    borderTopStartRadius: 4,
  },
  bodyTextSize: {
    fontSize: FontSize.GENERAL,
  },
  buttonTextSize: {
    fontSize: FontSize.GENERAL,
  },
  subTextSize: {
    fontSize: FontSize.SUB,
  },
  titleTextSize: {
    fontSize: FontSize.REMARKABLE,
  },
  titleTextStyle: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: getFontWeightByLanguage(getCurrentLanguage()),
    fontFamily: getMediumFontByLanguage(getCurrentLanguage()),
  },
}

export default commonStyles
