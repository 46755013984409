import { keyBy } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import GenderStatistic from '~/components/career/molecules/company/GenderStatistic'
import StatisticCardItem from '~/components/career/molecules/company/StatisticCardItem'
import StatisticLineChart from '~/components/career/molecules/company/StatisticLineChart'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { countriesData } from '~/components/common/molecules/users/CountryPicker'
import CompanyApplicantStatistic from '~/interfaces/CompanyApplicantStatistic'
import i18n from '~/plugins/i18n'

type Props = {
  statistics: CompanyApplicantStatistic[]
  isPC?: boolean
}

const ageRanges = ['20', '20~24', '24~26', '26~28', '28~35', '35~40', '40']
const japaneseLevels = ['N1', 'N2', 'N3', 'N4', 'N5']

const ApplicantStatisticsInfo: React.FC<Props> = ({ statistics, isPC }) => {
  const { t } = useTranslation()
  const countries = useMemo(
    () => keyBy(countriesData(i18n.language), 'value'),
    []
  )

  const formatNationalityStats = (map: Map<string, number>) => {
    const arrayData: { label: string; value: number }[] = []
    map.forEach((value, key) => {
      arrayData.push({
        label: countries[key]?.label ?? t('profile.other'),
        value,
      })
    })
    return arrayData
  }

  const getFormattedStatistics = () => {
    const genderStats = new Map<string, number>()
    const nationalityStats = new Map<string, number>()
    const ageStats = new Map()
    const jpStats = new Map()
    for (const item of statistics) {
      const gender = item.user.profile.sex ?? 'other'
      const genderCount = genderStats.get(gender) ?? 0
      genderStats.set(gender, genderCount + 1)
      const country = item.user.profile.country ?? 'other'
      const countryCount = nationalityStats.get(country) ?? 0
      nationalityStats.set(country, countryCount + 1)
      const birthday = item.user.profile.birthday
      if (birthday) {
        const age = moment().diff(birthday, 'y')
        ageRanges.forEach((range, index) => {
          let ranges: (string | number)[]
          if (index === 0) {
            ranges = [0, range]
          } else if (index === ageRanges.length - 1) {
            ranges = [Number(range) + 1, Infinity]
          } else {
            ranges = range.split('~')
          }
          if (age >= +ranges[0] && age < +ranges[1]) {
            const ageCount = ageStats.get(range) ?? 0
            ageStats.set(range, ageCount + 1)
          }
        })
      }
      const japaneseLevel = item.user.profile.japaneseLevel
      if (japaneseLevel) {
        const jpLevelCount = jpStats.get(japaneseLevel) ?? 0
        jpStats.set(japaneseLevel, jpLevelCount + 1)
      }
    }
    return {
      total: statistics.length,
      gender: {
        title: t('profile.gender'),
        data: [
          {
            label: t('profile.male'),
            value: genderStats.get('male') ?? 0,
          },
          {
            label: t('profile.female'),
            value: genderStats.get('female') ?? 0,
          },
          {
            label: t('profile.other'),
            value: genderStats.get('other') ?? 0,
          },
        ],
      },
      nationality: formatNationalityStats(nationalityStats),
      age: ageRanges.map((range, index) => {
        let label = range
        if (index === 0) {
          label = t('company.statistic.under', { age: range })
        } else if (index === ageRanges.length - 1) {
          label = t('company.statistic.above', { age: range })
        }
        return {
          label,
          value: ageStats.get(range) ?? 0,
        }
      }),
      japaneseAbility: japaneseLevels.map((level) => ({
        label: level,
        value: jpStats.get(level) ?? 0,
      })),
    }
  }

  const applicantData = getFormattedStatistics()
  const chartContainerStyle = isPC ? styles.multipleChartContainer : {}
  const ageContainerStyle = isPC
    ? styles.ageContainer
    : styles.ageContainerMobile

  return (
    <View testID="applicant-statistic-info">
      <HorizontalContainer>
        <View style={styles.totalApplicantContainer}>
          <StatisticCardItem
            item={{
              title: t('company.statistic.totalApplicants'),
              value: applicantData.total,
            }}
          />
        </View>
        <GenderStatistic genderData={applicantData.gender} />
      </HorizontalContainer>
      <StatisticLineChart
        data={applicantData.nationality}
        title={t('company.statistic.nationality')}
      />
      <View style={chartContainerStyle}>
        <View style={ageContainerStyle}>
          <StatisticLineChart
            data={applicantData.age}
            title={t('company.statistic.age')}
          />
        </View>
        <View style={styles.separator} />
        <StatisticLineChart
          data={applicantData.japaneseAbility}
          title={t('company.statistic.japaneseLevel')}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  ageContainer: { alignSelf: 'baseline', width: 293 },
  ageContainerMobile: { flex: 1 },
  multipleChartContainer: {
    flexDirection: 'row',
  },
  separator: {
    marginRight: 14,
    marginTop: 15,
  },
  totalApplicantContainer: {
    width: 191,
    marginRight: 14,
  },
})

export default ApplicantStatisticsInfo
