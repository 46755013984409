import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import SkeletonView from '~/components/common/atoms/SkeletonView'
import AccountSettingEmail from '~/components/common/features/settings/AccountSettingEmail'
import AccountSettingPassword from '~/components/common/features/settings/AccountSettingPassword'
import AccountSettingPhoneNumber from '~/components/common/features/settings/AccountSettingPhoneNumber'
import NotificationsSettings from '~/components/common/features/settings/NotificationsSettings'
import PrivacySettings from '~/components/common/features/settings/PrivacySettings'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import GeneralAccountSettings from '~/components/common/organisms/settings/GeneralAccountSettings'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { SettingsType } from '~/slices/common/settings'

const SettingsTemplate: React.FC = () => {
  const { currentType, account } = useSelector(
    (state: RootState) => state.settings
  )
  const { isPC } = useDevice()
  const accountSettingContainer = isPC ? styles.account : styles.accountMobile

  const getSettingComponent = (): React.ReactNode => {
    switch (currentType) {
      case SettingsType.Account:
        return (
          <CardContainer
            style={accountSettingContainer}
            testID="account-settings"
          >
            <GeneralAccountSettings
              isPC={isPC}
              emailSetting={<AccountSettingEmail />}
              phoneSetting={<AccountSettingPhoneNumber />}
              passwordSetting={<AccountSettingPassword />}
            />
          </CardContainer>
        )
      case SettingsType.Privacy:
        return <PrivacySettings />
      case SettingsType.Notifications:
        return <NotificationsSettings />
    }
  }

  return (
    <ScrollView style={styles.container} testID="settings-template">
      {account ? (
        getSettingComponent()
      ) : (
        <View style={[styles.loadingViewContainer, styles.account]}>
          <SkeletonView style={styles.singleLoadingView} />
          <SkeletonTwoBar />
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  account: {
    alignSelf: 'center',
    backgroundColor: color.white,
    marginTop: 50,
    paddingHorizontal: 100,
    paddingVertical: 40,
    width: 920,
  },
  accountMobile: {
    flex: 1,
  },
  container: {
    backgroundColor: color.baseBackground,
    flex: 1,
  },
  loadingViewContainer: {
    backgroundColor: color.white,
    paddingBottom: 200,
  },
  singleLoadingView: {
    marginBottom: 20,
  },
})

export default SettingsTemplate
