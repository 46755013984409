import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import camelcaseKeys from 'camelcase-keys'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import { RecruimentQueryParams } from '~/api/recruitments'
import CustomPicker from '~/components/common/molecules/CustomPicker'
import { ColorTheme } from '~/constants/common/color'
import { JobSection } from '~/constants/common/navigation'
import { RootContext } from '~/contexts/RootContext'
import useCustomNavigation from '~/hooks/useNavigation'
import User from '~/interfaces/User'
import { navigationRef } from '~/utils/navigation'

type Props = {
  searchInput?: React.ReactElement
  currentUser?: User
}

const JobsHeaderLeft: React.FC<Props> = ({
  searchInput,
  currentUser,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const { setQueryParams } = useCustomNavigation()

  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as RecruimentQueryParams
  const searchParams = camelcaseKeys(routeParams || {}) as RecruimentQueryParams
  const [type, setType] = useState('')

  const typeOptions = [
    { label: t('jobs.pages.all'), value: JobSection.FEED },
    { label: t('jobs.pages.saved'), value: JobSection.SAVED },
  ]
  if (!currentUser?.isCompanyAccount) {
    typeOptions.splice(1, 0, {
      label: t('jobs.pages.applied'),
      value: JobSection.APPLIED,
    })
  }

  const changeCategory = (value: ItemValue): void => {
    const val = value.toString() as JobSection
    if (searchParams.section !== val) {
      setType(val)
      setQueryParams({
        ...searchParams,
        section: val,
      })
    }
  }

  useEffect(() => {
    setType(searchParams?.section ?? '')
  }, [searchParams?.section])

  return (
    <View testID="jobs-header-left" style={isPC ? styles.container : undefined}>
      {isPC && (
        <CustomPicker
          size="none"
          data={typeOptions}
          selectedValue={type}
          colorTheme={isPC ? ColorTheme.Light : ColorTheme.Primary}
          onValueChange={changeCategory}
          isWithoutBorder={isPC}
        />
      )}
      <View
        style={StyleSheet.flatten([
          styles.searchContainer,
          isPC ? { marginHorizontal: 10 } : undefined,
        ])}
        testID="jobs-subheader-search"
      >
        {searchInput}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: 5,
    alignItems: 'center',
  },
  searchContainer: {
    marginTop: 10,
    width: 240,
  },
})

export default JobsHeaderLeft
