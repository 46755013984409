import { MaterialIcons } from '@expo/vector-icons'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

export enum MessageRecipientFilterMenuType {
  All = 'all',
  Recruiters = 'recruiters',
  Candidates = 'candidates',
  Connections = 'connections',
}

type Props = {
  onFilter: (isCompany?: boolean) => void
  isCompanyAccount?: boolean
}

const MessageRecipientFilter: FC<Props> = ({
  onFilter,
  isCompanyAccount = false,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<MessageRecipientFilterMenuType>(
    MessageRecipientFilterMenuType.All
  )

  const options: ControlMenuData[] = [
    {
      id: MessageRecipientFilterMenuType.All,
      title: t('message.filter.allRecipients'),
    },
    isCompanyAccount
      ? {
          id: MessageRecipientFilterMenuType.Candidates,
          title: t('message.filter.candidates'),
        }
      : {
          id: MessageRecipientFilterMenuType.Recruiters,
          title: t('message.filter.recruiters'),
        },
    {
      id: MessageRecipientFilterMenuType.Connections,
      title: t('message.filter.connections'),
    },
  ]

  const setFilterRecipient = (item: ControlMenuData): void => {
    setSelected(item.id as MessageRecipientFilterMenuType)
    const isCompany = item.id === MessageRecipientFilterMenuType.Candidates
    onFilter(
      item.id === MessageRecipientFilterMenuType.All ? undefined : isCompany
    )
  }

  const selectedOption = options.find((option) => option.id === selected)

  return (
    <View style={styles.container} testID="message-recipient-filter">
      <ControlMenu
        data={options}
        menuTrigger={
          <View style={styles.filterMenu}>
            <Text>{selectedOption?.title}</Text>
            <MaterialIcons name="arrow-drop-down" size={15} />
          </View>
        }
        onSelect={setFilterRecipient}
        preferredPlacement={'bottom'}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderColor: color.primary,
    borderRadius: 20,
    borderWidth: 1,
    flex: 1,
  },
  filterMenu: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 1,
  },
})

export default MessageRecipientFilter
