import { BaseAPI } from '~/api/baseAPI'
import Message from '~/interfaces/Message'

export type ShareBodyParams = {
  message: string
  contentId: number
  shareType: 'post' | 'question' | 'answer'
}

export type ShareMessageParams = {
  body: string
  userIds: number[]
}

export class SharesAPI extends BaseAPI {
  path = '/shares'

  async messageToUser(data: ShareMessageParams): Promise<Message> {
    return await this.client.post<Message>(`${this.path}/message_to_user`, {
      share: data,
    })
  }
}
