import { FontAwesome as Icon } from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import { baseURL } from '~/httpClient'
import UserDocument from '~/interfaces/UserDocument'
import { currentUserSelector } from '~/slices/common/users'
import { uriToBlob } from '~/uploader'
import { selectFile, uploadFile } from '~/utils/common/fileUpload'

type Props = {
  onUpload: (userDocument: UserDocument) => void
}

const UploadDocumentItem: React.FC<Props> = ({ onUpload }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const toast = useCustomToast()
  const { isPC } = useDevice()

  const selectDocumentFile = async (): Promise<void> => {
    if (isLoading) {
      return
    }
    const result: DocumentPicker.DocumentResult = await selectFile()
    if (result.type == 'cancel') {
      return
    }
    uploadDocument(
      result.name,
      `/resume/${currentUser?.id}/${Date.now()}`,
      result.uri
    )
  }

  const uploadDocument = async (
    name: string,
    dir: string,
    uri: string
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('name', name)
      formData.append('dir', dir)
      const blob = await uriToBlob(uri)
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        formData.append('file', {
          uri,
          name,
          type: blob.type,
        } as any) //eslint-disable-line @typescript-eslint/no-explicit-any
      } else {
        formData.append('file', blob)
      }
      const response = await uploadFile<UserDocument>(
        `${baseURL}/user_documents`,
        formData
      )
      onUpload(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.showError(error)
    }
  }

  return (
    <TouchableOpacity
      disabled={isLoading}
      onPress={selectDocumentFile}
      testID="upload-document-item"
    >
      <View
        testID="upload-document-item-content"
        style={StyleSheet.flatten([
          styles.container,
          isPC ? styles.containerSizePC : styles.containerSizeMobile,
        ])}
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.uploadContainer}>
            <Icon
              size={24}
              name="plus"
              testID="plus-icon"
              color={color.textGray}
            />
            <Text style={styles.subtitle}>{t('document.upload')}</Text>
            <Text>{t('document.pdf')}</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    borderRadius: 10,
    borderStyle: 'dashed',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: color.textGray,
    marginHorizontal: 5,
    padding: 10,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  subtitle: {
    fontSize: FontSize.GENERAL,
    borderColor: color.textGray,
  },
  uploadContainer: {
    alignItems: 'center',
  },
})

export default UploadDocumentItem
