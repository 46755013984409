import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import api from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import { ResumeBodyParams } from '~/api/resumes'
import Resume from '~/interfaces/Resume'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

export const EDIT_RESUME_START_FORM_INDEX = 0
export const EDIT_RESUME_END_FORM_INDEX = 1

export type ResumeStatus = 'editing' | 'saved'

type ResumeState = {
  isLoading: boolean
  error: string | null
  showTitlePopup: boolean
  current: Partial<Resume>
  currentEditResumeIndex: number
  status?: ResumeStatus
}

export const resumesAdapter = createEntityAdapter<Resume>()

const resumesSlice = createSlice({
  name: 'resumes',
  initialState: resumesAdapter.getInitialState({
    error: null,
    current: {},
    isLoading: false,
    showTitlePopup: false,
    currentEditResumeIndex: 0,
    status: 'editing',
  } as ResumeState),
  reducers: {
    addOne: resumesAdapter.addOne,
    setAll: resumesAdapter.setAll,
    addMany: resumesAdapter.addMany,
    updateOne: resumesAdapter.updateOne,
    updateMany: resumesAdapter.updateMany,
    requestStart(state): void {
      state.error = null
      state.isLoading = true
    },
    resetCurrentResume(state): void {
      state.current = {}
    },
    nextEditResumeIndex(state): void {
      state.currentEditResumeIndex = state.currentEditResumeIndex + 1
    },
    previousEditResumeIndex(state): void {
      state.currentEditResumeIndex = state.currentEditResumeIndex - 1
    },
    resetEditResumeIndex(state): void {
      state.currentEditResumeIndex = 0
    },
    toggleShowTitlePopup(state): void {
      state.showTitlePopup = !state.showTitlePopup
    },
    requestSuccess(state): void {
      state.isLoading = false
    },
    setCurrentResume(state, action: PayloadAction<Resume>): void {
      state.current = action.payload
    },
    updateCurrentResume(state, action: PayloadAction<Partial<Resume>>): void {
      state.current = { ...state.current, ...action.payload }
      state.status = 'editing'
    },
    requestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
    setResumeStatus(state, action: PayloadAction<ResumeStatus>): void {
      state.status = action.payload
    },
  },
})

export const {
  addOne,
  setAll,
  addMany,
  updateOne,
  updateMany,
  requestStart,
  requestSuccess,
  requestFailure,
  setResumeStatus,
  setCurrentResume,
  resetCurrentResume,
  updateCurrentResume,
  nextEditResumeIndex,
  toggleShowTitlePopup,
  previousEditResumeIndex,
  resetEditResumeIndex,
} = resumesSlice.actions

export const createResume = (resume: Partial<Resume>): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.resumes.create<ResumeBodyParams, Resume>({
      userResume: resume,
    })
    dispatch(requestSuccess())
    dispatch(setCurrentResume(response))
  } catch (err) {
    dispatch(requestFailure(String(err.error.detail)))
  }
}

export const updateResume = (resume: Partial<Resume>): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.resumes.update<ResumeBodyParams, Resume>(
      resume.id!,
      { userResume: resume }
    )
    dispatch(setCurrentResume(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const saveAndContinue = (resume: Partial<Resume>): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    await Promise.all([dispatch(updateResume(resume))])
    dispatch(nextEditResumeIndex())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
    throw err
  }
}

export const fetchResumes = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.resumes.index<Resume[], BaseQueryParams>({})
    dispatch(setAll(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
    throw err
  }
}

export const getAllResumes = (state: RootState): Resume[] =>
  resumesAdapter.getSelectors().selectAll(state.resumes)

export const fetchResumeDetail = (id: number): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  try {
    dispatch(requestStart())
    let resume = resumesAdapter
      .getSelectors()
      .selectById(getState().resumes, id)
    if (resume) {
      dispatch(setCurrentResume(resume))
    } else {
      resume = await api.resumes.show<Resume>(id)
      dispatch(setCurrentResume(resume))
    }
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const currentResumeSelector = (state: RootState): Partial<Resume> =>
  state.resumes.current

export const currentEditResumeIndexSelector = (state: RootState): number =>
  state.resumes.currentEditResumeIndex

export default resumesSlice.reducer
