import { TFunction } from 'i18next'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import api from '~/api'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import ReportDialog from '~/components/common/molecules/ReportDialog'
import useCustomToast from '~/hooks/useCustomToast'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'

type Props = {
  post: Post
  user?: User
  onSelectEdit: () => void
  onDeletePost?: (post: Post) => void
}

const PostItemControlMenu: React.FC<Props> = ({
  user,
  post,
  onSelectEdit,
  onDeletePost,
}: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [reportReason, setReason] = useState('')
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isShowReportDialog, setIsShowReportDialog] = useState(false)

  const onPressConfirmDelete = async (): Promise<void> => {
    setShowConfirmDialog(false)
    try {
      await api.posts.deletePost(post.id)
      onDeletePost && onDeletePost(post)
    } catch (error) {
      toast.showError(error)
    }
  }

  const reportPost = useCallback(async () => {
    try {
      await api.reports.create({
        resourceId: post.id,
        resourceType: 'Post',
        reason: reportReason,
      })
      toast.showSuccess(t('report.successReportMsg'))
      setIsShowReportDialog(false)
    } catch (error) {
      toast.showError(error)
    }
  }, [reportReason, post.id])

  const onSelectMenu = (item: ControlMenuData) => {
    switch (item.id) {
      case PostItemMenuType.EDIT_POST:
        onSelectEdit()
        break
      case PostItemMenuType.DEL_POST:
        setShowConfirmDialog(true)
        break
      case PostItemMenuType.REPORT:
        setIsShowReportDialog(true)
        break
      default:
        break
    }
  }

  return (
    <View testID="post-item-control-menu">
      <ControlMenu
        onSelect={onSelectMenu}
        data={getPostItemMenuData({ t, isOwner: user?.id === post.user?.id })}
      />
      <ConfirmationDialog
        isDanger={true}
        visible={isShowConfirmDialog}
        onPressAccept={onPressConfirmDelete}
        message={t('post.confirmRemovePost')}
        onPressCancel={() => setShowConfirmDialog(false)}
      />
      <ReportDialog
        reason={reportReason}
        onPressAccept={reportPost}
        visible={isShowReportDialog}
        onChangeReason={(value) => setReason(value)}
        onPressCancel={() => setIsShowReportDialog(false)}
      />
    </View>
  )
}

export enum PostItemMenuType {
  EDIT_POST,
  DEL_POST,
  REPORT,
}

export const getPostItemMenuData = ({
  t,
  isOwner,
}: {
  t: TFunction
  isOwner: boolean
}): ControlMenuData[] =>
  isOwner
    ? [
        {
          id: PostItemMenuType.EDIT_POST,
          title: t('post.editPost'),
        },
        {
          id: PostItemMenuType.DEL_POST,
          title: t('delete'),
        },
      ]
    : [
        {
          id: PostItemMenuType.REPORT,
          title: t('post.reportPost'),
        },
      ]

export default PostItemControlMenu
