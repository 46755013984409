import { find } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import joinCommunityToViewImage from '~/assets/images/prompts/joincommunitytoview.png'
import emptyPostsImage from '~/assets/images/prompts/nopostsyet.png'
import PostList from '~/components/career/features/posts/PostList'
import EmptyView from '~/components/common/molecules/EmptyView'
import CommunityUserList, {
  CommunityUserListType,
} from '~/components/community/features/CommunityUserList'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import ChannelHeader from '~/components/community/features/channels/ChannelHeader'
import PinnedPostFeed from '~/components/community/features/channels/PinnedPostFeed'
import ChannelList from '~/components/community/organisms/ChannelList'
import { ChannelViewMode } from '~/interfaces/Community'
import { RootState } from '~/rootReducer'

const CommunityTalk: React.FC = () => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const community = useSelector((state: RootState) => state.community.current)
  const { channelMode, current } = useSelector(
    (state: RootState) => state.community.channel
  )
  const currentUser = useSelector((state: RootState) => state.users.current)
  const isMember =
    find(community?.communityMembers, { userId: currentUser?.id }) !== undefined

  const channelHeaderComponent = useMemo(() => <ChannelHeader />, [])

  const renderChannelMode = (): React.ReactNode => {
    if (channelMode === ChannelViewMode.Pinned) {
      return <PinnedPostFeed />
    } else if (channelMode === ChannelViewMode.Member) {
      return <CommunityUserList type={CommunityUserListType.Channel} />
    } else {
      return (
        (community?.id && current?.id && (
          <PostList
            channel={current}
            hideForm={!isMember}
            communityId={community.id}
            listEmptyComponent={
              <EmptyView
                isPC={isPC}
                imageSource={
                  isMember ? emptyPostsImage : joinCommunityToViewImage
                }
                title={
                  isMember
                    ? t('community.emptyPages.post.title')
                    : t('community.joinToViewPostMessage')
                }
                subtitle={
                  isMember ? t('community.emptyPages.post.subtitle') : undefined
                }
              />
            }
            renderListHeaderComponent={() => (
              <>
                {!isPC && <HeaderCommunityDetail />}
                {channelHeaderComponent}
                {isPC && <View style={styles.separator} />}
              </>
            )}
          />
        )) ??
        channelHeaderComponent
      )
    }
  }

  return (
    <View testID="community-talk" style={styles.container}>
      {isPC ? (
        <View style={styles.contentContainer}>
          <ChannelList />
          <View testID="community-talk-right-column" style={styles.rightColumn}>
            {/*<ChannelHeader />*/}
            {/*<View style={styles.separator} />*/}
            {renderChannelMode()}
          </View>
        </View>
      ) : (
        <>{renderChannelMode()}</>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexDirection: 'row',
  },
  rightColumn: {
    flex: 1,
  },
  separator: {
    marginBottom: 20,
  },
})

export default CommunityTalk
