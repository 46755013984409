import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  onPress?: () => void
}

const CreateMessage: React.FC<Props> = ({ onPress }: Props) => (
  <View testID="create-message" style={styles.container}>
    <Icon size={24} name="edit" color={color.darkGray} onPress={onPress} />
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.lightMask,
    borderRadius: 30,
    padding: 4,
  },
})

export default CreateMessage
