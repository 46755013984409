import React from 'react'

import MessageMenuLink from '~/components/career/atoms/messages/MessageMenuLink'
import HeaderMenuLink from '~/components/common/atoms/HeaderMenuLink'

type Props = {
  lastRouteName: string
}

const CareerHeaderMenuCompany: React.FC<Props> = ({ lastRouteName }: Props) => {
  return (
    <>
      <HeaderMenuLink
        to={{ screen: 'jobs' }}
        translationKey={'menu.jobs'}
        isActive={lastRouteName === 'jobs'}
      />
      <HeaderMenuLink
        to={{ screen: 'jobsDashboard' }}
        translationKey={'menu.jobsDashboard'}
        isActive={lastRouteName === 'jobsDashboard'}
      />
      <MessageMenuLink isActive={lastRouteName === 'messages'} />
    </>
  )
}

export default CareerHeaderMenuCompany
