import React from 'react'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import SkeletonAvatar from '~/components/common/atoms/SkeletonAvatar'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import color from '~/constants/common/color'

const SkeletonTimelineItem: React.FC = () => (
  <CardContainer
    style={[styles.itemContainer, styles.skeletonView]}
    testID="skeleton-timeline-item"
  >
    <HorizontalContainer>
      <SkeletonAvatar size={ProfileAvatarSize.SMALL} />
      <View style={styles.rightTitle}>
        <SkeletonTwoBar />
      </View>
    </HorizontalContainer>
    <View style={styles.separator} />
    <SkeletonTwoBar />
  </CardContainer>
)

const styles = StyleSheet.create({
  itemContainer: {
    marginBottom: 20,
    overflow: 'hidden',
  },
  rightTitle: {
    marginLeft: 10,
    width: 100,
  },
  separator: {
    marginTop: 20,
  },
  skeletonView: {
    backgroundColor: color.white,
    paddingBottom: 80,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
})

export default SkeletonTimelineItem
