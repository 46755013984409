import {
  createConsumer,
  Consumer,
  createWebSocketURL,
} from '@rails/actioncable'
import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'

import { baseURL } from '~/httpClient'

const useStream = (accessToken?: string): Consumer | undefined => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    global.addEventListener = () => {
      return
    }
    global.removeEventListener = () => {
      return
    }
  }

  const stream = useMemo(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      return createConsumer(
        createWebSocketURL(`ws://${baseURL}/streams?token=${accessToken}`)
      )
    }
    return createConsumer(
      createWebSocketURL(`${baseURL}/streams?token=${accessToken}`)
    )
  }, [accessToken])

  useEffect(() => {
    stream && stream.connection.disconnected && stream.connect()
    return () => {
      stream && stream.disconnect()
    }
  }, [stream])

  return stream
}

export default useStream
