import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import TextField from '~/components/common/molecules/TextField'
import { FontSize } from '~/constants/common/font'

type Props = {
  givenName?: string
  familyName?: string
  onChangeLastName: (text: string) => void
  onChangeFirstName: (text: string) => void
  isPC?: boolean
  isEnglish?: boolean
}

const FullNameInput: React.FC<Props> = ({
  isPC,
  isEnglish,
  givenName,
  familyName,
  onChangeLastName,
  onChangeFirstName,
}: Props) => {
  const { t } = useTranslation()

  const containerStyle = isPC ? styles.container : styles.mobileContainer
  const lastNameTitle = isEnglish
    ? t('profile.englishLastName')
    : t('profile.lastName')
  const firstNameTitle = isEnglish
    ? t('profile.englishFirstName')
    : t('profile.firstName')

  return (
    <View style={containerStyle} testID="full-name-input">
      <TextField
        maxLength={15}
        value={familyName}
        size={FontSize.GENERAL}
        title={`${firstNameTitle}*`}
        onChangeText={onChangeFirstName}
      />
      <View style={styles.separator} />
      <TextField
        maxLength={15}
        value={givenName}
        title={`${lastNameTitle}*`}
        size={FontSize.GENERAL}
        onChangeText={onChangeLastName}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  mobileContainer: {
    justifyContent: 'space-between',
  },
  separator: {
    height: 10,
    width: 40,
  },
})

export default FullNameInput
