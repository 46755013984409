import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import defaultImage from '~/assets/images/subscriptions/secure_banner.png'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export type PricingDetailData = {
  title: string
  content: string
}
export type Props = {
  title: string
  color: string
  imageSrc?: string
  content: string
}

export const PricingDetail = ({ title, imageSrc, content, color }: Props) => (
  <View
    testID="pricing-detail"
    style={[styles.container, { backgroundColor: color }]}
  >
    <View style={styles.content}>
      {imageSrc && (
        <Image
          style={styles.image}
          testID="url-preview-image"
          source={imageSrc || defaultImage}
        />
      )}
      <View style={styles.detail}>
        <Text style={styles.detailTitle}>{title}</Text>
        <Text style={styles.detailContent}>{content}</Text>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 20,
    width: '100%',
  },
  content: {
    maxWidth: 900,
    flexDirection: 'column',
    paddingVertical: 50,
  },
  detail: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  detailContent: {
    fontSize: FontSize.SUB,
  },
  detailTitle: {
    color: color.darkTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  image: {
    aspectRatio: 920 / 162,
    marginBottom: 30,
    resizeMode: 'cover',
    width: '100%',
  },
})
