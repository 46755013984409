import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import FormLayout from '~/components/career/molecules/resume/FormLayout'
import TextField from '~/components/common/molecules/TextField'
import Resume from '~/interfaces/Resume'

type Props = {
  isPC: boolean
  interestedIndustry?: string
  interestedIndustryReason?: string
  onChange: (value: Partial<Resume>) => void
}

const InterestedFieldsForm: React.FC<Props> = ({
  isPC,
  onChange,
  interestedIndustry,
  interestedIndustryReason,
}: Props) => {
  const { t } = useTranslation()

  const handleChangeField = (value: string): void => {
    onChange({ interestedIndustry: value })
  }

  const handleChangeReason = (value: string): void => {
    onChange({ interestedIndustryReason: value })
  }

  return (
    <FormLayout
      isPC={isPC}
      hasAddAction={false}
      title={t('resume.interestFieldsTitle')}
    >
      <View style={styles.childrenContainer}>
        <TextField
          title={t('resume.field')}
          onChangeText={handleChangeField}
          value={interestedIndustry}
        />
        <TextField
          maxLength={250}
          numberOfLines={8}
          title={t('resume.reason')}
          onChangeText={handleChangeReason}
          value={interestedIndustryReason}
          shouldShowCounter
        />
      </View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  childrenContainer: {
    marginBottom: 20,
    marginTop: 10,
  },
})

export default InterestedFieldsForm
