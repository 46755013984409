import React from 'react'
import type { ComponentProps, ReactElement } from 'react'
import { FlatList, Platform, ScrollView } from 'react-native'

type ScrollViewProps = ComponentProps<typeof ScrollView>

class WrappedWebScrollView extends React.Component<ScrollViewProps> {
  scrollRef = React.createRef<ScrollView>()

  render(): ReactElement {
    return <ScrollView {...this.props} ref={this.scrollRef} />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNativeScrollRef(): any {
    if (this.scrollRef.current != null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this.scrollRef.current as any)._scrollNodeRef
    }
  }
}

class WebFlatList<ItemT> extends FlatList<ItemT> {
  render(): ReactElement {
    return (
      <FlatList
        {...this.props}
        renderScrollComponent={this.renderScrollComponent}
      />
    )
  }

  renderScrollComponent = (props: ScrollViewProps): ReactElement => (
    <WrappedWebScrollView {...props} />
  )
}

const getFlatList = (): typeof FlatList => {
  switch (Platform.OS) {
    case 'web':
      // FIXME: Remove this workaround
      //
      // Use custom FlatList implemenation only for web, to avoid causing errors.
      // This issue exists with react-native-web@0.14.10. And this might be fixed by upgrading the library.
      // For more details, please see: https://github.com/JAPI-org/enjin-front/pull/445
      return WebFlatList
    default:
      return FlatList
  }
}

export default getFlatList()
