import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'

import SearchInput from '~/components/common/atoms/SearchInput'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import { closeDrawer } from '~/utils/navigation'

type Props = {
  isMobile: boolean
}

const SearchDrawer: React.FC<Props> = ({ isMobile }: Props) => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const containerStyle = isMobile ? styles.containerMobile : styles.container
  const [searchWord, setSearchWord] = useState('')

  const startSearching = (): void => {
    setQueryParams({ q: searchWord })
    closeDrawer()
  }

  return (
    <ScrollView testID="search-drawer" style={containerStyle}>
      <TitleText style={styles.title} testID="search-drawer-title">
        {t('search.search')}
      </TitleText>
      <View style={styles.searchContainer} testID="search-input-container">
        <SearchInput
          value={searchWord}
          onSubmit={startSearching}
          onChangeText={setSearchWord}
          placeholderKey="search.searchForumQuestionsPlaceholder"
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingLeft: 25,
    paddingRight: 40,
    width: 350,
  },
  containerMobile: {
    backgroundColor: color.white,
    paddingHorizontal: 25,
    paddingTop: 42,
  },
  searchContainer: {
    marginTop: 20,
  },
  title: {
    color: color.textGray,
    marginTop: 42,
  },
})

export default SearchDrawer
