import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import FormAction from '~/components/common/atoms/users/FormAction'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
  editable?: boolean
  title?: string
  content?: string
  children?: React.ReactNode
  actionComponent?: React.ReactElement
  onSubmit?: (value: string) => void
}

const JobInfoBaseLayout: React.FC<Props> = ({
  isPC,
  title,
  content,
  children,
  actionComponent,
  editable,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>(content ?? '')
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const submit = async () => {
    onSubmit?.(value)
    setIsEditing(false)
  }

  return (
    <View
      testID="job-info-base-layout"
      style={isPC ? styles.pc : styles.mobile}
    >
      <Text style={styles.title} testID="job-info-base-title">
        {title}
      </Text>
      {children}
      {!isEmpty(content) && !isEditing && (
        <Text style={styles.content} testID="job-info-base-content">
          {content}
        </Text>
      )}
      {isEditing && (
        <TextField numberOfLines={4} onChangeText={setValue} value={value} />
      )}
      {actionComponent}
      {editable && (
        <View style={styles.btnSection} testID="button-section">
          {isEditing ? (
            <FormAction
              onCancel={() => setIsEditing(false)}
              isLoading={false}
              disabled={!isEditing}
              onSubmit={submit}
            />
          ) : (
            <Button
              type="solid"
              title={t('edit')}
              onPress={(): void => setIsEditing(true)}
              buttonStyle={[
                styles.btnContainer,
                { backgroundColor: color.primary },
              ]}
              titleStyle={[commonStyles.buttonTextSize, { color: color.white }]}
            />
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  btnContainer: {
    height: 30,
    width: 125,
  },
  btnSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  content: {
    fontSize: FontSize.REMARKABLE,
    lineHeight: 25.6,
    paddingVertical: 10,
  },
  mobile: {
    backgroundColor: color.white,
    paddingHorizontal: 32,
    paddingVertical: 20,
  },
  pc: {
    backgroundColor: color.white,
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  title: {
    fontWeight: 'bold',
    color: color.textGray,
    ...commonStyles.titleTextSize,
  },
})

export default JobInfoBaseLayout
