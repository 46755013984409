import React, { useCallback, useState } from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'

import MediaBox from '~/components/common/molecules/media/MediaBox'
import PagingControl from '~/components/common/molecules/media/MediaViewer/PagingControl'
import color from '~/constants/common/color'
import type Media from '~/interfaces/Media'
import { isVideo } from '~/interfaces/Video'

type Props = {
  media: Media[]
  isPC: boolean
  selected: number
  onDismiss?: () => void
}

type PageControlsProps = {
  onPressPrev?: () => void
  onPressNext?: () => void
}

const PageControls: React.FC<PageControlsProps> = ({
  onPressPrev,
  onPressNext,
}: PageControlsProps) => (
  <>
    <View
      style={StyleSheet.flatten([
        styles.pagingControl,
        styles.pagingControlLeft,
      ])}
      testID="media-viewer-left-paging-control"
    >
      <PagingControl direction="left" onPress={onPressPrev} />
    </View>
    <View
      style={StyleSheet.flatten([
        styles.pagingControl,
        styles.pagingControlRight,
      ])}
      testID="media-viewer-right-paging-control"
    >
      <PagingControl direction="right" onPress={onPressNext} />
    </View>
  </>
)

const MediaViewer: React.FC<Props> = ({ media, isPC, selected }: Props) => {
  const mediaNum = media.length
  const [index, setIndex] = useState(selected)
  const [active, setActive] = useState(false)
  const showNext = useCallback(() => {
    setIndex((index + 1) % mediaNum)
  }, [index, mediaNum, setIndex])
  const showPrev = useCallback(() => {
    setIndex((index + mediaNum - 1) % mediaNum)
  }, [index, mediaNum, setIndex])

  const handleActive = useCallback(
    (active: boolean) => {
      setActive(active)
    },
    [setActive]
  )

  const shownMedia = media[index]
  if (shownMedia == null) {
    return null
  }

  const uri = isVideo(shownMedia)
    ? shownMedia.presignedUrl
    : shownMedia.urls.original

  const controls =
    mediaNum > 1 ? (
      <PageControls onPressPrev={showPrev} onPressNext={showNext} />
    ) : null

  return (
    <SafeAreaView
      testID="media-viewer"
      style={isPC ? styles.pcBody : styles.mobileBody}
    >
      <View style={styles.content}>
        <MediaBox
          uri={uri}
          video={isVideo(shownMedia)}
          onActive={handleActive}
        />
        {active ? null : controls}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: color.black,
    flex: 1,
  },
  mobileBody: {
    backgroundColor: color.black,
    minHeight: 215,
    width: '100%',
  },
  pagingControl: {
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '20%',
    zIndex: 1,
  },
  pagingControlLeft: {
    left: 0,
  },
  pagingControlRight: {
    right: 0,
  },
  pcBody: {
    backgroundColor: color.white,
    height: '100%',
    flex: 1,
  },
})

export default MediaViewer
