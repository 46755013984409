import { useFocusEffect } from '@react-navigation/native'
import { isEmpty, isEqual, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'

import api, { API } from '~/api'
import CustomSwitch from '~/components/common/atoms/CustomSwitch'
import TitleText from '~/components/common/atoms/TitleText'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import {
  PricingDetail,
  Props as PricingDetailProps,
} from '~/components/common/molecules/PricingDetail'
import SubscriptionPlanCard from '~/components/common/molecules/subscriptions/SubscriptionPlanCard'
import { SubscriptionPlanCompare } from '~/components/common/molecules/subscriptions/SubscriptionPlanCompare'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { currentUserSelector, fetchCurrentUser } from '~/slices/common/users'
import { navigate } from '~/utils/navigation'

type Props = {
  onSelectPlan?: (plan: SubscriptionPlan) => void
  onLoadPlans?: (plans: SubscriptionPlan[]) => void
}

const PricingCardTemplate: React.FC<Props> = ({
  onLoadPlans,
  onSelectPlan,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)

  const [isMonthly, setIsMonthly] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >()
  const [pricingCards, setPricingCards] = useState<SubscriptionPlan[]>()
  const [isShowSecureCheckout, setIsShowSecureCheckout] = useState(false)
  const [isShowFreePlanDialog, setIsShowFreePlanDialog] = useState(false)
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan>()

  const currentSubscription = currentUser?.subscription

  const dataPricingDetail: PricingDetailProps[] = [
    {
      title: t('subscriptions.messagingCandidates'),
      color: color.lightGray,
      content: t('subscriptions.messagingCandidatesDetails'),
    },
    {
      title: t('subscriptions.jobListings'),
      color: color.white,
      content: t('subscriptions.jobListingsDetails'),
    },
    {
      title: t('subscriptions.candidateRecommendations'),
      color: color.lightGray,
      content: t('subscriptions.candidateRecommendationsDetails'),
    },
    {
      title: t('subscriptions.enjinJobPostingService'),
      color: color.white,
      content: t('subscriptions.enjinJobPostingServiceDetails'),
    },
    {
      title: t('subscriptions.enjinOfflineNetworking'),
      color: color.lightGray,
      content: t('subscriptions.enjinOfflineNetworkingDetails'),
    },
  ]

  const fetchSubscriptionPlan = useAPI(
    async (api: API): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.subscriptionPlans
          .configPath()
          .index<SubscriptionPlan[], {}>({})
        setSubscriptionPlans(response)
        onLoadPlans?.(response)
      } catch (e) {
        toast.showError(e)
      } finally {
        api.isAlive && setIsLoading(false)
      }
    },
    [setIsLoading]
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchSubscriptionPlan()
    }, [fetchSubscriptionPlan])
  )

  useEffect(() => {
    if (!isEmpty(subscriptionPlans)) {
      const data = isMonthly
        ? subscriptionPlans?.filter((plan) => {
            return plan.period === 'month'
          })
        : subscriptionPlans?.filter((plan) => {
            return plan.period === 'year'
          })
      setPricingCards(data)
    }
  }, [subscriptionPlans, isMonthly])

  const toggleIsMonthly = () => {
    setIsMonthly(!isMonthly)
  }

  const changePricingCards = (plan: SubscriptionPlan) => {
    setSubscriptionPlan(plan)
    setIsShowSecureCheckout(true)
  }

  const switchFreeDialog = () => {
    setIsShowFreePlanDialog(!isShowFreePlanDialog)
  }

  const switchFreeSubscription = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)

    try {
      await api.subscriptionPlans.switchFree({
        planId: subscriptionPlan?.id,
      })
      await dispatch(fetchCurrentUser())
      setIsShowFreePlanDialog(false)
      navigate('careerMain', {
        screen: 'companyPanel',
      })
    } catch (error) {
      toast.showError(`Error processing subscription: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.body} testID="pricing-card-template">
        <View style={styles.content}>
          <TitleText style={styles.title}>
            {t('pricingCard.chooseYourSubscriptionPLan')}
          </TitleText>
          <View style={styles.switchContainer}>
            <CustomSwitch
              selectionMode={1}
              option1={t('pricingCard.monthly')}
              option2={t('pricingCard.annual')}
              onSelect={toggleIsMonthly}
            />
          </View>
          <View
            style={
              isPC ? styles.pricingContainerPC : styles.pricingContainerMobile
            }
            testID="pricing-card-list"
          >
            {pricingCards?.map((plan, index) => {
              const isCurrentPlan = isEqual(
                plan.id,
                currentSubscription?.subscriptionPlan?.id
              )
              const selectable =
                !isCurrentPlan ||
                (isCurrentPlan &&
                  (currentSubscription?.expired ||
                    currentSubscription?.inactive) &&
                  (plan?.price ?? 0) > 0)
              return (
                <SubscriptionPlanCard
                  key={index}
                  isPC={isPC}
                  subscriptionPlan={plan}
                  selectable={selectable}
                  onSelect={changePricingCards}
                  hasCurrentText={isCurrentPlan}
                  ratioWidth={1 / pricingCards.length}
                  isSelected={isEqual(plan.id, subscriptionPlan?.id)}
                />
              )
            })}
          </View>
          {isShowSecureCheckout && !isNil(subscriptionPlan) && (
            <>
              <View style={styles.secureCheckout}>
                <Text style={styles.secureCheckoutText}>
                  {'*'}
                  {t('landing.allPricesGivenAreWithoutTax')}
                </Text>
                <Button
                  type="solid"
                  title={t('next')}
                  testID="pricing-card-button"
                  style={styles.button}
                  onPress={() =>
                    subscriptionPlan.price === 0
                      ? switchFreeDialog()
                      : onSelectPlan?.(subscriptionPlan!)
                  }
                  containerStyle={styles.buttonContainer}
                />
              </View>
              {isShowFreePlanDialog && (
                <ConfirmationDialog
                  isDanger={true}
                  visible={isShowFreePlanDialog}
                  onPressCancel={switchFreeDialog}
                  onPressAccept={() => switchFreeSubscription()}
                  message={t('company.switchToFreeSubscriptionPlan')}
                />
              )}
            </>
          )}
        </View>
      </View>
      <SubscriptionPlanCompare
        pricingCards={pricingCards}
        currentPlan={currentUser?.subscription?.subscriptionPlan?.id}
        isPC={isPC}
      />
      <View style={styles.pricingDetail} testID="pricing-detail">
        <View style={styles.pricingDetailView}>
          <Text style={styles.pricingDetailTitle}>
            {t('pricingCard.detailsAboutFeatures')}
          </Text>
        </View>
        {dataPricingDetail.map((item, index) => {
          return (
            <PricingDetail
              color={item.color}
              title={item.title}
              content={item.content}
              key={`${index}`}
            />
          )
        })}
        {/* TODO: Show me when FAQ page is ready or create new component for me */}
        {/* <Pressable style={styles.faq}>
          <Text style={styles.faqText}>FAQ</Text>
        </Pressable> */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: color.lightGray,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
    marginBottom: 40,
    width: '100%',
  },
  button: {
    marginTop: 10,
  },
  buttonContainer: {
    width: '100%',
  },
  container: {
    width: '100%',
  },
  content: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  pricingContainerMobile: {
    flexDirection: 'column',
  },
  pricingContainerPC: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  pricingDetail: {
    alignItems: 'center',
    backgroundColor: color.white,
    width: '100%',
    textAlign: 'center',
  },
  pricingDetailTitle: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
    color: color.darkTitleText,
    marginTop: 30,
  },
  pricingDetailView: {
    backgroundColor: color.lightGray,
    width: '100%',
  },
  secureCheckout: {
    alignItems: 'center',
  },
  secureCheckoutText: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  switchContainer: {
    alignItems: 'center',
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    paddingVertical: 20,
  },
})

export default PricingCardTemplate
