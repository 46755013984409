import React, { useState } from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  selectionMode: number
  option1: string
  option2: string
  onSelect: () => void
}

const CustomSwitch = ({ selectionMode, option1, option2, onSelect }: Props) => {
  const [getSelectionMode, setSelectionMode] = useState(selectionMode)

  const bgColor =
    getSelectionMode == 1
      ? { backgroundColor: color.darkGray }
      : { backgroundColor: color.textGray }
  const bgColor2 =
    getSelectionMode == 2
      ? { backgroundColor: color.darkGray }
      : { backgroundColor: color.textGray }

  const updatedSwitch = (value: number) => {
    setSelectionMode(value)
    onSelect()
  }

  return (
    <View testID="custom-switch">
      <View style={styles.container}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitch(1)}
          style={[styles.touchable, bgColor]}
        >
          <Text style={styles.title}>{option1}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitch(2)}
          style={[styles.touchable, bgColor2]}
        >
          <Text style={styles.title}>{option2}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.textGray,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 34,
    width: 205,
  },
  title: {
    color: color.white,
  },
  touchable: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
  },
})

export default CustomSwitch
