import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { CommunityQueryParams } from '~/api/communities/communities'
import CustomPicker from '~/components/common/molecules/CustomPicker'
import { ColorTheme } from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'

type Props = {
  onClickCreate: () => void
}

const CommunityHeaderFilters: React.FC<Props> = () => {
  const route = useRoute()
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const searchParams = camelcaseKeys(route.params || {}) as CommunityQueryParams
  const [category, setCategory] = useState(searchParams.category)

  const categoryOptions = [
    { label: t('community.type.allTypes'), value: 'any' },
    { label: t('community.type.school'), value: 'school' },
    { label: t('community.type.business'), value: 'business' },
    { label: t('community.type.organization'), value: 'organization' },
    { label: t('community.type.npo'), value: 'npo' },
    { label: t('community.type.hobby'), value: 'hobby' },
  ]

  const changeCategory = (value: ItemValue): void => {
    const val = value.toString()
    const category = val === 'any' ? undefined : val
    if (searchParams.category !== category) {
      setQueryParams({ ...searchParams, category })
      setCategory(val)
    }
  }

  return (
    <View testID="community-filters" style={styles.container}>
      <CustomPicker
        size="medium"
        data={categoryOptions}
        selectedValue={category}
        colorTheme={ColorTheme.Light}
        onValueChange={changeCategory}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 5,
  },
})

export default CommunityHeaderFilters
