import { useFocusEffect } from '@react-navigation/native'
import { MediaTypeOptions } from 'expo-image-picker'
import { first, isNil } from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Avatar } from 'react-native-elements'

import api from '~/api'
import HeaderJobContent from '~/components/career/molecules/jobs/HeaderJobContent'
import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import FormAction from '~/components/common/atoms/users/FormAction'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import ProfileBanner, {
  ProfileBannerAction,
} from '~/components/common/molecules/ProfileBanner'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import TextField from '~/components/common/molecules/TextField'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { RootContext } from '~/contexts/RootContext'
import useImagePicker from '~/hooks/useImagePicker'
import Image from '~/interfaces/Image'
import Media from '~/interfaces/Media'
import Recruitment from '~/interfaces/Recruitment'
import { setPageTitle } from '~/utils/navigation'

type Props = {
  editable?: boolean
  job: Partial<Recruitment>
  bannerComponent?: React.ReactElement
  footerRightComponent?: React.ReactElement
  onSubmit?: (value: Partial<Recruitment>) => void
  onUpdateJob?: (value: Partial<Recruitment>) => void
}

const HeaderJob: React.FC<Props> = ({
  job,
  editable,
  onSubmit,
  onUpdateJob,
  footerRightComponent,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const [subject, setSubject] = useState(job.subject ?? '')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [headOfficeArea, setHeadOfficeArea] = useState(job.headOfficeArea ?? '')

  const jobHeaderImage = job.image as Image

  useFocusEffect(() => setPageTitle(job.subject ?? job.companyName))

  const bannerMenuData: ControlMenuData[] = isNil(job?.image)
    ? [
        {
          id: ProfileBannerAction.CHANGE,
          title: t('banner.changeBanner'),
        },
      ]
    : [
        {
          id: ProfileBannerAction.CHANGE,
          title: t('banner.changeBanner'),
        },
        {
          id: ProfileBannerAction.REMOVE,
          title: t('banner.removeBanner'),
        },
      ]

  const pressSubmit = async () => {
    onSubmit?.({ subject, headOfficeArea })
    setIsEditing(false)
  }

  const renderFooterRightComponent = (): React.ReactElement => {
    if (footerRightComponent) return footerRightComponent
    if (!editable) return <></>
    return (
      <View style={styles.btnSection} testID="footer-right-container">
        {isEditing ? (
          <FormAction
            onCancel={() => setIsEditing(false)}
            isLoading={false}
            disabled={!isEditing}
            onSubmit={pressSubmit}
          />
        ) : (
          <Button
            testID="edit-button"
            type="solid"
            title={t('edit')}
            onPress={(): void => setIsEditing(true)}
            buttonStyle={[
              styles.btnContainer,
              { backgroundColor: color.primary },
            ]}
            titleStyle={[commonStyles.buttonTextSize, { color: color.white }]}
          />
        )}
      </View>
    )
  }

  const editableComponent = editable && (
    <>
      <TextField
        value={subject}
        numberOfLines={2}
        onChangeText={setSubject}
        placeholder={t('jobs.jobSubject')}
      />
      <TextField
        numberOfLines={1}
        value={headOfficeArea}
        onChangeText={setHeadOfficeArea}
        placeholder={t('profile.officeLocation')}
      />
    </>
  )

  const updateJobImage = async (media: Media[]) => {
    if (isNil(job.companyId) || isNil(job.id)) {
      return
    }
    const response = await api.companyRecruitments
      .configPath(job.companyId)
      .update<Partial<Recruitment>, Recruitment>(job.id, {
        image: first(media)?.signedId ?? null,
      })

    onUpdateJob?.(response)
  }

  const pickBanner = useImagePicker({
    isDirectUpload: true,
    kind: 'banner',
    mediaTypes: MediaTypeOptions.Images,
    allowsMultipleSelection: false,
    onUpload: updateJobImage,
  })

  const onSelectBannerMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case ProfileBannerAction.REMOVE:
        updateJobImage([])
        break
      case ProfileBannerAction.CHANGE:
        pickBanner()
    }
  }

  const companyImageProps = { transition: true, transitionDuration: 100 }

  const infoComponent = (
    <>
      <ProfileInfo
        isPC={isPC ?? true}
        avatarComponent={
          <Avatar
            rounded
            size={isPC ? 125 : 90}
            imageProps={companyImageProps}
            containerStyle={styles.avatar}
            source={
              !isNil(job?.company?.image?.urls.medium)
                ? { uri: job?.company?.image?.urls.medium }
                : require('~/assets/images/career/empty-job-portrait.png')
            }
          />
        }
        rightComponent={
          <View
            testID="header-job-content-container"
            style={isEditing ? styles.infoRowEdit : styles.infoRow}
          >
            <HeaderJobContent
              job={job}
              isEditing={isEditing}
              editableComponent={editableComponent}
            />
          </View>
        }
      />
      {!isPC && (
        <View style={styles.actionGroup}>{renderFooterRightComponent()}</View>
      )}
    </>
  )

  return (
    <View testID="header-job" style={isPC ? styles.container : undefined}>
      <ProfileHeader
        isPC={isPC ?? true}
        bannerComponent={
          <ProfileBanner
            imagePath={jobHeaderImage?.urls.original}
            controlMenu={
              editable ? (
                <ControlMenu
                  data={bannerMenuData}
                  onSelect={onSelectBannerMenu}
                  menuTrigger={<ControlMenuButton inverted />}
                />
              ) : undefined
            }
          />
        }
        infoComponent={infoComponent}
        footerRightComponent={isPC ? renderFooterRightComponent() : undefined}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  actionGroup: {
    alignItems: 'flex-end',
    paddingTop: 10,
  },
  avatar: {
    borderColor: color.white,
    borderRadius: 125,
    borderWidth: 4,
    backgroundColor: color.white,
  },
  btnContainer: {
    height: 30,
    width: 90,
  },
  btnSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  container: {
    alignSelf: 'center',
    width: 814,
    marginTop: 10,
  },
  infoRow: {
    flex: 1,
    maxWidth: 600,
  },
  infoRowEdit: {
    flex: 1,
    width: '100%',
  },
})

export default HeaderJob
