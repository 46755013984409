import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import IntroductionColumn from '~/components/common/features/IntroductionColumn'
import { RootState } from '~/rootReducer'

type Props = {
  children: ReactNode
  messageView?: ReactNode
}

const AuthContainerColumn: React.FC<Props> = ({
  children,
  messageView,
}: Props) => {
  const isPC = useSelector((state: RootState) => state.device.isPC)

  return (
    <View testID="auth-container-column">
      {messageView}
      {/* Hide Introduction column in PC and when a message view is available */}
      {!isPC && !messageView && <IntroductionColumn />}
      {children}
    </View>
  )
}

export default AuthContainerColumn
