import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'

import QuizAnswer from '~/components/career/molecules/quizzes/QuizAnswer'
import { quizChoiceLabels } from '~/interfaces/Quiz'

type Props = {
  answer?: string
  choices: string[]
  disabled?: boolean
  isLoading?: boolean
  selectedItem?: string
  onSelect?: (choice: string) => void
}

const QuizChoicesList: React.FC<Props> = ({
  answer,
  choices,
  onSelect,
  disabled,
  isLoading,
  selectedItem,
}: Props) => (
  <View testID="quiz-choices-list">
    {isLoading && <ActivityIndicator style={styles.indicator} />}
    {choices.map((item, index) => (
      <View
        key={`${item}-${index}`}
        style={[
          styles.answerItem,
          choices.length == index + 1 ? styles.answerItemLast : undefined,
        ]}
      >
        <QuizAnswer
          content={item}
          disabled={disabled}
          index={quizChoiceLabels[index]}
          isSelected={selectedItem === item}
          isCorrect={answer === selectedItem}
          onPress={(): void => onSelect && onSelect(item)}
        />
      </View>
    ))}
  </View>
)

const styles = StyleSheet.create({
  answerItem: {
    marginBottom: 15,
    marginHorizontal: 20,
  },
  answerItemLast: {
    marginBottom: 18,
  },
  indicator: {
    marginBottom: 10,
  },
})

export default QuizChoicesList
