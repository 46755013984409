import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '~/rootReducer'

export type SearchCategory = 'users' | 'communities' | 'jobs' | 'forum'
export type DrawerSearchState = {
  category?: SearchCategory
  keyword: string | undefined
}

const defaultState = {
  category: undefined,
  keyword: undefined,
} as DrawerSearchState

const drawerSearchSlice = createSlice({
  name: 'drawerSearch',
  initialState: defaultState,
  reducers: {
    setDrawerSearch: {
      reducer(
        state: DrawerSearchState,
        action: PayloadAction<{ drawerSearch?: Partial<DrawerSearchState> }>
      ): void {
        Object.assign(state, action.payload.drawerSearch)
      },
      prepare(
        drawerSearch?: Partial<DrawerSearchState>
      ): { payload: { drawerSearch?: Partial<DrawerSearchState> } } {
        return { payload: { drawerSearch } }
      },
    },
    setCategory: {
      reducer(
        state: DrawerSearchState,
        action: PayloadAction<{ category?: string }>
      ): void {
        state.category = action.payload.category as SearchCategory
      },
      prepare(category?: string): { payload: { category?: string } } {
        return { payload: { category } }
      },
    },
    setKeyword: {
      reducer(
        state: DrawerSearchState,
        action: PayloadAction<{ keyword?: string }>
      ): void {
        state.keyword = action.payload.keyword
      },
      prepare(keyword?: string): { payload: { keyword?: string } } {
        return { payload: { keyword } }
      },
    },
  },
})

export const {
  setCategory,
  setKeyword,
  setDrawerSearch,
} = drawerSearchSlice.actions

export const drawerSearchSelector = (state: RootState): DrawerSearchState =>
  state.drawerSearch

export default drawerSearchSlice.reducer
