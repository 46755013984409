import React from 'react'
import { StyleSheet, View } from 'react-native'

import UserQuestionList from '~/components/forum/features/users/UserQuestionList'
import User from '~/interfaces/User'

type Props = {
  user?: User
}

const UserForumQuestion: React.FC<Props> = ({ user }: Props) => {
  return (
    <View testID="user-forum-question" style={styles.container}>
      <UserQuestionList userId={user?.id} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default UserForumQuestion
