import { ImageSourcePropType } from 'react-native'

import { TemplateType } from '~/interfaces/Resume'

export const resumeDefaultImages: { [key: string]: ImageSourcePropType } = {
  [TemplateType.Enjin]: require('~/assets/images/resumes/resume_template_enjin.png'),
  [TemplateType.Formal]: require('~/assets/images/resumes/resume_template_formal.png'),
  [TemplateType.Minimal]: require('~/assets/images/resumes/resume_template_minimal.png'),
  [TemplateType.Shades]: require('~/assets/images/resumes/resume_template_shades.png'),
}
