import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'
import { useDispatch } from 'react-redux'

import EditResumeMain from '~/components/career/features/resume/EditResumeMain'
import EditResumeHeader from '~/components/career/organisms/resumes/EditResumeHeader'
import { resetEditResumeIndex } from '~/slices/career/resumes'

const EditResumeTemplate: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetEditResumeIndex())
  }, [])

  return (
    <ScrollView>
      <EditResumeHeader />
      <EditResumeMain />
    </ScrollView>
  )
}

export default EditResumeTemplate
