import React, { useCallback, useEffect, useState } from 'react'
import { Platform, StyleSheet, View, TouchableOpacity } from 'react-native'
import { Image } from 'react-native-elements'

import type { API } from '~/api'
import URLPreviewInfo from '~/components/common/molecules/URLPreview/URLPreviewInfo'
import { fetchPreview as fetchPreviewOnNative } from '~/components/common/molecules/URLPreview/linkPreview'
import color from '~/constants/common/color'
import useAPI from '~/hooks/useAPI'
import URLInspection from '~/interfaces/URLInspection'

type Props = {
  url: string
  onPress?: (url: string) => void
}

const URLPreview: React.FC<Props> = ({ url, onPress }: Props) => {
  const [urlInspection, setURLInspection] = useState<URLInspection>({ url })

  const getURLPreview = useCallback(async (): Promise<void> => {
    try {
      const urlInspection = await fetchPreviewOnNative(url)
      setURLInspection(urlInspection)
    } catch (error) {
      // When previewing a website link is incorrect. And the error returned from the server. We will hide that error
      // toast.showError(getErrorMessage(error))
    }
  }, [url, setURLInspection])

  const fetchPreview = useAPI(
    async (api: API): Promise<void> => {
      try {
        setURLInspection(await api.urlInspections.get(url))
      } catch (error) {
        // When previewing a website link is incorrect. And the error returned from the server. We will hide that error
        // toast.showError(getErrorMessage(error))
      }
    },
    [url, setURLInspection]
  )

  useEffect(() => {
    Platform.OS === 'web' ? fetchPreview() : getURLPreview()
  }, [fetchPreview])

  const previewURL = urlInspection.url

  const pressHandler = useCallback(() => {
    if (onPress != null) {
      onPress(previewURL)
    }
  }, [onPress, previewURL])

  return (
    <View style={styles.container} testID="url-preview" accessible={true}>
      <TouchableOpacity testID="url-preview-touchable" onPress={pressHandler}>
        {urlInspection.image && (
          <Image
            style={styles.image}
            testID="url-preview-image"
            source={{ uri: urlInspection.image.url }}
            height=""
            width=""
          />
        )}
        <URLPreviewInfo
          url={urlInspection.url}
          title={urlInspection.title}
          description={urlInspection.description}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.baseBackground,
    borderColor: color.gray,
    borderWidth: 1,
    width: '100%',
  },
  image: {
    height: 200,
    width: '100%',
  },
})

export default URLPreview
