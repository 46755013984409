import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import { RecruimentQueryParams, RecruimentFilterKey } from '~/api/recruitments'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams, isReadyNavigationRef } from '~/utils/navigation'

const JobFilterPopup: React.FC = () => {
  const { t } = useTranslation()
  const routeParams = camelcaseKeys(
    useRoute()?.params || {}
  ) as RecruimentQueryParams
  const { setQueryParams } = useCustomNavigation()
  const recruimentQueryParams = getRouteParams<RecruimentQueryParams>()
  const [selectedFilter, setSelectedFilter] = useState<
    FilterPopupData | undefined
  >(undefined)

  const jobFilterPopupData: FilterPopupData[] = [
    {
      id: RecruimentFilterKey.BY_RECENTLY,
      name: t('jobs.sort.recentRecruitments'),
    },
    {
      id: RecruimentFilterKey.BY_ACTIVE,
      name: t('jobs.sort.activeRecruitments'),
    },
  ]

  const enum popupDataKey {
    RECENTLY_INDEX = 0,
    ACTIVE_INDEX = 1,
  }

  const getFilterDataByParams = (): FilterPopupData => {
    return (
      jobFilterPopupData.find(
        (obj) => obj.id == recruimentQueryParams.sortBy
      ) || jobFilterPopupData[popupDataKey.RECENTLY_INDEX]
    )
  }

  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    if (isEmpty(routeParams) && item.id === 'recently') {
      return
    } else {
      routeParams.sortBy !== item.id &&
        setQueryParams({ sortBy: item.id as RecruimentFilterKey })
    }
  }

  useEffect(() => {
    isReadyNavigationRef.current &&
      changeSelectedFilter(getFilterDataByParams())
  }, [isReadyNavigationRef.current, recruimentQueryParams.sortBy])

  return (
    <View testID="job-filter-popup">
      <FilterPopupMenu
        selectedItem={selectedFilter}
        data={jobFilterPopupData}
        onSelect={changeSelectedFilter}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {`${selectedFilter?.name}`}
          </Text>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  selectedFilter: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
  },
})

export default JobFilterPopup
