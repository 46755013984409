import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNil } from 'lodash'

import api from '~/api'
import { UpdateMemberBodyParams } from '~/api/companyMembers'
import Company from '~/interfaces/Company'
import { CompanyUpdateRequest } from '~/interfaces/Company'
import CompanyUser from '~/interfaces/CompanyUser'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

type CompanyState = {
  current?: Company
  isLoading: boolean
}

const defaultState: CompanyState = {
  isLoading: false,
}

const companySlice = createSlice({
  name: 'company',
  initialState: defaultState,
  reducers: {
    setCurrentCompany(state, action: PayloadAction<Company>): void {
      state.current = { ...action.payload }
    },
    updateCurrentCompany(state, action: PayloadAction<Partial<Company>>): void {
      state.current = {
        ...(state.current || {}),
        ...action.payload,
        userWorkingCompanies:
          'userWorkingCompanies' in action.payload
            ? action.payload.userWorkingCompanies
            : state.current?.userWorkingCompanies,
      } as Company
    },
    setIsLoading(state, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload
    },
  },
})

export const {
  setIsLoading,
  setCurrentCompany,
  updateCurrentCompany,
} = companySlice.actions

const selectCompany = (state: RootState): CompanyState => state.company
export const currentCompanySelector = createSelector(
  selectCompany,
  (company: CompanyState): Company | undefined => company.current
)

export const fetchCompany = (id: number): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(setIsLoading(true))
    const response = await api.companies.show<Company>(id)
    dispatch(setCurrentCompany(response))
    dispatch(setIsLoading(false))
  } catch (err) {
    dispatch(setIsLoading(false))
    throw err
  }
}

export const updateCurrentCompanyInfo = (
  companyId: number,
  data: Partial<CompanyUpdateRequest>
): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(setIsLoading(true))
    const company: Company = await api.companies.update(companyId, data)
    if (isNil(company)) {
      return
    }
    dispatch(updateCurrentCompany(company))
    dispatch(setIsLoading(false))
  } catch (err) {
    dispatch(setIsLoading(false))
    throw err
  }
}

export const removeCompanyMember = ({
  companyId,
  memberId,
}: {
  companyId: number
  memberId: number
}): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(setIsLoading(true))
    await api.companyMembers.configPath(companyId).delete(memberId)
    const company = getState().company.current
    if (isNil(company)) {
      return
    }
    const userWorkingCompanies = company.userWorkingCompanies.filter(
      (item) => item.id !== memberId
    )
    dispatch(updateCurrentCompany({ userWorkingCompanies }))
    dispatch(setIsLoading(false))
  } catch (err) {
    dispatch(setIsLoading(false))
    throw err
  }
}

export const updateCompanyMemberRole = ({
  companyId,
  memberId,
  isAdmin,
}: {
  companyId: number
  memberId: number
  isAdmin: boolean
}): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(setIsLoading(true))
    const companyUser = await api.companyMembers
      .configPath(companyId)
      .update<UpdateMemberBodyParams, CompanyUser>(memberId, {
        userWorkingCompany: { isAdmin },
      })
    const company = getState().company.current
    if (isNil(company)) {
      return
    }
    const userWorkingCompanies: CompanyUser[] = company.userWorkingCompanies.map(
      (item) => (item.id !== memberId ? item : { ...item, ...companyUser })
    )
    dispatch(updateCurrentCompany({ userWorkingCompanies }))
    dispatch(setIsLoading(false))
  } catch (err) {
    dispatch(setIsLoading(false))
    throw err
  }
}

export default companySlice.reducer
