import { assign } from 'lodash'

import { ServiceType } from '~/constants/common/app'
import { CommunitySection } from '~/constants/common/navigation'
import Notification, {
  NotifiableType,
  NotifyType,
} from '~/interfaces/Notification'

export const getReportNavigationData = (notification: Notification) => {
  let targetScreenname = ''
  switch (notification.notifyType) {
    case NotifyType.POST:
      targetScreenname = 'postDetails'
      break
    case NotifyType.QUESTION:
    case NotifyType.ANSWER: // TODO there isn't answer detail page, should make it scrollable to answer
      targetScreenname = 'questionShow'
      break
    default:
      break
  }

  return {
    name: targetScreenname,
    data: {
      id: notification.notifyResourceId,
      notif: notification,
    },
  }
}

export const getNotifNavigationData = (notification: Notification) => {
  let targetScreenname = 'home'
  const data = {
    id: notification.notifyResourceId,
    notif: notification,
  }
  switch (notification.notifiableType) {
    case NotifiableType.REPORT:
      return getReportNavigationData(notification)
    case NotifiableType.LIKE:
    case NotifiableType.POST:
      targetScreenname = 'postDetails'
      break
    case NotifiableType.USER_FOLLOW:
      targetScreenname = 'userDetail'
      break
    case NotifiableType.COMMUNITY_MEMBER_INVITATION:
    case NotifiableType.COMMUNITY_MEMBER_JOIN_REQUEST:
      assign(data, { section: CommunitySection.ADMIN })
      targetScreenname = 'communityDetail'
      break
    case NotifiableType.COMMUNITY_EVENT_NEW_ATTENDEE:
    case NotifiableType.COMMUNITY_EVENT_ATTENDEE_INVITATION:
      assign(data, { section: CommunitySection.EVENTS })
      targetScreenname = 'communityDetail'
      break
    default:
      targetScreenname = 'notfound'
      break
  }
  return {
    name: targetScreenname,
    data,
  }
}

export const isCareerNotification = (notification: Notification): boolean =>
  notification.notifyType === NotifyType.LIKE ||
  notification.notifyType === NotifyType.POST_IMAGE ||
  notification.notifyType === NotifyType.POST_REPLY ||
  notification.notifyType === NotifyType.USER_FOLLOW ||
  notification.notifyType === NotifyType.POST_RECRUIT ||
  notification.notifyType === NotifyType.ENTRY_APPLIED ||
  notification.notifyType === NotifyType.ENTRY_REJECTED ||
  notification.notifiableType === NotifiableType.REPORT

export const isForumNotification = (notification: Notification): boolean =>
  notification.notifyType === NotifyType.ANSWER ||
  notification.notifyType === NotifyType.QUESTION

export const isCommunityNotification = (notification: Notification): boolean =>
  notification.notifiableType === NotifiableType.COMMUNITY_MEMBER_INVITATION ||
  notification.notifiableType ===
    NotifiableType.COMMUNITY_MEMBER_JOIN_REQUEST ||
  notification.notifiableType === NotifiableType.COMMUNITY_EVENT_NEW_ATTENDEE ||
  notification.notifiableType ===
    NotifiableType.COMMUNITY_EVENT_ATTENDEE_INVITATION

export const getServiceNotification = (
  notifications: Notification[],
  service: ServiceType | null
): Notification[] => {
  switch (service) {
    case ServiceType.CAREER:
      return notifications.filter(isCareerNotification)
    case ServiceType.FORUM:
      return notifications.filter(isForumNotification)
    case ServiceType.COMMUNITY:
      return notifications.filter(isCommunityNotification)
    default:
      return notifications
  }
}
