import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'

import EditableText from '~/components/common/atoms/EditableText'
import EditableTextAction, {
  EditButtonStyles,
} from '~/components/common/molecules/EditableTextAction'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'

const screenWidth = Dimensions.get('window').width
const MOBILE_PADDING_HORIZONTAL = 60

type Props = {
  value?: string
  editable?: boolean
  maxLength?: number
  onSubmit?: (value: string) => Promise<void>
  hintText?: string
  editButtonStyle?: EditButtonStyles
  textSize?: FontSize
}

const EditableTextForm: React.FC<Props> = ({
  editable,
  value,
  onSubmit,
  maxLength,
  hintText,
  editButtonStyle,
  textSize,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const editableContainerStyle = isPC
    ? isEditing
      ? styles.editableContainer
      : undefined
    : styles.editableContainerMobile

  const onSubmitEditing = async (): Promise<void> => {
    if (onSubmit) {
      setIsLoading(true)
      await onSubmit(inputValue)
      setIsLoading(false)
      setIsEditing(false)
    }
  }

  const onCancel = () => {
    setInputValue(value || '')
    setIsEditing(false)
  }

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  return (
    <View testID="editable-text-form" style={containerStyle}>
      <View
        style={StyleSheet.flatten([
          editableContainerStyle,
          { width: isEditing ? '100%' : '95%' },
        ])}
      >
        {isEmpty(inputValue) && !isEditing && !isEmpty(hintText) ? (
          <Text style={styles.hintText}>{hintText}</Text>
        ) : (
          <EditableText
            value={inputValue}
            multiline={false}
            editable={isEditing}
            busyMode={isLoading}
            onChangeText={setInputValue}
            maxLength={maxLength}
            textSize={textSize}
          />
        )}
      </View>

      {editable && (
        <View
          style={StyleSheet.flatten([
            styles.actionForm,
            { marginLeft: isPC ? 10 : 0 },
            { marginTop: editButtonStyle ? 0 : 10 },
          ])}
        >
          <EditableTextAction
            onPressCancel={onCancel}
            onPressEdit={() => setIsEditing(true)}
            isLoading={isLoading}
            onPressSave={onSubmitEditing}
            isEditing={isEditing}
            editButtonStyle={editButtonStyle}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  actionForm: {
    marginLeft: 10,
    marginTop: 10,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  containerMobile: {
    width: screenWidth - MOBILE_PADDING_HORIZONTAL,
    flexDirection: 'column',
  },
  editableContainer: {
    maxWidth: 410,
  },
  editableContainerMobile: {
    flex: 1,
  },
  hintText: {
    color: color.placeholderText,
  },
})

export default EditableTextForm
