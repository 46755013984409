import React from 'react'
import { StyleSheet, View } from 'react-native'

import GenderItem, {
  GenderItemData,
} from '~/components/career/atoms/company/GenderItem'
import StatisticCardContainer from '~/components/career/atoms/company/StatisticCardContainer'

export type GenderStatisticData = {
  title: string
  data: GenderItemData[]
}

type Props = {
  genderData: GenderStatisticData
}

const GenderStatistic: React.FC<Props> = ({ genderData }: Props) => (
  <StatisticCardContainer title={genderData.title}>
    <View style={styles.bodyContainer}>
      {genderData.data.map((genderItem, index) => (
        <GenderItem data={genderItem} key={index} />
      ))}
    </View>
  </StatisticCardContainer>
)

const styles = StyleSheet.create({
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 20,
  },
})

export default GenderStatistic
