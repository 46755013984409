import React, { useEffect, useState } from 'react'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import { CompanyBodyParams } from '~/api/companies'
import logoImage from '~/assets/images/logo_color.png'
import RegistrationCompanyColumn from '~/components/common/features/registration/RegistrationCompanyColumn'
import RegistrationCompleteColumn from '~/components/common/features/registration/RegistrationComplete'
import RegistrationLeftColumn from '~/components/common/features/registration/RegistrationLeftColumn'
import RegistrationProfileColumn from '~/components/common/features/registration/RegistrationProfileColumn'
import AuthenticationHeaderRight from '~/components/common/molecules/headers/AuthenticationHeaderRight'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Profile from '~/interfaces/Profile'
import {
  currentUserProfileFetched,
  currentUserSelector,
} from '~/slices/common/users'
import { navigate } from '~/utils/navigation'

type Props = {
  defaultRegistrationData?: Partial<CompanyBodyParams>
}

const RegistrationCompanyTemplate: React.FC<Props> = ({
  defaultRegistrationData,
}: Props) => {
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const [profile, setUserProfile] = useState<Partial<Profile>>({})

  const [isLoading, setIsLoading] = useState(false)
  const [registrationData, setRegistrationData] = useState(
    defaultRegistrationData || {}
  )
  const [currentColumnIndex, setCurrentColumnIndex] = useState(0)

  const containerStyles = isPC ? styles.pc : styles.mobile

  const bodyStyles = isPC ? styles.registration : styles.registrationMobile

  const switchLanguageStyle = isPC
    ? styles.switchLanguage
    : styles.switchLanguageMobile

  const handleChangeRegistration = (data: Partial<CompanyBodyParams>): void => {
    setRegistrationData(Object.assign(registrationData, data))
  }

  const nextColumn = (): void => {
    setCurrentColumnIndex(currentColumnIndex + 1)
  }

  const updateProfileParams = (data: Partial<Profile>) => {
    setUserProfile({ ...profile, ...data })
  }

  const submitRegistration = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await registerCompany()
      const user = await api.userProfile.create({
        userProfile: profile,
      })
      navigateToHomeScreen()
      dispatch(currentUserProfileFetched(user))
    } catch (error) {
      setIsLoading(false)
      toast.showError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const navigateToHomeScreen = () => {
    currentUser?.isCompanyAccount
      ? currentUser?.isInACompany
        ? navigate('careerMain', {
            screen: 'companyPanel',
          })
        : navigate('subscriptionPlan')
      : navigate('topPage')
  }

  const registerCompany = async (): Promise<void> => {
    if (currentUser?.isInACompany === true) {
      return
    }
    await api.companies.create({
      company: {
        ...registrationData,
        aboutUs: registrationData.name, // There is no aboutUs in design
      },
    })
  }

  const registrationChildColumns: React.ReactElement[] = [
    <RegistrationCompanyColumn
      key="0"
      onUpdateRegistrationData={handleChangeRegistration}
      profileData={registrationData}
      onNextColumn={nextColumn}
    />,
    <RegistrationProfileColumn
      key="1"
      profileData={profile}
      onNextColumn={nextColumn}
      onUpdateRegistrationData={updateProfileParams}
    />,
    <RegistrationCompleteColumn
      onSubmit={submitRegistration}
      key="2"
      isRegistering={isLoading}
    />,
  ]

  useEffect(() => {
    currentUser?.isInACompany === true && setCurrentColumnIndex(1)
  }, [currentUser?.isInACompany])

  return (
    <ScrollView
      contentContainerStyle={isPC ? styles.contentContainerStyle : undefined}
      testID="registration-company-template"
    >
      <View style={containerStyles}>
        <View style={switchLanguageStyle}>
          {!isPC && <Image source={logoImage} style={styles.logo} />}
          <View style={styles.buttonLanguage}>
            <AuthenticationHeaderRight />
          </View>
        </View>
        {isPC && <RegistrationLeftColumn />}
        <View style={bodyStyles} testID="registration-column-container">
          {registrationChildColumns[currentColumnIndex]}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  buttonLanguage: {
    marginLeft: 'auto',
    paddingTop: 5,
  },
  contentContainerStyle: {
    flex: 1,
  },
  logo: {
    height: 40,
    marginLeft: 20,
    marginRight: 30,
    marginTop: 5,
    width: 120,
  },
  mobile: {
    backgroundColor: color.white,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    paddingTop: 15,
  },
  pc: {
    flex: 1,
  },
  registration: {
    height: '100%',
    justifyContent: 'center',
    left: '30%',
    position: 'absolute',
    width: '100%',
  },
  registrationMobile: {
    flex: 14,
  },
  switchLanguage: {
    position: 'absolute',
    right: 0,
    margin: 45,
    zIndex: 1,
  },
  switchLanguageMobile: {
    flex: 1,
    flexDirection: 'row',
  },
})

export default RegistrationCompanyTemplate
