import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import api from '~/api'
import BackToLogin from '~/components/common/atoms/auth/BackToLogin'
import ConfirmPasswordInput from '~/components/common/atoms/auth/ConfirmPasswordInput'
import PasswordInput from '~/components/common/atoms/auth/PasswordInput'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type RouteParams = { resetToken?: string }

const ResetPasswordForm: React.FC = () => {
  const route = useRoute()
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const resetPasswordToken = (camelcaseKeys(route.params ?? {}) as RouteParams)
    ?.resetToken
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [
    isValidPasswordConfirmation,
    setIsValidPasswordConfirmation,
  ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePassword = (value: string, isValid: boolean): void => {
    setPassword(value)
    setIsValidPassword(isValid)
  }

  const submit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await api.auth.resetPassword({
        password,
        passwordConfirmation: password,
        resetPasswordToken: resetPasswordToken || '',
      })
      setMessage(response.message)
    } catch (error) {
      //TODO show error
      setIsLoading(false)
    }
  }

  return (
    <View style={styles.container} testID="reset-password-form">
      <Text style={styles.messsage} testID="reset-password-message">
        {message}
      </Text>
      <PasswordInput onChangeText={handleChangePassword} />
      <ConfirmPasswordInput
        originalPassword={password}
        verifyChanged={setIsValidPasswordConfirmation}
      />
      <View style={styles.separator} />
      <View style={styles.backToLogin}>
        <BackToLogin />
      </View>
      <Button
        testID="submit"
        onPress={submit}
        title={t('auth.changePassword')}
        titleStyle={commonStyles.buttonTextSize}
        disabled={
          !(isValidPassword && isValidPasswordConfirmation) || isLoading
        }
        buttonStyle={StyleSheet.flatten(styles.submit)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  backToLogin: {
    alignSelf: 'center',
    marginBottom: 12,
  },
  container: {
    marginTop: 20,
  },
  messsage: {
    alignSelf: 'center',
    color: color.warning,
    paddingBottom: 20,
    textAlign: 'center',
    fontSize: FontSize.GENERAL,
  },
  separator: {
    backgroundColor: color.textGray,
    height: 1,
    margin: 20,
  },
  submit: {
    marginHorizontal: 10,
    marginTop: 10,
    paddingVertical: 10,
  },
})

export default ResetPasswordForm
