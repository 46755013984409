import { Fontisto } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import emptyDailyQuizImage from '~/assets/images/prompts/nopostsyet.png'
import EmptyView from '~/components/common/molecules/EmptyView'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  isPC: boolean
  onPressDismissButton?: () => void
}

const DailyQuizEmptyView: React.FC<Props> = ({
  isPC,
  onPressDismissButton,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="daily-quiz-empty-view">
      <EmptyView
        isPC={isPC}
        title={t('quiz.dailyQuizEmptyTitle')}
        imageSource={emptyDailyQuizImage}
      />
      <Fontisto
        size={18}
        name="close-a"
        color={color.removeButton}
        style={styles.closeIcon}
        onPress={onPressDismissButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    justifyContent: 'center',
    paddingHorizontal: 20,
    ...commonStyles.borderRadiusAllCorner,
  },
})

export default DailyQuizEmptyView
