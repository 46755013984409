import React from 'react'
import { View, Image, StyleSheet } from 'react-native'

import logo from '~/assets/images/logo-white.png'
import color from '~/constants/common/color'

const RegistrationLeftColumn: React.FC = () => {
  return (
    <View style={styles.container} testID="registration-left-column">
      <Image style={styles.logo} source={logo} testID="logo" />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    height: '100%',
    width: '40%',
  },
  logo: {
    height: 83,
    marginLeft: '10%',
    marginTop: 56,
    width: 246,
  },
})

export default RegistrationLeftColumn
