import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import JobHuntingForm from '~/components/common/molecules/users/registration/JobHuntingForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Profile from '~/interfaces/Profile'
import { RootState } from '~/rootReducer'

type Props = {
  jobHuntingData: Partial<Profile>
  onPreviousColumn: () => void
  onNextColumn: () => void
  onUpdateJobHuntingData: (data: Partial<Profile>) => void
}

const RegistrationJobHuntingColumn: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const titleStyle = isPC ? styles.title : styles.titleMobile

  return (
    <CardContainer
      testID="registration-job-hunting-column"
      style={containerStyle}
    >
      <Text style={titleStyle}>{t('jobs.hunting.title')}</Text>
      <JobHuntingForm
        isPC={isPC}
        jobHuntingData={props.jobHuntingData}
        onPreviousColumn={props.onPreviousColumn}
        onUpdateJobHuntingData={props.onUpdateJobHuntingData}
        onNextColumn={props.onNextColumn}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingHorizontal: 50,
    paddingVertical: 30,
    width: 700,
  },
  containerMobile: {
    flex: 1,
    padding: 10,
  },
  title: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
  },
  titleMobile: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
    marginTop: 10,
  },
})

export default RegistrationJobHuntingColumn
