import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import CompanyAbout from '~/components/career/molecules/company/CompanyAbout'
import CompanyInfoForm from '~/components/career/organisms/company/CompanyInfoForm'
import CardContainer from '~/components/common/atoms/CardContainer'
import ProfileOverview from '~/components/common/organisms/ProfileOverview'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import Company from '~/interfaces/Company'
import { updateCurrentCompany } from '~/slices/career/company'

type Props = {
  isAdmin: boolean
  company?: Company
}

const CompanyProfileOverview: React.FC<Props> = ({
  company,
  isAdmin,
}: Props) => {
  const { isPC } = useDevice()
  const dispatch = useDispatch()

  return company ? (
    <ProfileOverview
      isPC={isPC}
      leftColumn={
        <CompanyAbout
          company={company}
          editable={isAdmin}
          onChange={(aboutUs) => dispatch(updateCurrentCompany({ aboutUs }))}
        />
      }
      rightColumn={
        <>
          <CardContainer
            style={styles.rightColumnCard}
            testID="company-info-form-card"
          >
            <CompanyInfoForm
              isPC={isPC}
              editable={isAdmin}
              company={company}
              onChange={(value) => dispatch(updateCurrentCompany(value))}
            />
          </CardContainer>
        </>
      }
    />
  ) : (
    <View testID="empty-profile-overview" />
  )
}

const styles = StyleSheet.create({
  rightColumnCard: {
    padding: 25,
    paddingVertical: 20,
    backgroundColor: color.white,
  },
})

export default CompanyProfileOverview
