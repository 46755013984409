import * as Factory from 'factory.ts'
import moment from 'moment'

import { recruitmentFactory } from '~/factories/recruitment'
import { userFactory } from '~/factories/user'
import Applicant from '~/interfaces/Applicant'

export const applicantFactory = Factory.makeFactory<Applicant>({
  id: Factory.each((i) => i + 1),
  user: userFactory.build(),
  createdAt: moment().subtract(1, 'days').toISOString(),
  recruitment: recruitmentFactory.build(),
})
