import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CandidateAvatar from '~/components/career/molecules/resume/CandidateAvatar'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import TextField from '~/components/common/molecules/TextField'
import FullNameInput from '~/components/common/molecules/users/FullNameInput'
import Media from '~/interfaces/Media'
import Resume from '~/interfaces/Resume'

type Props = {
  bioData: Partial<Resume>
  onChange: (value: Partial<Resume>) => void
  isPC: boolean
}

const BioForm: React.FC<Props> = ({ bioData, isPC, onChange }: Props) => {
  const { t } = useTranslation()

  const handleChangeFirstName = (value: string): void => {
    onChange({ givenName: value })
  }

  const handleChangeLastName = (value: string): void => {
    onChange({ familyName: value })
  }

  const handleChangeCurrentPosition = (value: string): void => {
    onChange({ currentPosition: value })
  }

  const handleChangeResumeImage = (media: Media): void => {
    onChange({ image: media, imageId: media.id })
  }

  return (
    <FormLayout
      title={t('resume.bio')}
      topElement={
        <CandidateAvatar
          isPC={isPC}
          onChange={handleChangeResumeImage}
          image={bioData.image}
        />
      }
      hasAddAction={false}
      isPC={isPC}
    >
      <View style={styles.textFieldContainer}>
        <FullNameInput
          onChangeLastName={handleChangeFirstName}
          onChangeFirstName={handleChangeLastName}
          givenName={bioData.givenName}
          familyName={bioData.familyName}
          isPC={isPC}
          isEnglish={false}
        />
        <TextField
          value={bioData.currentPosition}
          onChangeText={handleChangeCurrentPosition}
          title={t('resume.currentPosition')}
        />
      </View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  textFieldContainer: {
    marginBottom: 40,
    marginTop: 20,
  },
})

export default BioForm
