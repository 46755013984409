import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle, View, ViewStyle } from 'react-native'

import api from '~/api'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'
import { getUserNameByLanguage } from '~/utils/common/user'

type Props = {
  user?: User
  size?: 'small' | 'large'
}

const FollowButton: React.FC<Props> = ({ user, size }: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [isFollowed, setIsFollowed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  useEffect(() => {
    setIsFollowed(!!user?.following)
  }, [])

  const sizeStyle = size === 'large' ? styles.large : styles.small
  const followStyle = {
    ...sizeStyle,
    backgroundColor: isFollowed ? color.pressableText : color.primary,
  }

  const displayName = getUserNameByLanguage(i18n.language, user)

  const follow = async (): Promise<void> => {
    if (!user) {
      return
    }
    const options = {
      followableId: user.id,
      followableType: 'user',
    }
    try {
      setIsLoading(true)
      if (isFollowed) {
        await api.follow.delete(options)
        setIsFollowed(false)
      } else {
        await api.follow.create(options)
        setIsFollowed(true)
      }
    } catch (error) {
      toast.showError(error)
    }
    setIsShowConfirmDialog(false)
    setIsLoading(false)
  }

  return (
    <View testID="follow-button">
      <Button
        type="solid"
        title={
          isFollowed ? t('headerTab.following') : t('friendSuggestion.follow')
        }
        buttonStyle={followStyle}
        titleStyle={styles.title as TextStyle}
        containerStyle={styles.container as ViewStyle}
        onPress={(): void => {
          if (isFollowed) {
            setIsShowConfirmDialog(true)
          } else {
            follow()
          }
        }}
        loading={isLoading}
      />
      {isShowConfirmDialog && isFollowed && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('friendSuggestion.unfollow')}
          onPressAccept={follow}
          onPressCancel={(): void => setIsShowConfirmDialog(false)}
          visible={isShowConfirmDialog}
          message={t('message.unfollow', {
            name: displayName,
          })}
        />
      )}
    </View>
  )
}

const styles = {
  container: {
    alignSelf: 'baseline',
  },
  small: {
    height: 25,
    width: 90,
    padding: 5,
  },
  large: {
    padding: 5,
    width: 150,
  },
  title: {
    ...commonStyles.buttonTextSize,
    alignSelf: 'center',
    color: color.white,
    lineHeight: 19,
    paddingTop: 0,
    paddingBottom: 0,
  },
}

export default FollowButton
