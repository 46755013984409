import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import color from '~/constants/common/color'
import {
  ChannelControlMenuProps,
  ChannelControlMenuType,
} from '~/interfaces/ChannelControlMenu'

const ChannelControlMenuMobile: React.FC<ChannelControlMenuProps> = ({
  isAdmin,
  onSelect,
  isPublic,
}) => {
  const { t } = useTranslation()
  const manageMenuItems = [
    {
      id: ChannelControlMenuType.EditChannel,
      title: t('community.channel.editChannel'),
      hasSeparator: true,
    },
    {
      id: ChannelControlMenuType.CreateChannel,
      title: t('community.channel.createNewChannel'),
    },
    {
      id: ChannelControlMenuType.DeleteChannel,
      title: t('community.channel.deleteThisChannel'),
    },
  ]

  let data: ControlMenuData[] = [
    { id: ChannelControlMenuType.ShowTimeLine, title: t('headerTab.timeline') },
  ]
  if (!isPublic) {
    data.push({
      id: ChannelControlMenuType.ShowMembers,
      title: t('community.information.members'),
    })
  }
  if (isAdmin) {
    data = [...data, ...manageMenuItems]
  }

  return (
    <View testID="channel-control-menu-mobile">
      <ControlMenu
        data={data}
        onSelect={(item): void =>
          onSelect && onSelect(item.id as ChannelControlMenuType)
        }
        menuTrigger={<Icon name="bars" color={color.white} size={24} />}
      />
    </View>
  )
}

export default ChannelControlMenuMobile
