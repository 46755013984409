import { TFunction } from 'i18next'
import { isNil } from 'lodash'

import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import User from '~/interfaces/User'
import i18n, { SupportedLanguage } from '~/plugins/i18n'

const ANNUAL_UNIT = 12 // 12 months
const MONTHLY_UNIT = 1 // 1 month
const STUDENT_PLAN_ID = 1

export const getPriceSummary = (t: TFunction, plan: SubscriptionPlan) => {
  const isMonthly = plan.period === 'month'
  const priceEachMonth = isMonthly ? plan.price : plan.price / ANNUAL_UNIT

  const periodText = isMonthly
    ? t('subscriptions.secureCheckout.monthly')
    : t('subscriptions.secureCheckout.yearly')

  const periodValue = `¥${priceEachMonth} x  ${t(
    'subscriptions.secureCheckout.periodUnit',
    {
      periodUnit: isMonthly ? MONTHLY_UNIT : ANNUAL_UNIT,
      count: isMonthly ? MONTHLY_UNIT : ANNUAL_UNIT,
    }
  )}`

  return t('subscriptions.secureCheckout.summaryHeadContent', {
    planName: i18n.language === SupportedLanguage.EN ? plan.enName : plan.name,
    period: periodText,
    periodValue: periodValue,
  })
}

export const isPaid = (user?: User) => {
  const currentDate = new Date()
  const currentPeriodEnd = new Date(user?.subscription?.currentPeriodEnd!)
  return (
    !isNil(user?.subscription) &&
    !isNil(user?.subscription?.subscriptionPlan) &&
    user?.subscription?.inactive == false &&
    user?.subscription?.subscriptionPlan.id !== STUDENT_PLAN_ID &&
    currentPeriodEnd > currentDate
  )
}
