import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Linking } from 'react-native'

import api from '~/api'
import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import ControlMenuItem from '~/components/workarounds/MenuItem'
import useCustomToast from '~/hooks/useCustomToast'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'

type Props = {
  comment: Post
  currentUser?: User
  onSelectEdit?: () => void
  onDelete?: (post: Post) => void
}

const CommentControlMenu: React.FC<Props> = ({
  comment,
  onDelete,
  currentUser,
  onSelectEdit,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isShowConfirmDialog, setShowConfirmDialog] = useState(false)

  const acceptConfirmation = async (): Promise<void> => {
    try {
      await api.posts.deletePost(comment.id)
      onDelete && onDelete(comment)
    } catch (error) {
      toast.showError(error)
      setShowConfirmDialog(false)
    }
  }

  const goToContactPage = useCallback(() => {
    Linking.openURL('https://company.enjin.world/contact-us/')
  }, [])

  return (
    <View testID="comment-control-menu">
      <ControlMenuContainer
        onPressAccept={acceptConfirmation}
        menuTrigger={<ControlMenuButton />}
        isShowConfirmDialog={isShowConfirmDialog}
        confirmationMessage={t('post.confirmRemoveComment')}
        onPressCancel={(): void => setShowConfirmDialog(false)}
      >
        {currentUser?.id === comment.user?.id ? (
          <>
            <ControlMenuItem
              title={t('comment.edit')}
              onSelect={onSelectEdit}
            />
            <ControlMenuItem
              title={t('comment.delete')}
              onSelect={(): void => setShowConfirmDialog(true)}
            />
          </>
        ) : (
          <ControlMenuItem
            title={t('comment.report')}
            onSelect={goToContactPage}
          />
        )}
      </ControlMenuContainer>
    </View>
  )
}

export default CommentControlMenu
