import { find } from 'lodash'
import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

import TopTabNavigator, {
  TopTabNavigatorData,
} from '~/components/common/organisms/TopTabNavigator'
import useCustomNavigation from '~/hooks/useNavigation'
import {
  AppSection,
  ProfileSectionParam,
} from '~/interfaces/RootStackParamList'
import { getRouteParams } from '~/utils/navigation'

type Props = {
  isPC: boolean
  defaultSection: AppSection
  data: TopTabNavigatorData[]
}

const HeaderSectionMenu: React.FC<Props> = ({
  isPC,
  data,
  defaultSection,
}: Props) => {
  const { setQueryParams } = useCustomNavigation()
  const sectionParam =
    getRouteParams<ProfileSectionParam>().section ?? defaultSection
  const [selectedSection, setSelectedSection] = useState<
    TopTabNavigatorData | undefined
  >(undefined)

  const changeSelectedSection = (item: TopTabNavigatorData): void => {
    setSelectedSection(item)
    setQueryParams({ section: item.key as AppSection })
  }

  useEffect(() => {
    setSelectedSection(find(data, { key: sectionParam }))
  }, [sectionParam])

  return (
    <View testID="header-section-menu" style={styles.container}>
      <TopTabNavigator
        isPC={isPC}
        inverted
        data={data}
        selectedItem={selectedSection}
        onSelectTab={changeSelectedSection}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 814,
    marginTop: 10,
  },
})

export default HeaderSectionMenu
