import { Picker } from '@react-native-picker/picker'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { UserProfile } from '~/api/users'
import japanese from '~/assets/data/japanese.json'
import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import FormAction from '~/components/common/atoms/users/FormAction'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import JapaneseLevel from '~/interfaces/JapaneseLevel'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'

type Props = {
  user: User
  onUpdateProfile: (profile: UserProfile) => void
  isMe?: boolean
}

export const getJapaneseLevels = (language: string): JapaneseLevel[] => {
  const japaneseLevels = japanese.map((item) => ({
    id: item.id,
    name: language === 'ja' ? item.name : item.en_name,
  }))
  japaneseLevels.unshift({ id: '', name: '' })
  return japaneseLevels
}

const LanguageInfo: React.FC<Props> = ({
  user,
  onUpdateProfile,
  isMe,
}: Props) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const japaneseLevels = getJapaneseLevels(i18n.language)
  // Default is level which empty id and name
  const currentLevel =
    japaneseLevels.find((item) => item.id === user.profile.japaneseLevel) ??
    japaneseLevels[0]
  const [level, setLevel] = useState<string | undefined>(currentLevel.id)

  const startEdit = (): void => {
    setEditing(true)
  }

  const cancelEdit = (): void => {
    setLevel(currentLevel.id)
    setEditing(false)
  }

  const save = async (): Promise<void> => {
    if (level !== currentLevel.id) {
      onUpdateProfile({
        userProfile: {
          id: user.id,
          japaneseLevel: level === '' ? null : level,
        },
      } as UserProfile)
    }
    setEditing(false)
  }

  return (
    <CardContainer style={styles.container}>
      <TitleText testID="language-info-title" style={styles.title}>
        {t('profile.japaneseLevel')}
      </TitleText>
      {editing ? (
        <>
          <Picker
            style={styles.picker}
            selectedValue={level}
            onValueChange={(itemValue): void => {
              setLevel(itemValue)
            }}
          >
            {getJapaneseLevels(i18n.language).map((item) => (
              <Picker.Item key={item.id} value={item.id} label={item.name} />
            ))}
          </Picker>
          <View testID="language-info-action">
            <FormAction
              onCancel={cancelEdit}
              onSubmit={save}
              isLoading={false}
              disabled={false}
            />
          </View>
        </>
      ) : (
        <Text style={styles.contentText}>{currentLevel.name}</Text>
      )}
      {isMe && !editing && (
        <Button
          testID="edit-button"
          type="solid"
          title={t('edit')}
          onPress={startEdit}
          containerStyle={StyleSheet.flatten(styles.editButtonContainer)}
          buttonStyle={StyleSheet.flatten(styles.editButton)}
          titleStyle={StyleSheet.flatten(styles.editTitle)}
        />
      )}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 20,
  },
  contentText: {
    color: color.textGray,
  },
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 90,
  },
  editButtonContainer: {
    marginTop: 25,
  },
  editTitle: {
    fontSize: FontSize.GENERAL,
  },
  picker: {
    backgroundColor: color.white,
    borderColor: color.gray,
    borderRadius: 4,
    color: color.textGray,
    width: '100%',
  },
  title: {
    color: color.textGray,
    marginBottom: 10,
    marginTop: 10,
  },
})

export default LanguageInfo
