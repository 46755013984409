import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, TextInput, TextStyle } from 'react-native'

import PasswordVisibilitySwitch from '~/components/common/atoms/auth/PasswordVisibilitySwitch'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title?: string
  onChangeText: (text: string, isValid: boolean) => void
  onSubmitEditing?: () => void
}

type ErrorMessageHook = {
  showErrorMessage: boolean
  setShowErrorMessage: (visibility: boolean) => void
}

export const useShowErrorMessage = (): ErrorMessageHook => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  return { showErrorMessage, setShowErrorMessage }
}

const PasswordInput = React.forwardRef<TextInput, Props>(
  (props: Props, ref: React.ForwardedRef<TextInput>): JSX.Element => {
    const { t } = useTranslation()
    const [secureTextEntry, setSecureTextEntry] = useState(true)
    const { showErrorMessage, setShowErrorMessage } = useShowErrorMessage()
    const [valid, setValid] = useState(false)

    const switchVisibility = (): void => {
      setSecureTextEntry(!secureTextEntry)
    }

    const handleChangeText = (value: string): void => {
      const valid = value.length >= 8
      setValid(valid)
      if (showErrorMessage && valid) {
        setShowErrorMessage(false)
      }
      props.onChangeText(value, valid)
    }

    const handleBlur = (): void => {
      setShowErrorMessage(!valid)
    }

    return (
      <Input
        label={props.title ?? t('auth.password')}
        labelStyle={styles.label as TextStyle}
        secureTextEntry={secureTextEntry}
        inputStyle={styles.input}
        rightIconContainerStyle={styles.rightIconContainerStyle}
        rightIcon={
          <PasswordVisibilitySwitch
            secureTextEntry={secureTextEntry}
            onPress={switchVisibility}
          />
        }
        errorMessage={showErrorMessage ? t('auth.passwordErrorMessage') : ''}
        onBlur={handleBlur}
        inputContainerStyle={styles.inputContainer}
        onChangeText={handleChangeText}
        onSubmitEditing={props.onSubmitEditing}
        ref={ref}
        testID="password-input"
      />
    )
  }
)

const styles = {
  input: {
    paddingLeft: 4,
    paddingTop: 3,
    fontSize: FontSize.GENERAL,
    ...Platform.select({
      web: { outline: 'none' },
    }),
  },
  inputContainer: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    marginTop: 10,
  },
  label: {
    color: color.textGray,
    fontWeight: 'normal',
    fontSize: FontSize.GENERAL,
  },
  rightIconContainerStyle: {
    width: 50,
  },
}

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
