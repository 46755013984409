import { FontAwesome as Icon } from '@expo/vector-icons'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import CustomPicker from '~/components/common/molecules/CustomPicker'
import LanguagePicker from '~/components/common/molecules/users/LanguagePicker'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import ResumeLanguage from '~/interfaces/ResumeLanguage'
import i18n from '~/plugins/i18n'
import { getLanguageLevels } from '~/utils/common/languagePickers'

type Props = {
  index: number
  onChange: (value: Partial<ResumeLanguage>, index: number) => void
  language: Partial<ResumeLanguage>
  resumeLanguage: string
  onPressClose: () => void
}

const LanguageForm: React.FC<Props> = ({
  index,
  onChange,
  language,
  onPressClose,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const { t } = useTranslation()

  const handChangeName = (value: ItemValue): void => {
    onChange({ name: value.toString() }, index)
  }

  const handChangeLevel = (value: ItemValue): void => {
    onChange({ level: value.toString() }, index)
  }

  return (
    <View style={styles.container}>
      {index !== 0 && (
        <TouchableOpacity
          onPress={onPressClose}
          style={styles.closeButton}
          testID={'language-close-button'}
        >
          <Icon size={20} name="close" color={color.primary} />
        </TouchableOpacity>
      )}
      <View style={styles.form} testID="language-form">
        <LanguagePicker
          selected={language.name}
          onChange={handChangeName}
          language={String(language)}
          title={`${t('profile.language')}*`}
        />
        <View style={styles.separator} />
        <CustomPicker
          title={`${t('profile.level')}*`}
          selectedValue={language.level}
          onValueChange={handChangeLevel}
          size={isPC ? 'large' : 'medium'}
          data={getLanguageLevels(i18n.language)}
          isFlex
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    marginBottom: 20,
    flex: 1,
  },
  form: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  separator: {
    width: 40,
  },
})

export default LanguageForm
