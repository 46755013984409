import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import ChannelControlMenu from '~/components/community/molecules/ChannelControlMenu'
import ChannelList from '~/components/community/organisms/ChannelList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { ChannelControlMenuType } from '~/interfaces/ChannelControlMenu'
import { currentUserSelector } from '~/slices/common/users'
import {
  channelSelector,
  communitySelector,
  isCommunityAdmin,
} from '~/slices/community/community'

type Props = {
  onSelectControlMenu?: (type: ChannelControlMenuType) => void
}

const ChannelHeaderMobile: React.FC<Props> = ({
  onSelectControlMenu,
}: Props) => {
  const community = useSelector(communitySelector)
  const currentChannel = useSelector(channelSelector)
  const currentUserId = useSelector(currentUserSelector)?.id

  const isAdmin = isCommunityAdmin(currentUserId, community?.communityMembers)

  return (
    <>
      <HorizontalContainer>
        <ChannelList />
        <View style={styles.channelMenu} testID="channel-control-menu">
          <ChannelControlMenu
            isPC={false}
            isAdmin={isAdmin}
            onSelect={onSelectControlMenu}
            isPublic={currentChannel?.isPublic}
          />
        </View>
      </HorizontalContainer>
      <Text style={styles.description} numberOfLines={10}>
        {currentChannel?.description}
      </Text>
    </>
  )
}

const styles = StyleSheet.create({
  channelMenu: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 10,
  },
  description: {
    color: color.white,
    flexWrap: 'wrap',
    fontSize: FontSize.REMARKABLE,
    marginBottom: 10,
  },
})

export default ChannelHeaderMobile
