import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dimensions,
  ListRenderItem,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'

import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import EmptyView from '~/components/common/molecules/EmptyView'
import NotificationItem from '~/components/common/molecules/notification/NotificationItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import Notification from '~/interfaces/Notification'
import { RootState } from '~/rootReducer'

type Props = {
  notifications: Notification[]
  title?: string
  onPressItem?: (item: Notification) => void
}

const NotificationInfo: React.FC<Props> = ({
  title,
  onPressItem,
  notifications,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const isLoading = useSelector(
    (state: RootState) => state.notifications.isLoading
  )
  const heightScrollView = isPC
    ? Dimensions.get('window').height - 179
    : Dimensions.get('window').height - 126

  const keyExtractor = (item: Notification): string => item.id.toString()

  const titleStyle = isPC ? styles.title : styles.titleMobile

  const renderItem: ListRenderItem<Notification> = ({
    item,
  }: {
    item: Notification
  }) => (
    <TouchableOpacity
      testID="render-item"
      onPress={(): void => onPressItem && onPressItem(item)}
    >
      <NotificationItem notification={item} isPC={isPC} />
    </TouchableOpacity>
  )

  return (
    <View style={styles.container}>
      {title && (
        <Text style={titleStyle} testID="notification-info-title">
          {title}
        </Text>
      )}
      <View style={{ height: heightScrollView }}>
        <InfiniteScrollFlatList
          data={notifications}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListEmptyComponent={
            isLoading ? undefined : (
              <EmptyView
                isPC={isPC}
                title={t('notification.emptyNotification')}
                imageSource={require('~/assets/images/prompts/nopostsyet.png')}
              />
            )
          }
          ItemSeparatorComponent={({ leadingItem }): React.ReactElement => (
            <View style={!leadingItem?.wasAlreadyRead && styles.wrapSeparator}>
              <View style={styles.separator} />
            </View>
          )}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.transparent,
  },
  separator: {
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
    marginHorizontal: 16,
  },
  title: {
    color: color.textGray,
    padding: 30,
    paddingLeft: 10,
    lineHeight: 18,
    fontSize: FontSize.GENERAL,
  },
  titleMobile: {
    color: color.black,
    fontSize: FontSize.GENERAL,
    lineHeight: 14,
    paddingBottom: 5,
    paddingLeft: 32,
    paddingRight: 0,
    width: '100%',
  },
  wrapSeparator: {
    backgroundColor: color.primaryLight,
  },
})
export default NotificationInfo
