import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import * as WebBrowser from 'expo-web-browser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import { ConfirmationParams } from '~/api/auth'
import RegistrationMessage from '~/components/common/atoms/auth/RegistrationMessage'
import AuthContainerColumn from '~/components/common/features/auth/AuthContainerColumn'
import FacebookAuthButton from '~/components/common/features/auth/FacebookAuthButton'
import LoginForm from '~/components/common/organisms/auth/LoginForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'
import { login, resendConfirmation } from '~/slices/common/auth'

WebBrowser.maybeCompleteAuthSession()

const LoginColumn: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const route = useRoute()
  const [email, setEmail] = useState('')
  const [failed, setFailed] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [resendRequested, setResendRequested] = useState(false)
  const [resendSuccess, setResendSuccess] = useState(false)
  const [resendError, setResendError] = useState(false)
  const [showLoginError, setShowLoginError] = useState(false)
  const searchParams = camelcaseKeys(route.params ?? {}) as ConfirmationParams
  const error = useSelector((state: RootState) => state.auth.error)
  const unconfirmedEmail = useSelector(
    (state: RootState) => state.auth.unconfirmedEmail
  )
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const confirmationToken = searchParams?.confirmationToken

  useEffect(() => {
    if (confirmationToken) {
      api.auth
        .confirm({
          confirmationToken,
          redirectUrl: '',
        })
        .then(() => {
          setConfirmed(true)
        })
        .catch(() => {
          setFailed(true)
        })
    }
  }, [confirmationToken])

  const renderMessage = (): React.ReactElement | undefined => {
    if (confirmed) {
      return (
        <RegistrationMessage message={t('auth.emailConfirmed')} isPC={isPC} />
      )
    } else if (failed) {
      return (
        <RegistrationMessage
          message={t('auth.emailConfirmedError')}
          isPC={isPC}
        />
      )
    } else if (resendRequested) {
      if (resendSuccess) {
        return (
          <RegistrationMessage
            message={t('auth.registrationEmailSent')}
            extra={
              <Text
                testID="resend-link"
                style={styles.resendLink}
                onPress={async (): Promise<void> => requestConfirmation(email)}
              >
                {t('auth.resendConfirmation')}
              </Text>
            }
            isPC={isPC}
          />
        )
      } else if (resendError) {
        return (
          <RegistrationMessage message={t('auth.resendError')} isPC={isPC} />
        )
      }
    } else if (unconfirmedEmail) {
      return (
        <RegistrationMessage
          message={error ? error : t('auth.emailConfirmedError')}
          extra={
            <Text
              testID="resend-link"
              style={styles.resendLink}
              onPress={async (): Promise<void> => requestConfirmation(email)}
            >
              {t('auth.resendConfirmation')}
            </Text>
          }
          isPC={isPC}
        />
      )
    } else if (showLoginError) {
      return (
        <RegistrationMessage
          message={error ? error : t('loginFailed')}
          isPC={isPC}
        />
      )
    }
  }

  const requestConfirmation = async (email: string): Promise<void> => {
    try {
      setResendRequested(true)
      // reuse the email from the login attempt
      await dispatch(resendConfirmation(email))
      setResendSuccess(true)
    } catch (err) {
      setResendError(true)
    }
  }

  const loginWithPassword = async (
    email: string,
    password: string
  ): Promise<void> => {
    try {
      setShowLoginError(false)
      setEmail(email)
      await Promise.all([dispatch(login({ email, password }))])
    } catch (error) {
      setShowLoginError(true)
    }
  }

  return (
    <AuthContainerColumn messageView={renderMessage()}>
      <LoginForm
        isPC={isPC}
        loginFacebookButton={
          <FacebookAuthButton
            redirectPath="/login"
            title={t('auth.loginWithFacebook')}
            isPC={isPC}
          />
        }
        onPressLoginButton={loginWithPassword}
      />
    </AuthContainerColumn>
  )
}

const styles = StyleSheet.create({
  resendLink: {
    alignSelf: 'center',
    color: color.textGray,
    paddingTop: 5,
  },
})

export default LoginColumn
