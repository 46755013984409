import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import ProportionalImage from '~/components/common/atoms/ProportionalImage'
import VideoPlayer from '~/components/common/molecules/media/VideoPlayer'

type Props = {
  uri: string
  video?: boolean
  onActive?: (active: boolean) => void
}

const MediaBox: React.FC<Props> = ({ uri, video = false, onActive }: Props) => {
  const [stretch, setStretch] = useState<'horizontal' | 'vertical'>(
    'horizontal'
  )
  const [aspectRatio, setAspectRatio] = useState(0)

  const handleGetSize = useCallback(
    (width, height) => {
      setStretch(width / height > aspectRatio ? 'vertical' : 'horizontal')
    },
    [setStretch, aspectRatio]
  )

  const handleLayout = useCallback(
    (ev) => {
      const { width, height } = ev.nativeEvent.layout
      setAspectRatio(width / height)
    },
    [setAspectRatio]
  )

  const handlePlayingStatusChanged = useCallback(
    (isPlaying: boolean) => {
      if (onActive != null) {
        onActive(isPlaying)
      }
    },
    [onActive]
  )

  const containerStyle =
    stretch === 'vertical'
      ? styles.verticalContainer
      : styles.horizontallContainer

  const content = video ? (
    <View style={styles.videoContainer}>
      <VideoPlayer
        uri={uri}
        stretch={stretch}
        onGetSize={handleGetSize}
        onPlayingStatusChanged={handlePlayingStatusChanged}
      />
    </View>
  ) : (
    <ProportionalImage
      style={styles.image}
      stretch={stretch}
      uri={uri}
      onGetSize={handleGetSize}
    />
  )

  return (
    <View testID="media-box" style={containerStyle} onLayout={handleLayout}>
      {content}
    </View>
  )
}

const styles = StyleSheet.create({
  horizontallContainer: {
    alignItems: 'center',
    flex: 1,
  },
  image: {
    flex: 1,
  },
  verticalContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  videoContainer: {
    flex: 1,
  },
})

export default MediaBox
