import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import MenuItem from '~/components/workarounds/MenuItem'

export enum DocumentMenuType {
  DOWNLOAD,
  DELETE,
}

type Props = {
  onSelect: (type: DocumentMenuType) => void
}

const DocumentControlMenu: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  const acceptSelectDelete = (): void => {
    onSelect && onSelect(DocumentMenuType.DELETE)
  }

  const hideConfirmDialog = (): void => setIsShowConfirmDialog(false)

  return (
    <View testID="document-control-menu">
      <ControlMenuContainer
        onPressCancel={hideConfirmDialog}
        onPressAccept={acceptSelectDelete}
        isShowConfirmDialog={isShowConfirmDialog}
        confirmationMessage={t('document.deleteDocumentConfirmMessage')}
      >
        <MenuItem
          title={t('download')}
          onSelect={(): void => onSelect(DocumentMenuType.DOWNLOAD)}
        />
        <MenuItem
          title={t('delete')}
          onSelect={(): void => setIsShowConfirmDialog(true)}
        />
      </ControlMenuContainer>
    </View>
  )
}

export default DocumentControlMenu
