import type Media from '~/interfaces/Media'
import type Policy from '~/interfaces/Policy'
import buildFormData from '~/uploader/buildFormData'
import postForm from '~/uploader/postForm'

abstract class Uploader<M extends Media> {
  blob: Blob
  uri?: string

  constructor(blob: Blob, uri?: string) {
    this.blob = blob
    this.uri = uri
  }

  async upload(): Promise<M> {
    const policy = await this.createPolicy()
    const formData = buildFormData(this.blob, policy, this.uri)
    await postForm(policy.uploadUrl, formData)
    return policy.media
  }

  abstract createPolicy(): Promise<Policy<M>>
}

export default Uploader
