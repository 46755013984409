import React from 'react'
import { StyleSheet, View } from 'react-native'

import UserName from '~/components/common/atoms/users/UserName'
import ProfileFollow from '~/components/common/molecules/users/ProfileFollow'
import User from '~/interfaces/User'

type Props = {
  user?: User
  followable?: boolean
  userHeaderStatus?: React.ReactElement
}

const ProfileInfoUser: React.FC<Props> = ({
  user,
  followable = true,
  userHeaderStatus,
}: Props) => {
  return (
    <View testID="profile-info-user">
      <UserName user={user} size="large" />
      {userHeaderStatus}
      {followable && (
        <View style={styles.secondRow}>
          <ProfileFollow isPC={true} type="user-item" user={user} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  secondRow: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 4,
  },
})

export default ProfileInfoUser
