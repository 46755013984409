import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Skill from '~/interfaces/Skill'

type Props = {
  onPressRemove?: (item: Partial<Skill>) => void
  skill: Partial<Skill>
  isLastItem?: boolean
}

const SkillListItem: React.FC<Props> = ({
  onPressRemove,
  skill,
  isLastItem,
}) => {
  return (
    <View
      style={[styles.container, isLastItem ? styles.lastItem : null]}
      testID="skill-list-item"
    >
      <View style={styles.itemTextWrapper}>
        <Text style={styles.itemText}>{skill.name}</Text>
      </View>
      {onPressRemove && (
        <TouchableOpacity
          style={styles.removeBtn}
          onPress={() => onPressRemove(skill)}
          testID="remove-icon-container"
        >
          <Text style={styles.removeIcon}>✕</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    paddingVertical: 6,
  },
  itemText: {
    color: color.textGray,
    fontWeight: 'bold',
    fontSize: FontSize.GENERAL,
  },
  itemTextWrapper: {
    flex: 1,
  },
  lastItem: {
    borderBottomWidth: 0,
  },
  removeBtn: {
    justifyContent: 'center',
  },
  removeIcon: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
})

export default SkillListItem
