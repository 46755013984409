import * as Factory from 'factory.ts'

import { imageFactory } from '~/factories/image'
import Recruitment from '~/interfaces/Recruitment'

export const recruitmentFactory = Factory.makeFactory<Recruitment>({
  id: Factory.each((i) => i),
  companyName: 'Enjin. INC',
  subject: 'Looking for officer',
  overview:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc leo ex, dignissim quis augue eget, aliquam venenatis enim. Mauris eget luctus diam, a elementum metus. Cras aliquam fringilla arcu, et auctor turpis cursus ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi suscipit non ipsum ac tincidunt. Aenean porta feugiat purus, sit amet porta erat congue nec. Pellentesque nec purus gravida, ultrices orci eget, auctor neque. Phasellus vulputate orci enim, a lobortis nisi finibus sit amet. Duis sed mauris molestie, viverra turpis vel, tincidunt eros. Curabitur semper dolor in ipsum ultrices, quis faucibus augue porta. Duis vitae massa quis augue rutrum vestibulum sed ut tortor. Donec sodales laoreet tellus, in egestas elit laoreet et. Morbi sed lacus et magna porttitor molestie. Ut congue eros ac ligula consectetur auctor. Integer eu semper metus, et semper nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus.',
  criterion: 'Hard working, Honest',
  location: 'thainguyen, Tokyo, JP',
  workLocation: 'thainguyen, Tokyo, JP',
  businesses: 'Job, Recruiment',
  workTime: '8:00AM',
  step: '',
  salary: '200000yen',
  allowance: '',
  holiday: 'National rule',
  insurance: '100%',
  welfare: 'bonus end of year up to 6 salary-month',
  trialPeriod: '3 days',
  job: 'Writing',
  position: 'officer',
  headOfficeArea: 'Tokyo, JP',
  capital: 'Tokyo',
  numOfEmployees: '3000',
  companyDescription:
    'Enjin株式会社は、世界中の日本語学習者が活躍できる場所と絆の提供を行っています。\n有料職業紹介事業許可番号　13-ユ-312271',
  publishedAt: '',
  companyId: Factory.each((i) => i),
  companyLogo: imageFactory.build(),
  headerImage: imageFactory.build(),
  createdAt: '2021-09-20T10:52:19.230+09:00',
  isClosed: false,
  skills: [],
  closedAt: '',
  selectionEndedAt: '',
  isApplied: false,
  entriesCount: 0,
  applyUrl: '',
  isSaved: false,
  entries: [],
  savedRecruit: {
    recruitmentId: 1,
    id: 1,
  },
})
