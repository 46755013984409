import { first, isEmpty, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { BarChart } from 'react-native-chart-kit'
import { AbstractChartConfig } from 'react-native-chart-kit/dist/AbstractChart'
import { ChartData } from 'react-native-chart-kit/dist/HelperTypes'

import color from '~/constants/common/color'
import { findLCM } from '~/utils/common/math'

type Props = {
  data: ChartData
}

const StatisticsBarChart: React.FC<Props> = ({ data }: Props) => {
  const segmentsDefault = 10
  const [segments, setSegments] = useState(segmentsDefault)

  const chartConfig: AbstractChartConfig = {
    backgroundColor: color.white,
    backgroundGradientFrom: color.white,
    backgroundGradientTo: color.white,
    color: () => color.textGray,
    formatTopBarValue: (item) => (item == 0 ? '' : item),
    decimalPlaces: 0,
    propsForLabels: {
      rotation: 0,
    },
    propsForBackgroundLines: {
      stroke: color.lightGray,
      strokeDasharray: '',
      strokeWidth: 1,
    },
  }

  const computeSegments = (): number => {
    const thresholdForComputation = 20
    const values = first(data.datasets)?.data
    if (isNil(values) || isEmpty(values)) {
      return segmentsDefault
    }
    const results = values
      .filter((item) => item > 0)
      .sort((a: number, b: number) => a - b)
    const segmentValue = isEmpty(results)
      ? thresholdForComputation
      : results.reduce(findLCM)
    return segmentValue > thresholdForComputation
      ? thresholdForComputation
      : segmentValue
  }

  useEffect(() => {
    setSegments(computeSegments())
  }, [data])

  return (
    <View testID="statistics-bar-chart" style={styles.container}>
      <BarChart
        data={data}
        width={588}
        height={343}
        yAxisLabel=""
        yAxisSuffix=""
        flatColor={true}
        yLabelsOffset={20}
        segments={segments}
        showBarTops={false}
        chartConfig={chartConfig}
        showValuesOnTopOfBars={true}
        withCustomBarColorFromData={true}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: color.white,
  },
})

export default StatisticsBarChart
