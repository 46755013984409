import { useFocusEffect } from '@react-navigation/native'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'

import api from '~/api'
import JobApplicantPopup from '~/components/career/organisms/jobs/JobApplicantPopup'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  job: Recruitment
  isPC: boolean
  company: Company
}

const JobStatus = ({ job, isPC, company }: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isShowApplicantPopup, setIsShowApplicantPopup] = useState(false)
  const isJobWithoutClosingDate =
    !isEmpty(job.publishedAt) && isEmpty(job.closedAt)
  const statusBackground = isJobWithoutClosingDate
    ? styles.activeBackground
    : styles.inactiveBackground
  const actionButtonBackground = isJobWithoutClosingDate
    ? styles.inactiveBackground
    : styles.activeBackground

  const toggleJobApplicant = () => {
    setIsShowApplicantPopup(!isShowApplicantPopup)
  }

  useFocusEffect(
    React.useCallback(() => {
      return () => setIsShowApplicantPopup(false)
    }, [])
  )

  const reOpenRecruitment = async () => {
    if (isNil(job.companyId) || isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.companyRecruitments
        .configPath(job.companyId)
        .update<Partial<Recruitment>, Recruitment>(job.id, { closedAt: '' })
      job.closedAt = response.closedAt
    } catch (error) {
      toast.showError(error)
    } finally {
      api.isAlive && setIsLoading(false)
    }
  }

  const changeStatusRecruitment = async () => {
    if (isNil(job.companyId) || isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const publishedAt = isEmpty(job.publishedAt) ? new Date().toString() : ''
      const response = await api.companyRecruitments
        .configPath(job.companyId)
        .update<Partial<Recruitment>, Recruitment>(job.id!, { publishedAt })
      job.publishedAt = response.publishedAt
    } catch (error) {
      toast.showError(error)
    } finally {
      api.isAlive && setIsLoading(false)
    }
  }

  return (
    <View
      testID="job-status"
      style={[
        isPC ? styles.container : styles.containerMobile,
        statusBackground,
      ]}
    >
      <View style={isPC ? styles.containerView : styles.containerViewMobile}>
        <View>
          <View style={styles.statusView}>
            <Text style={[styles.content, styles.fontBold]}>
              {t('jobs.status')}:
            </Text>
            <Text style={[styles.status, styles.fontBold]}>
              {!isEmpty(job.publishedAt) && isEmpty(job.closedAt)
                ? t('company.jobs.active')
                : isEmpty(job.closedAt)
                ? t('company.jobs.inactive')
                : t('jobs.ended')}
            </Text>
          </View>
          <Text style={[styles.content, styles.paddingTop]}>
            {t('jobs.createdOn')} {moment(job.createdAt).format('DD-MM-YYYY')}
          </Text>
          {!isEmpty(job.closedAt) && (
            <Text style={[styles.content, styles.paddingTop]}>
              {t('jobs.recruitingEndedAt')}{' '}
              {moment(job.closedAt).format('DD-MM-YYYY')}
            </Text>
          )}
        </View>
        <View style={styles.applicantView}>
          <Pressable disabled={!job.entriesCount} onPress={toggleJobApplicant}>
            <Text style={[styles.content, styles.link]}>
              {t('company.jobs.applicantCount', { count: job.entriesCount })}
            </Text>
          </Pressable>
          {isShowApplicantPopup && (
            <JobApplicantPopup
              isShow={isShowApplicantPopup}
              job={job}
              hideApplicant={toggleJobApplicant}
              isPC={isPC}
              company={company}
            />
          )}
          <View style={styles.marginTop}>
            {!isEmpty(job.closedAt) && (
              <Pressable
                disabled={isLoading}
                onPress={reOpenRecruitment}
                testID="job-reopen"
                style={actionButtonBackground}
              >
                <Text style={styles.reOpenRecruitment}>
                  {t('jobs.reopenRecruitmentPost')}
                </Text>
              </Pressable>
            )}
            {isEmpty(job.closedAt) && (
              <Pressable
                disabled={isLoading}
                onPress={changeStatusRecruitment}
                testID="job-published"
                style={actionButtonBackground}
              >
                <Text style={styles.reOpenRecruitment}>
                  {!isEmpty(job.publishedAt)
                    ? t('company.jobs.deactivateThisJobPost')
                    : t('company.jobs.activeThisJobPost')}
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  activeBackground: {
    borderRadius: 4,
    backgroundColor: color.primary,
  },
  applicantView: {
    alignItems: 'flex-end',
  },
  container: {
    backgroundColor: color.primary,
    padding: 20,
    alignItems: 'center',
  },
  containerMobile: {
    backgroundColor: color.primary,
    paddingHorizontal: 5,
    paddingTop: 10,
  },
  containerView: {
    flexDirection: 'row',
    minWidth: 814,
    justifyContent: 'space-between',
  },
  containerViewMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 10,
  },
  content: {
    color: color.white,
    fontSize: FontSize.SUB,
    padding: 3,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  inactiveBackground: {
    borderRadius: 4,
    backgroundColor: color.danger,
  },
  link: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  marginTop: {
    marginTop: 10,
  },
  paddingTop: {
    paddingTop: 7,
  },
  reOpenRecruitment: {
    borderColor: color.white,
    borderRadius: 4,
    color: color.white,
    fontSize: FontSize.SUB,
    padding: 10,
  },
  status: {
    backgroundColor: color.white,
    borderRadius: 4,
    color: color.danger,
    fontSize: FontSize.SUB,
    padding: 3,
  },
  statusView: {
    flexDirection: 'row',
  },
})

export default JobStatus
