import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import PhoneNumberInput, {
  PhoneNumberInputChangeEventData,
} from '~/components/common/atoms/PhoneNumberInput'
import ActionsSettingElement from '~/components/common/molecules/settings/ActionsSettingElement'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { currentUserSelector, updateCurrentUser } from '~/slices/common/users'

const AccountSettingPhoneNumber: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)

  const [phoneNumber, setPhoneNumber] = useState(
    currentUser?.profile.phone || ''
  )
  const [isValid, setIsValid] = useState(false)
  const [editing, setEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const containerStyle = isPC ? styles.container : styles.containerMobile
  const phoneInputStyle = isPC
    ? styles.phoneInputContainer
    : styles.phoneInputContainerMobile

  const changePhoneNumber = (data: PhoneNumberInputChangeEventData): void => {
    setPhoneNumber(data.value)
    setIsValid(data.isValid)
  }

  const updatePhoneNumber = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await Promise.all([
        dispatch(updateCurrentUser({ userProfile: { phone: phoneNumber } })),
      ])
    } catch (error) {
      //TODO show error
    }
    setEditing(false)
    setIsLoading(false)
  }

  return (
    <View style={containerStyle} testID="account-setting-phone-number">
      <View style={styles.phoneInfo}>
        <Text style={styles.label}>{t('profile.phoneNumber')}</Text>
        {editing ? (
          <View style={phoneInputStyle}>
            <PhoneNumberInput
              value={phoneNumber}
              onChange={changePhoneNumber}
            />
          </View>
        ) : (
          <Text
            style={styles.value}
            testID="account-setting-phone-number-value"
          >
            {currentUser?.profile.phone}
          </Text>
        )}
      </View>
      <ActionsSettingElement
        savable={isValid}
        editable={editing}
        isLoading={isLoading}
        onPressSave={updatePhoneNumber}
        onPressEdit={(): void => setEditing(true)}
        onPressCancel={(): void => setEditing(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 6,
  },
  containerMobile: {
    marginBottom: 10,
    marginTop: 6,
  },
  label: {
    color: color.black,
    marginRight: 20,
    width: 120,
  },
  phoneInfo: {
    flex: 1,
    flexDirection: 'column',
  },
  phoneInputContainer: {
    flex: 1,
    marginRight: 30,
  },
  phoneInputContainerMobile: {
    flex: 1,
  },
  value: {
    color: color.textGray,
    flex: 1,
    marginTop: 10,
  },
})

export default AccountSettingPhoneNumber
