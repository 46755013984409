import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

import UserResultItem from '~/components/common/molecules/users/UserResultItem'
import User from '~/interfaces/User'

type Props = {
  isPC?: boolean
  isMe?: boolean
  controlMenu?: ReactNode
  user: User
  currentUser?: User
}

const CompanyUserItem: React.FC<Props> = ({
  isPC,
  isMe,
  controlMenu,
  user,
  currentUser,
}: Props) => (
  <View style={styles.container} testID="company-user-item">
    <UserResultItem
      user={user}
      isPC={isPC}
      isMe={isMe}
      currentUser={currentUser}
    />
    {controlMenu}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export default CompanyUserItem
