import React, { FC } from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  question: string
  size?: FontSize
}

const QuizQuestion: FC<Props> = ({ question, size }: Props) => (
  <View style={styles.container} testID="quiz-question">
    <Text style={[styles.questionContent, { fontSize: size }]}>{question}</Text>
  </View>
)

export default QuizQuestion

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    flex: 1,
    paddingHorizontal: 20,
    ...Platform.select({
      ios: {
        paddingTop: 10,
      },
      android: {
        paddingTop: 10,
      },
      default: {
        paddingVertical: 15,
      },
    }),
  },
  questionContent: {
    color: color.black,
    fontSize: FontSize.GENERAL,
  },
})
