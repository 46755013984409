import { makeRedirectUri, ResponseType } from 'expo-auth-session'
import { FacebookAuthRequestConfig } from 'expo-auth-session/providers/facebook'
import Constants from 'expo-constants'
import { Platform } from 'react-native'

import { authFacebookPath } from '~/api/auth'
import facebookAppId from '~/constants/common/facebookAppId'
import { baseURL } from '~/httpClient'

export const getAppId = (): string => {
  switch (window.location?.hostname) {
    case 'localhost':
      return facebookAppId.development
    case 'staging.enjin.world':
      return facebookAppId.staging
    case 'enjin.world':
      return facebookAppId.production
    default:
      return facebookAppId.development
  }
}

export const getFacebookAuthURL = (
  redirectUri: string,
  state?: string
): string =>
  `${baseURL}${authFacebookPath}?origin=${redirectUri}&state=${state}`

export const getFacebookAuthRequestConfig = (
  redirectPath: string
): FacebookAuthRequestConfig => ({
  clientId: getAppId(),
  responseType: ResponseType.Token,
  extraParams: {
    display: Platform.select({ web: 'popup' }) as string,
  },
  redirectUri: `${makeRedirectUri({
    native: `fb${getAppId()}://authorize`,
    useProxy: Constants.appOwnership === 'expo' && Platform.OS !== 'web',
  })}${redirectPath}`,
})
