import { sumBy } from 'lodash'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Slider } from 'react-native-elements'

import StatisticCardContainer from '~/components/career/atoms/company/StatisticCardContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type LineChartData = {
  label: string
  value: number
}

type Props = {
  title?: string
  data: LineChartData[]
}

const StatisticLineChart: React.FC<Props> = ({ title, data }: Props) => {
  const total = sumBy(data, 'value')

  return (
    <View testID="statistic-line-chart" style={styles.container}>
      <StatisticCardContainer title={title}>
        {data.map((item, index) => (
          <View
            key={index}
            style={styles.itemContainer}
            testID="statistic-line-chart-item"
          >
            <Text style={styles.label}>{item.label}</Text>
            <Slider
              disabled
              value={item.value}
              maximumValue={total}
              style={styles.slider}
              trackStyle={styles.track}
              thumbStyle={styles.thumbStyle}
              containerStyle={sliderContainerStyle}
              minimumTrackTintColor={color.primary}
            />
            <Text style={styles.value}>{item.value}</Text>
          </View>
        ))}
      </StatisticCardContainer>
    </View>
  )
}

const sliderContainerStyle = {
  containerHorizontal: { height: 20, justifyContent: 'center' },
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  label: {
    width: 80,
    color: color.textGray,
  },
  slider: {
    flex: 1,
  },
  thumbStyle: {
    display: 'none',
  },
  track: {
    height: 8,
  },
  value: {
    color: color.textGray,
    width: 20,
    marginLeft: 8,
  },
})

export default StatisticLineChart
