import React from 'react'
import { View, StyleSheet } from 'react-native'

type Props = {
  avatarView?: React.ReactNode
  titleView: React.ReactNode
  subTitleView?: React.ReactNode
}

const PostItemHeader: React.FC<Props> = ({
  avatarView,
  titleView,
  subTitleView,
}: Props) => (
  <View style={styles.container} testID="post-item-header">
    {avatarView}
    <View style={styles.titleContainer}>
      {titleView}
      {subTitleView}
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  titleContainer: {
    flex: 1,
    marginLeft: 10,
  },
})

export default PostItemHeader
