import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import DocumentList from '~/components/career/features/documents/DocumentList'
import ResumesList from '~/components/career/features/resume/ResumesList'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import UserDocument from '~/interfaces/UserDocument'
import { openURL } from '~/utils/navigation'

const ResumesTemplate: React.FC = () => {
  const { isPC } = useDevice()
  const { t } = useTranslation()

  const selectDocument = (document: UserDocument): void => {
    openURL(document.file.url)
  }

  return (
    <>
      {isPC ? (
        <InfiniteScrollContainer>
          <ResumesList />
          <View style={styles.separator} />
          <DocumentList
            onSelect={selectDocument}
            subTitle={t('resume.otherDocumentSubTitle')}
          />
        </InfiniteScrollContainer>
      ) : (
        <InfiniteScrollContainer>
          <ResumesList />
          <View style={styles.separator} />
          <DocumentList
            onSelect={selectDocument}
            subTitle={t('resume.otherDocumentSubTitle')}
          />
        </InfiniteScrollContainer>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: color.gray,
    width: 940,
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
})

export default ResumesTemplate
