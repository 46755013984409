import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer'
import React from 'react'

import CareerDrawerColumn from '~/components/career/templates/drawer/DrawerTemplate'
import CommunityDrawerColumn from '~/components/community/templates/DrawerTemplate'
import ForumDrawerColumn from '~/components/forum/templates/DrawerTemplate'
import { ServiceType } from '~/constants/common/app'
import useDevice from '~/hooks/commons/useDevice'
import {
  MainStackParamList,
  MobileMainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'

type DrawerParamList = MainStackParamList &
  MobileMainStackParamList &
  OtherStackParamList

const Drawer = createDrawerNavigator<DrawerParamList>()

type Props = {
  initialRouteName: keyof DrawerParamList
  children: React.ReactNode
  serviceType: ServiceType
}

const Navigator: React.FC<Props> = ({
  initialRouteName,
  children,
  serviceType,
}: Props) => {
  const { isMobile } = useDevice()

  const drawerStyle = {
    backgroundColor: 'transparent',
    ...(isMobile && { width: '100%' }),
  }

  const drawerColumn = (props: DrawerContentComponentProps): JSX.Element => {
    if (serviceType === ServiceType.COMMUNITY) {
      return <CommunityDrawerColumn {...props} />
    } else if (serviceType === ServiceType.FORUM) {
      return <ForumDrawerColumn {...props} />
    } else return <CareerDrawerColumn {...props} />
  }

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        drawerPosition: 'right',
        drawerStyle: drawerStyle,
      }}
      initialRouteName={initialRouteName}
      drawerContent={drawerColumn}
      useLegacyImplementation={!isMobile}
    >
      {children}
    </Drawer.Navigator>
  )
}

export default { Navigator, Screen: Drawer.Screen }
