import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import AchievementGroupItem from '~/components/career/molecules/resume/AchievementGroupItem'
import ResumeBaseGroup from '~/components/career/molecules/resume/ResumeBaseGroup'
import Achievement from '~/interfaces/Achievement'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  templateType: TemplateType
  achievements: Partial<Achievement>[]
}

const AchievementGroup: React.FC<Props> = ({
  achievements,
  templateType,
}: Props) => {
  const { t } = useTranslation()
  const isInSubPage = achievements.every((item) => item.isInSubPage === true)

  return isEmpty(achievements) ? (
    <></>
  ) : (
    <ResumeBaseGroup
      category={isInSubPage ? '' : t('profile.achievement')}
      templateType={templateType!}
    >
      <View testID="achievement-group" style={styles.container}>
        {achievements.map((achievement, index) => (
          <AchievementGroupItem
            achievement={achievement}
            templateType={templateType!}
            key={index}
          />
        ))}
      </View>
    </ResumeBaseGroup>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
export default AchievementGroup
