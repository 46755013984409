import { isEmpty, isNil, omit } from 'lodash'

import categories from '~/assets/data/questionCategories.json'
import type { HTTPClient } from '~/httpClient'
import Category from '~/interfaces/Category'
import Media from '~/interfaces/Media'
import Question from '~/interfaces/Question'

export const localCategories: Category[] = categories.map(
  (item) => item as Category
)

export enum QuestionSortKeys {
  BY_CREATED_AT = 'created_at',
  BY_LIKES_COUNT = 'likes_count',
}

export type UserForumType = 'question' | 'answer'

export type QuestionQueryParams = {
  q?: string
  page?: number
  perPage?: number
  categories?: string[]
  forumType?: UserForumType
  sortBy?: QuestionSortKeys.BY_CREATED_AT | QuestionSortKeys.BY_LIKES_COUNT
}

export type QuestionFormData = Question & {
  media?: Media[]
  removedImageIds?: number[]
}

export const parseResponseCategories = (question: Question): Category[] => {
  return question.categories.map((item) => {
    const category = localCategories.find(
      (localCategory) => localCategory.name === item.name
    )
    return { ...category, id: item.id } as Category
  })
}

export class QuestionsAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async fetch({
    q,
    page,
    perPage,
    categories,
    sortBy,
  }: QuestionQueryParams): Promise<Question[]> {
    let path = '/questions'
    const params = []
    if (page) params.push(`page=${page}`)
    if (perPage) params.push(`per_page=${perPage}`)
    if (q) params.push(`q=${q}`)
    if (!isEmpty(categories)) {
      categories?.forEach((item) => item && params.push(`categories[]=${item}`))
    }
    if (sortBy) params.push(`sort_by=${sortBy}`)
    if (params.length > 0) path += '?' + params.join('&')
    const response = await this.client.get<Question[]>(path)
    return response.map((item) => ({
      ...item,
      categories: parseResponseCategories(item),
    }))
  }

  async fetchDetail(id: number): Promise<Question> {
    const response = await this.client.get<Question>(`/questions/${id}`)
    return { ...response, categories: parseResponseCategories(response) }
  }

  async create(data: Partial<QuestionFormData>): Promise<Question> {
    return await this.client.post<Question>('/questions', {
      question: {
        ...data,
        images: data.media?.map((item) => item.signedId),
        categoryList: data.categories?.map((item) => item.name),
      },
    })
  }

  private parseUpdateQuestionFormData(
    data: Partial<QuestionFormData>
  ): Partial<QuestionFormData> {
    const newMedia = data.media
      ?.filter((item) => !isNil(item.signedId))
      ?.map((item) => item.signedId)

    const questionData = {
      ...data,
      images: newMedia,
      categoryList: data.categories?.map((item) => item.name),
    }
    const omitFields = isEmpty(newMedia) ? ['media', 'images'] : ['media']
    return omit(questionData, omitFields) as Partial<QuestionFormData>
  }

  async update(data: Partial<QuestionFormData>): Promise<Question> {
    return await this.client.patch<Question>(`/questions/${data.id}`, {
      question: this.parseUpdateQuestionFormData(data),
    })
  }

  async delete(id: number): Promise<void> {
    return await this.client.delete<void>(`/questions/${id}`)
  }
}
