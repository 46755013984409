import React, { useCallback, useEffect, useState } from 'react'
import { Image, StyleSheet } from 'react-native'
import type { ImageStyle, LayoutChangeEvent, StyleProp } from 'react-native'

import useUnmountedRef from '~/hooks/useUnmountedRef'
import getImageSize from '~/utils/common/getImageSize'

type Props = {
  uri: string
  stretch: 'horizontal' | 'vertical'
  onGetSize?: (width: number, height: number) => void
  onLoad?: () => void
  onError?: () => void
  style?: StyleProp<ImageStyle>
}

type Layout = { height: number; width: number }

const ProportionalImage: React.FC<Props> = ({
  uri,
  stretch,
  style,
  onGetSize,
  onLoad,
  onError,
}: Props) => {
  const [aspectRatio, setAspectRatio] = useState<number | null>(null)
  const [layout, setLayout] = useState<Layout | null>(null)

  const unmounted = useUnmountedRef()

  useEffect(() => {
    const promise = getImageSize(uri)
    const processImageSize = async (): Promise<void> => {
      const size = await promise.catch(() => null)
      if (unmounted.current) {
        return
      }
      if (size == null) {
        // Error case
        if (onError != null) {
          onError()
        }
      } else {
        const [width, height] = size
        setAspectRatio(width / height)
        if (onGetSize != null) {
          onGetSize(width, height)
        }
      }
    }
    processImageSize()
    return promise.cancel
  }, [uri, setAspectRatio, onGetSize, onError])

  const layoutHandler = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      if (!unmounted.current) {
        setLayout({ width, height })
      }
    },
    [setLayout]
  )

  // Early return to avoid rendering Image.
  // Otherwise, onError could be called twice in total for each error from onGetSize and the Image component.
  if (aspectRatio == null) {
    return null
  }

  const styles = style == null ? [] : [style]

  if (layout != null && aspectRatio != null) {
    const { width, height } = layout
    switch (stretch) {
      case 'horizontal':
        styles.push({ width: height * aspectRatio })
        break
      case 'vertical':
        styles.push({ height: width / aspectRatio })
        break
    }
  }

  return (
    <Image
      testID="proportional-image"
      source={{ uri }}
      style={StyleSheet.flatten(styles)}
      onLayout={layoutHandler}
      onLoad={onLoad}
      onError={onError}
    />
  )
}

export default ProportionalImage
