import { PathConfigMap } from '@react-navigation/native'

import { ForumStackParamList } from '~/interfaces/RootStackParamList'

export const forumConfigPath: PathConfigMap<ForumStackParamList> = {
  forum: '/',
  questions: 'questions',
}

export const otherForumConfigPath = {
  questionNew: 'questions/new',
  questionShow: {
    path: 'questions/:id',
    parse: {
      id: Number,
    },
  },
  questionEdit: {
    path: 'questions/:id/edit',
    parse: {
      id: Number,
    },
  },
}
