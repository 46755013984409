import React from 'react'
import { StyleSheet, View } from 'react-native'
import type { StyleProp, ViewStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  text: string
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}

const MediaMask: React.FC<Props> = ({ text, style, children }: Props) => (
  <View style={StyleSheet.flatten([styles.container, style])}>
    {children}
    <View style={styles.mask} testID="media-mask">
      <Text style={styles.text} testID="media-mask-text">
        {text}
      </Text>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mask: {
    backgroundColor: color.mask,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  text: {
    color: color.white,
    fontSize: 36,
    textAlign: 'center',
  },
})

export default MediaMask
