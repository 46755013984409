import { FontAwesome as Icon } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { find } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import ProfileCommunityBanner from '~/components/common/features/users/ProfileCommunityBanner'
import HeaderSectionMenu from '~/components/common/molecules/HeaderSectionMenu'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'
import { TopTabNavigatorData } from '~/components/common/organisms/TopTabNavigator'
import JoinButton from '~/components/community/atoms/JoinButton'
import CommunityUserInvitation from '~/components/community/features/CommunityUserInvitation'
import CommunityAvatar from '~/components/community/molecules/CommunityAvatar'
import CommunityInvitationButtonGroup from '~/components/community/molecules/CommunityInvitationButtonGroup'
import HeaderCommunityAction from '~/components/community/molecules/HeaderCommunityAction'
import CommunityInfo from '~/components/community/molecules/information/CommunityInfo'
import IconName from '~/constants/common/IconName'
import color from '~/constants/common/color'
import { CommunitySection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import Media from '~/interfaces/Media'
import { currentUserSelector } from '~/slices/common/users'
import {
  acceptCommunityMemberInvitation,
  canLeaveCommunity,
  communitySelector,
  isCommunityAdmin,
  rejectMemberInvitation,
  removeCommunityMember,
  updateCommunity,
} from '~/slices/community/community'
import { setPageTitle } from '~/utils/navigation'

const HeaderCommunityDetail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const [isShowInvitationDialog, setShowInvitationDialog] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const community = useSelector(communitySelector)

  const invitationStyle = isPC ? styles.invitation : styles.invitationMobile
  const memberInvitation = (community?.communityMemberInvitations || []).find(
    (item) => item.userId === currentUser?.id
  )
  const member = find(community?.communityMembers, { userId: currentUser?.id })
  const isMember = member !== undefined
  const isAdmin =
    community?.communityMembers &&
    !!find(community.communityMembers, {
      isAdmin: true,
      userId: currentUser?.id,
    })

  const leaveCommunity = useCallback(() => {
    member && dispatch(removeCommunityMember(member.id))
  }, [member])

  const updateAvatar = (media: Media[]): void => {
    Promise.all([
      dispatch(
        updateCommunity({
          ...community,
          avatar: media[0],
          avatarId: media[0].id,
        })
      ),
    ])
  }

  const getActionsGroup = (): React.ReactElement => {
    if (isMember) {
      return (
        <HeaderCommunityAction
          isPC={isPC}
          isAdmin={isCommunityAdmin(
            currentUser?.id,
            community?.communityMembers
          )}
          onPressInvite={(): void => setShowInvitationDialog(true)}
        />
      )
    } else if (memberInvitation) {
      return (
        <CommunityInvitationButtonGroup
          onPressAccept={(): void => {
            community && dispatch(acceptCommunityMemberInvitation(community.id))
          }}
          onPressReject={(): void => {
            memberInvitation &&
              dispatch(rejectMemberInvitation(memberInvitation.id))
          }}
        />
      )
    } else {
      return !community ? (
        <></>
      ) : (
        <JoinButton user={currentUser} community={community} />
      )
    }
  }

  useFocusEffect(() => setPageTitle(community?.name))

  const sectionData: TopTabNavigatorData[] = [
    {
      label: t('headerTab.talk'),
      key: CommunitySection.TALK,
      element: isPC ? undefined : (
        <Icon name={IconName.COMMUNITY_TALK} color={color.primary} size={22} />
      ),
    },
    {
      label: t('headerTab.events'),
      key: CommunitySection.EVENTS,
      element: isPC ? undefined : (
        <Icon name={IconName.COMMUNITY_EVENT} color={color.primary} size={22} />
      ),
    },
    {
      label: t('headerTab.information'),
      key: CommunitySection.INFO,
      element: isPC ? undefined : (
        <Icon
          name={IconName.COMMUNITY_INFORMATION}
          color={color.primary}
          size={22}
        />
      ),
    },
    {
      label: isAdmin
        ? t('headerTab.admin')
        : t('community.information.members'),
      key: isAdmin ? CommunitySection.ADMIN : CommunitySection.MEMBERS,
      element: isPC ? undefined : (
        <Icon
          name={isAdmin ? IconName.COMMUNITY_ADMIN : IconName.COMMUNITY_MEMBER}
          color={color.primary}
          size={22}
        />
      ),
    },
  ]

  const infoComponent = (
    <>
      <ProfileInfo
        isPC={isPC}
        avatarComponent={
          <CommunityAvatar
            editable={isCommunityAdmin(
              currentUser?.id,
              community?.communityMembers
            )}
            showBorder={true}
            community={community}
            onUpload={updateAvatar}
            size={isPC ? ProfileAvatarSize.GIGANTIC : ProfileAvatarSize.HUGE}
          />
        }
        rightComponent={<CommunityInfo community={community} />}
      />
      {!isPC && <View style={styles.actionGroup}>{getActionsGroup()}</View>}
    </>
  )

  return (
    <>
      <View
        testID="header-community-detail"
        style={isPC ? styles.container : undefined}
      >
        <ProfileHeader
          isPC={isPC}
          bannerComponent={
            <ProfileCommunityBanner
              canLeave={canLeaveCommunity(
                currentUser?.id,
                community?.communityMembers
              )}
              onPressLeave={leaveCommunity}
            />
          }
          infoComponent={infoComponent}
          footerLeftComponent={
            isPC ? (
              <View style={styles.section}>
                <HeaderSectionMenu
                  isPC={isPC}
                  data={sectionData}
                  defaultSection={CommunitySection.TALK}
                />
              </View>
            ) : (
              <HeaderSectionMenu
                isPC={isPC}
                data={sectionData}
                defaultSection={CommunitySection.TALK}
              />
            )
          }
          footerRightComponent={
            isPC ? (
              <View style={styles.actionGroupContainer}>
                {getActionsGroup()}
              </View>
            ) : undefined
          }
        />
      </View>
      {isShowInvitationDialog && (
        <ModalContainer
          visible={isShowInvitationDialog}
          onDismiss={(): void => setShowInvitationDialog(false)}
        >
          <View style={invitationStyle}>
            {!isPC && (
              <Icon
                size={20}
                name="window-close"
                style={styles.closeIcon}
                onPress={(): void => setShowInvitationDialog(false)}
              />
            )}
            <CommunityUserInvitation />
          </View>
        </ModalContainer>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  actionGroup: {
    bottom: 4,
    position: 'absolute',
    right: 4,
  },
  actionGroupContainer: {
    paddingTop: 10,
  },
  closeIcon: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
  container: {
    alignSelf: 'center',
    marginTop: 10,
    width: 814,
  },
  invitation: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    height: 635,
    padding: 40,
    width: 500,
  },
  invitationMobile: {
    backgroundColor: color.white,
    borderRadius: 4,
    height: '100%',
    maxHeight: 600,
    maxWidth: 375,
    padding: 10,
    width: '100%',
  },
  section: {
    maxWidth: 800,
  },
})

export default HeaderCommunityDetail
