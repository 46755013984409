import React from 'react'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'

type Props = {
  title?: string
  children?: React.ReactNode
}

const StatisticCardContainer: React.FC<Props> = ({
  children,
  title,
}: Props) => {
  return (
    <CardContainer style={styles.container} testID="statistic-card-container">
      <TitleText style={styles.title}>{title}</TitleText>
      <View style={styles.separator} />
      {children}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    marginTop: 13,
    justifyContent: 'center',
    backgroundColor: color.white,
  },
  separator: {
    marginVertical: 10,
    borderBottomWidth: 1,
    width: '100%',
    borderBottomColor: color.gray,
  },
  title: {
    color: color.textGray,
  },
})

export default StatisticCardContainer
