import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Image, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import EditResumeHeaderActContainer from '~/components/career/molecules/resume/EditResumeHeaderActContainer'
import OutlineButton from '~/components/common/atoms/OutlineButton'
import HeaderIndicator from '~/components/common/molecules/HeaderIndicator'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import useCustomToast from '~/hooks/useCustomToast'
import { RootState } from '~/rootReducer'
import { setResumeStatus, updateResume } from '~/slices/career/resumes'
import { getResumeValidationError } from '~/utils/career/resume'
import { openURL } from '~/utils/navigation'

const EditResumeHeader: React.FC = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useContext(RootContext)
  const { current: resume, status, isLoading } = useSelector(
    (state: RootState) => state.resumes
  )
  const containerStyle = isPC ? styles.containerPC : styles.containerMobile
  const headerContentStyle = isPC ? styles.content : styles.contentMobile
  const leftColumnStyle = isPC ? styles.leftColumn : styles.leftColumnMobile
  const currentEditResumeIndex = useSelector(
    (state: RootState) => state.resumes.currentEditResumeIndex
  )

  const saveResume = (): void => {
    const resumeValidationError = getResumeValidationError(
      currentEditResumeIndex,
      resume,
      t
    )
    if (!isEmpty(resumeValidationError)) {
      toast.showError(resumeValidationError)
      return
    }
    dispatch(updateResume(resume))
    dispatch(setResumeStatus('saved'))
  }

  return (
    <View style={containerStyle} testID="edit-resume-header">
      <View style={headerContentStyle}>
        <View style={leftColumnStyle} testID="left-column">
          <HeaderIndicator
            numOfElements={2}
            index={currentEditResumeIndex}
            title={t('resume.editResumeSlogan')}
          />
          <EditResumeHeaderActContainer
            saveButton={
              <OutlineButton
                onPress={saveResume}
                isLoading={isLoading}
                title={status === 'editing' ? t('save') : t('saved')}
              />
            }
            previewButton={
              <OutlineButton
                title={t('preview')}
                onPress={(): void => openURL(`/resumes/${resume.id}`)}
              />
            }
          />
        </View>
        <Image
          testID="header-image"
          source={require('~/assets/images/resumes/resume_edit.png')}
          style={isPC ? styles.image : styles.imageMobile}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerMobile: {
    flexDirection: 'row',
    backgroundColor: color.primary,
    paddingHorizontal: 20,
  },
  containerPC: {
    backgroundColor: color.primary,
    height: 220,
    justifyContent: 'center',
  },
  content: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    overflow: 'hidden',
    width: 815,
  },
  contentMobile: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    height: 151,
    marginLeft: 50,
    width: 150,
  },
  imageMobile: {
    height: 77,
    marginLeft: 20,
    width: 76,
  },
  leftColumn: {
    justifyContent: 'center',
  },
  leftColumnMobile: {
    justifyContent: 'center',
    padding: 10,
  },
})

export default EditResumeHeader
