import React from 'react'
import { View } from 'react-native'
import { CheckBoxProps } from 'react-native-elements'

import CheckBox from '~/components/workarounds/CheckBox'

const CustomCheckBox: React.FC<CheckBoxProps> = (props: CheckBoxProps) => (
  <View testID="custom-check-box">
    <CheckBox {...props} size={24} />
  </View>
)

export default CustomCheckBox
