import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import FormAction from '~/components/common/atoms/users/FormAction'
import EditCurrentPosition from '~/components/common/molecules/users/profileStatus/EditCurrentPosition'
import JobHuntingStatusGroup from '~/components/common/molecules/users/profileStatus/JobHuntingStatusGroup'
import { RootState } from '~/rootReducer'
import { updateCurrentUser } from '~/slices/common/users'

type Props = {
  onFinish: () => void
  jobHuntingStatus: string[]
  currentPosition: string
}

const StatusForm: React.FC<Props> = ({
  onFinish,
  jobHuntingStatus: currentJobHuntingStatus,
  currentPosition,
}: Props) => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.users.isLoading)
  const [isDataValid, setIsDataValid] = useState(false)

  const [jobHuntingStatus, setJobHuntingStatus] = useState<string[]>([
    ...currentJobHuntingStatus,
  ])
  const [positionUpdate, setPositionUpdate] = useState(currentPosition ?? '')

  const validate = (): void => {
    if (!isEmpty(jobHuntingStatus)) {
      setIsDataValid(true)
    } else {
      setIsDataValid(false)
    }
  }

  const submitUserProfile = async (): Promise<void> => {
    try {
      await Promise.all([
        dispatch(
          updateCurrentUser({
            userProfile: { jobHuntingStatus, position: positionUpdate },
          })
        ),
      ])
    } catch (error) {
      // TODO handle error
      console.error(error)
    }
    onFinish()
  }

  useEffect(() => {
    validate()
  }, [JSON.stringify(jobHuntingStatus)])

  return (
    <View testID="status-form">
      <EditCurrentPosition
        currentPosition={positionUpdate}
        onChangeText={setPositionUpdate}
      />
      <JobHuntingStatusGroup
        selectedItems={jobHuntingStatus}
        onChange={(selectedItems: string[]): void => {
          setJobHuntingStatus(selectedItems)
          validate()
        }}
      />
      <FormAction
        onCancel={onFinish}
        isLoading={isLoading}
        disabled={!isDataValid}
        onSubmit={submitUserProfile}
      />
    </View>
  )
}

export default StatusForm
