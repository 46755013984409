import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Image } from 'react-native-elements'

import { resumeDefaultImages } from '~/constants/career/resumes'
import color from '~/constants/common/color'
import Resume from '~/interfaces/Resume'

type Props = {
  resume: Partial<Resume>
}

const ResumeAvatar: React.FC<Props> = ({ resume }: Props) => {
  const templateType = resume.templateType!
  const imageSource =
    { uri: resume.image?.urls.original } ?? resumeDefaultImages[templateType]

  const avatarStyle = {
    enjin: {
      ...styles.size,
      borderRadius: 90,
      borderWidth: 8,
      borderColor: color.white,
    },
    shades: {
      ...styles.size,
      borderRadius: 90,
      borderWidth: 8,
      borderColor: color.white,
    },
    formal: { ...styles.size },
    minimal: { ...styles.size },
  }

  const containerStyle = {
    enjin: undefined,
    shades: {
      position: 'absolute',
      top: -120,
      alignSelf: 'center',
    },
    formal: undefined,
    minimal: undefined,
  }

  return (
    <View
      testID="resume-avatar"
      style={containerStyle[templateType] as ViewStyle}
    >
      <Image
        source={imageSource}
        width={styles.size.width}
        height={styles.size.height}
        style={avatarStyle[templateType]}
      />
    </View>
  )
}

const styles = {
  size: {
    width: 160,
    height: 160,
  },
}

export default ResumeAvatar
