import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import api from '~/api'
import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import ControlMenuItem from '~/components/workarounds/MenuItem'
import useCustomToast from '~/hooks/useCustomToast'
import Comment from '~/interfaces/Comment'

export enum AnswerCommentAction {
  DELETE,
  EDIT,
}

type Props = {
  comment: Comment
  answerId: number
  questionId: number
  onSelect?: (comment: Comment, action: AnswerCommentAction) => void
}

const AnswerCommentControlMenu: React.FC<Props> = ({
  comment,
  answerId,
  onSelect,
  questionId,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  const deleteComment = async (): Promise<void> => {
    try {
      await api.forumComments.delete({
        questionId,
        answerId,
        commentId: comment.id,
      })
      onSelect && onSelect({ ...comment }, AnswerCommentAction.DELETE)
    } catch (err) {
      toast.showError(err)
    }
  }

  const acceptConfirmation = (): void => {
    deleteComment()
    setIsShowConfirmDialog(false)
  }

  return (
    <View testID="answer-comment-control-menu">
      <ControlMenuContainer
        acceptLabel={t('delete')}
        onPressAccept={acceptConfirmation}
        isShowConfirmDialog={isShowConfirmDialog}
        confirmationMessage={t('forum.confirmDeleteComment')}
        onPressCancel={(): void => setIsShowConfirmDialog(false)}
      >
        {/* TODO feature yet to implement, there isn't edit answer comment API yet. Implemenation without API now could be caused confusions */}
        {/* <ControlMenuItem
          title={t('edit')}
          onSelect={(): void =>
            onSelect && onSelect({ ...comment }, AnswerCommentAction.EDIT)
          }
        /> */}
        <ControlMenuItem
          title={t('delete')}
          onSelect={(): void => setIsShowConfirmDialog(true)}
        />
      </ControlMenuContainer>
    </View>
  )
}

export default AnswerCommentControlMenu
