import React from 'react'
import { StyleSheet, View } from 'react-native'

import SocialActionContainer from '~/components/common/molecules/socials/SocialActionContainer'
import SocialCount from '~/components/common/molecules/socials/SocialCount'
import color from '~/constants/common/color'

type Props = {
  isHideBottomLine?: boolean
  likeButton?: React.ReactElement
  shareButton?: React.ReactElement
  likeCountComponent?: React.ReactElement
  sharedCountComponent?: React.ReactElement //Feature yet to implement
  commentCountComponent?: React.ReactElement
}

const SocialActivity: React.FC<Props> = ({
  likeButton,
  shareButton,
  isHideBottomLine,
  likeCountComponent,
  sharedCountComponent,
  commentCountComponent,
}: Props) => (
  <View testID="social-activity" style={styles.container}>
    <SocialCount
      likeCountComponent={likeCountComponent}
      commentCountComponent={commentCountComponent}
      sharedCountComponent={sharedCountComponent}
    />
    <View style={StyleSheet.flatten([styles.separator, { marginTop: 10 }])} />
    <SocialActionContainer>
      {likeButton}
      {shareButton}
    </SocialActionContainer>
    {!isHideBottomLine && (
      <View style={styles.separator} testID="social-activity-bottom-line" />
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingTop: 18,
  },
  separator: {
    borderBottomColor: color.borderSeparator,
    borderBottomWidth: 1,
  },
})

export default SocialActivity
