import { useFocusEffect, useRoute } from '@react-navigation/native'
import { first, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import JobApply from '~/components/career/features/jobs/JobApply'
import JobSave from '~/components/career/features/jobs/JobSave'
import HeaderJob from '~/components/career/organisms/jobs/HeaderJob'
import JobBasicInfo from '~/components/career/organisms/jobs/JobBasicInfo'
import JobCompanyDescription from '~/components/career/organisms/jobs/JobCompanyDescription'
import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import JobOverview from '~/components/career/organisms/jobs/JobOverview'
import JobQualifications from '~/components/career/organisms/jobs/JobQualifications'
import JobResponsibilities from '~/components/career/organisms/jobs/JobResponsibilities'
import JobSkills from '~/components/career/organisms/jobs/JobSkills'
import JobStatus from '~/components/career/organisms/jobs/JobStatus'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import OutsiderGuide from '~/components/common/features/auth/OutsiderGuide'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import Company from '~/interfaces/Company'
import Recruitment from '~/interfaces/Recruitment'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { currentCompanySelector } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { RouteParamsID } from '~/utils/navigation'

const JobShowTemplate: React.FC = () => {
  const route = useRoute()
  const { t } = useTranslation()
  const id = (route?.params as RouteParamsID)?.id
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const company = useSelector(currentCompanySelector)
  const [isLoading, setIsLoading] = useState(false)
  const [job, setJob] = useState<Recruitment | undefined>(undefined)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const fetchJob = useAPI(
    async (api: API): Promise<void> => {
      if (!id) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.recruitments.show<Recruitment>(id)
        setJob(response)
      } catch (err) {
        if (api.isAlive) {
          setIsLoading(false)
        }
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [id, setIsLoading]
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchJob(company)
    }, [fetchJob, company])
  )

  if (!job || isLoading) {
    return <View testID="empty-view" />
  }

  const isUserCompany = () => {
    const userCompany = currentUser?.profile?.companies?.find(
      (item) => item.id === job.companyId
    )
    return userCompany !== undefined
  }
  const onApplyJob = (newApplyStatus: boolean) => {
    setJob({ ...job, isApplied: newApplyStatus })
  }

  const basicInfoGroup = (
    <>
      <JobBasicInfo job={job} isPC={isPC} />
      {job.skills?.length ? <JobSkills skills={job.skills} /> : undefined}
    </>
  )
  const jobInfoGroup = (
    <>
      {!isEmpty(job.companyDescription) && (
        <JobCompanyDescription job={job} isPC={isPC} />
      )}
      {!isEmpty(job.overview.trim()) && <JobOverview job={job} isPC={isPC} />}
      {!isEmpty(job.job) && <JobResponsibilities job={job} isPC={isPC} />}
      {!isEmpty(job.criterion) && <JobQualifications job={job} isPC={isPC} />}
      {(!isEmpty(job.salary) || !isEmpty(job.welfare)) && (
        <JobInfoBaseLayout
          isPC={isPC}
          title={t('jobs.salaryAndBenefit')}
          content={`${job.salary} & ${job.welfare}`}
        />
      )}
      {!isEmpty(job.workLocation) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.workLocation}
          title={t('jobs.workLocation')}
        />
      )}
      {!isEmpty(job.allowance) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.allowance}
          title={t('jobs.jobAllowance')}
        />
      )}
      {!isEmpty(job.insurance) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.allowance}
          title={t('jobs.jobInsurance')}
        />
      )}
      {!isEmpty(job.holiday) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.holiday}
          title={t('jobs.jobHoliday')}
        />
      )}
      {!isEmpty(job.welfare) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.welfare}
          title={t('jobs.jobWelfare')}
        />
      )}
      {!isEmpty(job.capital) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.capital}
          title={t('jobs.jobCapital')}
        />
      )}
      {!isEmpty(job.headOfficeArea) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.headOfficeArea}
          title={t('jobs.jobHeadOffice')}
        />
      )}
      {!isEmpty(job.numOfEmployees) && (
        <JobInfoBaseLayout
          isPC={isPC}
          content={job.numOfEmployees}
          title={t('jobs.jobNumOfEmployees')}
        />
      )}
      {!isUserCompany() && (
        <View style={styles.bottomApplyButton as ViewStyle}>
          <JobApply job={job} onApply={onApplyJob} />
        </View>
      )}
    </>
  )

  const isAdminCompany = (company: Company, user: User) => {
    try {
      const userWorkingCompany = first(
        company?.userWorkingCompanies.filter((item) => item.user.id === user.id)
      )
      return userWorkingCompany?.isAdmin
    } catch {
      return false
    }
  }

  return (
    <>
      <ScrollView testID="job-show-template">
        {currentUser?.isCompanyAccount &&
          company?.id === job.companyId &&
          isAdminCompany(company!, currentUser!) && (
            <JobStatus job={job} isPC={isPC} company={company!} />
          )}
        <HeaderJob
          job={job}
          footerRightComponent={
            isUserCompany() ? undefined : (
              <HorizontalContainer>
                <JobSave job={job} />
                <JobApply job={job} onApply={onApplyJob} />
              </HorizontalContainer>
            )
          }
        />
        <View
          style={
            (isPC
              ? styles.bodyWithIsPC.true
              : styles.bodyWithIsPC.false) as ViewStyle
          }
        >
          <View
            style={styles.jobBodyContainer as ViewStyle}
            testID="job-show-template-content-wrapper"
          >
            {basicInfoGroup}
            {jobInfoGroup}
            <View />
          </View>
        </View>
      </ScrollView>
      {!accessToken && <OutsiderGuide />}
    </>
  )
}

const styles = {
  bottomApplyButton: {
    alignItems: 'center',
    marginBottom: 10,
  },
  jobBodyContainer: {
    gap: 16,
  },
  subRightColumn: {
    alignSelf: 'baseline',
    width: 200,
  },
  subLeftColumn: {
    flex: 1,
  },
  separator: {
    padding: 5,
  },
  saveText: {
    textDecorationLine: 'underline',
    color: color.pressableText,
    marginRight: 20,
    fontWeight: 'bold',
  },
  bodyWithIsPC: {
    true: {
      width: 814,
      alignSelf: 'center',
    },
    false: {
      flex: 1,
    },
  },
}

export default JobShowTemplate
