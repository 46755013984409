import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import ChannelControlMenu from '~/components/community/molecules/ChannelControlMenu'
import ChannelHeaderForm from '~/components/community/molecules/ChannelHeaderForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { ChannelControlMenuType } from '~/interfaces/ChannelControlMenu'
import { ChannelViewMode } from '~/interfaces/Community'
import { RootState } from '~/rootReducer'
import { isCommunityAdmin, setChannelMode } from '~/slices/community/community'

type Props = {
  onSelectControlMenu?: (type: ChannelControlMenuType) => void
}

const ChannelHeaderPC: React.FC<Props> = ({ onSelectControlMenu }: Props) => {
  const dispatch = useDispatch()
  const { channelMode, current } = useSelector(
    (state: RootState) => state.community.channel
  )

  const community = useSelector((state: RootState) => state.community.current)
  const currentUserId = useSelector(
    (state: RootState) => state.users.current?.id
  )
  const isAdmin = isCommunityAdmin(currentUserId, community?.communityMembers)
  const changeChannelViewMode = (channelMode: ChannelViewMode): void => {
    dispatch(setChannelMode(channelMode))
  }

  return (
    <View testID="channel-header-pc">
      <Text style={styles.name}>{current?.name}</Text>
      <Text style={styles.description} numberOfLines={10}>
        {current?.description}
      </Text>
      <ChannelHeaderForm
        channelMode={channelMode}
        onPressChannelMode={changeChannelViewMode}
        isPublic={current?.isPublic}
      />
      {isAdmin && (
        <View style={styles.controlMenu} testID="channel-control-menu">
          <ChannelControlMenu
            isPC={true}
            onSelect={onSelectControlMenu}
            isPublic={current?.isPublic}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  controlMenu: {
    position: 'absolute',
    right: 20,
    top: 10,
  },
  description: {
    color: color.white,
    flexWrap: 'wrap',
    fontSize: FontSize.REMARKABLE,
    marginBottom: 10,
  },
  name: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    fontWeight: '600',
    marginBottom: 10,
  },
})

export default ChannelHeaderPC
