import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, TextStyle, StyleSheet, ViewStyle } from 'react-native'

import CategoryTitle from '~/components/career/molecules/resume/CategoryTitle'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Resume, { TemplateType } from '~/interfaces/Resume'

type Props = {
  resume: Partial<Resume>
}

const UserSkills: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()
  const templateType = resume.templateType!

  const skillTextColors = {
    enjin: { color: color.black },
    shades: { color: color.black },
    formal: { color: color.white },
    minimal: { color: color.black },
  }

  const skillStyle = {
    enjin: {
      fontSize: FontSize.REMARKABLE,
      backgroundColor: color.secondary,
      paddingHorizontal: 10,
      margin: 4,
      paddingVertical: 2,
      alignSelf: 'baseline',
      ...commonStyles.borderRadiusAllCorner,
    },
    shades: {
      fontSize: FontSize.REMARKABLE,
      backgroundColor: color.white,
      paddingHorizontal: 10,
      margin: 4,
      paddingVertical: 2,
      alignSelf: 'baseline',
      ...commonStyles.borderRadiusAllCorner,
    },
    formal: undefined,
    minimal: undefined,
  }

  const skillsContainerStyle = {
    enjin: { flexDirection: 'row', flexWrap: 'wrap', flex: 1 },
    shades: { flexDirection: 'row', flexWrap: 'wrap', flex: 1 },
    formal: undefined,
    minimal: undefined,
  }

  const categoryTitleInverted = {
    enjin: undefined,
    shades: undefined,
    formal: true,
    minimal: undefined,
  }

  return isEmpty(resume.skills) ? (
    <></>
  ) : (
    <View testID="user-skills" style={styles.container}>
      <CategoryTitle
        templateType={templateType}
        title={t('resume.user.skillTitle')}
        inverted={categoryTitleInverted[templateType]}
        shouldShowUnderLine={templateType === TemplateType.Shades}
      />
      <View
        testID="skills-container"
        style={skillsContainerStyle[templateType] as ViewStyle}
      >
        {resume.skills!.map((skill) => (
          <Text
            testID="user-skill"
            key={skill.id}
            style={
              {
                ...skillStyle[templateType],
                ...skillTextColors[templateType],
              } as TextStyle
            }
          >
            {skill.name}
          </Text>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
})

export default UserSkills
