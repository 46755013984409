import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  message: string
  extra?: React.ReactElement
  isPC?: boolean
}

const RegistrationMessage: React.FC<Props> = (props: Props) => {
  return (
    <View style={styles.container} testID="registration-message">
      <View
        style={!props.isPC ? styles.errorMessageMobile : styles.errorMessagePC}
      >
        <Text style={styles.text} testID="registration-message-text">
          {props.message}
        </Text>
        {props.extra}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
  },
  errorMessageMobile: {
    paddingLeft: 35,
    paddingTop: 20,
  },
  errorMessagePC: {
    paddingTop: 10,
  },
  text: {
    color: color.danger,
  },
})

export default RegistrationMessage
