import React from 'react'
import { View, StyleSheet } from 'react-native'

import EnjinTermsAndPolicy from '~/components/common/molecules/auth/EnjinTermsAndPolicy'
import color from '~/constants/common/color'

type Props = {
  topOptionComponents?: React.ReactElement[]
  middleOptionComponents?: React.ReactElement[]
  bottomOptionComponents?: React.ReactElement[]
}

const MenuDrawerOptions: React.FC<Props> = ({
  topOptionComponents,
  middleOptionComponents,
  bottomOptionComponents,
}: Props) => {
  return (
    <View testID="menu-drawer-options" style={styles.container}>
      <View>{topOptionComponents}</View>
      <View style={styles.fillSpace} />
      <View>{middleOptionComponents}</View>
      <View style={styles.separator} />
      <View>{bottomOptionComponents}</View>
      <View style={styles.separator} />
      <EnjinTermsAndPolicy />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fillSpace: {
    flex: 1,
  },
  separator: {
    marginTop: 20,
    marginBottom: 20,
    borderBottomColor: color.borderSeparator,
    borderBottomWidth: 1,
  },
})

export default MenuDrawerOptions
