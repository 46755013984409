import React from 'react'
import { StyleSheet, View } from 'react-native'

import PostMessage from '~/components/career/molecules/post/PostMessage'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import color from '~/constants/common/color'
import Comment from '~/interfaces/Comment'

type Props = {
  comment: Comment
  commentControlMenu?: React.ReactElement
}

const AnswerCommentItem: React.FC<Props> = ({
  comment,
  commentControlMenu,
}: Props) => (
  <View style={styles.container} testID="answer-comment-item">
    <UserAvatar user={comment.user} size={ProfileAvatarSize.TINY} />
    <View style={styles.fillView}>
      <View style={styles.commentContainer} testID="comment-container">
        <View style={styles.comment}>
          <UserName size="small" user={comment.user} />
          <PostMessage message={comment.body} isComment={true} />
        </View>
        <View style={styles.separator} />
        {commentControlMenu}
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  comment: {
    backgroundColor: color.secondaryLight,
    borderRadius: 10,
    flexShrink: 1,
    marginLeft: 10,
    padding: 14,
  },
  commentContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    marginVertical: 10,
  },
  fillView: {
    flex: 1,
  },
  separator: {
    width: 6,
  },
})

export default AnswerCommentItem
