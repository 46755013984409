import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import SearchInput from '~/components/common/atoms/SearchInput'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  keyword?: string
  onPressSearch: () => void
  onChangeText: (value: string) => void
}

const UsersHeaderLeft: React.FC<Props> = ({
  keyword,
  onChangeText,
  onPressSearch,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="users-header-left" style={styles.container}>
      <TitleText testID="users-subheader-title" style={styles.title}>
        {t('search.users')}
      </TitleText>
      <View style={styles.conditionItem} testID="users-subheader-search">
        <SearchInput
          inverted
          value={keyword}
          onSubmit={onPressSearch}
          onChangeText={onChangeText}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  conditionItem: {
    marginRight: 20,
    width: 350,
    marginTop: 10,
  },
  container: {
    flexDirection: 'row',
  },
  title: {
    alignSelf: 'center',
    color: color.white,
    fontSize: FontSize.GENERAL,
    marginRight: 40,
  },
})

export default UsersHeaderLeft
