import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  title?: string
  content?: string
  footerComponent?: React.ReactElement
  contentComponent?: React.ReactNode
}

const JobBaseSubLayout: React.FC<Props> = ({
  title,
  content,
  footerComponent,
  contentComponent,
}: Props) => (
  <View testID="job-base-sub-layout" style={styles.container}>
    <Text style={styles.title} testID="job-base-sub-title">
      {title}
    </Text>
    {contentComponent || (
      <Text style={styles.content} testID="job-base-sub-content">
        {content}
      </Text>
    )}
    {footerComponent}
  </View>
)

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
  },
  content: {
    paddingVertical: 5,
    color: color.textGray,
  },
  title: {
    fontWeight: 'bold',
    ...commonStyles.titleTextSize,
  },
})

export default JobBaseSubLayout
