import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import SelectListItem from '~/interfaces/SelectListItem'

type Props = {
  item: SelectListItem
  isSelected: boolean
  onSelect: (itemId: number | string) => void
}

const SelectableListItem: React.FC<Props> = ({
  item,
  isSelected,
  onSelect,
}: Props) => (
  <TouchableOpacity
    testID="selectable-list-item"
    onPress={(): void => onSelect(item.id)}
    style={isSelected ? styles.selectedItem : styles.item}
  >
    {item.icon}
    <Text style={isSelected ? styles.selectedItemContent : styles.itemContent}>
      {item.name}
    </Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  item: {
    backgroundColor: color.white,
    flexDirection: 'row',
    padding: 10,
  },
  itemContent: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  selectedItem: {
    backgroundColor: color.primaryLight,
    flexDirection: 'row',
    padding: 10,
  },
  selectedItemContent: {
    color: color.darkGray,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
})

export default SelectableListItem
