import { Ionicons as Icon } from '@expo/vector-icons'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'

export type EditButtonStyles = 'icon' | 'text'

type Props = {
  onPressCancel?: () => void
  onPressSave?: () => void
  onPressEdit?: () => void
  isLoading?: boolean
  isEditing?: boolean
  editButtonStyle?: EditButtonStyles
}

const EditableTextAction: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  return (
    <View style={styles.container} testID="editable-text-action">
      {!props.isEditing ? (
        <TouchableOpacity
          style={StyleSheet.flatten([
            styles.editButton,
            { paddingTop: isPC ? 3 : 0 },
          ])}
          onPress={props.onPressEdit}
          testID="edit-button"
        >
          {props.editButtonStyle === 'icon' ? (
            <Icon size={24} name="pencil" color={color.primary} />
          ) : (
            <Text style={styles.edit}>{t('edit')}</Text>
          )}
        </TouchableOpacity>
      ) : (
        <View style={styles.buttonGroup}>
          <Button
            type="solid"
            testID="cancel-button"
            title={t('cancel')}
            disabled={props.isLoading}
            onPress={props.onPressCancel}
            buttonStyle={StyleSheet.flatten(styles.cancelButton)}
            titleStyle={StyleSheet.flatten(styles.cancelTitle)}
          />
          <Button
            type="solid"
            testID="save-button"
            title={t('save')}
            loading={props.isLoading}
            onPress={props.onPressSave}
            buttonStyle={StyleSheet.flatten(styles.saveButton)}
            titleStyle={StyleSheet.flatten(styles.buttonTitle)}
            disabled={props.isLoading}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  buttonTitle: {
    fontSize: FontSize.SUB,
  },
  cancelButton: {
    backgroundColor: color.gray,
    height: 30,
    width: 90,
  },
  cancelTitle: {
    alignSelf: 'center',
    color: color.black,
    fontSize: FontSize.SUB,
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
  },
  edit: {
    color: color.pressableText,
    padding: 0,
    paddingLeft: 5,
    textDecorationLine: 'underline',
  },
  editButton: {
    height: 30,
    paddingTop: 3,
  },
  saveButton: {
    height: 30,
    marginLeft: 10,
    width: 90,
  },
})

export default EditableTextAction
