import React from 'react'
import { Image as RNImage, StyleSheet, Text, View } from 'react-native'

import hiringData from '~/assets/data/jobHiring.json'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Image from '~/interfaces/Image'
import Recruitment from '~/interfaces/Recruitment'
import { getCurrentLanguage } from '~/plugins/i18n'
import { navigate } from '~/utils/navigation'

export type Props = {
  isPC?: boolean
  job: Recruitment
}

const SuggestedJobsItem: React.FC<Props> = ({ isPC, job }: Props) => {
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const uri = (job.headerImage as Image)?.presignedUrl
  const hiringText = job.hiring
    ?.map((item) => hiringData[item][getCurrentLanguage()].name)
    .join(', ')

  const navigateJobDetail = () => {
    navigate('jobShow', { id: job.id })
  }

  return (
    <View style={containerStyle} testID="suggested-jobs-item">
      <View style={styles.jobImage}>
        <RNImage
          source={
            uri
              ? { uri }
              : require('~/assets/images/career/empty-job-portrait.png')
          }
          style={styles.imageStyle}
        />
      </View>
      <View style={styles.jobInfo}>
        <Text
          numberOfLines={2}
          style={styles.title}
          onPress={navigateJobDetail}
        >
          {job.subject}
        </Text>
        <Text numberOfLines={1} style={styles.subTitle}>
          {hiringText}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
  },
  containerMobile: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  imageStyle: {
    height: 60,
    width: 60,
  },
  jobImage: {
    alignSelf: 'center',
  },
  jobInfo: {
    flexWrap: 'wrap',
    flex: 1,
    marginLeft: 15,
  },
  subTitle: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  title: {
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
})

export const areEqual = (
  prevProps: Readonly<React.PropsWithChildren<Props>>,
  nextProps: Readonly<React.PropsWithChildren<Props>>
): boolean => {
  return (
    prevProps.isPC === nextProps.isPC && prevProps.job.id === nextProps.job.id
  )
}
export default React.memo(SuggestedJobsItem, areEqual)
