import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { first, isEmpty, isNil, isUndefined } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import snakecaseKeys from 'snakecase-keys'

import { API } from '~/api'
import { RecruimentFilterKey, RecruimentQueryParams } from '~/api/recruitments'
import emptyJobImage from '~/assets/images/prompts/nopostsyet.png'
import JobItem from '~/components/career/organisms/jobs/JobItem'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import EmptyView from '~/components/common/molecules/EmptyView'
import SkeletonItemWithCover from '~/components/common/molecules/SkeletonItemWithCover'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import Recruitment from '~/interfaces/Recruitment'
import { currentCompanySelector } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { getRouteParams } from '~/utils/navigation'

type Props = {
  header?: React.ReactElement
  isCompanyMode?: boolean
  isJobDashboard?: boolean
  company?: Company
}

const JobsList: React.FC<Props> = ({
  header,
  isCompanyMode,
  isJobDashboard,
  company,
}: Props) => {
  const { t } = useTranslation()
  const { setParams } = useNavigation()
  const queryParams = getRouteParams<RecruimentQueryParams>()
  const currentUser = useSelector(currentUserSelector)
  const currentCompany = !isUndefined(company)
    ? company
    : useSelector(currentCompanySelector)

  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const defaultPage = 1
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [recruitments, setRecruitments] = useState<Recruitment[]>([])
  const shouldShowSkeleton = isLoading && isEmpty(recruitments)
  const toast = useCustomToast()

  const isAdminCompany = (job: Recruitment) => {
    const userWorkingCompany = first(
      currentCompany?.userWorkingCompanies?.filter(
        (item) => item.user?.id === currentUser?.id
      )
    )
    return userWorkingCompany?.isAdmin && currentCompany?.id === job.companyId
  }

  const renderItem: ListRenderItem<Recruitment> = ({
    item,
  }: {
    item: Recruitment
  }) => (
    <JobItem
      job={item}
      isPC={isPC}
      isAdminCompany={isAdminCompany(item)}
      company={currentCompany}
      onRemove={removeRecruitments}
      isJobDashboard={isJobDashboard}
    />
  )

  const keyExtractor = (item: Recruitment): string => item.id.toString()

  const fetchRecruitments = useAPI(
    async (api: API, page: number): Promise<void> => {
      setIsLoading(true)
      try {
        const params = {
          sortBy: queryParams.sortBy ?? RecruimentFilterKey.BY_RECENTLY,
          status: queryParams.status ?? queryParams.section,
          location: queryParams.location,
          q: queryParams.q,
          page,
        }
        const response =
          isCompanyMode && !isNil(currentCompany)
            ? await api.companyRecruitments
                .configPath(currentCompany.id)
                .index<Recruitment[], RecruimentQueryParams>(params)
            : await api.recruitments.index<
                Recruitment[],
                RecruimentQueryParams
              >({
                ...params,
                companyId: queryParams?.id,
              })
        if (page > currentPage) {
          if (!isEmpty(response)) {
            setRecruitments((recruitments) => [...recruitments, ...response])
            setCurrentPage(page)
            setParams(
              snakecaseKeys({
                ...queryParams,
                page,
              }) as never
            )
          }
        } else {
          setRecruitments(response)
        }
      } catch (err) {
        // NOTE: Ignore abort request notifies
        if (err instanceof DOMException) {
          return
        }
        toast.showError(err)
      }
      api.isAlive && setIsLoading(false)
    },
    [
      queryParams?.q,
      queryParams.location,
      queryParams.sortBy,
      queryParams?.section,
      queryParams?.status,
      currentCompany?.id,
      isCompanyMode,
      setIsLoading,
      setRecruitments,
    ]
  )

  const fetchMoreRecruiments = (): void => {
    !isLoading && fetchRecruitments(currentPage + 1)
  }

  const removeRecruitments = (recruitment: Recruitment): void => {
    setRecruitments(recruitments.filter((item) => item.id !== recruitment.id))
  }

  useFocusEffect(
    useCallback(() => {
      const page = queryParams?.page || defaultPage
      if (page > 1) {
        setParams(
          snakecaseKeys({
            ...queryParams,
            page,
          }) as never
        )
      }
      setCurrentPage(page)
      fetchRecruitments(page)
    }, [fetchRecruitments])
  )

  const getEmptyView = (): React.ReactElement => {
    let title = ''
    let subtitle = ''

    switch (queryParams.status) {
      case 'applied':
        title = t('jobs.emptyPages.applied.title')
        subtitle = t('jobs.emptyPages.applied.subtitle')
        break
      case 'saved':
        title = t('jobs.emptyPages.saved.title')
        subtitle = t('jobs.emptyPages.saved.subtitle')
        break
      default:
        title = t('jobs.emptyPages.jobs.title')
        subtitle = t('jobs.emptyPages.jobs.subtitle')
    }

    return (
      <EmptyView
        isPC={isPC}
        title={title}
        subtitle={subtitle}
        imageSource={emptyJobImage}
      />
    )
  }

  return (
    <View style={styles.container} testID="jobs-list">
      <InfiniteScrollFlatList
        data={recruitments}
        testID="jobs-list"
        extraData={isLoading}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        onEndReachedThreshold={0.01}
        ListHeaderComponent={header}
        style={styles.recruimentList}
        scrollEnabled={!isCompanyMode}
        onReachBottom={fetchMoreRecruiments}
        ListEmptyComponent={isLoading ? undefined : getEmptyView()}
      />
      {shouldShowSkeleton && (
        <View style={styles.skeletonContainer}>
          <SkeletonList
            count={2}
            numColumns={1}
            itemComponent={<SkeletonItemWithCover isPC={isPC} />}
            header={<View style={styles.skeletonHeader}>{header}</View>}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  recruimentList: {
    paddingBottom: 10,
    height: '100%',
  },
  skeletonContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  skeletonHeader: {
    marginBottom: 15,
  },
})

export default JobsList
