import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'
import ChatThread from '~/interfaces/ChatThread'

export type MessagesQueryParams = {
  id?: number
}

export type ChatThreadRequestBody = {
  chatThread: {
    userIds: number[]
  }
}

export type ChatThreadQueryParams = {
  q?: string
  type?: 'unread' | 'read'
  isCompany?: '0' | '1'
} & BaseQueryParams

export class ChatThreadsAPI extends BaseAPI {
  path = '/chat_threads'

  async markAsUnread(id: number): Promise<ChatThread> {
    return await this.client.patch(`${this.path}/${id}/unread`, {})
  }

  async markAsRead(id: number): Promise<ChatThread> {
    return await this.client.patch(`${this.path}/${id}/read`, {})
  }

  async getArchivedThreads(): Promise<ChatThread[]> {
    return await this.client.get(`${this.path}?is_archived=true`)
  }

  async archiveThread(id: number): Promise<ChatThread> {
    return await this.client.patch(`${this.path}/${id}`, {
      isArchived: true,
    })
  }
}
