import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { isEmpty, isEqual, isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import DocumentList from '~/components/career/features/documents/DocumentList'
import ResumesList from '~/components/career/features/resume/ResumesList'
import JobApplyConfirmation from '~/components/career/organisms/jobs/JobApplyConfirmation'
import JobApplyResultDialog from '~/components/career/organisms/jobs/JobApplyResultDialog'
import JobApplyResumeSelection from '~/components/career/organisms/jobs/JobApplyResumeSelection'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import LoginColumn from '~/components/common/features/auth/LoginColumn'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useDevice from '~/hooks/commons/useDevice'
import Recruitment from '~/interfaces/Recruitment'
import Resume from '~/interfaces/Resume'
import UserDocument from '~/interfaces/UserDocument'
import { RootState } from '~/rootReducer'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  job: Recruitment
  onApply: (applyStatus: boolean) => void
}

const JobApply: React.FC<Props> = ({ job, onApply }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isShowSignIn, setIsShowSignIn] = useState(false)
  const [selectedResume, setSelectedResume] = useState<Resume>()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<UserDocument>()
  const [shouldShowResultDialog, setShouldShowResultDialog] = useState(false)

  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const isApplied = job.isApplied
  const title = isApplied ? t('jobs.pages.applied') : t('apply')
  const mainColor = isApplied ? color.inactive : color.primary

  const showApplyJobResult = (): void => {
    onApply && onApply(true)
    setIsShowConfirmDialog(false)
    setShouldShowResultDialog(true)
  }

  const showSelectionDialog = (): void => {
    setIsConfirmed(false)
    setIsShowConfirmDialog(true)
    setSelectedResume(undefined)
    setSelectedDocument(undefined)
  }

  const showSignIn = (): void => {
    isPC ? setIsShowSignIn(true) : navigate('login')
  }

  const toggleSelectedResume = (resume: Resume) =>
    setSelectedResume(
      isNil(selectedResume) || !isEqual(resume, selectedResume)
        ? resume
        : undefined
    )

  const toggleSelectedDocument = (document: UserDocument) =>
    setSelectedDocument(
      isNil(selectedDocument) || !isEqual(document, selectedDocument)
        ? document
        : undefined
    )

  const selectionData = [
    {
      title: t('jobs.selectResumeTitle'),
      selectionView: (
        <ResumesList
          hideTitle
          mode="selection"
          onSelect={toggleSelectedResume}
          selectedResume={selectedResume}
        />
      ),
    },
    {
      title: `"${t('document.title')}"`,
      description: `(${t('resume.otherDocumentSubTitle')})`,
      selectionView: (
        <DocumentList
          hideTitle
          mode="selection"
          onSelect={toggleSelectedDocument}
          selectedDocument={selectedDocument}
        />
      ),
    },
  ]

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setIsConfirmed(false)
        setIsShowConfirmDialog(false)
        setSelectedResume(undefined)
        setSelectedDocument(undefined)
      }
    }, [])
  )
  return (
    <View testID="job-apply">
      <Button
        type="solid"
        title={title}
        onPress={(): void =>
          !isEmpty(accessToken) ? showSelectionDialog() : showSignIn()
        }
        disabled={isApplied}
        buttonStyle={[
          isPC ? styles.pcApplyButton : styles.mobileApplyButton,
          { backgroundColor: mainColor },
        ]}
        titleStyle={[commonStyles.buttonTextSize, { color: color.white }]}
      />
      <ModalContainer
        visible={isShowConfirmDialog}
        onDismiss={(): void => setIsShowConfirmDialog(false)}
      >
        <ScrollView
          testID="job-apply-modal"
          style={
            isPC
              ? isConfirmed
                ? styles.applyDialogContainer
                : StyleSheet.flatten([
                    styles.applyDialogContainer,
                    styles.applyDialogContainerHeight,
                  ])
              : StyleSheet.flatten([
                  styles.applyDialogContainer,
                  styles.dialogContainerMobile,
                ])
          }
        >
          <View
            testID="resume-selection-container"
            style={isConfirmed && styles.invisible}
          >
            <JobApplyResumeSelection
              targetJob={job}
              data={selectionData}
              onPressNext={() => setIsConfirmed(true)}
              onPressCancel={() => setIsShowConfirmDialog(false)}
            />
          </View>
          {isConfirmed && (
            <JobApplyConfirmation
              job={job}
              isPC={isPC ?? true}
              resume={selectedResume}
              onApply={showApplyJobResult}
              userDocument={selectedDocument}
              onPressBack={(): void => setIsConfirmed(false)}
            />
          )}
        </ScrollView>
      </ModalContainer>

      {shouldShowResultDialog && (
        <JobApplyResultDialog
          visible={shouldShowResultDialog}
          onPressDismiss={() => setShouldShowResultDialog(false)}
        />
      )}

      {/* TODO evade render warnings from LoginFacebookButton */}
      {isShowSignIn && (
        <ModalContainer
          visible={isShowSignIn}
          onDismiss={(): void => setIsShowSignIn(false)}
        >
          <LoginColumn />
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  applyDialogContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    flex: 1,
    maxWidth: 'fit-content',
    padding: 36,
  },
  applyDialogContainerHeight: {
    height: '95%',
  },
  dialogContainerMobile: {
    maxHeight: 450,
  },
  invisible: {
    display: 'none',
  },
  mobileApplyButton: {
    height: 33,
    width: 183,
  },
  pcApplyButton: {
    height: 40,
    width: 125,
  },
})

export default JobApply
