import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View, Image } from 'react-native'
import { Card } from 'react-native-elements'

import blackTickIcon from '~/assets/images/subscriptions/black-tick.png'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { getCurrentLanguage, SupportedLanguage } from '~/plugins/i18n'
import { currencyFormat } from '~/utils/common/string'

export type ButtonInformation = {
  title: string
  icon?: string
}

type Props = {
  isPC: boolean
  ratioWidth: number
  isSelected: boolean
  recommended?: string
  selectable?: boolean
  hasCurrentText?: boolean
  subscriptionPlan: SubscriptionPlan
  onSelect?: (plan: SubscriptionPlan) => void
}

const SubscriptionPlanCard = ({
  isPC,
  onSelect,
  ratioWidth,
  isSelected,
  selectable,
  recommended,
  hasCurrentText,
  subscriptionPlan,
}: Props) => {
  const { t } = useTranslation()
  const pcWrapper = {
    width: `${100 * ratioWidth}%`,
    maxWidth: '320px',
  }
  const selectedStyle = isSelected ? styles.selected : undefined

  const onPressPlanCard = () => {
    onSelect?.(subscriptionPlan)
  }

  const priceInfo =
    subscriptionPlan.price === 0
      ? t('pricingCard.free')
      : subscriptionPlan.period === 'month'
      ? t('pricingCard.pricePerMonth', {
          price: currencyFormat(subscriptionPlan.price),
        })
      : t('pricingCard.pricePerYear', {
          price: currencyFormat(subscriptionPlan.price),
        })

  return (
    <View testID="subscription-plan-card" style={isPC ? pcWrapper : null}>
      <Pressable
        disabled={!selectable}
        onPress={onPressPlanCard}
        testID="subscription-plan-pressable"
        style={styles.cardContainer}
      >
        <Card
          wrapperStyle={styles.wrapperContainer}
          containerStyle={[
            styles.container,
            selectable ? null : styles.currentPlanView,
            selectedStyle,
          ]}
        >
          <View style={styles.heightCard}>
            <View>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>
                  {getCurrentLanguage() === SupportedLanguage.JA
                    ? subscriptionPlan.name
                    : subscriptionPlan.enName}
                </Text>
                {!isEmpty(recommended) && (
                  <Text style={styles.recommended}>{recommended}</Text>
                )}
              </View>
              <Text style={styles.price}>{priceInfo}</Text>
              <View style={styles.horizontal} />
              <View>
                {subscriptionPlan.pjPlan.optionPlans.map((option, id) => {
                  return (
                    <Text key={id} style={styles.description}>
                      <Image
                        source={blackTickIcon}
                        resizeMode="cover"
                        style={styles.icon}
                      />
                      {getCurrentLanguage() === SupportedLanguage.JA
                        ? option.name
                        : option.enName}
                    </Text>
                  )
                })}
              </View>
            </View>
            <View>
              {hasCurrentText && (
                <Text style={styles.currentPlan} testID="pricing-current-plan">
                  {t('pricingCard.currentPlan')}
                </Text>
              )}
            </View>
          </View>
        </Card>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    height: '100%',
  },
  container: {
    borderColor: color.transparent,
    borderRadius: 10,
    borderWidth: 4,
    height: '100%',
    minHeight: 350,
    textAlign: 'left',
    width: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    position: 'relative',
  },
  currentPlan: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  currentPlanView: {
    backgroundColor: color.lightGray,
  },
  description: {
    display: 'flex',
    fontSize: FontSize.GENERAL,
    lineHeight: 22,
    marginVertical: 10,
    overflow: 'hidden',
  },
  heightCard: {
    height: '100%',
    justifyContent: 'space-between',
  },
  horizontal: {
    backgroundColor: color.gray,
    height: 1,
    marginVertical: 5,
  },
  icon: {
    height: 24,
    width: 24,
  },
  price: {
    fontSize: FontSize.REMARKABLE,
    marginVertical: 15,
  },
  recommended: {
    alignSelf: 'center',
    borderWidth: 1,
    borderRadius: 10,
    fontSize: 19,
    marginLeft: 10,
    padding: 5,
  },
  selected: {
    borderColor: color.primary,
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  titleContainer: {
    flexDirection: 'row',
  },
  wrapperContainer: {
    paddingHorizontal: 15,
    height: '100%',
  },
})

export default SubscriptionPlanCard
