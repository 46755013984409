import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import api from '~/api'
import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import ControlMenuItem from '~/components/workarounds/MenuItem'
import useCustomToast from '~/hooks/useCustomToast'
import Question from '~/interfaces/Question'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  question: Question
  onDelete?: (question: Question) => void
}

const QuestionControlMenu: React.FC<Props> = ({
  question,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const toast = useCustomToast()

  const acceptConfirmation = (): void => {
    deleteQuestion()
    setIsShowConfirmDialog(false)
  }

  const deleteQuestion = async (): Promise<void> => {
    try {
      await api.questions.delete(question.id)
      onDelete && onDelete(question)
    } catch (error) {
      toast.showError(error)
    }
  }

  const navigateToEditQuestion = (): void => {
    navigate('questionEdit', { id: question.id })
  }

  return (
    <View testID="question-control-menu">
      <ControlMenuContainer
        acceptLabel={t('delete')}
        onPressAccept={acceptConfirmation}
        isShowConfirmDialog={isShowConfirmDialog}
        confirmationMessage={t('forum.confirmDeleteQuestion')}
        onPressCancel={(): void => setIsShowConfirmDialog(false)}
      >
        <ControlMenuItem title={t('edit')} onSelect={navigateToEditQuestion} />
        <ControlMenuItem
          title={t('delete')}
          onSelect={(): void => setIsShowConfirmDialog(true)}
        />
      </ControlMenuContainer>
    </View>
  )
}

export default QuestionControlMenu
