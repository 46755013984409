import React, { useState } from 'react'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'

import Tag, { TagData } from '~/components/common/atoms/Tag'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
  tags: TagData[]
  numColumns?: number
  onItemSelected?: (tag: TagData, isSelected: boolean) => void
}

const TagList: React.FC<Props> = (props: Props) => {
  const { isPC, tags, onItemSelected, numColumns } = props
  const [selectedChanged, setSelectedChanged] = useState(false)
  const selectItem = (item: TagData, isSelected: boolean): void => {
    onItemSelected && onItemSelected(item, isSelected)
    setSelectedChanged(!selectedChanged)
  }
  const renderItem: ListRenderItem<TagData> = ({ item }: { item: TagData }) => (
    <View style={styles.tagContainer}>
      <Tag
        data={item}
        onSelected={(isSelected: boolean): void => selectItem(item, isSelected)}
      />
    </View>
  )
  const keyExtractor = (item: TagData): string => item.key

  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View testID="tag-list" style={containerStyle}>
      <FlatList
        extraData={selectedChanged}
        data={tags}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        numColumns={numColumns ?? 2}
        testID="tag-data-list"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    flexWrap: 'wrap',
  },
  containerMobile: {
    backgroundColor: color.primary,
  },
  tagContainer: {
    marginHorizontal: 4,
    marginTop: 4,
    width: 120,
  },
})

export default TagList
