import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

type Props = {
  imagePath?: string
  controlMenu?: React.ReactElement
}

export enum ProfileBannerAction {
  ADD = 'add_banner',
  CHANGE = 'change_banner',
  REMOVE = 'remove_banner',
  EDIT_PROFILE = 'edit_profile_header',
  LEAVE = 'leave_community',
}

const ProfileBanner: React.FC<Props> = ({ controlMenu, imagePath }: Props) => (
  <View style={styles.container} testID="profile-banner">
    <ImageBackground
      source={
        imagePath
          ? {
              uri: imagePath,
            }
          : require('~/assets/images/banner-background.png')
      }
      style={styles.backgroundImage}
      testID="profile-banner-background"
    >
      <View style={styles.controlMenu} testID="control-menu-container">
        {controlMenu}
      </View>
    </ImageBackground>
  </View>
)

const styles = StyleSheet.create({
  backgroundImage: {
    alignItems: 'flex-end',
    height: 65,
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    flex: 1,
    height: 65,
  },
  controlMenu: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
})

export default ProfileBanner
