import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ListRenderItem, FlatList } from 'react-native'

import { API } from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import Attendee from '~/components/community/molecules/EventAttendee'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityEvent from '~/interfaces/CommunityEvent'
import CommunityEventAttendee from '~/interfaces/CommunityEventAttendee'

type Props = {
  communityEvent: CommunityEvent
}

const EventAttendeeList: React.FC<Props> = ({ communityEvent }: Props) => {
  const defaultPage = 1
  const { t } = useTranslation()
  const [attendees, setAttendees] = useState<CommunityEventAttendee[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const toast = useCustomToast()

  const keyExtractor = (item: CommunityEventAttendee): string =>
    item.id.toString()

  const renderItem: ListRenderItem<CommunityEventAttendee> = ({
    item,
  }: {
    item: CommunityEventAttendee
    index: number
  }): JSX.Element => <Attendee user={item.user!} />

  const fetchAttendees = useAPI(
    async (api: API, page: number): Promise<void> => {
      if (!communityEvent.community || isLoading) {
        return
      }

      setIsLoading(true)
      try {
        const response = await api.communityEventAttendees
          .configPath(communityEvent.community.id, communityEvent.id)
          .index<CommunityEventAttendee[], BaseQueryParams>({ page })
        if (!isEmpty(response)) {
          if (page > currentPage) {
            setCurrentPage(page)
            setAttendees((attendees) => [...attendees, ...response])
          } else {
            setAttendees(response)
          }
        }
      } catch (error) {
        toast.showError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [communityEvent.id, setIsLoading, currentPage, setCurrentPage]
  )

  const fetchMore = async (): Promise<void> => fetchAttendees(currentPage + 1)

  useFocusEffect(
    React.useCallback(() => {
      fetchAttendees(defaultPage)
    }, [fetchAttendees])
  )

  return (
    <View testID="event-attendee-list" style={styles.container}>
      <FlatList
        data={attendees}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListFooterComponent={
          !isEmpty(attendees) ? (
            <Text onPress={fetchMore} style={styles.seeMore} testID="see-more">
              {t('seeMore')}
            </Text>
          ) : undefined
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    width: '100%',
  },
  seeMore: {
    color: color.pressableText,
    fontSize: FontSize.GENERAL,
  },
})
export default EventAttendeeList
