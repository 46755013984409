import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import SelectableList, {
  ID,
  SelectableListData,
} from '~/components/common/features/SelectableList'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

export enum DashboardMenuType {
  RECRUITMENT,
  APPLICANT,
}

type Props = {
  selectedMenu: DashboardMenuType
  onSelect?: (menuType: DashboardMenuType) => void
  isPC?: boolean
}

const DashboardMenu: React.FC<Props> = ({
  onSelect,
  selectedMenu,
  isPC,
}: Props) => {
  const { t } = useTranslation()

  const menuData: SelectableListData[] = [
    {
      title: '',
      data: [
        {
          id: DashboardMenuType.RECRUITMENT,
          name: t('company.dashboard.recruitmentPostMenu'),
        },
        {
          id: DashboardMenuType.APPLICANT,
          name: t('company.dashboard.applicantsDataMenu'),
        },
      ],
    },
  ]
  const options: ControlMenuData[] = [
    {
      id: DashboardMenuType.RECRUITMENT,
      title: t('company.dashboard.recruitmentPostMenu'),
    },
    {
      id: DashboardMenuType.APPLICANT,
      title: t('company.dashboard.applicantsDataMenu'),
    },
  ]
  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View style={containerStyle} testID="dashboard-menu">
      {isPC ? (
        <View style={styles.body}>
          <SelectableList
            data={menuData}
            shouldHideHeader
            selectedId={selectedMenu}
            onSelect={(id: ID) => onSelect?.(id as DashboardMenuType)}
          />
        </View>
      ) : (
        <>
          <View style={styles.bodyMobile}>
            <ControlMenu
              data={options}
              menuTrigger={
                <View style={styles.filterMenu}>
                  <Text style={styles.filterMenuText}>
                    {options.find((item) => item.id === selectedMenu)?.title}
                  </Text>
                  <MaterialIcons
                    color={color.white}
                    name="arrow-drop-down"
                    size={15}
                  />
                </View>
              }
              onSelect={(item) => onSelect?.(item.id as DashboardMenuType)}
              preferredPlacement={'bottom'}
            />
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.white,
    overflow: 'hidden',
    width: 200,
    alignSelf: 'baseline',
    ...commonStyles.shadow,
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
  },
  bodyMobile: {
    borderColor: color.primary,
    borderRadius: 20,
    borderWidth: 1,
    paddingHorizontal: 8,
  },
  container: {},
  containerMobile: {
    alignItems: 'center',
    backgroundColor: color.primary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 14,
  },
  filterMenu: {
    ...commonStyles.borderRadiusAllCorner,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: color.lightGray,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  filterMenuText: {
    color: color.white,
  },
})

export default DashboardMenu
