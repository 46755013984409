import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useSelector } from 'react-redux'

import questionCategories from '~/assets/data/questionCategories.json'
import FloatButton from '~/components/common/atoms/FloatButton'
import CategoriesFilter from '~/components/common/features/CategoriesFilter'
import OutsiderGuide from '~/components/common/features/auth/OutsiderGuide'
import { CheckBoxListData } from '~/components/common/organisms/CheckBoxList'
import QuestionList from '~/components/forum/features/questions/QuestionList'
import ForumFrame from '~/components/forum/templates/ForumFrame'
import useDevice from '~/hooks/commons/useDevice'
import i18n from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { RootStackNavigationProp } from '~/utils/navigation'

const getCheckBoxListData = (): CheckBoxListData[] =>
  questionCategories.map(
    (item) =>
      ({
        value: item.name,
        title: i18n.language === 'ja' ? item.title : item.enTitle,
      } as CheckBoxListData)
  )

const ForumTemplate: React.FC = () => {
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const { isPC } = useDevice()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const categoryList = getCheckBoxListData()

  return isPC ? (
    <>
      <ForumFrame
        leftColumn={<CategoriesFilter categoryListData={categoryList} />}
      >
        <QuestionList />
      </ForumFrame>
      {!accessToken && <OutsiderGuide />}
    </>
  ) : (
    <>
      <QuestionList />
      {<FloatButton onPress={(): void => navigate('questionNew')} />}
      {!accessToken && <OutsiderGuide />}
    </>
  )
}

export default ForumTemplate
