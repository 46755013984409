import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ActivityIndicator } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Entry from '~/interfaces/Entry'
import UserDocument from '~/interfaces/UserDocument'
import { openURL } from '~/utils/navigation'

type Props = {
  entry: Entry
}

const ApplicantDocument: React.FC<Props> = ({ entry }) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isLoadingDocument, setIsLoading] = useState(false)

  const fetchDocument = useAPI(async (api) => {
    if (isLoadingDocument || isNil(entry?.userDocument?.id)) return
    setIsLoading(true)
    try {
      const response = await api.userDocuments.show<UserDocument>(
        entry.userDocument!.id
      )
      openURL(response.file.url)
    } catch (e) {
      toast.showError(e)
    }
    api.isAlive && setIsLoading(false)
  })

  const openDocument = () => {
    if (!isNil(entry?.userDocument?.file?.url)) {
      openURL(entry.userDocument!.file.url)
    } else {
      fetchDocument()
    }
  }

  return (
    <View testID="applicant-document" style={styles.container}>
      <Text
        style={styles.title}
        onPress={openDocument}
        testID="applicant-document-text"
      >
        {t('resume.title.document')}
      </Text>
      {isLoadingDocument && <ActivityIndicator />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  title: {
    color: color.pressableText,
    fontWeight: '600',
    marginRight: 4,
    textDecorationLine: 'underline',
  },
})

export default ApplicantDocument
