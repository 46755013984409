import * as Factory from 'factory.ts'

import Education from '~/interfaces/Education'

export const educationFactory = Factory.makeFactory<Education>({
  id: Factory.each((i) => i),
  title: 'Master in Media Design',
  school: 'Nanyang Technological University',
  term: 'Aug 2010 - May 2014',
  image: '',
  userResumeId: 2,
  schoolName: 'School name',
  degree: 'Degree',
  major: 'Major',
  enrollmentYear: '2004-10',
  graduationYear: '2008-10',
  description: 'description',
  isStudyingHere: true,
})
