import { isEmpty } from 'lodash'

import type { HTTPClient } from '~/httpClient'
import Answer from '~/interfaces/Answer'

export type UserAnswerQueryParams = {
  q?: string
  page?: number
  userId?: number
  categories?: string[]
  sortBy?: string
}

export class UserAnswersAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async index({
    page,
    userId,
    categories,
    q,
    sortBy,
  }: UserAnswerQueryParams): Promise<Answer[]> {
    let path = `/users/${userId}/user_answers`
    const params = []
    if (page) params.push(`page=${page}`)
    if (q) params.push(`q=${q}`)
    if (sortBy) params.push(`sort_by=${sortBy}`)
    if (!isEmpty(categories)) {
      categories?.forEach((item) => params.push(`categories[]=${item}`))
    }
    if (params.length > 0) path += '?' + params.join('&')
    return await this.client.get<Answer[]>(path)
  }
}
