import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import Toast from 'react-native-toast-notifications'

import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import Color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Community from '~/interfaces/Community'

type Props = {
  dismissDialog?: () => void
  isVisible: boolean
  community: Community
  onCancel?: (communityId: number) => void
}

const CommunityCancelDialog: React.FC<Props> = ({
  dismissDialog,
  isVisible,
  community,
  onCancel,
}) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const toastRef = useRef(null)

  const handleDismissDialog = () => {
    dismissDialog?.()
  }

  const submitDeleteCommunity = useAPI(
    async (api): Promise<void> => {
      try {
        await api.communities.delete(community.id)
        onCancel?.(community.id)
      } catch (error) {
        toast.showError(error, toastRef)
      } finally {
        if (api.isAlive) {
          dismissDialog?.()
        }
      }
    }
  )

  return (
    <ModalContainer visible={isVisible} onDismiss={handleDismissDialog}>
      <ScrollView style={styles.container}>
        <View testID="community-cancel-dialog" style={styles.viewMainDialog}>
          <View style={styles.content}>
            <TitleText style={styles.marginBottom}>
              {t('community.areYouSureYouWouldWantToCancel')}
            </TitleText>
          </View>
          <View style={styles.footer}>
            <Button
              onPress={handleDismissDialog}
              title={t('community.noDoNotCancel')}
              containerStyle={styles.marginBottom}
              testID="community-no-change-button"
              titleStyle={commonStyles.buttonTextSize}
            />
          </View>
          <View style={styles.proceedCancelButton}>
            <Button
              onPress={submitDeleteCommunity}
              title={t('community.proceedWithCancellation')}
              buttonStyle={styles.backBtn}
              testID="community-proceed-cancel-button"
              titleStyle={[commonStyles.buttonTextSize, styles.cancelBtnText]}
            />
          </View>
        </View>
      </ScrollView>
      <Toast ref={toastRef} placement="bottom" />
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  backBtn: {
    backgroundColor: Color.transparent,
    borderColor: Color.primary,
    borderWidth: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  cancelBtnText: {
    color: Color.textGray,
  },
  container: {
    flex: 1,
    backgroundColor: color.white,
    maxWidth: 400,
    padding: 40,
    maxHeight: '90%',
    ...commonStyles.borderRadiusAllCorner,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
  },
  marginBottom: {
    marginBottom: 10,
  },
  proceedCancelButton: {
    borderBottomColor: Color.textGray,
    borderBottomWidth: 1,
  },
  viewMainDialog: {
    alignItems: 'center',
  },
})

export default CommunityCancelDialog
