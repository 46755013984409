import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'

type Props = {
  url: string
  title?: string
  description?: string
}

const URLPreviewInfo: React.FC<Props> = ({
  url,
  title,
  description,
}: Props) => {
  const texts = [
    <Text key="url" testID="url-preview-url" numberOfLines={1}>
      {url}
    </Text>,
  ]
  if (title != null) {
    texts.push(
      <Text
        key="title"
        testID="url-preview-title"
        numberOfLines={1}
        style={styles.title}
      >
        {title}
      </Text>
    )
  }
  if (description != null) {
    texts.push(
      <Text
        key="description"
        testID="url-preview-description"
        numberOfLines={1}
        style={styles.description}
      >
        {description}
      </Text>
    )
  }
  return <View style={styles.info}>{texts}</View>
}

const styles = StyleSheet.create({
  description: {
    marginTop: 10,
  },
  info: {
    padding: 10,
  },
  title: {
    marginTop: 10,
  },
})

export default URLPreviewInfo
