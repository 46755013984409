import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { UserSection } from '~/constants/common/navigation'
import User from '~/interfaces/User'

type Props = {
  user?: User
}

const MyQuizButton: React.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation()

  return (
    <Link
      to={{
        screen: 'userDetail',
        params: { id: user!.id, section: UserSection.QUIZ },
      }}
      testID="my-quiz-button"
      style={styles.container}
    >
      <Text style={styles.explanationText}>{t('quiz.goToMyQuiz')}</Text>
    </Link>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    backgroundColor: color.white,
    marginBottom: 15,
    marginLeft: 20,
    paddingHorizontal: 20,
    paddingVertical: 10,
    ...commonStyles.borderRadiusAllCorner,
  },
  explanationText: {
    color: color.primary,
    fontSize: FontSize.GENERAL,
    fontWeight: '500',
  },
})
export default MyQuizButton
