import React from 'react'
import { View, StyleSheet } from 'react-native'

type Props = {
  mediaPicker?: React.ReactNode
}

const CommentEditorInputAction: React.FC<Props> = ({ mediaPicker }: Props) => {
  return <View style={styles.container}>{mediaPicker}</View>
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})

export default CommentEditorInputAction
