import moment from 'moment'
import React from 'react'
import { View, StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import i18n from '~/plugins/i18n'

type Props = {
  date: string
  fontSize: FontSize
}

const DateText: React.FC<Props> = ({ date, fontSize }: Props) => {
  const dateString = moment(date)
    .locale(i18n.language)
    .calendar({ sameElse: 'MMMM DD YYYY' })

  return (
    <View>
      <Text testID="date-text" style={[styles.date, { fontSize }]}>
        {dateString}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  date: {
    color: color.textGray,
  },
})

export default DateText
