import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import AddressTextField from '~/components/career/molecules/resume/AddressTextField'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import MobileTextField from '~/components/career/molecules/resume/MobileTextField'
import TextField from '~/components/common/molecules/TextField'
import Resume from '~/interfaces/Resume'

type Props = {
  contactData: Partial<Resume>
  onChange: (value: Partial<Resume>) => void
  isPC: boolean
}

const ContactForm: React.FC<Props> = ({
  contactData,
  isPC,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  return (
    <FormLayout title={t('profile.contact')} hasAddAction={false} isPC={isPC}>
      <View style={styles.textFieldContainer}>
        <MobileTextField
          addressNumber={contactData.phone ?? ''}
          onChange={(phone): void => onChange({ phone })}
        />
        <TextField
          value={contactData.email}
          title={`${t('profile.email')}*`}
          onChangeText={(email): void => onChange({ email })}
        />
        <AddressTextField
          isPC={isPC}
          address={contactData.address ?? ''}
          onChangeAddress={(address): void => onChange({ address })}
        />
        <TextField
          value={contactData.webSite ?? ''}
          title={t('profile.website')}
          onChangeText={(webSite): void => onChange({ webSite })}
        />
      </View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  textFieldContainer: {
    paddingBottom: 40,
    paddingTop: 10,
    flex: 1,
  },
})

export default ContactForm
