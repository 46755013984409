import { find } from 'lodash'

import api from '~/api'
import ChatNotification from '~/interfaces/ChatNotification'
import ChatThread from '~/interfaces/ChatThread'
import { updateItem } from '~/utils/common/array'

export const markAsRead = (chatThreadId: number): void => {
  try {
    api.chatThreads.markAsRead(chatThreadId)
  } catch (error) {
    console.log(error) //Just log error is enough.
  }
}

export const markAsUnread = (chatThreadId: number): void => {
  try {
    api.chatThreads.markAsUnread(chatThreadId)
  } catch (error) {
    console.log(error) //Just log error is enough.
  }
}

export const updateThreadsFromNotification = (
  chatThreads: ChatThread[],
  chatNotification: ChatNotification
): ChatThread[] => {
  return find(chatThreads, { id: chatNotification.threadId })
    ? updateItem(chatThreads, {
        ...chatNotification.thread,
        latestMessage: chatNotification.body,
      })
    : [
        { ...chatNotification.thread, latestMessage: chatNotification.body },
        ...chatThreads,
      ]
}
