import React from 'react'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isLoading?: boolean
  disabled?: boolean
  onPress?: () => void
  title?: string
  isNoBorderRadius?: boolean
}

const OutlineButton: React.FC<Props> = ({
  isLoading,
  disabled,
  onPress,
  title,
  isNoBorderRadius,
}: Props) => {
  const borderRadiusStyle = isNoBorderRadius ? styles.noBorderRadius : undefined

  return (
    <Button
      type="outline"
      testID="outline-button"
      loading={isLoading}
      disabled={disabled}
      loadingProps={{ color: color.white, size: FontSize.SUB }}
      title={title}
      titleStyle={styles.title}
      buttonStyle={[styles.button, borderRadiusStyle]}
      containerStyle={[styles.container, borderRadiusStyle]}
      onPress={onPress}
    />
  )
}

const styles = {
  noBorderRadius: {
    borderRadius: 0,
  },
  container: {
    flex: 1,
  },
  button: {
    borderColor: color.white,
    borderWidth: 1,
  },
  title: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
}

export default OutlineButton
