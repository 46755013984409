import { isEmpty } from 'lodash'
import get from 'lodash/get'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { Divider } from 'react-native-elements'
import Toast from 'react-native-toast-notifications'

import { CommunityFormData } from '~/api/communities/communities'
import communityCloud from '~/assets/images/communities/community-cloud.png'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import CustomPicker from '~/components/common/molecules/CustomPicker'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import Color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Community, { CommunityCategory } from '~/interfaces/Community'

type Props = {
  dismissDialog?: () => void
  onSubmit?: () => void
  isVisible: boolean
}

const CommunityFormDialog: React.FC<Props> = ({
  dismissDialog,
  onSubmit,
  isVisible,
}) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const toastRef = useRef(null)
  const communityFieldsMaxLength = {
    name: 80,
    description: 150,
    purpose: 150,
  }
  const [communityFormData, setCommunityFormData] = useState<
    Partial<CommunityFormData>
  >()
  const [isLoading, setIsLoading] = useState(false)

  const communityCategories = Object.values(CommunityCategory).map((item) => ({
    label: t(item ? `community.type.${item}` : 'none'),
    value: item,
  }))

  const updateCommunityFormData = (data: Partial<CommunityFormData>) => {
    setCommunityFormData((prevState) =>
      prevState ? { ...prevState, ...data } : data
    )
  }

  const submitCommunity = useAPI(
    async (api): Promise<void> => {
      if (isLoading || !communityFormData) return
      try {
        await api.communities.create<Partial<CommunityFormData>, Community>(
          communityFormData
        )
        setCommunityFormData(undefined)
        dismissDialog?.()
        onSubmit?.()
      } catch (error) {
        toast.showError(error, toastRef)
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [isLoading, communityFormData]
  )

  const isValid =
    !isEmpty(communityFormData?.category) &&
    !isEmpty(communityFormData?.name?.trim()) &&
    !isEmpty(communityFormData?.description?.trim()) &&
    !isEmpty(communityFormData?.purpose?.trim()) &&
    get(communityFormData, 'name.length', 0) <= communityFieldsMaxLength.name &&
    get(communityFormData, 'description.length', 0) <=
      communityFieldsMaxLength.description &&
    get(communityFormData, 'purpose.length', 0) <=
      communityFieldsMaxLength.purpose

  const handleDismissDialog = () => {
    setCommunityFormData(undefined)
    dismissDialog?.()
  }

  return (
    <ModalContainer visible={isVisible} onDismiss={handleDismissDialog}>
      <ScrollView style={styles.container}>
        <View testID="community-form-main-dialog">
          <View style={styles.content}>
            <Image source={communityCloud} style={styles.communityCloud} />
            <TitleText style={styles.marginBottom}>
              {t('community.createCommunityTitle')}
            </TitleText>
            <Text>{t('community.beforeCreating')}</Text>
            <View style={styles.form}>
              <View style={styles.marginBottom} testID="community-type-picker">
                <Text style={[styles.marginBottom, styles.textGray]}>
                  {t('community.typeOfCommunity')}
                </Text>
                <CustomPicker
                  data={communityCategories}
                  size="large"
                  selectedValue={communityFormData?.category}
                  onValueChange={(itemVal) =>
                    updateCommunityFormData({
                      category: itemVal as CommunityCategory,
                    })
                  }
                />
              </View>
              <TextField
                value={communityFormData?.name}
                onChangeText={(name) => updateCommunityFormData({ name })}
                maxLength={communityFieldsMaxLength.name}
                title={t('name')}
                placeholder={t('community.name')}
                shouldShowCounter
              />
              <TextField
                value={communityFormData?.description}
                onChangeText={(description) =>
                  updateCommunityFormData({ description })
                }
                maxLength={communityFieldsMaxLength.description}
                title={t('community.description')}
                placeholder={t('community.descriptionPlaceholder')}
                numberOfLines={4}
                shouldShowCounter
              />
              <Divider style={styles.marginBottom} />
              <TextField
                value={communityFormData?.purpose}
                onChangeText={(purpose) => updateCommunityFormData({ purpose })}
                maxLength={communityFieldsMaxLength.purpose}
                title={t('community.purpose')}
                placeholder={t('community.purposePlaceholder')}
                numberOfLines={3}
                shouldShowCounter
              />
            </View>
          </View>
          <View style={styles.footer}>
            <Button
              onPress={submitCommunity}
              title={t('community.requestCreation')}
              containerStyle={styles.marginBottom}
              testID="community-submit-button"
              titleStyle={commonStyles.buttonTextSize}
              disabled={isLoading || !isValid}
            />
            <Button
              onPress={handleDismissDialog}
              title={t('cancel')}
              buttonStyle={styles.backBtn}
              testID="community-cancel-button"
              titleStyle={[commonStyles.buttonTextSize, styles.cancelBtnText]}
            />
          </View>
        </View>
      </ScrollView>
      <Toast ref={toastRef} placement="bottom" />
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  backBtn: {
    backgroundColor: Color.white,
    borderColor: Color.primary,
    borderWidth: 1,
  },
  cancelBtnText: {
    color: Color.primary,
  },
  communityCloud: {
    height: 126,
    resizeMode: 'cover',
    width: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: color.white,
    maxWidth: 700,
    padding: 40,
    maxHeight: '90%',
    ...commonStyles.borderRadiusAllCorner,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginVertical: 20,
  },
  marginBottom: {
    marginBottom: 10,
  },
  textGray: {
    color: Color.textGray,
  },
})

export default CommunityFormDialog
