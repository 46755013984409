import camelcaseKeys from 'camelcase-keys'
import { eq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import snakecaseKeys from 'snakecase-keys'

import { QuizResultsQueryParams } from '~/api/quizResults'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import color from '~/constants/common/color'
import { isReadyNavigationRef, navigationRef } from '~/utils/navigation'

export enum QuizResultIndexMenu {
  MOST_RECENT = 0,
  CORRECT = 1,
  INCORRECT = 2,
}

const QuizResultFilterPopup: React.FC = () => {
  const { t } = useTranslation()
  const routeParams = navigationRef.current?.getCurrentRoute()?.params
  const quizResultParams = camelcaseKeys(
    routeParams || {}
  ) as QuizResultsQueryParams
  const [selectedFilter, setSelectedFilter] = useState<
    FilterPopupData | undefined
  >(undefined)

  const quizFilterPopupData: FilterPopupData[] = [
    { id: QuizResultIndexMenu.MOST_RECENT, name: t('mostRecent') },
    { id: QuizResultIndexMenu.CORRECT, name: t('quiz.correct') },
    { id: QuizResultIndexMenu.INCORRECT, name: t('quiz.incorrect') },
  ]

  const getFilterDataByParams = (): FilterPopupData => {
    if (
      eq(quizResultParams.isCorrect, true) ||
      eq(quizResultParams.isCorrect, 'true')
    ) {
      return quizFilterPopupData[QuizResultIndexMenu.CORRECT]
    } else if (
      eq(quizResultParams.isCorrect, false) ||
      eq(quizResultParams.isCorrect, 'false')
    ) {
      return quizFilterPopupData[QuizResultIndexMenu.INCORRECT]
    } else {
      return quizFilterPopupData[QuizResultIndexMenu.MOST_RECENT]
    }
  }

  const setRouteParamsByFilterData = (item: FilterPopupData): void => {
    switch (item.id) {
      case QuizResultIndexMenu.MOST_RECENT:
        navigationRef.current?.setParams(
          snakecaseKeys({ ...quizResultParams, isCorrect: undefined })
        )
        break
      case QuizResultIndexMenu.CORRECT:
        navigationRef.current?.setParams(
          snakecaseKeys({ ...quizResultParams, isCorrect: true })
        )
        break
      case QuizResultIndexMenu.INCORRECT:
        navigationRef.current?.setParams(
          snakecaseKeys({ ...quizResultParams, isCorrect: false })
        )
        break
    }
  }

  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    setRouteParamsByFilterData(item)
  }

  useEffect(() => {
    isReadyNavigationRef.current &&
      changeSelectedFilter(getFilterDataByParams())
  }, [isReadyNavigationRef.current])

  return (
    <View testID="quiz-result-filter-popup">
      <FilterPopupMenu
        data={quizFilterPopupData}
        selectedItem={selectedFilter}
        onSelect={changeSelectedFilter}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {`${t('sortBy')}: ${selectedFilter?.name}`}
          </Text>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  selectedFilter: {
    color: color.pressableText,
  },
})

export default QuizResultFilterPopup
