import { Dimensions } from 'react-native'

const breakPoint = 920

export const isPC = (): boolean => {
  return Dimensions.get('window').width > breakPoint
}

export const isMobile = (): boolean => {
  return Dimensions.get('window').width <= breakPoint
}
