import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native'

import imageSource from '~/assets/images/admin.png'
import CrossIcon from '~/components/common/atoms/CrossIcon'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  onPressDismiss: () => void
  isVisible: boolean
}

const CommunityResultDialog: React.FC<Props> = ({
  onPressDismiss,
  isVisible,
}) => {
  const { t } = useTranslation()

  return (
    <ModalContainer visible={isVisible} onDismiss={onPressDismiss}>
      <View testID="community-result-dialog" style={styles.modalContainer}>
        <View style={styles.container}>
          <TouchableOpacity style={styles.closeButton}>
            <CrossIcon onPress={onPressDismiss} />
          </TouchableOpacity>
          <View style={styles.imageContainer}>
            <Image source={imageSource} style={styles.image} />
          </View>
          <TitleText style={styles.margin}>
            {t('community.createSuccessCommunityTitle')}
          </TitleText>
          <Text>{t('community.createSuccessCommunityMessage')}</Text>
        </View>
      </View>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
  },
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    flex: 1,
    maxWidth: 470,
    padding: 36,
    textAlign: 'center',
  },
  image: {
    flex: 1,
    height: 244,
    maxWidth: 180,
  },
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  margin: {
    marginBottom: 10,
    marginTop: 20,
  },
  modalContainer: {
    alignItems: 'center',
    padding: 36,
    width: '100%',
  },
})

export default CommunityResultDialog
