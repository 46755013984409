import { useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import CommunityDetailTemplate from '~/components/common/templates/CommunityDetailTemplate'
import { fetchCommunityDetail, reset } from '~/slices/community/community'
import { RouteParamsID } from '~/utils/navigation'

const CommunityDetail: React.FC = () => {
  const route = useRoute()
  const dispatch = useDispatch()
  const communityId = (route?.params as RouteParamsID)?.id

  useEffect(() => {
    /*
    All community slice serve community detail.
    So reseting community slice here is necessary.
    And the community is always loaded with new ones here.
    */
    Promise.all([dispatch(reset())]).finally(() => {
      communityId && dispatch(fetchCommunityDetail(communityId))
    })
  }, [communityId])

  return (
    <DefaultLayout>
      <CommunityDetailTemplate />
    </DefaultLayout>
  )
}

export default CommunityDetail
