import ChatThread from '~/interfaces/ChatThread'

export enum MessageControlMenuType {
  RENAME = 'rename',
  CHANGE_IMAGE = 'change-image',
  MARK_AS_READ = 'mark-as-read',
  MARK_AS_UNREAD = 'mark-as-unread',
  DELETE = 'delete',
  ARCHIVE = 'archive',
}
export type MessageItemControlMenuEvent = {
  type: MessageControlMenuType
  data?: ChatThread
}
