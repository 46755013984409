import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { format } from 'timeago.js'

import ResumeTitle from '~/components/career/atoms/resume/ResumeTitle'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import Resume from '~/interfaces/Resume'
import i18n from '~/plugins/i18n'

type Props = {
  resume: Resume
}

const ResumeItemContent: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  return (
    <View style={styles.description} testID="resume-item-content">
      <ResumeTitle size={isPC ? 'medium' : 'small'} resume={resume} />
      <Text style={styles.subject} ellipsizeMode="tail" numberOfLines={1}>
        {resume.headline}
      </Text>
      <Text style={styles.postedTime}>
        {t('community.information.created', {
          time: format(new Date(resume.createdAt), i18n.language),
        })}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    flex: 1,
    padding: 20,
  },
  postedTime: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    padding: 2,
  },
  subject: {
    fontSize: FontSize.GENERAL,
    fontWeight: '300',
    padding: 2,
  },
})

export default ResumeItemContent
