import { TFunction } from 'i18next'
import { findIndex, first, get, isEqual, isNil, last } from 'lodash'

import { ControlMenuData } from '~/components/common/molecules/ControlMenu'
import Company from '~/interfaces/Company'
import { DataByMonth } from '~/interfaces/CompanyRecruitmentStatistic'
import User from '~/interfaces/User'
import { SupportedLanguage } from '~/plugins/i18n'

export enum CompanyMemberMenuType {
  SET_AS_ADMIN,
  SET_AS_NORMAL_HR,
  REMOVE,
}

export enum JobRecruitmentMenu {
  EDIT_POST,
  DUPLICATE_POST,
  ENDED_JOB,
}

export const getCompanyNameByLang = (
  company: Company,
  language: SupportedLanguage
): string => {
  const name =
    language === SupportedLanguage.JA
      ? get(company, 'name')
      : get(company, 'enName')
  return isNil(name) ? '' : name
}

export const getMemberMenuData = ({
  t,
  isAdmin,
}: {
  t: TFunction
  isAdmin: boolean
}): ControlMenuData[] =>
  isAdmin
    ? [
        {
          id: CompanyMemberMenuType.SET_AS_NORMAL_HR,
          title: t('company.setAsNormalHR'),
        },
        {
          id: CompanyMemberMenuType.REMOVE,
          title: t('remove'),
        },
      ]
    : [
        {
          id: CompanyMemberMenuType.SET_AS_ADMIN,
          title: t('company.setMemberAsAdmin'),
        },
        {
          id: CompanyMemberMenuType.REMOVE,
          title: t('remove'),
        },
      ]

export const parseChartDataByMonth = (
  data: DataByMonth[],
  translate: TFunction
): DataByMonth[] => {
  const monthsInYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const parseData = data.map((dataItem) => {
    const itemDate = new Date(first(dataItem)!.toString())
    const monthIndex = itemDate.getUTCMonth() + 1
    return [monthIndex, last(dataItem)]
  })
  return monthsInYear.map((monthInYear) => {
    const monthLabel = translate(`monthName.${monthInYear}`)
    const indexOfItem = findIndex(parseData, (item) =>
      isEqual(first(item), monthInYear)
    )
    if (indexOfItem >= 0) {
      return [monthLabel, last(parseData[indexOfItem])]
    } else {
      return [monthLabel, 0]
    }
  }) as DataByMonth[]
}

export const isCompanyAdmin = (currentUser: User, company: Company): boolean =>
  !isNil(
    company.userWorkingCompanies.find(
      (userWorkingCompany) =>
        userWorkingCompany.isAdmin &&
        userWorkingCompany.user?.id === currentUser.id
    )
  )
