import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'

import QuizQuestion from '~/components/career/atoms/quiz/QuizQuestion'
import DailyQuizItemHeader from '~/components/career/molecules/quizzes/DailyQuizItemHeader'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Quiz from '~/interfaces/Quiz'
import QuizResult from '~/interfaces/QuizResult'

type Props = {
  quiz: Quiz
  isPC: boolean
  quizResult?: QuizResult
  dailyQuizAnswerList?: React.ReactElement
}

const DailyQuizItem: React.FC<Props> = ({
  quiz,
  isPC,
  quizResult,
  dailyQuizAnswerList,
}: Props) => (
  <View
    testID="daily-quiz-item"
    style={isPC ? styles.itemContainer : styles.itemContainerMobile}
  >
    <DailyQuizItemHeader quiz={quiz} quizResult={quizResult} />
    <QuizQuestion question={quiz.question} size={FontSize.GENERAL} />
    {dailyQuizAnswerList}
  </View>
)

export default DailyQuizItem

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: color.white,
    width: 320,
  },
  itemContainerMobile: {
    backgroundColor: color.white,
    width: Dimensions.get('window').width,
  },
})
