import { Consumer } from '@rails/actioncable'
import React from 'react'
import { useSelector } from 'react-redux'

import { DeviceType } from '~/features/commons/deviceSlice'
import useStream from '~/hooks/useStream'
import { RootState } from '~/rootReducer'

// We define our type for the context properties right here
type ContextProps = {
  isPC: boolean
  deviceType: DeviceType
  stream: Consumer
}

const RootContext = React.createContext<Partial<ContextProps>>({})

type Props = {
  children?: React.ReactNode
}

const RootContextProvider: React.FC<Props> = (props: Props) => {
  const { isPC, type } = useSelector((state: RootState) => state.device)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const stream = useStream(accessToken)

  return (
    <RootContext.Provider value={{ isPC, deviceType: type, stream }}>
      {props.children}
    </RootContext.Provider>
  )
}

export { RootContext, RootContextProvider }
