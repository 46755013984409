import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

import AutoLink from '~/components/atoms/common/AutoLink'
import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import CategoriesGroup from '~/components/common/molecules/CategoriesGroup'
import URLPreview from '~/components/common/molecules/URLPreview'
import QuestionCreatedInfo from '~/components/forum/molecules/questions/QuestionCreatedInfo'
import QuestionUserAvatar from '~/components/forum/molecules/questions/QuestionUserAvatar'
import { QuestionContent } from '~/components/forum/organisms/questions/QuestionContent'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { detectedURL } from '~/utils/common/string'
import { openURL } from '~/utils/navigation'

const QuestionContentMobile: React.FC<QuestionContent> = ({
  question,
  questionControlMenu,
  socialActivityComponent,
}: QuestionContent) => {
  const questionUrl = detectedURL(question.body)
  const urlPreviewHandler = useCallback(openURL, [])

  return (
    <View testID="question-detail-mobile" style={styles.container}>
      <HorizontalContainer>
        <QuestionUserAvatar user={question.user} isQuestioner={true} />
        <View style={styles.description}>
          <QuestionCreatedInfo
            user={question.user}
            createdAt={question.createdAt}
          />
        </View>
      </HorizontalContainer>
      <Text style={styles.title}>{question.title}</Text>
      <View style={styles.body}>
        <AutoLink text={question.body} onPress={openURL} />
        {!isEmpty(question.images) ? (
          <MediaArea entity={question} />
        ) : (
          !isEmpty(questionUrl) && (
            <View style={styles.urlPreviewWrapper}>
              <URLPreview url={questionUrl!} onPress={urlPreviewHandler} />
            </View>
          )
        )}
      </View>

      <CategoriesGroup categories={question.categories} />
      <View style={styles.verticalSeparator} />
      {socialActivityComponent}
      <View testID="control-menu" style={styles.controlMenu}>
        {questionControlMenu}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    fontSize: FontSize.GENERAL,
    marginBottom: 10,
    marginTop: 4,
  },
  container: {
    backgroundColor: color.white,
    marginTop: 10,
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  controlMenu: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  description: {
    flex: 1,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    marginTop: 10,
  },
  urlPreviewWrapper: {
    marginTop: 10,
  },
  verticalSeparator: {
    marginTop: 20,
  },
})
export default QuestionContentMobile
