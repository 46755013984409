import { useNavigationState } from '@react-navigation/core'
import { useNavigation, useRoute } from '@react-navigation/native'
import { last } from 'lodash'
import React from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import CareerHeaderMenuCompany from '~/components/career/molecules/headers/CareerHeaderMenuCompany'
import CareerHeaderMenuUser from '~/components/career/molecules/headers/CareerHeaderMenuUser'
import logo from '~/constants/common/logo'
import { currentUserSelector } from '~/slices/common/users'
import { isPC } from '~/utils/common/device'
import {
  getLastRouteName,
  History,
  RootStackNavigationProp,
  RouteInfo,
} from '~/utils/navigation'

const CareerHeaderLeft: React.FC = () => {
  const currentUser = useSelector(currentUserSelector)
  const routes = useNavigationState((state) => state.routes)
  const logoStyle = isPC() ? styles.logo : styles.logoMobile
  const route: any = useRoute() // eslint-disable-line @typescript-eslint/no-explicit-any
  const lastRoute = last(routes)
  const lastRouteState = lastRoute?.state
  const lastRouteParams: any = lastRoute?.params // eslint-disable-line @typescript-eslint/no-explicit-any
  const { navigate } = useNavigation<RootStackNavigationProp>()

  let childRoutes: RouteInfo[] = []
  if (lastRouteState) {
    childRoutes = lastRouteState?.routes as RouteInfo[]
  } else if (lastRouteParams) {
    childRoutes = [
      {
        name: lastRouteParams?.screen,
        key: lastRouteParams?.screen,
        path: lastRouteParams?.path,
      },
    ]
  }

  const histories = lastRouteState?.history as History[]

  const lastRouteName =
    getLastRouteName({ routes: childRoutes, histories }) || route.name

  return (
    <View style={styles.container} testID="career-header-left">
      <TouchableOpacity
        testID="career-header-left-logo"
        onPress={() => navigate('career')}
      >
        <Image source={logo.career.path} style={logoStyle} />
      </TouchableOpacity>
      {isPC() &&
        (currentUser?.isCompanyAccount ? (
          <CareerHeaderMenuCompany lastRouteName={lastRouteName} />
        ) : (
          <CareerHeaderMenuUser lastRouteName={lastRouteName} />
        ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  logo: {
    height: 45,
    marginLeft: 50,
    marginRight: 30,
    width: 152,
  },
  logoMobile: {
    height: 45,
    marginLeft: 20,
    marginRight: 30,
    width: 152,
  },
})

export default CareerHeaderLeft
