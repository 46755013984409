import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import DefaultLayout from '~/components/DefaultLayout'
import LoginTemplate from '~/components/common/templates/auth/LoginTemplate'
import { setPageTitle } from '~/utils/navigation'

const CompanyLanding: React.FC = () => {
  const { t } = useTranslation()

  useFocusEffect(() => setPageTitle(t('auth.login')))

  return (
    <DefaultLayout>
      <View testID="company-landing" style={styles.container}>
        <LoginTemplate isCompany />
      </View>
    </DefaultLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default CompanyLanding
