import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, ViewStyle } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import StatusForm from '~/components/common/molecules/users/profileStatus/StatusForm'
import StatusOverview from '~/components/common/molecules/users/profileStatus/StatusOverview'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import User from '~/interfaces/User'

type Props = {
  isMe?: boolean
  user?: User
}

const ProfileStatus: React.FC<Props> = ({ isMe, user }: Props) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)
  const jobHuntingStatus = user?.profile?.jobHuntingStatus || []

  const enableEdit = useCallback(() => setIsEdit(true), [setIsEdit])
  const disableEdit = useCallback(() => setIsEdit(false), [setIsEdit])

  return (
    <View style={styles.wrapper} testID="profile-status">
      <TitleText style={styles.title}>
        {t('profile.jobHuntingStatus')}
      </TitleText>
      {isEdit ? (
        <StatusForm
          onFinish={disableEdit}
          jobHuntingStatus={jobHuntingStatus}
          currentPosition={user?.profile?.position ?? ''}
        />
      ) : (
        <StatusOverview
          jobHuntingStatus={jobHuntingStatus}
          currentPosition={user?.profile?.position}
        />
      )}
      {isMe && !isEdit && (
        <Button
          testID="edit-button"
          type="solid"
          title={t('edit')}
          onPress={enableEdit}
          titleStyle={commonStyles.buttonTextSize}
          buttonStyle={StyleSheet.flatten(styles.editButton)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    marginTop: 10,
    width: 90,
  },
  title: {
    color: color.textGray,
    marginTop: 10,
  },
  wrapper: {
    gap: 10,
  } as ViewStyle,
})

export default ProfileStatus
