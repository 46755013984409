import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle } from 'react-native'

import Button from '~/components/workarounds/Button'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  onSubmit: () => void
  disabled?: boolean
}

const PostButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { onSubmit, disabled } = props

  return (
    <Button
      type="solid"
      titleStyle={styles}
      title={t('postForm.post')}
      onPress={onSubmit}
      testID="post-button"
      disabled={disabled}
    />
  )
}

const styles: TextStyle = {
  ...commonStyles.buttonTextSize,
  height: 20,
  paddingLeft: 15,
  paddingRight: 15,
}

export default PostButton
