import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { QuestionQueryParams } from '~/api/questions'
import CardContainer from '~/components/common/atoms/CardContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import MediaViewerDialog from '~/components/common/molecules/MediaViewerDialog'
import SkeletonTimelineItem from '~/components/common/molecules/SkeletonTimelineItem'
import CommentCount from '~/components/common/molecules/socials/CommentCount'
import SharedCount from '~/components/common/molecules/socials/SharedCount'
import SocialActivity from '~/components/common/molecules/socials/SocialActivity'
import SearchNotFound from '~/components/common/organisms/SearchNotFound'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import QuestionControlMenu from '~/components/forum/features/questions/QuestionControlMenu'
import QuestionLikeButton from '~/components/forum/features/questions/QuestionLikeButton'
import QuestionLikeCount from '~/components/forum/features/questions/QuestionLikeCount'
import QuestionSharing from '~/components/forum/features/questions/QuestionSharing'
import QuestionItem from '~/components/forum/molecules/questions/QuestionItem'
import ForumHeader from '~/components/forum/organisms/questions/ForumHeader'
import color from '~/constants/common/color'
import { MediaViewerContextProvider } from '~/contexts/MediaViewerContext'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Question from '~/interfaces/Question'
import { RootState } from '~/rootReducer'
import {
  RootStackNavigationProp,
  getCategoriesFromRoute,
  getRouteParams,
} from '~/utils/navigation'

const QuestionList: React.FC = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const defaultPage = 1
  const { isPC } = useDevice()
  const paramsFromRoute = getRouteParams<QuestionQueryParams>()
  const categoriesQueryParams = getCategoriesFromRoute(
    paramsFromRoute.categories
  )
  const currentUser = useSelector((state: RootState) => state.users.current)
  const [queryParams, setQueryParams] = useState<QuestionQueryParams>(
    paramsFromRoute
  )
  const [questions, setQuestions] = useState([] as Question[])
  const [isLoading, setIsLoading] = useState(true)
  const shouldShowSkeleton = isLoading && isEmpty(questions)

  const fetchQuestions = useAPI(
    async (api: API, page: number = defaultPage): Promise<void> => {
      setIsLoading(true)
      try {
        const response = await api.questions.fetch({
          ...queryParams,
          page,
          categories: categoriesQueryParams,
        })
        if (queryParams.page && page > queryParams.page) {
          !isEmpty(response) && setQueryParams({ ...queryParams, page })
          !isEmpty(response) &&
            setQuestions((questions) => [...questions, ...response])
        } else {
          setQuestions(response)
        }
      } catch (error) {
        toast.showError(error)
      }
      api.isAlive && setIsLoading(false)
    },
    [queryParams.q, queryParams.categories, queryParams.sortBy]
  )

  const deleteQuestion = useCallback(
    (question: Question) => {
      setQuestions((questions: Question[]) =>
        questions.filter((item) => item.id != question.id)
      )
    },
    [setQuestions]
  )

  const updateQuestion = useCallback(
    (question: Question) => {
      setQuestions((questions: Question[]) =>
        questions.map((item) =>
          item.id !== question.id ? item : { ...item, ...question }
        )
      )
    },
    [setQuestions]
  )

  const fetchMoreQuestions = (): void => {
    !isLoading && queryParams?.page && fetchQuestions(queryParams.page + 1)
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchQuestions(queryParams.page)
    }, [fetchQuestions])
  )

  useFocusEffect(
    React.useCallback(() => {
      setQueryParams({
        ...queryParams,
        ...paramsFromRoute,
        page: defaultPage,
      })
    }, [paramsFromRoute.sortBy, paramsFromRoute.q, paramsFromRoute.categories])
  )

  const renderItem: ListRenderItem<Question> = useCallback(
    ({ item }: { item: Question }) => (
      <CardContainer
        testID="question-item-container"
        style={styles.questionItemContainer}
      >
        <QuestionItem
          question={item}
          questionSocialActivity={
            <SocialActivity
              isHideBottomLine
              likeButton={
                <QuestionLikeButton
                  isShowIcon={true}
                  question={item}
                  isShowCountLabel={false}
                />
              }
              shareButton={
                <QuestionSharing
                  question={item}
                  onShared={(shared): void => updateQuestion(shared.question!)}
                />
              }
              sharedCountComponent={<SharedCount value={item.sharedCount} />}
              commentCountComponent={
                <CommentCount
                  value={item.answers.length}
                  type="answer"
                  onPress={(): void =>
                    navigate('questionShow', { id: item.id })
                  }
                />
              }
              likeCountComponent={<QuestionLikeCount question={item} />}
            />
          }
          questionControlMenu={
            item.user.id === currentUser?.id ? (
              <QuestionControlMenu question={item} onDelete={deleteQuestion} />
            ) : undefined
          }
        />
      </CardContainer>
    ),
    []
  )

  const keyExtractor = (item: Question): string => item.id.toString()

  const header = !isPC ? <ForumHeader isPC={false} /> : undefined

  return (
    <View testID="question-list" style={styles.container}>
      {/* FIXME: this is just a workaround to resolve https://github.com/JAPI-org/enjin/issues/1124
        Verify again with higher Chrome or react native version until the problem is resolved. */}
      {isPC && <ForumHeader isPC={true} />}
      {shouldShowSkeleton ? (
        <SkeletonList
          count={9}
          numColumns={1}
          header={<View style={styles.skeletonHeader}>{header}</View>}
          itemComponent={
            <View style={styles.loadingItemContainer}>
              <SkeletonTimelineItem />
            </View>
          }
        />
      ) : (
        <MediaViewerContextProvider>
          <InfiniteScrollFlatList
            data={questions}
            extraData={questions}
            scrollEnabled={!isPC}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ListHeaderComponent={header}
            style={styles.scrollContainer}
            onReachBottom={fetchMoreQuestions}
            ListEmptyComponent={
              isLoading ? undefined : (
                <SearchNotFound subtitle={t('forum.notFoundTitle')} />
              )
            }
            ItemSeparatorComponent={(): React.ReactElement => (
              <View style={styles.separator} testID="separator" />
            )}
          />
          <MediaViewerDialog isPC={isPC} />
        </MediaViewerContextProvider>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingItemContainer: {
    width: '100%',
  },
  questionItemContainer: {
    backgroundColor: color.white,
    marginTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  scrollContainer: {
    flex: 1,
    paddingBottom: 10,
  },
  separator: {
    backgroundColor: color.lightGray,
    height: 1,
  },
  skeletonHeader: {
    marginBottom: 10,
  },
})

export default QuestionList
