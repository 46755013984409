import React from 'react'
import { StyleSheet, View, Text } from 'react-native'

import TextAvatar from '~/components/common/atoms/TextAvatar'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Question from '~/interfaces/Question'
import i18next from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

type Props = {
  question: Question
  questionControlMenu?: React.ReactElement
}

const UserQuestionItem: React.FC<Props> = ({
  question,
  questionControlMenu,
}: Props) => {
  const titleStyles = StyleSheet.flatten([
    styles.title,
    { fontFamily: getMediumFontByLanguage(i18next.language) },
  ])
  return (
    <View testID="user-question-item" style={styles.container}>
      <TextAvatar text="Q" size="medium" color="secondary" />
      <View style={styles.questionContent}>
        <Link
          numberOfLines={2}
          style={titleStyles}
          to={`/forum/questions/${question.id}`}
        >
          {question.title}
        </Link>
        <Text style={styles.body} numberOfLines={2}>
          {question.body}
        </Text>
      </View>
      {questionControlMenu && (
        <View style={styles.controlMenu} testID="user-question-control-menu">
          {questionControlMenu}
        </View>
      )}
    </View>
  )
}
StyleSheet.flatten()
const styles = StyleSheet.create({
  body: {
    color: color.textGray,
    flexWrap: 'wrap',
    marginTop: 4,
  },
  container: {
    backgroundColor: color.white,
    flexDirection: 'row',
    marginBottom: 10,
    padding: 20,
    ...commonStyles.borderRadiusAllCorner,
  },
  controlMenu: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  questionContent: {
    flex: 1,
    paddingLeft: 20,
  },
  title: {
    alignSelf: 'baseline',
    color: color.pressableText,
    marginRight: 40,
  },
})

export default UserQuestionItem
