import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import EducationGroupItem from '~/components/career/molecules/resume/EducationGroupItem'
import ResumeBaseGroup from '~/components/career/molecules/resume/ResumeBaseGroup'
import Education from '~/interfaces/Education'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  templateType: TemplateType
  educations: Partial<Education>[]
}

const EducationGroup: React.FC<Props> = ({
  educations,
  templateType,
}: Props) => {
  const { t } = useTranslation()

  return isEmpty(educations) ? (
    <></>
  ) : (
    <ResumeBaseGroup
      category={t('resume.user.educationTitle')}
      templateType={templateType}
    >
      <View testID="education-group" style={styles.container}>
        {educations!.map((education, index) => (
          <EducationGroupItem
            key={index}
            education={education}
            templateType={templateType}
          />
        ))}
      </View>
    </ResumeBaseGroup>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default EducationGroup
