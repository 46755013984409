import React, { useContext } from 'react'
import {
  Linking,
  StyleSheet,
  TouchableOpacity,
  Image,
  View,
} from 'react-native'

import logoImage from '~/assets/images/logo-white.png'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import FooterAddress from '~/components/common/molecules/footer/FooterAddress'
import FooterMenu from '~/components/common/molecules/footer/FooterMenu'
import color from '~/constants/common/color'
import socialLink from '~/constants/common/socialLink'
import { RootContext } from '~/contexts/RootContext'

const LOGO_SIZE = {
  height: 70,
  width: 260,
}

const LOGO_SIZE_MOBILE = {
  height: 40,
  width: 145,
}

const socialIconSize = {
  width: 42,
  height: 42,
}

const Footer: React.FC = () => {
  const { isPC } = useContext(RootContext)

  return (
    <View
      testID="footer"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <View style={styles.content}>
        <Image style={isPC ? LOGO_SIZE : LOGO_SIZE_MOBILE} source={logoImage} />
        <View style={styles.body}>
          <FooterMenu isPC={isPC} />
          <View style={styles.footerSeparator} />
          <FooterAddress isPC={isPC} />
        </View>
        <HorizontalContainer>
          <Image
            style={socialIconSize}
            source={require('~/assets/images/icons/socials/f.png')}
          />
          <View style={styles.separator} />
          <TouchableOpacity
            onPress={() => Linking.openURL(socialLink.linkedin)}
            testID={'social-linkedin'}
          >
            <Image
              style={socialIconSize}
              source={require('~/assets/images/icons/socials/in.png')}
            />
          </TouchableOpacity>
          <View style={styles.separator} />
          <TouchableOpacity
            onPress={() => Linking.openURL(socialLink.instagram)}
            testID={'social-instagram'}
          >
            <Image
              style={socialIconSize}
              source={require('~/assets/images/icons/socials/inst.png')}
            />
          </TouchableOpacity>
        </HorizontalContainer>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    marginBottom: 42,
    marginTop: 32,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: color.darkGray,
    paddingVertical: 44,
    paddingHorizontal: 20,
    width: '100%',
  },
  containerMobile: {
    backgroundColor: color.darkGray,
    flexDirection: 'column',
    padding: 10,
    width: '100%',
  },
  content: {
    flexDirection: 'column',
    maxWidth: 900,
    width: '100%',
  },
  footerSeparator: {
    width: 50,
  },
  separator: {
    width: 21,
  },
})
export default Footer
