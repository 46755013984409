import 'react-native-gesture-handler'
import {
  FontAwesome,
  AntDesign,
  Fontisto,
  Ionicons,
  MaterialIcons,
} from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { MenuProvider } from 'react-native-popup-menu'
import { ToastProvider } from 'react-native-toast-notifications'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { register } from 'timeago.js'
import { en_US, ja } from 'timeago.js/lib/lang'

import RootPage from '~/components/app/RootPage'
import '~/plugins/i18n'
import color from '~/constants/common/color'
import { SupportedLanguage, switchLanguage } from '~/plugins/i18n'
import { RootState } from '~/rootReducer'
import { loginWithStoredToken } from '~/slices/common/auth'
import store from '~/store'
import { initSentry } from '~/utils/common/sentry'

const AppData: React.FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state: RootState) => state.users.current)
  const [fontLoaded, setFontLoaded] = useState(false)

  const fetchFonts = async (): Promise<void> => {
    try {
      const fontText = Font.loadAsync({
        Roboto: require('~/assets/font/Roboto.ttf'),
        RobotoMedium: require('~/assets/font/Roboto-Medium.ttf'),
        NotoSansJPRegular: require('~/assets/font/NotoSansJP-Regular.otf'),
      })

      await Promise.all([
        fontText,
        ...[
          FontAwesome.font,
          AntDesign.font,
          Fontisto.font,
          MaterialIcons.font,
          Ionicons.font,
        ].map((font) => Font.loadAsync(font)),
      ])
    } catch (error) {
      console.warn(error)
    }
  }

  const fetchUserData = async (): Promise<void> => {
    try {
      await dispatch(loginWithStoredToken())
    } catch (e) {
      // don't throw error here to keep loading fonts
    }
  }

  const prepareData = async (): Promise<void> => {
    try {
      await SplashScreen.preventAutoHideAsync()
      await fetchUserData()
      initSentry()
      await fetchFonts()
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    prepareData().finally(() => {
      setFontLoaded(true)
    })
    register(`${SupportedLanguage.EN}`, en_US)
    register(`${SupportedLanguage.JA}`, ja)
  }, [])

  useEffect(() => {
    fontLoaded &&
      currentUser?.displayLang &&
      switchLanguage(currentUser.displayLang)
  }, [currentUser?.displayLang, fontLoaded])

  const hideSplashScreen = useCallback(async () => {
    if (fontLoaded) {
      await SplashScreen.hideAsync()
    }
  }, [fontLoaded])

  if (!fontLoaded) {
    return null
  }

  return (
    <View onLayout={hideSplashScreen} style={styles.container}>
      <MenuProvider>
        <RootPage />
      </MenuProvider>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

const App: React.FC = () => (
  <ToastProvider
    placement="bottom"
    normalColor={color.bodyText}
    successColor={color.primary}
    warningColor={color.warning}
    dangerColor={color.danger}
    successIcon={<Ionicons name="checkmark-done" color={color.white} />}
    warningIcon={<Ionicons name="warning-outline" color={color.white} />}
    dangerIcon={<Ionicons name="warning-outline" color={color.white} />}
  >
    <Provider store={store}>
      <AppData />
    </Provider>
  </ToastProvider>
)

export default App
