import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, TextStyle, StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Resume from '~/interfaces/Resume'

type Props = {
  resume: Partial<Resume>
}

const ResumeUserProfile: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()

  const textColors = {
    enjin: { color: color.white },
    shades: { color: color.white },
    formal: { color: color.black },
    minimal: { color: color.black },
  }

  const userNameFontStyle = {
    fontSize: FontSize.IMPORTANT,
    fontWeight: 'bold',
  }

  const userNameStyle = {
    ...userNameFontStyle,
    ...textColors[resume.templateType!],
  }

  const currentPositionStyle = {
    ...textColors[resume.templateType!],
    fontSize: FontSize.IMPORTANT,
  }

  const currentOrganisationStyle = {
    ...textColors[resume.templateType!],
    fontSize: FontSize.GENERAL,
  }

  return (
    <View testID="resume-user-profile" style={styles.container}>
      <Text style={userNameStyle as TextStyle}>
        {t('profile.displayName', {
          givenName: resume.givenName,
          familyName: resume.familyName,
        })}
      </Text>
      <Text style={currentPositionStyle}>{resume.currentPosition}</Text>
      <Text style={currentOrganisationStyle}>{resume.currentOrganisation}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
})

export default ResumeUserProfile
