import { range } from 'lodash'
import React, { useContext } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'

type Props = {
  title?: string
  index: number
  numOfElements: number
}

const HeaderIndicator: React.FC<Props> = ({
  index,
  title,
  numOfElements,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const elementStyle = isPC ? styles.element : styles.elementMobile
  const elementActiveStyle = (elementIndex: number): ViewStyle =>
    index === elementIndex ? styles.active : { opacity: 0.5 }

  const renderElements = (): React.ReactNode[] =>
    range(numOfElements).map((index) => (
      <View
        key={index}
        testID="header-indicator-element"
        style={StyleSheet.flatten([elementStyle, elementActiveStyle(index)])}
      />
    ))

  return (
    <View>
      <Text style={isPC ? styles.title : styles.titleMobile}>{title}</Text>
      <View style={styles.elementContainer} testID="header-indicator">
        {renderElements()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  active: {
    backgroundColor: color.white,
    opacity: 1,
  },
  element: {
    backgroundColor: color.textGray,
    height: 8,
    width: 100,
    marginRight: 8,
    ...commonStyles.borderRadiusAllCorner,
  },
  elementContainer: {
    flexDirection: 'row',
  },
  elementMobile: {
    backgroundColor: color.textGray,
    height: 8,
    marginRight: 4,
    width: 46,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    color: color.white,
    fontSize: FontSize.IMPORTANT,
    marginBottom: 8,
  },
  titleMobile: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 8,
  },
})

export default HeaderIndicator
