import { BaseAPI } from '~/api/baseAPI'

export enum CompanyRecruitmentsSortType {
  ALL = '',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  RECENTLY = 'recently',
  ENDED = 'ended',
}

export type CompanyRecruitmentParams = {
  sortBy: CompanyRecruitmentsSortType
}

export class CompanyRecruitmentsAPI extends BaseAPI {
  configPath(companyId: number): CompanyRecruitmentsAPI {
    this.path = `/companies/${companyId}/recruitments`
    return this
  }

  duplicate(companyId: number, recruitmentId: number): CompanyRecruitmentsAPI {
    this.path = `/companies/${companyId}/recruitments/${recruitmentId}/duplicate`
    return this
  }
}
