import React, { useContext } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'

import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'

type Props = {
  leftFilters?: React.ReactElement
  rightFilters?: React.ReactElement
}

const HeaderFilters: React.FC<Props> = ({
  leftFilters,
  rightFilters,
}: Props) => {
  const { isPC } = useContext(RootContext)

  return (
    <View
      testID="filter-header"
      style={StyleSheet.flatten([
        styles.wrapper,
        isPC
          ? undefined
          : { borderBottomColor: color.lightGray, borderBottomWidth: 1 },
      ])}
    >
      <View
        style={isPC ? styles.container : styles.containerMobile}
        testID="filter-header-container"
      >
        <ScrollView
          horizontal
          style={styles.searchForm}
          contentContainerStyle={styles.scrollContainer}
          testID="filter-header-scroll"
        >
          <>
            {isPC && leftFilters}
            {rightFilters}
          </>
        </ScrollView>
      </View>
      {!isPC && (
        <View style={styles.searchContainer} testID="search-container">
          {leftFilters}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    flexDirection: 'row',
    height: 65,
    justifyContent: 'center',
  },
  containerMobile: {
    backgroundColor: color.primary,
    justifyContent: 'center',
    paddingVertical: 8,
  },
  scrollContainer: {
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 15,
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
  },
  searchForm: {
    flexDirection: 'row',
    width: '100%',
  },
  wrapper: {
    backgroundColor: color.white,
  },
})

export default HeaderFilters
