import { FontAwesome as Icon } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import MenuDrawerItem from '~/components/common/atoms/drawer/MenuDrawerItem'
import MenuDrawerOptions from '~/components/common/molecules/drawer/MenuDrawerOptions'
import CompanyServiceList from '~/components/common/organisms/CompanyServiceList'
import ServiceList from '~/components/common/organisms/ServiceList'
import IconName from '~/constants/common/IconName'
import { ServiceType } from '~/constants/common/app'
import color from '~/constants/common/color'
import User from '~/interfaces/User'
import { RootStackNavigationProp } from '~/utils/navigation'
import { openURL } from '~/utils/navigation'

type Props = {
  isMobile: boolean
  user?: User
  onLogout?: () => void
  languageSettingComponent: React.ReactElement
  userInfoComponent?: React.ReactElement
}

const MenuDrawer: React.FC<Props> = ({
  isMobile,
  onLogout,
  languageSettingComponent,
  userInfoComponent,
  user,
}: Props) => {
  const contactURL = 'https://company.enjin.world/contact-us'
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const containerStyle = isMobile ? styles.containerMobile : styles.container

  return (
    <ScrollView
      style={containerStyle}
      testID="menu-drawer"
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.contentContainer}>
        {!isMobile && (
          <TitleText style={styles.title} testID="menu-drawer-title">
            {t('menu.menuTitle')}
          </TitleText>
        )}
        {userInfoComponent}
        <MenuDrawerOptions
          topOptionComponents={[
            <View key="top-separator" style={styles.separator} />,
            !user?.isCompanyAccount ? (
              <ServiceList
                isPC={false}
                key="service-list"
                isSelection={true}
                currentService={ServiceType.CAREER}
                title={t('appService.moveToServiceTitle')}
              />
            ) : (
              <CompanyServiceList key="service-list" user={user} />
            ),
          ]}
          middleOptionComponents={[
            <View key="separator" style={styles.separator} />,
            <MenuDrawerItem
              key="setting"
              title={t('menu.settingsAndPrivacy')}
              onPress={(): void => navigate('settings')}
              icon={
                <Icon
                  size={25}
                  name={IconName.DRAWER_SETTING}
                  color={color.textGray}
                />
              }
            />,
            <MenuDrawerItem
              key="help"
              title={t('menu.helpAndSupport')}
              icon={
                <Icon
                  size={25}
                  name={IconName.DRAWER_HELP_SUPPORT}
                  color={color.textGray}
                />
              }
              onPress={(): void => {
                openURL(contactURL)
              }}
            />,
          ]}
          bottomOptionComponents={[
            languageSettingComponent,
            <MenuDrawerItem
              key="sign-out"
              title={t('auth.logOut')}
              icon={
                <Icon
                  size={25}
                  name={IconName.DRAWER_SIGNOUT}
                  color={color.textGray}
                />
              }
              onPress={onLogout}
            />,
          ]}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    width: 320,
    flex: 1,
    paddingHorizontal: 30,
  },
  containerMobile: {
    backgroundColor: color.white,
    flex: 1,
    paddingHorizontal: 30,
  },
  contentContainer: {
    flex: 1,
  },
  separator: {
    borderBottomColor: color.borderSeparator,
    borderBottomWidth: 1,
    marginVertical: 20,
  },
  title: {
    paddingTop: 30,
    color: color.textGray,
  },
})

export default MenuDrawer
