import { isNil } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import AchievementFormList from '~/components/career/molecules/resume/AchievementFormList'
import BioForm from '~/components/career/molecules/resume/BioForm'
import ContactForm from '~/components/career/molecules/resume/ContactForm'
import EducationFormList from '~/components/career/molecules/resume/EducationFormList'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import LanguageFormList from '~/components/career/molecules/resume/LanguageFormList'
import ResumeLanguageSelection from '~/components/career/molecules/resume/ResumeLanguageSelection'
import ResumeTemplateList from '~/components/career/molecules/resume/ResumeTemplateList'
import WorkExperienceFormList from '~/components/career/molecules/resume/WorkExperienceFormList'
import EditableTextForm from '~/components/common/molecules/EditableTextForm'
import SkillForm from '~/components/common/molecules/SkillForm'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import Achievement from '~/interfaces/Achievement'
import Education from '~/interfaces/Education'
import Resume, { TemplateType } from '~/interfaces/Resume'
import ResumeLanguage from '~/interfaces/ResumeLanguage'
import Skill from '~/interfaces/Skill'
import WorkExperience from '~/interfaces/WorkExperience'
import { SupportedLanguage } from '~/plugins/i18n'
import {
  currentResumeSelector,
  updateCurrentResume,
} from '~/slices/career/resumes'

const EditResumeMainFirst: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const resume = useSelector(currentResumeSelector)

  const changeResume = (data: Partial<Resume>): void => {
    dispatch(updateCurrentResume(data))
  }

  const changeResumeTitle = async (value: string) =>
    changeResume({ headline: value })

  useEffect(() => {
    //Set default language for resume
    if (!resume.language) {
      changeResume({ language: SupportedLanguage.EN })
    }
  }, [resume.language])

  return (
    <View testID="edit-resume-main-first">
      <View style={styles.separator} />
      <ResumeLanguageSelection
        selectedLanguage={resume.language}
        onSelect={(language) => changeResume({ language })}
      />
      <ResumeTemplateList
        resume={resume as Resume}
        onPressItem={(type: string): void =>
          changeResume({ templateType: type as TemplateType })
        }
      />
      <View style={styles.separator} />
      <EditableTextForm
        value={resume.headline}
        editable
        onSubmit={changeResumeTitle}
        editButtonStyle={'icon'}
        textSize={FontSize.IMPORTANT}
      />
      <View style={styles.separator} />
      <BioForm bioData={resume} onChange={changeResume} isPC={isPC} />
      <View style={styles.separator} />
      <ContactForm contactData={resume} onChange={changeResume} isPC={isPC} />
      <View style={styles.separator} />
      <WorkExperienceFormList
        isPC={isPC}
        onChange={(workExperiences: Partial<WorkExperience>[]): void =>
          changeResume({ workExperiences })
        }
        workExperiences={
          isNil(resume.workExperiences) ? [{}] : resume.workExperiences
        }
      />
      <View style={styles.separator} />
      <EducationFormList
        isPC={isPC}
        onChange={(educations: Partial<Education>[]): void =>
          changeResume({ educations })
        }
        educations={isNil(resume.educations) ? [{}] : resume.educations}
      />
      <View style={styles.separator} />
      <AchievementFormList
        isPC={isPC}
        onChange={(achievements: Partial<Achievement>[]): void =>
          changeResume({ achievements })
        }
        achievements={isNil(resume.achievements) ? [{}] : resume.achievements}
      />
      <View style={styles.separator} />
      <FormLayout
        title={t('profile.skill')}
        hasAddAction={false}
        isPC={isPC}
        containerStyle={styles.skillFormContainer}
      >
        <SkillForm
          isPC={isPC}
          skills={resume.skills || []}
          onChange={(skills: Partial<Skill>[]): void =>
            changeResume({ skills })
          }
          maxItemLength={10}
        />
      </FormLayout>
      <View style={styles.separator} />
      <LanguageFormList
        isPC={isPC}
        onChange={(languages: Partial<ResumeLanguage>[]): void =>
          changeResume({ languages })
        }
        languages={isNil(resume.languages) ? [{}] : resume.languages}
        resumeLanguage={resume.language!}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  separator: {
    marginTop: 15,
  },
  skillFormContainer: {
    paddingBottom: 40,
  },
})
export default EditResumeMainFirst
