import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import CommunityName from '~/components/community/atoms/CommunityName'
import Text from '~/components/workarounds/Text'
import { FontSize } from '~/constants/common/font'
import Community from '~/interfaces/Community'

type Props = {
  community?: Community
}

const CommunityInfo: React.FC<Props> = ({ community }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="community-info">
      <CommunityName size="large" community={community} />
      <Text style={styles.title}>
        {t('community.information.memberNumber', {
          number: community?.communityMembers?.length || 0,
        })}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: FontSize.REMARKABLE,
  },
})

export default CommunityInfo
