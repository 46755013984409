import { find } from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { UserProfile } from '~/api/users'
import countries from '~/assets/data/country.json'
import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import FormAction from '~/components/common/atoms/users/FormAction'
import BasicInfoForm from '~/components/common/molecules/users/BasicInfoForm'
import ProfileCard from '~/components/common/molecules/users/ProfileCard'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import Profile from '~/interfaces/Profile'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'
import { getUserNameByLanguage } from '~/utils/common/user'

type Props = {
  profile: Profile
  onUpdateProfile: (profile: UserProfile) => void
  isMe?: boolean
}

const BasicInfo: React.FC<Props> = ({
  profile,
  onUpdateProfile,
  isMe,
}: Props) => {
  const { t } = useTranslation()
  const containerStyle = styles.container

  const [editing, setEditing] = useState(false)
  const initProfile = {
    id: profile.id,
    country: profile.country,
    birthday: profile.birthday,
    givenName: profile.givenName,
    familyName: profile.familyName,
    sex: profile.sex,
  }
  const [userProfile, setUserProfile] = useState(initProfile)
  const { isPC } = useContext(RootContext)

  useEffect(() => {
    setUserProfile(initProfile)
  }, [profile])

  const getNationalityName = (): string | undefined => {
    const country = find(countries, ['id', userProfile.country])
    return i18n.language === 'ja' ? country?.name : country?.en_name
  }

  const startEdit = (): void => {
    setEditing(true)
  }

  const cancelEdit = (): void => {
    setUserProfile(initProfile)
    setEditing(false)
  }

  const save = async (): Promise<void> => {
    if (
      profile.country !== userProfile.country ||
      profile.birthday !== userProfile.birthday ||
      profile.givenName !== userProfile.givenName ||
      profile.familyName !== userProfile.familyName ||
      profile.sex !== userProfile.sex
    ) {
      onUpdateProfile({
        userProfile,
      } as UserProfile)
    }
    setEditing(false)
  }

  return (
    <CardContainer style={containerStyle}>
      <View style={styles.header}>
        <TitleText testID="basic-info-title" style={styles.title}>
          {t('profile.basicInfo')}
        </TitleText>
      </View>
      {editing ? (
        <View>
          <BasicInfoForm profile={userProfile} isPC={isPC} />
          <View testID="basic-info-action">
            <FormAction
              onCancel={cancelEdit}
              onSubmit={save}
              isLoading={false}
              disabled={false}
            />
          </View>
        </View>
      ) : (
        <View style={isPC ? styles.content : styles.contentMobile}>
          <ProfileCard
            icon=""
            title={t('profile.fullName')}
            description={getUserNameByLanguage(i18n.language, {
              profile,
            } as User)}
          />
          <ProfileCard
            icon=""
            title={t('profile.nationality')}
            description={getNationalityName() || ''}
          />
          <ProfileCard
            icon=""
            title={t('profile.birthdate')}
            description={userProfile.birthday || ''}
          />
        </View>
      )}
      {isMe && !editing && (
        <Button
          testID="edit-button"
          type="solid"
          title={t('edit')}
          onPress={startEdit}
          containerStyle={StyleSheet.flatten(styles.editButtonContainer)}
          buttonStyle={StyleSheet.flatten(styles.editButton)}
          titleStyle={StyleSheet.flatten(styles.editTitle)}
        />
      )}
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    gap: 10,
    padding: 20,
  },
  content: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
    paddingBottom: 6,
    paddingTop: 6,
  },
  contentMobile: {
    flexDirection: 'column',
    gap: 20,
    paddingBottom: 6,
    paddingTop: 6,
  },
  editButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 90,
  },
  editButtonContainer: {
    marginTop: 25,
  },
  editTitle: {
    fontSize: FontSize.GENERAL,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: color.textGray,
  },
})

export default BasicInfo
