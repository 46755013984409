import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import ResumeTitlePopup from '~/components/career/features/resume/ResumeTitlePopup'
import CreateResumeTemplate from '~/components/career/templates/resume/CreateResumeTemplate'
import { RootState } from '~/rootReducer'
import { resetCurrentResume } from '~/slices/career/resumes'

const ResumeNew: React.FC = () => {
  const dispatch = useDispatch()

  const showTitlePopup = useSelector(
    (state: RootState) => state.resumes.showTitlePopup
  )

  useEffect(() => {
    // Reset current resume before create
    dispatch(resetCurrentResume)
  }, [])

  return (
    <DefaultLayout>
      <CreateResumeTemplate />
      {showTitlePopup && <ResumeTitlePopup type="create" />}
    </DefaultLayout>
  )
}

export default ResumeNew
