import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import Link, { To } from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  to: To
  isActive?: boolean
  translationKey: string
}

const HeaderMenuLink: React.FC<Props> = ({
  to,
  isActive,
  translationKey,
}: Props) => {
  const { t } = useTranslation()

  const styleToUse = isActive
    ? StyleSheet.flatten([styles.link, styles.withLine])
    : styles.link

  return (
    <Link to={to} style={styleToUse} testID="header-menu-link">
      {t(translationKey)}
    </Link>
  )
}

const styles = StyleSheet.create({
  link: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
    margin: 10,
    marginRight: 16,
    paddingBottom: 4,
  },
  withLine: {
    borderBottomColor: color.primary,
    borderBottomWidth: 2,
    paddingBottom: 2,
  },
})

export default HeaderMenuLink
