import { FontAwesome } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { MenuProvider } from 'react-native-popup-menu'

import api from '~/api'
import { RecruitmentEntryParams } from '~/api/companyRecruitmentEntries'
import JobApplicantItem from '~/components/career/molecules/jobs/JobApplicantItem'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import SearchInput from '~/components/common/atoms/SearchInput'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import Entry, { EntryControlMenuType } from '~/interfaces/Entry'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  isShow: boolean
  job: Recruitment
  hideApplicant: () => void
  isPC: boolean
  company: Company
}

const JobApplicantPopup = ({
  isShow,
  job,
  hideApplicant,
  isPC,
  company,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()

  const [searchApplicant, setSearchApplicant] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [entries, setEntries] = useState<Entry[]>([])
  const [isArchived, setIsArchived] = useState(false)

  const getApplicantMenuData = [
    {
      id: EntryControlMenuType.ALL,
      title: t('company.applicant.all'),
      active: true,
    },
    {
      id: EntryControlMenuType.NEW,
      title: t('company.applicant.new'),
      active: false,
    },
    {
      id: EntryControlMenuType.MESSAGED,
      title: t('company.applicant.messaged'),
      active: false,
    },
    {
      id: EntryControlMenuType.ARCHIVED,
      title: t('company.applicant.archived'),
      active: false,
    },
  ]

  const [applicantMenuData, setApplicantMenuData] = useState(
    getApplicantMenuData
  )

  const fetchRecruitmentEntries = async (status?: string): Promise<void> => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await api.companyRecruitmentEntries
        .configPath(company!.id, job.id)
        .index<Entry[], RecruitmentEntryParams>({
          q: searchApplicant,
          status,
        })
      setEntries(response)
    } catch (err) {
      toast.showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const submitSearch = () => {
    fetchRecruitmentEntries()
  }

  const changeApplicantMenu = (menuId: string) => {
    setApplicantMenuData(
      applicantMenuData.filter((item) => {
        if (item.id === menuId) {
          item.active = true
          changeEntries(menuId)
        } else {
          item.active = false
        }
        return item
      })
    )
  }

  const changeEntries = (menuId: string) => {
    switch (menuId) {
      case EntryControlMenuType.ALL:
      case EntryControlMenuType.MESSAGED:
        fetchRecruitmentEntries()
        break
      case EntryControlMenuType.ARCHIVED:
        fetchRecruitmentEntries(menuId)
        setIsArchived(true)
        break
      case EntryControlMenuType.NEW:
        fetchRecruitmentEntries(menuId)
        break
      default:
    }
  }

  const dismissDialog = () => {
    hideApplicant()
  }

  useEffect(() => {
    fetchRecruitmentEntries()
  }, [])

  return (
    <ModalContainer visible={isShow} onDismiss={dismissDialog}>
      <MenuProvider skipInstanceCheck>
        <View
          style={[styles.container, isPC ? styles.pc : styles.mobile]}
          testID="job-applicant-popup"
        >
          <TouchableOpacity style={styles.close} onPress={dismissDialog}>
            <FontAwesome
              testID="applicant-icon-close"
              name="close"
              color={color.gray}
              size={18}
            />
          </TouchableOpacity>
          <View testID="job-applicant-menu" style={styles.menuContainer}>
            {applicantMenuData.map((menu, key) => {
              return (
                <TouchableOpacity
                  onPress={() => changeApplicantMenu(menu.id)}
                  style={[
                    styles.menuMarginLeft,
                    menu.active ? styles.menu : undefined,
                  ]}
                  key={key}
                >
                  <Text>{menu.title}</Text>
                </TouchableOpacity>
              )
            })}
          </View>
          <View
            style={isPC ? styles.searchView : styles.searchViewMobile}
            testID="search-input-container"
          >
            <SearchInput
              value={searchApplicant}
              onSubmit={submitSearch}
              onChangeText={setSearchApplicant}
              placeholderKey={t('company.applicant.searchApplicantPlaceholder')}
            />
          </View>
          <View testID="job-applicant-list">
            <ScrollView
              testID="job-application-scroll"
              style={styles.dialogContainer}
            >
              {entries.map((entry) => {
                return (
                  <JobApplicantItem
                    entry={entry}
                    key={entry.id}
                    isArchived={isArchived}
                  />
                )
              })}
            </ScrollView>
          </View>
        </View>
      </MenuProvider>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    marginTop: 10,
    marginRight: 10,
    right: 0,
    top: 0,
  },
  container: {
    ...commonStyles.borderRadiusAllCorner,
    backgroundColor: color.white,
    height: 'auto',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  dialogContainer: {
    maxHeight: 450,
  },
  menu: {
    borderColor: color.primary,
    borderBottomWidth: 2,
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuMarginLeft: {
    marginLeft: 30,
  },
  mobile: {
    paddingLeft: 20,
    paddingTop: 40,
    width: '100%',
  },
  pc: {
    padding: 40,
    width: 800,
  },
  searchView: {
    marginLeft: 20,
    marginTop: 20,
    width: '94%',
  },
  searchViewMobile: {
    marginTop: 10,
    width: '94%',
  },
})

export default JobApplicantPopup
