import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'

export type RecruitmentStatisticParams = {
  year: number
} & BaseQueryParams

export class CompanyRecruitmentStatisticsAPI extends BaseAPI {
  configPath(companyId: number): CompanyRecruitmentStatisticsAPI {
    this.path = `/companies/${companyId}/recruitment_statistics`
    return this
  }
}
