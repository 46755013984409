import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'

import logoImage from '~/assets/images/logo_color.png'
import { RootStackNavigationProp } from '~/utils/navigation'

const AuthenticationHeaderLeft: React.FC = () => {
  const { navigate } = useNavigation<RootStackNavigationProp>()

  return (
    <TouchableOpacity
      testID="authentication-header-left"
      onPress={() => navigate('topPage')}
    >
      <Image source={logoImage} style={styles.logo} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  logo: {
    height: 40,
    marginLeft: 50,
    marginRight: 30,
    width: 120,
  },
})

export default AuthenticationHeaderLeft
