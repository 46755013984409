import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  ListRenderItem,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'

import emptyImage from '~/assets/images/prompts/inboxempty.png'
import MessageItemControlMenu from '~/components/career/features/messages/MessageItemControlMenu'
import InboxItem from '~/components/career/molecules/message/InboxItem'
import EmptyView from '~/components/common/molecules/EmptyView'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import ChatThread from '~/interfaces/ChatThread'
import {
  MessageControlMenuType,
  MessageItemControlMenuEvent,
} from '~/interfaces/MessageItemControlMenu'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  header?: React.ReactElement
  chatThreads?: ChatThread[]
  selectedChatThread?: ChatThread
  onSelect: (thread: ChatThread) => void
  onChange?: (thread: ChatThread) => void
  deleteThread?: (thread: ChatThread) => void
  renameThread?: (thread: ChatThread) => void
  archiveThread?: (thread: ChatThread) => void
  onPress?: (thread: ChatThread) => void
  isShowCheckboxThreads?: boolean
}

const InboxColumn: React.FC<Props> = ({
  header,
  chatThreads,
  onSelect,
  selectedChatThread,
  deleteThread,
  onChange,
  renameThread,
  archiveThread,
  onPress,
  isShowCheckboxThreads,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const containerStyle = isPC ? styles.pc : styles.mobile
  const keyExtractor = (item: ChatThread): string => item.id.toString()
  const currentUser = useSelector(currentUserSelector)

  const renderMessageItemControlMenu = useCallback(
    (item: ChatThread) => {
      return (
        <MessageItemControlMenu
          chatThread={item}
          onSelect={(event: MessageItemControlMenuEvent) => {
            switch (event.type) {
              case MessageControlMenuType.MARK_AS_READ:
                event.data && onChange?.(event.data)
                break
              case MessageControlMenuType.MARK_AS_UNREAD:
                event.data && onChange?.(event.data)
                break
              case MessageControlMenuType.DELETE:
                deleteThread?.(item)
                break
              case MessageControlMenuType.RENAME:
                renameThread?.(item)
                break
              case MessageControlMenuType.ARCHIVE:
                archiveThread?.(item)
                break
            }
          }}
        />
      )
    },
    [onChange, deleteThread]
  )

  const renderItem: ListRenderItem<ChatThread> = ({
    item,
  }: {
    item: ChatThread
  }) => (
    <TouchableOpacity
      testID="inbox-item-container"
      disabled={selectedChatThread?.id === item.id}
      onPress={(): void => onSelect({ ...item, unreadCount: 0 })}
    >
      <View style={styles.separator} />
      <InboxItem
        chatThread={item}
        currentUser={currentUser}
        isSelected={selectedChatThread?.id === item.id}
        controlMenu={renderMessageItemControlMenu(item)}
        onPress={onPress}
        isShowCheckboxThreads={isShowCheckboxThreads}
      />
    </TouchableOpacity>
  )

  return (
    <View style={containerStyle} testID="inbox-column">
      <FlatList
        testID="inbox-list"
        renderItem={renderItem}
        data={chatThreads}
        ListHeaderComponent={header}
        keyExtractor={keyExtractor}
        showsVerticalScrollIndicator={true}
        ListEmptyComponent={
          <EmptyView
            isPC={!!isPC}
            title={t('message.messageNotFound')}
            imageSource={emptyImage}
          />
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  mobile: {
    flex: 1,
  },
  pc: {
    backgroundColor: color.white,
    borderRightColor: color.gray,
    borderRightWidth: 1,
    maxHeight: 1395,
    width: 387,
  },
  separator: {
    borderTopColor: color.lightGray,
    borderTopWidth: 1,
  },
})

export default InboxColumn
