import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import i18n from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'

const SelectedMask: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.selectedMask} testID="selected-mask">
      <Text style={styles.selectedText}>{t('selected')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  selectedMask: {
    alignItems: 'center',
    backgroundColor: color.mask,
    borderRadius: 8,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  selectedText: {
    color: color.white,
    fontFamily: getMediumFontByLanguage(i18n.language),
  },
})

export default SelectedMask
