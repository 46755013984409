import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import CommentCount from '~/components/common/molecules/socials/CommentCount'
import Answer from '~/interfaces/Answer'
import { RootState } from '~/rootReducer'

type Props = {
  answer: Answer
}

const AnswerCommentCount: React.FC<Props> = ({ answer }: Props) => {
  const [commentCount, setCommentCount] = useState(answer.comments.length)
  const comments = useSelector((state: RootState) =>
    answer?.id === state.answers?.current?.id
      ? state.answers?.current.comments
      : undefined
  )

  useEffect(() => {
    comments && setCommentCount(comments?.length)
  }, [comments])

  return commentCount > 0 ? (
    <View testID="answer-comment-count">
      <CommentCount value={commentCount} type="comment" />
    </View>
  ) : (
    <></>
  )
}

export default AnswerCommentCount
