import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import LikeButton from '~/components/common/atoms/socials/LikeButton'
import Post from '~/interfaces/Post'
import { RootState } from '~/rootReducer'
import { setPost } from '~/slices/career/posts'

type Props = {
  post: Post
  showIcon?: boolean
  size?: 'medium' | 'small'
}

const PostLikeButton: React.FC<Props> = ({
  size = 'medium',
  post,
  showIcon,
}: Props) => {
  const dispatch = useDispatch()
  const [likeCount, setLikeCount] = useState(post.likeCount)
  const [isLiking, setIsLiking] = useState(false)

  const updateLike = async (): Promise<void> => {
    const liked = post.liked
    setIsLiking(true)
    try {
      liked
        ? await api.likes.unlike({ postId: post.id })
        : await api.likes.like({ postId: post.id })
      const newLikeCount = liked ? likeCount - 1 : likeCount + 1
      setLikeCount(newLikeCount)
      const postData = {
        id: post.id,
        likeCount: newLikeCount,
        liked: !liked,
      }
      dispatch(setPost(postData))
      setIsLiking(false)
      post.liked = !liked
      post.likeCount = newLikeCount
    } catch (error) {
      setIsLiking(false)
    }
  }

  const postLiked = useSelector((state: RootState) =>
    state.posts?.current?.id === post.id
      ? state.posts.current?.liked
      : undefined
  )

  return (
    <View style={styles.container} testID="post-like-button">
      <LikeButton
        size={size}
        disabed={isLiking}
        onPress={updateLike}
        isShowIcon={showIcon}
        liked={postLiked ?? post.liked}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default PostLikeButton
