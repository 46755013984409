import { isEmpty } from 'lodash'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import CreateMessage from '~/components/career/atoms/messages/CreateMessage'
import MoreHorizRoundIcon from '~/components/common/atoms/MoreHorizRoundIcon'
import SearchInput from '~/components/common/atoms/SearchInput'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import ChatThread from '~/interfaces/ChatThread'

enum MessageControlMenuType {
  MARK_AS_READ = 'mark-as-read',
  MARK_AS_UNREAD = 'mark-as-unread',
  DELETE = 'delete',
  MANAGER = 'manager',
}

type Props = {
  onPressCreateMessage?: () => void
  onSearch?: (keyword: string) => void
  selectedThreads: ChatThread[]
  onReloadChatThread: (chatThread: ChatThread) => void
  onChange: (chatThreads: ChatThread[]) => void
  showCheckboxThread?: (isShow: boolean) => void
  filterSection?: React.ReactNode
}

const InboxColumnHeader: React.FC<Props> = ({
  onPressCreateMessage,
  onSearch,
  selectedThreads,
  onReloadChatThread,
  onChange,
  showCheckboxThread,
  filterSection,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [keyword, setKeyword] = useState<string>('')
  const [isManagerMessageSelected, setIsManagerMessageSelected] = useState(
    false
  )

  const search = () => {
    if (!onSearch) return
    onSearch(keyword.trim())
  }

  const data: ControlMenuData[] = !isManagerMessageSelected
    ? [
        {
          id: MessageControlMenuType.MANAGER,
          title: t('message.menu.managerMessages'),
        },
      ]
    : [
        {
          id: MessageControlMenuType.MARK_AS_UNREAD,
          title: t('message.menu.markAsUnread'),
        },
        {
          id: MessageControlMenuType.DELETE,
          title: t('message.menu.deleteThread'),
        },
      ]

  const onSelectMessageMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case MessageControlMenuType.MARK_AS_READ:
        markThreads({ isUnread: false })
        break
      case MessageControlMenuType.MARK_AS_UNREAD:
        markThreads({ isUnread: true })
        break
      case MessageControlMenuType.DELETE:
        setIsDeleteDialogVisible(true)
        break
      case MessageControlMenuType.MANAGER:
        setIsManagerMessageSelected(true)
        showCheckboxThread && showCheckboxThread(true)
        break
      default:
    }
  }

  const deleteThreads = useCallback(async () => {
    if (isEmpty(selectedThreads)) return
    try {
      selectedThreads.map(async (chatThread: ChatThread) => {
        await api.chatThreads.delete(chatThread.id)
        onReloadChatThread(chatThread)
      })
    } catch (e) {
      toast.showError(e)
    }
  }, [selectedThreads])

  const markThreads = async ({
    isUnread,
  }: {
    isUnread?: boolean
  }): Promise<void> => {
    if (isEmpty(selectedThreads)) return
    try {
      const listThreads = await Promise.all(
        selectedThreads.map((thread) => {
          return isUnread
            ? api.chatThreads.markAsUnread(thread.id)
            : api.chatThreads.markAsRead(thread.id)
        })
      )
      onChange(listThreads)
    } catch (error) {
      toast.showError(error)
    }
  }

  return (
    <>
      <View style={styles.container} testID="inbox-column-header">
        <View style={styles.leftColumn}>
          <View>
            <View style={styles.messageContainer}>
              <Text style={[commonStyles.titleTextStyle, styles.title]}>
                {t('message.messages')}
              </Text>
              <ControlMenu
                data={data}
                menuTrigger={<MoreHorizRoundIcon />}
                onSelect={onSelectMessageMenu}
                preferredPlacement={'left'}
              />
              <CreateMessage onPress={onPressCreateMessage} />
            </View>
            <SearchInput
              value={keyword}
              onSubmit={search}
              onChangeText={setKeyword}
              placeholderKey="message.search"
            />
          </View>
          {filterSection && (
            <View style={styles.messageContainer}>{filterSection}</View>
          )}
        </View>
      </View>
      <View style={styles.separator} />
      <ConfirmationDialog
        onPressAccept={(): void => {
          setIsDeleteDialogVisible(false)
          deleteThreads()
        }}
        isOnlyAccept={false}
        isDanger
        acceptLabel={t('ok')}
        visible={isDeleteDialogVisible}
        message={t('message.deleteThreadConfirmMessage')}
        onPressCancel={(): void => setIsDeleteDialogVisible(false)}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 110,
    backgroundColor: color.white,
  },
  leftColumn: {
    backgroundColor: color.white,
    width: '100%',
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 5,
  },
  separator: {
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    ...commonStyles.shadow,
    marginHorizontal: 30,
  },
  title: {
    flex: 1,
    marginBottom: 4,
    paddingTop: 5,
  },
})

export default InboxColumnHeader
