import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import LikeIcon from '~/components/common/atoms/socials/LikeIcon'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  value?: number
  onPress?: () => void
}

const LikeCount: React.FC<Props> = ({ onPress, value }: Props) => (
  <View style={styles.container} testID="like-count">
    <LikeIcon onPress={onPress} isRound={true} />
    <TouchableOpacity onPress={onPress} style={styles.likeCountValueContainer}>
      {/* TODO: if value >= 1000, format me: 1k, 1k2, etc. */}
      <Text testID="like-count-value" style={styles.likeCountValue}>
        {value}
      </Text>
    </TouchableOpacity>
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  likeCountValue: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  likeCountValueContainer: {
    marginLeft: 4,
  },
})

export default LikeCount
