import { find } from 'lodash'
import React from 'react'
import { StyleSheet, TextStyle, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { TemplateType } from '~/interfaces/Resume'
import ResumeLanguage from '~/interfaces/ResumeLanguage'
import i18n from '~/plugins/i18n'
import { getLanguageLevels, getLanguages } from '~/utils/common/languagePickers'

type Props = {
  templateType: TemplateType
  language: Partial<ResumeLanguage>
}

const ResumeLanguageItem: React.FC<Props> = ({
  language,
  templateType,
}: Props) => {
  const titleStyle = {
    enjin: { ...baseTitleFontStyle, color: color.black },
    shades: { ...baseTitleFontStyle, color: color.white },
    formal: { ...baseTitleFontStyle, color: color.white },
    minimal: { ...baseTitleFontStyle, color: color.black },
  }

  const subtitleStyle = {
    enjin: { color: color.black },
    shades: { color: color.white },
    formal: { color: color.white },
    minimal: { color: color.black },
  }

  return (
    <View style={styles.container} testID="resume-language-item">
      <Text style={titleStyle[templateType] as TextStyle}>
        {find(getLanguages(i18n.language), { value: language.name })?.label}
      </Text>
      <Text style={subtitleStyle[templateType]}>
        {
          find(getLanguageLevels(i18n.language), { value: language.level })
            ?.label
        }
      </Text>
    </View>
  )
}

const baseTitleFontStyle = {
  fontWeight: 'bold',
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
})

export default ResumeLanguageItem
