import { getLinkPreview } from 'link-preview-js'

import URLInspection from '~/interfaces/URLInspection'

interface LinkPreview {
  url: string
  title: string
  siteName: string
  description: string
  mediaType: string
  contentType: string
  images: string[]
  videos: string[]
  favicons: string[]
}

export const fetchPreview = async (url: string): Promise<URLInspection> => {
  try {
    const data = (await getLinkPreview(url)) as LinkPreview
    const urlInspection: URLInspection = {
      url: data.url,
      title: data.title,
      description: data.description,
    }
    if (data.images.length > 0) {
      urlInspection.image = { url: data.images[0] }
    }
    return urlInspection
  } catch (_e) {
    return {
      url,
    }
  }
}
