import { useFocusEffect } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import ThreadNameEditDialog from '../molecules/message/ThreadNameEditDialog'

import api, { API } from '~/api'
import { ChatMessageRequestBody } from '~/api/chatMessages'
import {
  ChatThreadQueryParams,
  ChatThreadRequestBody,
  MessagesQueryParams,
} from '~/api/chatThreads'
import ChatColumn from '~/components/career/features/messages/ChatColumn'
import InboxColumnHeader from '~/components/career/molecules/message/InboxColumnHeader'
import MessageFilter, {
  MessageFilterMenuType,
} from '~/components/career/molecules/message/MessageFilter'
import MessageRecipientFilter from '~/components/career/molecules/message/MessageRecipientFilter'
import NewThreadMessage from '~/components/career/molecules/message/NewThreadMessage'
import ThreadMessageAction from '~/components/career/molecules/message/ThreadMessageAction'
import InboxColumn from '~/components/career/organisms/messages/InboxColumn'
import CancelButton from '~/components/common/atoms/CancelButton'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import UsersFilter from '~/components/common/features/UsersFilter'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { RootContext } from '~/contexts/RootContext'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import useStreamSubscription from '~/hooks/useStreamSubscription'
import ChatNotification from '~/interfaces/ChatNotification'
import ChatThread from '~/interfaces/ChatThread'
import Message from '~/interfaces/Message'
import User from '~/interfaces/User'
import { currentUserSelector } from '~/slices/common/users'
import {
  markAsRead,
  updateThreadsFromNotification,
} from '~/utils/career/chatThread'
import { removeItem, updateItem } from '~/utils/common/array'
import { getRouteParams, navigate } from '~/utils/navigation'

const MessagesTemplate: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const paramsFromRoute = getRouteParams<MessagesQueryParams>()
  const { isPC, deviceType, stream } = useContext(RootContext)
  const toast = useCustomToast()
  const [selectedThread, setSelectedThread] = useState<ChatThread | undefined>(
    undefined
  )
  const currentUser = useSelector(currentUserSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [threadActionSelected, setThreadActionSelected] = useState<
    ChatThread | undefined
  >(undefined)
  const [isShowThreadNameDialog, setIsShowThreadNameDialog] = useState(false)
  const [chatThreads, setChatThreads] = useState<ChatThread[]>([])
  const [chatThreadFilter, setChatThreadFilter] = useState<
    ChatThreadQueryParams
  >()
  const [selectedThreads, setSelectedThreads] = useState<ChatThread[]>([])
  const [isShowCheckboxThreads, setIsShowCheckboxThreads] = useState(false)
  const bodyStyle: ViewStyle = StyleSheet.flatten([
    styles.body,
    isPC
      ? {
          marginTop: 35,
          width: '100%',
          alignSelf: 'center',
          justifyContent: 'center',
        }
      : {},
  ])
  const [isShowUsersDialog, setIsShowUsersDialog] = useState(false)
  const [recipients, setRecipients] = useState<User[]>([])
  const selectCurrentChatThread = (listThread: ChatThread[]): void => {
    if (paramsFromRoute.id) {
      const currentThread = listThread.find((x) => x.id === paramsFromRoute.id)
      if (currentThread) {
        setSelectedThread(currentThread)
      }
    }
  }
  const fetchChatThreads = useAPI(
    async (
      api: API,
      queryParams: ChatThreadQueryParams = {}
    ): Promise<void> => {
      if (isLoading) return
      setIsLoading(true)
      try {
        const response = await api.chatThreads.index<
          ChatThread[],
          ChatThreadQueryParams
        >(queryParams)
        const uniqueItems = response?.filter((item, id) => {
          return id == response.findIndex((element) => element.id === item.id)
        })
        setChatThreads(uniqueItems)
        selectCurrentChatThread(response)
      } catch (e) {
        toast.showError(e)
      }
      api.isAlive && setIsLoading(false)
    },
    []
  )

  const createNewThread = useCallback(
    async (firstMessage: string): Promise<undefined> => {
      if (!selectedThread) return
      try {
        const userIds = selectedThread.users.map((item) => item.id)
        // Create a new thread
        const newThread = await api.chatThreads.create<
          ChatThreadRequestBody,
          ChatThread
        >({
          chatThread: {
            userIds,
          },
        })
        // Send first message to the newly created thread
        await api.chatMessages
          .build(newThread.id)
          .create<ChatMessageRequestBody, Message>({
            chatMessage: {
              body: firstMessage,
              userIds,
            },
          })
        // Sync the current thread list with the new thread one
        setChatThreads((prevState) => {
          if (prevState.some((item) => item.id === newThread.id)) {
            return prevState
          }
          // Prepend the new thread to the current thread list
          return [newThread, ...prevState]
        })
        // Set the newly created thread to be active
        setSelectedThread(newThread)
        navigate('messages', { id: newThread.id })
      } catch (e) {
        toast.showError(e)
      }
    },
    [selectedThread]
  )

  const deleteThread = useCallback(async () => {
    if (!threadActionSelected) return
    try {
      await api.chatThreads.delete(threadActionSelected.id)
      setChatThreads((prevState) =>
        prevState.filter((item) => item.id !== threadActionSelected.id)
      )
      initChatThread()
    } catch (e) {
      toast.showError(e)
    }
  }, [threadActionSelected])

  const archiveThread = async (threadId: number) => {
    if (!threadId) return

    try {
      await api.chatThreads.archiveThread(threadId)
      setChatThreads((prevState) =>
        prevState.filter((item) => item.id !== threadId)
      )
      initChatThread()
    } catch (e) {
      toast.showError(e)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      isPC && initChatThread()
      fetchChatThreads(chatThreadFilter)
    }, [fetchChatThreads, chatThreadFilter])
  )

  useEffect(() => {
    if (!paramsFromRoute.id && !isPC) {
      setSelectedThread(undefined)
    }
  }, [paramsFromRoute.id])

  const initChatThread = (): void => {
    setSelectedThread({ type: 'user' } as ChatThread)
  }

  const createNewMessage = (): void => {
    initChatThread()
    navigate('messages')
  }

  const updateThread = (thread: ChatThread): void => {
    const newChatThreads = updateItem(chatThreads, thread)
    setChatThreads(newChatThreads)
  }

  const updateSelectedThread = (thread: ChatThread): void => {
    navigate('messages', { id: thread.id })
    updateThread(thread)

    selectedThread?.id === thread.id
      ? setSelectedThread({ ...selectedThread, ...thread })
      : setSelectedThread(thread)
  }

  const updateSelectedThreads = (threads: ChatThread[]): void => {
    let listThreads: ChatThread[] = chatThreads
    threads.map((thread) => {
      navigate('messages', { id: thread.id })
      listThreads = listThreads.map((listThread) => {
        return listThread.id === thread.id
          ? { ...listThread, ...thread }
          : listThread
      })
    })
    setChatThreads(listThreads)
  }

  const updateRecipients = (): void => {
    setSelectedThread({ ...selectedThread, users: recipients } as ChatThread)
    setIsShowUsersDialog(false)
  }

  const openSelectRecipientsDialog = (): void => {
    setRecipients(selectedThread?.users ?? [])
    setIsShowUsersDialog(true)
  }

  const cancelSelectRecipients = (): void => {
    setRecipients([])
    setIsShowUsersDialog(false)
  }

  const removeRecipient = (recipient: User): void => {
    const newRecipients = removeItem(recipients, recipient)
    setRecipients(newRecipients)
    setSelectedThread({
      ...selectedThread,
      users: newRecipients,
    } as ChatThread)
  }

  const mixin = useMemo(
    () => ({
      received(data: ChatNotification) {
        const response = camelcaseKeys(data, { deep: true })
        setChatThreads((chatThreads) =>
          updateThreadsFromNotification(chatThreads, response)
        )
        selectedThread?.id &&
          response.threadId === selectedThread.id &&
          markAsRead(selectedThread.id)
      },
    }),
    [selectedThread?.id, dispatch, setChatThreads]
  )

  useStreamSubscription({
    stream,
    channelName: 'ChatNotificationChannel',
    mixin,
    deps: [selectedThread?.id, chatThreads, dispatch, setChatThreads],
  })

  const submitSearch = async (keyword: string) => {
    setChatThreadFilter((prevState) => ({ ...prevState, q: keyword }))
  }

  const submitMessageTypeFilter = (filter: MessageFilterMenuType) => {
    setChatThreadFilter((prevState) => ({
      ...prevState,
      type: filter === MessageFilterMenuType.All ? undefined : filter,
    }))
  }

  const submitRecipientFilter = (isCompany?: boolean) => {
    setChatThreadFilter((prevState) => ({
      ...prevState,
      isCompany: isCompany === false ? '0' : isCompany ? '1' : undefined,
    }))
  }

  const chatThreadAction: React.ReactElement | undefined = selectedThread ? (
    <ThreadMessageAction
      chatThread={selectedThread}
      currentUser={currentUser}
      newThreadMessage={
        <NewThreadMessage
          chatThread={selectedThread}
          onPress={openSelectRecipientsDialog}
          onPressRemoveRecipient={removeRecipient}
        />
      }
      onChange={updateSelectedThread}
      renameThread={() => {
        setThreadActionSelected(selectedThread)
        setIsShowThreadNameDialog(true)
      }}
      deleteThread={() => {
        setThreadActionSelected(selectedThread)
        setIsDeleteDialogVisible(true)
      }}
      archiveThread={() => {
        archiveThread(selectedThread.id)
      }}
    />
  ) : undefined

  const chatThreadChecked = (chatThread: ChatThread) => {
    setSelectedThreads((prevState) => {
      if (prevState.some((item) => item.id === chatThread.id)) {
        return prevState.filter((item) => item.id !== chatThread.id)
      } else {
        prevState.push(chatThread)
      }
      return [...prevState]
    })
  }

  const onReloadChatThread = (chatThread: ChatThread) => {
    setChatThreads((prevState) =>
      prevState.filter((item) => item.id !== chatThread.id)
    )
    setSelectedThreads((prevState) =>
      prevState.filter((item) => item.id !== chatThread.id)
    )
    initChatThread()
  }

  const showCheckboxThread = (isShow: boolean) => {
    setIsShowCheckboxThreads(isShow)
  }

  const messageFilterSection = (
    <>
      <MessageFilter onSelect={submitMessageTypeFilter} />
      <View style={styles.filterGap} />
      <MessageRecipientFilter
        onFilter={submitRecipientFilter}
        isCompanyAccount={currentUser?.isCompanyAccount}
      />
    </>
  )

  return (
    <View testID="message-template" style={styles.body}>
      {isPC ? (
        <View testID="message-template-content" style={styles.container}>
          <View style={bodyStyle} testID="message-template-body">
            <InboxColumn
              chatThreads={chatThreads}
              onSelect={updateSelectedThread}
              selectedChatThread={selectedThread}
              onChange={updateThread}
              deleteThread={(thread: ChatThread) => {
                setThreadActionSelected(thread)
                setIsDeleteDialogVisible(true)
              }}
              renameThread={(thread: ChatThread) => {
                setThreadActionSelected(thread)
                setIsShowThreadNameDialog(true)
              }}
              archiveThread={(thread: ChatThread) => {
                archiveThread(thread.id)
              }}
              header={
                <InboxColumnHeader
                  onSearch={submitSearch}
                  onPressCreateMessage={createNewMessage}
                  selectedThreads={selectedThreads}
                  onReloadChatThread={onReloadChatThread}
                  onChange={updateSelectedThreads}
                  showCheckboxThread={showCheckboxThread}
                  filterSection={messageFilterSection}
                />
              }
              onPress={chatThreadChecked}
              isShowCheckboxThreads={isShowCheckboxThreads}
            />
            {selectedThread && (
              <ChatColumn
                selectedChatThread={selectedThread}
                threadMessageAction={chatThreadAction}
                createNewThread={createNewThread}
              />
            )}
          </View>
        </View>
      ) : (
        <View style={styles.container}>
          {selectedThread ? (
            <ChatColumn
              selectedChatThread={selectedThread}
              threadMessageAction={chatThreadAction}
              createNewThread={createNewThread}
            />
          ) : (
            <View testID="inbox-container">
              <InboxColumn
                chatThreads={chatThreads}
                renameThread={(thread: ChatThread) => {
                  setThreadActionSelected(thread)
                  setIsShowThreadNameDialog(true)
                }}
                onChange={updateThread}
                deleteThread={(thread: ChatThread) => {
                  setThreadActionSelected(thread)
                  setIsDeleteDialogVisible(true)
                }}
                archiveThread={(thread: ChatThread) => {
                  archiveThread(thread.id)
                }}
                onSelect={updateSelectedThread}
                selectedChatThread={selectedThread}
                header={
                  <InboxColumnHeader
                    onSearch={submitSearch}
                    onPressCreateMessage={createNewMessage}
                    selectedThreads={selectedThreads}
                    onReloadChatThread={onReloadChatThread}
                    onChange={updateSelectedThreads}
                    showCheckboxThread={showCheckboxThread}
                    filterSection={messageFilterSection}
                  />
                }
                onPress={chatThreadChecked}
                isShowCheckboxThreads={isShowCheckboxThreads}
              />
            </View>
          )}
        </View>
      )}
      <ModalContainer
        visible={isShowUsersDialog}
        onDismiss={(): void => setIsShowUsersDialog(false)}
      >
        <View
          testID="users-filter-dialog"
          style={usersDialogStyle[deviceType!] as ViewStyle}
        >
          <UsersFilter
            onChange={setRecipients}
            selectedUsers={recipients}
            title={t('message.searchRecipientsPlaceholder')}
          />
          <View style={styles.separator} />
          <HorizontalContainer>
            <Button
              title={t('done')}
              onPress={updateRecipients}
              style={styles.usersFilterButton}
              testID="users-filter-done-button"
              titleStyle={commonStyles.bodyTextSize}
            />
            <CancelButton onPress={cancelSelectRecipients} />
          </HorizontalContainer>
        </View>
      </ModalContainer>
      <ConfirmationDialog
        onPressAccept={(): void => {
          setIsDeleteDialogVisible(false)
          deleteThread()
        }}
        isOnlyAccept={false}
        isDanger
        acceptLabel={t('ok')}
        visible={isDeleteDialogVisible}
        message={t('message.deleteThreadConfirmMessage')}
        onPressCancel={(): void => setIsDeleteDialogVisible(false)}
      />
      <ThreadNameEditDialog
        onUpdate={(thread: ChatThread) => {
          if (selectedThread?.id === threadActionSelected?.id) {
            updateSelectedThread(thread)
          } else {
            updateThread(thread)
          }
        }}
        visible={isShowThreadNameDialog}
        chatThread={threadActionSelected}
        onFinish={(): void => setIsShowThreadNameDialog(false)}
      />
    </View>
  )
}

const usersDialogStyle = {
  pc: {
    ...commonStyles.borderRadiusAllCorner,
    backgroundColor: color.white,
    height: 635,
    padding: 40,
    width: 500,
    alignItems: 'center',
  },
  mobile: {
    ...commonStyles.borderRadiusAllCorner,
    backgroundColor: color.white,
    height: '100%',
    maxHeight: 600,
    maxWidth: 375,
    padding: 10,
    width: '100%',
  },
  tablet: {
    backgroundColor: color.white,
    height: 635,
    padding: 40,
    width: 500,
    alignItems: 'center',
  },
}

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    flex: 1,
  },
  container: {
    flex: 1,
  },
  filterGap: {
    width: 15,
  },
  separator: {
    marginTop: 20,
  },
  usersFilterButton: {
    marginHorizontal: 15,
    width: 80,
  },
})

export default MessagesTemplate
