import { useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import DefaultLayout from '~/components/DefaultLayout'
import UserTemplate from '~/components/forum/templates/UserTemplate'
import UserType from '~/interfaces/User'
import UserModel from '~/interfaces/User'
import { currentUserSelector } from '~/slices/common/users'
import { RouteParamsID } from '~/utils/navigation'

type UserHook = {
  user: UserModel | undefined
  setUser: (user: UserModel | undefined) => void
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
}

const useUser = (): UserHook => {
  const [user, setUser] = useState<UserModel | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  return { user, setUser, isLoading, setLoading }
}

const ForumUser: React.FC = () => {
  const route = useRoute()
  const userId = (route?.params as RouteParamsID)?.id
  const currentUser = useSelector(currentUserSelector)
  const { user, setUser, isLoading, setLoading } = useUser()

  useEffect(() => {
    if (userId == null || userId === currentUser?.id) {
      setUser(currentUser)
      return
    }
    const api = new API()

    const fetchUser = async (userId: number): Promise<void> => {
      try {
        setLoading(true)
        const result = await api.users.show<UserType>(userId)
        setUser(result)
        // TODO: catch error
      } finally {
        if (api.isAlive) {
          setLoading(false)
        }
      }
    }
    fetchUser(userId)
    return api.abort
  }, [userId, setLoading, setUser])

  return isLoading ? (
    <></>
  ) : (
    <DefaultLayout>
      <UserTemplate user={user} isLoading={isLoading} />
    </DefaultLayout>
  )
}

export default ForumUser
