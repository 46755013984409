import { first, isEqual, isNil } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import DefaultLayout from '~/components/DefaultLayout'
import ExpirationDialog from '~/components/common/molecules/subscriptions/ExpirationDialog'
import Footer from '~/components/common/organisms/Footer'
import PricingCardTemplate from '~/components/common/templates/PricingCardTemplate'
import SecureCheckout from '~/components/common/templates/subscriptions/SecureCheckoutTemplate'
import color from '~/constants/common/color'
import StripeContext from '~/contexts/StripeContext'
import useCustomToast from '~/hooks/useCustomToast'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { currentUserSelector, fetchCurrentUser } from '~/slices/common/users'

const Plan: React.FC = () => {
  const toast = useCustomToast()
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isShowExpirationDialog, setIsShowExpirationDialog] = useState(false)
  const isExpired =
    useSelector(currentUserSelector)?.subscription?.expired ?? false
  const [plans, setPlans] = useState<SubscriptionPlan[]>()
  const scrollRef = useRef<ScrollView>(null)
  const dispatch = useDispatch()

  const changeToFreeSubscription = async () => {
    if (isLoading) {
      return
    }
    const freePlan = first(plans?.filter((item) => isEqual(0, item.price)))

    if (isNil(freePlan)) {
      return
    }

    setIsLoading(true)

    try {
      await api.subscriptionPlans.switchFree({
        planId: freePlan?.id,
      })
      setIsShowExpirationDialog(false)
      dispatch(fetchCurrentUser())
    } catch (error) {
      toast.showError(`Error processing subscription: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsShowExpirationDialog(isExpired)
  }, [isExpired])

  useEffect(() => {
    ;() => setSelectedPlan(null)
  }, [])

  return (
    <DefaultLayout>
      <View style={styles.container}>
        <ScrollView ref={scrollRef}>
          <StripeContext>
            <View style={styles.content} testID="plan">
              {isNil(selectedPlan) ? (
                <PricingCardTemplate
                  onLoadPlans={setPlans}
                  onSelectPlan={setSelectedPlan}
                />
              ) : (
                <SecureCheckout
                  selectedPlan={selectedPlan}
                  changePlan={() => setSelectedPlan(null)}
                />
              )}
              <View style={styles.footer}>
                <Footer />
              </View>
            </View>
          </StripeContext>
        </ScrollView>
        <View
          style={styles.expirationDialog}
          testID="expiration-dialog-container"
        >
          <ExpirationDialog
            visible={isShowExpirationDialog}
            onPressOk={changeToFreeSubscription}
            onPressUpgrade={() => setIsShowExpirationDialog(false)}
          />
        </View>
      </View>
    </DefaultLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  content: {
    alignItems: 'center',
    flex: 1,
    paddingVertical: 40,
    justifyContent: 'center',
  },
  expirationDialog: {
    flex: 1,
    backgroundColor: color.mask,
  },
  footer: { width: '100%' },
})

export default Plan
