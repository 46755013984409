import React from 'react'
import { View, StyleSheet } from 'react-native'

import DateText from '~/components/common/atoms/DateText'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

type Props = {
  user?: User
  date: string
}

const PostItemTitle: React.FC<Props> = ({ user, date }: Props) => (
  <View style={styles.container} testID="post-item-title">
    <UserAvatar user={user} size={ProfileAvatarSize.SMALL} />
    <View style={styles.title}>
      <UserName size="medium" user={user} />
      <DateText date={date} fontSize={FontSize.SUB} />
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  title: {
    alignSelf: 'baseline',
    flex: 1,
    marginLeft: 10,
    paddingVertical: 4,
  },
})

export default PostItemTitle
