import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import { CommunityChannelRequest } from '~/api/communities/communityChannels'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import HorizontalSwitch from '~/components/common/molecules/HorizontalSwitch'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityChannel from '~/interfaces/CommunityChannel'
import { RootState } from '~/rootReducer'
import {
  addChannel,
  removeChannel,
  updateChannel,
} from '~/slices/community/community'

type Props = {
  isEdit?: boolean
  onBack: () => void
  communityId: number
}

const ChannelEditForm: React.FC<Props> = ({
  onBack,
  isEdit,
  communityId,
}: Props) => {
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [isValidData, setIsValidData] = useState(false)
  const channel = useSelector(
    (state: RootState) => state.community.channel.current
  )
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [channelData, setChannelData] = useState<Partial<CommunityChannel>>(
    isEdit ? (channel as Partial<CommunityChannel>) : { isPublic: true } //Default is public
  )

  const createCommunityChannel = async (
    communityId: number,
    request: CommunityChannelRequest
  ): Promise<void> => {
    try {
      const response = await api.communityChannel
        .configPath(communityId)
        .create<CommunityChannelRequest, CommunityChannel>(request)
      dispatch(addChannel(response))
      onBack()
    } catch (error) {
      toast.showError(error)
    }
  }

  const updateCurrentChannel = (request: CommunityChannelRequest): void => {
    Promise.all([
      dispatch(updateChannel(communityId, channel?.id || 0, request)),
    ])
      .then(() => onBack())
      .catch((error) => {
        toast.showError(error)
      })
  }

  const submit = (): void => {
    const communityChannel = channelData as CommunityChannel
    isEdit
      ? updateCurrentChannel({ communityChannel })
      : createCommunityChannel(communityId, { communityChannel })
  }

  const deleteChannel = async (): Promise<void> => {
    if (!channel?.id) {
      return
    }
    try {
      await api.communityChannel.configPath(communityId).delete(channel.id)
      dispatch(removeChannel(channel.id))
      setIsShowConfirmDialog(false)
      onBack()
    } catch (error) {
      toast.showError(error)
    }
  }

  const updateChannelData = (data: Partial<CommunityChannel>): void => {
    setChannelData({ ...channelData, ...data })
  }

  useEffect(() => {
    setIsValidData(channelData.name ? true : false)
  }, [channelData.name])

  return (
    <View style={styles.container} testID="channel-edit-form">
      <>
        <Text testID="channel-form-header" style={styles.header}>
          {isEdit
            ? t('community.channel.updateChannel')
            : t('community.channel.createChannel')}
        </Text>
        <View style={styles.verticalSpace} />
        <TextField
          maxLength={80}
          value={channelData.name}
          title={t('community.channel.name')}
          onChangeText={(value): void => updateChannelData({ name: value })}
        />
        <View style={styles.verticalSpace} />
        <HorizontalSwitch
          value={!channelData.isPublic}
          onValueChange={(value): void =>
            updateChannelData({ isPublic: !value })
          }
          title={t('community.channel.makePrivate')}
          label={t('community.channel.makePrivateInfo')}
        />
        <View style={styles.verticalSpace} />
        <TextField
          value={channelData.description}
          maxLength={150}
          numberOfLines={3}
          title={t('community.channel.description')}
          onChangeText={(value): void =>
            updateChannelData({ description: value })
          }
          placeholder={t('community.channel.descriptionPlaceholder')}
        />
        <Text style={styles.description}>
          {t('community.channel.descriptionInfo')}
        </Text>
        <View style={styles.verticalSpace} />
        {isEdit && (
          <>
            <TouchableOpacity
              testID="delete-channel"
              onPress={(): void => setIsShowConfirmDialog(true)}
            >
              <Text style={styles.deleteChannelTitle}>
                {t('community.channel.deleteChannel')}
              </Text>
            </TouchableOpacity>
            <View style={styles.verticalSpace} />
          </>
        )}
        <Button
          onPress={submit}
          disabled={!isValidData}
          testID="channel-edit-form-submit"
          title={
            isEdit
              ? t('community.channel.updateChannel')
              : t('community.channel.createChannel')
          }
        />
      </>
      {isShowConfirmDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('delete')}
          onPressAccept={deleteChannel}
          visible={isShowConfirmDialog}
          onPressCancel={(): void => setIsShowConfirmDialog(false)}
          message={t('community.channel.deleteChannelConfirmMessage')}
        />
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 50,
  },
  deleteChannelTitle: {
    color: color.badgeColor,
    textDecorationLine: 'underline',
  },
  description: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  header: {
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
  verticalSpace: {
    height: 16,
  },
})

export default ChannelEditForm
