import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native'

import TextWithIcon from '~/components/common/atoms/TextWithIcon'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Resume from '~/interfaces/Resume'

type Props = {
  resume: Partial<Resume>
}

const ContactGroup: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()
  const templateType = resume.templateType!

  const textColors = {
    enjin: { color: color.black },
    shades: { color: color.white },
    formal: { color: color.black },
    minimal: { color: color.white },
  }

  const title = {
    enjin: t('profile.contact'),
    shades: t('profile.contact'),
    formal: '',
    minimal: '',
  }

  const basTitleStyle = {
    ...textColors[templateType],
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  }

  const titleStyle = {
    enjin: basTitleStyle,
    shades: { ...basTitleStyle, textDecorationLine: 'underline' },
    formal: basTitleStyle,
    minimal: basTitleStyle,
  }

  const bodyContainerStyle = {
    enjin: undefined,
    shades: undefined,
    formal: undefined,
    minimal: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }

  const itemIcon = {
    enjin: (
      <Icon
        name="circle"
        size={14}
        color={color.secondary}
        style={styles.icon}
      />
    ),
    shades: (
      <Icon name="circle" size={14} color={color.white} style={styles.icon} />
    ),
    formal: (
      <Icon name="square" size={12} color={color.black} style={styles.icon} />
    ),
    minimal: undefined,
  }

  const separator = {
    enjin: undefined,
    shades: undefined,
    formal: undefined,
    minimal: <View style={styles.separator} />,
  }

  const containerStyle = {
    enjin: baseContainerStyle,
    shades: baseContainerStyle,
    formal: { maxWidth: 200 },
    minimal: {
      ...baseContainerStyle,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }

  return (
    <View
      testID="contact-group"
      style={containerStyle[templateType] as ViewStyle}
    >
      <Text
        style={titleStyle[templateType] as TextStyle}
        testID="contact-group-title"
      >
        {title[templateType]}
      </Text>
      <View style={bodyContainerStyle[templateType] as ViewStyle}>
        <TextWithIcon
          icon={itemIcon[templateType]}
          value={resume.phone ?? ''}
          style={textColors[templateType]}
        />
        {separator[templateType]}
        <TextWithIcon
          icon={itemIcon[templateType]}
          value={resume.email ?? ''}
          style={textColors[templateType]}
        />
        {separator[templateType]}
        <TextWithIcon
          icon={itemIcon[templateType]}
          value={resume.address ?? ''}
          style={textColors[templateType]}
        />
      </View>
      <TextWithIcon
        icon={itemIcon[templateType]}
        value={resume.webSite ?? ''}
        numberOfLines={2}
        style={textColors[templateType]}
      />
    </View>
  )
}

const baseContainerStyle = {
  flex: 1,
}
const styles = StyleSheet.create({
  icon: {
    marginRight: 6,
  },
  separator: {
    height: 14,
    borderRightColor: color.white,
    borderRightWidth: 1,
    marginHorizontal: 10,
  },
})

export default ContactGroup
