import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import jobHuntingStatusFromAssets from '~/assets/data/jobHuntingStatus.json'
import ProfileCard from '~/components/common/molecules/users/ProfileCard'
import i18n from '~/plugins/i18n'

type Props = {
  jobHuntingStatus: string[]
  currentPosition?: string
}

const StatusOverview: React.FC<Props> = ({ jobHuntingStatus }: Props) => {
  const { t } = useTranslation()

  const jobStatus = jobHuntingStatusFromAssets.reduce(
    (result: string[], item) => {
      if (jobHuntingStatus.includes(item.id)) {
        result.push(i18n.language === 'ja' ? item.name : item.en_name)
      }

      return result
    },
    []
  )

  return (
    <View testID="status-overview">
      <ProfileCard
        icon=""
        title={t('profile.openTo')}
        description={jobStatus.join(', ')}
      />
    </View>
  )
}

export default StatusOverview
