import { FontAwesome as Icon } from '@expo/vector-icons'
import { TFunction } from 'i18next'
import { find } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import localPrefectures from '~/assets/data/prefectures.json'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import { PickerOption } from '~/components/common/molecules/CustomPicker'
import SearchableList from '~/components/common/molecules/SearchableList'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import i18n from '~/plugins/i18n'

export const getPrefecturesData = (
  lang: string,
  t: TFunction
): PickerOption[] => {
  const dataPrefectures: PickerOption[] = localPrefectures.map((item) => {
    return {
      label: lang === 'ja' ? item.name : item.en_name,
      value: item.id,
    }
  })
  dataPrefectures.unshift({ label: t('all'), value: '' })
  return dataPrefectures
}

type Props = {
  onChange: (value: string) => void
  selected?: string
  isPC?: boolean
  size?: 'small' | 'medium' | 'large' | 'full'
  title?: string
  invertedText?: boolean
}

const LocationPicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [location, setLocation] = useState(props.selected)
  const [isShowDialog, setIsShowDialog] = useState(false)
  const locations = getPrefecturesData(i18n.language, t)
  const isPC = props.isPC
  const textColor = props.invertedText ? color.white : color.textGray

  const changeSelectedCountry = (value: string): void => {
    const selectedLocation =
      find(locations, { label: value })?.value.toString() ?? ''
    setLocation(location)
    props.onChange(selectedLocation)
    setIsShowDialog(false)
  }

  useEffect(() => {
    setLocation(props.selected)
  }, [props.selected])

  return (
    <View testID="location-picker">
      {props.title && (
        <Text
          style={StyleSheet.flatten([
            styles.title,
            { fontSize: FontSize.GENERAL },
          ])}
        >
          {props.title}
        </Text>
      )}
      <View style={styles.pickerContainer} testID="location-picker-container">
        <Button
          onPress={() => setIsShowDialog(true)}
          title={
            find(locations, { value: location })?.label ?? t('profile.location')
          }
          type="outline"
          buttonStyle={styles.button}
          testID="location-picker-button"
          titleStyle={StyleSheet.flatten([
            styles.buttonTitle,
            { color: textColor },
          ])}
          iconRight
          icon={
            <Icon
              name="angle-down"
              color={textColor}
              size={FontSize.GENERAL}
              style={styles.buttonIcon}
            />
          }
          containerStyle={styles.buttonContainer}
        />
      </View>
      <ModalContainer
        visible={isShowDialog}
        onDismiss={() => setIsShowDialog(false)}
      >
        <View
          style={isPC ? styles.searchDialog : styles.searchDialogMobile}
          testID="location-list-container"
        >
          <SearchableList
            title={t('selectLocation')}
            data={locations.map((item) => item.label)}
            onPressItem={changeSelectedCountry}
          />
        </View>
      </ModalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderColor: color.lightGray,
    padding: 4,
  },
  buttonContainer: {
    flex: 1,
  },
  buttonIcon: {
    marginLeft: 8,
  },
  buttonTitle: {
    fontSize: FontSize.GENERAL,
  },
  pickerContainer: {
    flexDirection: 'row',
  },
  searchDialog: {
    padding: 20,
    backgroundColor: color.white,
    width: 420,
    height: 500,
    ...commonStyles.borderRadiusAllCorner,
  },
  searchDialogMobile: {
    padding: 10,
    backgroundColor: color.white,
    width: 300,
    height: 400,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    color: color.darkTitleText,
    fontWeight: 'normal',
    marginBottom: 4,
  },
})

export default LocationPicker
