export default {
  career: {
    path: require('~/assets/images/logo_career.png'),
  },
  forum: {
    path: require('~/assets/images/logo_forum.png'),
  },
  community: {
    path: require('~/assets/images/logo_community.png'),
  },
}
