import { includes } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import api from '~/api'
import { JoinCommunityParams } from '~/api/communities/communityMemberJoinRequests'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Community from '~/interfaces/Community'
import CommunityMemberJoinRequest from '~/interfaces/CommunityMemberJoinRequest'
import User from '~/interfaces/User'

type Props = {
  user?: User
  community?: Community
}

const JoinButton: React.FC<Props> = ({ user, community }: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isJoined, setIsJoined] = useState(false)
  const [isRequested, setIsRequested] = useState(false)

  const joinCommunity = async (): Promise<void> => {
    if (!user || !community) {
      return
    }

    try {
      setLoading(true)
      await api.communityMemberJoinRequest.create<
        JoinCommunityParams,
        CommunityMemberJoinRequest
      >({
        userId: user.id,
        communityId: community.id,
      })
      setIsRequested(true)
    } catch (error) {
      toast.showError(error)
    }
    setLoading(false)
  }

  const title = isJoined
    ? t('community.joined')
    : isRequested
    ? t('community.requested')
    : t('community.join')

  useEffect(() => {
    setIsJoined(
      includes(
        community?.communityMembers?.map((cm) => cm.user.id),
        user?.id
      )
    )
    setIsRequested(
      includes(
        community?.communityMemberJoinRequests?.map((jr) => jr.user.id),
        user?.id
      )
    )
  }, [user, community])

  return (
    <Button
      title={title}
      loading={loading}
      testID="join-button"
      onPress={joinCommunity}
      style={styles.joinButton}
      titleStyle={styles.buttonTitle}
      disabled={isJoined || isRequested}
    />
  )
}

const styles = {
  joinButton: {
    marginBottom: 6,
    maxHeight: 32,
  },
  buttonTitle: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
}

export default JoinButton
