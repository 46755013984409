import { useNavigation } from '@react-navigation/native'
import React from 'react'
import type { StyleProp, TextStyle } from 'react-native'
import RNAutolink from 'react-native-autolink'
import type {
  AutolinkProps as RNAutolinkProps,
  CustomMatch,
  ReplacerArgs,
} from 'react-native-autolink'

import color from '~/constants/common/color'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'
import type { RootStackNavigationProp } from '~/utils/navigation'

const defaultProps: Partial<RNAutolinkProps> = {
  email: true,
  phone: true,
  url: true,
  truncate: 32,
}

type AutoLinkProps = RNAutolinkProps & { style?: StyleProp<TextStyle> }

const userLinkMatcher = (
  navigation: RootStackNavigationProp,
  style: StyleProp<TextStyle>
) => ({
  pattern: /@User\[([^[]*)]\(([^)]*)\)/g,
  style: style,
  getLinkText: (replacerArgs: ReplacerArgs) => '@' + replacerArgs[1],
  onPress: (match: CustomMatch) => {
    navigation.navigate('userDetail', { id: match.getReplacerArgs()[2] })
  },
})

const AutoLink: React.FC<AutoLinkProps> = ({
  style,
  linkStyle,
  ...props
}: AutoLinkProps) => {
  const navigation = useNavigation<RootStackNavigationProp>()
  const fontFamily = getFontByLanguage(getCurrentLanguage())
  const mergedLinkStyle = [
    { fontFamily, color: color.pressableText },
    linkStyle,
  ]
  return (
    <RNAutolink
      {...Object.assign({}, defaultProps, props)}
      style={[{ fontFamily }, style]}
      linkStyle={mergedLinkStyle}
      matchers={[userLinkMatcher(navigation, mergedLinkStyle)]}
    />
  )
}

export default AutoLink
