import React from 'react'
import { TextStyle, View } from 'react-native'

import CustomCheckBox from '~/components/common/atoms/CustomCheckBox'
import color from '~/constants/common/color'
import JobHunting from '~/interfaces/JobHunting'

type Props = {
  jobHunntingItem: JobHunting
  onChange: (value: JobHunting) => void
}

const JobHuntingStatusItem: React.FC<Props> = ({
  onChange,
  jobHunntingItem,
}: Props) => (
  <View testID="job-hunting-status-item" style={styles.container}>
    <CustomCheckBox
      uncheckedIcon="square-o"
      checkedIcon="check-square-o"
      title={jobHunntingItem.title}
      checked={jobHunntingItem.checked}
      containerStyle={styles.checkBoxContainer}
      textStyle={styles.checkBoxTitle as TextStyle}
      onPress={(): void =>
        onChange({ ...jobHunntingItem, checked: !jobHunntingItem.checked })
      }
    />
  </View>
)

const styles = {
  container: {
    paddingTop: 10,
    minWidth: 180,
  },
  checkBoxContainer: {
    margin: 0,
    padding: 0,
    backgroundColor: 'none',
    borderColor: color.transparent,
  },
  checkBoxTitle: {
    fontWeight: 'normal',
  },
}

export default JobHuntingStatusItem
