import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import snakecaseKeys from 'snakecase-keys'

import CardContainer from '~/components/common/atoms/CardContainer'
import SearchInput from '~/components/common/atoms/SearchInput'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { navigationRef } from '~/utils/navigation'

type RouteParams = { q?: string }

type Props = {
  title: string
  searchInputLabel?: string
  searchInputPlaceholder?: string
  actionButton?: React.ReactElement
}

const PrimarySearchHeader: React.FC<Props> = ({
  title,
  actionButton,
  searchInputLabel,
  searchInputPlaceholder,
}: Props) => {
  const route = useRoute()
  const searchWordFromRoute =
    (camelcaseKeys(route.params ?? {}) as RouteParams).q ?? ''
  const [searchWord, setSearchWord] = useState(searchWordFromRoute)

  const submitSearch = (): void => {
    navigationRef.current?.setParams(snakecaseKeys({ q: searchWord }))
  }

  return (
    <CardContainer testID="primary-search-header">
      <View style={styles.container}>
        <View style={styles.viewContainerStyle}>
          <TitleText style={styles.title}>{title}</TitleText>
          {actionButton && (
            <View style={styles.buttonContainer} testID="action-button">
              {actionButton}
            </View>
          )}
          <SearchInput
            inverted
            value={searchWord}
            onSubmit={submitSearch}
            label={searchInputLabel}
            onChangeText={setSearchWord}
            placeholderKey={searchInputPlaceholder}
          />
        </View>
      </View>
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: 13,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.primary,
    justifyContent: 'center',
    maxHeight: 200,
    padding: 20,
  },
  title: {
    color: color.white,
    fontSize: FontSize.IMPORTANT,
    marginBottom: 13,
    textAlign: 'center',
  },
  viewContainerStyle: {
    width: '75%',
  },
})
export default PrimarySearchHeader
