import { BaseAPI } from '~/api/baseAPI'
import Like from '~/interfaces/Like'

export type LikeParams = {
  page: number
  postId: number
  perPage: number
  answerId: number
  questionId: number
}

export class LikesAPI extends BaseAPI {
  path = '/likes'

  async like(likeParams: Partial<LikeParams>): Promise<Like> {
    return await this.client.post<Like>(this.path, likeParams)
  }

  async unlike(likeParams: Partial<LikeParams>): Promise<void> {
    await this.client.delete<void>(this.path, likeParams)
  }
}
