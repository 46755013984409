import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CompanyAvatar from '~/components/career/molecules/company/CompanyAvatar'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import SearchIcon from '~/components/common/atoms/SearchIcon'
import MenuIcon from '~/components/common/molecules/MenuIcon'
import NotificationIcon from '~/components/common/molecules/NotificationIcon'
import OutsiderGuideLinks from '~/components/common/molecules/auth/OutsiderGuideLinks'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { currentCompanySelector } from '~/slices/career/company'
import { DrawerType, openDrawer } from '~/slices/common/drawer'
import { fetchNotifications } from '~/slices/common/notifications'
import { currentUserSelector } from '~/slices/common/users'
import { isCareerNotification } from '~/utils/common/notification'
import { closeDrawer } from '~/utils/navigation'

const CareerHeaderRight: React.FC = () => {
  const dispatch = useDispatch()
  const currentDrawerType = useSelector(
    (state: RootState) => state.drawer.currentDrawerType
  )
  const currentUser = useSelector(currentUserSelector)
  const company = useSelector(currentCompanySelector)
  const { isPC } = useDevice()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const isDrawerOpen = useSelector(
    (state: RootState) => state.drawer.isDrawerOpen
  )
  const showDrawer = (drawerType: DrawerType, isOpened: boolean): void => {
    if (!isOpened) {
      closeDrawer()
    } else {
      dispatch(openDrawer(drawerType))
    }
  }

  const isActiveSearchIcon =
    isDrawerOpen && currentDrawerType === DrawerType.Search

  const isActiveNotificationIcon =
    isDrawerOpen && currentDrawerType === DrawerType.Notifications

  const isActiveMenuIcon = isDrawerOpen && currentDrawerType === DrawerType.Menu

  const unreadCount = useSelector(
    (state: RootState) => state.notifications.unreadCount
  )

  useEffect(() => {
    accessToken && dispatch(fetchNotifications((n) => isCareerNotification(n)))
  }, [accessToken])

  const menuView = (
    <View style={styles.container} testID="career-header-right">
      <SearchIcon
        onPress={(): void => {
          showDrawer(DrawerType.Search, !isActiveSearchIcon)
        }}
        isActive={isActiveSearchIcon}
      />
      <NotificationIcon
        onPress={(): void => {
          showDrawer(DrawerType.Notifications, !isActiveNotificationIcon)
        }}
        isActive={isActiveNotificationIcon}
        unreadNotificationCount={unreadCount.unreadNotificationsCount}
      />
      {currentUser?.isCompanyAccount ? (
        <View style={styles.activeIcon} testID="notification-icon">
          <CompanyAvatar
            company={company}
            size={ProfileAvatarSize.MICRO}
            onPress={() => showDrawer(DrawerType.Menu, !isActiveMenuIcon)}
          />
        </View>
      ) : (
        <MenuIcon />
      )}
    </View>
  )

  if (isPC) {
    return menuView
  }

  return accessToken ? menuView : <OutsiderGuideLinks />
}

const styles = StyleSheet.create({
  activeIcon: {
    margin: 4,
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    marginRight: 20,
  },
})

export default CareerHeaderRight
