import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { Fonts, FontSize } from '~/constants/common/font'
import { getCurrentLanguage, SupportedLanguage } from '~/plugins/i18n'

type Props = {
  title: string
  description?: string
}

const JobSelectionTitle: React.FC<Props> = ({ title, description }: Props) => {
  const { t } = useTranslation()
  const selectionLabel = (
    <Text style={styles.heading} testID="selection-label">
      {t('jobs.selectA')}
    </Text>
  )

  return (
    <View testID="job-selection-title">
      <HorizontalContainer>
        {getCurrentLanguage() !== SupportedLanguage.JA && selectionLabel}
        <Text
          style={StyleSheet.flatten([
            styles.heading,
            styles.headingTargetTitle,
          ])}
        >
          {title}
        </Text>
        {getCurrentLanguage() === SupportedLanguage.JA && selectionLabel}
        {description && (
          <>
            &nbsp;
            <Text style={[styles.heading, styles.description]}>
              {description}
            </Text>
          </>
        )}
      </HorizontalContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    color: color.textGray,
  },
  heading: {
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.GENERAL,
    paddingBottom: 16,
  },
  headingTargetTitle: {
    fontWeight: 'bold',
    marginLeft: 6,
  },
})

export default JobSelectionTitle
