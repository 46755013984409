import { useFocusEffect } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import QuizPoints from '~/components/career/molecules/quiz/QuizPoints'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import FollowButton from '~/components/common/atoms/users/FollowButton'
import ResumeLink from '~/components/common/atoms/users/ResumeLink'
import ProfileUserBanner from '~/components/common/features/users/ProfileUserBanner'
// import EditableTextForm from '~/components/common/molecules/EditableTextForm'
import HeaderSectionMenu from '~/components/common/molecules/HeaderSectionMenu'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import UploadAvatarPrompt from '~/components/common/molecules/UploadAvatarPrompt'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import ProfileInfoUser from '~/components/common/molecules/users/ProfileInfoUser'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'
import { TopTabNavigatorData } from '~/components/common/organisms/TopTabNavigator'
import { UserSection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import Media from '~/interfaces/Media'
import { ProfileSectionParam } from '~/interfaces/RootStackParamList'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'
import { currentUserSelector, updateCurrentUser } from '~/slices/common/users'
import {
  getRouteParams,
  parseUserSection,
  setPageTitle,
} from '~/utils/navigation'

type Props = {
  user?: User
}

// const STATUS_MAX_LENGTH = 220

const HeaderUser: React.FC<Props> = ({ user }: Props) => {
  const section = parseUserSection(
    getRouteParams<ProfileSectionParam>().section
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const [isVisiblePrompt, setIsVisiblePrompt] = useState(false)
  const [promptMessage, setPromptMessage] = useState('')

  const dismissPrompt = () => {
    setIsVisiblePrompt(false)
  }

  const updateAvatar = (media: Media[]): void => {
    setIsVisiblePrompt(true)
    setPromptMessage(t('profile.uploadAvatarPromptMessage'))
    dispatch(updateCurrentUser({ userProfile: { imageId: media[0].id } }))
  }

  const updateBanner = (media: Media[]): void => {
    setIsVisiblePrompt(true)
    setPromptMessage(t('profile.uploadBannerPromptMessage'))
    dispatch(
      updateCurrentUser({ userProfile: { banner: media[0]?.signedId || null } })
    )
  }

  // const updateUserProfileStatus = (status: string) =>
  //   new Promise<void>((resolve, reject) => {
  //     Promise.all([dispatch(updateCurrentUser({ userProfile: { status } }))])
  //       .then(() => resolve())
  //       .catch((error) => reject(error))
  //   })

  const isMe = currentUser?.id === user?.id

  const sectionData: TopTabNavigatorData[] =
    section === UserSection.FOLLOWERS || section === UserSection.FOLLOWING
      ? [
          {
            label: t('headerTab.followers'),
            key: UserSection.FOLLOWERS,
          },
          {
            label: t('headerTab.following'),
            key: UserSection.FOLLOWING,
          },
        ]
      : [
          {
            label: t('headerTab.profile'),
            key: UserSection.INFO,
            element: undefined,
          },
          {
            label: t('headerTab.timeline'),
            key: UserSection.TIMELINE,
            element: undefined,
          },
          {
            label: t('headerTab.myQuiz'),
            key: UserSection.QUIZ,
            element: undefined,
          },
        ]

  useFocusEffect(() =>
    setPageTitle(
      i18n.language === 'ja'
        ? user?.profile?.name
        : user?.profile?.enName ?? user?.profile?.name
    )
  )

  const infoComponent = (
    <>
      <ProfileInfo
        isPC={isPC}
        avatarComponent={
          <>
            <UserAvatar
              user={user}
              viewable={true}
              editable={isMe}
              showBorder={true}
              onUpload={updateAvatar}
              size={isPC ? ProfileAvatarSize.GIGANTIC : ProfileAvatarSize.HUGE}
            />
            <UploadAvatarPrompt
              visible={isVisiblePrompt}
              onDismiss={dismissPrompt}
              promptMessage={promptMessage}
            ></UploadAvatarPrompt>
          </>
        }
        rightComponent={
          <View style={styles.rightComponentContainer}>
            <ProfileInfoUser
              user={user}
              // userHeaderStatus={
              //   <EditableTextForm
              //     value={user?.profile.status}
              //     editable={isMe}
              //     onSubmit={updateUserProfileStatus}
              //     maxLength={STATUS_MAX_LENGTH}
              //     hintText={isMe ? t('banner.noneStatus') : ''}
              //   />
              // }
            />
            {!isPC && section === UserSection.QUIZ && (
              <View style={styles.quizPointMobileContainer}>
                <QuizPoints totalPoint={25} isMe={isMe} />
              </View>
            )}
          </View>
        }
      />
      <View style={styles.followButton}>
        {!isPC && (
          <>
            {isMe ? <ResumeLink /> : <FollowButton user={user} size="small" />}
          </>
        )}
      </View>
    </>
  )

  const footerRightComponent = useMemo(() => {
    if (!isPC) return
    if (!isMe) {
      return (
        <HorizontalContainer>
          <View style={styles.separator} />
          <FollowButton user={user} size="small" />
        </HorizontalContainer>
      )
    }
    if (section === UserSection.QUIZ) {
      return (
        <View style={styles.quizPointPCContainer}>
          <QuizPoints totalPoint={25} isMe={isMe} />
        </View>
      )
    }
    return (
      <HorizontalContainer>
        <View style={styles.separator} />
        <ResumeLink />
      </HorizontalContainer>
    )
  }, [isMe, isPC, user, section])

  return (
    <View testID="header-user" style={isPC ? styles.container : undefined}>
      <ProfileHeader
        isPC={isPC}
        bannerComponent={
          <ProfileUserBanner
            user={user!}
            isMe={isMe}
            onUpdateBanner={updateBanner}
          />
        }
        infoComponent={infoComponent}
        footerLeftComponent={
          isPC ? (
            <View>
              <HeaderSectionMenu
                isPC={isPC}
                data={sectionData}
                defaultSection={UserSection.INFO}
              />
            </View>
          ) : (
            <HeaderSectionMenu
              isPC={isPC}
              data={sectionData}
              defaultSection={UserSection.INFO}
            />
          )
        }
        footerRightComponent={footerRightComponent}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 10,
    width: 814,
  },
  followButton: {
    position: 'absolute',
    right: 30,
    bottom: 0,
  },
  quizPointMobileContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  quizPointPCContainer: {
    marginTop: -90,
  },
  rightComponentContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  separator: {
    marginRight: 20,
  },
})

export default HeaderUser
