import { PostsAPI } from '~/api/posts'

export type ChannelPostQueryParams = {
  category?: string
  afterId?: number
  userId?: number
}

export class ChannelPostsAPI extends PostsAPI {
  build(communityId: number, channelId: number): ChannelPostsAPI {
    this.setBasePath(
      `/communities/${communityId}/community_channels/${channelId}/community_channel_posts`
    )
    return this
  }
}
