import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dimensions } from 'react-native'

import { RootState } from '~/rootReducer'

export type DeviceType = 'pc' | 'mobile' | 'tablet'
export const getDeviceType = (width: number): DeviceType => {
  const breakPoint = {
    tablet: 600,
    pc: 920,
  }
  if (width < breakPoint.tablet) {
    return 'mobile'
  } else if (width >= breakPoint.pc) {
    return 'pc'
  } else {
    return 'tablet'
  }
}

type DeviceState = {
  type: DeviceType
  isPC: boolean
  isMobile: boolean
  isTablet: boolean
}

const initialType = getDeviceType(Dimensions.get('window').width)
const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    type: initialType,
    isPC: initialType === 'pc',
    isMobile: initialType === 'mobile',
    isTablet: initialType === 'tablet',
  } as DeviceState,
  reducers: {
    setDeviceType(state, action: PayloadAction<DeviceType>): void {
      state.type = action.payload
      state.isPC = action.payload === 'pc'
      state.isMobile = action.payload === 'mobile'
      state.isTablet = action.payload === 'tablet'
    },
  },
})

export const { setDeviceType } = deviceSlice.actions

export default deviceSlice.reducer

export const deviceSelector = (state: RootState): DeviceState => state.device

export const deviceIsPCSelector = createSelector(
  deviceSelector,
  (device: DeviceState): boolean => device.isPC
)
export const deviceIsTabletSelector = createSelector(
  deviceSelector,
  (device: DeviceState): boolean => device.isTablet
)

export const deviceIsMobileSelector = createSelector(
  deviceSelector,
  (device: DeviceState): boolean => device.isMobile
)
