import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

import AutoLink from '~/components/atoms/common/AutoLink'
import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import URLPreview from '~/components/common/molecules/URLPreview'
import AnswerItemHeader from '~/components/forum/molecules/answers/AnswerItemHeader'
import Answer from '~/interfaces/Answer'
import { detectedURL } from '~/utils/common/string'
import { openURL } from '~/utils/navigation'

type Props = {
  answer: Answer
  answerControlMenu?: React.ReactElement
  answerCommentList?: React.ReactElement
  answerSocialActivity?: React.ReactElement
  isPC: boolean
}

const AnswerItem: React.FC<Props> = ({
  answer,
  answerCommentList,
  answerControlMenu,
  answerSocialActivity,
}: Props) => {
  const questionUrl = detectedURL(answer.body)
  const urlPreviewHandler = useCallback(openURL, [])

  return (
    <View testID="answer-item">
      <View style={styles.answerContainer}>
        <AnswerItemHeader user={answer.user} date={answer.createdAt} />
        <View style={styles.body} testID="body-container">
          <AutoLink text={answer.body} onPress={openURL} />
          {!!answer.images?.length && (
            <View style={styles.body}>
              <MediaArea entity={answer} />
            </View>
          )}
          {!isEmpty(questionUrl) && (
            <View style={styles.urlPreviewWrapper}>
              <URLPreview url={questionUrl!} onPress={urlPreviewHandler} />
            </View>
          )}
        </View>
        {answerSocialActivity}
        <View style={styles.controlMenu} testID="control-menu-container">
          {answerControlMenu}
        </View>
      </View>
      {answerCommentList}
    </View>
  )
}

const styles = StyleSheet.create({
  answerContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  body: {
    marginTop: 10,
  },
  controlMenu: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  urlPreviewWrapper: {
    marginTop: 10,
  },
})

export default AnswerItem
