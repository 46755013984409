import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { View, StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'

export type Props = {
  isActive: boolean
  onPress: () => void
}

const SearchIcon: React.FC<Props> = ({ isActive, onPress }: Props) => {
  const containerStyle = isActive
    ? styles.activeContainer
    : styles.inactiveContainer
  const iconColor = isActive ? color.primary : color.textGray

  return (
    <View style={containerStyle} testID="search-icon">
      <Button
        type="clear"
        testID="search-icon-button"
        onPress={onPress}
        icon={<Icon size={22} name="search" color={iconColor} />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  activeContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    margin: 4,
  },
  inactiveContainer: {
    margin: 4,
  },
})

export default SearchIcon
