import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import ResumesTemplate from '~/components/career/templates/resume/ResumesTemplate'
import { setPageTitle } from '~/utils/navigation'

const ResumeIndex: React.FC = () => {
  const { t } = useTranslation()

  useFocusEffect(() => setPageTitle(t('resume.title.index')))

  return (
    <DefaultLayout>
      <ResumesTemplate />
    </DefaultLayout>
  )
}
export default ResumeIndex
