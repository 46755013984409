import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useCallback } from 'react'
import { View, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'
import { DrawerType, openDrawer } from '~/slices/common/drawer'
import { closeDrawer } from '~/utils/navigation'

const MenuIcon: React.FC = () => {
  const dispatch = useDispatch()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const drawerType = useSelector(
    (state: RootState) => state.drawer.currentDrawerType
  )
  const isDrawerOpen = useSelector(
    (state: RootState) => state.drawer.isDrawerOpen
  )
  const active = isPC && isDrawerOpen && drawerType === DrawerType.Menu
  const containerStyle = active
    ? styles.activeContainer
    : styles.inactiveContainer
  const iconColor = active ? color.primary : color.textGray

  const handleDrawer = useCallback((): void => {
    if (isDrawerOpen && drawerType === DrawerType.Menu) {
      closeDrawer()
    } else {
      dispatch(openDrawer(DrawerType.Menu))
    }
  }, [dispatch, isDrawerOpen, drawerType])

  return (
    <View style={containerStyle} testID="menu-icon">
      <Button
        type="clear"
        testID="icon-button"
        onPressIn={handleDrawer}
        icon={<Icon size={22} name="bars" color={iconColor} />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  activeContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    margin: 4,
  },
  inactiveContainer: {
    margin: 4,
  },
})

export default MenuIcon
