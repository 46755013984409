import React from 'react'
import { StyleSheet } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  value?: string
  onPress?: () => void
}

const ChatThreadName: React.FC<Props> = ({ value, onPress }: Props) => (
  <Text
    numberOfLines={2}
    onPress={onPress}
    style={styles.senderText}
    testID="chat-thread-name"
  >
    {value}
  </Text>
)

const styles = StyleSheet.create({
  senderText: {
    color: color.pressableText,
    flexWrap: 'wrap',
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default ChatThreadName
