import { Link } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Recruitment from '~/interfaces/Recruitment'
import i18n from '~/plugins/i18n'

type Props = {
  job: Partial<Recruitment>
  isEditing?: boolean
  editableComponent?: React.ReactNode
}

const HeaderJobContent: React.FC<Props> = ({
  job,
  isEditing,
  editableComponent,
}: Props) => {
  const companyJob = job.company

  return (
    <View testID="header-job-content">
      {isEditing ? (
        editableComponent
      ) : (
        <>
          <Text style={[styles.text, styles.subject]} numberOfLines={2}>
            {job.subject}
          </Text>
          <Link
            to={{ screen: 'companyShow', params: { id: job.companyId } }}
            testID="company-profile-button"
          >
            <Text style={[styles.text, styles.companyName]} numberOfLines={1}>
              {job.companyName ||
                (i18n.language === 'en' && companyJob?.enName
                  ? companyJob?.enName
                  : companyJob?.name)}
            </Text>
          </Link>

          <Text style={[styles.text, styles.location]} numberOfLines={1}>
            {job.headOfficeArea}
          </Text>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  companyName: {
    color: color.pressableText,
    ...commonStyles.titleTextSize,
  },
  location: {
    fontSize: FontSize.SUB,
  },
  subject: {
    ...commonStyles.titleTextSize,
    fontWeight: 'bold',
  },
  text: {
    flexWrap: 'wrap',
  },
})

export default HeaderJobContent
