import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Image } from 'react-native-elements'

import EventPresenter from '~/components/community/atoms/EventPresenter'
import EventLocation from '~/components/community/molecules/EventLocation'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
  eventHeader?: React.ReactElement
}

const EventDetail: React.FC<Props> = ({ event, eventHeader }: Props) => (
  <View testID="event-detail" style={styles.container}>
    {!!event?.cover?.presignedUrl && (
      <Image
        width={164}
        height="100%"
        testID="event-cover"
        style={styles.avatar}
        source={{ uri: event?.cover?.presignedUrl }}
      />
    )}
    <View>
      {eventHeader}
      <View>
        {event.presenter && event.isVirtual && <EventPresenter event={event} />}
        <Text style={styles.body}>{event.description}</Text>
        {event.location &&
          ((event.isJoined && event.isVirtual) || !event.isVirtual) && (
            <EventLocation event={event} />
          )}
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  avatar: {
    height: 164,
    width: '100%',
  },
  body: {
    paddingVertical: 20,
  },
  container: {
    paddingBottom: 20,
    backgroundColor: color.white,
  },
})

export default EventDetail
