import React from 'react'
import { StyleSheet, View } from 'react-native'

import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import NotificationList from '~/components/common/features/notification/NotificationList'
import useDevice from '~/hooks/commons/useDevice'

const NotificationsTemplate: React.FC = () => {
  const { isPC } = useDevice()

  return isPC ? (
    <InfiniteScrollContainer>
      <View style={styles.container}>
        <NotificationList />
      </View>
    </InfiniteScrollContainer>
  ) : (
    <NotificationList />
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    width: 920,
  },
  rightColumn: {
    alignSelf: 'baseline',
    marginLeft: 30,
    width: 300,
  },
})

export default NotificationsTemplate
