import React, { useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import AdminDashboardList from '~/components/community/features/AdminDashboardList'
import CommunityUserList, {
  CommunityUserListType,
} from '~/components/community/features/CommunityUserList'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import RequestedUserList from '~/components/community/features/RequestedUserList'
import GeneralSetting from '~/components/community/molecules/setting/GeneralSetting'
import useDevice from '~/hooks/commons/useDevice'
import { CommunityCategory } from '~/interfaces/Community'
import { RootState } from '~/rootReducer'
import {
  AdminDashboardType,
  isCommunityAdmin,
  updateCommunity,
  updateSetting,
} from '~/slices/community/community'

const AdminDashboard: React.FC = () => {
  const dispatch = useDispatch()
  const { isPC } = useDevice()
  const currentUser = useSelector((state: RootState) => state.users.current)
  const adminDashboardType = useSelector(
    (state: RootState) => state.community.setting.adminDashboardType
  )
  const community = useSelector((state: RootState) => state.community.current)

  const updateCommunitySetting = (
    name: string,
    category: CommunityCategory,
    callback?: Function
  ): void => {
    Promise.all([
      dispatch(updateCommunity({ id: community?.id, name, category })),
    ]).then((): void => {
      callback && callback()
    })
  }

  const renderDashboardView = (): React.ReactNode => {
    if (adminDashboardType === AdminDashboardType.MemberManagement) {
      return <CommunityUserList type={CommunityUserListType.Community} />
    } else if (adminDashboardType === AdminDashboardType.MemberRequests) {
      return <RequestedUserList />
    } else {
      return isPC ? (
        <GeneralSetting
          community={community}
          onSubmit={updateCommunitySetting}
        />
      ) : (
        <ScrollView>
          <HeaderCommunityDetail />
          <GeneralSetting
            community={community}
            onSubmit={updateCommunitySetting}
          />
        </ScrollView>
      )
    }
  }

  const isMeAdmin = isCommunityAdmin(
    currentUser?.id,
    community?.communityMembers
  )

  useEffect(() => {
    //NOTE always show member list with member
    adminDashboardType !== AdminDashboardType.MemberManagement &&
      !isMeAdmin &&
      dispatch(
        updateSetting({
          adminDashboardType: AdminDashboardType.MemberManagement,
        })
      )
  }, [currentUser?.id])

  return (
    <View style={styles.container} testID="admin-dashboard">
      {isPC ? (
        <HorizontalContainer>
          {isMeAdmin && <AdminDashboardList />}
          <CardContainer
            testID="admin-dashboard-right-column"
            style={styles.rightColumn}
          >
            {renderDashboardView()}
          </CardContainer>
        </HorizontalContainer>
      ) : (
        renderDashboardView()
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rightColumn: {
    flex: 1,
  },
})

export default AdminDashboard
