import type { NavigationAction } from '@react-navigation/core'
import { Link as NavigationLink } from '@react-navigation/native'
import React from 'react'
import { TextProps, GestureResponderEvent } from 'react-native'

import { RootStackParamList } from '~/interfaces/RootStackParamList'
import { getCurrentLanguage } from '~/plugins/i18n'
import { getFontByLanguage } from '~/utils/common/themeConfig'

export type To =
  | string
  | {
      screen: Extract<keyof RootStackParamList, string>
      params?: RootStackParamList[keyof RootStackParamList]
    }

declare type Props = {
  to: To
  action?: NavigationAction
  target?: string
  onPress?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent
  ) => void
} & (TextProps & {
  children: React.ReactNode
})

const Link: React.FC<Props> = (props: Props) => (
  <NavigationLink
    {...props}
    style={[
      { fontFamily: getFontByLanguage(getCurrentLanguage()) },
      props.style,
    ]}
  >
    {props.children}
  </NavigationLink>
)

export default Link
