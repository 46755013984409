import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'

export type RecruitmentEntryParams = {
  q: string
  status?: string
} & BaseQueryParams

export class CompanyRecruitmentEntriesAPI extends BaseAPI {
  configPath(
    companyId: number,
    recruitmentId: number
  ): CompanyRecruitmentEntriesAPI {
    this.path = `/companies/${companyId}/recruitments/${recruitmentId}/entries`
    return this
  }
}
