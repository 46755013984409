import { BaseAPI } from '~/api/baseAPI'
import type CommunityChannel from '~/interfaces/CommunityChannel'

export type CommunityChannelRequest = {
  communityChannel: CommunityChannel
}

export class CommunityChannelAPI extends BaseAPI {
  configPath(communityId: number): CommunityChannelAPI {
    this.path = `/communities/${communityId}/community_channels`
    return this
  }
}
