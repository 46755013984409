import { useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import ResumeTemplate from '~/components/career/templates/resume/ResumeTemplate'
import { RootState } from '~/rootReducer'
import { fetchResumeDetail } from '~/slices/career/resumes'
import { RouteParamsID } from '~/utils/navigation'

const ResumeShow: React.FC = () => {
  const route = useRoute()
  const dispatch = useDispatch()
  const resumeId = (route?.params as RouteParamsID)?.id
  const { current, isLoading } = useSelector(
    (state: RootState) => state.resumes
  )

  useEffect(() => {
    if (current.id !== resumeId && resumeId) {
      dispatch(fetchResumeDetail(resumeId))
    }
  }, [resumeId])

  return isLoading ? <View testID="loading" /> : <ResumeTemplate />
}

export default ResumeShow
