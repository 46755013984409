import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC?: boolean
}

const FooterMenu: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()

  const textStyles = isPC ? styles.text : styles.textMobile
  const titleStyles = isPC ? styles.title : styles.titleMobile

  return (
    <View testID="footer-menu">
      <TitleText style={titleStyles}>{t('footer.menuTitle')}</TitleText>
      <Text style={textStyles}>{t('footer.menu.aboutUs')}</Text>
      <Text style={textStyles}>{t('footer.menu.faq')}</Text>
      <Text style={textStyles}>{t('footer.menu.privacyPolicy')}</Text>
      <Text style={textStyles}>{t('footer.menu.termsOfUse')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    marginTop: 12,
  },
  textMobile: {
    color: color.white,
    marginTop: 6,
  },
  title: {
    color: color.white,
    fontSize: 27,
    marginTop: 12,
  },
  titleMobile: {
    color: color.white,
    fontSize: 22,
    marginTop: 12,
  },
})

export default FooterMenu
