import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
  onPress?: () => void
}

const CreateResumeItem: React.FC<Props> = ({ isPC, onPress }: Props) => {
  const { t } = useTranslation()

  return (
    <TouchableOpacity testID="create-resume-item" onPress={onPress}>
      <View
        testID="create-resume-item-content"
        style={StyleSheet.flatten([
          styles.container,
          isPC ? styles.containerSizePC : styles.containerSizeMobile,
        ])}
      >
        <Icon size={24} name="plus" testID="plus-icon" color={color.textGray} />
        <Text style={styles.subtitle}>{t('resume.createMainResumeGuide')}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    borderRadius: 10,
    borderStyle: 'dashed',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: color.textGray,
    marginHorizontal: 5,
    padding: 10,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  subtitle: {
    fontSize: FontSize.GENERAL,
    borderColor: color.textGray,
  },
})

export default CreateResumeItem
