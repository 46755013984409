import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import CommentEditorInput from '~/components/career/molecules/post/CommentEditorInput'
import CommentSendButton from '~/components/career/molecules/post/CommentSendButton'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import color from '~/constants/common/color'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'

type Props = {
  comment?: Post
  mediaPicker?: React.ReactNode
  onSubmitEditing?: (text: string) => void
  user?: User
}

const CommentEditor: React.FC<Props> = ({
  comment,
  mediaPicker,
  onSubmitEditing,
  user,
}: Props) => {
  const [message, setMessage] = useState(comment?.message || '')
  useEffect(() => {
    setMessage(comment?.message || message)
  }, [comment])

  const onSubmit = () => {
    if (onSubmitEditing && message) {
      onSubmitEditing(message)
      setMessage('')
    }
  }

  return (
    <View style={styles.container} testID="comment-editor">
      <UserAvatar size={ProfileAvatarSize.TINY} user={user} />
      <View style={styles.input}>
        <CommentEditorInput
          message={message}
          setMessage={setMessage}
          mediaPicker={mediaPicker}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
      <CommentSendButton onPress={onSubmit} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: color.primary,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    flexDirection: 'row',
    padding: 10,
  },
  input: {
    flex: 1,
    marginLeft: 10,
  },
})

export default CommentEditor
