import { ExpoConfig, ConfigContext } from '@expo/config'

const isProduction = process.env.NODE_ENV === 'production'
export default ({ config }: ConfigContext): ExpoConfig =>
  ({
    ...config,
    expo: {
      scheme: 'enjin',
      web: {
        ...config.web,
        name: 'Enjin - Social Community Service',
        favicon: './assets/favicon.png',
        config: {
          firebase: firebaseConfig,
        },
      },
      android: {
        ...config.android,
        package: isProduction
          ? 'com.enjin.jobnetwork'
          : 'com.enjin.jobnetwork.dev',
        googleServicesFile: isProduction
          ? './google-services.json'
          : './google-services-dev.json',
      },
      ios: {
        ...config.ios,
        bundleIdentifier: isProduction
          ? 'com.enjin.jobnetwork'
          : 'com.enjin.jobnetwork.dev',
        googleServicesFile: isProduction
          ? './GoogleService-Info.plist'
          : './GoogleService-Info-dev.plist',
      },
      hooks: {
        postPublish: [
          {
            file: 'sentry-expo/upload-sourcemaps',
            config: {
              organization: isProduction ? 'enjin' : 'funcoding-dev',
              project: 'enjin',
              authToken: isProduction
                ? process.env.SENTRY_AUTH_TOKEN
                : '3f74df4e018911ecb6118a8f564604e5',
            },
          },
        ],
      },
    },
  } as ExpoConfig)

export const firebaseConfig = isProduction
  ? {
      apiKey: 'AIzaSyCXSikAvppvcHNdojPk22nksu1GqI0kIm0',
      authDomain: 'scs-enjin.firebaseapp.com',
      projectId: 'scs-enjin',
      storageBucket: 'scs-enjin.appspot.com',
      messagingSenderId: '666377440994',
      appId: '1:666377440994:web:e313d05ffec13350f698bb',
      measurementId: 'G-6JLNPCHZKY',
    }
  : {
      apiKey: 'AIzaSyDlXiKU8qgIZvwnYjsrQcNVzl8H6xTG7Mw',
      authDomain: 'hip-light-852.firebaseapp.com',
      databaseURL: 'https://hip-light-852.firebaseio.com',
      projectId: 'hip-light-852',
      storageBucket: 'hip-light-852.appspot.com',
      messagingSenderId: '18379531724',
      appId: '1:18379531724:web:f65ca65e53ec3b16812969',
      measurementId: 'G-108XHBGBTR',
    }

export const webBaseUrl = isProduction
  ? 'https://enjin.world/'
  : 'http://localhost:19006/'
