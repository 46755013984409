import camelcaseKeys from 'camelcase-keys'
import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import MobileLayout from '~/components/career/pages/RootPage/CareerMain/MobileLayout'
import PCLayout from '~/components/career/pages/RootPage/CareerMain/PCLayout'
import { RootContext } from '~/contexts/RootContext'
import useStreamSubscription from '~/hooks/useStreamSubscription'
import UnreadCountNotification from '~/interfaces/UnreadCountNotification'
import { RootState } from '~/rootReducer'
import { setUnreadCount } from '~/slices/common/notifications'

const Main: React.FC = () => {
  const dispatch = useDispatch()
  const { stream } = useContext(RootContext)
  const isPC = useSelector((state: RootState) => state.device.isPC)

  stream &&
    useStreamSubscription({
      stream,
      channelName: 'UnreadChannel',
      mixin: {
        received: (data: UnreadCountNotification) =>
          dispatch(
            setUnreadCount(camelcaseKeys(data, { deep: true }).unreadCount)
          ),
        disconnected: () => console.log('Disconnect UnreadChannel'),
      },
      deps: [],
    })

  return (
    <View style={styles.content}>{isPC ? <PCLayout /> : <MobileLayout />}</View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    overflow: 'hidden',
  },
})

export default Main
