import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

const styles = StyleSheet.create({
  emptyView: {
    backgroundColor: color.mask,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  loadingIndicator: {
    flex: 1,
  },
})

const EmptyImage: React.FC = () => (
  <View style={styles.emptyView}>
    <ActivityIndicator
      testID="empty-media-activity-indicator"
      color={color.white}
      style={styles.loadingIndicator}
      size="large"
    />
  </View>
)

export default EmptyImage
