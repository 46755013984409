import { isEqual } from 'lodash'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import LanguageSetting from '~/components/common/features/LanguageSetting'
import MenuDrawerUserInfo from '~/components/common/molecules/drawer/MenuDrawerUserInfo'
import MenuDrawer from '~/components/forum/organisms/drawers/MenuDrawer'
import NotificationDrawer from '~/components/forum/organisms/drawers/NotificationDrawer'
import SearchDrawer from '~/components/forum/organisms/drawers/SearchDrawer'
import useDevice from '~/hooks/commons/useDevice'
import Notification from '~/interfaces/Notification'
import { RootState } from '~/rootReducer'
import { logout as resetAccessToken } from '~/slices/common/auth'
import { DrawerType, drawerTypeSelector } from '~/slices/common/drawer'
import {
  getAllNotifications,
  markNotificationAsRead,
  reset as resetNotification,
} from '~/slices/common/notifications'
import { currentUserSelector } from '~/slices/common/users'
import {
  closeDrawer,
  navigate,
  navigateWithNotification,
} from '~/utils/navigation'

const DrawerContent: React.FC = () => {
  const dispatch = useDispatch()
  const { isMobile } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const notifications = useSelector((state: RootState) =>
    getAllNotifications(state)
  )
  const drawerType = useSelector(drawerTypeSelector)

  const markAsRead = (item: Notification): void => {
    if (!item.wasAlreadyRead) {
      dispatch(markNotificationAsRead(item.id))
    }
  }

  const navigateToContent = (notification: Notification): void => {
    closeDrawer()
    navigateWithNotification(notification)
  }

  const logout = (): void => {
    Promise.all([
      dispatch(resetAccessToken()),
      dispatch(resetNotification()),
    ]).then(() => {
      closeDrawer()
      navigate('login')
    })
  }

  switch (drawerType) {
    case DrawerType.Search:
      return (
        <View testID="drawer-search" style={styles.drawerContent}>
          <SearchDrawer isMobile={isMobile} />
        </View>
      )
    case DrawerType.Notifications:
      return (
        <View testID="drawer-notifications" style={styles.drawerContent}>
          <NotificationDrawer
            isMobile={isMobile}
            markAsRead={markAsRead}
            notifications={notifications}
            navigateToContent={navigateToContent}
          />
        </View>
      )
    case DrawerType.Menu:
      return (
        <View testID="drawer-menu" style={styles.drawerContent}>
          <MenuDrawer
            isMobile={isMobile}
            user={currentUser}
            onLogout={logout}
            userInfoComponent={<MenuDrawerUserInfo user={currentUser} />}
            languageSettingComponent={
              <LanguageSetting key="language-setting" />
            }
          />
        </View>
      )
  }
}

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
})

export default React.memo(DrawerContent, isEqual)
