import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import { UserAnswerQueryParams } from '~/api/userAnswers'
import CardContainer from '~/components/common/atoms/CardContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu from '~/components/common/molecules/ControlMenu'
import EmptyView from '~/components/common/molecules/EmptyView'
import SkeletonListItem from '~/components/common/molecules/SkeletonListItem'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import AnswerForm from '~/components/forum/features/answers/AnswerForm'
import UserAnswerItem from '~/components/forum/organisms/users/UserAnswerItem'
import { AnswerMenuType } from '~/constants/forum/answer'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Answer from '~/interfaces/Answer'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { getAnswerMenuData } from '~/utils/forum/answer'
import { getCategoriesFromRoute, getRouteParams } from '~/utils/navigation'

type Props = {
  userId?: number
  header?: React.ReactElement
}

const keyExtractor = (item: Answer, index: number): string =>
  `${item.id}-${index}`

const UserAnswerList: React.FC<Props> = ({ header, userId }: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const defaultPage = 1
  const routeParams = getRouteParams<UserAnswerQueryParams>()
  const categories = getCategoriesFromRoute(routeParams.categories)

  const [isLoading, setIsLoading] = useState(true)
  const [answers, setAnswers] = useState<Answer[]>([])
  const [isShowEditDialog, setIsShowEditDialog] = useState(false)
  const [currentAnswer, setCurrentAnswer] = useState<Answer | undefined>()
  const [isShowConfirmationDialog, setIsShowConfirmDialog] = useState(false)
  const [queryParams, setQueryParams] = useState<UserAnswerQueryParams>({
    ...routeParams,
    categories,
    userId,
  })

  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)

  const shouldShowSkeleton = isEmpty(answers) && isLoading

  const fetchUserAnswers = useAPI(
    async (api: API, page: number): Promise<void> => {
      if (!queryParams.userId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.userAnswers.index({
          ...queryParams,
          page,
        })
        if (queryParams.page && page > queryParams.page) {
          const isEmptyResponse = isEmpty(response)
          !isEmptyResponse && setAnswers((answers) => [...answers, ...response])
          !isEmptyResponse && setQueryParams({ ...queryParams, page })
        } else {
          setAnswers(response)
        }
      } catch (err) {
        toast.showError(err)
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [
      setIsLoading,
      queryParams.categories,
      queryParams.q,
      queryParams.userId,
      queryParams.sortBy,
    ]
  )

  const updateAnswer = (answer: Answer): void => {
    setAnswers((answers) =>
      answers.map((item) => (answer.id === item.id ? answer : item))
    )
    hideEditDialog()
  }

  useFocusEffect(
    React.useCallback(() => {
      //Reset current page
      setQueryParams({
        categories,
        ...queryParams,
        q: routeParams.q,
        page: routeParams.page || defaultPage,
        sortBy: routeParams.sortBy,
      })
    }, [categories, routeParams.q, routeParams.userId, routeParams.sortBy])
  )

  useFocusEffect(
    React.useCallback(() => {
      fetchUserAnswers(queryParams.page)
    }, [fetchUserAnswers])
  )

  const fetchMoreAnswers = (): void => {
    !isLoading && queryParams.page && fetchUserAnswers(queryParams.page + 1)
  }

  useEffect(() => {
    isBottom && fetchMoreAnswers()
  }, [isBottom])

  const deleteAnswer = async () => {
    if (!currentAnswer || !currentAnswer?.question?.id) {
      return
    }
    try {
      await api.answers.delete(currentAnswer.question.id, currentAnswer.id)
      setAnswers((answers: Answer[]) =>
        answers.filter((item) => item.id != currentAnswer.id)
      )
      hideConfirmDialog()
    } catch (error) {
      toast.showError(error)
    }
  }

  const onSelectAnswerMenu = (type: AnswerMenuType, answer: Answer): void => {
    switch (type) {
      case AnswerMenuType.DELETE:
        setCurrentAnswer(answer)
        setIsShowConfirmDialog(true)
        break
      case AnswerMenuType.EDIT:
        setCurrentAnswer(answer)
        setIsShowEditDialog(true)
        break
    }
  }

  const hideConfirmDialog = (): void => {
    setIsShowConfirmDialog(false)
    setCurrentAnswer(undefined)
  }

  const hideEditDialog = (): void => {
    setIsShowEditDialog(false)
    setCurrentAnswer(undefined)
  }

  const renderItem: ListRenderItem<Answer> = ({ item }: { item: Answer }) => (
    <UserAnswerItem
      answer={item}
      answerControlMenu={
        item?.user?.id === currentUser?.id ? (
          <ControlMenu
            data={getAnswerMenuData(t)}
            onSelect={(type): void =>
              onSelectAnswerMenu(type.id as AnswerMenuType, item)
            }
          />
        ) : undefined
      }
    />
  )

  return (
    <View testID="user-answer-list" style={styles.container}>
      {shouldShowSkeleton ? (
        <SkeletonList
          count={3}
          numColumns={1}
          itemComponent={
            <CardContainer style={styles.loadingItemContainer}>
              <SkeletonListItem />
            </CardContainer>
          }
        />
      ) : (
        <InfiniteScrollFlatList
          data={answers}
          scrollEnabled={false}
          renderItem={renderItem}
          style={styles.answerList}
          keyExtractor={keyExtractor}
          ListHeaderComponent={header}
          onReachBottom={fetchMoreAnswers}
          ListEmptyComponent={
            isLoading ? undefined : (
              <EmptyView
                isPC={isPC}
                title={t('jobs.emptyPages.answer.title')}
                imageSource={require('~/assets/images/prompts/nopostsyet.png')}
              />
            )
          }
        />
      )}
      {isShowConfirmationDialog && (
        <ConfirmationDialog
          isDanger={true}
          acceptLabel={t('delete')}
          onPressAccept={deleteAnswer}
          onPressCancel={hideConfirmDialog}
          visible={isShowConfirmationDialog}
          message={t('forum.confirmDeleteAnswer')}
        />
      )}
      {currentAnswer?.question?.id && (
        <ModalContainer visible={isShowEditDialog} onDismiss={hideEditDialog}>
          <View
            testID="answer-edit-form-container"
            style={styles.answerEditFormContainer}
          >
            <AnswerForm
              onUpdate={updateAnswer}
              currentAnswer={currentAnswer}
              questionId={currentAnswer?.question?.id}
            />
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  answerEditFormContainer: {
    flex: 1,
    maxWidth: 590,
  },
  answerList: {
    height: '100%',
  },
  container: {
    flex: 1,
  },
  loadingItemContainer: {
    marginBottom: 20,
    width: '100%',
  },
})

export default UserAnswerList
