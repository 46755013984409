import { Image } from 'react-native'

export type ImageSize = [number, number]

type CancellablePromise<T> = Promise<T> & { cancel: () => void }

const getImageSize = (uri: string): CancellablePromise<ImageSize> => {
  let cancelled = false
  const promise = new Promise<ImageSize>((resolve, reject) => {
    Image.getSize(
      uri,
      (width: number, height: number) => {
        if (!cancelled) {
          resolve([width, height])
        }
      },
      () => {
        if (!cancelled) {
          reject()
        }
      }
    )
  }) as CancellablePromise<ImageSize>
  promise.cancel = (): void => {
    cancelled = true
  }
  return promise
}

export default getImageSize
