import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TouchableOpacity, TextStyle } from 'react-native'
import { Text } from 'react-native-elements'

import QuizImage from '~/components/career/atoms/quiz/QuizImage'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { quizChoiceLabels } from '~/interfaces/Quiz'
import QuizResult from '~/interfaces/QuizResult'

type Props = {
  quizResult: QuizResult
  isPC: boolean
}

const QuizItem: React.FC<Props> = ({ quizResult, isPC }: Props) => {
  const { t } = useTranslation()
  const [isShowingDetail, setIsShowingDetail] = useState(false)

  const getChoiceStyle = (choice: string): TextStyle | undefined => {
    if (choice === quizResult.choice) {
      return !quizResult.isCorrect ? styles.inCorrect : styles.correct
    } else {
      return choice === quizResult.quiz.answer ? styles.correct : undefined
    }
  }

  const getChoicedSymbol = (choice: string): string => {
    if (choice === quizResult.choice) {
      return quizResult.isCorrect ? '○' : '✕'
    } else return ''
  }

  return (
    <View
      testID="quiz-item"
      style={StyleSheet.flatten([
        styles.container,
        { width: isPC ? 293 : '92%' },
        { alignSelf: isPC ? undefined : 'center' },
        isPC ? undefined : { alignSelf: 'center', ...commonStyles.shadow },
      ])}
    >
      <View style={styles.quizImage}>
        <QuizImage quiz={quizResult.quiz} />
      </View>
      <View style={styles.quizDetail}>
        <Text
          ellipsizeMode="tail"
          testID="quiz-question-text"
          style={styles.questionText}
        >
          {quizResult.quiz.question}
        </Text>
        {isShowingDetail && (
          <View testID="quiz-detail-group">
            <View style={styles.separator} />
            {quizResult.quiz.choices.map((choice, index) => {
              return (
                <View
                  key={`${choice}-${index}`}
                  style={styles.quizChoiceContainer}
                >
                  <Text
                    testID="quiz-choice"
                    style={getChoiceStyle(choice)}
                  >{`${quizChoiceLabels[index]}: ${choice}`}</Text>
                  <Text style={[getChoiceStyle(choice), styles.symbol]}>
                    {getChoicedSymbol(choice)}
                  </Text>
                </View>
              )
            })}
            <View style={styles.separator} />
            <Text>{t('quiz.explanation')}:</Text>
            <Text>{quizResult.quiz.explanation}</Text>
            <View style={styles.separator} />
          </View>
        )}
      </View>
      <TouchableOpacity
        testID="show-more-pressable"
        onPress={(): void => setIsShowingDetail(!isShowingDetail)}
      >
        <View style={styles.seeMoreButton}>
          <Icon
            size={16}
            name={isShowingDetail ? 'caret-up' : 'caret-down'}
            color={color.white}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 14,
    ...commonStyles.borderRadiusBottomCorner,
  },
  correct: {
    color: color.primary,
  },
  inCorrect: {
    color: color.badgeColor,
  },
  questionText: {
    flexWrap: 'wrap',
  },
  quizChoiceContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  quizDetail: {
    backgroundColor: color.white,
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
  quizImage: {
    overflow: 'hidden',
    ...commonStyles.borderRadiusTopCorner,
  },
  seeMoreButton: {
    alignItems: 'flex-end',
    backgroundColor: color.primary,
    height: 25,
    paddingRight: 10,
    paddingVertical: 4,
    ...commonStyles.borderRadiusBottomCorner,
  },
  separator: {
    marginTop: 10,
  },
  symbol: {
    fontWeight: 'bold',
    marginLeft: 10,
  },
})

export default QuizItem
