import { BaseAPI } from '~/api/baseAPI'
import { HTTPClient } from '~/httpClient'
import Message from '~/interfaces/Message'

export type ChatMessageRequestBody = {
  chatMessage: {
    body: string
    userIds: number[]
  }
}

export class ChatMessagesAPI extends BaseAPI {
  path = '/chat_threads'
  constructor(client: HTTPClient) {
    super(client)
  }

  build(threadId: number) {
    this.path = `/chat_threads/${threadId}/chat_messages`
    return this
  }

  async directMessage(data: ChatMessageRequestBody): Promise<Message> {
    return await this.client.post(`/chat_messages`, data)
  }
}
