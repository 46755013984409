import { FontAwesome as Icon } from '@expo/vector-icons'
import moment from 'moment'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Education from '~/interfaces/Education'
import { TemplateType } from '~/interfaces/Resume'
import { RESUME_DATE_FORMAT } from '~/utils/career/resume'

type Props = {
  education: Partial<Education>
  templateType: TemplateType
}

const EducationGroupItem: React.FC<Props> = ({
  education,
  templateType,
}: Props) => {
  const schoolNameIcon = {
    enjin: (
      <Icon name="circle" size={14} color={color.primary} style={styles.icon} />
    ),
    shades: (
      <Icon name="circle" size={14} color={color.black} style={styles.icon} />
    ),
    formal: undefined,
    minimal: undefined,
  }

  const commonView = (
    <>
      <HorizontalContainer>
        {schoolNameIcon[templateType]}
        <Text style={styles.schoolName}>{education.schoolName}</Text>
      </HorizontalContainer>
      <Text style={styles.remarkable} testID="education-major">{`${
        education.major ?? ''
      }${`(${education.degree})` ?? ''}`}</Text>
      <Text style={styles.date}>
        {moment(education.enrollmentYear).format(RESUME_DATE_FORMAT)}
        {education.graduationYear
          ? `- ${moment(education.graduationYear).format(RESUME_DATE_FORMAT)}`
          : ''}
      </Text>
      <Text style={styles.description}>{education.description}</Text>
    </>
  )

  const content = {
    enjin: commonView,
    shades: commonView,
    formal: commonView,
    minimal: commonView,
  }

  return (
    <View style={styles.container} testID="education-group-item">
      {content[templateType]}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 20,
  },
  date: {
    color: color.textGray,
  },
  description: {
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: 6,
  },
  remarkable: {
    fontWeight: 'bold',
  },
  schoolName: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
})

export default EducationGroupItem
