import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

import UserResultItem from '~/components/common/molecules/users/UserResultItem'
import CommunityUser from '~/interfaces/CommunityUser'

type Props = {
  isPC: boolean
  isMe?: boolean
  controlMenu?: ReactNode
  communityUser: CommunityUser
}

const CommunityUserItem: React.FC<Props> = ({
  isPC,
  isMe,
  controlMenu,
  communityUser,
}: Props) => (
  <View style={styles.container} testID="community-user-item">
    <UserResultItem user={communityUser.user} isPC={false} />
    {isPC && !isMe && controlMenu}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export default CommunityUserItem
