import { Ionicons as Icon } from '@expo/vector-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TextWithIcon from '~/components/common/atoms/TextWithIcon'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import ChatThread from '~/interfaces/ChatThread'
import User from '~/interfaces/User'

type Props = {
  onPress?: () => void
  chatThread: Partial<ChatThread>
  onPressRemoveRecipient?: (recipient: User) => void
}

const NewThreadMessage: React.FC<Props> = ({
  onPress,
  chatThread,
  onPressRemoveRecipient,
}: Props) => {
  const users = chatThread.users
  const { t } = useTranslation()

  const selectedUserElement = (user: User): React.ReactElement => (
    <View
      style={styles.valueContainer}
      key={user.id}
      testID="new-selected-recipients"
    >
      <TextWithIcon
        onPress={onPress}
        style={[commonStyles.titleTextStyle, styles.value]}
        value={t('profile.displayFullName', {
          name: user.profile.name,
          enName: user.profile.enName || user.profile.name,
        })}
        testID="new-thread-message-value"
      />
      <Icon
        name="close-circle-sharp"
        size={23}
        color={color.badgeColor}
        style={styles.closeIcon}
        onPress={(): void =>
          onPressRemoveRecipient && onPressRemoveRecipient(user)
        }
      />
    </View>
  )

  return (
    <View style={styles.container} testID="new-thread-message">
      <Text
        style={[commonStyles.titleTextStyle, styles.guideText]}
        testID="new-thread-message-guide-text"
      >
        {t('message.newThreadPlaceholder')}
      </Text>
      <View style={styles.recipientsBlock}>
        {users?.map((item) => selectedUserElement(item))}
        <Text
          onPress={onPress}
          style={[commonStyles.titleTextStyle, styles.addText]}
          testID="add-recipients"
        >
          {t('add')}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  addText: {
    color: color.pressableText,
    marginHorizontal: 10,
    textDecorationLine: 'underline',
  },
  closeIcon: {
    position: 'absolute',
    right: -8,
    top: -10,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  guideText: {
    marginHorizontal: 10,
    paddingBottom: 10,
  },
  recipientsBlock: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  value: {
    color: color.pressableText,
    marginRight: 10,
  },
  valueContainer: {
    marginLeft: 10,
    marginVertical: 4,
    backgroundColor: color.lightGray,
    borderRadius: 12,
    paddingHorizontal: 4,
  },
})

export default NewThreadMessage
