import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import api from '~/api'
import LikeButton from '~/components/common/atoms/socials/LikeButton'
import useCustomToast from '~/hooks/useCustomToast'
import Question from '~/interfaces/Question'
import { setEventData } from '~/slices/forum/questions'

type Props = {
  question: Question
  isShowIcon?: boolean
  isShowCountLabel?: boolean
}

const QuestionLikeButton: React.FC<Props> = ({
  question,
  isShowIcon,
}: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useCustomToast()

  const updateLike = (): void => {
    setIsLoading(true)
    if (question.liked) {
      api.likes
        .unlike({ questionId: question.id })
        .then(() => {
          question.liked = false
          question.likesCount = question.likesCount - 1
          dispatch(
            setEventData({ id: question.id, likesCount: question.likesCount })
          )
        })
        .catch((error) => {
          toast.showError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      api.likes
        .like({ questionId: question.id })
        .then(() => {
          question.liked = true
          question.likesCount = question.likesCount + 1
          dispatch(
            setEventData({ id: question.id, likesCount: question.likesCount })
          )
        })
        .catch((error) => {
          toast.showError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <View style={styles.container} testID="question-like-button">
      <LikeButton
        isShowIcon={isShowIcon}
        disabed={isLoading}
        onPress={updateLike}
        liked={question.liked}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default QuestionLikeButton
