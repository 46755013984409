import { useFocusEffect } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { QuizResultsQueryParams } from '~/api/quizResults'
import QuizResultFilterPopup from '~/components/career/molecules/quizzes/QuizResultFilterPopup'
import QuizItem from '~/components/career/organisms/quizzes/QuizItem'
import EmptyView from '~/components/common/molecules/EmptyView'
import FlatList from '~/components/workarounds/FlatList'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import QuizResult from '~/interfaces/QuizResult'
import { RootState } from '~/rootReducer'
import { getCategoriesFromRoute, navigationRef } from '~/utils/navigation'

const keyExtractor = (item: QuizResult): string => item.id.toString()

type Props = {
  userId?: number
  header?: React.ReactElement
}

const QuizList: React.FC<Props> = ({ header, userId }: Props) => {
  const { t } = useTranslation()
  const [quizzes, setQuizzes] = useState([] as QuizResult[])
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(true)

  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)

  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as QuizResultsQueryParams
  const categories = getCategoriesFromRoute(routeParams.categories)

  const [queryParams, setQueryParams] = useState<QuizResultsQueryParams>({})

  const defaultPage = 1

  const fetchQuizzes = useAPI(
    async (api: API, page: number): Promise<void> => {
      if (!userId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.quizResults.index({
          ...queryParams,
          page,
        })

        if (queryParams.page && page > queryParams.page) {
          const isEmptyResponse = isEmpty(response)
          !isEmptyResponse && setQuizzes((quizzes) => [...quizzes, ...response])
          !isEmptyResponse && setQueryParams({ ...queryParams, page })
        } else {
          setQuizzes(response)
        }
      } catch (err) {
        //TODO show error
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [
      userId,
      queryParams?.categories,
      queryParams?.isCorrect,
      queryParams.q,
      setIsLoading,
    ]
  )

  useFocusEffect(
    React.useCallback(() => {
      userId && fetchQuizzes(queryParams.page)
    }, [fetchQuizzes])
  )

  useFocusEffect(
    React.useCallback(() => {
      setQueryParams({
        ...queryParams,
        categories: categories,
        userId: userId,
        isCorrect: routeParams.isCorrect,
        q: routeParams.q,
        page: queryParams.page || defaultPage,
      })
    }, [
      routeParams.categories,
      setQueryParams,
      userId,
      routeParams.isCorrect,
      routeParams.q,
    ])
  )

  useEffect(() => {
    !isLoading &&
      isBottom &&
      queryParams.page &&
      !isEmpty(quizzes) &&
      fetchQuizzes(queryParams.page + 1)
  }, [isBottom])

  return (
    <FlatList
      data={quizzes}
      testID="quiz-list"
      style={styles.container}
      numColumns={isPC ? 2 : 1}
      keyExtractor={keyExtractor}
      ListHeaderComponent={
        <View>
          {header}
          {isPC && quizzes.length > 0 && (
            <View style={styles.filterContainer} testID="filter-container">
              <QuizResultFilterPopup />
            </View>
          )}
        </View>
      }
      columnWrapperStyle={isPC ? styles.columnWrapper : undefined}
      ListEmptyComponent={
        isLoading ? undefined : (
          <EmptyView
            isPC={isPC}
            title={t('quiz.emptyTitle')}
            imageSource={require('~/assets/images/prompts/nopostsyet.png')}
          />
        )
      }
      renderItem={({ item }): React.ReactElement => (
        <QuizItem quizResult={item} isPC={isPC} />
      )}
    />
  )
}

const styles = StyleSheet.create({
  columnWrapper: {
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
  },
  filterContainer: {
    alignItems: 'flex-end',
    paddingTop: 10,
  },
})

export default QuizList
