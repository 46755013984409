import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import FormLayout from '~/components/career/molecules/resume/FormLayout'
import TextField from '~/components/common/molecules/TextField'
import Resume from '~/interfaces/Resume'

type Props = {
  isPC: boolean
  interestedJob?: string
  interestedJobReason?: string
  onChange: (value: Partial<Resume>) => void
}

const InterestedJobsForm: React.FC<Props> = ({
  isPC,
  onChange,
  interestedJob,
  interestedJobReason,
}: Props) => {
  const { t } = useTranslation()

  const handleChangeJob = (value: string): void => {
    onChange({ interestedJob: value })
  }

  const handleChangeReason = (value: string): void => {
    onChange({ interestedJobReason: value })
  }

  return (
    <FormLayout
      isPC={isPC}
      hasAddAction={false}
      title={t('resume.interestJobsTitle')}
    >
      <View style={styles.childrenContainer}>
        <TextField
          value={interestedJob}
          title={t('resume.job')}
          onChangeText={handleChangeJob}
        />
        <TextField
          maxLength={250}
          numberOfLines={8}
          title={t('resume.reason')}
          value={interestedJobReason}
          onChangeText={handleChangeReason}
          shouldShowCounter
        />
      </View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  childrenContainer: {
    marginBottom: 20,
    marginTop: 10,
  },
})

export default InterestedJobsForm
