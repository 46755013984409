import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, TextStyle } from 'react-native'

import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  email?: string
  invisibleLabel?: boolean
  onSubmitEditing?: () => void
  onChangeText: (text: string, isValid: boolean) => void
}

type ErrorMessageHook = {
  showErrorMessage: boolean
  setShowErrorMessage: (visibility: boolean) => void
}

export const useShowErrorMessage = (): ErrorMessageHook => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  return { showErrorMessage, setShowErrorMessage }
}

const EmailInput: React.FC<Props> = ({
  email,
  invisibleLabel,
  onChangeText,
  onSubmitEditing,
}: Props) => {
  const { t } = useTranslation()
  const [valid, setValid] = useState(false)
  const { showErrorMessage, setShowErrorMessage } = useShowErrorMessage()

  // https://stackoverflow.com/a/201378/6521970
  // eslint-disable-next-line
  const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const handleEmail = (value: string): void => {
    const valid = emailReg.test(value)
    setValid(valid)
    if (showErrorMessage && valid) {
      setShowErrorMessage(false)
    }
    onChangeText(value, valid)
  }

  const handleErrorMessage = (): void => {
    setShowErrorMessage(!valid)
  }

  useEffect(() => {
    !isNil(email) && setValid(emailReg.test(email))
  }, [email])

  return (
    <Input
      label={!invisibleLabel && t('auth.emailAddress')}
      labelStyle={styles.label as TextStyle}
      inputStyle={styles.input}
      value={email}
      onBlur={handleErrorMessage}
      onSubmitEditing={onSubmitEditing}
      errorMessage={showErrorMessage ? t('auth.emailErrorMessage') : ''}
      inputContainerStyle={styles.inputContainer}
      onChangeText={handleEmail}
      testID="email-input"
      autoCapitalize="none"
    />
  )
}

const styles = {
  input: {
    paddingLeft: 4,
    paddingTop: 3,
    fontSize: FontSize.GENERAL,
    ...Platform.select({
      web: { outline: 'none' },
    }),
  },
  inputContainer: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    marginTop: 10,
  },
  label: {
    color: color.textGray,
    fontWeight: 'normal',
    fontSize: FontSize.GENERAL,
  },
}

export default EmailInput
