import { MediaTypeOptions } from 'expo-image-picker'
import { some } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import ProfileBanner, {
  ProfileBannerAction,
} from '~/components/common/molecules/ProfileBanner'
import { currentUserSelector } from '~/slices/common/users'
import {
  communitySelector,
  isCommunityAdmin,
  updateCommunity,
} from '~/slices/community/community'
import { selectMedia, upload } from '~/utils/common/media'

type Props = {
  canLeave?: boolean
  onPressLeave?: () => void
}

const ProfileCommunityBanner: React.FC<Props> = ({
  canLeave,
  onPressLeave,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const community = useSelector(communitySelector)
  const currentUser = useSelector(currentUserSelector)
  const imagePath = community?.cover?.urls.original
  const isAdmin = isCommunityAdmin(currentUser?.id, community?.communityMembers)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const isMember = some(community?.communityMembers, {
    userId: currentUser?.id,
  })

  const onSelectBannerMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case ProfileBannerAction.REMOVE:
        dispatch(updateCommunity({ coverId: '', id: community?.id }))
        break
      case ProfileBannerAction.ADD:
      case ProfileBannerAction.CHANGE:
        uploadBanner()
        break
      case ProfileBannerAction.LEAVE:
        setShowConfirmDialog(true)
        break
    }
  }

  const uploadBanner = async (): Promise<void> => {
    if (!community) {
      return
    }
    const uri = await selectMedia(MediaTypeOptions.Images)
    if (!uri) {
      return
    }
    try {
      const media = await upload(uri, 'banner')
      dispatch(updateCommunity({ coverId: media.id, id: community?.id }))
    } catch (e) {
      console.error(e)
    }
  }

  const getBannerMenuData = (): ControlMenuData[] => {
    if (!isMember) return []
    const defaultOptions = [
      {
        id: ProfileBannerAction.LEAVE,
        title: t('community.leaveCommunity'),
      },
    ]
    if (!isAdmin) return defaultOptions
    const adminOptions = imagePath
      ? [
          {
            id: ProfileBannerAction.CHANGE,
            title: t('banner.changeBanner'),
          },
          {
            id: ProfileBannerAction.REMOVE,
            title: t('banner.removeBanner'),
          },
        ]
      : [
          {
            id: ProfileBannerAction.ADD,
            title: t('banner.addBanner'),
          },
        ]
    return [...adminOptions, ...defaultOptions]
  }

  const bannerMenuData = getBannerMenuData()

  return (
    <>
      <View testID="profile-community-banner">
        <ProfileBanner
          controlMenu={
            bannerMenuData.length ? (
              <ControlMenu
                data={bannerMenuData}
                onSelect={onSelectBannerMenu}
                menuTrigger={<ControlMenuButton inverted />}
                preferredPlacement="bottom"
              />
            ) : undefined
          }
          imagePath={imagePath}
        />
      </View>
      <ConfirmationDialog
        onPressAccept={(): void => {
          setShowConfirmDialog(!showConfirmDialog)
          canLeave && onPressLeave?.()
        }}
        isOnlyAccept={!canLeave}
        isDanger
        acceptLabel={!canLeave ? t('ok') : undefined}
        visible={showConfirmDialog}
        message={t(
          canLeave
            ? 'community.leaveCommunityConfirmMessage'
            : 'community.leaveCommunityAdminWarning'
        )}
        onPressCancel={(): void => setShowConfirmDialog(false)}
      />
    </>
  )
}

export default ProfileCommunityBanner
