import React from 'react'

import AppDrawer from '~/components/common/pages/RootPage/AppDrawer'
import { ServiceType } from '~/constants/common/app'
import {
  mainCareerRoutes,
  mainCareerCompanyAddition,
  otherCareerRoutes,
} from '~/utils/career/routes'
import { otherRoutes } from '~/utils/common/routes'

const PCLayout: React.FC = () => (
  <AppDrawer.Navigator
    initialRouteName="career"
    serviceType={ServiceType.CAREER}
  >
    {[
      ...mainCareerRoutes,
      ...otherCareerRoutes,
      ...otherRoutes,
      ...mainCareerCompanyAddition,
    ].map((route) => (
      <AppDrawer.Screen key={route.name} {...route} />
    ))}
  </AppDrawer.Navigator>
)

export default PCLayout
