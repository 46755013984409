import { isEmpty } from 'lodash'
import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import PostMessage from '~/components/career/molecules/post/PostMessage'
import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import color from '~/constants/common/color'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'

type Props = {
  comment: Post
  isPC: boolean
  currentUser?: User
  isEditMode?: boolean
  controlMenu?: React.ReactElement
  postCommentForm?: React.ReactElement
  socialActivityComponent?: React.ReactElement
}

const CommentItem: React.FC<Props> = ({
  comment,
  isEditMode,
  controlMenu,
  postCommentForm,
  socialActivityComponent,
}: Props) => {
  const user = comment.user

  const renderCommentItem = (
    <View style={styles.commentContainer} testID="comment-container">
      <View style={styles.comment}>
        <View style={styles.wrapUser}>
          <UserName size="small" user={user} />
        </View>
        <PostMessage message={comment.message} isComment={true} />
        <View style={styles.commentOption}>{controlMenu}</View>
        {!isEmpty(comment.images) || !isEmpty(comment.videos) ? (
          <View style={styles.media}>
            <MediaArea entity={comment} />
          </View>
        ) : undefined}
      </View>
    </View>
  )

  return isEditMode && postCommentForm ? (
    postCommentForm
  ) : (
    <View style={styles.container} testID="comment-item">
      <View style={styles.postCommentContainer}>
        <UserAvatar user={user} size={ProfileAvatarSize.TINY} />
        <View style={styles.fillView}>
          {renderCommentItem}
          {socialActivityComponent}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  action: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    paddingLeft: 10,
    paddingTop: 4,
  },
  actionSeparator: {
    backgroundColor: color.gray,
    marginHorizontal: 6,
    paddingVertical: 6,
    width: 1,
  },
  comment: {
    flexShrink: 1,
    padding: 14,
    width: '100%',
  },
  commentContainer: {
    alignItems: 'center',
    backgroundColor: color.primaryLight,
    borderRadius: 10,
    flexDirection: 'row',
    flex: 1,
    marginLeft: 10,
  },
  commentOption: {
    position: 'absolute',
    right: 20,
  },
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  fillView: {
    flex: 1,
  },
  media: {
    marginTop: 6,
    maxWidth: 100,
    ...Platform.select({
      web: { maxWidth: 150 },
    }),
  },
  postCommentContainer: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
  },
  separator: {
    width: 6,
  },
  wrapUser: {
    marginBottom: 2,
  },
})

export default CommentItem
