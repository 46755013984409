import { useCallback, useEffect, useState } from 'react'
import type { DependencyList } from 'react'

import { API } from '~/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = <R>(...args: any[]) => R

const useAPI = <F extends AnyFunction, R>(
  fn: (api: API, ...args: Parameters<F>) => Promise<R>,
  deps?: DependencyList
): ((...args: Parameters<F>) => Promise<R | undefined>) => {
  const [api, setAPI] = useState<API | undefined>(undefined)

  useEffect(() => {
    const instance = new API()
    setAPI(instance)
    return instance.abort
  }, [setAPI, ...(deps || [])])

  const wrapped = useCallback(
    async (...args: Parameters<F>): Promise<R | undefined> => {
      if (api == null) {
        return
      }
      try {
        return await fn(api, ...args)
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }
        throw e
      }
    },
    [api, ...(deps || [])]
  )

  return wrapped
}
export default useAPI
