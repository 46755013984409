import React from 'react'
import { View, StyleSheet } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import FollowButton from '~/components/common/atoms/users/FollowButton'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'

type Props = {
  user?: User
  isPC: boolean
  isHideTitle?: boolean
  isHideFollowButton?: boolean
}

const UserHorizontalItem: React.FC<Props> = ({
  user,
  isPC,
  isHideTitle,
  isHideFollowButton,
}: Props) => (
  <View
    style={isPC ? styles.container : styles.containerMobile}
    testID="user-horizontal-item"
  >
    <UserAvatar user={user} size={ProfileAvatarSize.SMALL} />
    <View style={isPC ? styles.profileInfo : styles.profileInfoMobile}>
      <UserName size="small" user={user} />
      {!isHideTitle && (
        <Text
          testID="user-horizontal-item-title"
          style={styles.description}
          numberOfLines={2}
        >
          {user?.profile?.title}
        </Text>
      )}
    </View>
    {!isHideFollowButton && <FollowButton user={user} />}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 2,
    padding: 4,
    width: 141.5,
  },
  containerMobile: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    flex: 1,
    padding: 5,
    width: '110%',
  },
  description: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.GENERAL,
  },
  profileInfo: {
    marginLeft: 5,
    width: 100,
  },
  profileInfoMobile: {
    marginLeft: 3,
    marginRight: 5,
    width: '45%',
  },
})

export default UserHorizontalItem
