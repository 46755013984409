import { useNavigation } from '@react-navigation/native'
import snakecaseKeys from 'snakecase-keys'

import { RootStackParamList } from '~/interfaces/RootStackParamList'

const useCustomNavigation = () => {
  const navigation = useNavigation()

  function setQueryParams<RouteName extends keyof RootStackParamList>(
    params: Partial<RootStackParamList[RouteName]>
  ): void {
    navigation.isFocused() &&
      navigation.setParams(snakecaseKeys(params) as never)
  }

  return { setQueryParams }
}

export default useCustomNavigation
