import { useDispatch, useSelector } from 'react-redux'

import {
  DeviceType,
  deviceIsMobileSelector,
  deviceIsPCSelector,
  deviceIsTabletSelector,
  getDeviceType,
  setDeviceType as setDeviceTypeAction,
} from '~/features/commons/deviceSlice'

interface DeviceProps {
  getDeviceType: (width: number) => DeviceType
  setDeviceType: (width: number) => void
  isPC: boolean
  isMobile: boolean
  isTablet: boolean
}

const useDevice = (): DeviceProps => {
  const dispatch = useDispatch()
  const isPC = useSelector(deviceIsPCSelector)
  const isMobile = useSelector(deviceIsMobileSelector)
  const isTablet = useSelector(deviceIsTabletSelector)

  const setDeviceType = (width: number) => {
    const deviceType = getDeviceType(width)
    dispatch(setDeviceTypeAction(deviceType))
  }

  return { getDeviceType, setDeviceType, isMobile, isPC, isTablet }
}

export default useDevice
