import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import UserName from '~/components/common/atoms/users/UserName'
import QuestionUserAvatar from '~/components/forum/molecules/questions/QuestionUserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'

type Props = {
  user: User
  date: string
}

const AnswerItemHeader: React.FC<Props> = ({ user, date }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="answer-item-header">
      <QuestionUserAvatar user={user} isQuestioner={false} />
      <View style={styles.info}>
        <UserName size="small" user={user} />
        <Text style={styles.date}>
          {t('community.information.created', {
            time: moment(date).locale(i18n.language).fromNow(),
          })}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  date: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  info: {
    marginLeft: 20,
  },
})

export default AnswerItemHeader
