import Home from '~/components/career/pages/Home'
import JobShow from '~/components/career/pages/JobShow'
import Jobs from '~/components/career/pages/Jobs'
import Messages from '~/components/career/pages/Messages'
import PostDetail from '~/components/career/pages/PostDetail'
import CompanyJobDashboard from '~/components/career/pages/company/CompanyJobDashboard'
import CompanyJobEdit from '~/components/career/pages/company/CompanyJobEdit'
import CompanyPanel from '~/components/career/pages/company/CompanyPanel'
import CompanyShow from '~/components/career/pages/company/CompanyShow'
import ResumeEdit from '~/components/career/pages/resume/ResumeEdit'
import ResumeIndex from '~/components/career/pages/resume/ResumeIndex'
import ResumeNew from '~/components/career/pages/resume/ResumeNew'
import CareerUser from '~/components/career/pages/users/CareerUser'
import Users from '~/components/common/pages/Users'
import Plan from '~/components/common/pages/subscription/SubscriptionPlan'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'
import { Route } from '~/utils/common/routes'

const ROUTE_RESUME_NEW: Route<keyof OtherStackParamList> = {
  name: 'resumeNew',
  component: ResumeNew,
}
const ROUTE_RESUME_EDIT: Route<keyof OtherStackParamList> = {
  name: 'resumeEdit',
  component: ResumeEdit,
}
const ROUTE_USERS: Route<keyof OtherStackParamList> = {
  name: 'users',
  component: Users,
}
const ROUTE_POST_DETAILS: Route<keyof OtherStackParamList> = {
  name: 'postDetails',
  component: PostDetail,
}
const ROUTE_JOB_SHOW: Route<keyof OtherStackParamList> = {
  name: 'jobShow',
  component: JobShow,
}
const ROUTE_COMPANY_SHOW: Route<keyof OtherStackParamList> = {
  name: 'companyShow',
  component: CompanyShow,
}
const ROUTE_USER_DETAIL: Route<keyof OtherStackParamList> = {
  name: 'userDetail',
  component: CareerUser,
}
const ROUTE_MY_PAGE: Route<keyof OtherStackParamList> = {
  name: 'mypage',
  component: CareerUser,
  labelTranslationKey: 'menu.mypage',
}

const MAIN_ROUTE_CAREER: Route<keyof MainStackParamList> = {
  name: 'career',
  component: Home,
  labelTranslationKey: 'menu.home',
}
const MAIN_ROUTE_COMPANY_PANEL: Route<keyof OtherStackParamList> = {
  name: 'companyPanel',
  component: CompanyPanel,
  labelTranslationKey: 'menu.companyPanel',
}
const MAIN_ROUTE_JOBS: Route<keyof MainStackParamList> = {
  name: 'jobs',
  component: Jobs,
  labelTranslationKey: 'menu.jobs',
}
export const MAIN_ROUTE_JOBS_DASHBOARD: Route<keyof MainStackParamList> = {
  name: 'jobsDashboard',
  component: CompanyJobDashboard,
  labelTranslationKey: 'menu.jobsDashboard',
}
export const ROUTE_COMPANY_JOB_CREATE: Route<keyof OtherStackParamList> = {
  name: 'companyJobCreate',
  component: CompanyJobEdit,
}
export const ROUTE_COMPANY_JOB_EDIT: Route<keyof OtherStackParamList> = {
  name: 'companyJobEdit',
  component: CompanyJobEdit,
}
const MAIN_ROUTE_RESUME_INDEX: Route<keyof MainStackParamList> = {
  name: 'resumeIndex',
  component: ResumeIndex,
  labelTranslationKey: 'menu.resume',
}
const MAIN_ROUTE_MESSAGES: Route<keyof MainStackParamList> = {
  name: 'messages',
  component: Messages,
  labelTranslationKey: 'menu.messages',
}
const MAIN_ROUTE_SUBSCRIPTION: Route<keyof OtherStackParamList> = {
  name: 'subscriptionPlan',
  component: Plan,
}

// Company
export const otherCareerRoutesCompany: Route<keyof OtherStackParamList>[] = [
  MAIN_ROUTE_COMPANY_PANEL,
  ROUTE_COMPANY_JOB_CREATE,
  ROUTE_COMPANY_JOB_EDIT,
  MAIN_ROUTE_SUBSCRIPTION,
]

/**
 * Add router for another pages that is not display in Mobile BottomNavigator
 */
export const otherCareerRoutes: Route<keyof OtherStackParamList>[] = [
  ROUTE_RESUME_NEW,
  ROUTE_RESUME_EDIT,
  ROUTE_USERS,
  ROUTE_POST_DETAILS,
  ROUTE_JOB_SHOW,
  ROUTE_COMPANY_SHOW,
  ROUTE_USER_DETAIL,
  ROUTE_MY_PAGE,
  ...otherCareerRoutesCompany,
]

export const mainCareerRoutes: Route<keyof MainStackParamList>[] = [
  MAIN_ROUTE_CAREER,
  MAIN_ROUTE_JOBS,
  MAIN_ROUTE_RESUME_INDEX,
  MAIN_ROUTE_MESSAGES,
]

export const mainCareerCompanyAddition: Route<keyof MainStackParamList>[] = [
  MAIN_ROUTE_JOBS_DASHBOARD,
]

export const mainCareerRoutesCompany: Route<keyof MainStackParamList>[] = [
  MAIN_ROUTE_CAREER,
  MAIN_ROUTE_JOBS,
  ...mainCareerCompanyAddition,
  MAIN_ROUTE_MESSAGES,
]
