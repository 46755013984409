import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ScrollState = {
  isBottom: boolean
}

const scrollSlice = createSlice({
  name: 'scroll',
  initialState: {
    isBottom: false,
  } as ScrollState,
  reducers: {
    setIsBottom(state: ScrollState, action: PayloadAction<boolean>): void {
      state.isBottom = action.payload
    },
  },
})

export const { setIsBottom } = scrollSlice.actions

export default scrollSlice.reducer
