import { BaseAPI } from '~/api/baseAPI'

export type UpdateMemberBodyParams = {
  userWorkingCompany: { isAdmin: boolean }
}

export type CreateMemberBodyParams = {
  userId: string
  email: string
}

export class CompanyMembersAPI extends BaseAPI {
  configPath(companyId: number): CompanyMembersAPI {
    this.path = `/companies/${companyId}/members`
    return this
  }
}
