import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import SelectableList, { ID } from '~/components/common/features/SelectableList'
import color from '~/constants/common/color'
import { recruitmentFactory } from '~/factories/recruitment'
import SelectListItem from '~/interfaces/SelectListItem'
import { currentCompanySelector } from '~/slices/career/company'

const RecruitmentPostSelectableList: React.FC = () => {
  const { t } = useTranslation()
  const company = useSelector(currentCompanySelector)
  const [data, setData] = useState<SelectListItem[]>([])
  const [selectedItem, setSelectedItem] = useState<SelectListItem | undefined>()
  const recruitments =
    company?.recruitmentPosts ?? recruitmentFactory.buildList(2)

  useEffect(() => {
    setData(
      recruitments.map((item) => ({
        id: item.id,
        name: item.subject,
      }))
    )
  }, [company?.id])

  const selectItem = (itemID: ID): void =>
    setSelectedItem(data.find((item) => item.id === itemID))

  return (
    <CardContainer
      style={styles.container}
      testID="recruitment-post-selectable-list"
    >
      <SelectableList
        data={[{ title: t('company.applicant.activeRecruitingPost'), data }]}
        onSelect={selectItem}
        selectedId={selectedItem?.id || 0}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    backgroundColor: color.white,
    marginRight: 10,
    overflow: 'hidden',
    width: 200,
  },
})

export default RecruitmentPostSelectableList
