import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { TextStyle, TouchableOpacity } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Community from '~/interfaces/Community'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  community?: Community
  size?: 'large' | 'medium' | 'small'
  isText?: boolean
}

const CommunityName: React.FC<Props> = ({ community, size, isText }: Props) => {
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const textColor: string = isText ? color.white : color.pressableText
  const fontStyle = (): TextStyle => {
    switch (size) {
      case 'large':
        return { fontSize: FontSize.IMPORTANT, lineHeight: 30 }
      case 'medium':
        return { fontSize: FontSize.REMARKABLE, lineHeight: 22 }
      case 'small':
        return { fontSize: FontSize.GENERAL, lineHeight: 16 }
      default:
        return { fontSize: FontSize.GENERAL, lineHeight: 16 }
    }
  }

  const textStyle = { color: textColor, ...fontStyle() }

  const navigateToCommunityPage = (): void => {
    community && navigate('communityDetail', { id: community.id })
  }

  return isText ? (
    <TitleText style={textStyle as TextStyle} testID="community-name-text">
      {community?.name}
    </TitleText>
  ) : (
    <TouchableOpacity
      onPress={navigateToCommunityPage}
      testID="community-name-clickable"
    >
      <TitleText
        style={textStyle as TextStyle}
        testID="community-name-text"
        numberOfLines={2}
      >
        {community?.name}
      </TitleText>
    </TouchableOpacity>
  )
}

export default CommunityName
