import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  onPress?: () => void
  communityEvent: CommunityEvent
}

const EventAttendanceButton: React.FC<Props> = ({
  onPress,
  communityEvent,
}: Props) => {
  const { t } = useTranslation()
  const realWorldTitle = communityEvent.isJoined
    ? t('events.attending')
    : t('events.attend')
  const virtualTitle = communityEvent.isJoined
    ? t('community.joined')
    : t('events.joinNow')

  const title = communityEvent.isVirtual ? virtualTitle : realWorldTitle

  return (
    <View testID="event-attendance-button" style={styles.container}>
      <Button
        type="outline"
        title={title}
        onPress={onPress}
        disabled={communityEvent.isJoined}
        titleStyle={commonStyles.buttonTextSize}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 120,
    backgroundColor: color.white,
    ...commonStyles.borderRadiusAllCorner,
  },
})

export default EventAttendanceButton
