import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { API } from '~/api'
import { UserQuestionQueryParams } from '~/api/userQuestions'
import CardContainer from '~/components/common/atoms/CardContainer'
import EmptyView from '~/components/common/molecules/EmptyView'
import SkeletonListItem from '~/components/common/molecules/SkeletonListItem'
import SkeletonList from '~/components/common/organisms/SkeletonList'
import QuestionControlMenu from '~/components/forum/features/questions/QuestionControlMenu'
import UserQuestionItem from '~/components/forum/organisms/users/UserQuestionItem'
import useDevice from '~/hooks/commons/useDevice'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import Question from '~/interfaces/Question'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'
import { getCategoriesFromRoute, getRouteParams } from '~/utils/navigation'

type Props = {
  userId?: number
  header?: React.ReactElement
}

const keyExtractor = (item: Question, index: number): string =>
  `${item.id}-${index}`

const UserQuestionList: React.FC<Props> = ({ header, userId }: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const defaultPage = 1
  const routeParams = getRouteParams<UserQuestionQueryParams>()
  const categories = getCategoriesFromRoute(routeParams.categories)
  const [queryParams, setQueryParams] = useState<UserQuestionQueryParams>({
    ...routeParams,
    categories,
    userId,
  })

  const currentPage = routeParams.page || 1
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(true)
  const [questions, setQuestions] = useState<Question[]>([])
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const currentUser = useSelector(currentUserSelector)
  const shouldShowSkeleton = isEmpty(questions) && isLoading

  const fetchUserQuestions = useAPI(
    async (api: API, page: number): Promise<void> => {
      if (!queryParams.userId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.userQuestions
          .configPath(queryParams.userId)
          .index<Question[], UserQuestionQueryParams>({
            ...queryParams,
            page,
          })

        if (queryParams.page && page > queryParams.page) {
          const isEmptyResponse = isEmpty(response)
          !isEmptyResponse &&
            setQuestions((questions) => [...questions, ...response])
          !isEmptyResponse && setQueryParams({ ...queryParams, page })
        } else {
          setQuestions(response)
        }
      } catch (err) {
        toast.showError(err)
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [
      setIsLoading,
      queryParams.categories,
      queryParams.q,
      queryParams.userId,
      queryParams.sortBy,
    ]
  )

  const hideQuestion = useCallback(
    (question: Question) => {
      setQuestions((questions: Question[]) =>
        questions.filter((item) => item.id != question.id)
      )
    },
    [setQuestions]
  )

  useFocusEffect(
    React.useCallback(() => {
      setQueryParams((queryParams) => ({
        ...queryParams,
        userId,
        categories,
        searchWord: routeParams.q,
        page: routeParams.page || defaultPage,
        sortBy: routeParams.sortBy,
      }))
    }, [
      categories,
      routeParams.sortBy,
      routeParams.page,
      routeParams.userId,
      routeParams.q,
    ])
  )

  const fetchMoreQuestion = (): void => {
    !isLoading && queryParams.page && fetchUserQuestions(queryParams.page + 1)
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchUserQuestions(currentPage)
    }, [fetchUserQuestions])
  )

  useEffect(() => {
    isBottom && fetchMoreQuestion()
  }, [isBottom])

  const renderItem: ListRenderItem<Question> = ({
    item,
  }: {
    item: Question
  }) => (
    <UserQuestionItem
      question={item}
      questionControlMenu={
        item.user.id === currentUser?.id ? (
          <QuestionControlMenu question={item} onDelete={hideQuestion} />
        ) : undefined
      }
    />
  )

  return (
    <View testID="user-question-list" style={styles.container}>
      {shouldShowSkeleton ? (
        <SkeletonList
          count={3}
          numColumns={1}
          itemComponent={
            <CardContainer style={styles.loadingItemContainer}>
              <SkeletonListItem />
            </CardContainer>
          }
        />
      ) : (
        <FlatList
          data={questions}
          scrollEnabled={true}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          style={styles.questionList}
          ListHeaderComponent={header}
          ListEmptyComponent={
            isLoading ? undefined : (
              <EmptyView
                isPC={isPC}
                title={t('jobs.emptyPages.question.title')}
                imageSource={require('~/assets/images/prompts/nopostsyet.png')}
              />
            )
          }
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingItemContainer: {
    marginBottom: 20,
    width: '100%',
  },
  questionList: {
    height: '100%',
  },
})

export default UserQuestionList
