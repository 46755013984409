import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'
import Profile from '~/interfaces/Profile'

export type UserProfile = {
  userProfile: Partial<Profile>
}

export type UsersParams = {
  job?: string
  page?: number
  name?: string
  country?: string
  business?: string
  randomSize?: number
  excludeMe?: boolean
  isGraduated?: boolean
  japaneseLevel?: string
  career?: string
} & BaseQueryParams

export class UsersAPI extends BaseAPI {
  path = '/users'
}
