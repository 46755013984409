import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { format } from 'timeago.js'

import CardContainer from '~/components/common/atoms/CardContainer'
import UserHorizontalItem from '~/components/common/molecules/users/UserHorizontalItem'
import BasicInfoItem from '~/components/community/molecules/information/BasicInfoItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Community from '~/interfaces/Community'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'

type Props = {
  community: Community
  isPC: boolean
}

const userDisplayName = (user: User, t: TFunction): string => {
  return user?.profile
    ? t('profile.displayName', {
        familyName: user.profile.givenName,
        givenName: user.profile.familyName,
      })
    : ''
}

const BasicInfo: React.FC<Props> = ({ community, isPC }: Props) => {
  const { t } = useTranslation()
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const admins =
    community.communityMembers?.filter((m) => m.isAdmin).map((m) => m.user) ??
    []

  const adminsInfo = (
    <View testID="community-admins-list">
      {admins?.map((item, index) => (
        <UserHorizontalItem
          key={index}
          user={item}
          isPC={isPC}
          isHideTitle
          isHideFollowButton
        />
      ))}
    </View>
  )
  return (
    <CardContainer style={containerStyle} testID="community-basic-info">
      <View style={styles.header}>
        <Text style={styles.title}>{t('community.information.basicInfo')}</Text>
      </View>
      <View style={styles.content}>
        <BasicInfoItem
          isPC={isPC}
          title={t('community.information.createdBy')}
          content={userDisplayName(community?.user, t)}
        />
        <BasicInfoItem
          isPC={isPC}
          title={t('community.information.createdAt')}
          content={format(new Date(community.createdAt), i18n.language)}
        />
        <BasicInfoItem
          isPC={isPC}
          title={t('community.information.type')}
          content={t(`community.type.${community.category}`)}
        />
        <BasicInfoItem
          isPC={isPC}
          title={t('community.information.admins')}
          subTitle={`(${admins.length})`}
          otherContent={adminsInfo}
        />
      </View>
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 25,
    paddingVertical: 20,
  },
  containerMobile: {
    backgroundColor: color.white,
    marginTop: 10,
    padding: 10,
  },
  content: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default BasicInfo
