import BaseHttpData from '~/interfaces/BaseHttpData'
import User from '~/interfaces/User'

export enum NotifiableType {
  REPORT = 'Report',
  POST = 'Post',
  LIKE = 'Like',
  USER_FOLLOW = 'UserFollow',
  ENTRY = 'Entry',
  ANSWER = 'Answer',
  COMMENT = 'Comment',
  COMMUNITY_MEMBER_INVITATION = 'CommunityMemberInvitation',
  COMMUNITY_MEMBER_JOIN_REQUEST = 'CommunityMemberJoinRequest',
  COMMUNITY_EVENT_NEW_ATTENDEE = 'CommunityEventAttendee',
  COMMUNITY_EVENT_ATTENDEE_INVITATION = 'CommunityEventAttendeeInvitation',
}

export enum NotifyType {
  POST_RECRUIT = 'Post.Recruit',
  POST_IMAGE = 'Post.Image',
  POST_REPLY = 'Post.Reply',
  LIKE = 'Like',
  USER_FOLLOW = 'UserFollow',
  ENTRY_REJECTED = 'Entry.Rejected',
  ENTRY_APPLIED = 'Entry.Applied',
  ANSWER = 'Answer',
  COMMENT = 'Comment',
  POST = 'Post',
  QUESTION = 'Question',
}

export default interface Notification extends BaseHttpData {
  type: string
  recruitId: number
  body: string
  fullBody: string
  wasAlreadyRead: boolean
  notifiableType: NotifiableType
  notifyType: NotifyType
  notifyResourceId: number
  originUser?: User
  date: string
}
