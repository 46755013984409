import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import UserItem from '~/components/common/molecules/UserItem'
import CompanyUser from '~/interfaces/CompanyUser'
import i18n from '~/plugins/i18n'
import { CompanyMemberMenuType } from '~/utils/career/company'
import { getUserNameByLanguage } from '~/utils/common/user'

type Props = {
  companyMember: CompanyUser
  isEditMember?: boolean
  onRemove: (companyUser: CompanyUser) => void
  onUpdate: (companyUser: CompanyUser, isAdmin: boolean) => void
}

const CompanyMemberItem: React.FC<Props> = ({
  companyMember,
  isEditMember,
  onRemove,
  onUpdate,
}: Props) => {
  const { t } = useTranslation()

  const getControlMenuData: ControlMenuData[] = [
    companyMember.isAdmin
      ? {
          id: CompanyMemberMenuType.SET_AS_NORMAL_HR,
          title: t('company.setAsNormalHR'),
        }
      : {
          id: CompanyMemberMenuType.SET_AS_ADMIN,
          title: t('company.setMemberAsAdmin'),
        },
    {
      id: CompanyMemberMenuType.REMOVE,
      title: t('company.member.deleteMemberOption'),
    },
  ]

  const [isShowRemoveMemberDialog, setIsShowRemoveMemberDialog] = useState(
    false
  )
  const [isShowSetMemberRoleDialog, setIsShowSetMemberRoleDialog] = useState(
    false
  )

  const userTitle = companyMember.isAdmin
    ? t('company.member.role.admin')
    : t('company.member.role.hr')

  const onSelectControlMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case CompanyMemberMenuType.REMOVE:
        setIsShowRemoveMemberDialog(true)
        break
      case CompanyMemberMenuType.SET_AS_ADMIN:
      case CompanyMemberMenuType.SET_AS_NORMAL_HR:
        setIsShowSetMemberRoleDialog(true)
        break
      default:
        break
    }
  }

  const removeMember = async () => {
    if (isNil(companyMember)) {
      return
    }
    onRemove(companyMember)
    setIsShowRemoveMemberDialog(false)
  }

  const setMemberRole = (isAdmin: boolean) => {
    onUpdate(companyMember, isAdmin)
    setIsShowSetMemberRoleDialog(false)
  }

  return (
    <View testID="company-member-item" style={styles.container}>
      <UserItem user={companyMember.user} title={userTitle} />
      {isEditMember && (
        <View testID="control-menu-company">
          <ControlMenu
            onSelect={(item): void => {
              onSelectControlMenu(item)
            }}
            data={getControlMenuData}
          />
          <ConfirmationDialog
            isDanger
            onPressAccept={removeMember}
            visible={isShowRemoveMemberDialog}
            onPressCancel={(): void => setIsShowRemoveMemberDialog(false)}
            message={t('company.removeUserConfirmMessage', {
              user: getUserNameByLanguage(i18n.language, companyMember?.user),
            })}
          />
          <ConfirmationDialog
            isDanger
            onPressAccept={() => setMemberRole(!companyMember.isAdmin)}
            visible={isShowSetMemberRoleDialog}
            onPressCancel={(): void => setIsShowSetMemberRoleDialog(false)}
            message={
              companyMember.isAdmin
                ? t('company.setMemberAsNormalHRMessage', {
                    user: getUserNameByLanguage(
                      i18n.language,
                      companyMember?.user
                    ),
                  })
                : t('company.setMemberAsAdminMessage', {
                    user: getUserNameByLanguage(
                      i18n.language,
                      companyMember?.user
                    ),
                  })
            }
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
})

export default CompanyMemberItem
