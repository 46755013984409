import React from 'react'
import { View, StyleSheet } from 'react-native'

import CustomPicker from '~/components/common/molecules/CustomPicker'
import { ColorTheme } from '~/constants/common/color'
import CommunityChannel from '~/interfaces/CommunityChannel'

type Props = {
  channels: CommunityChannel[]
  currentChannel?: CommunityChannel
  onChange: (channelId: number | string) => void
}

const ChannelFilter: React.FC<Props> = ({
  channels,
  onChange,
  currentChannel,
}: Props) => (
  <View testID="channel-filter" style={styles.container}>
    <CustomPicker
      size="full"
      colorTheme={ColorTheme.Light}
      selectedValue={currentChannel?.id}
      onValueChange={(item) => onChange(item as number)}
      data={channels.map((item) => ({
        value: item.id,
        label: `${!item.isPublic ? '🔒' : ''} ${item.name}`,
      }))}
    />
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    maxWidth: 240,
  },
})

export default ChannelFilter
