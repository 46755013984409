import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import {
  EventQueryParams,
  EventFilterKeys,
} from '~/api/communities/communityEvents'
import FilterPopupMenu, {
  FilterPopupData,
} from '~/components/common/molecules/FilterPopupMenu'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams, isReadyNavigationRef } from '~/utils/navigation'

const FILTER_INDEX_OF_ALL = 0
const FILTER_INDEX_OF_VIRTUAL = 1
const FILTER_INDEX_OF_REAL_WORLD = 2

const EventFilterPopup: React.FC = () => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const eventQueryParams = getRouteParams<EventQueryParams>()
  const [selectedFilter, setSelectedFilter] = useState<
    FilterPopupData | undefined
  >(undefined)

  const eventFilterPopupData: FilterPopupData[] = [
    { id: EventFilterKeys.ALL_EVENT, name: t('events.filterPopup.all') },
    {
      id: EventFilterKeys.VIRTUAL_EVENT,
      name: t('events.filterPopup.virtualEvents'),
    },
    {
      id: EventFilterKeys.REAL_WORLD_EVENT,
      name: t('events.filterPopup.realWorldEvents'),
    },
  ]

  const getFilterDataByParams = (): FilterPopupData => {
    switch (eventQueryParams?.filterBy) {
      case EventFilterKeys.VIRTUAL_EVENT:
        return eventFilterPopupData[FILTER_INDEX_OF_VIRTUAL]
      case EventFilterKeys.REAL_WORLD_EVENT:
        return eventFilterPopupData[FILTER_INDEX_OF_REAL_WORLD]
      default:
        return eventFilterPopupData[FILTER_INDEX_OF_ALL]
    }
  }

  const changeSelectedFilter = (item: FilterPopupData): void => {
    setSelectedFilter(item)
    setQueryParams({ filterBy: item.id as EventFilterKeys })
  }

  useEffect(() => {
    isReadyNavigationRef.current &&
      changeSelectedFilter(getFilterDataByParams())
  }, [isReadyNavigationRef.current, eventQueryParams?.filterBy])

  return (
    <View testID="event-filter-popup">
      <FilterPopupMenu
        data={eventFilterPopupData}
        selectedItem={selectedFilter}
        onSelect={changeSelectedFilter}
        menuTrigger={
          <Text style={styles.selectedFilter} testID="menu-trigger">
            {selectedFilter?.name}
          </Text>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  selectedFilter: {
    color: color.pressableText,
    paddingVertical: 6,
    ...commonStyles.bodyTextSize,
  },
})

export default EventFilterPopup
