import { MediaTypeOptions } from 'expo-image-picker'
import React from 'react'
import { ButtonProps } from 'react-native-elements'
import 'react-native-get-random-values'

import Button from '~/components/workarounds/Button'
import useImagePicker from '~/hooks/useImagePicker'
import ImageKind from '~/interfaces/ImageKind'
import Media from '~/interfaces/Media'

type Props = Omit<ButtonProps, 'onPress'> & {
  kind: ImageKind
  mediaTypes?: MediaTypeOptions
  allowsMultipleSelection?: boolean
  onUpload?: (media: Media[]) => void
  onError?: (error: Error) => void
  onLoading?: (isLoading: boolean) => void
  // TODO isDirectUpload should be removed after refactoring all the image uploading that use direct upload.
  isDirectUpload?: boolean
}

const ImagePickerButton: React.FC<Props> = ({
  kind,
  onUpload,
  onError,
  allowsMultipleSelection,
  mediaTypes,
  loading,
  onLoading,
  isDirectUpload,
  ...props
}: Props) => {
  const pressHandler = useImagePicker({
    kind,
    onUpload,
    onError,
    allowsMultipleSelection,
    mediaTypes,
    onLoading,
    isDirectUpload,
  })
  return (
    <Button
      {...props}
      loading={loading}
      onPress={pressHandler}
      testID="image-picker-button"
    />
  )
}
export default ImagePickerButton
