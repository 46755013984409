import React from 'react'
import { StyleSheet, View } from 'react-native'

import PostList from '~/components/career/features/posts/PostList'
import DailyQuiz from '~/components/career/features/quizzes/DailyQuiz'
import HomeRightColumn from '~/components/career/organisms/posts/HomeRightColumn'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

const HomeTemplate: React.FC = () => {
  const { isPC } = useDevice()
  const postFeedStyle = isPC ? styles.postFeedPc : styles.postFeedMobile

  return (
    <View testID="home-template" style={styles.container}>
      {isPC ? (
        <InfiniteScrollContainer>
          <View style={styles.body}>
            <View style={postFeedStyle} testID="post-list-container">
              <PostList />
            </View>
            <View style={styles.rightColumn}>
              <HomeRightColumn />
            </View>
          </View>
        </InfiniteScrollContainer>
      ) : (
        <View style={postFeedStyle} testID="post-list-container">
          <PostList dailyQuiz={<DailyQuiz />} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  container: {
    flex: 1,
  },
  postFeedMobile: {
    flex: 1,
  },
  postFeedPc: {
    width: 590,
  },
  rightColumn: {
    backgroundColor: color.transparent,
    marginLeft: 10,
    width: 320,
  },
})

export default HomeTemplate
