import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, TextStyle, TextInput as InputType } from 'react-native'

import PasswordVisibilitySwitch from '~/components/common/atoms/auth/PasswordVisibilitySwitch'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  originalPassword: string
  verifyChanged: (isValid: boolean) => void
  onSubmitEditing?: () => void
  reference?: RefObject<InputType>
}

type ErrorMessageHook = {
  isShowErrorMessage: boolean
  setShowErrorMessage: (visibility: boolean) => void
}

export const useShowErrorMessage = (): ErrorMessageHook => {
  const [isShowErrorMessage, setShowErrorMessage] = useState(false)
  return { isShowErrorMessage, setShowErrorMessage }
}

const ConfirmPasswordInput: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const { verifyChanged, originalPassword } = props
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const { isShowErrorMessage, setShowErrorMessage } = useShowErrorMessage()

  const switchVisibility = (): void => {
    setSecureTextEntry(!secureTextEntry)
  }

  const handleChangeText = (value: string): void => {
    const valid = value === originalPassword
    if (isShowErrorMessage && valid) {
      setShowErrorMessage(false)
    }
    setInputValue(value)
    verifyChanged(valid)
  }

  const handleBlur = (): void => {
    setShowErrorMessage(inputValue !== originalPassword)
  }

  useEffect(() => {
    verifyChanged(inputValue === originalPassword)
  }, [originalPassword])

  return (
    <Input
      value={inputValue}
      onBlur={handleBlur}
      inputStyle={styles.input}
      testID="confirm-password-input"
      onChangeText={handleChangeText}
      label={t('auth.confirmPassword')}
      secureTextEntry={secureTextEntry}
      labelStyle={styles.label as TextStyle}
      inputContainerStyle={styles.inputContainer}
      rightIconContainerStyle={styles.rightIconContainerStyle}
      rightIcon={
        <PasswordVisibilitySwitch
          secureTextEntry={secureTextEntry}
          onPress={switchVisibility}
        />
      }
      errorMessage={
        isShowErrorMessage ? t('auth.confirmPasswordErrorMessage') : ''
      }
      errorStyle={styles.error}
      onSubmitEditing={props.onSubmitEditing}
      ref={props.reference}
    />
  )
}

const styles = {
  input: {
    paddingLeft: 4,
    fontSize: FontSize.GENERAL,
    ...Platform.select({
      web: { outline: 'none' },
    }),
  },
  inputContainer: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    marginTop: 10,
  },
  error: {
    fontSize: FontSize.GENERAL,
  },
  label: {
    color: color.textGray,
    fontWeight: 'normal',
    fontSize: FontSize.GENERAL,
  },
  rightIconContainerStyle: {
    width: 50,
  },
}

export default ConfirmPasswordInput
