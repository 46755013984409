import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import InterestedGroup from '~/components/career/molecules/resume/InterestedGroup'
import ResumeAvatar from '~/components/career/molecules/resume/templates/ResumeAvatar'
import ResumeUserProfile from '~/components/career/molecules/resume/templates/ResumeUserProfile'
import TemplateBody from '~/components/career/organisms/resumes/TemplateBody'
import TemplateHeader from '~/components/career/organisms/resumes/TemplateHeader'
import AchievementGroup from '~/components/career/organisms/resumes/templates/AchievementGroup'
import ContactGroup from '~/components/career/organisms/resumes/templates/ContactGroup'
import EducationGroup from '~/components/career/organisms/resumes/templates/EducationGroup'
import UserLanguages from '~/components/career/organisms/resumes/templates/UserLanguages'
import UserSkills from '~/components/career/organisms/resumes/templates/UserSkills'
import WorkExperienceGroup from '~/components/career/organisms/resumes/templates/WorkExperienceGroup'
import Resume from '~/interfaces/Resume'
import { getResumePageData } from '~/utils/career/resume'

type Props = {
  resume: Partial<Resume>
}

const EnjinTemplate: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()

  const resumePageData = getResumePageData(resume)

  const renderFirstPage = () => {
    return (
      <View style={styles.fillParent}>
        <EducationGroup
          templateType={resume.templateType!}
          educations={resumePageData?.first?.educations ?? []}
        />
        <WorkExperienceGroup
          templateType={resume.templateType!}
          workExperiences={resumePageData?.first?.workExperiences ?? []}
        />
        <AchievementGroup
          templateType={resume.templateType!}
          achievements={resumePageData?.first?.achievements ?? []}
        />
        <InterestedGroup
          templateType={resume.templateType!}
          value={resume.interestedIndustry || ''}
          reason={resume.interestedIndustryReason || ''}
          categoryTitle={t('resume.interestFieldsTitle')}
        />
        <InterestedGroup
          templateType={resume.templateType!}
          value={resume.interestedJob || ''}
          reason={resume.interestedJobReason || ''}
          categoryTitle={t('resume.interestJobsTitle')}
        />
      </View>
    )
  }

  return (
    <View style={styles.fillParent} testID="enjin-template">
      <View style={styles.partContainer}>
        <TemplateHeader
          templateType={resume.templateType!}
          leftColumn={<ResumeAvatar resume={resume} />}
          rightColumn={<ResumeUserProfile resume={resume} />}
        />
        <TemplateBody
          templateType={resume.templateType!}
          leftColumn={
            <View>
              <UserSkills resume={resume} />
              <View style={styles.separator} />
              <UserLanguages resume={resume} />
              <View style={styles.separator} />
              <ContactGroup resume={resume} />
            </View>
          }
          rightColumn={renderFirstPage()}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  fillParent: {
    flex: 1,
  },
  partContainer: {
    width: '100%',
  },
  separator: {
    marginTop: 40,
  },
})

export default EnjinTemplate
