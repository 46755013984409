import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ImageSourcePropType,
  View,
  useWindowDimensions,
} from 'react-native'

import resumeTemplateEnjinImage from '~/assets/images/resumes/enjin.png'
import resumeTemplateFormalImage from '~/assets/images/resumes/formal.png'
import resumeTemplateMinimalImage from '~/assets/images/resumes/minimal.png'
import resumeTemplateShadesImage from '~/assets/images/resumes/shades.png'
import ResumeTemplateItem from '~/components/career/molecules/resume/ResumeTemplateItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import Resume, { TemplateType } from '~/interfaces/Resume'

type Props = {
  resume: Resume
  onPressItem: (id: string) => void
}

export type ResumeTemplateType = {
  name: string
  type: TemplateType
  imageSource: ImageSourcePropType
}

const ResumeTemplateList: React.FC<Props> = ({
  resume,
  onPressItem,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const { t } = useTranslation()

  const resumeTemplates: ResumeTemplateType[] = [
    {
      type: TemplateType.Enjin,
      name: t('resume.templateRegistry.enjin'),
      imageSource: resumeTemplateEnjinImage,
    },
    {
      type: TemplateType.Shades,
      name: t('resume.templateRegistry.shades'),
      imageSource: resumeTemplateShadesImage,
    },
    {
      type: TemplateType.Formal,
      name: t('resume.templateRegistry.formal'),
      imageSource: resumeTemplateFormalImage,
    },
    {
      type: TemplateType.Minimal,
      name: t('resume.templateRegistry.minimal'),
      imageSource: resumeTemplateMinimalImage,
    },
  ]

  const renderItem = ({
    item,
  }: {
    item: ResumeTemplateType
  }): React.ReactElement => (
    <TouchableOpacity
      style={styles.resumeTemplateItem}
      onPress={(): void => onPressItem(item.type)}
    >
      <ResumeTemplateItem data={item} resume={resume as Resume} />
    </TouchableOpacity>
  )

  const keyExtractor = (item: ResumeTemplateType): string => item.type

  const { width } = useWindowDimensions()
  const wrapperStyle = isPC
    ? styles.wrapper
    : [styles.wrapper, { height: width * 0.35 }]

  return (
    <View
      testID="resume-template-list"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <Text style={styles.title}>{t('resume.selectTemplateType')}</Text>
      <FlatList
        numColumns={2}
        data={resumeTemplates}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        columnWrapperStyle={wrapperStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginRight: -12,
  },
  containerMobile: {
    marginRight: -12,
  },
  resumeTemplateItem: {
    marginTop: 12,
    marginRight: 12,
    flex: 1,
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    color: color.textGray,
  },
  wrapper: {
    justifyContent: 'space-between',
    height: 225,
  },
})

export default ResumeTemplateList
