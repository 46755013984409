import { useNavigation } from '@react-navigation/native'
import React, { RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TextInput } from 'react-native'

import EmailInput from '~/components/common/atoms/auth/EmailInput'
import PasswordInput from '~/components/common/atoms/auth/PasswordInput'
import TermsAndPolicy from '~/components/common/molecules/auth/TermsAndPolicy'
import Button from '~/components/workarounds/Button'
import Link from '~/components/workarounds/Link'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isPC: boolean
  loginFacebookButton?: React.ReactElement
  onPressLoginButton: (email: string, password: string) => void
}

const LoginForm: React.FC<Props> = ({
  isPC,
  onPressLoginButton,
  loginFacebookButton,
}: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const passwordRef = useRef<TextInput>() as RefObject<TextInput>
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  const horizontalStyle = isPC ? styles.horizontal : styles.horizontalMobile
  const loginActionStyle = isPC ? styles.loginAction : styles.loginActionMobile
  const containerStyle = isPC ? styles.container : styles.containerMobile

  const handleEmail = (value: string, isValid: boolean): void => {
    setEmailValid(isValid)
    setEmail(value)
  }

  const handlePassword = (value: string, isValid: boolean): void => {
    setPasswordValid(isValid)
    setPassword(value)
  }

  return (
    <View style={containerStyle}>
      <EmailInput
        onChangeText={handleEmail}
        onSubmitEditing={(): void => passwordRef.current?.focus()}
      />
      <PasswordInput
        onChangeText={handlePassword}
        onSubmitEditing={(): void => {
          emailValid && passwordValid && onPressLoginButton(email, password)
        }}
        ref={passwordRef}
      />
      <Button
        buttonStyle={StyleSheet.flatten(styles.loginButton)}
        titleStyle={commonStyles.buttonTextSize}
        title={t('auth.login')}
        onPress={(): void => {
          onPressLoginButton(email, password)
        }}
        disabled={!(emailValid && passwordValid)}
        testID="submit"
      />
      <View style={loginActionStyle} testID="login-action">
        <Link to="/request-password-reset" style={styles.forgotPassword}>
          {t('auth.forgotPassword')}
        </Link>
      </View>
      <View style={horizontalStyle} />
      {loginFacebookButton}
      <Button
        type="outline"
        testID="sign-up-button"
        title={t('auth.signup')}
        titleStyle={commonStyles.buttonTextSize}
        onPress={(): void => {
          navigate('signup')
        }}
        containerStyle={StyleSheet.flatten(styles.signupButton)}
      />
      <TermsAndPolicy />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: 415,
  },
  containerMobile: {
    padding: 35,
    width: '100%',
  },
  forgotPassword: {
    alignSelf: 'center',
    color: color.textGray,
    margin: 20,
    fontSize: FontSize.GENERAL,
  },
  horizontal: {
    backgroundColor: color.lightGray,
    height: 1,
    marginBottom: 48,
    marginHorizontal: 10,
    marginTop: 28,
  },
  horizontalMobile: {
    backgroundColor: color.lightGray,
    height: 1,
    margin: 10,
  },
  loginAction: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  loginActionMobile: {
    justifyContent: 'center',
    marginTop: 10,
  },
  loginButton: {
    marginTop: 20,
    paddingVertical: 10,
  },
  signupButton: {
    marginTop: 10,
    margin: 6,
  },
})

export default LoginForm
