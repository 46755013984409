import { Fontisto } from '@expo/vector-icons'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu'

import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

const { Popover } = renderers

export type FilterPopupData = {
  id: string | number
  name: string
}

type Props = {
  data: FilterPopupData[]
  selectedItem?: FilterPopupData
  menuTrigger: React.ReactElement
  onSelect?: (item: FilterPopupData) => void
}

const FilterPopupMenu: React.FC<Props> = ({
  data,
  onSelect,
  menuTrigger,
  selectedItem,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false)

  const optionSelect = (item: FilterPopupData): void => {
    onSelect && onSelect(item)
    setIsOpened(false)
  }

  return (
    <View testID="filter-popup-menu">
      <Menu
        renderer={Popover}
        onBackdropPress={(): void => setIsOpened(false)}
        rendererProps={{
          preferredPlacement: 'bottom',
          anchorStyle: styles.anchor,
        }}
      >
        <MenuTrigger onPress={(): void => setIsOpened(true)}>
          <View style={styles.menuTrigger}>
            {menuTrigger}
            <View style={styles.spaceBetween} />
            <Fontisto
              size={10}
              name={isOpened ? 'angle-up' : 'angle-down'}
              color={color.pressableText}
            />
          </View>
        </MenuTrigger>
        <MenuOptions optionsContainerStyle={styles.menuOptions}>
          {data.map((item) => (
            <MenuOption key={item.id} onSelect={(): void => optionSelect(item)}>
              <Text
                numberOfLines={1}
                style={
                  selectedItem?.id === item.id ? styles.selectedText : undefined
                }
              >
                {item.name}
              </Text>
            </MenuOption>
          ))}
        </MenuOptions>
      </Menu>
    </View>
  )
}

const styles = StyleSheet.create({
  anchor: {
    backgroundColor: color.gray,
  },
  menuOptions: {
    backgroundColor: color.gray,
    padding: 10,
    ...commonStyles.shadow,
  },
  menuTrigger: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  selectedText: {
    color: color.black,
    fontWeight: 'bold',
  },
  spaceBetween: {
    paddingHorizontal: 3,
  },
})

export default FilterPopupMenu
