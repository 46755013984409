import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import CompanyInvitePopup from '~/components/career/features/company/CompanyInvitePopup'
import CompanyMemberItem from '~/components/career/organisms/company/CompanyMemberItem'
import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import CompanyUser from '~/interfaces/CompanyUser'
import { SupportedLanguage, getCurrentLanguage } from '~/plugins/i18n'
import {
  currentCompanySelector,
  removeCompanyMember,
  updateCompanyMemberRole,
} from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'

export const getCompanyName = (lang: SupportedLanguage, company: Company) =>
  (lang === SupportedLanguage.EN ? company.enName : company.name) ??
  company.name ??
  company.enName

const CompanyMemberList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useCustomToast()

  const { isPC } = useDevice()
  const company = useSelector(currentCompanySelector)
  const currentUser = useSelector(currentUserSelector)
  const isAdmin = !!company?.userWorkingCompanies.find(
    (i) => i.isAdmin && i.user?.id === currentUser?.id
  )

  const [isShowInviteDialog, setIsShowInviteDialog] = useState(false)

  const toggleInviteDialog = (): void => {
    setIsShowInviteDialog(!isShowInviteDialog)
  }

  const removeMember = async (companyUser: CompanyUser) => {
    try {
      await dispatch(
        removeCompanyMember({
          companyId: companyUser.companyId,
          memberId: companyUser.id,
        })
      )
    } catch (error) {
      toast.showError(error)
    }
  }

  const setMemberRole = async (companyUser: CompanyUser, isAdmin: boolean) => {
    try {
      await dispatch(
        updateCompanyMemberRole({
          isAdmin,
          memberId: companyUser.id,
          companyId: companyUser.companyId,
        })
      )
    } catch (error) {
      toast.showError(error)
    }
  }

  return isNil(company) ? null : (
    <View
      testID="company-member-list"
      style={isPC ? styles.container : undefined}
    >
      <View style={styles.header}>
        <TitleText>
          {t('company.member.title', {
            companyName: getCompanyName(getCurrentLanguage(), company),
            memberCount: currentUser?.subscription?.consumedPerson,
            limitMember:
              currentUser?.subscription?.subscriptionPlan?.maxPersons,
          })}
        </TitleText>
        {isAdmin && (
          <Text
            testID="company-invite-hr"
            style={styles.invitationLink}
            onPress={toggleInviteDialog}
          >
            {t('company.inviteHRButton')}
          </Text>
        )}
      </View>

      <View style={styles.separator} />
      <View style={styles.body}>
        {company.userWorkingCompanies.map((item) => (
          <CompanyMemberItem
            companyMember={item}
            key={item.id}
            isEditMember={isAdmin}
            onRemove={removeMember}
            onUpdate={setMemberRole}
          />
        ))}
      </View>
      {isAdmin && (
        <CompanyInvitePopup
          isShow={isShowInviteDialog}
          onCancelInviteDialog={toggleInviteDialog}
          company={company}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    paddingVertical: 10,
  },
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  invitationLink: {
    borderBottomColor: color.textGray,
    borderBottomWidth: 1,
    paddingBottom: 2,
  },
  separator: {
    marginBottom: 15,
  },
})

export default CompanyMemberList
