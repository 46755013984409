import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'
import {
  Menu,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu'

import ControlMenuButton from '~/components/common/atoms/ControlMenuButton'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import color from '~/constants/common/color'
const { Popover } = renderers
type Props = {
  children: ReactNode
  acceptLabel?: string
  menuTrigger?: ReactNode
  onPressAccept?: () => void
  onPressCancel?: () => void
  confirmationMessage?: string
  isShowConfirmDialog?: boolean
}
const ControlMenuContainer: React.FC<Props> = ({
  children,
  acceptLabel,
  menuTrigger,
  onPressAccept,
  onPressCancel,
  confirmationMessage,
  isShowConfirmDialog,
}: Props) => (
  <>
    <Menu
      renderer={Popover}
      rendererProps={{
        preferredPlacement: 'top',
        anchorStyle: styles.anchor,
      }}
    >
      <MenuTrigger testID="control-menu-container">
        {menuTrigger ? menuTrigger : <ControlMenuButton />}
      </MenuTrigger>
      <MenuOptions optionsContainerStyle={styles.menuOptions}>
        {children}
      </MenuOptions>
    </Menu>
    {isShowConfirmDialog && (
      <ConfirmationDialog
        isDanger={true}
        acceptLabel={acceptLabel}
        onPressAccept={onPressAccept}
        onPressCancel={onPressCancel}
        visible={isShowConfirmDialog}
        message={confirmationMessage || ''}
      />
    )}
  </>
)

const styles = StyleSheet.create({
  anchor: {
    backgroundColor: color.gray,
  },
  menuOptions: {
    backgroundColor: color.gray,
    padding: 10,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 3,
  },
})
export default ControlMenuContainer
