import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'

const SubscriptionSuccess: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View testID="subscription-success" style={styles.container}>
      <Text style={styles.title}>
        {t('subscriptions.subscriptionSuccess.title')}
      </Text>
      <Text style={styles.message}>
        {t('subscriptions.subscriptionSuccess.message')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    lineHeight: 30,
  },
  title: { fontSize: 20, fontWeight: 'bold', marginBottom: 20 },
})

export default SubscriptionSuccess
