import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import SubscriptionPlan from '~/interfaces/SubscriptionPlan'
import { currencyFormat } from '~/utils/common/string'
import { getPriceSummary } from '~/utils/common/subscription'

type Props = {
  plan: SubscriptionPlan
  onPressAnotherOption?: () => void
}

const OrderSummarySection: React.FC<Props> = ({
  plan,
  onPressAnotherOption,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="order-summary-section">
      <Text style={styles.title}>{t('subscriptions.orderSummaryTitle')}</Text>
      <CardContainer style={styles.cardContainer}>
        <View style={styles.orderSummary}>
          <Text style={styles.contentText}>{getPriceSummary(t, plan)}</Text>
          <Text style={styles.chooseOption} onPress={onPressAnotherOption}>
            {t('subscriptions.chooseAnotherOption')}
          </Text>
        </View>
        <View style={styles.separator} />
        <View style={styles.bottomSection}>
          <Text style={styles.bottomSectionText}>
            {t('subscriptions.total')}
          </Text>
          <Text style={styles.bottomSectionText}>
            ¥{currencyFormat(plan.price)}
          </Text>
        </View>
      </CardContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  bottomSection: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bottomSectionText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  cardContainer: {
    flex: 1,
    padding: 30,
    backgroundColor: color.white,
  },
  chooseOption: {
    fontSize: 20,
    textDecorationLine: 'underline',
  },
  contentText: {
    fontSize: 20,
  },
  orderSummary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  separator: {
    borderBottomColor: color.lightGray,
    borderWidth: 1,
    borderColor: color.lightGray,
    marginVertical: 24,
  },
  title: {
    fontSize: 35,
    marginBottom: 24,
    fontWeight: 'bold',
  },
})

export default OrderSummarySection
