import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'
import Toast from 'react-native-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import api from '~/api'
import { UsersParams } from '~/api/users'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import SearchInput from '~/components/common/atoms/SearchInput'
import CommunityUserInvitationItem from '~/components/community/molecules/CommunityUserInvitationItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import { InvitationUser } from '~/interfaces/CommunityUser'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import {
  filterCommunityInvitationMembers,
  inviteMemberToCommunity,
} from '~/slices/community/community'

// FIXME: Should be refactored with useAPI
const CommunityUserInvitation: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toastRef = useRef(null)
  const toast = useCustomToast()
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [invitationUsers, setInvitationUsers] = useState<InvitationUser[]>([])
  const community = useSelector((state: RootState) => state.community.current)

  const fetchUsers = async (pageIndex: number): Promise<void> => {
    if (isLoading) {
      return
    }
    setLoading(true)
    try {
      const response = await api.users.index<User[], UsersParams>({
        name: keyword,
        page: pageIndex,
      })
      if (pageIndex === page) {
        updateInvitationUsers(response, false)
      } else if (response.length > 0) {
        updateInvitationUsers(response, true)
        setPage(pageIndex)
      }
    } catch (error) {
      toast.showError(error, toastRef)
    }
    setLoading(false)
  }

  const updateInvitationUsers = (users: User[], isFetchMore: boolean): void => {
    if (community) {
      isFetchMore
        ? setInvitationUsers([
            ...invitationUsers,
            ...filterCommunityInvitationMembers(community, users),
          ])
        : setInvitationUsers(filterCommunityInvitationMembers(community, users))
    }
  }

  useEffect(() => {
    updateInvitationUsers(invitationUsers, false)
  }, [community?.communityMemberInvitations])

  useEffect(() => {
    fetchUsers(page)
  }, [keyword])

  const renderItem: ListRenderItem<InvitationUser> = ({
    item,
  }: {
    item: InvitationUser
  }) => (
    <CommunityUserInvitationItem
      user={item}
      invitationDisabled={item.isJoined || item.isInvited}
      invite={(onInvited): void => {
        community &&
          Promise.all([
            dispatch(inviteMemberToCommunity(item, community)),
          ]).finally(() => onInvited && onInvited())
      }}
    />
  )

  const keyExtractor = (item: InvitationUser): string => item.id.toString()

  return (
    <View testID="community-user-invitation" style={styles.container}>
      <Text style={styles.title}>{t('community.inviteMemberDialogTitle')}</Text>
      <Text style={styles.subTitle}>{t('community.channel.name')}</Text>
      <SearchInput
        value={searchValue}
        onChangeText={setSearchValue}
        onSubmit={(): void => setKeyword(searchValue)}
        placeholderKey={t('community.searchFriendPlaceholder')}
      />
      <Text style={styles.subTitle}>{t('community.selectFriends')}</Text>
      <InfiniteScrollContainer
        onScrollBottom={(): void => {
          fetchUsers(page + 1)
        }}
      >
        <FlatList
          data={invitationUsers}
          renderItem={renderItem}
          style={styles.userList}
          keyExtractor={keyExtractor}
          testID="user-invitation-list"
        />
      </InfiniteScrollContainer>
      <Toast ref={toastRef} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subTitle: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
    marginTop: 10,
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.IMPORTANT,
  },
  userList: {
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
})
export default CommunityUserInvitation
