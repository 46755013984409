import { useFocusEffect, useRoute } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { View } from 'react-native'

import { API } from '~/api'
import QuestionEditForm from '~/components/forum/features/questions/QuestionEditForm'
import ForumFrame from '~/components/forum/templates/ForumFrame'
import Text from '~/components/workarounds/Text'
import useAPI from '~/hooks/useAPI'
import Question from '~/interfaces/Question'
import { RouteParamsID } from '~/utils/navigation'

const QuestionEditTemplate: React.FC = () => {
  const route = useRoute()
  const questionId = (route?.params as RouteParamsID)?.id
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [question, setQuestion] = useState<Question | undefined>(undefined)

  const fetchQuestion = useAPI(
    async (api: API): Promise<void> => {
      if (!questionId) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.questions.fetchDetail(questionId)
        setQuestion(response)
      } catch (err) {
        if (api.isAlive) {
          setError(err)
        }
      } finally {
        if (api.isAlive) {
          setIsLoading(false)
        }
      }
    },
    [questionId, setIsLoading]
  )

  useFocusEffect(
    useCallback(() => {
      fetchQuestion()
    }, [fetchQuestion, questionId])
  )

  return (
    <ForumFrame>
      {error && (
        <Text testID="question-edit-template-error">{error.message}</Text>
      )}
      {isLoading && <View testID="question-edit-template-loading" />}
      {!isLoading && question && (
        <QuestionEditForm question={question} isEdit={true} />
      )}
    </ForumFrame>
  )
}

export default QuestionEditTemplate
