import Recruitment from '~/interfaces/Recruitment'
import Resume from '~/interfaces/Resume'
import User from '~/interfaces/User'
import UserDocument from '~/interfaces/UserDocument'

export type EntryStatus =
  | 'saved'
  | 'applied'
  | 'rejected'
  | 'on_contacting'
  | 'on_interview'
  | 'passed'
  | 'started_working'

export enum EntryControlMenuType {
  ALL = 'all',
  NEW = 'new',
  MESSAGED = 'messaged',
  ARCHIVED = 'archived',
}

export default interface Entry {
  id: number
  status: EntryStatus
  recruit?: Recruitment
  user: User
  userResume?: Resume
  userDocument?: UserDocument
}
