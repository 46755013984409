import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-elements'

import CardContainer from '~/components/common/atoms/CardContainer'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
  onInvite?: () => void
  eventAttendeeList?: React.ReactElement
  attendeeInvitationDialog?: React.ReactElement
}

const EventAttendance: React.FC<Props> = ({
  event,
  onInvite,
  eventAttendeeList,
  attendeeInvitationDialog,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  const contentView = (
    <>
      <Text style={styles.title}>{t('events.attendance')}</Text>
      <Text style={styles.attendance}>{event.attendeesCount}</Text>
      <Text style={styles.subAttendanceTitle}>{t('events.attendees')}</Text>
      <Button
        onPress={onInvite}
        title={t('invitePeople')}
        containerStyle={styles.inviteButton}
        titleStyle={styles.inviteButtonTitle}
      />
      {eventAttendeeList}
      {attendeeInvitationDialog}
    </>
  )

  return isPC ? (
    <CardContainer testID="event-attendance" style={styles.container}>
      {contentView}
    </CardContainer>
  ) : (
    <View testID="event-attendance" style={styles.container}>
      {contentView}
    </View>
  )
}

const styles = StyleSheet.create({
  attendance: {
    color: color.pressableText,
    fontSize: FontSize.IMPORTANT,
    marginVertical: 4,
  },
  container: {
    backgroundColor: color.white,
    padding: 20,
    marginTop: 20,
  },
  inviteButton: {
    backgroundColor: color.primary,
    borderRadius: 0,
    marginVertical: 10,
    maxHeight: 40,
    maxWidth: 120,
  },
  inviteButtonTitle: {
    color: color.white,
    ...commonStyles.buttonTextSize,
  },
  subAttendanceTitle: {
    fontSize: FontSize.GENERAL,
    fontWeight: 'bold',
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default EventAttendance
