import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

const OutsiderGuideLinks: React.FC = () => {
  const { t } = useTranslation()

  return (
    <HorizontalContainer>
      <Link
        to={'/login'}
        testID="outsider-guide-link-login"
        style={StyleSheet.flatten([styles.login, styles.text])}
      >
        <Text>{t('auth.login')}</Text>
      </Link>
      <Link to={'/signup'} style={styles.text}>
        <Text>{t('auth.signup')}</Text>
      </Link>
    </HorizontalContainer>
  )
}

const styles = StyleSheet.create({
  login: {
    borderColor: color.white,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
  text: {
    alignSelf: 'center',
    color: color.white,
    fontWeight: 'bold',
    marginHorizontal: 10,
  },
})

export default OutsiderGuideLinks
