import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import MenuItem from '~/components/workarounds/MenuItem'

export enum EventMenuType {
  Edit = 'edit',
  Delete = 'delete',
}

type Props = {
  onSelect?: (type: EventMenuType) => void
}

const EventControlMenu: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="event-control-menu">
      <ControlMenuContainer>
        <MenuItem
          title={t('edit')}
          onSelect={(): void => onSelect && onSelect(EventMenuType.Edit)}
        />
        <MenuItem
          title={t('delete')}
          onSelect={(): void => onSelect && onSelect(EventMenuType.Delete)}
        />
      </ControlMenuContainer>
    </View>
  )
}

export default EventControlMenu
