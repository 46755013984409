import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import color from '~/constants/common/color'

type Props = {
  size?: 'small' | 'medium'
}

const CheckIcon: React.FC<Props> = ({ size }: Props) => {
  let iconSize = 24
  switch (size) {
    case 'small':
      iconSize = 14
      break
    case 'medium':
    default:
      iconSize = 24
  }

  return (
    <View testID="icon">
      <Svg
        viewBox={`0 0 448 512`}
        fill={color.correctAnswer}
        width={iconSize}
        height={iconSize}
      >
        <Path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></Path>
      </Svg>
    </View>
  )
}

export default CheckIcon
