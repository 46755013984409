import React from 'react'

import LoginColumn from '~/components/common/features/auth/LoginColumn'
import AuthContainerTemplate from '~/components/common/templates/auth/AuthContainerTemplate'

type Props = {
  isCompany?: boolean
}

const LoginTemplate: React.FC<Props> = ({ isCompany }: Props) => (
  <AuthContainerTemplate isCompany={isCompany}>
    <LoginColumn />
  </AuthContainerTemplate>
)

export default LoginTemplate
