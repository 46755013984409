import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

const OverlayLoadingView: React.FC = () => (
  <View testID="overlay-loading-view" style={styles.container} />
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
})

export default OverlayLoadingView
