import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import BasicInfo from '~/components/community/molecules/information/BasicInfo'
import SelfIntroduction from '~/components/community/molecules/information/SelfIntroduction'
import CommunityPreview from '~/components/community/organisms/CommunityPreview'
import useDevice from '~/hooks/commons/useDevice'
import Community from '~/interfaces/Community'
import { RootState } from '~/rootReducer'
import {
  communitySelector,
  updateCommunity,
} from '~/slices/community/community'

type Props = {
  isAdmin?: boolean // it's better to get with selector from Redux store
}

const CommunityOverview: React.FC<Props> = ({ isAdmin }: Props) => {
  const { isPC } = useDevice()
  const community = useSelector(communitySelector)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isSuccess = useSelector((state: RootState) => state.community.isSuccess)
  const error = useSelector((state: RootState) => state.community.error)
  const dispatch = useDispatch()

  const onUpdateCommunity = async (data: Partial<Community>): Promise<void> => {
    setIsLoading(true)
    await Promise.all([dispatch(updateCommunity(data))])
    setIsLoading(false)
  }

  return community ? (
    <View testID="community-overview" style={styles.container}>
      {isPC ? (
        <>
          <View style={styles.secondaryRow}>
            <View style={styles.leftColumn} testID="left-column">
              {isAdmin && <CommunityPreview community={community} />}
              <SelfIntroduction
                isPC={isPC}
                isAdmin={isAdmin}
                community={community}
                isLoading={isLoading}
                isSuccess={isSuccess}
                error={error}
                onUpdateCommunity={onUpdateCommunity}
              />
            </View>
            <View style={styles.rightColumn} testID="right-column">
              <BasicInfo community={community} isPC={isPC} />
            </View>
          </View>
        </>
      ) : (
        <ScrollView>
          <HeaderCommunityDetail />
          {isAdmin && <CommunityPreview community={community} />}
          <SelfIntroduction
            isPC={isPC}
            isAdmin={isAdmin}
            community={community}
            isLoading={isLoading}
            isSuccess={isSuccess}
            error={error}
            onUpdateCommunity={onUpdateCommunity}
          />
          <BasicInfo community={community} isPC={isPC} />
        </ScrollView>
      )}
    </View>
  ) : (
    <View testID="empty-community-overview"></View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  leftColumn: {
    flex: 1,
  },
  rightColumn: {
    marginLeft: 10,
    width: 210,
  },
  secondaryRow: {
    flexDirection: 'row',
  },
})

export default CommunityOverview
