import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'
import Community, { CommunityCategory } from '~/interfaces/Community'

export type CommunityQueryParams = {
  category?: string
  q?: string
  type?: CommunityType
  is_approved?: string | boolean
  status?: string
} & BaseQueryParams

export type CommunityType =
  | 'all'
  | 'joined'
  | 'manage'
  | 'suggest'
  | 'active'
  | 'pending'
  | 'reject'

export enum CommunityFilterKey {
  BY_ALL = 'all',
  BY_SUGGEST = 'suggest',
}

export type CommunityFormData = Pick<
  Community,
  'name' | 'description' | 'purpose' | 'isPrivate' | 'avatarId' | 'coverId'
> & {
  category: CommunityCategory
}

export class CommunitiesAPI extends BaseAPI {
  path = '/communities'
  configPath(type?: CommunityType): CommunitiesAPI {
    let path = '/communities'
    if (type === 'joined') {
      path = '/communities/belong'
    } else if (type === 'manage' || type === 'pending' || type === 'reject') {
      path = '/communities/me'
    } else if (type === 'suggest') {
      path = '/communities/suggestions'
    }

    this.path = path
    return this
  }
}
