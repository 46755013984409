export enum Fonts {
  NormalFont = 'Roboto',
  MediumFont = 'RobotoMedium',
  JapaneseFont = 'NotoSansJPRegular',
}

export enum FontSize {
  IMPORTANT = 26,
  REMARKABLE = 16,
  GENERAL = 15,
  SUB = 13,
}
