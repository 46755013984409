import React from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { TemplateType } from '~/interfaces/Resume'

type Props = {
  title: string
  templateType: TemplateType
  shouldShowUnderLine?: boolean
  inverted?: boolean
}

const CategoryTitle: React.FC<Props> = ({
  title,
  inverted,
  templateType,
  shouldShowUnderLine,
}: Props) => {
  const titleFontStyle = {
    enjin: {
      fontSize: FontSize.REMARKABLE,
      color: color.white,
    },
    shades: {
      fontSize: FontSize.REMARKABLE,
      color: color.white,
      textDecorationLine: shouldShowUnderLine ? 'underline' : undefined,
    },
    formal: {
      fontSize: FontSize.REMARKABLE,
      color: inverted ? color.black : color.black,
      fontWeight: 'bold',
    },
    minimal: {
      fontSize: FontSize.REMARKABLE,
      color: color.black,
      fontWeight: 'bold',
      textDecorationLine: 'underline',
    },
  }

  const titleStyle = {
    enjin: {
      ...titleFontStyle[templateType],
      backgroundColor: color.primary,
      paddingHorizontal: 20,
      paddingVertical: 4,
      borderRadius: 20,
      alignSelf: 'baseline',
    },
    shades: {
      ...titleFontStyle[templateType],
      backgroundColor: color.darkGray,
      paddingLeft: 20,
      paddingRight: 10,
      paddingVertical: 4,
      borderBottomEndRadius: 3,
      borderTopStartRadius: 2,
      borderBottomStartRadius: 2,
      height: 30,
    },
    formal: {
      ...titleFontStyle[templateType],
      paddingVertical: 6,
    },
    minimal: {
      ...titleFontStyle[templateType],
      paddingVertical: 6,
    },
  }

  const decoratorStyle = {
    enjin: undefined,
    shades: {
      position: 'absolute',
      right: -39,
      top: 0,
      width: 0,
      height: 0,
      borderRightWidth: 40,
      borderRightColor: color.transparent,
      borderTopWidth: 30,
      borderTopColor: color.darkGray,
      borderBottomWidth: 40,
      borderBottomColor: color.transparent,
    },
    formal: undefined,
    minimal: undefined,
  }

  return (
    <View testID="category-title" style={styles.container as ViewStyle}>
      <Text
        testID="category-title-value"
        style={titleStyle[templateType] as TextStyle}
      >
        {title}
      </Text>
      <View style={decoratorStyle[templateType] as ViewStyle} />
    </View>
  )
}

const styles = {
  container: {
    alignSelf: 'baseline',
    marginBottom: 4,
  },
}

export default CategoryTitle
