import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
  disabled?: boolean
  onPress?: () => void
  fontSize?: FontSize
  isFullTitle?: boolean
}

const EventName: React.FC<Props> = ({
  event,
  onPress,
  disabled,
  fontSize = FontSize.REMARKABLE,
  isFullTitle,
}: Props) => (
  <View testID="event-name" style={styles.container}>
    <Text
      style={[
        styles.text,
        disabled ? { color: color.black } : { color: color.pressableText },
        { fontSize },
      ]}
      onPress={onPress}
      numberOfLines={isFullTitle ? undefined : 1}
      testID="event-name-text"
    >
      {event.title}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'baseline',
  },
  text: {
    flexWrap: 'wrap',
    fontWeight: 'bold',
    paddingVertical: 4,
  },
})

export default EventName
