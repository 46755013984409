import { useRoute } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import { UsersParams } from '~/api/users'
import CardContainer from '~/components/common/atoms/CardContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import UserResultItem from '~/components/common/molecules/users/UserResultItem'
import SearchNotFound from '~/components/common/organisms/SearchNotFound'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import User from '~/interfaces/User'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  header?: React.ReactElement
}

const UsersList: React.FC<Props> = ({ header }: Props) => {
  const { t } = useTranslation()
  const defaultPage = 1
  const route = useRoute()
  const searchParams = camelcaseKeys(route.params || {}) as UsersParams
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)
  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [params, setParams] = useState<Partial<UsersParams>>(searchParams)
  const containerStyle = isPC ? styles.container : styles.containerMobile

  const renderItem: ListRenderItem<User> = ({ item }: { item: User }) => (
    <CardContainer style={styles.cardContainer}>
      <UserResultItem
        user={item}
        isPC={isPC}
        isMe={currentUser?.id === item.id}
        currentUser={currentUser}
      />
    </CardContainer>
  )
  const keyExtractor = (item: User): string => item.id.toString()

  const fetch = async (page: number): Promise<void> => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.users.index<User[], Partial<UsersParams>>({
        ...params,
        page,
      })
      if (params.page && page > params.page) {
        if (!isEmpty(response)) {
          setUsers((users) => [...users, ...response])
          setParams((params) => ({ ...params, page }))
        }
      } else {
        setUsers(response)
      }
    } catch (err) {
      //TODO show error
    } finally {
      setIsLoading(false)
    }
  }

  const fetchMore = async (): Promise<void> => {
    !isLoading && fetch(params.page! + 1)
  }

  useEffect(() => {
    fetch(defaultPage)
  }, [
    params.name,
    params.country,
    params.isGraduated,
    params.japaneseLevel,
    params.career,
  ])

  useEffect(() => {
    setParams({ ...params, ...searchParams, page: defaultPage })
  }, [
    searchParams.name,
    searchParams.country,
    searchParams.isGraduated,
    searchParams.japaneseLevel,
    searchParams.career,
  ])

  return (
    <View style={containerStyle} testID="users-list">
      <InfiniteScrollFlatList
        data={users}
        horizontal={false}
        style={styles.list}
        scrollEnabled={!isPC}
        renderItem={renderItem}
        onReachBottom={fetchMore}
        numColumns={isPC ? 2 : 1}
        keyExtractor={keyExtractor}
        ListHeaderComponent={header}
        columnWrapperStyle={isPC ? styles.columnWrapper : undefined}
        ListEmptyComponent={
          isLoading ? undefined : (
            <SearchNotFound subtitle={t('search.noResult')} />
          )
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: color.white,
    flex: 1,
    margin: 8,
    maxWidth: 440,
  },
  columnWrapper: {
    justifyContent: 'space-between',
  },
  container: {
    alignSelf: 'center',
    paddingBottom: 20,
    width: 920,
  },
  containerMobile: {
    flex: 1,
    paddingBottom: 20,
  },
  list: {
    height: '100%',
  },
})

export default UsersList
