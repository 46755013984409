import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import CardContainer from '~/components/common/atoms/CardContainer'
import UpdateProfileForm from '~/components/common/molecules/users/registration/UpdateProfileForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Profile from '~/interfaces/Profile'
import { RootState } from '~/rootReducer'

type Props = {
  profileData: Partial<Profile>
  onNextColumn: () => void
  onUpdateRegistrationData: (data: Partial<Profile>) => void
}

const RegistrationProfileColumn: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const titleStyle = isPC ? styles.title : styles.titleMobile

  return (
    <CardContainer testID="registration-profile-column" style={containerStyle}>
      {!isPC && (
        <Text style={styles.welcomeText} testID="welcome-text">
          {t('welcome')}
        </Text>
      )}
      <Text style={titleStyle}>{t('profile.setProfileTitle')}</Text>
      <UpdateProfileForm
        isPC={isPC}
        onNext={props.onNextColumn}
        registrationData={props.profileData}
        onUpdateRegistrationData={props.onUpdateRegistrationData}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingHorizontal: 50,
    paddingVertical: 30,
    width: 700,
  },
  containerMobile: {
    flex: 1,
    padding: 10,
  },
  title: {
    alignSelf: 'center',
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
  titleMobile: {
    alignSelf: 'center',
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
  welcomeText: {
    alignSelf: 'center',
    fontSize: FontSize.IMPORTANT,
    marginTop: 10,
  },
})

export default RegistrationProfileColumn
