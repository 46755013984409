import { FontAwesome as Icon } from '@expo/vector-icons'
import { MediaTypeOptions } from 'expo-image-picker'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar } from 'react-native-elements'

import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import color from '~/constants/common/color'
import Media from '~/interfaces/Media'

type Props = {
  image?: Media
  isPC: boolean
  onChange: (media: Media) => void
}

const CandidateAvatar: React.FC<Props> = ({ isPC, image, onChange }: Props) => {
  const [media, setMedia] = useState<Media | undefined>(image || undefined)
  const addAvatarButtonStyle = isPC
    ? StyleSheet.flatten([
        styles.addAvatarButtonContainer,
        styles.addButtonPCSize,
      ])
    : StyleSheet.flatten([
        styles.addAvatarButtonContainer,
        styles.addButtonMobileSize,
      ])
  const addAvatarIconSize = isPC ? 20 : 14
  const addAvatarIcon = (
    <Icon
      name="plus"
      color={color.white}
      size={addAvatarIconSize}
      testID="add-icon"
    />
  )

  const handleChangeMedia = (mediaData: Media[]): void => {
    if (mediaData.length) {
      setMedia(mediaData[0])
      onChange(mediaData[0])
    }
  }

  return (
    <View testID="candidate-avatar" style={styles.container}>
      <Avatar
        rounded
        size={90}
        containerStyle={{ backgroundColor: color.baseBackground }}
        source={
          media
            ? {
                uri: media?.urls.small,
              }
            : require('~/assets/images/user_default.png')
        }
      />
      <ImagePickerButton
        type="clear"
        kind="resume"
        icon={addAvatarIcon}
        onUpload={handleChangeMedia}
        mediaTypes={MediaTypeOptions.Images}
        containerStyle={addAvatarButtonStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  addAvatarButtonContainer: {
    backgroundColor: color.primary,
    borderRadius: 20,
    bottom: 0,
    elevation: 2,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    shadowColor: color.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
  },
  addButtonMobileSize: {
    height: 28,
    width: 28,
  },
  addButtonPCSize: {
    height: 40,
    width: 40,
  },
  container: {
    alignSelf: 'center',
    marginBottom: 40,
  },
})

export default CandidateAvatar
