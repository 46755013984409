import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewStyle } from 'react-native'

import api from '~/api'
import { CreateMemberParam } from '~/api/communities/communityMembers'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityMemberJoinRequest from '~/interfaces/CommunityMemberJoinRequest'
import CommunityUser from '~/interfaces/CommunityUser'

type Props = {
  onAccept?: () => void
  onDismiss?: () => void
  memberJoinRequest: CommunityMemberJoinRequest
}

const RequestedUserActions: React.FC<Props> = ({
  onAccept,
  onDismiss,
  memberJoinRequest,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)

  const user = memberJoinRequest?.user

  const accept = async (): Promise<void> => {
    if (!user || !memberJoinRequest || isLoading) {
      return
    }
    setIsLoading(true)
    try {
      await api.communityMembers.create<CreateMemberParam, CommunityUser>({
        isAdmin: false,
        userId: user.id,
        communityId: memberJoinRequest.communityId,
      })
      onAccept && onAccept()
    } catch (error) {
      toast.showError(error)
    }
    setIsLoading(false)
  }

  const dismiss = async (): Promise<void> => {
    if (!memberJoinRequest || isLoading) {
      return
    }
    setIsLoading(true)
    try {
      await api.communityMemberJoinRequest.delete(memberJoinRequest.id)
      onDismiss && onDismiss()
    } catch (error) {
      toast.showError(error)
    }
    setIsLoading(false)
  }

  const acceptButtonStyle = isPC
    ? styles.acceptButton
    : styles.acceptButtonMobile
  const acceptTitleStyle = isPC ? styles.acceptTitle : styles.acceptTitleMobile
  const dismissButtonStyle = isPC
    ? styles.dismissButton
    : styles.dismissButtonMobile
  const dismissTitleStyle = isPC
    ? styles.dismissTitle
    : styles.dismissTitleMobile

  return (
    <View style={styles.container as ViewStyle} testID="requested-user-actions">
      <Button
        onPress={accept}
        disabled={isLoading}
        titleStyle={acceptTitleStyle}
        title={t('community.accept')}
        testID="requested-user-accept"
        buttonStyle={acceptButtonStyle}
        disabledStyle={styles.disabledButton}
        containerStyle={styles.buttonWrapper}
      />
      <Button
        onPress={dismiss}
        disabled={isLoading}
        title={t('community.dismiss')}
        titleStyle={dismissTitleStyle}
        testID="requested-user-dismiss"
        buttonStyle={dismissButtonStyle}
        disabledStyle={styles.disabledButton}
        containerStyle={styles.buttonWrapper}
      />
    </View>
  )
}

const styles = {
  disabledButton: {
    borderColor: color.transparent,
  },
  acceptButton: {
    borderColor: color.primary,
    borderWidth: 1,
  },
  acceptButtonMobile: {
    borderColor: color.primary,
    borderRadius: 0,
    borderWidth: 1,
    fontSize: FontSize.GENERAL,
    marginRight: 5,
    padding: 5,
    height: 29,
    overflow: 'hidden',
  },
  acceptTitle: {
    fontSize: FontSize.GENERAL,
    lineHeight: 16,
  },
  acceptTitleMobile: {
    fontSize: FontSize.GENERAL,
    lineHeight: 14,
  },
  buttonWrapper: {
    borderRadius: 0,
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  dismissButton: {
    backgroundColor: color.white,
    marginLeft: 10,
  },
  dismissButtonMobile: {
    backgroundColor: color.white,
    borderColor: color.primary,
    borderRadius: 0,
    borderWidth: 1,
    fontSize: FontSize.GENERAL,
    marginLeft: 5,
    padding: 5,
  },
  dismissTitle: {
    color: color.primary,
    fontSize: FontSize.GENERAL,
    lineHeight: 16,
  },
  dismissTitleMobile: {
    color: color.primary,
    fontSize: FontSize.GENERAL,
    lineHeight: 14,
  },
}

export default RequestedUserActions
