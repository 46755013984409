import Forum from '~/components/forum/pages/Forum'
import ForumUser from '~/components/forum/pages/ForumUser'
import QuestionEdit from '~/components/forum/pages/QuestionEdit'
import QuestionNew from '~/components/forum/pages/QuestionNew'
import QuestionShow from '~/components/forum/pages/QuestionShow'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'
import { Route } from '~/utils/common/routes'

/**
 * Add router for another pages that is not display in Mobile BottomNavigator
 */
export const otherForumRoutes: Route<keyof OtherStackParamList>[] = [
  { name: 'questionNew', component: QuestionNew },
  { name: 'questionShow', component: QuestionShow },
  { name: 'questionEdit', component: QuestionEdit },
  { name: 'userDetail', component: ForumUser },
  { name: 'mypage', component: ForumUser, labelTranslationKey: 'menu.mypage' },
]

export const mainForumRoutes: Route<keyof MainStackParamList>[] = [
  { name: 'forum', component: Forum, labelTranslationKey: 'menu.home' },
  { name: 'questions', component: Forum, labelTranslationKey: 'menu.forum' },
]
