import React from 'react'
import { ViewProps, View, ViewStyle } from 'react-native'

import color from '~/constants/common/color'

const SkeletonView: React.FC<ViewProps> = (props: ViewProps) => (
  <View
    {...props}
    style={[defaultSpacingStyle, props.style, { backgroundColor: color.gray }]}
    testID="skeleton-view"
  />
)

const defaultSpacingStyle: ViewStyle = {
  padding: 6,
}

export default SkeletonView
