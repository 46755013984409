import { TFunction } from 'i18next'
import { first, last } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ChartData } from 'react-native-chart-kit/dist/HelperTypes'
import { useSelector } from 'react-redux'

import api from '~/api'
import { RecruitmentStatisticParams } from '~/api/companyRecruitmentStatistics'
import StatisticsBarChart from '~/components/career/organisms/company/StatisticsBarChart'
import StatisticsGrid, {
  StatisticsGridItem,
} from '~/components/career/organisms/company/StatisticsGrid'
import CardContainer from '~/components/common/atoms/CardContainer'
import TitleText from '~/components/common/atoms/TitleText'
import YearPicker from '~/components/common/atoms/YearPicker'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import CompanyRecruitmentStatistic from '~/interfaces/CompanyRecruitmentStatistic'
import { RootState } from '~/rootReducer'
import { parseChartDataByMonth } from '~/utils/career/company'

export const getChartData = (
  recruitmentStatistic: CompanyRecruitmentStatistic,
  t: TFunction
): ChartData => {
  const data = parseChartDataByMonth(recruitmentStatistic.dataByMonth, t)
  const colors = data.map(() => () => color.primary)
  const labels = data.map((item) => first(item)?.toString() ?? '')

  return {
    labels,
    datasets: [
      {
        data: data.map((item) => last(item)) as number[],
        colors,
      },
    ],
  }
}

const defaultCharData = {
  labels: [],
  datasets: [
    {
      data: [],
      colors: [],
    },
  ],
}

const START_YEAR = 2018

const RecruitmentStatistics: React.FC = () => {
  const currentYear = new Date().getFullYear()
  const { t } = useTranslation()
  const toast = useCustomToast()
  const currentCompany = useSelector(
    (state: RootState) => state.company.current
  )
  const { isPC } = useDevice()
  const [isLoading, setIsLoading] = useState(false)
  const [statisticData, setStatisticData] = useState<
    CompanyRecruitmentStatistic | undefined
  >()
  const [chartData, setChartData] = useState<ChartData>(defaultCharData)
  const [selectedYear, setSelectedYear] = useState(currentYear)

  const statisticsGridData: StatisticsGridItem[] = [
    {
      title: t('company.statistic.totalRecruitments'),
      value: statisticData?.total ?? 0,
    },
    {
      title: t('company.statistic.activeRecruitment'),
      value: statisticData?.opening ?? 0,
      isActive: true,
    },
    {
      title: t('company.statistic.endedJobs'),
      value: statisticData?.ended ?? 0,
    },
    {
      title: t('company.statistic.inactiveRecruitments'),
      value: statisticData?.inactive ?? 0,
    },
  ]

  const fetchRecruitmentStatistic = async (): Promise<void> => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await api.companyRecruitmentStatistics
        .configPath(currentCompany!.id)
        .index<CompanyRecruitmentStatistic, RecruitmentStatisticParams>({
          year: selectedYear,
        })
      setChartData(getChartData(response, t))
      setStatisticData(response)
    } catch (err) {
      toast.showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    currentCompany && fetchRecruitmentStatistic()
  }, [currentCompany?.id, selectedYear])

  const containerStyle = !isPC ? styles.containerMobile : {}

  return (
    <View testID="recruitment-statistics" style={containerStyle}>
      <CardContainer style={styles.charContainer}>
        <View style={styles.chartHeader}>
          <TitleText style={styles.chartTitle}>
            {t('company.statistic.recruitmentPosted')}
          </TitleText>
          <View style={styles.yearContainer}>
            <YearPicker
              fromYear={START_YEAR}
              maxYear={currentYear}
              onChange={setSelectedYear}
              selectedValue={selectedYear}
            />
          </View>
        </View>
        <StatisticsBarChart data={chartData} />
      </CardContainer>
      <StatisticsGrid numOfColumns={2} data={statisticsGridData} isPC={isPC} />
    </View>
  )
}

const styles = StyleSheet.create({
  charContainer: {
    backgroundColor: color.white,
  },
  chartHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  chartTitle: {
    color: color.textGray,
  },
  containerMobile: {
    padding: 20,
  },
  yearContainer: {
    width: 90,
  },
})

export default RecruitmentStatistics
