import React from 'react'
import { Platform, StyleSheet, TouchableHighlight } from 'react-native'
import type { StyleProp, ViewStyle } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import color from '~/constants/common/color'

type Props = {
  size: number
  style?: StyleProp<ViewStyle>
  onPress?: () => void
}

const RemoveButton: React.FC<Props> = ({ size, style, onPress }: Props) => (
  <TouchableHighlight
    testID="remove-button"
    style={StyleSheet.flatten([
      styles.container,
      { width: size, height: size, borderRadius: size / 2 },
      style,
    ])}
    underlayColor={color.baseBackground}
    onPress={onPress}
  >
    <Icon name="close" size={(size / 4) * 3} color={color.removeButton} />
  </TouchableHighlight>
)

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: { cursor: 'pointer' },
    }),
    alignItems: 'center',
    backgroundColor: color.white,
    borderColor: color.removeButton,
    borderWidth: 1,
    justifyContent: 'center',
  },
})

export default RemoveButton
