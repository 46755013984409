import { first } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DefaultLayout from '~/components/DefaultLayout'
import CompanyPanelTemplate from '~/components/career/templates/company/CompanyPanelTemplate'
import useCustomToast from '~/hooks/useCustomToast'
import { fetchCompany } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'

const CompanyPanel: React.FC = () => {
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const currentUser = useSelector(currentUserSelector)

  const companyId = first(currentUser?.profile.companies)?.id

  useEffect(() => {
    if (companyId == null) {
      return
    }
    try {
      dispatch(fetchCompany(companyId))
    } catch (error) {
      toast.showError(error)
    }
  }, [companyId])

  return (
    <DefaultLayout>
      <CompanyPanelTemplate />
    </DefaultLayout>
  )
}

export default CompanyPanel
