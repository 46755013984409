import React from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  text: string
  size: 'tiny' | 'small' | 'medium'
  color: 'primary' | 'secondary'
}

const TextAvatar: React.FC<Props> = ({ text, size, color }: Props) => (
  <View
    testID="text-avatar"
    style={
      {
        ...viewStyles[size],
        ...viewStyles[color],
        ...viewStyles.container,
      } as ViewStyle
    }
  >
    <Text style={{ ...textStyles.text, ...textStyles[size] } as TextStyle}>
      {text}
    </Text>
  </View>
)

const textStyles = {
  text: {
    color: color.white,
    textAlign: 'center',
    paddingBottom: 2,
    fontWeight: 'bold',
  },
  tiny: {
    fontSize: 10,
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 34,
  },
}

const viewStyles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tiny: {
    width: 18,
    height: 18,
    borderRadius: 10,
  },
  small: {
    width: 30,
    height: 30,
    borderRadius: 20,
  },
  medium: {
    width: 50,
    height: 50,
    borderRadius: 32,
  },
  primary: {
    backgroundColor: color.primary,
  },
  secondary: {
    backgroundColor: color.secondary,
  },
}

export default TextAvatar
