import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'

import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isCompany?: boolean
}

const TermsAndPolicy: React.FC<Props> = ({ isCompany }) => {
  const { t } = useTranslation()
  const marginTop = isCompany ? styles.marginTopCompany : styles.marginTop

  return (
    <>
      <View style={[styles.container, marginTop]}>
        <TouchableOpacity>
          <Link testID="privacy" to="/privacy" style={styles.link}>
            {t('auth.privacyPolicy')}
          </Link>
        </TouchableOpacity>
        <Text style={styles.verticalSeparate}>|</Text>
        <TouchableOpacity>
          <Link testID="terms" to="/terms" style={styles.link}>
            {t('auth.termsOfService')}
          </Link>
        </TouchableOpacity>
      </View>
      <Text style={[styles.appName, marginTop]}>{t('enjin')}</Text>
    </>
  )
}

const styles = StyleSheet.create({
  appName: {
    alignSelf: 'center',
    color: color.textGray,
    fontSize: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  link: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
  },
  marginTop: {
    marginTop: 26,
  },
  marginTopCompany: {
    marginTop: 15,
  },
  verticalSeparate: {
    color: color.textGray,
    marginLeft: 10,
    marginRight: 10,
  },
})

export default TermsAndPolicy
