import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import FormLayout from '~/components/career/molecules/resume/FormLayout'
import TextField from '~/components/common/molecules/TextField'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'
import {
  createResume,
  toggleShowTitlePopup,
  updateResume,
} from '~/slices/career/resumes'

type Props = {
  type: 'create' | 'update'
}

const ResumeTitlePopup: React.FC<Props> = ({ type }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelector((state: RootState) => state.resumes.isLoading)
  const resume = useSelector((state: RootState) => state.resumes.current)
  const [headline, setHeadline] = useState(resume.headline || '')

  const saveResume = async (): Promise<void> => {
    if (headline === resume.headline) {
      dispatch(toggleShowTitlePopup())
    } else if (headline) {
      Promise.all([dispatch(updateResume({ ...resume, ...{ headline } }))])
        .then(() => dispatch(toggleShowTitlePopup()))
        .catch(() => {
          //TODO show error
        })
    }
  }

  const create = (): void => {
    if (headline) {
      Promise.all([dispatch(createResume({ ...resume, ...{ headline } }))])
        .then(() => dispatch(toggleShowTitlePopup()))
        .catch(() => {
          //TODO show error
        })
    }
  }

  const handlePressNextButton = (): void => {
    type === 'create' ? create() : saveResume()
  }

  const handleCancel = (): void => {
    dispatch(toggleShowTitlePopup())
  }

  return (
    <View
      style={styles.container}
      testID="resume-title-popup"
      pointerEvents={isLoading ? 'none' : 'auto'}
    >
      <View style={styles.backdrop} />
      <View style={styles.content}>
        <FormLayout
          isPC={false}
          hasAddAction={false}
          title={t('profile.createResume')}
        >
          <View style={styles.inputContainer}>
            <TextField
              maxLength={50}
              value={headline}
              onChangeText={setHeadline}
              title={t('resume.resumeTitle')}
              subtitle={t('resume.resumeTitleEg')}
            />
          </View>
          <Button
            testID="submit"
            loading={isLoading}
            onPress={handlePressNextButton}
            title={type === 'update' ? t('save') : t('next')}
            containerStyle={StyleSheet.flatten(styles.nextButton)}
          />
          <Button
            type="outline"
            testID="cancel"
            title={t('cancel')}
            disabled={isLoading}
            onPress={handleCancel}
          />
        </FormLayout>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: color.black,
    flex: 1,
    opacity: 0.8,
  },
  container: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  content: {
    alignSelf: 'center',
    backgroundColor: color.white,
    maxWidth: 478,
    minWidth: 310,
    padding: 10,
    position: 'absolute',
  },
  inputContainer: {
    marginTop: 10,
  },
  nextButton: {
    marginBottom: 10,
    marginTop: 10,
  },
})

export default ResumeTitlePopup
