import { MediaTypeOptions } from 'expo-image-picker'
import { useCallback } from 'react'
import { Platform } from 'react-native'

import ImageKind from '~/interfaces/ImageKind'
import Media from '~/interfaces/Media'
import {
  selectMedia,
  selectMultipleMedia,
  uploadMultiple,
  uploadMultipleMedia,
} from '~/utils/common/media'

interface Props {
  kind: ImageKind
  onUpload?: (media: Media[]) => void
  onError?: (error: Error) => void
  allowsMultipleSelection?: boolean
  mediaTypes?: MediaTypeOptions
  onLoading?: (isLoading: boolean) => void
  isDirectUpload?: boolean
}

const useImagePicker = ({
  kind,
  onUpload,
  onError,
  allowsMultipleSelection,
  mediaTypes,
  onLoading,
  isDirectUpload,
}: Props): (() => void) =>
  useCallback(async () => {
    const uris =
      allowsMultipleSelection && Platform.OS === 'web'
        ? await selectMultipleMedia(mediaTypes)
        : await selectMedia(mediaTypes)
    if (!uris) {
      return
    }
    try {
      onLoading && onLoading(true)
      const uriData = Array.isArray(uris) ? uris : [uris]
      const media = isDirectUpload
        ? await uploadMultipleMedia(uriData)
        : await uploadMultiple(uriData, kind)

      if (onUpload) {
        onUpload(media)
      }
    } catch (e) {
      if (onError != null) {
        onError(e as Error)
      }
    }
    onLoading && onLoading(false)
  }, [kind, onUpload, onError])

export default useImagePicker
