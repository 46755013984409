import React, { useState } from 'react'

import PostItemTitle from '~/components/career/molecules/post/PostItemTitle'
import PostItem from '~/components/career/organisms/posts/PostItem'
import CrossIcon from '~/components/common/atoms/CrossIcon'
import SharingPopup from '~/components/common/molecules/SharingPopup'
import Post from '~/interfaces/Post'
import User from '~/interfaces/User'
import { getSharingUrl } from '~/utils/navigation'

type Props = {
  post: Post
  isPC: boolean
  editable: boolean
  currentUser: User
  onDismiss: () => void
  onSubmit: (post: Post) => void
}

const PostSharingPopup: React.FC<Props> = ({
  isPC,
  post,
  editable,
  onSubmit,
  onDismiss,
  currentUser,
}: Props) => {
  const [message, setMessage] = useState('')
  const postToShare = {
    url: getSharingUrl({ id: post.id, type: 'post' }),
    createdAt: new Date().toISOString(),
  } as Post

  return (
    <SharingPopup
      header={
        <>
          <PostItemTitle user={currentUser} date={postToShare.createdAt} />
          <CrossIcon onPress={onDismiss} />
        </>
      }
      message={message}
      disabled={!editable}
      onChange={setMessage}
      onDismiss={onDismiss}
      sharingContent={<PostItem post={post} isPC={isPC} />}
      onSubmit={(): void => onSubmit({ ...postToShare, message })}
    />
  )
}

export default PostSharingPopup
