import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import MainLayout from '~/components/forum/pages/RootPage/ForumMain/MainLayout'
import MobileLayout from '~/components/forum/pages/RootPage/ForumMain/MobileLayout'
import { RootState } from '~/rootReducer'

const Main: React.FC = () => {
  const isPC = useSelector((state: RootState) => state.device.isPC)
  return (
    <View style={styles.content}>
      {isPC ? <MainLayout /> : <MobileLayout />}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    overflow: 'hidden',
  },
})

export default Main
