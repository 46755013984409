import get from 'lodash/get'
import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import { useMediaViewerContext } from '~/contexts/MediaViewerContext'
import Answer from '~/interfaces/Answer'
import Media from '~/interfaces/Media'
import Post from '~/interfaces/Post'
import Question from '~/interfaces/Question'

type Props = {
  entity: Post | Answer | Question
}

const MediaArea: React.FC<Props> = ({ entity }: Props) => {
  const media: Media[] = [
    ...get(entity, 'images', []),
    ...get(entity, 'videos', []),
  ]
  const { setEntity, setSelectedMedia } = useMediaViewerContext()

  const openMediaDialog = (selectedMedia?: Media) => {
    setEntity?.(entity)
    setSelectedMedia?.(selectedMedia)
  }

  if (!media.length) {
    return null
  }

  return (
    <View style={styles.mediaPreview} testID="media-area">
      <MediaPreview onPress={openMediaDialog} media={media} retry={false} />
    </View>
  )
}

const styles = StyleSheet.create({
  mediaPreview: {
    maxHeight: 600,
    overflow: 'hidden',
    width: '100%',
    ...Platform.select({
      ios: { minHeight: 420 },
      android: { minHeight: 420 },
    }),
  },
})

export default MediaArea
