import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import CommunityUserResult from '~/components/community/molecules/CommunityUserResult'
import Button from '~/components/workarounds/Button'
import { FontSize } from '~/constants/common/font'
import { InvitationUser } from '~/interfaces/CommunityUser'

type Props = {
  user: InvitationUser
  invitationDisabled?: boolean
  invite?: (onInvited: () => void) => void
}

const CommunityUserInvitationItem: React.FC<Props> = ({
  user,
  invite,
  invitationDisabled,
}: Props) => {
  const { t } = useTranslation()
  const [isRequesting, setIsRequesting] = useState(false)

  const pressInvitation = (): void => {
    setIsRequesting(true)
    invite && invite(() => setIsRequesting(false))
  }

  return (
    <View style={styles.container} testID="community-user-result-item">
      <CommunityUserResult user={user} />
      <Button
        loading={isRequesting}
        title={
          user.isJoined
            ? t('community.invitationJoined')
            : user.isInvited
            ? t('community.invited')
            : t('community.invite')
        }
        onPress={pressInvitation}
        testID="invitation-button"
        disabled={invitationDisabled || isRequesting}
        style={StyleSheet.flatten([styles.invitationButton])}
        titleStyle={StyleSheet.flatten([styles.buttonTitle])}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonTitle: {
    fontSize: FontSize.GENERAL,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  invitationButton: {
    width: 80,
  },
})

export default CommunityUserInvitationItem
