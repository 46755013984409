const sizes = {
  fullContainer: {
    width: '100%',
  },
  largerContainer: {
    width: 280,
  },
  mediumContainer: {
    width: 110,
  },
  smallContainer: {
    width: 72,
  },
}

export const sizeStyle = {
  small: sizes.smallContainer,
  medium: sizes.mediumContainer,
  large: sizes.largerContainer,
  full: sizes.fullContainer,
  none: undefined,
}
