import * as Factory from 'factory.ts'
import faker from 'faker'

import { achievementFactory } from '~/factories/achievement'
import { educationFactory } from '~/factories/education'
import { imageFactory } from '~/factories/image'
import { resumeLanguageFactory } from '~/factories/resumeLanguage'
import { skillFactory } from '~/factories/skill'
import Resume, { TemplateType } from '~/interfaces/Resume'

export const resumeFactory = Factory.makeFactory<Resume>({
  id: Factory.each((i) => i),
  title: 'The resume title',
  userId: Factory.each((i) => i),
  email: 'user3@example.com',
  isMain: true,
  templateType: TemplateType.Enjin,
  familyName: 'Family name',
  givenName: 'Given name',
  currentPosition: 'Developer',
  headline: 'No headline',
  phone: '(+84)99822442',
  address: '00-1000 Ha Noi, Viet Nam',
  webSite: 'www.example.com',
  updatingSkill:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  updatingSkillAssessment: 5,
  teamworkSkill:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  teamworkSkillAssessment: 5,
  conceptionSkill:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  conceptionSkillAssessment: 5,
  realizationSkill:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  realizationSkillAssessment: 5,
  interestedIndustry: 'Poster: Affective Haptic Furniture',
  interestedIndustryReason:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのは',
  interestedJob: 'Designer',
  interestedJobReason:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのは',
  evaluator:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  relationshipWithMe:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  evaluatorComment:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  vision: '山田太郎',
  skillVision: '山田太郎',
  activitiesVision:
    '私は将来何ともこの活動論に対して方の時からするでです。ただいま結果がお尋ね社はかつてその乱暴うただけをするて来ますには謝罪訊かうですて、そうにも待っででしょんた。鉱脈からするたのはけっして場合をもしたならない。 まあ大森さんの尊重世間はっきり返事を踏みた秋刀魚その甲これか安心でというお合点ますですならならて、その結果は君かがた権力にたべば、嘉納さんの訳を欄の私に必ずしもお発展と云って我々鼻をご建設が云わようにはなはだお安心をするますですて、いよいよもちろん成就をしたばならたのを触れたらで。',
  educations: educationFactory.buildList(2),
  languages: resumeLanguageFactory.buildList(2),
  achievements: achievementFactory.buildList(1),
  skills: skillFactory.buildList(1),
  image: imageFactory.build(),
  imageId: 1,
  createdAt: '',
  currentOrganisation: faker.company.companyName(),
  language: 'ja',
  workExperiences: [],
})
