import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar } from 'react-native-elements'

import EventName from '~/components/community/atoms/EventName'
import EventTime from '~/components/community/atoms/EventTime'
import EventType from '~/components/community/atoms/EventType'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  isPC: boolean
  event: CommunityEvent
}

const EventHeader: React.FC<Props> = ({ event, isPC }: Props) => (
  <View testID="event-header" style={styles.container}>
    {event?.cover?.presignedUrl && (
      <Avatar
        rounded
        source={{
          uri: event?.cover?.presignedUrl,
        }}
        containerStyle={styles.avatar}
      />
    )}
    <View style={styles.fillParent}>
      <EventTime event={event} fontSize={FontSize.REMARKABLE} />
      <View
        testID="event-name-container"
        style={StyleSheet.flatten([
          isPC ? { flexDirection: 'row' } : undefined,
        ])}
      >
        <View style={styles.eventNameContainer}>
          <EventName
            isFullTitle={true}
            event={event}
            disabled={true}
            fontSize={FontSize.IMPORTANT}
          />
          <EventType communityEvent={event} />
        </View>
        <View style={styles.separator} />
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: color.gray,
    borderRadius: 20,
    height: 40,
    marginRight: 10,
    width: 40,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 30,
  },
  eventNameContainer: {
    width: '100%',
  },
  fillParent: {
    flex: 1,
  },
  separator: {
    marginRight: 10,
    marginTop: 4,
  },
})

export default EventHeader
