import { isNil } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import {
  CommunityQueryParams,
  CommunityType,
} from '~/api/communities/communities'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import TitleText from '~/components/common/atoms/TitleText'
import OutsiderGuide from '~/components/common/features/auth/OutsiderGuide'
import SearchNotFound from '~/components/common/organisms/SearchNotFound'
import CommunityList from '~/components/community/features/CommunityList'
import CreateCommunity from '~/components/community/molecules/CreateCommunity'
import NoCommunityJoinedYet from '~/components/community/molecules/NoCommunitiesJoinedYet'
import HeaderCommunity from '~/components/community/organisms/HeaderCommunity'
import ManagerCommunity from '~/components/community/organisms/ManagerCommunity'
import UserGuestCommunityList from '~/components/community/organisms/UserGuestCommunityList'
import MobileTemplate from '~/components/community/templates/MobileTemplate'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { getRouteParams } from '~/utils/navigation'

const CommunityTemplate: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const queryParams = getRouteParams<CommunityQueryParams>()
  const isSearchWithWords = !isNil(queryParams.q)
  const isShowAllType =
    (isNil(queryParams.type) || queryParams.type === 'all') &&
    !isSearchWithWords
  const communityType = isShowAllType ? 'suggest' : queryParams.type

  const getCommunityTitle = (type?: CommunityType): string => {
    switch (type) {
      case 'suggest':
        return t('community.suggestTitle')
      case 'joined':
        return t('community.joinedTitle')
      case 'manage':
        return t('community.manageTitle')
      case 'active':
        return t('community.activeCommunities')
      case 'pending':
        return t('community.pendingCommunities')
      case 'reject':
        return t('community.rejectedCommunities')
      default:
        return ''
    }
  }

  return (
    <>
      {!accessToken ? (
        <>
          <InfiniteScrollContainer>
            <HeaderCommunity />
            <UserGuestCommunityList />
          </InfiniteScrollContainer>
          <OutsiderGuide />
        </>
      ) : isPC ? (
        <InfiniteScrollContainer>
          <View style={styles.container} testID="community-template">
            <HeaderCommunity />
            {isShowAllType && (
              <>
                <CreateCommunity isPC={isPC} />
                <CommunityList
                  hasLoadMoreButton
                  header={
                    <TitleText style={styles.header}>
                      {getCommunityTitle('joined')}
                    </TitleText>
                  }
                  params={{ ...queryParams, type: 'joined' }}
                  emptyView={
                    <View style={styles.emptyContainer}>
                      <NoCommunityJoinedYet
                        title={t('community.noCommunitiesJoinedYet')}
                      />
                    </View>
                  }
                />
              </>
            )}
            {(isShowAllType || communityType === 'manage') && (
              <ManagerCommunity isPC={isPC} />
            )}
            {communityType !== 'manage' && (
              <View style={styles.header}>
                <CommunityList
                  header={
                    <TitleText style={styles.header}>
                      {getCommunityTitle(communityType)}
                    </TitleText>
                  }
                  params={{ ...queryParams, type: communityType }}
                  emptyView={
                    <SearchNotFound
                      title={t('community.searchNotFoundCommunity')}
                      subtitle={t('community.searchNotFoundCommunityHint')}
                    />
                  }
                />
              </View>
            )}
          </View>
        </InfiniteScrollContainer>
      ) : (
        <View testID="community-template">
          <MobileTemplate />
        </View>
      )}
      <View style={styles.bottom}></View>
    </>
  )
}

const styles = StyleSheet.create({
  bottom: {
    marginBottom: 50,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  emptyContainer: {
    width: 940,
    alignSelf: 'center',
    paddingHorizontal: 8,
    paddingTop: 10,
  },
  header: {
    marginTop: 20,
  },
})

export default CommunityTemplate
