import React from 'react'
import { ListRenderItem, StyleSheet, View } from 'react-native'

import StatisticCardItem from '~/components/career/molecules/company/StatisticCardItem'
import FlatList from '~/components/workarounds/FlatList'

export type StatisticsGridItem = {
  title: string
  value: string | number
  isActive?: boolean
}

type Props = {
  numOfColumns: number
  data: StatisticsGridItem[]
  isPC?: boolean
}

const StatisticsGrid: React.FC<Props> = ({
  numOfColumns,
  data,
  isPC,
}: Props) => {
  const containerStyle = isPC ? styles.container : {}

  const renderItem: ListRenderItem<StatisticsGridItem> = ({
    item,
    index,
  }: {
    item: StatisticsGridItem
    index: number
  }) => {
    const marginStyle = index % numOfColumns === 0 ? {} : { marginLeft: 12 }

    return (
      <View
        style={StyleSheet.flatten([styles.itemContainer, marginStyle])}
        testID="statistic-grid-item"
      >
        <StatisticCardItem item={item} />
      </View>
    )
  }

  const keyExtractor = (item: StatisticsGridItem, index: number): string =>
    `${item.title}-${index}`

  return (
    <View testID="statistic-grid" style={containerStyle}>
      <FlatList
        data={data}
        renderItem={renderItem}
        numColumns={numOfColumns}
        keyExtractor={keyExtractor}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  itemContainer: {
    flex: 1,
  },
})

export default StatisticsGrid
