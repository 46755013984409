import type Image from '~/interfaces/Image'
import type ImageKind from '~/interfaces/ImageKind'
import type Video from '~/interfaces/Video'
import ImageUploader from '~/uploader/ImageUploader'
import Uploader from '~/uploader/Uploader'
import VideoUploader from '~/uploader/VideoUploader'
import blobToBuffer from '~/uploader/blobToBuffer'
import uriToBlob from '~/uploader/uriToBlob'

const uploadImage = (
  blob: Blob,
  name: string,
  kind: ImageKind,
  uri?: string
): Promise<Image> => new ImageUploader(blob, name, kind, uri).upload()

const uploadVideo = (blob: Blob, uri?: string): Promise<Video> =>
  new VideoUploader(blob, uri).upload()

export {
  Uploader,
  ImageUploader,
  VideoUploader,
  uriToBlob,
  uploadImage,
  uploadVideo,
  blobToBuffer,
}
