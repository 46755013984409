import React from 'react'
import {
  ButtonGroup as RNElementsButtonGroup,
  ButtonGroupProps,
} from 'react-native-elements'

const ButtonGroup: React.FC<ButtonGroupProps> = (props: ButtonGroupProps) => (
  <RNElementsButtonGroup
    {...props}
    textStyle={[props.textStyle]}
    selectedTextStyle={[props.selectedTextStyle]}
    disabledTextStyle={[props.disabledTextStyle]}
    disabledSelectedTextStyle={[props.disabledSelectedTextStyle]}
  />
)

export default ButtonGroup
