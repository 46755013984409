import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import { CompanyBodyParams } from '~/api/companies'
import TitleText from '~/components/common/atoms/TitleText'
import TextForm from '~/components/common/molecules/TextForm'
import TextInputAction from '~/components/common/molecules/TextInputAction'
import color from '~/constants/common/color'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'

type Props = {
  isPC?: boolean
  company: Company
  editable?: boolean
  onChange: (company: Partial<Company>) => void
}

const CompanyInfoForm: React.FC<Props> = ({
  isPC,
  editable,
  company,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [newCompanyInfo, setNewCompanyInfo] = useState<
    Partial<CompanyBodyParams>
  >({})

  const containerStyle = styles.container
  const toast = useCustomToast()

  const updateCompanyAbout = async () => {
    if (isLoading || isEmpty(newCompanyInfo)) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.companies.update<
        Partial<CompanyBodyParams>,
        Company
      >(company.id, newCompanyInfo)
      setIsEditing(false)
      onChange(response)
    } catch (error) {
      toast.showError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View style={containerStyle} testID="company-info-form">
      <TitleText style={styles.title}>
        {t('company.info.infoFormTitle')}
      </TitleText>
      <TextForm
        isEditing={isEditing}
        title={t('company.info.website')}
        value={newCompanyInfo?.homepage ?? company?.homepage}
        onChange={(homepage) =>
          setNewCompanyInfo({ ...newCompanyInfo, homepage })
        }
      />

      <TextForm
        isEditing={isEditing}
        title={t('company.info.tel')}
        value={newCompanyInfo?.tel ?? company.tel}
        onChange={(tel) => setNewCompanyInfo({ ...newCompanyInfo, tel })}
      />

      {/* TODO handle to save company location */}
      {/* <TextForm
        isEditing={isEditing}
        title={t('company.info.location')}
        value={newCompanyInfo?.location ?? company.location}
        onChange={(location) =>
          setNewCompanyInfo({ ...newCompanyInfo, location })
        }
      /> */}

      {editable && (
        <View
          style={isPC ? styles.textInputAction : styles.textInputActionMobile}
          testID="text-input-action-container"
        >
          <TextInputAction
            isEdit={true}
            isVertical={true}
            isSaveLoading={isLoading}
            isNormalMode={!isEditing}
            onSavePress={updateCompanyAbout}
            isDisabled={isEmpty(newCompanyInfo)}
            onEditPress={() => setIsEditing(true)}
            onCancelPress={() => setIsEditing(false)}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
  },
  textInputAction: {
    marginTop: 10,
  },
  textInputActionMobile: {
    alignItems: 'flex-end',
  },
  title: {
    color: color.darkTitleText,
  },
})

export default CompanyInfoForm
