import { filter, find, debounce, isEmpty } from 'lodash'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import SkillListItem from '~/components/common/atoms/SkillListItem'
import SkillText from '~/components/common/atoms/SkillText'
import SkillTextField, {
  SkillTextFieldVariant,
} from '~/components/common/atoms/SkillTextField'
import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import Skill from '~/interfaces/Skill'

type Props = {
  isPC?: boolean
  onChange: (skills: Partial<Skill>[]) => void
  onSkillInputChange?: (skill: string) => void
  skills: Partial<Skill>[]
  editMode?: 'tag' | 'list'
  maxItemLength?: number
}

const SkillForm: React.FC<Props> = ({
  onChange,
  onSkillInputChange,
  skills,
  editMode = 'tag',
  maxItemLength,
}) => {
  const { t } = useTranslation()
  const [inputVariant, setInputVariant] = useState<SkillTextFieldVariant>(
    'dark'
  )
  const isEnoughSkills = maxItemLength && skills.length === maxItemLength

  const addSkill = (skill: Partial<Skill>): void => {
    if (isEnoughSkills) return
    if (!find(skills, { name: skill.name })) {
      const newSkills = skills.slice()
      newSkills.push(skill)
      onChange(newSkills)
    }
  }

  const removeSkill = (skill: Partial<Skill>): void => {
    const newSkills = filter(skills, (current) => current.name !== skill.name)
    onChange(newSkills)
  }

  const skillListItemContainerStyle = {
    dark: {
      borderWidth: 0,
      borderColor: color.transparent,
    },
    danger: {
      borderWidth: 1,
      borderColor: color.danger,
    },
  }

  const renderSkills = (): React.ReactElement => {
    if (editMode === 'list') {
      return (
        <View
          style={StyleSheet.flatten([
            styles.container,
            skillListItemContainerStyle[inputVariant],
          ])}
          testID="list-mode-container"
        >
          {skills.map((item, index) => (
            <SkillListItem
              key={index}
              skill={item}
              isLastItem={index === skills.length - 1}
              onPressRemove={removeSkill}
            />
          ))}
        </View>
      )
    }
    return (
      <View style={styles.skillContainer} testID="tag-mode-container">
        {skills.map((item, index) => (
          <SkillText
            skill={item}
            onClickRemoveIcon={(): void => removeSkill(item)}
            key={index}
          />
        ))}
      </View>
    )
  }

  const fieldWrapperStyle = editMode === 'tag' ? { marginVertical: 10 } : null

  const updateInputVariant = useCallback(
    debounce((skill) => {
      onSkillInputChange?.(skill)
      setInputVariant(
        (!isEmpty(skill) && find(skills, { name: skill.trim() })) ||
          isEnoughSkills
          ? 'danger'
          : 'dark'
      )
    }, 400),
    [setInputVariant, skills]
  )

  return (
    <View style={styles.container} testID="skill-form-container">
      <View style={[styles.fieldWrapper, fieldWrapperStyle]}>
        <View style={styles.subtitleContainer}>
          <TitleText style={styles.skillSubtitle}>
            {t('profile.skillInfo.inputTitle')}
          </TitleText>
          <TitleText style={styles.skillSubtitleGuide}>
            {maxItemLength
              ? t('profile.skillInfo.inputSubtitle', {
                  maximum: maxItemLength,
                })
              : undefined}
          </TitleText>
        </View>
        <SkillTextField
          variant={inputVariant}
          onSubmitSkillText={addSkill}
          onChange={updateInputVariant}
          subtitleColor={isEnoughSkills ? 'warning' : undefined}
        />
      </View>
      {renderSkills()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  fieldWrapper: {
    flex: 1,
  },
  skillContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  skillSubtitle: {
    color: color.textGray,
  },
  skillSubtitleGuide: {
    color: color.textGray,
    fontStyle: 'italic',
    marginLeft: 6,
  },
  subtitleContainer: {
    marginBottom: 10,
    flexDirection: 'row',
  },
})

export default SkillForm
