import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const PrivacySettings: React.FC = () => {
  return (
    <View style={styles.container} testID="privacy-settings">
      <Text style={styles.title}>Privacy</Text>
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    maxWidth: 920,
  },
  title: {
    alignSelf: 'center',
    color: color.unpressableTitleText,
    fontSize: FontSize.REMARKABLE,
  },
})

export default PrivacySettings
