import React from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  children: React.ReactNode
}

const HorizontalContainer: React.FC<Props> = ({ children }: Props) => (
  <View style={styles.container} testID="horizontal-container">
    {children}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default HorizontalContainer
