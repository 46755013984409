import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import {
  CommunityQueryParams,
  CommunityType,
} from '~/api/communities/communities'
import TitleText from '~/components/common/atoms/TitleText'
import CommunityList from '~/components/community/features/CommunityList'
import CreateCommunity from '~/components/community/molecules/CreateCommunity'
import NoCommunityJoinedYet from '~/components/community/molecules/NoCommunitiesJoinedYet'
import { getRouteParams } from '~/utils/navigation'

type Props = {
  isPC: boolean
}

const ManagerCommunity: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const queryParams = getRouteParams<CommunityQueryParams>()

  const getCommunityTitle = (type?: CommunityType): string => {
    switch (type) {
      case 'suggest':
        return t('community.suggestTitle')
      case 'joined':
        return t('community.joinedTitle')
      case 'manage':
        return t('community.manageTitle')
      case 'active':
        return t('community.activeCommunities')
      case 'pending':
        return t('community.pendingCommunities')
      case 'reject':
        return t('community.rejectedCommunities')
      default:
        return ''
    }
  }

  return (
    <View testID="manager-community">
      {queryParams.type === 'manage' && <CreateCommunity isPC={isPC} />}
      <View testID="manager-community-active">
        <CommunityList
          hasLoadMoreButton
          header={
            <TitleText style={styles.header}>
              {getCommunityTitle('active')}
            </TitleText>
          }
          params={{ ...queryParams, type: 'manage', status: 'approved' }}
          emptyView={
            <View style={styles.emptyContainer}>
              <NoCommunityJoinedYet
                title={t('community.noCommunitiesJoinedYet')}
              />
            </View>
          }
        />
      </View>
      <View testID="manager-community-pending">
        <CommunityList
          hasLoadMoreButton
          header={
            <TitleText style={styles.header}>
              {getCommunityTitle('pending')}
            </TitleText>
          }
          params={{ ...queryParams, type: 'manage', status: 'pending' }}
          emptyView={
            <View style={styles.emptyContainer}>
              <NoCommunityJoinedYet
                title={t('community.noCommunitiesJoinedYet')}
              />
            </View>
          }
        />
      </View>
      <View testID="manager-community-reject">
        <CommunityList
          hasLoadMoreButton
          header={
            <TitleText style={styles.header}>
              {getCommunityTitle('reject')}
            </TitleText>
          }
          params={{ ...queryParams, type: 'manage', status: 'reject' }}
          emptyView={
            <View style={styles.emptyContainer}>
              <NoCommunityJoinedYet
                title={t('community.noCommunitiesJoinedYet')}
              />
            </View>
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyContainer: {
    width: 940,
    alignSelf: 'center',
    paddingHorizontal: 8,
    paddingTop: 10,
  },
  header: {
    marginTop: 20,
  },
})

export default ManagerCommunity
