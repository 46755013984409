import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import Post from '~/interfaces/Post'
import { RootState } from '~/rootReducer'

export type PostData = { id: number } & Partial<Post>

export type PostsState = {
  current: PostData
}

const postsSlice = createSlice({
  name: 'posts',
  initialState: {} as PostsState,
  reducers: {
    setPost(state: PostsState, action: PayloadAction<PostData>): void {
      state.current = action.payload
    },
  },
})

export const { setPost } = postsSlice.actions

export const postsSelector = (state: RootState): PostData | undefined =>
  state.posts.current

export default postsSlice.reducer
