import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

type Props = {
  title: string
}

const NoCommunityJoinedYet: React.FC<Props> = ({ title }: Props) => (
  <View style={styles.container} testID="no-community-joined-yet">
    <Text style={styles.title}>{title}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.gray,
    width: 303,
    height: 245,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
})

export default NoCommunityJoinedYet
