import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  createNavigationContainerRef,
  DrawerActions,
  Route,
} from '@react-navigation/native'
import type { LinkingOptions } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { find, isEmpty, last } from 'lodash'
import { createRef, MutableRefObject } from 'react'
import { Linking } from 'react-native'
import { NativeStackNavigationProp } from 'react-native-screens/native-stack'
import snakecaseKeys from 'snakecase-keys'

import { webBaseUrl } from '~/app.config'
import {
  CommunitySection,
  CompanySection,
  currentRoutePathKey,
  JobSection,
  UserSection,
} from '~/constants/common/navigation'
import Notification from '~/interfaces/Notification'
import { RootStackParamList } from '~/interfaces/RootStackParamList'
import {
  careerConfigPath,
  otherCareerConfigPath,
} from '~/utils/career/navigation'
import { getNotifNavigationData } from '~/utils/common/notification'
import {
  communityConfigPath,
  otherCommunityConfigPath,
} from '~/utils/community/navigation'
import { forumConfigPath, otherForumConfigPath } from '~/utils/forum/navigation'

export type RouteParamsID = {
  id?: number
}

// https://reactnavigation.org/docs/5.x/navigating-without-navigation-prop#handling-initialization
// Remove me when upgrate to navigation 6.x
export const isReadyNavigationRef = createRef<boolean>() as MutableRefObject<
  boolean
>
export const screenRef: { title?: string } = {}

export const navigationRef = createNavigationContainerRef<RootStackParamList>()

export function navigate<RouteName extends keyof RootStackParamList>(
  name: keyof RootStackParamList,
  params?: RootStackParamList[RouteName]
): void {
  navigationRef.current &&
    navigationRef.isReady() &&
    navigationRef.current?.navigate<keyof RootStackParamList>(name, params)
}

export type History = {
  type: string
  key: string
}

export type RouteInfo = {
  key: string
  name: string
  path: string
}

export type RootStackNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  keyof RootStackParamList
>

export const parseUserSection = (value?: string): UserSection => {
  return Object.values(UserSection).includes(value as UserSection)
    ? (value as UserSection)
    : UserSection.INFO
}

export const parseCompanySection = (value?: string): CompanySection => {
  return Object.values(CompanySection).includes(value as CompanySection)
    ? (value as CompanySection)
    : CompanySection.PROFILES
}

export const parseJobSection = (value?: string): JobSection => {
  return Object.values(JobSection).includes(value as JobSection)
    ? (value as JobSection)
    : JobSection.FEED
}

export const parseCommunitySection = (value?: string): CommunitySection => {
  return Object.values(CommunitySection).includes(value as CommunitySection)
    ? (value as CommunitySection)
    : CommunitySection.TALK
}
export const getCurrentRoute = (): Route<string> | undefined => {
  return navigationRef.current?.getCurrentRoute()
}
export const saveCurrentRouteAsyncStore = (): void => {
  const currentRoute = getCurrentRoute()
  if (currentRoute && currentRoute.name && currentRoute.name != 'login') {
    let currentPath = ''
    if (currentRoute.path) {
      currentPath = currentRoute.path
    } else if (
      currentRoute.name == 'carrer' ||
      currentRoute.name == 'forum' ||
      currentRoute.name == 'community'
    ) {
      currentPath = `/${currentRoute.name}`
    }
    AsyncStorage.setItem(currentRoutePathKey, currentPath)
  }
}
export const getLastRouteName = ({
  routes,
  histories,
}: {
  routes?: RouteInfo[]
  histories?: History[]
}): string => {
  if (isEmpty(routes) && isEmpty(histories)) {
    return ''
  } else if (!isEmpty(routes) && !isEmpty(histories)) {
    const currentKey = last(histories)?.key
    const lastRoute = find(routes, { key: currentKey })
    return lastRoute && typeof lastRoute === 'object' ? lastRoute.name : ''
  } else if (isEmpty(histories) && !isEmpty(routes)) {
    return last(routes)?.name || ''
  }
  return ''
}

export function setQueryParams<RouteName extends keyof RootStackParamList>(
  params: Partial<RootStackParamList[RouteName]>
): void {
  navigationRef.current &&
    navigationRef.isReady() &&
    navigationRef.current.setParams(snakecaseKeys(params))
}

export function setPageTitle(title?: string | null): void {
  try {
    document && (document.title = title || screenRef.title || 'Enjin')
  } catch (e) {
    //ignore non-web env
  }
  screenRef.title = title || screenRef.title || 'Enjin'
}

export function getRouteParams<T extends object>(): T {
  const routeParams = navigationRef.current?.getCurrentRoute()?.params || {}
  return camelcaseKeys(routeParams as T)
}

export const getPageFromQueryParams = (): number | undefined => {
  type QueryParams = {
    page?: string
  }
  const params = (navigationRef.current?.getCurrentRoute()?.params ||
    {}) as QueryParams
  return params.page ? parseInt(params.page) : undefined
}

export const getCategoriesFromRoute = (
  categories?: string | string[]
): string[] | undefined => {
  if (typeof categories === 'string') {
    return categories.split(',')
  }
  return categories
}

export const openURL = (url: string): void => {
  window.open
    ? window.open(url)
    : Linking.canOpenURL(url).then((canOpen) => canOpen && Linking.openURL(url))
}

export const back = (): void => {
  navigationRef.current?.canGoBack()
    ? navigationRef.current.goBack()
    : navigate('home')
}

export const navigateWithNotification = (notification: Notification): void => {
  const notificationNavigationData = getNotifNavigationData(notification)
  navigate(
    notificationNavigationData?.name as keyof RootStackParamList,
    notificationNavigationData?.data
  )
}

export const openDrawer = (): void => {
  navigationRef.current?.dispatch(DrawerActions.openDrawer())
}

export const closeDrawer = (): void => {
  navigationRef.current?.dispatch(DrawerActions.closeDrawer())
}

/**
 * Add linking router for another pages that is not display in Mobile BottomNavigator
 */
const otherCommonConfigPath = {
  settings: 'settings',
  users: 'users',
  userDetail: {
    path: 'users/:id/:section?',
    parse: {
      id: Number,
      section: parseUserSection,
    },
  },
  notifications: 'notifications',
  mypage: {
    path: 'mypage/:section?',
    parse: {
      section: parseUserSection,
    },
  },
  notfound: '*',
}

const mainCareerScreenMobile = {
  mobiletab: {
    path: '',
    screens: {
      ...careerConfigPath,
      ...otherCareerConfigPath,
      ...otherCommonConfigPath,
    },
  },
}

const mainForumScreenMobile = {
  mobiletab: {
    path: '',
    screens: {
      ...forumConfigPath,
      ...otherForumConfigPath,
      ...otherCommonConfigPath,
    },
  },
}

const mainCommunityScreenMobile = {
  mobiletab: {
    path: '',
    screens: {
      ...communityConfigPath,
      ...otherCommunityConfigPath,
      ...otherCommonConfigPath,
    },
  },
}

export const getLinking = (
  isPC: boolean
): LinkingOptions<RootStackParamList> => ({
  prefixes: ['expo://'],
  config: {
    screens: {
      login: 'login',
      signup: 'signup',
      privacy: 'privacy',
      terms: 'terms',
      requestPasswordReset: 'request-password-reset',
      resetPassword: 'reset_password',
      registration: 'registration',
      topPage: '',
      resumeShow: {
        path: 'resumes/:id',
        parse: {
          id: Number,
        },
      },
      companySignUp: 'company/signup',
      companySignIn: 'company/signin',
      landingCompany: 'company',
      subscriptionPlan: 'subscriptions',
      careerMain: {
        path: 'career',
        screens: isPC
          ? {
              ...careerConfigPath,
              ...otherCareerConfigPath,
              ...otherCommonConfigPath,
            }
          : mainCareerScreenMobile,
      },
      forumMain: {
        path: 'forum',
        screens: isPC
          ? {
              ...forumConfigPath,
              ...otherForumConfigPath,
              ...otherCommonConfigPath,
            }
          : mainForumScreenMobile,
      },
      communityMain: {
        path: 'community',
        screens: isPC
          ? {
              ...communityConfigPath,
              ...otherCommunityConfigPath,
              ...otherCommonConfigPath,
            }
          : mainCommunityScreenMobile,
      },
    },
  },
})

const getSharingWebBaseUrl = (): string => {
  // Cannot check for mobile
  if (!isEmpty(window?.location?.origin)) {
    return `${window.location.origin}/`
  }
  return webBaseUrl
}

type SharingURLParams = {
  id: number
  type: 'question' | 'post'
}

export const getSharingUrl = ({ id, type }: SharingURLParams): string => {
  const baseUrl = getSharingWebBaseUrl()

  switch (type) {
    case 'question':
      return baseUrl + `forum/questions/${id}`
    case 'post':
      return baseUrl + `posts/${id}`
    default:
      return baseUrl
  }
}
