import AsyncStorage from '@react-native-async-storage/async-storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isString } from 'lodash'

import api from '~/api'
import { fetchCurrentUser } from '~/slices/common/users'
import { AppThunk } from '~/store'

type AuthState = {
  accessToken: string | undefined
  isLoading: boolean
  error: string | null
  unconfirmedEmail: boolean | null
}

const authSlice = createSlice({
  name: 'auth',
  // Since it's called very beginning, isLoading inital value is true
  initialState: { accessToken: undefined, isLoading: true } as AuthState,
  reducers: {
    loginStart(state: AuthState): void {
      state.isLoading = true
      state.error = null
    },
    loginSuccess(state: AuthState, action: PayloadAction<string>): void {
      const accessToken = action.payload
      state.accessToken = accessToken
      state.isLoading = false
      AsyncStorage.setItem(accessTokenStorageKey, accessToken)
    },
    authFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
    notConfirmedError(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
      state.unconfirmedEmail = true
    },
    logout(state: AuthState): void {
      state.accessToken = ''
      AsyncStorage.removeItem(accessTokenStorageKey)
    },
    resendStart(state: AuthState): void {
      state.isLoading = true
    },
    resendFinish(state: AuthState): void {
      state.isLoading = false
    },
  },
})

export const {
  loginStart,
  loginSuccess,
  authFailure,
  notConfirmedError,
  logout,
  resendStart,
  resendFinish,
} = authSlice.actions

type LoginParams = {
  email: string
  password: string
}

type LoginWithSocialParams = {
  accessToken: string
}

export const loginSocialSuccess = ({
  accessToken,
}: LoginWithSocialParams): AppThunk => async (dispatch): Promise<void> => {
  await dispatch(loginSuccess(accessToken))
  dispatch(fetchCurrentUser())
}

export const login = (params: LoginParams): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(loginStart())
    const response = await api.auth.login({
      email: params.email,
      password: params.password,
    })
    const accessToken = response.accessToken
    if (accessToken) {
      dispatch(loginSuccess(accessToken))
      await Promise.all([dispatch(fetchCurrentUser())])
    }
  } catch (err) {
    if (isString(err.error)) {
      dispatch(notConfirmedError(String(err.error)))
    } else {
      dispatch(authFailure(String(err.error.detail)))
    }
    dispatch(logout())
    throw err
  }
}

export const resendConfirmation = (email: string): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(resendStart())
    await api.auth.resendConfirmation({ email })
  } finally {
    dispatch(resendFinish())
  }
}

const accessTokenStorageKey = 'access_token'
export const loginWithStoredToken = (): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(loginStart())
    const accessToken = await AsyncStorage.getItem(accessTokenStorageKey)
    if (accessToken) {
      await dispatch(loginSuccess(accessToken)) //NOTE Should wait for saving access token
      await dispatch(fetchCurrentUser())
    } else {
      dispatch(authFailure('Login failed'))
    }
  } catch (err) {
    if (isString(err)) {
      dispatch(authFailure(err))
    } else {
      dispatch(authFailure(err.error.detail))
    }
    dispatch(logout())
    throw err
  }
}

export default authSlice.reducer
