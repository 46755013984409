import { useFocusEffect } from '@react-navigation/native'
import Constants from 'expo-constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { WebView } from 'react-native-webview'

import { setPageTitle } from '~/utils/navigation'

const Terms: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('terms.terms.title')))

  const { i18n } = useTranslation()
  const content =
    i18n.language === 'ja'
      ? require('~/assets/html/terms.ja.html')
      : require('~/assets/html/terms.en.html')

  return Constants.platform?.web ? (
    <ScrollView testID="terms-of-services-view">
      <div
        style={styles.container}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </ScrollView>
  ) : (
    <WebView originWhitelist={['*']} source={content} testID="terms-webview" />
  )
}

const styles = {
  container: {
    padding: 20,
  },
}

export default Terms
