import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ListRenderItem,
  SectionList,
  StyleSheet,
  View,
  TouchableOpacity,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import noMemberImage from '~/assets/images/prompts/nomembersyet.png'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import EmptyView from '~/components/common/molecules/EmptyView'
import ChannelAddMember from '~/components/community/features/ChannelAddMember'
import HeaderCommunityDetail from '~/components/community/features/HeaderCommunityDetail'
import CommunityUserControlMenu, {
  CommunityUserControlMenuType,
  CommunityUserControlMenuData,
} from '~/components/community/molecules/CommunityUserControlMenu'
import CommunityUserItem from '~/components/community/molecules/CommunityUserItem'
import HeaderCommunityUser from '~/components/community/organisms/HeaderCommunityUser'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import CommunityUser from '~/interfaces/CommunityUser'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import {
  channelSelector,
  communitySelector,
  isCommunityAdmin,
  removeChannelMember,
  removeCommunityMember,
  updateChannelMember,
  updateCommunityMember,
} from '~/slices/community/community'

type MemberData = {
  key: string
  title: string
  data: CommunityUser[]
}

export enum CommunityUserListType {
  Channel = 'channel',
  Community = 'community',
}

type Props = {
  type: CommunityUserListType
}

export const communityUserMenuData = (
  translate: Function,
  isAdmin: boolean,
  isMe: boolean
): CommunityUserControlMenuData[] => [
  {
    visible: !isAdmin && !isMe,
    type: CommunityUserControlMenuType.MakeAdmin,
    title: translate('community.admin.makeAdmin'),
  },
  {
    visible: !isMe,
    needConfirm: true,
    type: CommunityUserControlMenuType.RemoveMember,
    title: translate('community.removeFromCommunity'),
  },
  {
    visible: !isMe && isAdmin,
    needConfirm: false,
    type: CommunityUserControlMenuType.RemoveFromAdmin,
    title: translate('community.admin.removeFromAdmin'),
  },
]

export const channelUserMenuData = (
  translate: Function,
  isChannelAdmin?: boolean
): CommunityUserControlMenuData[] => [
  {
    visible: !isChannelAdmin,
    type: CommunityUserControlMenuType.MakeChannelAdmin,
    title: translate('community.admin.makeAdmin'),
  },
  {
    visible: true,
    needConfirm: true,
    type: CommunityUserControlMenuType.RemoveMember,
    title: translate('community.removeFromChannel'),
  },
  {
    visible: !!isChannelAdmin,
    type: CommunityUserControlMenuType.RemoveChannelAdmin,
    title: translate('community.admin.removeFromAdmin'),
  },
]

const CommunityUserList: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchWord, setSearchWord] = useState('')
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const currentUser = useSelector((state: RootState) => state.users.current)
  const [isShowInviteMemberDialog, setShowInviteMemberDialog] = useState(false)
  const channel = useSelector(channelSelector)
  const community = useSelector(communitySelector)

  const containerStyle = isPC
    ? StyleSheet.flatten([styles.container, styles.paddingContainer])
    : styles.container

  const allMembers =
    type === CommunityUserListType.Channel
      ? channel?.communityChannelMembers
      : community?.communityMembers

  const [admins, setAdmins] = useState<CommunityUser[]>(
    allMembers?.filter((item) => item.isAdmin) || []
  )
  const isMeAdmin = isCommunityAdmin(currentUser?.id, admins)
  const [members, setMembers] = useState<CommunityUser[]>(
    allMembers?.filter((item) => !item.isAdmin) || []
  )

  const isSearchWordMatchUser = (user: User): boolean => {
    const lowerCaseSearchWord = searchWord.toLowerCase()
    return (
      (user.profile?.enName || '')
        .toLowerCase()
        .includes(lowerCaseSearchWord) ||
      (user.profile?.name || '').toLowerCase().includes(lowerCaseSearchWord)
    )
  }

  useEffect(() => {
    setAdmins(
      allMembers?.filter(
        (item) => item.isAdmin && isSearchWordMatchUser(item.user)
      ) || []
    )
    setMembers(
      allMembers?.filter(
        (item) => !item.isAdmin && isSearchWordMatchUser(item.user)
      ) || []
    )
  }, [
    searchWord,
    community?.communityMembers,
    channel?.communityChannelMembers,
  ])

  const memberData: MemberData[] = [
    {
      key: 'admin',
      data: admins,
      title: t('community.information.admins'),
    },
    {
      key: 'member',
      data: members,
      title: t('community.allMembers'),
    },
  ]

  const handleSelectUserControlMenu = (
    menuType: CommunityUserControlMenuType,
    memberId: number,
    userId: number
  ): void => {
    switch (menuType) {
      case CommunityUserControlMenuType.MakeAdmin:
        dispatch(updateCommunityMember({ id: memberId, isAdmin: true }))
        break
      case CommunityUserControlMenuType.MakeChannelAdmin:
        if (channel) {
          dispatch(
            updateChannelMember(memberId, {
              communityChannelId: channel.id,
              userId,
              isAdmin: true,
            })
          )
        }
        break
      case CommunityUserControlMenuType.RemoveChannelAdmin:
        if (channel) {
          dispatch(
            updateChannelMember(memberId, {
              communityChannelId: channel.id,
              userId,
              isAdmin: false,
            })
          )
        }
        break
      case CommunityUserControlMenuType.RemoveMember:
        type === CommunityUserListType.Community
          ? dispatch(removeCommunityMember(memberId))
          : dispatch(removeChannelMember(memberId))
        break
      case CommunityUserControlMenuType.RemoveFromAdmin:
        dispatch(updateCommunityMember({ id: memberId, isAdmin: false }))
        break
    }
  }

  const renderItem: ListRenderItem<CommunityUser> = useCallback(
    ({ item }: { item: CommunityUser }): JSX.Element => (
      <CommunityUserItem
        isPC={isPC}
        communityUser={item}
        isMe={currentUser?.id === item.userId}
        controlMenu={
          type === CommunityUserListType.Community && isMeAdmin ? (
            <CommunityUserControlMenu
              items={communityUserMenuData(
                t,
                item.isAdmin,
                item.userId === currentUser?.id
              )}
              onSelect={(type): void =>
                handleSelectUserControlMenu(type, item.id, item.userId)
              }
            />
          ) : isMeAdmin && item.userId !== currentUser?.id ? (
            <CommunityUserControlMenu
              items={channelUserMenuData(t, item.isAdmin)}
              onSelect={(type): void =>
                handleSelectUserControlMenu(type, item.id, item.userId)
              }
            />
          ) : undefined
        }
      />
    ),
    [isPC, isMeAdmin, type, currentUser]
  )
  const keyExtractor = (item: CommunityUser, index: number): string =>
    (item.id + index).toString()
  const invitationStyle = isPC ? styles.invitation : styles.invitationMobile

  return (
    <View testID="community-user-list" style={containerStyle}>
      <SectionList
        sections={memberData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          <>
            {!isPC && <HeaderCommunityDetail />}
            <HeaderCommunityUser
              searchWord={searchWord}
              onChange={setSearchWord}
              totalOfMembers={members?.length}
            />
          </>
        }
        SectionSeparatorComponent={(): JSX.Element => (
          <View style={styles.separator} />
        )}
        renderSectionHeader={({ section: { title, key } }): JSX.Element => (
          <HorizontalContainer>
            <Text style={styles.header}>{title}</Text>
            {key === 'admin' &&
              type === CommunityUserListType.Channel &&
              isMeAdmin && (
                <TouchableOpacity
                  testID="add-member-button"
                  onPress={(): void => setShowInviteMemberDialog(true)}
                >
                  <Text style={styles.inviteMembers}>
                    {t('community.addMembers')}
                  </Text>
                </TouchableOpacity>
              )}
          </HorizontalContainer>
        )}
        renderSectionFooter={({
          section: { data, key },
        }): JSX.Element | null => {
          if (key === 'member' && data.length === 0) {
            return (
              <EmptyView
                isPC={isPC}
                title={t('community.emptyPages.member.title')}
                imageSource={noMemberImage}
              />
            )
          }

          return null
        }}
      />
      {isShowInviteMemberDialog && (
        <ModalContainer
          visible={isShowInviteMemberDialog}
          onDismiss={(): void => setShowInviteMemberDialog(false)}
        >
          <View style={invitationStyle}>
            <ChannelAddMember />
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingBottom: 10,
  },
  header: {
    ...commonStyles.titleTextSize,
    color: color.unpressableTitleText,
    flex: 1,
    fontWeight: 'bold',
    marginTop: 20,
    paddingLeft: 10,
  },
  invitation: {
    backgroundColor: color.white,
    borderColor: color.lightGray,
    borderRadius: 4,
    height: 635,
    padding: 40,
    width: 500,
  },
  invitationMobile: {
    backgroundColor: color.white,
    borderRadius: 4,
    height: '100%',
    maxHeight: 500,
    maxWidth: 375,
    padding: 10,
    width: '100%',
  },
  inviteMembers: {
    color: color.pressableText,
    fontSize: FontSize.REMARKABLE,
    marginTop: 20,
    paddingRight: 4,
    paddingLeft: 10,
  },
  paddingContainer: {
    paddingHorizontal: 40,
    paddingTop: 20,
  },
  separator: {
    borderBottomColor: color.lightGray,
    borderBottomWidth: 2,
    marginTop: 20,
  },
})

export default CommunityUserList
