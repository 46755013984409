import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'

type Props = {
  searchWord?: string
  totalOfMembers?: number
  onChange?: (searchWord: string) => void
}

const HeaderCommunityUser: React.FC<Props> = ({
  onChange,
  searchWord,
  totalOfMembers,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="header-community-user">
      <Input
        value={searchWord}
        onChangeText={onChange}
        labelStyle={styles.label}
        inputStyle={styles.input}
        containerStyle={styles.inputContainer}
        placeholder={t('community.searchForMembers')}
        label={
          totalOfMembers
            ? `${totalOfMembers} ${t('community.information.members')}`
            : undefined
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
  },
  input: {
    borderColor: color.textGray,
    borderWidth: 1,
    paddingHorizontal: 4,
    ...commonStyles.bodyTextSize,
    ...commonStyles.borderRadiusAllCorner,
  },
  inputContainer: {
    paddingHorizontal: 10,
  },
  label: {
    marginBottom: 6,
  },
})

export default HeaderCommunityUser
