import { isNil } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SocialLikeCount from '~/components/common/features/socials/SocialLikeCount'
import Post from '~/interfaces/Post'
import { RootState } from '~/rootReducer'

type Props = {
  post: Post
}

const PostLikeCount: React.FC<Props> = ({ post }: Props) => {
  const [likeCount, setLikeCount] = useState(post.likeCount)

  const postLikeCount = useSelector((state: RootState) =>
    state.posts?.current?.id === post.id
      ? state.posts.current.likeCount
      : undefined
  )

  useEffect(() => {
    !isNil(postLikeCount) && setLikeCount(postLikeCount)
  }, [postLikeCount])

  return likeCount > 0 ? (
    <SocialLikeCount likeCount={likeCount} likeParams={{ postId: post.id }} />
  ) : (
    <></>
  )
}

export default PostLikeCount
