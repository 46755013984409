import { APIError } from '~/api/error'
import { HTTPClient } from '~/httpClient'

class APIClient extends HTTPClient {
  async httpRequest<T extends {}>(
    url: string,
    request: RequestInit
  ): Promise<T> {
    return super.httpRequest(url, request).catch(APIError.wrap) as Promise<T>
  }
}
export default APIClient
