import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import ProfileFollow from '~/components/common/molecules/users/ProfileFollow'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import CommunityMemberJoinRequest from '~/interfaces/CommunityMemberJoinRequest'

type Props = {
  isPC: boolean
  requestUserActions: React.ReactElement
  memberJoinRequest: CommunityMemberJoinRequest
}

const RequestedUser: React.FC<Props> = ({
  isPC,
  requestUserActions,
  memberJoinRequest,
}: Props) => {
  const user = memberJoinRequest.user

  return (
    <View
      testID="requested-user"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <View style={isPC ? styles.row : styles.rowMobile}>
        <UserAvatar
          user={user}
          size={isPC ? ProfileAvatarSize.LARGE : ProfileAvatarSize.MEDIUM}
        />
        <View style={isPC ? styles.profileInfo : styles.profileInfoMobile}>
          <UserName size="medium" user={user} />
          <Text style={styles.description} numberOfLines={2}>
            {user?.profile?.title}
          </Text>
          <Text style={styles.description} numberOfLines={2}>
            {user?.profile?.residence}
          </Text>
          <ProfileFollow isPC={true} type="user-item" user={user} />
        </View>
      </View>
      <View style={isPC ? styles.row : styles.rowMobile}>
        {!isPC && <View style={styles.indent} />}
        <View
          testID="profile-info"
          style={isPC ? styles.profileInfo : styles.profileInfoMobile}
        >
          {requestUserActions}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  containerMobile: {
    alignItems: 'center',
    backgroundColor: color.white,
    flexDirection: 'column',
    flex: 1,
    padding: 10,
  },
  description: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.GENERAL,
  },
  indent: {
    width: 50,
  },
  profileInfo: {
    marginLeft: 20,
    width: 200,
  },
  profileInfoMobile: {
    marginLeft: 13,
    width: '80%',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  rowMobile: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 10,
    width: '100%',
  },
})

export default RequestedUser
