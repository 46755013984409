import React from 'react'
import { View, StyleSheet } from 'react-native'

type Props = {
  children?: React.ReactNode
}

const SocialActionContainer: React.FC<Props> = ({ children }: Props) => (
  <View style={styles.container} testID="social-action-container">
    {children}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
})

export default SocialActionContainer
