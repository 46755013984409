import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { ReactNode } from 'react'
import { Platform } from 'react-native'

const STRIPE_PUBLISHABLE_KEY_TEST =
  'pk_test_51NXlhOHJQi8yd7bOBhIUwJDcc15EjHCqv5rmLCxURzPzMqaXm39zQIWytKE9wCAoM7YozK6sBQ1P2WAG9sLw8pwr00PELYLFWb'
const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51NXlhOHJQi8yd7bOSgKxJXWBaBseseABgJYuyrOgZzVlQij3vTUjTZ72uMf3fC4cFvemyijDO9QClRQA1J9XF0ZH00V3eSY3kt'
export const getStripePubpishableKey = (): string => {
  // Return production key if on mobile platform
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    return STRIPE_PUBLISHABLE_KEY
  }
  switch (window.location?.hostname) {
    case 'localhost':
      return STRIPE_PUBLISHABLE_KEY_TEST
    case 'staging.enjin.world':
      return STRIPE_PUBLISHABLE_KEY_TEST
    case 'enjin.world':
      return STRIPE_PUBLISHABLE_KEY
    default:
      return STRIPE_PUBLISHABLE_KEY_TEST
  }
}

const stripePromise = loadStripe(getStripePubpishableKey())

type Props = {
  children?: ReactNode
}

const StripeContext: React.FC<Props> = ({ children }: Props) => (
  <Elements stripe={stripePromise}>{children}</Elements>
)

export default StripeContext
