import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import color from '~/constants/common/color'

type Props = {
  inverted?: boolean
  size?: 'small' | 'medium'
}

const MoreHorizRoundIcon: React.FC<Props> = ({ size, inverted }: Props) => {
  let iconSize = 25
  switch (size) {
    case 'small':
      iconSize = 14
      break
    case 'medium':
    default:
      iconSize = 25
  }

  return (
    <View testID="icon" style={styles.container}>
      <Svg
        viewBox={`0 0 ${iconSize} ${iconSize}`}
        data-supported-dps={`${iconSize}x${iconSize}`}
        fill={inverted ? color.white : color.pressableText}
        width={iconSize}
        height={iconSize}
      >
        <Path d="M14 12a2 2 0 11-2-2 2 2 0 012 2zM4 10a2 2 0 102 2 2 2 0 00-2-2zm16 0a2 2 0 102 2 2 2 0 00-2-2z"></Path>
      </Svg>
    </View>
  )
}

const styles = {
  container: {
    backgroundColor: color.lightMask,
    borderRadius: 30,
    marginRight: 5,
    padding: 4,
  },
}

export default MoreHorizRoundIcon
