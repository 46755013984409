import React from 'react'
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

import commonStyles from '~/constants/common/commonStyles'

type Props = {
  children?: React.ReactNode
} & ViewProps

const CardContainer: React.FC<Props> = (props: Props) => (
  <View {...props} style={StyleSheet.flatten([cardStyle, props.style])}>
    {props.children}
  </View>
)

const cardStyle = {
  overflow: 'hidden',
  ...commonStyles.shadow,
  ...commonStyles.borderRadiusAllCorner,
} as ViewStyle

export default CardContainer
