import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import Skill from '~/interfaces/Skill'

type Props = {
  skills: Skill[]
}

const JobSkills: React.FC<Props> = ({ skills }: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="job-skills" style={styles.container}>
      <Text style={styles.title}>{t('jobs.skillsNeeded')}</Text>
      {skills.map((item) => (
        <View style={styles.item} key={item.id} testID="job-skill">
          <Text style={styles.itemText}>{item.name}</Text>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 20,
  },
  item: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: color.lightGray,
  },
  itemText: {
    color: color.textGray,
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 'bold',
    ...commonStyles.titleTextSize,
  },
})

export default JobSkills
