import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextStyle, View } from 'react-native'

import RegistrationIndicator from '~/components/common/atoms/users/RegistrationIndicator'
import FullNameInput from '~/components/common/molecules/users/FullNameInput'
import PickerGroup from '~/components/common/molecules/users/registration/PickerGroup'
import Button from '~/components/workarounds/Button'
import { FontSize } from '~/constants/common/font'
import Profile from '~/interfaces/Profile'

type Props = {
  registrationData: Partial<Profile>
  onUpdateRegistrationData: (data: Partial<Profile>) => void
  onNext: () => void
  isPC?: boolean
}

type UpdateProfileHook = {
  givenName: string
  setGivenName: (givenName: string) => void
  familyName: string
  setFamilyName: (familyName: string) => void
  birthday?: string
  setBirthday: (birthday?: string) => void
  country: string
  setCountry: (country: string) => void
  sex: string
  setSex: (sex: string) => void
}

export const useUpdateProfile = (
  profileData: Partial<Profile>
): UpdateProfileHook => {
  const [givenName, setGivenName] = useState(profileData.givenName || '')
  const [familyName, setFamilyName] = useState(profileData.familyName || '')
  const [birthday, setBirthday] = useState(profileData.birthday)
  const [country, setCountry] = useState(profileData.country || '')
  const [sex, setSex] = useState(profileData.sex || '')

  return {
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    birthday,
    setBirthday,
    country,
    setCountry,
    sex,
    setSex,
  }
}

const UpdateProfileForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [isDataValid, setIsDataValid] = useState(false)
  const {
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    birthday,
    setBirthday,
    country,
    setCountry,
    sex,
    setSex,
  } = useUpdateProfile(props.registrationData)

  const onUpdateRegistrationData = (): void => {
    const profileData = {
      givenName,
      familyName,
      birthday,
      country,
      sex,
    }
    props.onUpdateRegistrationData(profileData)
    props.onNext()
  }

  const validate = (): void => {
    if (givenName && familyName && sex && birthday && country) {
      setIsDataValid(true)
    } else {
      setIsDataValid(false)
    }
  }

  const titleButtonStyle = {
    fontSize: FontSize.GENERAL,
  } as TextStyle

  useEffect(() => {
    validate()
  }, [givenName, familyName, sex, birthday, country])

  return (
    <View testID="update-profile-form" style={styles.container}>
      <RegistrationIndicator index={0} isPC={props.isPC} />
      <FullNameInput
        isPC={props.isPC}
        givenName={givenName}
        familyName={familyName}
        onChangeLastName={setGivenName}
        onChangeFirstName={setFamilyName}
        isEnglish={false}
      />
      <View style={styles.verticalSeparator} />
      <PickerGroup
        isPC={props.isPC}
        onBirthdateChange={setBirthday}
        onGenderChange={setSex}
        onCountryChange={setCountry}
        registrationData={{ birthday, sex, country }}
      />
      <Button
        testID="next-button"
        style={StyleSheet.flatten(styles.nextButton)}
        title={t('next')}
        titleStyle={titleButtonStyle}
        disabled={!isDataValid}
        onPress={onUpdateRegistrationData}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  nextButton: {
    marginBottom: 20,
    marginTop: 30,
  },
  verticalSeparator: {
    marginTop: 20,
  },
})

export default UpdateProfileForm
