import { Fontisto } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListRenderItem, StyleSheet, View } from 'react-native'

import api from '~/api'
import { LikeParams } from '~/api/likes'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import ReactionItem from '~/components/common/organisms/ReactionItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import Like from '~/interfaces/Like'

type Props = {
  params: Partial<LikeParams>
  onPressClose?: () => void
}

const keyExtractor = (item: Like, index: number): string =>
  `${item.id.toString()}-${index}`

const ReactionList: React.FC<Props> = ({ params, onPressClose }: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const defaultPage = 1
  const [reactions, setReactions] = useState<Like[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [likeParams, setLikeParams] = useState<Partial<LikeParams>>({
    ...params,
    page: defaultPage,
  })

  const fetchReactions = async (page: number): Promise<void> => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const response = await api.likes.index<Like[], Partial<LikeParams>>({
        ...likeParams,
        page: page,
      })
      if (likeParams.page && page > likeParams.page) {
        if (!isEmpty(response)) {
          setLikeParams({ ...likeParams, page })
          setReactions((reactions) => [...reactions, ...response])
        }
      } else {
        setReactions(response)
      }
    } catch (error) {
      toast.showError(error)
    }
    setIsLoading(false)
  }

  const fetchMore = (): void => {
    fetchReactions(likeParams.page! + 1)
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchReactions(likeParams.page!)
    }, [params.postId, params.answerId, params.questionId])
  )

  const renderItem: ListRenderItem<Like> = ({ item }: { item: Like }) => (
    <ReactionItem like={item} />
  )

  return (
    <View testID="reaction-list">
      <InfiniteScrollFlatList
        data={reactions}
        listKey="reaction-list"
        renderItem={renderItem}
        onReachBottom={fetchMore}
        style={styles.reactionList}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          <View style={styles.header}>
            <Text style={styles.headerText}>{t('likes')}</Text>
            <Fontisto
              size={14}
              name="close-a"
              color={color.textGray}
              onPress={onPressClose}
            />
          </View>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: 1,
    borderColor: color.gray,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 10,
  },
  headerText: {
    color: color.textGray,
    fontWeight: 'bold',
    ...commonStyles.titleTextSize,
  },
  reactionList: {
    backgroundColor: color.white,
    height: 400,
    padding: 20,
    width: 300,
  },
})

export default ReactionList
