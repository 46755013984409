import React from 'react'

import DefaultLayout from '~/components/DefaultLayout'
import CompanyJobEditTemplate from '~/components/career/templates/company/CompanyJobEditTemplate'

const CompanyJobEdit: React.FC = () => (
  <DefaultLayout>
    <CompanyJobEditTemplate />
  </DefaultLayout>
)

export default CompanyJobEdit
