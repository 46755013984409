import { useNavigation } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import MediaArea from '~/components/career/organisms/posts/PostItem/MediaArea'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import TitleText from '~/components/common/atoms/TitleText'
import CategoriesGroup from '~/components/common/molecules/CategoriesGroup'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import QuestionCreatedInfo from '~/components/forum/molecules/questions/QuestionCreatedInfo'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { RootContext } from '~/contexts/RootContext'
import Question from '~/interfaces/Question'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  question: Question
  questionControlMenu?: React.ReactElement
  questionSocialActivity?: React.ReactElement
}

const QuestionItem: React.FC<Props> = ({
  question,
  questionControlMenu,
  questionSocialActivity,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const navigateToQuestionDetail = (): void => {
    question && navigate('questionShow', { id: question.id })
  }

  return (
    <View testID="question-item">
      <View style={styles.header}>
        <TouchableOpacity
          style={styles.navigateButton}
          testID="question-item-title"
          onPress={navigateToQuestionDetail}
        >
          <TitleText style={styles.title} numberOfLines={2}>
            {question.title}
          </TitleText>
        </TouchableOpacity>
        <View testID="control-menu">{questionControlMenu}</View>
      </View>

      <View style={styles.descriptionContainer}>
        <UserAvatar user={question.user} size={ProfileAvatarSize.SMALL} />
        <View style={styles.description}>
          <QuestionCreatedInfo
            user={question.user}
            isPC={isPC}
            createdAt={question.createdAt}
          />
        </View>
      </View>
      <Text style={styles.questionBody}>{question.body}</Text>
      {!isEmpty(question.images) && <MediaArea entity={question} />}
      <CategoriesGroup categories={question.categories} />
      {questionSocialActivity}
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 14,
  },
  descriptionContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navigateButton: {
    flexShrink: 1,
    width: 'auto',
  },
  questionBody: {
    ...commonStyles.bodyTextSize,
    color: color.darkTitleText,
    marginBottom: 10,
  },
  title: {
    color: color.pressableText,
    paddingRight: 40,
  },
})
export default QuestionItem
