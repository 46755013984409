import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import User from '~/interfaces/User'

type Props = { user: User }

const Attendee: React.FC<Props> = ({ user }: Props) => (
  <View testID="event-attendee" style={styles.container}>
    <UserAvatar user={user} size={ProfileAvatarSize.TINY} />
    <View style={styles.userInfo}>
      <UserName user={user} size="small" />
      <Text numberOfLines={1} style={styles.userTitle}>
        {user.profile.title}
      </Text>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
  userInfo: {
    flex: 1,
    marginLeft: 10,
  },
  userTitle: {
    color: color.textGray,
    flexWrap: 'wrap',
  },
})

export default Attendee
