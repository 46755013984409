import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import NotificationsTemplate from '~/components/common/templates/NotificationsTemplate'
import { setPageTitle } from '~/utils/navigation'

const Notifications: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('notification.notifications')))

  return (
    <DefaultLayout>
      <NotificationsTemplate />
    </DefaultLayout>
  )
}

export default Notifications
