import type { HTTPClient } from '~/httpClient'
import URLInspection from '~/interfaces/URLInspection'

export class URLInspectionsAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async get(url: string): Promise<URLInspection> {
    return await this.client.get<URLInspection>(
      `/url_inspections/${encodeURIComponent(url)}`
    )
  }
}
