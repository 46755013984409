import { range, reduce } from 'lodash'
import moment from 'moment'
import React from 'react'
import { View } from 'react-native'

import CustomPicker, {
  pickerValue,
} from '~/components/common/molecules/CustomPicker'

export type TimeData = {
  hour: number
  minute: number
}

type Props = {
  title?: string
  minuteSteps: number
  selectedTime?: string
  onChange: (time: string) => void
}

export const TimeFormat = 'h:mm A'

export const getTime = (minuteSteps: number): string[] =>
  reduce(
    range(24),
    (memo, hour) => {
      range(0, 60, minuteSteps).forEach((minute) =>
        memo.push(moment({ hour, minute }).format(TimeFormat))
      )
      return memo
    },
    ['']
  )
const TimePicker: React.FC<Props> = ({
  title,
  onChange,
  minuteSteps,
  selectedTime,
}: Props) => {
  const timeData = getTime(minuteSteps)
  const timePickerData = timeData.map((time, index) => pickerValue(time, index))

  return (
    <View testID="time-picker">
      <CustomPicker
        size="medium"
        title={title}
        data={timePickerData}
        selectedValue={timeData.indexOf(selectedTime ?? '')}
        onValueChange={(value): void => onChange(timeData[value as number])}
      />
    </View>
  )
}

export default TimePicker
