import { HTTPClient } from '~/httpClient'
import Account from '~/interfaces/Account'

export type UpdatePasswordRequest = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export class AccountAPI {
  client: HTTPClient

  constructor(client: HTTPClient) {
    this.client = client
  }

  async fetchAccount(): Promise<Account> {
    return await this.client.get<Account>('/account')
  }

  async updateEmail(email: string): Promise<Account> {
    return await this.client.patch<Account>('/account/update_email', {
      user: { email },
    })
  }

  async updatePassword(passwordData: UpdatePasswordRequest): Promise<Account> {
    return await this.client.patch<Account>('/account/update_password', {
      user: passwordData,
    })
  }
}
