import React from 'react'
import { ImageSourcePropType, StyleSheet, View, Image } from 'react-native'

import EmptyTitle from '~/components/common/atoms/EmptyTitle'

type Props = {
  isPC: boolean
  title: string
  subtitle?: string
  imageSource: ImageSourcePropType
}

const EmptyView: React.FC<Props> = ({
  title,
  subtitle,
  imageSource,
  isPC,
}: Props) => {
  const imageStyle = isPC ? styles.imagePC : styles.imageMobile
  const containerStyle = isPC ? styles.containerPC : styles.containerMobile

  return (
    <View style={containerStyle} testID="empty-view">
      <Image
        testID="empty-image"
        source={imageSource}
        style={imageStyle}
        resizeMode="contain"
      />
      <EmptyTitle title={title} subtitle={subtitle} />
    </View>
  )
}

const styles = StyleSheet.create({
  containerMobile: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 50,
    marginTop: 50,
  },
  containerPC: {
    alignSelf: 'center',
    marginBottom: 115,
    marginTop: 115,
  },
  imageMobile: {
    alignSelf: 'center',
    height: 150,
    width: 288,
  },
  imagePC: {
    height: 150,
    width: 288,
  },
})

export default EmptyView
