import { some } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'

import api from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import { CreateInvitationParams } from '~/api/communities/communityEventAttendeeInvitation'
import { UsersParams } from '~/api/users'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import SearchInput from '~/components/common/atoms/SearchInput'
import CommunityUserInvitationItem from '~/components/community/molecules/CommunityUserInvitationItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityEvent from '~/interfaces/CommunityEvent'
import CommunityEventAttendeeInvitation from '~/interfaces/CommunityEventAttendeeInvitation'
import { InvitationUser } from '~/interfaces/CommunityUser'
import User from '~/interfaces/User'

type Props = {
  communityEvent: CommunityEvent
}

const EventAttendeeInvitation: React.FC<Props> = ({
  communityEvent,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [users, setUsers] = useState<InvitationUser[]>([])
  const [invitedUsers, setInvitedUsers] = useState<InvitationUser[]>([])
  const [isLoadingInvitedUser, setIsLoadingInvitedUser] = useState(true)

  const fetchUsers = async (pageIndex: number): Promise<void> => {
    if (isLoading) {
      return
    }
    setLoading(true)
    try {
      const response = await api.users.index<User[], UsersParams>({
        name: keyword,
        page: pageIndex,
      })
      if (pageIndex === page) {
        updateInvitationUsers(response, false)
      } else if (response.length > 0) {
        updateInvitationUsers(response, true)
        setPage(pageIndex)
      }
    } catch (error) {
      toast.showError(error)
    }
    setLoading(false)
  }

  const fetchInvitedUsers = async (): Promise<void> => {
    if (!communityEvent.community) {
      return
    }
    setIsLoadingInvitedUser(true)
    try {
      const response = await api.communityEventAttendeeInvitations
        .configPath(communityEvent.community.id, communityEvent.id)
        .index<CommunityEventAttendeeInvitation[], BaseQueryParams>({})
      setInvitedUsers(
        response.map(
          (item) => ({ ...item.user, isInvited: true } as InvitationUser)
        )
      )
      setIsLoadingInvitedUser(false)
    } catch (error) {
      toast.showError(error)
      setIsLoadingInvitedUser(false)
    }
  }

  const updateInvitationUsers = (
    newUsers: User[],
    isFetchMore: boolean
  ): void => {
    const newInvitationUsers = newUsers.map(
      (item) =>
        ({
          ...item,
          isInvited: some(invitedUsers, { id: item.id }),
        } as InvitationUser)
    )
    isFetchMore
      ? setUsers((users) => [...users, ...newInvitationUsers])
      : setUsers(newInvitationUsers)
  }

  useEffect(() => {
    !isLoadingInvitedUser && fetchUsers(page)
  }, [keyword, isLoadingInvitedUser])

  useEffect(() => {
    fetchInvitedUsers()
  }, [])

  const inviteUser = async (
    user: InvitationUser,
    onInvited?: () => void
  ): Promise<void> => {
    if (!communityEvent.community) {
      return
    }
    const response = await api.communityEventAttendeeInvitations
      .configPath(communityEvent.community.id, communityEvent.id)
      .create<CreateInvitationParams, CommunityEventAttendeeInvitation>({
        userId: user.id,
      })
    setUsers((users) =>
      users.map((item) =>
        item.id === response.user.id
          ? ({ ...item, isInvited: true } as InvitationUser)
          : item
      )
    )
    onInvited && onInvited()
  }

  const renderItem: ListRenderItem<InvitationUser> = ({
    item,
  }: {
    item: InvitationUser
  }) => (
    <CommunityUserInvitationItem
      user={item}
      invitationDisabled={item.isJoined || item.isInvited}
      invite={(onInvited): Promise<void> => inviteUser(item, onInvited)}
    />
  )

  const keyExtractor = (item: InvitationUser): string => item.id.toString()

  return (
    <View testID="event-attendee-invitation" style={styles.container}>
      <Text style={styles.title}>{t('community.inviteMemberDialogTitle')}</Text>
      <Text style={styles.subTitle}>{t('community.channel.name')}</Text>
      <SearchInput
        value={searchValue}
        onChangeText={setSearchValue}
        onSubmit={(): void => setKeyword(searchValue)}
        placeholderKey={t('community.searchFriendPlaceholder')}
      />
      <Text style={styles.subTitle}>{t('community.selectFriends')}</Text>
      <InfiniteScrollContainer
        onScrollBottom={(): void => {
          fetchUsers(page + 1)
        }}
      >
        <FlatList
          data={users}
          renderItem={renderItem}
          style={styles.userList}
          keyExtractor={keyExtractor}
          testID="user-invitation-list"
        />
      </InfiniteScrollContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: color.white,
  },
  subTitle: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
    marginTop: 10,
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.IMPORTANT,
  },
  userList: {
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
})
export default EventAttendeeInvitation
