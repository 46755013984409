import { FontAwesome as Icon } from '@expo/vector-icons'
import { Picker } from '@react-native-picker/picker'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import DropdownButton from '~/components/common/atoms/DropdownButton'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import Button from '~/components/workarounds/Button'
import color, { ColorTheme } from '~/constants/common/color'
import { sizeStyle } from '~/constants/common/pickerSizes'

type PickerOption = {
  label: string
  value: ItemValue
}

type Props = {
  data: PickerOption[]
  colorTheme?: ColorTheme
  selectedValue?: ItemValue
  onValueChange: (value: ItemValue) => void
  size: 'small' | 'medium' | 'large' | 'full' | 'none'
}

const PickerIOS: React.FC<Props> = ({
  data,
  size,
  colorTheme,
  onValueChange,
  selectedValue,
}: Props) => {
  const [pickerPopupVisible, setPickerPopupVisible] = useState(false)
  const [value, setValue] = useState<ItemValue | undefined>(selectedValue)

  const changePickerValue = (value: ItemValue): void => {
    setValue(value)
    onValueChange(value)
  }

  useEffect(() => {
    selectedValue
      ? setValue(selectedValue)
      : !isEmpty(data) && setValue(data[0].value)
  }, [selectedValue])

  return (
    <View testID="picker-ios" style={sizeStyle[size]}>
      <DropdownButton
        colorTheme={colorTheme}
        onPress={(): void => setPickerPopupVisible(true)}
        title={data.find((item) => item.value === value)?.label}
      />
      {pickerPopupVisible && (
        <ModalContainer
          visible={pickerPopupVisible}
          onDismiss={(): void => setPickerPopupVisible(false)}
        >
          <View
            style={styles.pickerContainer}
            testID="picker-content-container"
          >
            <Button
              type="clear"
              style={styles.doneButton}
              testID="picker-ios-close-picker"
              onPress={(): void => setPickerPopupVisible(false)}
              icon={
                <Icon size={30} name="window-close" color={color.textGray} />
              }
            />
            <Picker
              selectedValue={value}
              testID="picker-ios-content"
              onValueChange={changePickerValue}
            >
              {data.map(({ label, value }) => (
                <Picker.Item key={value} label={label} value={value} />
              ))}
            </Picker>
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  doneButton: {
    alignSelf: 'flex-end',
  },
  pickerContainer: {
    alignSelf: 'flex-end',
    backgroundColor: color.white,
    flex: 1,
  },
})

export default PickerIOS
