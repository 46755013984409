import React, { useContext } from 'react'
import { ImageSourcePropType, StyleSheet, Image } from 'react-native'

import appIcon from '~/assets/icon.png'
import { RootContext } from '~/contexts/RootContext'
import Quiz from '~/interfaces/Quiz'
import { QuizCategoryType } from '~/interfaces/QuizCategory'

type Props = {
  quiz: Quiz
}

const quizImagesPC: { [key: string]: ImageSourcePropType } = {
  [QuizCategoryType.BUSSINESS_MANNER]: require('~/assets/images/quizzes/business_manner.png'),
  [QuizCategoryType.COMMON_SENSE]: require('~/assets/images/quizzes/common_sense.png'),
  [QuizCategoryType.CURRENT_PROBLEMS]: require('~/assets/images/quizzes/current_problems.png'),
  [QuizCategoryType.GEOGRAPHY]: require('~/assets/images/quizzes/geography.png'),
  [QuizCategoryType.JAPANESE]: require('~/assets/images/quizzes/japanese.png'),
  [QuizCategoryType.KANJI]: require('~/assets/images/quizzes/kanji.png'),
  [QuizCategoryType.KEIGO]: require('~/assets/images/quizzes/keigo.png'),
}
const quizImagesTablet: { [key: string]: ImageSourcePropType } = {
  [QuizCategoryType.BUSSINESS_MANNER]: require('~/assets/images/quizzes/tablet/business_manner.png'),
  [QuizCategoryType.COMMON_SENSE]: require('~/assets/images/quizzes/tablet/common_sense.png'),
  [QuizCategoryType.CURRENT_PROBLEMS]: require('~/assets/images/quizzes/tablet/current_problems.png'),
  [QuizCategoryType.GEOGRAPHY]: require('~/assets/images/quizzes/tablet/geography.png'),
  [QuizCategoryType.JAPANESE]: require('~/assets/images/quizzes/tablet/japanese.png'),
  [QuizCategoryType.KANJI]: require('~/assets/images/quizzes/tablet/kanji.png'),
  [QuizCategoryType.KEIGO]: require('~/assets/images/quizzes/tablet/keigo.png'),
}

const quizImagesMobile: { [key: string]: ImageSourcePropType } = {
  [QuizCategoryType.BUSSINESS_MANNER]: require('~/assets/images/quizzes/mobile/business_manner.png'),
  [QuizCategoryType.COMMON_SENSE]: require('~/assets/images/quizzes/mobile/common_sense.png'),
  [QuizCategoryType.CURRENT_PROBLEMS]: require('~/assets/images/quizzes/mobile/current_problems.png'),
  [QuizCategoryType.GEOGRAPHY]: require('~/assets/images/quizzes/mobile/geography.png'),
  [QuizCategoryType.JAPANESE]: require('~/assets/images/quizzes/mobile/japanese.png'),
  [QuizCategoryType.KANJI]: require('~/assets/images/quizzes/mobile/kanji.png'),
  [QuizCategoryType.KEIGO]: require('~/assets/images/quizzes/mobile/keigo.png'),
}

const quizImageDeviceType = {
  mobile: quizImagesMobile,
  pc: quizImagesPC,
  tablet: quizImagesTablet,
}

const QuizImage: React.FC<Props> = ({ quiz }: Props) => {
  const { deviceType } = useContext(RootContext)

  const getQuizImage = (): ImageSourcePropType => {
    if (quiz.image) {
      return { uri: quiz.image.urls.original }
    }

    return deviceType ? quizImageDeviceType[deviceType][quiz.category] : appIcon
  }

  return (
    <Image
      source={getQuizImage()}
      resizeMode="cover"
      testID="quiz-image"
      style={styles.image}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    minHeight: 134,
    width: '100%',
  },
})

export default QuizImage
