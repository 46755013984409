import { MediaTypeOptions } from 'expo-image-picker'
import { isEmpty, omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import { AnswerFormData } from '~/api/answers'
import imageIcon from '~/assets/images/icons/picture.png'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import LoginColumn from '~/components/common/features/auth/LoginColumn'
import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import MediaPreview from '~/components/common/molecules/media/MediaPreview'
import Button from '~/components/workarounds/Button'
import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Answer from '~/interfaces/Answer'
import Media from '~/interfaces/Media'
import { RootState } from '~/rootReducer'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  questionId: number
  currentAnswer?: Answer
  onCreate?: (answer: Answer) => void
  onUpdate?: (answer: Answer) => void
}

const AnswerForm: React.FC<Props> = ({
  onCreate,
  onUpdate,
  currentAnswer,
  questionId,
}: Props) => {
  const maxLengthOfContent = 1000
  const { t } = useTranslation()
  const toast = useCustomToast()
  const [isShowLogin, setIsShowLogin] = useState(false)
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const [isValidData, setIsValidData] = useState(false)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const [answer, setAnswer] = useState<AnswerFormData>(
    currentAnswer
      ? { ...currentAnswer, media: currentAnswer?.images }
      : ({ body: '' } as AnswerFormData)
  )

  const updateAnswer = (data: Partial<AnswerFormData>): void => {
    setAnswer({ ...answer, ...data })
  }

  const updateMedia = (media: Media[]): void => {
    updateAnswer({ media })
  }

  const submitAnswer = async (): Promise<void> => {
    if (accessToken) {
      currentAnswer ? update() : create()
    } else {
      setIsShowLogin(true)
    }
  }

  const create = async (): Promise<void> => {
    try {
      const newAnswer = await api.answers.create(questionId, answer)
      currentUser && onCreate && onCreate({ ...newAnswer, user: currentUser })
      setAnswer({} as Answer)
    } catch (error) {
      toast.showError(error)
    }
  }

  const update = async (): Promise<void> => {
    try {
      const updatedAnswer = await api.answers.update(questionId, answer)
      onUpdate && onUpdate(omit(updatedAnswer, ['user']) as Answer)
      setAnswer({} as Answer)
    } catch (error) {
      toast.showError(error)
    }
  }

  const removeMedia = (media?: Media): void => {
    const previousMedia = answer?.media ?? []
    !isEmpty(previousMedia) &&
      updateAnswer({
        media: previousMedia.filter((item: Media) => item != media),
      })
  }

  useEffect(() => {
    !isEmpty(answer.body) &&
    answer.body &&
    answer.body.length <= maxLengthOfContent
      ? setIsValidData(true)
      : setIsValidData(false)
  }, [answer.body, answer.media])

  return (
    <ScrollView testID="answer-form" style={styles.container}>
      <View>
        <Input
          multiline
          numberOfLines={5}
          value={answer.body || ''}
          testID="answer-input"
          maxLength={maxLengthOfContent}
          placeholderTextColor={color.placeholderText}
          placeholder={t('forum.answerPlaceholder')}
          style={StyleSheet.flatten([styles.input])}
          onChangeText={(body: string): void => updateAnswer({ body })}
          inputContainerStyle={StyleSheet.flatten([styles.inputContainer])}
        />
        <Text style={styles.counter}>{`${
          answer.body?.length || 0
        }/${maxLengthOfContent}`}</Text>
      </View>
      <View style={styles.answerActionRow} testID="answer-action-row">
        <ImagePickerButton
          type="clear"
          kind="answer"
          onUpload={updateMedia}
          loading={isLoadingMedia}
          mediaTypes={MediaTypeOptions.Images}
          title={t('image')}
          onLoading={setIsLoadingMedia}
          allowsMultipleSelection={true}
          titleStyle={styles.imagePickerButton}
          icon={<Image style={styles.icon} source={imageIcon} />}
        />
        <Button
          onPress={submitAnswer}
          testID="answer-form-submit"
          title={t('forum.submitAnswer')}
          disabled={!isValidData}
          buttonStyle={StyleSheet.flatten([styles.submitButton])}
          titleStyle={StyleSheet.flatten([styles.submitButtonTitle])}
          containerStyle={StyleSheet.flatten([styles.submitButtonContainer])}
        />
      </View>
      {!isEmpty(answer?.media) && (
        <View style={styles.media} testID="media-preview-container">
          <MediaPreview
            retry={true}
            media={answer.media!}
            onRemove={removeMedia}
          />
        </View>
      )}
      {isShowLogin && !accessToken && (
        <ModalContainer
          visible={isShowLogin}
          onDismiss={(): void => setIsShowLogin(false)}
        >
          <ScrollView style={styles.loginContainer} testID="login-container">
            <LoginColumn />
          </ScrollView>
        </ModalContainer>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  answerActionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  counter: {
    bottom: 14,
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    position: 'absolute',
    right: 20,
  },
  icon: {
    height: 15,
    width: 15,
  },
  imagePickerButton: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
    marginLeft: 4,
  },
  input: {
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    fontSize: FontSize.GENERAL,
    padding: 10,
  },
  inputContainer: {
    borderColor: color.transparent,
    padding: 0,
  },
  loginContainer: {
    backgroundColor: color.white,
    maxHeight: 580,
    maxWidth: 370,
  },
  media: {
    marginTop: 8,
    maxHeight: 100,
    maxWidth: 100,
    ...Platform.select({
      web: {
        maxWidth: 150,
        maxHeight: 150,
      },
    }),
  },
  submitButton: {
    backgroundColor: color.primary,
    height: 33,
    paddingHorizontal: 14,
  },
  submitButtonContainer: {
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  submitButtonTitle: {
    color: color.white,
    fontSize: FontSize.GENERAL,
  },
})

export default AnswerForm
