import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

export type GenderItemData = {
  label: string
  value: string | number
}

type Props = {
  data: GenderItemData
}

const GenderItem: React.FC<Props> = ({ data }: Props) => (
  <View testID="gender-item" style={styles.container}>
    <Text style={styles.value}>{data.value}</Text>
    <Text style={styles.label}>{data.label}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: FontSize.SUB,
    color: color.textGray,
    textAlign: 'center',
  },
  value: {
    fontSize: 50,
    color: color.textGray,
    textAlign: 'center',
  },
})

export default GenderItem
