import React from 'react'
import { StyleSheet, View } from 'react-native'

import AutoLink from '~/components/atoms/common/AutoLink'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Link from '~/interfaces/Link'
import { openURL } from '~/utils/navigation'

type Props = {
  message: string
  links?: Link[]
  isComment: boolean
}

const injectLinks = (message: string, links: Link[]): string => {
  let result = ''
  let current = 0
  links.forEach((link) => {
    result += message.slice(current, link.offset)
    result += `@${link.type}[${link.displayName}](${link.id})`
    current = link.offset
  })
  result += message.slice(current)
  return result
}

const PostMessage: React.FC<Props> = ({ message, links, isComment }: Props) => (
  <View
    style={isComment ? undefined : styles.messageContainer}
    testID="post-message"
  >
    <AutoLink
      text={links ? injectLinks(message, links) : message}
      onPress={openURL}
      style={[
        styles.message,
        { fontSize: FontSize.GENERAL, lineHeight: FontSize.GENERAL * 1.33 },
      ]}
    />
  </View>
)

const styles = StyleSheet.create({
  message: {
    color: color.bodyText,
  },
  messageContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
})

export default PostMessage
