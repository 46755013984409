import React from 'react'
import { StyleSheet, View } from 'react-native'

import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import SkeletonAvatar from '~/components/common/atoms/SkeletonAvatar'
import SkeletonView from '~/components/common/atoms/SkeletonView'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'

type Props = {
  isPC: boolean
}

const SkeletonProfileHeader: React.FC<Props> = ({ isPC }: Props) => (
  <View testID="skeleton-profile-header" style={styles.container}>
    <ProfileHeader
      isPC={isPC}
      bannerComponent={<SkeletonView style={styles.banner} />}
      infoComponent={
        <ProfileInfo
          isPC={isPC}
          avatarComponent={
            <SkeletonAvatar
              size={isPC ? ProfileAvatarSize.GIGANTIC : ProfileAvatarSize.HUGE}
            />
          }
          rightComponent={
            <View style={styles.rightComponent}>
              <SkeletonTwoBar />
            </View>
          }
        />
      }
      footerLeftComponent={
        <HorizontalContainer>
          <SkeletonView style={[styles.separator, styles.footerItem]} />
          <SkeletonView style={[styles.separator, styles.footerItem]} />
          <SkeletonView style={[styles.separator, styles.footerItem]} />
          <SkeletonView style={[styles.separator, styles.footerItem]} />
        </HorizontalContainer>
      }
    />
  </View>
)

const styles = StyleSheet.create({
  banner: {
    height: 65,
  },
  container: {
    marginTop: 20,
  },
  footerItem: {
    marginBottom: 10,
    width: 50,
  },
  rightComponent: {
    width: 130,
  },
  separator: {
    marginRight: 5,
  },
})

export default SkeletonProfileHeader
