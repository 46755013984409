import { useFocusEffect, useScrollToTop } from '@react-navigation/native'
import { find, isEmpty } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { ListRenderItem, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import CommentList from '~/components/career/features/posts/CommentList'
import PostCommentCount from '~/components/career/features/posts/PostCommentCount'
import PostForm from '~/components/career/features/posts/PostForm'
import PostItemControlMenu from '~/components/career/features/posts/PostItemControlMenu'
import PostLikeButton from '~/components/career/features/posts/PostLikeButton'
import PostLikeCount from '~/components/career/features/posts/PostLikeCount'
import PostSharing from '~/components/career/features/posts/PostSharing'
import PostItem from '~/components/career/organisms/posts/PostItem'
import PostMediaViewerDialog from '~/components/career/organisms/posts/PostMediaViewerDialog'
import PostSharedContent from '~/components/career/organisms/posts/PostSharedContent'
import CardContainer from '~/components/common/atoms/CardContainer'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import InfiniteScrollFlatList from '~/components/common/features/InfiniteScrollFlatList'
import SkeletonTimelineItem from '~/components/common/molecules/SkeletonTimelineItem'
import SharedCount from '~/components/common/molecules/socials/SharedCount'
import SocialActivity from '~/components/common/molecules/socials/SocialActivity'
import color from '~/constants/common/color'
import { MediaViewerContextProvider } from '~/contexts/MediaViewerContext'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityChannel from '~/interfaces/CommunityChannel'
import Post from '~/interfaces/Post'
import Share from '~/interfaces/Share'
import { RootState } from '~/rootReducer'

const keyExtractor = (item: Post, idx: number): string => `${item.id}-${idx}`

type Props = {
  userId?: number
  hideForm?: boolean
  communityId?: number
  communityEventId?: number
  listEmptyComponent?: React.ReactElement
  dailyQuiz?: React.ReactElement
  channel?: CommunityChannel
  renderListHeaderComponent?: () => React.ReactElement
}

const PostList: React.FC<Props> = ({
  userId,
  channel,
  hideForm,
  communityId,
  communityEventId,
  listEmptyComponent,
  dailyQuiz,
  renderListHeaderComponent,
}: Props) => {
  const toast = useCustomToast()
  const channelId = channel?.id
  const shouldHideShareButton = channel && !channel?.isPublic
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const [posts, setPosts] = useState([] as Post[])
  const { isPC } = useDevice()
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector((state: RootState) => state.users.current)
  const [isEditingPost, setIsEditingPost] = useState(false)
  const [currentPost, setCurrentPost] = useState<Post | undefined>(undefined)
  const shouldShowSkeleton = loading && isEmpty(posts)
  const isCompanyAccount = currentUser?.isCompanyAccount

  const flatListRef = useRef(null)
  useScrollToTop(flatListRef)

  const fetchPosts = async (perPage = 25): Promise<void> => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      const donePage = Math.floor(posts.length / perPage)
      const response = await (communityId && channelId
        ? api.channelPosts.build(communityId, channelId)
        : communityId && communityEventId
        ? api.communityEventPosts.build(communityId, communityEventId)
        : api.posts
      ).fetch({
        userId,
        page: donePage + 1,
        perPage,
      })
      if (!isEmpty(response)) {
        const newPosts = response.filter(
          (item) => !find(posts, { id: item.id })
        )
        !isEmpty(newPosts) &&
          setPosts([...posts.slice(0, donePage * perPage), ...newPosts])
      }
    } catch (error) {
      console.log(error)
      toast.showError(error)
    }
    setLoading(false)
  }

  const addPost = useCallback(
    (post: Post) => {
      setPosts((posts: Post[]) => [post, ...posts])
    },
    [setPosts]
  )

  const deletePost = useCallback(
    (post: Post) => {
      setPosts((posts: Post[]) => posts.filter((item) => item.id != post.id))
    },
    [setPosts]
  )

  const updatePost = useCallback(
    (post: Post) => {
      setPosts((posts: Post[]) =>
        posts.map((item) => (item.id === post.id ? { ...item, ...post } : item))
      )
      setIsEditingPost(false)
      setCurrentPost(undefined)
    },
    [setCurrentPost, setIsEditingPost, setPosts]
  )

  const startEditPost = useCallback(
    (post: Post) => {
      setCurrentPost(post)
      setIsEditingPost(true)
    },
    [setCurrentPost, setIsEditingPost]
  )

  useFocusEffect(
    React.useCallback(() => {
      isBottom && fetchPosts()
    }, [isBottom])
  )

  useFocusEffect(
    React.useCallback(() => {
      setPosts([])
      fetchPosts()
    }, [channelId, communityId, userId])
  )

  const addSharedPost = (share: Share): void => {
    addPost({
      ...share.toPost,
      user: currentUser,
      share: { ...share, type: 'post' },
    })
    updatePost(share.post!)
  }

  const renderPostItemControlMenu = useCallback(
    (item: Post, cb?: Function) => (
      <PostItemControlMenu
        post={item}
        user={currentUser}
        onDeletePost={(post: Post) => {
          cb?.()
          deletePost(post)
        }}
        onSelectEdit={(): void => {
          cb?.()
          startEditPost(item)
        }}
      />
    ),
    [currentUser, deletePost, startEditPost]
  )

  const renderSocialActivity = useCallback(
    (item: Post) => (
      <View style={styles.socialActivity}>
        <SocialActivity
          isHideBottomLine={isEmpty(item.comments)}
          likeButton={<PostLikeButton post={item} showIcon={true} />}
          likeCountComponent={<PostLikeCount post={item} />}
          commentCountComponent={<PostCommentCount post={item} />}
          shareButton={
            shouldHideShareButton ? undefined : (
              <PostSharing
                post={item}
                onShared={(share: Share) => {
                  addSharedPost(share)
                }}
              />
            )
          }
          sharedCountComponent={
            item.sharedCount > 0 ? (
              <SharedCount value={item.sharedCount} />
            ) : undefined
          }
        />
      </View>
    ),
    [addSharedPost]
  )

  const renderPostCommentList = useCallback(
    (item: Post) => (
      <CommentList post={item} isReadOnly={hideForm} level={item.level} />
    ),
    [hideForm]
  )

  const renderItem: ListRenderItem<Post> = useCallback(
    ({ item }: { item: Post }) => {
      return (
        <CardContainer style={styles.postItemContainer}>
          {item?.share ? (
            <PostSharedContent
              post={item}
              controlMenu={renderPostItemControlMenu(item)}
            />
          ) : (
            <PostItem
              post={item}
              isPC={isPC}
              controlMenu={renderPostItemControlMenu(item)}
            />
          )}
          {renderSocialActivity(item)}
          {renderPostCommentList(item)}
        </CardContainer>
      )
    },
    [isPC]
  )

  const headerComponent = hideForm ? null : (
    <>
      {renderListHeaderComponent?.()}
      <PostForm
        onCreate={addPost}
        communityId={communityId}
        communityChannelId={channelId}
        communityEventId={communityEventId}
      />
      {!isCompanyAccount && dailyQuiz}
    </>
  )

  return (
    <View testID="post-list" style={styles.container}>
      {shouldShowSkeleton ? (
        <>
          <SkeletonTimelineItem />
          <SkeletonTimelineItem />
        </>
      ) : (
        <MediaViewerContextProvider>
          <InfiniteScrollFlatList
            data={posts}
            scrollEnabled={!isPC}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ListHeaderComponent={headerComponent}
            style={isPC ? styles.postList : styles.postListMobile}
            onReachBottom={!isPC ? fetchPosts : undefined}
            ListEmptyComponent={loading ? undefined : listEmptyComponent}
            innerRef={flatListRef}
          />
          <PostMediaViewerDialog
            isPC={isPC}
            renderPostItemControlMenu={renderPostItemControlMenu}
            renderSocialActivity={renderSocialActivity}
            renderPostCommentList={renderPostCommentList}
          />
        </MediaViewerContextProvider>
      )}
      {isEditingPost && (
        <ModalContainer
          visible={isEditingPost}
          onDismiss={(): void => setIsEditingPost(false)}
        >
          <View style={isPC ? styles.postForm : styles.postFormMobile}>
            <PostForm
              isEdit={true}
              post={currentPost}
              onUpdate={updatePost}
              communityId={communityId}
              communityChannelId={channelId}
            />
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  postForm: {
    width: 590,
    height: 400,
  },
  postFormMobile: {
    minWidth: 375,
    height: 400,
  },
  postItemContainer: {
    marginBottom: 20,
    overflow: 'hidden',
  },
  postList: {
    height: '100%',
  },
  postListMobile: {
    flex: 1,
  },
  socialActivity: {
    backgroundColor: color.white,
    paddingHorizontal: 15,
  },
})

export default PostList
