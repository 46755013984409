import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'

import shareIcon from '~/assets/images/icons/share.png'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'

const ShareButton: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} testID="share-button">
      <Image testID="like-icon" style={styles.icon} source={shareIcon} />
      <Text
        testID="share-button-title"
        style={[styles.share, { fontSize: FontSize.GENERAL }]}
      >
        {t('post.share')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  icon: {
    height: 18,
    width: 18,
  },
  share: {
    ...commonStyles.subTextSize,
    alignSelf: 'center',
    color: color.textGray,
    marginLeft: 6,
  },
})

export default ShareButton
