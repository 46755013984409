import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import ButtonGroup from '~/components/workarounds/ButtonGroup'
import color from '~/constants/common/color'
import { SupportedLanguage } from '~/plugins/i18n'
import { changeLanguage } from '~/plugins/resume_language'

type Props = {
  selectedLanguage?: string
  onSelect(language: string): void
}

export const getLanguageObjects = (t: TFunction) =>
  Object.values(SupportedLanguage).map((language) => ({
    label: t(`languageKey.${language}`),
    key: language,
  }))

export const getLanguageComponents = (t: TFunction) => {
  return getLanguageObjects(t).map((lang) => lang.label)
}

const ResumeLanguageSelection: React.FC<Props> = ({
  onSelect,
  selectedLanguage,
}: Props) => {
  const defaultSelectedIndex = -1
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex)

  const getSelectedIndexByLang = () => {
    return getLanguageObjects(t).findIndex(
      (lang) => lang.key == selectedLanguage
    )
  }

  const selectLanguage = (index: number) => {
    onSelect(getLanguageObjects(t)[index].key)
  }

  useEffect(() => {
    if (selectedLanguage) {
      setSelectedIndex(getSelectedIndexByLang())
      changeLanguage(selectedLanguage)
    }
  }, [selectedLanguage])

  return (
    <View style={styles.container} testID="resume-language-selection">
      <TitleText style={styles.title}>
        {t('resume.resumeLanguageTitle')}
      </TitleText>
      <ButtonGroup
        onPress={selectLanguage}
        selectedIndex={selectedIndex}
        buttons={getLanguageComponents(t)}
        containerStyle={styles.languageGroup}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  languageGroup: {
    marginHorizontal: 0,
  },
  title: {
    marginBottom: 4,
    color: color.textGray,
  },
})

export default ResumeLanguageSelection
