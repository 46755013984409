import React from 'react'
import { StyleSheet, View, Image } from 'react-native'

import emptyImage from '~/assets/images/prompts/notfound.png'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  title?: string
  subtitle?: string
}

const SearchNotFound: React.FC<Props> = ({ title, subtitle }: Props) => (
  <View style={styles.container} testID="search-not-found">
    <Image source={emptyImage} style={styles.image} resizeMode="center" />
    {title && <Text style={styles.title}>{title}</Text>}
    {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 8,
    paddingTop: 30,
  },
  image: {
    height: 150,
    width: 288,
  },
  subtitle: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    textAlign: 'center',
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})

export default SearchNotFound
