import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import {
  CommunityQueryParams,
  CommunityType,
} from '~/api/communities/communities'
import TitleText from '~/components/common/atoms/TitleText'
import TopTabNavigator, {
  TopTabNavigatorData,
} from '~/components/common/organisms/TopTabNavigator'
import CommunityList from '~/components/community/features/CommunityList'
import NoCommunityJoinedYet from '~/components/community/molecules/NoCommunitiesJoinedYet'
import HeaderCommunity from '~/components/community/organisms/HeaderCommunity'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams } from '~/utils/navigation'

type Props = {
  isPC: boolean
}

const ManagerCommunityMobile: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const queryParams = getRouteParams<CommunityQueryParams>()

  const topTabNavigatorData: TopTabNavigatorData[] = [
    { label: t('community.mobile-community'), key: 'all' },
    { label: t('community.pages.joined'), key: 'joined' },
    { label: t('community.pages.manage'), key: 'manage' },
  ]

  const [currentCommunityType, setCurrentCommunityType] = useState<
    TopTabNavigatorData | undefined
  >(topTabNavigatorData[0])

  const changeCommunityType = (tab: TopTabNavigatorData): void => {
    setCurrentCommunityType(tab)
    setQueryParams({ type: tab.key as CommunityType })
  }

  useEffect(() => {
    const tabKey = queryParams?.type ? queryParams.type : 'all'
    const selectedTab = topTabNavigatorData.find((item) => item.key === tabKey)
    setCurrentCommunityType(selectedTab)
  }, [queryParams.type])

  return (
    <View testID="manager-community">
      <View testID="manager-community-active">
        <CommunityList
          header={
            <>
              <HeaderCommunity />
              <TopTabNavigator
                isPC={isPC}
                data={topTabNavigatorData}
                onSelectTab={changeCommunityType}
                selectedItem={currentCommunityType}
              />
            </>
          }
          params={{ ...queryParams, type: 'manage', status: 'approved' }}
          emptyView={
            isPC ? (
              <View style={styles.emptyContainer}>
                <NoCommunityJoinedYet
                  title={t('community.noCommunitiesJoinedYet')}
                />
              </View>
            ) : (
              <></>
            )
          }
        />
      </View>
      <View testID="manager-community-pending">
        <CommunityList
          params={{ ...queryParams, type: 'manage', status: 'pending' }}
          header={
            <TitleText style={styles.header}>
              {t('community.pendingCommunities')}
            </TitleText>
          }
          emptyView={
            <View style={styles.emptyContainer}>
              <NoCommunityJoinedYet
                title={t('community.noCommunitiesJoinedYet')}
              />
            </View>
          }
        />
      </View>
      <View testID="manager-community-reject">
        <CommunityList
          params={{ ...queryParams, type: 'manage', status: 'reject' }}
          header={
            <TitleText style={styles.header}>
              {t('community.rejectedCommunities')}
            </TitleText>
          }
          emptyView={
            <View style={styles.emptyContainer}>
              <NoCommunityJoinedYet
                title={t('community.noCommunitiesJoinedYet')}
              />
            </View>
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyContainer: {
    width: 940,
    alignSelf: 'center',
    paddingHorizontal: 8,
    paddingTop: 10,
  },
  header: {
    marginTop: 20,
    marginLeft: 20,
  },
})

export default ManagerCommunityMobile
