import { Ionicons as Icon } from '@expo/vector-icons'
import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'

type Props = {
  onPress?: () => void
}

const CommentSendButton: React.FC<Props> = ({ onPress }: Props) => {
  const { isPC } = useContext(RootContext)

  return (
    <Button
      style={isPC ? styles.sendButton : styles.sendButtonMobile}
      type="clear"
      testID="comment-send-button"
      onPress={onPress}
      icon={<Icon name="md-send" size={isPC ? 25 : 22} color={color.white} />}
    />
  )
}

const styles = StyleSheet.create({
  sendButton: {
    marginLeft: 10,
  },
  sendButtonMobile: {
    marginLeft: 6,
  },
})

export default CommentSendButton
