import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import ForumTemplate from '~/components/forum/templates/ForumTemplate'
import { setPageTitle } from '~/utils/navigation'

const Forum: React.FC = () => {
  const { t } = useTranslation()
  useFocusEffect(() => setPageTitle(t('forum.questions')))

  return (
    <DefaultLayout>
      <ForumTemplate />
    </DefaultLayout>
  )
}

export default Forum
