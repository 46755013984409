import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '~/components/DefaultLayout'
import LoginTemplate from '~/components/common/templates/auth/LoginTemplate'
import { setPageTitle } from '~/utils/navigation'

const Login: React.FC = () => {
  const { t } = useTranslation()

  useFocusEffect(() => setPageTitle(t('auth.login')))

  return (
    <DefaultLayout>
      <LoginTemplate />
    </DefaultLayout>
  )
}

export default Login
