import React from 'react'
import { StyleSheet, TouchableOpacity, Image } from 'react-native'

import thumbCircleIcon from '~/assets/images/icons/thumb-circle.png'
import thumbSolidIcon from '~/assets/images/icons/thumb-solid.png'
import thumbIcon from '~/assets/images/icons/thumb.png'

type Props = {
  liked?: boolean
  isRound?: boolean
  onPress?: () => void
}

const LikeIcon: React.FC<Props> = ({ liked, onPress, isRound }: Props) => {
  const icon = isRound ? thumbCircleIcon : liked ? thumbSolidIcon : thumbIcon
  const iconStyles = isRound ? styles.iconRound : styles.icon

  return onPress ? (
    <TouchableOpacity onPress={onPress} testID="like-icon">
      <Image style={iconStyles} source={icon} />
    </TouchableOpacity>
  ) : (
    <Image testID="like-icon" style={iconStyles} source={icon} />
  )
}

const styles = StyleSheet.create({
  icon: {
    height: 18,
    width: 21,
  },
  iconRound: {
    height: 16,
    width: 16,
  },
})

export default LikeIcon
