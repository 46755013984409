import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'

export type UserQuickReplyRequestBody = {
  userId?: number
  content: string
}

export type UserQuickReplyQueryParams = {
  userId?: number
  sortBy?: string
} & BaseQueryParams

export class UserQuickRepliesAPI extends BaseAPI {
  path = '/user_quick_replies'

  configPath(userId: number | undefined): UserQuickRepliesAPI {
    this.path = `/users/${userId}/user_quick_replies`
    return this
  }
}
