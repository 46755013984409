import { FontAwesome as Icon } from '@expo/vector-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

import CommunityFormDialog from '~/components/community/organisms/CommunityFormDialog'
import CommunityResultDialog from '~/components/community/organisms/CommunityResultDialog'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
}

const CreateCommunity: React.FC<Props> = ({ isPC }: Props) => {
  const { t } = useTranslation()
  const [isFormDialogVisible, setIsFormDialogVisible] = useState<boolean>(false)
  const [shouldShowResultDialog, setShouldShowResultDialog] = useState<boolean>(
    false
  )

  const showCommunityForm = () => {
    setIsFormDialogVisible(true)
  }

  return (
    <View
      testID="create-community"
      style={isPC ? styles.containerView : undefined}
    >
      <TouchableOpacity
        testID="create-community-item"
        onPress={showCommunityForm}
      >
        <View
          testID="create-community-item-content"
          style={StyleSheet.flatten([
            styles.container,
            isPC ? styles.containerSizePC : styles.containerSizeMobile,
          ])}
        >
          <Icon
            size={24}
            name="plus"
            testID="plus-icon"
            color={color.textGray}
          />
          <Text style={styles.subtitle}>
            {t('community.requestForTheCreation')}
          </Text>
        </View>
      </TouchableOpacity>
      <CommunityFormDialog
        isVisible={isFormDialogVisible}
        onSubmit={() => setShouldShowResultDialog(true)}
        dismissDialog={() => setIsFormDialogVisible(false)}
      />
      <CommunityResultDialog
        isVisible={shouldShowResultDialog}
        onPressDismiss={() => setShouldShowResultDialog(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    borderRadius: 3,
    borderStyle: 'dashed',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: color.textGray,
    marginHorizontal: 5,
    padding: 10,
  },
  containerSizeMobile: {
    height: 242,
    marginTop: 15,
    width: '90%',
  },
  containerSizePC: {
    height: 242,
    marginTop: 10,
    width: 303,
  },
  containerView: {
    alignSelf: 'center',
    alignItems: 'flex-start',
    paddingTop: 10,
    width: 940,
  },
  subtitle: {
    fontSize: FontSize.SUB,
    borderColor: color.textGray,
    color: color.textGray,
    paddingTop: 10,
  },
})

export default CreateCommunity
