import React from 'react'

import SignupColumn from '~/components/common/features/auth/SignupColumn'
import AuthContainerTemplate from '~/components/common/templates/auth/AuthContainerTemplate'
import CompanyMemberInvitation from '~/interfaces/CompanyMemberInvitation'

type Props = {
  isCompany?: boolean
  invitation?: CompanyMemberInvitation
}

const SignupTemplate: React.FC<Props> = ({ isCompany, invitation }) => (
  <AuthContainerTemplate isCompany={isCompany}>
    <SignupColumn isCompany={isCompany} invitation={invitation} />
  </AuthContainerTemplate>
)

export default SignupTemplate
