import React from 'react'

import QuizzesHeaderMobile from '~/components/career/features/quizzes/QuizzesHeader/QuizzesHeaderMobile'
import QuizzesHeaderPC from '~/components/career/features/quizzes/QuizzesHeader/QuizzesHeaderPC'
import useDevice from '~/hooks/commons/useDevice'

const QuizzesHeader: React.FC = () =>
  useDevice().isPC ? <QuizzesHeaderPC /> : <QuizzesHeaderMobile />

export default QuizzesHeader
