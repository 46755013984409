import { isNil } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GestureResponderEvent, StyleSheet, View } from 'react-native'

import api from '~/api'
import { EntryBodyParams } from '~/api/entry'
import DocumentItem from '~/components/career/organisms/documents/DocumentItem'
import ResumeItem from '~/components/career/organisms/resumes/ResumeItem'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { Fonts, FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Entry from '~/interfaces/Entry'
import Recruitment from '~/interfaces/Recruitment'
import Resume from '~/interfaces/Resume'
import UserDocument from '~/interfaces/UserDocument'

type Props = {
  isPC: boolean
  job: Recruitment
  resume?: Resume
  userDocument?: UserDocument
  onApply?: (job: Recruitment) => void
  onPressBack?: (event: GestureResponderEvent) => void
}

const JobApplyConfirmation: React.FC<Props> = ({
  isPC,
  job,
  resume,
  onApply,
  onPressBack,
  userDocument,
}: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()

  const createEntry = async (): Promise<void> => {
    if (isNil(resume) && isNil(userDocument)) {
      return
    }
    try {
      const entry = await api.entries.create<Partial<EntryBodyParams>, Entry>({
        recruitmentId: job.id,
        userResumeId: resume?.id,
        userDocumentId: userDocument?.id,
      })
      onApply?.(entry.recruit!)
    } catch (error) {
      toast.showError(error)
    }
  }

  const targetResumeView = (
    <>
      {resume && (
        <ResumeItem
          isPC={isPC}
          resume={resume}
          pressMode="whole-content"
          onPress={onPressBack}
        />
      )}
      {userDocument && (
        <DocumentItem
          isPC={isPC}
          userDocument={userDocument}
          onPress={onPressBack}
          pressMode="whole-content"
        />
      )}
    </>
  )

  return (
    <View testID="job-apply-confirmation">
      <Text style={styles.title}>{t('jobs.applyConfirmation')}</Text>
      <View style={styles.confirmBody}>
        <Text style={styles.headingConfirm}>{t('jobs.selectedResume')}</Text>
        <View>{targetResumeView}</View>
      </View>
      <View style={styles.footer}>
        <Button
          title={t('back')}
          testID="back-button"
          onPress={onPressBack}
          buttonStyle={styles.backBtn}
          containerStyle={styles.actionBtn}
          titleStyle={commonStyles.buttonTextSize}
        />
        <Button
          title={t('confirm')}
          onPress={createEntry}
          testID="confirm-button"
          containerStyle={styles.actionBtn}
          titleStyle={commonStyles.buttonTextSize}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  actionBtn: {
    marginLeft: 15,
    width: 92,
  },
  backBtn: {
    backgroundColor: color.lightMask,
  },
  confirmBody: {
    marginTop: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
  headingConfirm: {
    fontFamily: Fonts.NormalFont,
    fontSize: FontSize.GENERAL,
    textAlign: 'center',
  },
  title: {
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.GENERAL,
  },
})

export default JobApplyConfirmation
