import camelcaseKeys from 'camelcase-keys'

import { HTTPError } from '~/httpClient'

// API response must be the same format
// but currently, it's not, it returns error or errors
// It looks ugly for now, but it can accept either response
export class APIError extends Error {
  name = 'APIError'
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors?: Errors
  status: string
  error: APIErrorType

  constructor(body: APIErrorBody) {
    super(body.error?.title)
    this.data = body.data
    this.errors = body.errors
    this.status = body.status
    this.error = body.error
  }

  static async wrap<T>(error: Error): Promise<T> {
    if (error instanceof HTTPError) {
      throw (await APIError.build(error.response)) || error
    } else {
      throw error
    }
  }

  static async build(response: Response): Promise<APIError | null> {
    let parsedBody: APIErrorBody

    try {
      // may error if there is no body
      const json = await response.json()
      parsedBody = camelcaseKeys(json, { deep: true })
    } catch (ex) {
      return null
    }

    return new APIError(parsedBody)
  }
}

type APIErrorType = {
  status: number
  title: string
  detail: string
  link?: string
}

type Errors = {
  fullMessages: string[]
}

type APIErrorBody = {
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors?: Errors
  status: string
  error: APIErrorType
}
