import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex, find, isEmpty, includes, orderBy } from 'lodash'

import api from '~/api'
import { AddChannelMemberParams } from '~/api/communities/communityChanelMembers'
import { CommunityChannelRequest } from '~/api/communities/communityChannels'
import { MemberInvitationParams } from '~/api/communities/communityMemberInvitations'
import { CreateMemberParam } from '~/api/communities/communityMembers'
import Community, { ChannelViewMode } from '~/interfaces/Community'
import CommunityChannel from '~/interfaces/CommunityChannel'
import CommunityUser, { InvitationUser } from '~/interfaces/CommunityUser'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

type CommunityState = {
  isLoading: boolean
  current?: Community
  error: string | null
  channel: ChannelState
  setting: SettingState
  isSuccess?: boolean
  isCommunityMember?: boolean
}

export enum AdminDashboardType {
  MemberManagement = 'member-management',
  MemberRequests = 'member-requests',
  CommunitySettings = 'community-setting',
}

type SettingState = {
  adminDashboardType: AdminDashboardType
}

type ChannelState = {
  current?: CommunityChannel
  channelMode: ChannelViewMode
}

const defaultState: CommunityState = {
  error: null,
  isLoading: false,
  isSuccess: false,
  isCommunityMember: false,
  channel: {
    channelMode: ChannelViewMode.Timeline,
  },
  setting: { adminDashboardType: AdminDashboardType.MemberManagement },
}

const communitySlice = createSlice({
  name: 'community',
  initialState: defaultState,
  reducers: {
    setCurrentCommunity(state, action: PayloadAction<Community>): void {
      state.current = { ...action.payload }
    },
    updateCurrentCommunity(state, action: PayloadAction<Community>): void {
      state.current = { ...(state.current || {}), ...action.payload }
    },
    addChannel(state, action: PayloadAction<CommunityChannel>): void {
      if (state.current) {
        state.current.channels = action.payload.isPublic
          ? [
              ...state.current.channels.filter((e) => e.isPublic),
              action.payload,
              ...state.current.channels.filter((e) => !e.isPublic),
            ]
          : [...state.current.channels, action.payload]
      }
    },
    removeChannel(state, action: PayloadAction<number>): void {
      if (state.current?.channels?.length) {
        state.current.channels = state.current.channels.filter(
          (item) => item.id !== action.payload
        )
        state.channel.current =
          state.current.channels.length > 0
            ? state.current.channels[0]
            : undefined
      }
    },
    requestStart(state): void {
      state.error = null
      state.isLoading = true
      state.isSuccess = false
    },
    setChannelMode(state, action: PayloadAction<ChannelViewMode>): void {
      state.channel.channelMode = action.payload
    },
    setCurrentChannel(
      state,
      action: PayloadAction<CommunityChannel | undefined>
    ): void {
      state.channel.current = action.payload
    },
    updateCurrentChannel(state, action: PayloadAction<CommunityChannel>): void {
      state.channel.current = { ...state.channel.current, ...action.payload }
      const channels = state.current?.channels || []
      const indexOfChannel = findIndex(channels, {
        id: state.channel.current.id,
      })
      if (!isEmpty(channels) && indexOfChannel !== -1) {
        channels[indexOfChannel] = state.channel.current
      }
    },
    requestSuccess(state): void {
      state.isLoading = false
      state.isSuccess = true
    },
    requestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
      state.isSuccess = false
    },
    updateSetting(state, action: PayloadAction<SettingState>): void {
      state.setting = { ...state.setting, ...action.payload }
    },
    reset(state): void {
      state.current = defaultState.current
      state.channel.current = defaultState.channel.current
      state.error = defaultState.error
      state.isLoading = defaultState.isLoading
      state.setting = defaultState.setting
    },
    changeIsCommunityMember(state, action: PayloadAction<boolean>): void {
      state.isCommunityMember = action.payload
    },
  },
})

export const {
  reset,
  addChannel,
  requestStart,
  removeChannel,
  updateSetting,
  requestSuccess,
  requestFailure,
  setChannelMode,
  setCurrentChannel,
  setCurrentCommunity,
  updateCurrentChannel,
  updateCurrentCommunity,
  changeIsCommunityMember,
} = communitySlice.actions

export const fetchChannels = (communityId: number): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    const channels = await api.communityChannel
      .configPath(communityId)
      .index<CommunityChannel[], {}>({})
    dispatch(
      updateCurrentCommunity({
        channels: orderBy(channels, ['isPublic'], ['desc']),
      } as Community)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const fetchChannelDetail = (
  communityId: number,
  id: number
): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.communityChannel
      .configPath(communityId)
      .show<CommunityChannel>(id)
    dispatch(setCurrentChannel(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const fetchCommunityDetail = (id: number): AppThunk => async (
  dispatch,
  getState
): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.communities.show<Community>(id)
    dispatch(setCurrentCommunity(response))
    dispatch(requestSuccess())
    dispatch(
      changeIsCommunityMember(
        !!find(response.communityMembers, {
          userId: getState().users.current?.id,
        })
      )
    )
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const acceptCommunityMemberInvitation = (
  communityId: number
): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(requestStart())
    const currentUser = getState().users?.current
    const response = await api.communityMembers.create<
      CreateMemberParam,
      CommunityUser
    >({
      communityId,
      isAdmin: false,
      userId: currentUser?.id!,
    })
    const currentCommunity = getState().community.current
    dispatch(
      setCurrentCommunity({
        ...currentCommunity,
        communityMembers: [
          ...(currentCommunity?.communityMembers || []),
          { ...response, user: currentUser as User },
        ],
        communityMemberInvitations: (
          currentCommunity?.communityMemberInvitations || []
        ).filter((item) => item.userId != currentUser?.id),
      } as Community)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const rejectMemberInvitation = (
  invitationId: number
): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(requestStart())
    const currentUser = getState().users?.current
    await api.communityMemberInvitations.delete(invitationId)
    const currentCommunity = getState().community.current
    dispatch(
      setCurrentCommunity({
        ...currentCommunity,
        communityMemberInvitations: (
          currentCommunity?.communityMemberInvitations || []
        ).filter((item) => item.userId != currentUser?.id),
      } as Community)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const updateCommunity = (
  community: Partial<Community>
): AppThunk => async (dispatch): Promise<void> => {
  if (!community.id) return
  try {
    dispatch(requestStart())
    const response = await api.communities.update<
      Partial<Community>,
      Community
    >(community.id, community)
    dispatch(updateCurrentCommunity(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const updateCommunityMember = (
  communityMember: Partial<CommunityUser>
): AppThunk => async (dispatch, getState): Promise<void> => {
  if (!communityMember.id) {
    return
  }
  try {
    dispatch(requestStart())
    const response = await api.communityMembers.update<
      Partial<CommunityUser>,
      CommunityUser
    >(communityMember.id, communityMember)
    const currentCommunity = getState().community.current
    if (currentCommunity) {
      const indexOfMember = findIndex(currentCommunity.communityMembers, {
        id: response.id,
      })
      if (indexOfMember != -1) {
        const newCommunityMembers = currentCommunity.communityMembers.map(
          (item, index) =>
            index !== indexOfMember ? item : { ...item, ...response }
        )
        dispatch(
          updateCurrentCommunity({
            communityMembers: newCommunityMembers,
          } as Community)
        )
      }
    }
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const isCommunityAdmin = (
  userId?: number,
  members?: CommunityUser[]
): boolean => {
  return (
    !!members &&
    members.find((item) => item.userId === userId && item.isAdmin) !== undefined
  )
}

export const canLeaveCommunity = (
  userId?: number,
  members?: CommunityUser[]
): boolean => {
  return (
    !!members &&
    members.find((item) => item.userId !== userId && item.isAdmin) !== undefined
  )
}

export const removeCommunityMember = (
  communityUserId: number
): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(requestStart())
    await api.communityMembers.delete(communityUserId)
    const currentCommunity = getState().community.current
    if (currentCommunity) {
      dispatch(
        updateCurrentCommunity({
          communityMembers: currentCommunity.communityMembers.filter(
            (item) => item.id != communityUserId
          ),
        } as Community)
      )
    }
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const filterCommunityInvitationMembers = (
  community: Community,
  users: User[]
): InvitationUser[] => {
  return users.map((item) => {
    let isInvited = false
    const isMember =
      find(community.communityMembers, { userId: item.id }) !== undefined
    if (isMember) {
      isInvited = true
    } else {
      const pendingMemberIds = [
        ...(community.communityMemberJoinRequests || []).map(
          (member) => member.user.id
        ),
        ...(community.communityMemberInvitations || []).map(
          (member) => member.userId
        ),
      ]
      isInvited = includes(pendingMemberIds, item.id)
    }
    return { ...item, isInvited, isJoined: isMember } as InvitationUser
  })
}

export const filterCommunityMemberByChannel = (
  users: User[],
  channel: CommunityChannel
): InvitationUser[] => {
  return users.map((item) => {
    const isMember =
      find(channel.communityChannelMembers, { userId: item.id }) !== undefined
    return { ...item, isJoined: isMember } as InvitationUser
  })
}

export const updateChannel = (
  communityId: number,
  channelId: number,
  data: CommunityChannelRequest
): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.communityChannel
      .configPath(communityId)
      .update<CommunityChannelRequest, CommunityChannel>(channelId, data)
    dispatch(updateCurrentChannel(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const removeChannelMember = (
  channelMemberId: number
): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(requestStart())
    await api.communityChannelMembers.delete(channelMemberId)
    const currentChannel = getState().community.channel.current
    const communityChannelMembers = (
      currentChannel?.communityChannelMembers || []
    ).filter((item) => item.id != channelMemberId)
    dispatch(
      updateCurrentChannel({ communityChannelMembers } as CommunityChannel)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const addChannelMember = (
  user: User,
  communityChannelId: number
): AppThunk => async (dispatch, getState): Promise<void> => {
  dispatch(requestStart())
  try {
    const response = await api.communityChannelMembers.create<
      AddChannelMemberParams,
      CommunityUser
    >({
      userId: user.id,
      isAdmin: false,
      communityChannelId: communityChannelId,
    })
    const communityChannelMembers = [
      ...(getState().community.channel?.current?.communityChannelMembers || []),
      { ...response, user },
    ]
    dispatch(
      updateCurrentChannel({
        communityChannelMembers,
      } as CommunityChannel)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const updateChannelMember = (
  channelMemberId: number,
  data: AddChannelMemberParams
): AppThunk => async (dispatch, getState): Promise<void> => {
  dispatch(requestStart())
  try {
    const response = await api.communityChannelMembers.update<
      AddChannelMemberParams,
      CommunityChannel
    >(channelMemberId, data)
    const communityChannelMembers = getState().community.channel?.current?.communityChannelMembers.map(
      (item) => {
        if (item.userId !== channelMemberId) return item
        return { ...item, ...response }
      }
    )
    dispatch(
      updateCurrentChannel({
        communityChannelMembers,
      } as CommunityChannel)
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const inviteMemberToCommunity = (
  user: User,
  community: Community
): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.communityMemberInvitations.create<
      MemberInvitationParams,
      CommunityUser
    >({
      userId: user.id,
      communityId: community.id,
      isAdmin: false,
    })
    const communityMemberInvitations = [
      ...(community.communityMemberInvitations || []),
      ...[{ ...response, user }],
    ]
    dispatch(
      setCurrentCommunity({ ...community, ...{ communityMemberInvitations } })
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}

export const communitySelector = (state: RootState): Community | undefined =>
  state.community.current
export const channelSelector = (
  state: RootState
): CommunityChannel | undefined => state.community.channel.current

export default communitySlice.reducer
