import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import CategoryTitle from '~/components/career/molecules/resume/CategoryTitle'
import ResumeLanguageItem from '~/components/career/molecules/resume/ResumeLanguageItem'
import Resume, { TemplateType } from '~/interfaces/Resume'

type Props = {
  resume: Partial<Resume>
}

const UserLanguages: React.FC<Props> = ({ resume }: Props) => {
  const { t } = useTranslation()
  const templateType = resume.templateType!

  const categoryTitleInverted = {
    enjin: undefined,
    shades: undefined,
    formal: true,
    minimal: undefined,
  }

  return isEmpty(resume.languages) ? (
    <></>
  ) : (
    <View testID="user-languages" style={styles.container}>
      <CategoryTitle
        templateType={templateType}
        title={t('resume.user.languageTitle')}
        inverted={categoryTitleInverted[templateType]}
        shouldShowUnderLine={templateType === TemplateType.Shades}
      />
      <View testID="languages-container">
        {resume.languages!.map((language) => (
          <ResumeLanguageItem
            key={language.id}
            language={language}
            templateType={templateType}
          />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
})

export default UserLanguages
