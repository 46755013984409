import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'
import Resume, { TemplateType } from '~/interfaces/Resume'

export type ResumeQueryParams = {
  templateType: TemplateType
} & BaseQueryParams

export type ResumeBodyParams = {
  userResume: Partial<Resume>
}

export class ResumesAPI extends BaseAPI {
  path = '/user_resumes'
}
