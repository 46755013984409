import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import api from '~/api'
import ApplicantDocument from '~/components/career/atoms/jobs/ApplicantDocument'
import ApplicantResume from '~/components/career/atoms/jobs/ApplicantResume'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import ControlMenu from '~/components/common/molecules/ControlMenu'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import useCustomToast from '~/hooks/useCustomToast'
import Entry from '~/interfaces/Entry'
import { navigate } from '~/utils/navigation'

type Props = {
  entry: Entry
  isArchived?: boolean
}

const JobApplicantItem = ({ entry, isArchived }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const { deviceType } = useContext(RootContext)
  const toast = useCustomToast()
  const [isLoading, setIsLoading] = useState(false)

  const getControlMenuData = [
    {
      id: 'archive',
      title: t('company.applicant.archiveApplicant'),
    },
  ]

  const archiveApplicant = () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      api.entries.update(entry.id, { isArchived: true })
    } catch (err) {
      toast.showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const navigateMessage = () => {
    navigate('messages')
  }

  const centerColumn = (
    <View style={styles.centerColumn}>
      {entry.userResume && <ApplicantResume entry={entry} />}
      {entry.userDocument && <ApplicantDocument entry={entry} />}
      <Text
        style={styles.message}
        onPress={navigateMessage}
        testID="job-navigate-message"
      >
        {t('company.jobs.message')}
      </Text>
    </View>
  )

  return (
    <View testID="job-applicant-item" style={styles.container}>
      <View style={styles.userInfo} testID="job-user-item">
        <UserAvatar user={entry.user} size={userAvatarSize[deviceType!]} />
        {isPC ? (
          <Text style={styles.userName}>{entry.user.profile.name}</Text>
        ) : (
          <View>
            <Text style={styles.userName}>{entry.user.profile.name}</Text>
            {centerColumn}
          </View>
        )}
      </View>
      {isPC && centerColumn}
      {!isArchived && (
        <View testID="job-control-menu">
          <ControlMenu data={getControlMenuData} onSelect={archiveApplicant} />
        </View>
      )}
    </View>
  )
}

const userAvatarSize = {
  pc: ProfileAvatarSize.MEDIUM,
  tablet: ProfileAvatarSize.MEDIUM,
  mobile: ProfileAvatarSize.SMALL,
}

const styles = StyleSheet.create({
  centerColumn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 300,
    paddingHorizontal: 10,
    paddingTop: 4,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '94%',
  },
  message: {
    color: color.pressableText,
    fontWeight: '600',
    textDecorationLine: 'underline',
  },
  userInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 20,
    width: '50%',
  },
  userName: {
    paddingLeft: 10,
  },
})

export default JobApplicantItem
