import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'
import { JobSection } from '~/constants/common/navigation'
import Recruitment from '~/interfaces/Recruitment'

export type RecruimentQueryParams = {
  id?: number
  searchWord?: string
  status?: JobSection
  sortBy?: RecruimentFilterKey
  section?: JobSection
  location?: string
  companyId?: number
} & BaseQueryParams

export enum RecruimentFilterKey {
  BY_ACTIVE = 'active',
  BY_ENDED = 'ended',
  BY_RECENTLY = 'recently',
}

export class RecruitmentsAPI extends BaseAPI {
  path = '/recruitments'
  async fetchSuggestedJobs(): Promise<Recruitment[]> {
    const response = await this.client.get<Recruitment[]>(
      '/recruitments/suggests'
    )
    return response
  }
}
