import { isNumber } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet } from 'react-native'

import api from '~/api'
import { PostRequest } from '~/api/posts'
import imageIcon from '~/assets/images/icons/picture.png'
import CardContainer from '~/components/common/atoms/CardContainer'
import ImagePickerButton from '~/components/common/molecules/ImagePickerButton'
import PostInputArea, {
  PostAreaData,
} from '~/components/common/organisms/PostInputArea'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomToast from '~/hooks/useCustomToast'
import Media from '~/interfaces/Media'
import Post from '~/interfaces/Post'

type Props = {
  post?: Post
  isEdit?: boolean
  communityId?: number
  communityEventId?: number
  communityChannelId?: number
  onCreate?: (post: Post) => void
  onUpdate?: (post: Post) => void
}

const PostForm: React.FC<Props> = ({
  post,
  isEdit,
  onCreate,
  onUpdate,
  communityId,
  communityEventId,
  communityChannelId,
}: Props) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const [postData, setPostData] = useState<PostAreaData>({})
  const [isShowPreviewPost, setIsShowPreviewPost] = useState(false)

  const updatePostData = (data: Partial<PostAreaData>): void => {
    setPostData({ ...postData, ...data })
  }

  const updateMedia = (media: Media[]): void => {
    updatePostData({
      media: isEdit ? [...(postData.media || []), ...media] : media,
    })
  }

  const submit = (): void => {
    const bodyData: PostRequest = {
      id: post?.id,
      message: postData.postText || '',
      media: postData.media,
      communityChannelId,
      communityEventId,
    }
    setIsLoading(true)
    onChangePreview(false)
    isEdit ? updatePost(bodyData) : createPost(bodyData)
  }

  const onChangePreview = (isShow: boolean): void => {
    setIsShowPreviewPost(isShow)
  }

  const createPost = async (postRequest: PostRequest): Promise<void> => {
    try {
      const post =
        isNumber(communityId) && isNumber(communityChannelId)
          ? await api.channelPosts
              .build(communityId!, communityChannelId!)
              .create(postRequest)
          : isNumber(communityId) && isNumber(communityEventId)
          ? await api.communityEventPosts
              .build(communityId!, communityEventId!)
              .create(postRequest)
          : await api.posts.create(postRequest)
      setPostData({})
      onCreate && onCreate(post)
    } catch (error) {
      toast.showError(error)
    }
    setIsLoading(false)
  }

  const updatePost = async (postRequest: PostRequest): Promise<void> => {
    try {
      const post =
        communityId && communityChannelId
          ? await api.channelPosts
              .build(communityId, communityChannelId)
              .update(postRequest)
          : await api.posts.update(postRequest)
      setPostData({})
      onUpdate && onUpdate(post)
    } catch (error) {
      toast.showError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    isEdit &&
      setPostData({
        postText: post?.message,
        media: [...(post?.images || []), ...(post?.videos || [])],
      })
  }, [post, isEdit])

  return (
    <CardContainer style={styles.container} testID="post-form">
      <PostInputArea
        data={postData}
        submit={submit}
        isLoading={isLoading}
        onChanged={updatePostData}
        submitButtonTitle={isEdit ? t('update') : t('postForm.post')}
        onChangePreview={onChangePreview}
        isShowPreviewPost={isShowPreviewPost}
        mediaPicker={
          <ImagePickerButton
            type="clear"
            kind="post"
            onUpload={updateMedia}
            loading={isLoadingMedia}
            title={t('postForm.image')}
            onLoading={setIsLoadingMedia}
            allowsMultipleSelection={true}
            titleStyle={StyleSheet.flatten(styles.imagePickerButton)}
            icon={<Image style={styles.icon} source={imageIcon} />}
          />
        }
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  icon: {
    height: 15,
    width: 15,
  },
  imagePickerButton: {
    color: color.pressableText,
    fontSize: FontSize.SUB,
    marginLeft: 4,
  },
})

export default PostForm
