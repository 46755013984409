import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Input from '~/components/workarounds/Input'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isPC: boolean
  address?: string
  onChangeAddress: (value: string) => void
}

const AddressTextField: React.FC<Props> = ({
  isPC,
  address,
  onChangeAddress,
}: Props) => {
  const { t } = useTranslation()
  const inputContainerStyle = isPC ? styles.inputContainer : {}
  const addressStyle = isPC ? styles.addressInput : styles.addressInputMobile

  return (
    <View style={styles.container} testID="address-text-field">
      <Text style={styles.title}>{`${t('profile.address')}*`}</Text>
      <View style={inputContainerStyle} testID="input-container">
        {/*
          ZipCode has not supported yet
          <ZipCodeTextField
          isPC={isPC}
          zipCodeFirst={zipCodeFirst}
          zipCodeSecond={zipCodeSecond}
          onChangeZipCodeFirst={onChangeZipCodeFirst}
          onChangeZipCodeSecond={onChangeZipCodeSecond}
        /> */}
        <Input
          value={address}
          style={addressStyle}
          testID="address-input"
          onChangeText={onChangeAddress}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  addressInput: {
    borderColor: color.gray,
    borderRadius: 4,
    borderWidth: 1,
    flex: 1,
    height: 40,
    padding: 4,
  },
  addressInputMobile: {
    borderColor: color.gray,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    marginTop: 10,
    padding: 4,
  },
  container: {
    marginBottom: 10,
    marginTop: 10,
  },
  inputContainer: {
    alignContent: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  title: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
  },
})

export default AddressTextField
