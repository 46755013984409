import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Resume from '~/interfaces/Resume'
import i18next from '~/plugins/i18n'
import { getMediumFontByLanguage } from '~/utils/common/themeConfig'
import { openURL } from '~/utils/navigation'

type Props = {
  resume: Resume
  isText?: boolean
  size?: 'large' | 'medium' | 'small'
}

const ResumeTitle: React.FC<Props> = ({ resume, size, isText }: Props) => {
  const { t } = useTranslation()
  const textColor: string = isText ? color.white : color.pressableText
  const fontStyle = (): TextStyle => {
    switch (size) {
      case 'large':
        return { fontSize: FontSize.IMPORTANT }
      case 'medium':
        return { fontSize: FontSize.REMARKABLE }
      case 'small':
        return { fontSize: FontSize.GENERAL }
      default:
        return { fontSize: FontSize.GENERAL }
    }
  }

  const textStyle = {
    color: textColor,
    fontFamily: getMediumFontByLanguage(i18next.language),
    ...fontStyle(),
  }

  const navigateToResumeDetail = (): void => {
    openURL(`/resumes/${resume.id}`)
  }

  return (
    <Text
      testID="resume-title"
      style={textStyle as TextStyle}
      onPress={isText ? undefined : navigateToResumeDetail}
    >
      {t('profile.displayName', {
        familyName: resume.givenName,
        givenName: resume.familyName,
      })}
    </Text>
  )
}

export default ResumeTitle
