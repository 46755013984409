import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import User from '~/interfaces/User'

type Props = {
  user: User
  title?: string
}

const UserItem: React.FC<Props> = ({ user, title }: Props) => {
  const { deviceType } = useContext(RootContext)

  return (
    <View style={styles.container} testID="user-item">
      <UserAvatar user={user} size={userAvatarSize[deviceType!]} />
      <View style={styles.userInfo}>
        <UserName size={userNameSize[deviceType!]} user={user} />
        <Text style={styles.userTitle} numberOfLines={1}>
          {title ?? user?.profile?.title}
        </Text>
      </View>
    </View>
  )
}

const userAvatarSize = {
  pc: ProfileAvatarSize.MEDIUM,
  tablet: ProfileAvatarSize.MEDIUM,
  mobile: ProfileAvatarSize.SMALL,
}

const userNameSize: {
  [name: string]: 'medium' | 'small' | 'large' | 'tiny'
} = {
  pc: 'medium',
  tablet: 'medium',
  mobile: 'small',
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userInfo: {
    flex: 1,
    paddingHorizontal: 10,
  },
  userTitle: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.GENERAL,
    paddingVertical: 4,
  },
})

export default UserItem
