import { useNavigation } from '@react-navigation/native'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Image } from 'react-native-elements'

import api from '~/api'
import JobSave from '~/components/career/features/jobs/JobSave'
import CompanyJobItemContent from '~/components/career/molecules/company/CompanyJobItemContent'
import JobItemContent from '~/components/career/molecules/jobs/JobItemContent'
import CardContainer from '~/components/common/atoms/CardContainer'
import ConfirmationDialog from '~/components/common/molecules/ConfirmationDialog'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import color from '~/constants/common/color'
import useCustomToast from '~/hooks/useCustomToast'
import Company from '~/interfaces/Company'
import IImage from '~/interfaces/Image'
import Recruitment from '~/interfaces/Recruitment'
import { JobRecruitmentMenu } from '~/utils/career/company'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  job: Recruitment
  isPC: boolean
  isJobDashboard?: boolean
  company?: Company
  onRemove?: (job: Recruitment) => void
  isAdminCompany?: boolean
}

const JobItem: React.FC<Props> = ({
  job,
  isPC,
  company,
  isJobDashboard,
  onRemove,
  isAdminCompany,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const uri = (job?.image as IImage)?.urls.medium

  const containerStyle = StyleSheet.flatten([
    styles.container,
    isPC ? styles.constainerWithIsPC.true : styles.constainerWithIsPC.false,
    job.closedAt ? styles.endJob : undefined,
  ]) as ViewStyle

  const [
    isShowEndedRecruitmentDialog,
    setIsShowEndedRecruitmentDialog,
  ] = useState(false)

  const controlMenuData = [
    {
      id: JobRecruitmentMenu.EDIT_POST,
      title: t('post.editPost'),
    },
    {
      id: JobRecruitmentMenu.DUPLICATE_POST,
      title: t('company.recruitments.sortMenu.duplicatePost'),
    },
  ]

  if (!job.closedAt) {
    controlMenuData.push({
      id: JobRecruitmentMenu.ENDED_JOB,
      title: t('company.recruitments.sortMenu.endJob'),
    })
  }

  const jobImage = uri
    ? { uri }
    : isPC
    ? require('~/assets/images/career/empty-job-portrait.png')
    : require('~/assets/images/career/empty-job-landscape.png')

  const onSelectJobMenu = (item: ControlMenuData): void => {
    switch (item.id) {
      case JobRecruitmentMenu.EDIT_POST:
        navigate('companyJobEdit', { id: job.id, companyId: job.companyId })
        break
      case JobRecruitmentMenu.DUPLICATE_POST:
        duplicateRecruitment()
        break
      case JobRecruitmentMenu.ENDED_JOB:
        setIsShowEndedRecruitmentDialog(true)
        break
      default:
    }
  }

  const endRecruitment = async () => {
    if (isNil(company)) {
      return
    }
    try {
      const response = await api.companyRecruitments
        .configPath(company.id)
        .update<Partial<Recruitment>, Recruitment>(job.id, {
          closedAt: new Date().toString(),
        })
      isJobDashboard
        ? (job.closedAt = response.closedAt)
        : onRemove && onRemove(response)
    } catch (error) {
      toast.showError(error)
    }
    setIsShowEndedRecruitmentDialog(false)
  }

  const duplicateRecruitment = async () => {
    if (isNil(company)) {
      return
    }
    try {
      const response = await api.companyRecruitments
        .duplicate(company.id, job.id)
        .create<Partial<Recruitment>, Recruitment>({})
      navigate('companyJobEdit', {
        id: response.id,
        companyId: response.companyId,
      })
    } catch (error) {
      toast.showError(error)
    }
    setIsShowEndedRecruitmentDialog(false)
  }

  return (
    <CardContainer style={containerStyle} testID="job-item" key={job.id}>
      <Image
        testID="job-item-image"
        source={jobImage}
        resizeMode="cover"
        width={
          isPC
            ? styles.jobAvatarWithIsPC.true.width
            : styles.jobAvatarWithIsPC.false.width
        }
        height={
          isPC
            ? styles.jobAvatarWithIsPC.true.height
            : styles.jobAvatarWithIsPC.false.height
        }
        style={
          isPC ? styles.jobAvatarWithIsPC.true : styles.jobAvatarWithIsPC.false
        }
        onPress={(): void => navigate('jobShow', { id: job.id })}
      />
      {isJobDashboard ? (
        <CompanyJobItemContent job={job} isPC={isPC} company={company!} />
      ) : (
        <JobItemContent job={job} isPC={isPC} />
      )}

      {isAdminCompany ? (
        <View testID="job-control-menu" style={styles.jobControlMenu}>
          <ControlMenu
            onSelect={(menuItem): void => onSelectJobMenu(menuItem)}
            data={controlMenuData}
            preferredPlacement="bottom"
          />
          <ConfirmationDialog
            isDanger
            onPressAccept={endRecruitment}
            visible={isShowEndedRecruitmentDialog}
            onPressCancel={(): void => setIsShowEndedRecruitmentDialog(false)}
            message={t('company.endJobConfirmMessage')}
          />
        </View>
      ) : (
        <View testID="job-save-view" style={styles.jobControlMenu}>
          <JobSave job={job} isShowIcon={true} />
        </View>
      )}
    </CardContainer>
  )
}

const styles = {
  jobAvatarWithIsPC: {
    true: {
      height: '100%',
      width: 240,
    },
    false: {
      height: 135,
      width: '100%',
    },
  },
  container: {
    alignSelf: 'center',
    backgroundColor: color.white,
    marginTop: 15,
  },
  constainerWithIsPC: {
    false: {
      width: '90%',
      height: 280,
    },
    true: {
      width: '100%',
      height: 215,
      flexDirection: 'row',
      maxWidth: 900,
      paddingRight: 20,
    },
  },
  endJob: {
    backgroundColor: color.gray,
  },
  jobControlMenu: {
    paddingTop: 10,
    paddingRight: 10,
    position: 'absolute',
    right: 0,
  } as ViewStyle,
}

export default JobItem
