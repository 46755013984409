import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import Toast from 'react-native-toast-notifications'
import ToastContainer from 'react-native-toast-notifications'
import { useSelector } from 'react-redux'

import api from '~/api'
import CancelButton from '~/components/common/atoms/CancelButton'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import EventEditForm from '~/components/community/organisms/EventEditForm'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useCustomToast from '~/hooks/useCustomToast'
import CommunityEvent from '~/interfaces/CommunityEvent'
import { currentUserSelector } from '~/slices/common/users'
import { communitySelector } from '~/slices/community/community'

type Props = {
  onFinish: () => void
  event?: CommunityEvent
  onCreated?: (event: CommunityEvent) => void
  onUpdate?: (event: CommunityEvent) => void
}

const EventEditDialog: React.FC<Props> = ({
  event,
  onFinish,
  onCreated,
  onUpdate,
}: Props) => {
  const isEdit = !!event
  const { t } = useTranslation()
  const toast = useCustomToast()
  const toastRef = useRef<ToastContainer>(null)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const communityId = useSelector(communitySelector)?.id
  const [data, setData] = useState<Partial<CommunityEvent>>(
    event ?? {
      isVirtual: true,
      createdUserId: currentUser?.id,
    }
  )

  const changeEventData = (communityEvent: Partial<CommunityEvent>): void => {
    setData((data) => ({ ...data, ...communityEvent }))
  }

  const createEvent = async (): Promise<void> => {
    if (isLoading || !communityId) {
      return
    }
    setIsLoading(true)
    try {
      const communityEvent = await api.communityEvents
        .configPath(communityId)
        .create<Partial<CommunityEvent>, CommunityEvent>(data)
      onCreated && onCreated(communityEvent)
    } catch (error) {
      toast.showError(error, toastRef)
    } finally {
      setIsLoading(false)
    }
  }

  const updateEvent = async (): Promise<void> => {
    if (isLoading || !communityId || !event) {
      return
    }
    setIsLoading(true)
    try {
      const communityEvent = await api.communityEvents
        .configPath(communityId)
        .update<Partial<CommunityEvent>, CommunityEvent>(event.id, data)
      onUpdate && onUpdate(communityEvent)
    } catch (error) {
      toast.showError(error, toastRef)
    } finally {
      setIsLoading(false)
    }
  }

  const submit = () => {
    isEdit ? updateEvent() : createEvent()
  }

  useEffect(() => {
    setIsValid(
      !isEmpty(data.title?.trim()) &&
        !isEmpty(data.startAt) &&
        !isEmpty(data.endAt) &&
        !isEmpty(data.description) &&
        !isEmpty(data.location) &&
        !isEmpty(data.eventType)
    )
  }, [
    data.title,
    data.startAt,
    data.endAt,
    data.description,
    data.location,
    data.eventType,
  ])

  return (
    <ModalContainer visible={true} onDismiss={onFinish}>
      <Toast ref={toastRef} offsetBottom={120} />
      <ScrollView style={styles.container}>
        <View testID="event-edit-dialog">
          <EventEditForm
            data={data}
            isEdit={isEdit}
            onChange={changeEventData}
          />
          <View style={styles.footer}>
            <CancelButton onPress={onFinish} />
            <Button
              onPress={submit}
              title={t('save')}
              containerStyle={styles.submit}
              disabled={!isValid || isLoading}
              testID="event-edit-submit-button"
              titleStyle={commonStyles.buttonTextSize}
            />
          </View>
        </View>
      </ScrollView>
    </ModalContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    maxWidth: 700,
    padding: 40,
    maxHeight: '90%',
    ...commonStyles.borderRadiusAllCorner,
  },
  footer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  submit: {
    width: 120,
    paddingHorizontal: 10,
    alignSelf: 'flex-end',
    marginLeft: 10,
  },
})

export default EventEditDialog
