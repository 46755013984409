import React from 'react'
import { StyleSheet, View } from 'react-native'

import MessageItemControlMenu from '~/components/career/features/messages/MessageItemControlMenu'
import InboxItem from '~/components/career/molecules/message/InboxItem'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import ChatThread from '~/interfaces/ChatThread'
import {
  MessageControlMenuType,
  MessageItemControlMenuEvent,
} from '~/interfaces/MessageItemControlMenu'
import User from '~/interfaces/User'

type Props = {
  currentUser?: User
  chatThread: Partial<ChatThread>
  newThreadMessage?: React.ReactElement
  onChange?: (chatThread: ChatThread) => void
  deleteThread?: () => void
  renameThread?: () => void
  archiveThread?: () => void
}

const ThreadMessageAction: React.FC<Props> = ({
  onChange,
  chatThread,
  currentUser,
  newThreadMessage,
  deleteThread,
  renameThread,
  archiveThread,
}: Props) => {
  const isEdit = chatThread.id !== undefined

  const onSelectMenu = (event: MessageItemControlMenuEvent) => {
    switch (event.type) {
      case MessageControlMenuType.RENAME:
        renameThread?.()
        break
      case MessageControlMenuType.MARK_AS_READ:
        event.data && onChange?.(event.data)
        break
      case MessageControlMenuType.MARK_AS_UNREAD:
        event.data && onChange?.(event.data)
        break
      case MessageControlMenuType.DELETE:
        deleteThread?.()
        break
      case MessageControlMenuType.ARCHIVE:
        archiveThread?.()
        break
    }
  }

  return isEdit ? (
    <View style={styles.container} testID="thread-message-action">
      <InboxItem
        withoutBackground
        currentUser={currentUser}
        chatThread={chatThread as ChatThread}
      />
      <MessageItemControlMenu
        chatThread={chatThread as ChatThread}
        onSelect={onSelectMenu}
      />
    </View>
  ) : (
    <View style={styles.container} testID="thread-message-action">
      {newThreadMessage}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    borderBottomColor: color.gray,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    height: 110,
    paddingHorizontal: 30,
    ...commonStyles.shadow,
  },
  content: {
    flex: 1,
    flexWrap: 'wrap',
    paddingLeft: 20,
    alignSelf: 'center',
  },
  sender: {
    color: color.pressableText,
    flexWrap: 'wrap',
  },
})

export default ThreadMessageAction
