import React from 'react'

import AppDrawer from '~/components/common/pages/RootPage/AppDrawer'
import { ServiceType } from '~/constants/common/app'
import { otherRoutes } from '~/utils/common/routes'
import {
  mainCommunityRoutes,
  otherCommunityRoutes,
} from '~/utils/community/routes'

const PCLayout: React.FC = () => (
  <AppDrawer.Navigator
    initialRouteName="community"
    serviceType={ServiceType.COMMUNITY}
  >
    {[...mainCommunityRoutes, ...otherCommunityRoutes, ...otherRoutes].map(
      (route) => (
        <AppDrawer.Screen key={route.name} {...route} />
      )
    )}
  </AppDrawer.Navigator>
)

export default PCLayout
