import { range } from 'lodash'
import React from 'react'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'

type Props = {
  count: number
  numColumns: number
  header?: React.ReactElement
  itemComponent: React.ReactElement
}

const SkeletonList: React.FC<Props> = ({
  count,
  header,
  numColumns,
  itemComponent,
}: Props) => {
  const data = range(0, count)

  const renderItem: ListRenderItem<number> = ({ item }: { item: number }) => (
    <View key={item} style={styles.itemContainer}>
      {itemComponent}
    </View>
  )

  const keyExtractor = (item: number): string => item.toString()

  return (
    <View testID="skeleton-list" style={styles.container}>
      <FlatList
        data={data}
        numColumns={numColumns}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={header}
        columnWrapperStyle={numColumns > 1 ? styles.columnWrapper : undefined}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  columnWrapper: {
    alignSelf: 'center',
  },
  container: {
    flex: 1,
  },
  itemContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default SkeletonList
