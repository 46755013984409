import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Image } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

const Snippet: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View style={styles.body} testID="snippet">
      <View style={styles.content}>
        <Image
          testID="url-snippet-image"
          source={require('~/assets/images/snippet.png')}
          style={styles.snippet}
        />
        <TitleText style={styles.title}>{t('snippet.title')}</TitleText>
        <Text style={styles.description}>{t('snippet.description')}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: color.white,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
    paddingHorizontal: 20,
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
  },
  content: {
    flexDirection: 'column',
    maxWidth: 900,
    width: '100%',
    textAlign: 'center',
  },
  description: {
    fontSize: FontSize.REMARKABLE,
    marginBottom: 50,
  },
  snippet: {
    marginTop: 80,
    marginBottom: 20,
    height: 172,
    width: '100%',
  },
  title: {
    fontSize: FontSize.IMPORTANT,
    paddingVertical: 20,
  },
})

export default Snippet
