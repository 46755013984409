import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import CommunityItem from '~/components/community/organisms/CommunityItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import Community from '~/interfaces/Community'

type Props = {
  community: Community
}

const CommunityPreview: React.FC<Props> = ({ community }: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)

  return (
    <CardContainer
      testID="community-preview"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <Text style={styles.title}>{t('community.communityPreview')}</Text>
      <CommunityItem isPC={isPC ?? true} community={community} />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    padding: 30,
    paddingVertical: 20,
  },
  containerMobile: {
    backgroundColor: color.white,
    marginTop: 10,
    padding: 10,
  },
  title: {
    color: color.unpressableTitleText,
    fontWeight: 'bold',
    marginLeft: 10,
    marginBottom: 10,
  },
})

export default CommunityPreview
