import camelcaseKeys from 'camelcase-keys'
import { find, first, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { CompanyRecruitmentsSortType } from '~/api/companyRecruitments'
import { RecruimentQueryParams } from '~/api/recruitments'
import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import useCustomNavigation from '~/hooks/useNavigation'
import { navigationRef } from '~/utils/navigation'

type Props = {
  isPC?: boolean
}

const CompanyJobDashboardFilterMenu: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const { setQueryParams } = useCustomNavigation()
  const routeParams = camelcaseKeys(
    navigationRef.current?.getCurrentRoute()?.params || {}
  ) as RecruimentQueryParams

  const [selectedItem, setSelectedItem] = useState<
    ControlMenuData | undefined
  >()

  const menuData = [
    {
      id: CompanyRecruitmentsSortType.ALL,
      title: t('company.jobs.sortMenu.all'),
    },
    {
      id: CompanyRecruitmentsSortType.ACTIVE,
      title: t('company.jobs.sortMenu.active'),
    },
    {
      id: CompanyRecruitmentsSortType.ENDED,
      title: t('company.jobs.sortMenu.ended'),
    },
  ]

  const updateSortByParam = (controlMenuData: ControlMenuData): void => {
    setQueryParams({ status: controlMenuData.id.toString() })
    setSelectedItem(controlMenuData)
  }

  useEffect(() => {
    const selectedItem = find(menuData, {
      id: routeParams.status,
    }) as ControlMenuData
    if (!isNil(routeParams.status) && !isNil(selectedItem)) {
      updateSortByParam(selectedItem)
    } else {
      updateSortByParam(first(menuData) as ControlMenuData)
    }
  }, [t, routeParams.status])

  const containerStyle = isPC ? styles.container : styles.containerMobile

  return (
    <View testID="company-job-dashboard-filter-menu" style={containerStyle}>
      <ControlMenu
        data={menuData}
        onSelect={updateSortByParam}
        menuTrigger={
          <View style={styles.menuTriggerText}>
            <Text>{selectedItem?.title}</Text>
            <Icon name="chevron-down" size={24} color={color.pressableText} />
          </View>
        }
        preferredPlacement="bottom"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-end',
    marginTop: 30,
  },
  containerMobile: {
    alignSelf: 'flex-end',
    marginTop: 20,
    paddingHorizontal: 20,
  },
  menuTriggerText: {
    alignItems: 'center',
    color: color.pressableText,
    flex: 1,
    flexDirection: 'row',
    fontSize: FontSize.GENERAL,
  },
})

export default CompanyJobDashboardFilterMenu
