import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenuContainer from '~/components/common/molecules/ControlMenuContainer'
import MenuItem from '~/components/workarounds/MenuItem'

export enum ResumeMenuType {
  Edit = 'edit',
  Delete = 'delete',
}

type Props = {
  onSelect?: (type: ResumeMenuType) => void
}

const ResumeControlMenu: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  const acceptSelectDelete = (): void => {
    onSelect && onSelect(ResumeMenuType.Delete)
  }

  const hideConfirmDialog = (): void => setIsShowConfirmDialog(false)

  return (
    <View testID="resume-control-menu">
      <ControlMenuContainer
        onPressCancel={hideConfirmDialog}
        onPressAccept={acceptSelectDelete}
        isShowConfirmDialog={isShowConfirmDialog}
        confirmationMessage={t('resume.deleteResumeConfirmMessage')}
      >
        <MenuItem
          title={t('edit')}
          onSelect={(): void => onSelect && onSelect(ResumeMenuType.Edit)}
        />
        <MenuItem
          title={t('delete')}
          onSelect={(): void => setIsShowConfirmDialog(true)}
        />
      </ControlMenuContainer>
    </View>
  )
}

export default ResumeControlMenu
