import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  isLoading: boolean
  disabled: boolean
  onCancel?: () => void
  onSubmit: () => Promise<void>
}

const FormAction: React.FC<Props> = ({
  onCancel,
  onSubmit,
  isLoading,
  disabled,
}: Props) => {
  const { t } = useTranslation()

  return (
    <View testID="form-action" style={styles.container}>
      {onCancel && (
        <Button
          type="solid"
          testID="cancel-button"
          title={t('cancel')}
          onPress={onCancel}
          buttonStyle={StyleSheet.flatten(styles.cancelButton)}
          titleStyle={StyleSheet.flatten(styles.cancelTitle)}
        />
      )}
      <Button
        type="solid"
        testID="save-button"
        title={t('save')}
        loading={isLoading}
        disabled={disabled}
        onPress={onSubmit}
        buttonStyle={StyleSheet.flatten(styles.saveButton)}
        titleStyle={StyleSheet.flatten(styles.saveTitle)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  cancelButton: {
    alignSelf: 'flex-end',
    backgroundColor: color.gray,
    height: 30,
    marginRight: 10,
    width: 85,
  },
  cancelTitle: {
    color: color.black,
    fontSize: FontSize.SUB,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  saveButton: {
    alignSelf: 'flex-end',
    height: 30,
    width: 65,
  },
  saveTitle: {
    fontSize: FontSize.SUB,
  },
})

export default FormAction
