import React from 'react'
import { StyleSheet } from 'react-native'
import { Composer, ComposerProps } from 'react-native-gifted-chat'

import color from '~/constants/common/color'

export const renderComposer = (props: ComposerProps): React.ReactNode => {
  return (
    <Composer
      {...props}
      textInputStyle={styles.composer}
      multiline={true}
      textInputProps={{ testID: 'composer-text-input' }}
    />
  )
}

const styles = StyleSheet.create({
  composer: {
    backgroundColor: color.white,
    borderRadius: 4,
    borderWidth: 0,
    flex: 1,
    paddingTop: 10,
    paddingLeft: 12,
  },
})
