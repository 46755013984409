import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import PrimarySearchHeader from '~/components/common/organisms/PrimarySearchHeader'
import Company from '~/interfaces/Company'
import User from '~/interfaces/User'
import { isCompanyAdmin } from '~/utils/career/company'

type Props = {
  company: Company
  currentUser: User | undefined
  isPC?: boolean
  createJobButton?: React.ReactElement
}

const CompanyRecruitmentsHeader: React.FC<Props> = ({
  company,
  currentUser,
  isPC,
  createJobButton,
}: Props) => {
  const { t } = useTranslation()

  const isAdmin = useMemo(() => {
    return isNil(currentUser) || isNil(company)
      ? false
      : isCompanyAdmin(currentUser, company)
  }, [currentUser?.id, company?.id])

  return (
    <View testID="company-recruitments-header">
      {isPC ? (
        <PrimarySearchHeader
          title={t('company.jobs.headerTitle')}
          searchInputPlaceholder={t('company.jobs.searchJobPostPlaceholder')}
          actionButton={isAdmin ? createJobButton : <></>}
        />
      ) : (
        <PrimarySearchHeader
          title=""
          searchInputPlaceholder={t('company.jobs.searchJobPostPlaceholder')}
        />
      )}
    </View>
  )
}

export default CompanyRecruitmentsHeader
