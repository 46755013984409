import { Fontisto as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet } from 'react-native'
import { FAB } from 'react-native-elements'

import color from '~/constants/common/color'

type Props = {
  onPress?: () => void
}

const FloatButton: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <FAB
      testID="float-button"
      placement="right"
      color={color.primary}
      buttonStyle={styles.container}
      icon={
        <Icon size={25} name="plus-a" type="fontisto" color={color.white} />
      }
      onPress={onPress}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 40,
    maxHeight: 80,
    maxWidth: 80,
  },
})

export default FloatButton
