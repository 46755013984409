import { BaseAPI } from '~/api/baseAPI'

export type CreateMemberParam = {
  userId: number
  communityId: number
  isAdmin: boolean
}

export class CommunityMembersAPI extends BaseAPI {
  path = '/community_members'
}
