import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'

import DailyQuiz from '~/components/career/features/quizzes/DailyQuiz'
import DailyQuizEmptyView from '~/components/career/molecules/quiz/DailyQuizEmptyView'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'

type Props = {
  totalPoint: number
  isShowQuizPoint?: boolean
  isMe?: boolean
}

const QuizPoints: FC<Props> = ({
  totalPoint,
  isShowQuizPoint = false,
  isMe,
}: Props) => {
  const { t } = useTranslation()
  const { isPC } = useContext(RootContext)
  const [isShowDailyQuiz, setIsShowDailyQuiz] = useState(false)

  const showDailyQuizPopup = (): void => {
    setIsShowDailyQuiz(true)
  }

  const closeDailyQuizPopup = (): void => {
    setIsShowDailyQuiz(false)
  }

  return (
    <View testID="quiz-points">
      {isShowQuizPoint &&
        (isPC ? (
          <View style={styles.container}>
            <Text style={styles.pointsPC}>{totalPoint}</Text>
            <Text style={styles.subTitlePointsPC}>{t('quiz.points')}</Text>
          </View>
        ) : (
          <Text style={styles.pointsMobile} testID="quiz-points-title">
            {totalPoint}
            {t('quiz.points')}
          </Text>
        ))}
      {isMe && (
        <Button
          testID="answer-quiz-today"
          onPress={showDailyQuizPopup}
          title={t('quiz.answerTodayQuiz')}
          buttonStyle={isPC ? styles.answerTodayQuizPC : styles.answerTodayQuiz}
          titleStyle={styles.answerButtonTitle}
        />
      )}

      {isShowDailyQuiz && (
        <ModalContainer
          visible={isShowDailyQuiz}
          onDismiss={closeDailyQuizPopup}
        >
          <View
            style={
              isPC ? styles.dailyQuizContainer : styles.dailyQuizContainerMobile
            }
          >
            <DailyQuiz
              shouldHideMyQuiz={true}
              onPressDismissButton={closeDailyQuizPopup}
              emptyView={
                <DailyQuizEmptyView
                  isPC={isPC ?? false}
                  onPressDismissButton={closeDailyQuizPopup}
                />
              }
            />
          </View>
        </ModalContainer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  answerButtonTitle: {
    fontSize: FontSize.GENERAL,
  },
  answerTodayQuiz: {
    backgroundColor: color.primary,
    marginTop: 5,
    width: 160,
  },
  answerTodayQuizPC: {
    marginTop: 90,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    borderRadius: 48,
    borderWidth: 5,
    borderColor: color.secondary,
    justifyContent: 'center',
    height: 96,
    width: 96,
  },
  dailyQuizContainer: {
    maxWidth: 320,
    overflow: 'hidden',
  },
  dailyQuizContainerMobile: {
    maxWidth: Dimensions.get('window').width,
    overflow: 'hidden',
  },
  pointsMobile: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginTop: 10,
  },
  pointsPC: {
    color: color.secondary,
    fontSize: 37,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subTitlePointsPC: {
    textAlign: 'center',
    color: color.secondary,
    fontSize: FontSize.REMARKABLE,
  },
})

export default QuizPoints
