import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView } from 'react-native'

import Tag, { TagData } from '~/components/common/atoms/Tag'
import { ChannelViewMode } from '~/interfaces/Community'
import CommunityChannel from '~/interfaces/CommunityChannel'

type Props = {
  channelMode: ChannelViewMode
  currentChannel?: CommunityChannel
  onPressChannelMode?: (channelMode: ChannelViewMode) => void
  isPublic?: boolean
}

const ChannelHeaderForm: React.FC<Props> = ({
  channelMode,
  onPressChannelMode,
  isPublic,
}: Props) => {
  const { t } = useTranslation()
  const handlePressViewMode = (mode: ChannelViewMode): void => {
    mode !== channelMode && onPressChannelMode && onPressChannelMode(mode)
  }

  const getTagData = (mode: ChannelViewMode, name: string): TagData => {
    return {
      name: name,
      key: mode.toString(),
      isSelected: channelMode === mode,
    }
  }

  return (
    <>
      <ScrollView
        horizontal={true}
        style={styles.container}
        testID="channel-header-form"
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.button} testID="channel-header-timeline-button">
          <Tag
            data={getTagData(ChannelViewMode.Timeline, t('headerTab.timeline'))}
            onSelected={(): void =>
              handlePressViewMode(ChannelViewMode.Timeline)
            }
          />
        </View>
        {!isPublic && (
          <View
            style={styles.marginHorizontal}
            testID="channel-header-members-button"
          >
            <Tag
              data={getTagData(
                ChannelViewMode.Member,
                t('community.information.members')
              )}
              onSelected={(): void =>
                handlePressViewMode(ChannelViewMode.Member)
              }
            />
          </View>
        )}
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    marginRight: 6,
  },
  container: {
    flexDirection: 'row',
  },
  marginHorizontal: {
    marginLeft: 6,
    marginRight: 6,
  },
})

export default ChannelHeaderForm
