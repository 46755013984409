import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

const ListSeparator: React.FC = () => {
  return <View testID="list-separator" style={styles.separator} />
}

const styles = StyleSheet.create({
  separator: {
    backgroundColor: color.transparent,
    borderStyle: 'solid',
    borderTopColor: color.borderSeparator,
    borderTopWidth: 1,
    height: 1,
    width: '100%',
  },
})

export default ListSeparator
