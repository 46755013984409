import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { CompanyBodyParams } from '~/api/companies'
import CardContainer from '~/components/common/atoms/CardContainer'
import CompanyForm from '~/components/common/organisms/auth/CompanyForm'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { RootState } from '~/rootReducer'

type Props = {
  profileData: Partial<CompanyBodyParams>
  onNextColumn: () => void
  onUpdateRegistrationData: (data: Partial<CompanyBodyParams>) => void
}

const RegistrationCompanyColumn: React.FC<Props> = ({
  onNextColumn,
  profileData,
  onUpdateRegistrationData,
}: Props) => {
  const { t } = useTranslation()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const titleStyle = isPC ? styles.title : styles.titleMobile

  return (
    <CardContainer testID="registration-company-column" style={containerStyle}>
      <Text style={titleStyle}>{t('auth.fillInCompanyDetails')}</Text>
      <Text style={styles.subtitle}>{t('auth.fillInCompanyNote')}</Text>
      <CompanyForm
        isPC={isPC}
        registrationData={profileData}
        onUpdateRegistrationData={onUpdateRegistrationData}
        onNext={onNextColumn}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingHorizontal: 50,
    paddingVertical: 30,
    width: 700,
  },
  containerMobile: {
    flex: 1,
    padding: 10,
    marginTop: 10,
  },
  subtitle: {
    fontSize: FontSize.SUB,
    marginBottom: 30,
    marginLeft: 10,
  },
  title: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginLeft: 10,
    marginVertical: 10,
  },
  titleMobile: {
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
    marginLeft: 10,
  },
})

export default RegistrationCompanyColumn
