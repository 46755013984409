import React from 'react'

import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import UsersList from '~/components/common/features/users/UsersList'
import HeaderUsers from '~/components/common/organisms/users/HeaderUsers'
import useDevice from '~/hooks/commons/useDevice'

const UsersTemplate: React.FC = () => {
  const { isPC } = useDevice()

  return isPC ? (
    <InfiniteScrollContainer>
      <HeaderUsers isPC={true} />
      <UsersList />
    </InfiniteScrollContainer>
  ) : (
    <UsersList header={<HeaderUsers isPC={isPC} />} />
  )
}

export default UsersTemplate
