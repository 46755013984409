import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import IntroductionColumn from '~/components/common/features/IntroductionColumn'
import LandingBottomSection from '~/components/common/organisms/LandingBottomSection'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { RootState } from '~/rootReducer'
import { RootStackNavigationProp } from '~/utils/navigation'

const LandingTemplate: React.FC = () => {
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const topSectionStyles = isPC ? styles.pc : styles.pc
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const { t } = useTranslation()

  const goToLogin = () => navigate('login')

  return (
    <View style={styles.container} testID="landing-template">
      <View style={topSectionStyles}>
        <IntroductionColumn shouldHideImage={true} />
        <Button
          title={t('landing.getStarted')}
          containerStyle={styles.getStartedContainer}
          buttonStyle={styles.getStartedBtn}
          onPress={goToLogin}
        />
      </View>
      <LandingBottomSection isPC={isPC} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flex: 1,
  },
  getStartedBtn: {
    paddingHorizontal: 20,
  },
  getStartedContainer: {
    alignSelf: 'center',
    marginTop: 20,
  },
  pc: {
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 46,
    marginTop: 58,
  },
})

export default LandingTemplate
