import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import PostItemHeader from '~/components/career/molecules/post/PostItemHeader'
import DateText from '~/components/common/atoms/DateText'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { Fonts, FontSize } from '~/constants/common/font'
import Post from '~/interfaces/Post'
import { getCurrentLanguage, SupportedLanguage } from '~/plugins/i18n'
import {
  getFontWeightByLanguage,
  getMediumFontByLanguage,
} from '~/utils/common/themeConfig'

type Props = {
  post: Post
}

const SharedContentHeader: React.FC<Props> = ({ post }: Props) => {
  const { t } = useTranslation()
  const isJapanese = getCurrentLanguage() === SupportedLanguage.JA
  const forumStyle = StyleSheet.flatten([
    styles.forum,
    {
      fontWeight: getFontWeightByLanguage(getCurrentLanguage()),
      fontFamily: getMediumFontByLanguage(getCurrentLanguage()),
    },
  ])
  const forumLinkView = (
    <Link
      style={forumStyle}
      to={`/forum/questions`}
      testID="post-question-shared-from"
    >
      {t('post.postQuestionShareFrom')}
    </Link>
  )

  const shareContentHeaderTitle = {
    question: (
      <View style={styles.titleContainer} testID="title-container">
        <View style={styles.userNameContainer}>
          <UserName user={post.user} size="medium" />
        </View>
        {isJapanese && <Text>{t('post.postQuestionShareFromPrefix')}</Text>}
        {isJapanese && forumLinkView}
        <Text>{t('post.postQuestionSubtitle')}</Text>
        {!isJapanese && forumLinkView}
      </View>
    ),
    post: (
      <View style={styles.userNameContainer}>
        <UserName user={post.user} size="medium" />
      </View>
    ),
    answer: (
      <View style={styles.userNameContainer}>
        <UserName user={post.user} size="medium" />
      </View>
    ),
  }

  return (
    <View testID="shared-content-header" style={styles.container}>
      <PostItemHeader
        avatarView={
          <UserAvatar user={post.user} size={ProfileAvatarSize.MEDIUM} />
        }
        titleView={shareContentHeaderTitle[post.share?.type!]}
        subTitleView={
          <DateText date={post.createdAt} fontSize={FontSize.GENERAL} />
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  forum: {
    marginLeft: 4,
    color: color.pressableText,
    textDecorationLine: 'underline',
    fontFamily: Fonts.MediumFont,
  },
  titleContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  userNameContainer: {
    marginRight: 4,
  },
})

export default SharedContentHeader
