import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import HorizontalContainer from '~/components/common/atoms/HorizontalContainer'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import SkeletonAvatar from '~/components/common/atoms/SkeletonAvatar'
import SkeletonView from '~/components/common/atoms/SkeletonView'
import SkeletonTwoBar from '~/components/common/molecules/SkeletonTwoBar'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
}

const SkeletonItemWithCover: React.FC<Props> = ({ isPC }: Props) => (
  <CardContainer
    style={isPC ? styles.containerPC : styles.containerMobile}
    testID="skeleton-item-with-cover"
  >
    <Image
      source={{ uri: '' }}
      resizeMode="cover"
      style={isPC ? styles.coverPC : styles.coverMobile}
    />
    {isPC && <View style={styles.separator} />}
    <View
      style={
        isPC
          ? styles.rightColumn
          : StyleSheet.flatten([styles.rightColumn, { paddingHorizontal: 20 }])
      }
    >
      <View style={styles.firstLoadingBar}>
        <SkeletonTwoBar />
      </View>
      {isPC && <SkeletonTwoBar />}
      <HorizontalContainer>
        <SkeletonAvatar size={ProfileAvatarSize.TINY} />
        <SkeletonView style={styles.singleSkeletonView} />
      </HorizontalContainer>
    </View>
  </CardContainer>
)

const styles = StyleSheet.create({
  containerMobile: {
    backgroundColor: color.white,
    marginBottom: 15,
    width: '90%',
    height: 264,
  },
  containerPC: {
    backgroundColor: color.white,
    flexDirection: 'row',
    height: 190,
    marginBottom: 15,
    maxWidth: 900,
    paddingRight: 10,
    width: '100%',
    alignSelf: 'center',
  },
  coverMobile: {
    backgroundColor: color.gray,
    height: 135,
    width: '100%',
  },
  coverPC: {
    backgroundColor: color.gray,
    height: '100%',
    width: 240,
  },
  firstLoadingBar: {
    marginBottom: 16,
    width: '50%',
  },
  rightColumn: {
    flex: 1,
    paddingVertical: 10,
    justifyContent: 'space-between',
  },
  separator: {
    marginLeft: 20,
    marginBottom: 10,
  },
  singleSkeletonView: {
    marginLeft: 7,
    width: 100,
  },
})

export default SkeletonItemWithCover
