import { AntDesign } from '@expo/vector-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import QuizChoicesList from '~/components/career/molecules/quizzes/QuizChoisesList'
import QuizExplanation from '~/components/career/molecules/quizzes/QuizExplanation'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { quizChoiceLabels } from '~/interfaces/Quiz'
import QuizResult from '~/interfaces/QuizResult'

type Props = {
  quizResult: QuizResult
  myQuizButton?: React.ReactElement
}

const QuizResultChoicesList: React.FC<Props> = ({
  quizResult,
  myQuizButton,
}: Props) => {
  const { t } = useTranslation()
  const [isShowExplanation, setIsShowExplanation] = useState<boolean>(false)
  const labelIndex =
    quizChoiceLabels[quizResult.quiz.choices.indexOf(quizResult.choice)]

  return (
    <View testID="quiz-result-choices-list">
      {isShowExplanation ? (
        <>
          <QuizExplanation
            index={labelIndex}
            answer={quizResult?.choice}
            explanation={quizResult.quiz.explanation ?? ''}
            goBack={(): void => setIsShowExplanation(false)}
          />
          {myQuizButton}
        </>
      ) : (
        <QuizChoicesList
          disabled
          answer={quizResult.quiz.answer}
          choices={quizResult.quiz.choices}
          selectedItem={quizResult.choice}
        />
      )}
      {!isShowExplanation && quizResult && (
        <Button
          type="clear"
          iconRight={true}
          testID="explanation-button"
          title={t('quiz.explanation')}
          titleStyle={styles.explanation}
          style={styles.explanationContainer}
          onPress={(): void => setIsShowExplanation(true)}
          icon={<AntDesign name="caretright" size={12} color={color.white} />}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  explanation: {
    color: color.primary,
    fontSize: FontSize.SUB,
  },
  explanationContainer: {
    alignSelf: 'flex-end',
  },
})

export default QuizResultChoicesList
