import React, { useContext } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Image } from 'react-native-elements'

import CardContainer from '~/components/common/atoms/CardContainer'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  event: CommunityEvent
  eventInfoView?: React.ReactElement
  controlMenu?: React.ReactElement
}

const EventItem: React.FC<Props> = ({
  event,
  controlMenu,
  eventInfoView,
}: Props) => {
  const { deviceType, isPC } = useContext(RootContext)
  const avatarStyle = isPC ? stylesObj.avatar : stylesObj.avatarMobile
  const eventItemStyle = isPC
    ? { ...stylesObj.eventItem, ...stylesObj.eventItemPC }
    : stylesObj.eventItem
  const containerStyle = !isPC ? styles.mobileContainerStyle : undefined

  const eventNarrowImages = {
    realWorld: {
      chill: require('~/assets/images/communities/mobile/event_real_world_chill.png'),
      seminar: require('~/assets/images/communities/mobile/event_real_world_seminar.png'),
      discussion: require('~/assets/images/communities/mobile/event_real_world_discussion.png'),
    },
    virtual: {
      chill: require('~/assets/images/communities/mobile/event_virtual_chill.png'),
      seminar: require('~/assets/images/communities/mobile/event_virtual_seminar.png'),
      discussion: require('~/assets/images/communities/mobile/event_virtual_discussion.png'),
    },
  }

  const defaultImages = {
    pc: {
      realWorld: {
        chill: require('~/assets/images/communities/event_real_world_chill.png'),
        seminar: require('~/assets/images/communities/event_real_world_seminar.png'),
        discussion: require('~/assets/images/communities/event_real_world_discussion.png'),
      },
      virtual: {
        chill: require('~/assets/images/communities/event_virtual_chill.png'),
        seminar: require('~/assets/images/communities/event_virtual_seminar.png'),
        discussion: require('~/assets/images/communities/event_virtual_discussion.png'),
      },
    },
    mobile: eventNarrowImages,
    tablet: eventNarrowImages,
  }

  return (
    <View testID="event-item">
      <View style={styles.itemSeparator} />
      <CardContainer style={containerStyle}>
        <View style={eventItemStyle as ViewStyle} testID="event-item-info">
          <Image
            source={
              event?.cover?.presignedUrl
                ? { uri: event.cover.presignedUrl }
                : defaultImages[deviceType!][
                    event.isVirtual ? 'virtual' : 'realWorld'
                  ][event.eventType]
            }
            style={avatarStyle}
            width={avatarStyle.width}
            height={avatarStyle.height}
            placeholderStyle={styles.opacityZero}
          />
          {eventInfoView}
        </View>
      </CardContainer>
      <View
        style={[styles.controlMenu, !isPC && styles.controlMenuMobile]}
        testID="event-item-control-menu"
      >
        {controlMenu}
      </View>
    </View>
  )
}

const stylesObj = {
  avatar: {
    height: 158,
    width: 240,
  },
  avatarMobile: {
    height: 96,
    width: '100%',
  },
  eventItem: {
    backgroundColor: color.white,
  },
  eventItemPC: {
    flexDirection: 'row',
    height: 158,
    alignItems: 'center',
    paddingRight: 40,
  },
}

const styles = StyleSheet.create({
  controlMenu: {
    position: 'absolute',
    top: 30,
    right: 20,
  },
  controlMenuMobile: {
    marginHorizontal: 20,
  },
  itemSeparator: {
    marginTop: 15,
    marginHorizontal: 20,
  },
  mobileContainerStyle: {
    borderRadius: 10,
    marginHorizontal: 20,
  },
  opacityZero: {
    opacity: 0,
  },
})

export default EventItem
