import { useFocusEffect } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { FlatList, StyleSheet, View, ListRenderItem } from 'react-native'

import { API } from '~/api'
import CardContainer from '~/components/common/atoms/CardContainer'
import ListSeparator from '~/components/common/atoms/ListSeparator'
import TitleText from '~/components/common/atoms/TitleText'
import SuggestedJobsItem from '~/components/common/molecules/suggestedJobs/SuggestedJobsItem'
import color from '~/constants/common/color'
import useAPI from '~/hooks/useAPI'
import Recruitment from '~/interfaces/Recruitment'

export const keyExtractor = (job: Recruitment): string => job.id.toString()

export type Props = {
  isPC?: boolean
  title: string
}

const SuggestedJobs: React.FC<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedJobs, setSuggestedJobs] = useState<Recruitment[]>([])

  const fetchSuggestedJobs = useAPI(
    async (api: API): Promise<void> => {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      try {
        const response = await api.recruitments.fetchSuggestedJobs()
        setSuggestedJobs(response)
      } catch (err) {
        //NOTE not show toast error in home screen
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading]
  )

  useFocusEffect(
    useCallback(() => {
      fetchSuggestedJobs()
    }, [fetchSuggestedJobs])
  )

  const renderItem: ListRenderItem<Recruitment> = ({
    item,
  }: {
    item: Recruitment
  }) => <SuggestedJobsItem isPC={props.isPC} job={item} key={item.id} />

  return isEmpty(suggestedJobs) ? (
    <></>
  ) : (
    <CardContainer style={styles.container}>
      <TitleText style={styles.title}>{props.title}</TitleText>
      <View>
        <FlatList
          ItemSeparatorComponent={ListSeparator}
          keyExtractor={keyExtractor}
          data={suggestedJobs}
          renderItem={renderItem}
          testID="suggested-jobs-list"
        />
      </View>
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    paddingHorizontal: 25,
  },
  title: {
    color: color.unpressableTitleText,
    marginBottom: 7,
    marginTop: 20,
  },
})

export default SuggestedJobs
