import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import SearchInput from '~/components/common/atoms/SearchInput'
import ChannelUserAdditionItem from '~/components/community/molecules/ChannelUserAdditionItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { InvitationUser } from '~/interfaces/CommunityUser'
import { RootState } from '~/rootReducer'
import {
  addChannelMember,
  filterCommunityMemberByChannel,
} from '~/slices/community/community'

const ChannelAddMember: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [communityUsers, setCommunityUsers] = useState<InvitationUser[]>([])
  const community = useSelector((state: RootState) => state.community.current)
  const channel = useSelector(
    (state: RootState) => state.community.channel.current
  )

  const filterCommunityMembers = (): void => {
    const filterResults = (community?.communityMembers || [])
      .filter(
        (item) =>
          item.user.profile.givenName.indexOf(searchValue) >= 0 ||
          item.user.profile.familyName.indexOf(searchValue) >= 0
      )
      .map((item) => item.user)
    channel &&
      setCommunityUsers(filterCommunityMemberByChannel(filterResults, channel))
  }

  useEffect(() => {
    channel &&
      setCommunityUsers(
        filterCommunityMemberByChannel(
          (community?.communityMembers || []).map((item) => item.user),
          channel
        )
      )
  }, [channel])

  useEffect(() => {
    filterCommunityMembers()
  }, [searchValue, channel?.communityChannelMembers])

  const renderItem: ListRenderItem<InvitationUser> = ({
    item,
  }: {
    item: InvitationUser
  }) => (
    <ChannelUserAdditionItem
      user={item}
      isAddable={item.isJoined}
      addMember={(onAdded): void => {
        channel &&
          Promise.all([dispatch(addChannelMember(item, channel.id))]).finally(
            () => onAdded && onAdded()
          )
      }}
    />
  )

  const keyExtractor = (item: InvitationUser): string => item.id.toString()

  return (
    <View testID="channel-add-member" style={styles.container}>
      <Text style={styles.title}>{t('community.addMembers')}</Text>
      <Text style={styles.subTitle}>{t('community.channel.name')}</Text>
      <SearchInput
        value={searchValue}
        onChangeText={setSearchValue}
        placeholderKey={t('community.searchFriendPlaceholder')}
      />
      <Text style={styles.subTitle}>{t('community.selectFriends')}</Text>
      <FlatList
        data={communityUsers}
        renderItem={renderItem}
        style={styles.userList}
        keyExtractor={keyExtractor}
        testID="community-user-list"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subTitle: {
    color: color.textGray,
    fontSize: FontSize.REMARKABLE,
    marginBottom: 4,
    marginTop: 10,
  },
  title: {
    color: color.unpressableTitleText,
    fontSize: FontSize.IMPORTANT,
  },
  userList: {
    borderColor: color.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
})
export default ChannelAddMember
