import { FontAwesome as Icon } from '@expo/vector-icons'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { CheckBox } from 'react-native-elements'

import MonthPicker from '~/components/common/atoms/MonthPicker'
import YearPicker from '~/components/common/atoms/YearPicker'
import TextField from '~/components/common/molecules/TextField'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import WorkExperience from '~/interfaces/WorkExperience'

type Props = {
  index: number
  workExperience: Partial<WorkExperience>
  onChange: (value: Partial<WorkExperience>, index: number) => void
  onPressClose: () => void
}

const WorkExperienceForm: React.FC<Props> = ({
  index,
  onChange,
  workExperience,
  onPressClose,
}: Props) => {
  const { t } = useTranslation()

  const startDate = workExperience.startDate
    ? new Date(workExperience.startDate || '')
    : undefined

  const endDate = workExperience.endDate
    ? new Date(workExperience.endDate || '')
    : undefined

  const [isStudyingHere, setIsStudyingHere] = useState(false)
  const [isHideEndDate, setIsHideEndDate] = useState(false)

  const [startMonth, setStartMonth] = useState<number | undefined>(
    startDate ? startDate.getMonth() + 1 : undefined
  )
  const [startYear, setStartYear] = useState<number | undefined>(
    startDate?.getFullYear() || undefined
  )

  const [endMonth, setEndMonth] = useState<number | undefined>(
    endDate ? endDate.getMonth() + 1 : undefined
  )
  const [endYear, setEndYear] = useState<number | undefined>(
    endDate?.getFullYear() || undefined
  )

  const changeCompany = (value: string): void => {
    onChange({ companyName: value }, index)
  }

  const changePosition = (value: string): void => {
    onChange({ position: value }, index)
  }

  const changeStartYear = (year: number): void => {
    const newYear = year != 0 ? year : undefined
    setStartYear(newYear)
    onChange(
      {
        startDate: isNil(newYear)
          ? undefined
          : startMonth
          ? new Date(newYear, startMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeStartMonth = (month: number): void => {
    const newMonth = month != 0 ? month : undefined
    setStartMonth(newMonth)
    onChange(
      {
        startDate: isNil(newMonth)
          ? undefined
          : startYear
          ? new Date(startYear, newMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeEndYear = (year: number): void => {
    const newYear = year != 0 ? year : undefined
    setEndYear(newYear)
    onChange(
      {
        endDate: isNil(newYear)
          ? undefined
          : endMonth
          ? new Date(newYear, endMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeEndMonth = (month: number): void => {
    const newMonth = month != 0 ? month : undefined
    setEndMonth(newMonth)
    onChange(
      {
        endDate: isNil(newMonth)
          ? undefined
          : endYear
          ? new Date(endYear, newMonth, 0).toDateString()
          : undefined,
      },
      index
    )
  }

  const changeResponsibilities = (value: string) => {
    onChange({ responsibilities: value }, index)
  }

  const changeStudyingHere = () => {
    const newIsStudyingHere = !isStudyingHere
    setIsStudyingHere(newIsStudyingHere)
    setIsHideEndDate(newIsStudyingHere)
    const newEndDate =
      !newIsStudyingHere && endYear && endMonth
        ? new Date(endYear, endMonth, 0).toDateString()
        : ''
    onChange(
      {
        endDate: newEndDate,
        isStudyingHere: newIsStudyingHere,
      },
      index
    )
  }

  useEffect(() => {
    setIsHideEndDate(workExperience.isStudyingHere ?? false)
    setIsStudyingHere(workExperience.isStudyingHere ?? false)
  }, [])

  return (
    <View style={styles.container}>
      {index !== 0 && (
        <TouchableOpacity
          onPress={onPressClose}
          style={styles.closeButton}
          testID={'work-experience-close-button'}
        >
          <Icon size={20} name="close" color={color.primary} />
        </TouchableOpacity>
      )}
      <View testID="work-experience-form">
        <TextField
          title={`${t('profile.companyName')}*`}
          onChangeText={changeCompany}
          value={workExperience.companyName}
        />
        <TextField
          title={`${t('profile.position')}*`}
          onChangeText={changePosition}
          value={workExperience.position}
        />
        <CheckBox
          checked={isStudyingHere}
          onPress={changeStudyingHere}
          textStyle={styles.textCheckBox}
          wrapperStyle={styles.checkBoxWrapper}
          containerStyle={styles.checkBoxContainer}
          title={t('profile.isCurrentlyStillWorkingHere')}
        />
        <View style={styles.timeGroup}>
          <MonthPicker
            onChange={changeStartMonth}
            selectedValue={startMonth}
            title={`${t('profile.startMonth')}*`}
          />
          <View style={styles.separator} />
          <YearPicker
            onChange={changeStartYear}
            selectedValue={startYear}
            title={`${t('profile.startYear')}*`}
          />
        </View>
        <View
          style={[
            styles.timeGroup,
            isHideEndDate ? styles.hideEndDate : styles.showEndDate,
          ]}
          testID="end-date-view"
        >
          <MonthPicker
            selectedValue={endMonth}
            onChange={changeEndMonth}
            title={`${t('profile.endMonth')}*`}
          />
          <View style={styles.separator} />
          <YearPicker
            selectedValue={endYear}
            onChange={changeEndYear}
            title={`${t('profile.endYear')}*`}
          />
        </View>
        <TextField
          numberOfLines={4}
          title={`${t('profile.businessContent')}`}
          onChangeText={changeResponsibilities}
          value={workExperience.responsibilities}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    borderColor: color.transparent,
    backgroundColor: color.transparent,
    borderWidth: 0,
    marginLeft: 0,
    padding: 0,
    alignSelf: 'baseline',
  },
  checkBoxWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    marginBottom: 40,
  },
  hideEndDate: {
    display: 'none',
  },
  separator: {
    width: 40,
  },
  showEndDate: {
    display: 'flex',
  },
  textCheckBox: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    fontWeight: '400',
  },
  timeGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
})

export default WorkExperienceForm
