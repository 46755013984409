import { useNavigation } from '@react-navigation/native'
import React, { useState, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

import EditAvatar from '~/components/common/atoms/EditAvatar'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import ProfileAvatar, {
  ProfileAvatarSize,
} from '~/components/common/atoms/ProfileAvatar'
import MediaBox from '~/components/common/molecules/media/MediaBox'
import color from '~/constants/common/color'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'
import { RootStackNavigationProp } from '~/utils/navigation'

//TODO fix me by remove UserAvatarSize and use ProfileAvatarSize
export enum UserAvatarSize {
  GIGANTIC = 125,
  HUGE = 78,
  LARGE = 54,
  MEDIUM = 50,
  SMALL = 45,
  TINY = 36,
}

type Props = {
  user?: User
  editable?: boolean
  viewable?: boolean
  showBorder?: boolean
  size: ProfileAvatarSize
  onUpload?: (media: Media[]) => void
}

const UserAvatar: React.FC<Props> = ({
  user,
  size,
  editable,
  onUpload,
  viewable,
  showBorder,
}: Props) => {
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const uri = user?.profile?.image?.urls?.small
  const uriOriginal = user?.profile?.image?.urls?.original
  const avatar = uri ? { uri } : require('~/assets/images/user_default.png')

  const [isShowAvatar, setIsShowAvatar] = useState(false)

  const navigateToUserPage = (): void => {
    user && navigate('userDetail', { id: user.id })
  }

  const dismissModal = useCallback(() => {
    setIsShowAvatar(false)
  }, [isShowAvatar])

  return (
    <>
      <View testID="user-avatar">
        <ProfileAvatar
          size={size}
          source={avatar}
          hasBorder={showBorder}
          onPress={(): void =>
            viewable ? setIsShowAvatar(true) : navigateToUserPage()
          }
        />
        {editable && (
          <View
            style={StyleSheet.flatten([
              {
                width: size,
                height: size,
                borderRadius: size,
              },
              styles.crop,
            ])}
          >
            <EditAvatar onUpload={onUpload} />
          </View>
        )}
      </View>
      {isShowAvatar && uriOriginal && (
        <ModalContainer visible={isShowAvatar} onDismiss={dismissModal}>
          <View style={styles.modalContent} testID="original-avatar">
            <MediaBox uri={uriOriginal} />
          </View>
        </ModalContainer>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  crop: {
    overflow: 'hidden',
    position: 'absolute',
    borderWidth: 4,
    borderColor: color.white,
  },
  modalContent: {
    backgroundColor: color.black,
    height: '72%',
    width: '76%',
  },
})

export default UserAvatar
