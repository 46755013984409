import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { filter, ListIterateeCustom } from 'lodash'

import api from '~/api'
import { BaseQueryParams } from '~/api/baseAPI'
import { UnreadCount } from '~/api/notifications'
import Notification from '~/interfaces/Notification'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

export const notificationsAdapter = createEntityAdapter<Notification>()

type NotificationsState = {
  unreadCount: UnreadCount
  isLoading: boolean
  error: string | null
}

const initialState = {
  unreadCount: {
    unreadMessagesCount: 0,
    unreadNotificationsCount: 0,
    total: 0,
  },
  isLoading: false,
  error: null,
} as NotificationsState

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState(initialState),
  reducers: {
    setAll: notificationsAdapter.setAll,
    updateOne: notificationsAdapter.updateOne,
    requestStart(state): void {
      state.isLoading = true
      state.error = null
    },
    requestSuccess(state): void {
      state.isLoading = false
    },
    requestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
    addUnreadNotificationCount(state, action: PayloadAction<number>): void {
      state.unreadCount.unreadNotificationsCount += action.payload
    },
    setMessageUnreadCount(state, action: PayloadAction<number>): void {
      state.unreadCount.unreadMessagesCount = action.payload
    },
    setUnreadCount(state, action: PayloadAction<UnreadCount>): void {
      state.unreadCount = action.payload
    },
    reset(state): void {
      state.ids = notificationsAdapter.getInitialState(initialState).ids
      state.error = notificationsAdapter.getInitialState(initialState).error
      state.entities = notificationsAdapter.getInitialState(
        initialState
      ).entities
      state.isLoading = notificationsAdapter.getInitialState(
        initialState
      ).isLoading
      state.unreadCount = notificationsAdapter.getInitialState(
        initialState
      ).unreadCount
    },
  },
})

export const {
  reset,
  setAll,
  updateOne,
  requestStart,
  requestSuccess,
  requestFailure,
  setUnreadCount,
  setMessageUnreadCount,
  addUnreadNotificationCount,
} = notificationsSlice.actions

export const getAllNotifications = (state: RootState): Notification[] =>
  notificationsAdapter.getSelectors().selectAll(state.notifications)

export const getUnreadCount = (state: RootState): UnreadCount =>
  state.notifications.unreadCount

export const fetchNotifications = (
  predicate?: ListIterateeCustom<Notification, boolean>
): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.notifications.index<
      Notification[],
      BaseQueryParams
    >({})
    const notifications = filter(response, predicate)
    const unreadNotification = filter(notifications, (n) => !n.wasAlreadyRead)
    dispatch(setAll(notifications))
    const unreadCount = getState().notifications.unreadCount
    dispatch(
      setUnreadCount({
        ...unreadCount,
        unreadNotificationsCount: unreadNotification.length,
      })
    )
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(err))
  }
}

export const fetchNotificationUnreadCount = (): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.notifications.getUnreadCount()
    dispatch(setUnreadCount(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(err))
  }
}

export const markNotificationAsRead = (id: number): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    await api.notifications.check({ id })
    dispatch(
      updateOne({
        id,
        changes: { wasAlreadyRead: true },
      })
    )
    dispatch(addUnreadNotificationCount(-1))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(err))
  }
}

export const selectMessageUnReadCount = (
  state: RootState
): NotificationsState => state.notifications
export const messageUnreadCountSelector = createSelector(
  selectMessageUnReadCount,
  (res: NotificationsState) => res.unreadCount.unreadMessagesCount
)

export default notificationsSlice.reducer
