import Achievement from '~/interfaces/Achievement'
import BaseHttpData from '~/interfaces/BaseHttpData'
import Education from '~/interfaces/Education'
import Media from '~/interfaces/Media'
import ResumeLanguage from '~/interfaces/ResumeLanguage'
import Skill from '~/interfaces/Skill'
import WorkExperience from '~/interfaces/WorkExperience'

export enum TemplateType {
  Enjin = 'enjin',
  Shades = 'shades',
  Formal = 'formal',
  Minimal = 'minimal',
}

export default interface Resume extends BaseHttpData {
  title: string
  userId: number
  email: string
  isMain: boolean
  templateType: TemplateType
  familyName: string
  givenName: string
  currentPosition: string
  headline: string
  phone: string
  address: string
  webSite: string
  updatingSkill: string
  updatingSkillAssessment: number
  teamworkSkill: string
  teamworkSkillAssessment: number
  conceptionSkill: string
  conceptionSkillAssessment: number
  realizationSkill: string
  realizationSkillAssessment: number
  interestedIndustry: string
  interestedIndustryReason: string
  interestedJob: string
  interestedJobReason: string
  evaluator: string
  relationshipWithMe: string
  evaluatorComment: string
  vision: string
  skillVision: string
  activitiesVision: string
  workExperiences: Partial<WorkExperience>[]
  educations: Partial<Education>[]
  achievements: Partial<Achievement>[]
  languages: Partial<ResumeLanguage>[]
  skills: Partial<Skill>[]
  image: Media
  imageId: number
  currentOrganisation: string
  language: string
}
