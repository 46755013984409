import React from 'react'

import ChannelControlMenuMobile from '~/components/community/molecules/ChannelControlMenu/ChannelControlMenuMobile'
import ChannelControlMenuPC from '~/components/community/molecules/ChannelControlMenu/ChannelControlMenuPC'
import { ChannelControlMenuProps } from '~/interfaces/ChannelControlMenu'

const ChannelControlMenu: React.FC<ChannelControlMenuProps> = (
  props: ChannelControlMenuProps
) =>
  props.isPC ? (
    <ChannelControlMenuPC {...props} />
  ) : (
    <ChannelControlMenuMobile {...props} />
  )

export default ChannelControlMenu
