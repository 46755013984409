import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'

type Props = {
  type: 'comment' | 'answer'
  value: number
  onPress?: () => void
}

const CommentCount: React.FC<Props> = ({ onPress, value, type }: Props) => {
  const { t } = useTranslation()
  const countLabel =
    type === 'answer'
      ? t('forum.answerCountLabel', {
          pluralCharacter: value > 1 ? 's' : '',
        })
      : t('comment.commentCountLabel', {
          pluralCharacter: value > 1 ? 's' : '',
        })

  return (
    <TouchableOpacity
      onPress={onPress}
      testID="comment-count"
      style={styles.container}
    >
      {/* TODO: if value >= 1000, format me: 1k, 1k2, etc. */}
      <Text testID="comment-count-value" style={styles.commentCountValue}>
        {value}
      </Text>
      <Text testID="comment-count-label" style={styles.commentCountValue}>
        {countLabel}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  commentCountValue: {
    color: color.textGray,
    fontSize: FontSize.GENERAL,
    marginHorizontal: 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default CommentCount
