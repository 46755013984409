import { range, uniqueId } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import AchievementForm from '~/components/career/molecules/resume/AchievementForm'
import FormLayout from '~/components/career/molecules/resume/FormLayout'
import color from '~/constants/common/color'
import Achievement from '~/interfaces/Achievement'
import { resumeLimitation } from '~/utils/career/resume'

type Props = {
  isPC: boolean
  onChange: (achievement: Partial<Achievement>[]) => void
  achievements: Partial<Achievement & { key?: string }>[]
}

const AchievementFormList: React.FC<Props> = ({
  isPC,
  onChange,
  achievements,
}: Props) => {
  const { t } = useTranslation()
  const newItemIndex = achievements.findIndex((item) => {
    return !item.id
  })

  const handleChangeAchievementValue = (
    achievement: Partial<Achievement>,
    index: number
  ): void => {
    const newAchievement = { ...achievements[index], ...achievement }
    const newAchievements = achievements.slice()
    newAchievements.splice(index, 1, newAchievement)
    onChange(newAchievements)
  }

  const addNewAchievementForm = (): void => {
    onChange(achievements.concat([{ key: `${uniqueId('achievement-')}` }]))
  }

  const removeForm = (index: number): void => {
    const newAchievements = achievements.slice()
    newAchievements.splice(index, 1)
    onChange(newAchievements)
  }

  const achievementForms = range(achievements.length).map((index) => {
    const achievementKey =
      achievements[index].id !== undefined
        ? `${achievements[index].id}`
        : `${achievements[index].key}`

    return (
      <View key={achievementKey}>
        {index === newItemIndex && <View style={styles.separator} />}
        <AchievementForm
          index={index}
          achievement={achievements[index]}
          onChange={handleChangeAchievementValue}
          onPressClose={() => {
            removeForm(index)
          }}
        />
      </View>
    )
  })

  return (
    <FormLayout
      title={t('profile.achievement')}
      hasAddAction={achievements.length < resumeLimitation.achivement}
      isPC={isPC}
      onAddPress={addNewAchievementForm}
    >
      <View style={styles.formContainer}>{achievementForms}</View>
    </FormLayout>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    paddingBottom: 20,
    paddingTop: 10,
  },
  separator: {
    backgroundColor: color.borderSeparator,
    height: 1,
    marginBottom: 20,
  },
})

export default AchievementFormList
