import { MaterialIcons } from '@expo/vector-icons'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

export enum MessageFilterMenuType {
  All = 'all',
  Unread = 'unread',
  Read = 'read',
}

type Props = {
  onSelect: (type: MessageFilterMenuType) => void
}

const MessageFilter: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<MessageFilterMenuType>(
    MessageFilterMenuType.All
  )

  const options: ControlMenuData[] = [
    {
      id: MessageFilterMenuType.All,
      title: t('message.filter.allMessages'),
    },
    {
      id: MessageFilterMenuType.Unread,
      title: t('message.filter.unread'),
    },
    {
      id: MessageFilterMenuType.Read,
      title: t('message.filter.read'),
    },
  ]

  const selectFilterItem = (item: ControlMenuData): void => {
    setSelected(item.id as MessageFilterMenuType)
    onSelect(item.id as MessageFilterMenuType)
  }

  const selectedOption = options.find((option) => option.id === selected)

  const visibleOptions =
    selected === MessageFilterMenuType.All
      ? options
      : options.filter((item) => item.id !== selected)

  return (
    <View style={styles.container} testID="message-filter">
      <ControlMenu
        data={visibleOptions}
        menuTrigger={
          <View style={styles.filterMenu}>
            <Text>{selectedOption?.title}</Text>
            <MaterialIcons name="arrow-drop-down" size={15} />
          </View>
        }
        onSelect={selectFilterItem}
        preferredPlacement={'bottom'}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderColor: color.primary,
    borderRadius: 20,
    borderWidth: 1,
    flex: 1,
  },
  filterMenu: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 1,
  },
})

export default MessageFilter
