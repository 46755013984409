import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'

import SearchInput from '~/components/common/atoms/SearchInput'
import { TagData } from '~/components/common/atoms/Tag'
import TitleText from '~/components/common/atoms/TitleText'
import TagList from '~/components/common/molecules/search/TagList'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import { DrawerSearchState, SearchCategory } from '~/slices/common/drawerSearch'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isMobile: boolean
  currentSearch: DrawerSearchState
  updateCurrentSearch: (searchData: Partial<DrawerSearchState>) => void
}

const SearchDrawer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()
  const { isMobile, currentSearch, updateCurrentSearch } = props
  const containerStyle = isMobile ? styles.containerMobile : styles.container
  const tags = ['users', 'jobs'].map((category) => {
    return {
      key: category,
      name: t(`search.${category}`),
      isSelected: currentSearch.category === category,
    } as TagData
  })
  tags.push({ key: 'fix_grid_2_col', isHidden: true } as TagData)
  const [searchData, setSearchData] = useState<DrawerSearchState>({
    ...currentSearch,
  })
  const [categories] = useState<TagData[]>(tags)
  const selectCategory = (tag: TagData, isSelected: boolean): void => {
    categories.forEach((t: TagData) => {
      t.isSelected = t.key === tag.key ? isSelected : false
    })
    setSearchData({
      ...searchData,
      category: isSelected ? (tag.key as SearchCategory) : undefined,
    })
  }

  const startSearching = (): void => {
    updateCurrentSearch(searchData)
    switch (searchData.category) {
      case 'users':
        navigate(searchData.category, { name: searchData.keyword })
        break
      case 'jobs':
        navigate(searchData.category, { q: searchData.keyword })
        break
    }
  }

  const updateSearchKeyWord = (keyword: string): void => {
    setSearchData({ ...searchData, keyword })
  }

  const placeholderKey = {
    users: 'search.searchUsersPlaceholder',
    jobs: 'search.searchJobsPlaceholder',
  }

  return (
    <ScrollView testID="search-drawer" style={containerStyle}>
      {!isMobile && (
        <TitleText style={styles.title} testID="search-drawer-title">
          {t('search.search')}
        </TitleText>
      )}
      <View>
        <Text style={styles.subTitle} testID="category-search-title">
          {t('search.categorySearch')}
        </Text>
        <TagList
          isPC={!isMobile}
          tags={categories}
          onItemSelected={selectCategory}
          numColumns={2}
        />
      </View>
      {searchData.category && (
        <View style={styles.searchContainer}>
          <SearchInput
            inverted
            onSubmit={startSearching}
            value={searchData.keyword}
            onChangeText={updateSearchKeyWord}
            placeholderKey={
              placeholderKey[searchData.category as 'users' | 'jobs']
            }
          />
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    paddingHorizontal: 25,
    width: '100%',
  },
  containerMobile: {
    backgroundColor: color.primary,
    paddingHorizontal: 25,
    paddingTop: 42,
  },
  searchContainer: {
    marginTop: 30,
  },
  subTitle: {
    color: color.white,
    fontSize: FontSize.GENERAL,
    paddingBottom: 15,
    paddingTop: 5,
  },
  title: {
    color: color.white,
    marginTop: 42,
  },
})

export default SearchDrawer
