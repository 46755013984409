import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Badge } from 'react-native-elements'
import { format } from 'timeago.js'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import Notification from '~/interfaces/Notification'
import i18n from '~/plugins/i18n'

type Props = {
  isPC: boolean
  notification: Notification
}

const NotificationItem: React.FC<Props> = ({ isPC, notification }: Props) => {
  const readContainerStyle = isPC ? styles.container : styles.containerMobile
  const unReadContainerStyle = StyleSheet.flatten([
    readContainerStyle,
    { backgroundColor: color.primaryLight },
  ])
  const containerStyle = notification.wasAlreadyRead
    ? readContainerStyle
    : unReadContainerStyle
  const contentStyle = isPC ? styles.content : styles.contentMobile
  const badgeContainerStyle = isPC
    ? styles.badgeContainer
    : styles.badgeContainerMobile
  const badgeStyle = isPC ? styles.badge : styles.badgeMobile
  const dateStyle = isPC ? styles.date : styles.dateMobile
  const createdAt = new Date(notification.date)

  return (
    <View style={containerStyle} testID="container">
      {!notification.wasAlreadyRead && (
        <View style={badgeContainerStyle} testID="badge">
          <Badge
            status="error"
            value=""
            badgeStyle={StyleSheet.flatten(badgeStyle)}
          />
        </View>
      )}
      <View style={styles.avatar}>
        <UserAvatar
          size={isPC ? ProfileAvatarSize.LARGE : ProfileAvatarSize.MEDIUM}
          user={notification.originUser}
        />
      </View>
      <View style={contentStyle}>
        <UserName user={notification.originUser} size="medium" />
        <Text style={styles.body}>
          {notification.body?.replace('「', `'`).replace('」', `'`)}
        </Text>
        <Text style={dateStyle}>{format(createdAt, i18n.language)}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  avatar: {
    alignSelf: 'center',
  },
  badge: {
    backgroundColor: color.badgeColor,
    borderRadius: 10,
    height: 14,
    width: 14,
    ...commonStyles.shadow,
  },
  badgeContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    left: 24,
    position: 'absolute',
  },
  badgeContainerMobile: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    left: 15,
    position: 'absolute',
  },
  badgeMobile: {
    backgroundColor: color.badgeColor,
    borderRadius: 10,
    height: 10,
    width: 10,
  },
  body: {
    paddingVertical: 4,
    fontSize: FontSize.GENERAL,
  },
  container: {
    backgroundColor: color.white,
    flexDirection: 'row',
    paddingLeft: 53,
    paddingRight: 24,
    paddingVertical: 10,
  },
  containerMobile: {
    backgroundColor: color.white,
    flexDirection: 'row',
    paddingHorizontal: 32,
    paddingVertical: 10,
  },
  content: {
    flex: 1,
    marginLeft: 17,
    marginRight: 13,
  },
  contentMobile: {
    flex: 1,
    marginLeft: 13,
    marginRight: 15,
  },
  date: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    paddingVertical: 3,
  },
  dateMobile: {
    color: color.textGray,
    fontSize: FontSize.SUB,
    lineHeight: 13,
    paddingBottom: 3,
    paddingTop: 1,
  },
})
export default NotificationItem
