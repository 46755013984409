import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'

type Props = {
  isPC: boolean
  title?: string
  content?: string
  subTitle?: string
  otherContent?: JSX.Element
}

const BasicInfoItem: React.FC<Props> = ({
  isPC,
  title,
  content,
  otherContent,
  subTitle,
}: Props) => {
  const containerStyle = isPC ? styles.pc : styles.mobile
  const contentStyle = isPC ? styles.contentPC : styles.contentMobile

  return (
    <>
      <View style={containerStyle} testID="community-basic-info-item">
        <Text style={styles.title}>
          {subTitle ? `${title} ${subTitle}` : title}
        </Text>
        <Text style={contentStyle} testID="community-basic-info-item-content">
          {content}
        </Text>
      </View>
      <View
        test-id="community-basic-info-item-other-content"
        style={styles.contentPC}
      >
        {React.Children.map(otherContent, (child) => {
          return child
        })}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  contentMobile: {
    color: color.textGray,
    paddingBottom: 4,
    paddingTop: 4,
  },
  contentPC: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  mobile: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pc: {
    flex: 1,
  },
  title: {
    color: color.unpressableTitleText,
    fontWeight: 'bold',
  },
})

export default BasicInfoItem
