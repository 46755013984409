import { last } from 'lodash'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import User from '~/interfaces/User'

type Props = {
  users: User[]
}

const AvatarGroup: React.FC<Props> = ({ users }: Props) => {
  const numberOfDisplayAvatar = 2

  return (
    <View style={styles.container} testID="avatar-group">
      {users.length === 1 ? (
        <UserAvatar
          showBorder
          user={last(users)}
          size={ProfileAvatarSize.MEDIUM}
        />
      ) : (
        users.slice(0, numberOfDisplayAvatar).map((user, index) => (
          <View
            key={user.id}
            testID="user-avatar-container"
            style={index % 2 === 0 ? styles.evenItem : styles.oddItem}
          >
            <UserAvatar user={user} size={ProfileAvatarSize.TINY} showBorder />
          </View>
        ))
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
  evenItem: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  oddItem: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
})

export default AvatarGroup
