import { BaseAPI, BaseQueryParams } from '~/api/baseAPI'

export enum EventFilterKeys {
  ALL_EVENT = '',
  VIRTUAL_EVENT = 'virtual',
  REAL_WORLD_EVENT = 'real-world',
}

export type EventQueryParams = {
  page?: number
  perPage?: number
  filterBy?: EventFilterKeys
}

export type EventQueryApiParams = {
  isVirtual?: string
} & BaseQueryParams

export class CommunityEventsAPI extends BaseAPI {
  configPath(communityId: number): CommunityEventsAPI {
    this.path = `/communities/${communityId}/events`
    return this
  }
}
