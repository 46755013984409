import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import User from '~/interfaces/User'

type Props = {
  user: User
  isQuestioner: boolean
}

const QuestionUserAvatar: React.FC<Props> = ({ isQuestioner, user }: Props) => {
  return (
    <View testID="forum-user-avatar">
      <UserAvatar user={user} size={ProfileAvatarSize.TINY} />
      <View
        testID="ballon-container"
        style={StyleSheet.flatten([
          styles.ballonContainer,
          isQuestioner ? styles.questionBallon : styles.answerBallon,
        ])}
      >
        <Text style={styles.text} testID="ballon-text">
          {isQuestioner ? 'Q' : 'A'}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  answerBallon: {
    backgroundColor: color.primary,
  },
  ballonContainer: {
    alignItems: 'center',
    borderRadius: 10,
    bottom: 0,
    height: 18,
    justifyContent: 'center',
    position: 'absolute',
    right: -8,
    width: 18,
  },
  questionBallon: {
    backgroundColor: color.secondary,
    bottom: 20,
  },
  text: { color: color.white, fontSize: 10 },
})

export default QuestionUserAvatar
