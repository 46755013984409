import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import { API } from '~/api'
import Button from '~/components/workarounds/Button'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import { reset } from '~/slices/common/notifications'

type Props = {
  onCheckAll?: () => void
}

const NotificationsCheckAllButton: React.FC<Props> = ({
  onCheckAll,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toast = useCustomToast()

  const checkAll = useAPI(
    async (api: API): Promise<void> => {
      try {
        await api.notifications.checkAll()
        dispatch(reset())
        onCheckAll && onCheckAll()
      } catch (error) {
        toast.showError(error)
      }
    }
  )

  const acceptCheckAllNotification = (): void => {
    checkAll()
  }

  return (
    <View testID="notifications-check-all-button">
      <Button
        type="clear"
        titleStyle={styles.markAllAsRead}
        title={t('notification.markAllAsRead')}
        onPress={acceptCheckAllNotification}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  markAllAsRead: {
    color: color.pressableText,
    ...commonStyles.buttonTextSize,
  },
})

export default NotificationsCheckAllButton
