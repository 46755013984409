import React from 'react'
import { StyleSheet, View } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  index: number
  isPC?: boolean
}

const RegistrationIndicator: React.FC<Props> = (props: Props) => {
  const isPC = props.isPC
  const elementStyle = isPC ? styles.element : styles.elementMobile
  const firstActiveIndicatorStyle = props.index === 0 ? styles.active : {}
  const secondActiveIndicatorStyle = props.index === 1 ? styles.active : {}

  return (
    <View style={styles.container} testID="registration-indicator">
      <View
        testID="registration-indicator-first"
        style={StyleSheet.flatten([elementStyle, firstActiveIndicatorStyle])}
      ></View>
      <View
        style={StyleSheet.flatten([elementStyle, secondActiveIndicatorStyle])}
        testID="registration-indicator-second"
      ></View>
    </View>
  )
}

const styles = StyleSheet.create({
  active: {
    backgroundColor: color.primary,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  element: {
    backgroundColor: color.gray,
    height: 8,
    marginEnd: 4,
    marginStart: 4,
    marginTop: 20,
    width: 168,
  },
  elementMobile: {
    backgroundColor: color.gray,
    height: 6,
    marginEnd: 4,
    marginStart: 4,
    marginTop: 20,
    width: 118,
  },
})

export default RegistrationIndicator
