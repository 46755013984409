import { useRoute } from '@react-navigation/native'
import { includes, some } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import CardContainer from '~/components/common/atoms/CardContainer'
import CheckBoxList, {
  CheckBoxListData,
} from '~/components/common/organisms/CheckBoxList'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import { getCategoriesFromRoute } from '~/utils/navigation'

type QueryParams = {
  categories?: string
}

type Props = {
  categoryListData: CheckBoxListData[]
}

const CategoriesFilter: React.FC<Props> = ({ categoryListData }) => {
  const route = useRoute()
  const { setQueryParams } = useCustomNavigation()
  const categoriesQueryParams = getCategoriesFromRoute(
    (route.params as QueryParams)?.categories
  )
  const { t } = useTranslation()

  const [selectedCategories, setSelectedCategories] = useState<
    CheckBoxListData[]
  >([])

  const initSelectedCategories = (): void => {
    setSelectedCategories(
      categoryListData.filter((category) =>
        includes(categoriesQueryParams, category.value)
      )
    )
  }

  const updateSelectedCategories = (item: CheckBoxListData): void => {
    some(selectedCategories, item)
      ? unselectCategory(item)
      : selectCategory(item)
  }

  const unselectCategory = (item: CheckBoxListData): void => {
    const categories = selectedCategories.filter(
      (category) => item.value !== category.value
    )
    setSelectedCategories(categories)
    updateRouteParams(categories)
  }

  const selectCategory = (item: CheckBoxListData): void => {
    const categories = [...selectedCategories, item]
    setSelectedCategories(categories)
    updateRouteParams(categories)
  }

  const updateRouteParams = (data: CheckBoxListData[]): void => {
    const categoriesParam =
      data.length > 0 ? data.map((item) => item.value) : undefined
    setQueryParams({ categories: categoriesParam })
  }

  useEffect(() => {
    initSelectedCategories()
  }, [route.params])

  return (
    <CardContainer style={styles.container} testID="categories-filter">
      <CheckBoxList
        data={categoryListData}
        title={t('categories')}
        selectedItems={selectedCategories}
        onSelectItem={updateSelectedCategories}
      />
    </CardContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
    overflow: 'hidden',
    width: 200,
  },
})

export default CategoriesFilter
