import React from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  likeCountComponent?: React.ReactElement
  sharedCountComponent?: React.ReactElement
  commentCountComponent?: React.ReactElement
}

const SocialCount: React.FC<Props> = ({
  likeCountComponent,
  sharedCountComponent,
  commentCountComponent,
}: Props) => (
  <View style={styles.container} testID="social-count">
    <View style={styles.likesContainer}>{likeCountComponent}</View>
    {commentCountComponent}
    {sharedCountComponent && <View style={styles.separator} />}
    {sharedCountComponent}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  likesContainer: {
    flex: 1,
  },
  separator: {
    marginHorizontal: 10,
  },
})

export default SocialCount
