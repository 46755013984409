import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import EventShowTemplate from '~/components/common/templates/EventShowTemplate'
import EventList from '~/components/community/features/EventList'
import CommunityEvent from '~/interfaces/CommunityEvent'

type Props = {
  communityId: number
}

const EventContainerTemplate: React.FC<Props> = ({ communityId }) => {
  const [currentEvent, setCurrentEvent] = useState<CommunityEvent | undefined>()

  const hideEventDetail = (): void => {
    setCurrentEvent(undefined)
  }

  return (
    <View testID="event-container-template" style={styles.container}>
      {currentEvent ? (
        <EventShowTemplate eventId={currentEvent.id} onBack={hideEventDetail} />
      ) : (
        <EventList onSelect={setCurrentEvent} communityId={communityId} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default EventContainerTemplate
