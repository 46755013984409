import { Platform } from 'react-native'

import type Media from '~/interfaces/Media'
import type Policy from '~/interfaces/Policy'

const KEY_CONVERSIONS: { [key: string]: string } = {
  contentType: 'Content-Type',
  expires: 'Expires',
  cacheControl: 'Cache-Control',
  xAmzMetaOriginalFilename: 'x-amz-meta-original-filename',
  xAmzCredential: 'x-amz-credential',
  xAmzAlgorithm: 'x-amz-algorithm',
  xAmzDate: 'x-amz-date',
  xAmzSignature: 'x-amz-signature',
  xAmzSecurityToken: 'x-amz-security-token',
}

const buildFormData = <M extends Media>(
  blob: Blob,
  policy: Policy<M>,
  uri?: string
): FormData => {
  const formData = new FormData()
  Object.keys(policy.form).forEach((key) => {
    formData.append(KEY_CONVERSIONS[key] || key, policy.form[key])
  })
  /*
    For disable eslint:
    append(name: string, value: string | Blob, fileName?: string): void
    The value type only supports string | Blob. There isn't any extra type.
  */
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    formData.append('file', {
      uri,
      name: 'media',
      type: blob.type,
    } as any) //eslint-disable-line @typescript-eslint/no-explicit-any
  } else {
    formData.append('file', blob)
  }

  return formData
}
export default buildFormData
