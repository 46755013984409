import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Badge } from 'react-native-elements'
import { useSelector } from 'react-redux'

import HeaderMenuLink from '~/components/common/atoms/HeaderMenuLink'
import { messageUnreadCountSelector } from '~/slices/common/notifications'

type Props = {
  isActive?: boolean
}

const MessageMenuLink: React.FC<Props> = ({ isActive }: Props) => {
  const unreadCount = useSelector(messageUnreadCountSelector)

  return (
    <>
      {unreadCount > 0 && (
        <View testID="message-badge" style={styles.badge}>
          <Badge status="error" value={unreadCount} />
        </View>
      )}
      <HeaderMenuLink
        to={{ screen: 'messages' }}
        isActive={isActive}
        translationKey={'menu.messages'}
      />
    </>
  )
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})
export default MessageMenuLink
