import React from 'react'
import { StyleSheet } from 'react-native'
import { InputToolbarProps, InputToolbar } from 'react-native-gifted-chat'

import color from '~/constants/common/color'

export const renderInputToolbar = (
  props: InputToolbarProps
): React.ReactNode => {
  return (
    <InputToolbar {...props} containerStyle={styles.inputToolbarContainer} />
  )
}

const styles = StyleSheet.create({
  inputToolbarContainer: {
    backgroundColor: color.primary,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
  },
})
