import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Like from '~/interfaces/Like'

type Props = {
  like: Like
}

const ReactionItem: React.FC<Props> = ({ like }: Props) => (
  <View style={styles.container} testID="reaction-item">
    <UserAvatar user={like.user} size={ProfileAvatarSize.SMALL} />
    <View style={styles.userName}>
      <UserName user={like.user} />
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 4,
    marginBottom: 7,
  },
  userName: {
    marginLeft: 10,
  },
})

export default ReactionItem
