import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import api from '~/api'
import Account from '~/interfaces/Account'
import { AppThunk } from '~/store'

export enum SettingsType {
  Account = 'account',
  Privacy = 'privacy',
  Notifications = 'notifications',
}

type SettingsState = {
  isLoading: boolean
  error: string | null
  currentType: SettingsType
  account?: Account
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isLoading: false,
    currentType: SettingsType.Account,
  } as SettingsState,
  reducers: {
    requestStart(state): void {
      state.error = null
      state.isLoading = true
    },
    requestSuccess(state): void {
      state.isLoading = false
    },
    requestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
    setCurrentType(state, action: PayloadAction<SettingsType>): void {
      state.currentType = action.payload
    },
    setAccount(state, action: PayloadAction<Account>): void {
      state.account = action.payload
    },
  },
})

export const {
  setAccount,
  requestStart,
  requestSuccess,
  requestFailure,
  setCurrentType,
} = settingsSlice.actions

export default settingsSlice.reducer

export const updateEmail = (email: string): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    await api.account.updateEmail(email)
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
    throw err
  }
}

export const fetchAccount = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await api.account.fetchAccount()
    dispatch(setAccount(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(String(err?.error?.detail)))
  }
}
