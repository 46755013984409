import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { Pressable, StyleProp, StyleSheet, View } from 'react-native' // FIXME: To use Pressable we need to upgrade react-native
// FIXME: Use official type import type { InteractionState } from 'react-native'

import color from '~/constants/common/color'

type Direction = 'right' | 'left'

type InteractionState = {
  focused: boolean
  hovered: boolean
  pressed: boolean
}

type Props = {
  direction: Direction
  onPress?: () => void
}

const PagingControl: React.FC<Props> = ({ direction, onPress }: Props) => (
  <Pressable
    onPress={onPress}
    style={pagingControlStyles[direction]}
    testID="paging-control"
  >
    <View style={StyleSheet.flatten([styles.button, {}])}>
      <Icon name={`angle-${direction}`} size={28} />
    </View>
  </Pressable>
)

export default PagingControl

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: color.transparentWhite,
    borderRadius: 22,
    height: 44,
    justifyContent: 'center',
    width: 44,
  },
  pagingControl: {
    alignItems: 'center',
    flex: 1,
    paddingLeft: '30%',
    paddingRight: '30%',
  },
  pagingControlActive: {
    backgroundColor: color.maskWhite,
  },
  pagingControlLeft: {
    flexDirection: 'row',
    left: 0,
  },
  pagingControlRight: {
    flexDirection: 'row-reverse',
    right: 0,
  },
})

export const pressableStyle = (direction: Direction) => ({
  hovered,
  pressed,
}: InteractionState): StyleProp<{}> => {
  const style: StyleProp<{}>[] = [styles.pagingControl]
  if (hovered || pressed) {
    style.push(styles.pagingControlActive)
  }
  switch (direction) {
    case 'right':
      style.push(styles.pagingControlRight)
      break
    case 'left':
      style.push(styles.pagingControlLeft)
      break
  }
  return StyleSheet.flatten(style)
}

type PagingControlStyle = {
  [key: string]: StyleProp<{}>
}

const pagingControlStyles: PagingControlStyle = {
  left: pressableStyle('left'),
  right: pressableStyle('right'),
}
