import { useFocusEffect } from '@react-navigation/native'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty, isEqual } from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { GiftedChat, SendProps } from 'react-native-gifted-chat'
import { useSelector } from 'react-redux'

import api, { API } from '~/api'
import { ChatMessageRequestBody } from '~/api/chatMessages'
import { renderBubble } from '~/components/career/molecules/message/ChatBubble'
import { renderComposer } from '~/components/career/molecules/message/ChatComposer'
import ChatSendButton from '~/components/career/molecules/message/ChatSendButton'
import { renderTime } from '~/components/career/molecules/message/ChatTime'
import { renderInputToolbar } from '~/components/career/molecules/message/InputToolbar'
import color from '~/constants/common/color'
import { RootContext } from '~/contexts/RootContext'
import useAPI from '~/hooks/useAPI'
import useCustomToast from '~/hooks/useCustomToast'
import useStreamSubscription from '~/hooks/useStreamSubscription'
import ChatMessageCable from '~/interfaces/ChatMessageCable'
import ChatThread from '~/interfaces/ChatThread'
import Message from '~/interfaces/Message'
import { currentUserSelector } from '~/slices/common/users'
import {
  parseMessageFromCable,
  formatMessage,
  parseGiftedUserFromUser,
} from '~/utils/career/giftedChat'
import { openURL } from '~/utils/navigation'

type Props = {
  selectedChatThread: Partial<ChatThread>
  threadMessageAction?: React.ReactElement
  createNewThread?: (firstMessage: string) => Promise<ChatThread | undefined>
}

const ChatColumn: React.FC<Props> = ({
  selectedChatThread,
  threadMessageAction,
  createNewThread,
}: Props) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { stream } = useContext(RootContext)
  const currentUser = useSelector(currentUserSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<Message[]>([])

  const fetchThreadMessages = useAPI(async (): Promise<void> => {
    if (isLoading) return
    if (!selectedChatThread.id) {
      setMessages([])
      return
    }
    setIsLoading(true)
    try {
      const response = await api.chatThreads.show<Message[]>(
        selectedChatThread.id
      )
      setMessages(
        response.map((message) =>
          formatMessage(message, t, selectedChatThread.users)
        )
      )
      api.isAlive && setIsLoading(false)
    } catch (e) {
      api.isAlive && setIsLoading(false)
      if (api.isAlive) {
        toast.showError(e)
      }
    }
  }, [selectedChatThread.id, setMessages, setIsLoading])

  useFocusEffect(
    React.useCallback(() => {
      fetchThreadMessages()
    }, [fetchThreadMessages])
  )

  useStreamSubscription({
    stream,
    channelName: {
      channel: 'ChatChannel',
      chat_thread_id: selectedChatThread.id,
    },
    mixin: {
      received(data: ChatMessageCable) {
        const response = camelcaseKeys(data, { deep: true }) as ChatMessageCable
        if (!isEqual(response?.chatThreadId, selectedChatThread?.id)) {
          return
        }
        const newMessage = parseMessageFromCable(
          response,
          selectedChatThread.users || [],
          t
        )
        newMessage &&
          setMessages((previousMessages) => {
            if (!previousMessages.find((item) => item.id === newMessage.id)) {
              return GiftedChat.prepend(previousMessages, [newMessage])
            }
            return previousMessages
          })
      },
    },
    deps: [selectedChatThread.id, setMessages],
  })

  const sendMessage = useAPI(
    async (api: API, messages: Message[] = []): Promise<void> => {
      if (!messages.length) return
      const selectedChatThreadId = selectedChatThread.id
      try {
        if (!selectedChatThreadId) {
          // If the thread is new, should create it along with the very first message
          createNewThread?.(messages[0].text)
          return
        }

        // Otherwise, just send a new message to the existing thread
        await api.chatMessages
          .build(selectedChatThreadId)
          .create<ChatMessageRequestBody, Message>({
            chatMessage: {
              body: messages[0].text,
              userIds: [],
            },
          })
      } catch (e) {
        api.isAlive && toast.showError(e)
      }
    },
    [selectedChatThread, createNewThread]
  )

  const renderSendButton = (
    props: Readonly<SendProps<Message>> &
      Readonly<{ children?: React.ReactNode }>
  ): React.ReactNode => (
    <ChatSendButton {...props} disabled={isEmpty(selectedChatThread.users)} />
  )

  return (
    <View style={styles.body} testID="chat-column">
      {threadMessageAction}
      <GiftedChat
        key={selectedChatThread.id}
        alwaysShowSend={true}
        inverted={false}
        messages={isLoading ? [] : messages}
        onSend={sendMessage}
        user={currentUser && parseGiftedUserFromUser(currentUser, t)}
        renderComposer={renderComposer}
        renderInputToolbar={renderInputToolbar}
        minInputToolbarHeight={150}
        minComposerHeight={90}
        maxComposerHeight={300}
        renderSend={renderSendButton}
        renderBubble={renderBubble}
        renderTime={renderTime}
        parsePatterns={(linkStyle) => [
          {
            type: 'url',
            style: linkStyle,
            onPress: openURL,
          },
        ]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.white,
    flex: 1,
    justifyContent: 'center',
    maxWidth: 920,
    minWidth: 300,
  },
})

export default ChatColumn
