import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import HeaderCompany from '~/components/career/features/company/HeaderCompany'
import JobAdd from '~/components/career/features/jobs/JobAdd'
import JobsList from '~/components/career/features/jobs/JobsList'
import CompanyJobDashboardFilterMenu from '~/components/career/molecules/company/CompanyJobDashboardFilterMenu'
import CompanyRecruitmentsHeader from '~/components/career/molecules/company/CompanyRecruitmentsHeader'
import CompanyOverview from '~/components/career/organisms/company/CompanyOverview'
import CompanyRecruitments from '~/components/career/organisms/company/CompanyRecruitments'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import SkeletonProfileHeader from '~/components/common/organisms/SkeletonProfileHeader'
import { CompanySection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import { ProfileSectionParam } from '~/interfaces/RootStackParamList'
import { currentCompanySelector } from '~/slices/career/company'
import { currentUserSelector } from '~/slices/common/users'
import { isCompanyAdmin } from '~/utils/career/company'
import { getRouteParams, parseCompanySection } from '~/utils/navigation'

type Props = {
  isLoading?: boolean
}

const CompanyJobDashboardTemplate: React.FC<Props> = ({ isLoading }: Props) => {
  const companySection = parseCompanySection(
    getRouteParams<ProfileSectionParam>().section
  )
  const company = useSelector(currentCompanySelector)
  const currentUser = useSelector(currentUserSelector)

  const isAdmin = useMemo(() => {
    return isNil(currentUser) || isNil(company)
      ? false
      : isCompanyAdmin(currentUser, company)
  }, [currentUser?.id, company?.id])

  const { isPC } = useDevice()

  const getContentComponent = (): React.ReactNode => {
    switch (companySection) {
      case CompanySection.OVERVIEW:
        return <CompanyOverview isPC={isPC} />
      case CompanySection.JOB_POSTS:
      default:
        return (
          <CompanyRecruitments
            companyJobPostsElement={
              <>
                <JobsList
                  isCompanyMode={true}
                  header={<CompanyJobDashboardFilterMenu isPC={isPC} />}
                  isJobDashboard={true}
                />
              </>
            }
            companyRecruitmentsHeaderElement={
              company ? (
                <CompanyRecruitmentsHeader
                  company={company}
                  currentUser={currentUser}
                  isPC={isPC}
                  createJobButton={<JobAdd />}
                />
              ) : (
                <></>
              )
            }
          />
        )
    }
  }

  return (
    <>
      <InfiniteScrollContainer>
        <View
          testID="jobs-dashboard-template"
          style={isPC ? styles.container : styles.fullParent}
        >
          {isLoading ? (
            <SkeletonProfileHeader isPC={isPC} />
          ) : (
            <HeaderCompany isAdmin={isAdmin} />
          )}
          <View style={styles.body}>
            <View style={styles.horizontalContainer}>
              <View testID="company-right-column" style={styles.fullParent}>
                {getContentComponent()}
              </View>
            </View>
          </View>
        </View>
      </InfiniteScrollContainer>
      {!isPC && <JobAdd />}
    </>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  container: {
    alignSelf: 'center',
    width: 814,
  },
  fullParent: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
  },
})

export default CompanyJobDashboardTemplate
