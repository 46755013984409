import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { UsersParams } from '~/api/users'
import jlpt from '~/assets/data/jlpt.json'
import jobHuntingOccupations from '~/assets/data/jobHuntingOccupations.json'
import CustomPicker, {
  PickerOption,
  PickerProps,
  pickerValue,
} from '~/components/common/molecules/CustomPicker'
import CountryPicker from '~/components/common/molecules/users/CountryPicker'
import { ColorTheme } from '~/constants/common/color'
import i18n from '~/plugins/i18n'

// TODO: Translations
type PickerDataItem = {
  key: string
  data: PickerOption[]
}

const getOccupationListData = (): PickerOption[] =>
  jobHuntingOccupations.map((item) =>
    pickerValue(i18n.language === 'ja' ? item.name : item.enName, item.id)
  )

export const getPickerData = (lang: string, t: TFunction): PickerDataItem[] => {
  return [
    {
      key: 'japaneseLevel',
      data: [
        { label: t('language.jlpt'), value: '' },
        ...jlpt.map((item) => {
          return {
            label: lang === 'ja' ? item.name : item.en_name,
            value: item.id,
          }
        }),
      ],
    },
    {
      key: 'isGraduated',
      data: [
        { label: t('profile.studyStatus'), value: '' },
        { label: t('profile.student'), value: 'false' },
        { label: t('profile.graduated'), value: 'true' },
      ],
    },
    {
      key: 'career',
      data: getOccupationListData(),
    },
  ]
}

type Props = {
  searchParams: Partial<UsersParams>
  onChangePicker: (key: string, value: ItemValue) => void
}

const UsersHeaderFilters: React.FC<Props> = ({
  searchParams,
  onChangePicker,
}: Props) => {
  const { t } = useTranslation()
  const selectedData: any = searchParams // eslint-disable-line @typescript-eslint/no-explicit-any

  const convertFieldToPicker: (field: PickerDataItem) => JSX.Element = (
    field
  ) => {
    const onChange = (value: ItemValue): void =>
      onChangePicker(field.key, value)
    const pickerProps: PickerProps = {
      data: field.data,
      onValueChange: onChange,
    }

    return (
      <View
        key={field.key}
        testID="users-filters-filter-container"
        style={styles.conditionItem}
      >
        <CustomPicker
          size="full"
          data={pickerProps.data}
          colorTheme={ColorTheme.Light}
          selectedValue={selectedData[field.key]}
          onValueChange={pickerProps.onValueChange}
        />
      </View>
    )
  }

  return (
    <View testID="users-filters" style={styles.conditionSet}>
      <CountryPicker
        title=""
        invertedText
        selected={searchParams?.country}
        onChange={(value: string) => onChangePicker('country', value)}
      />
      {getPickerData(i18n.language, t).map(convertFieldToPicker)}
    </View>
  )
}

const styles = StyleSheet.create({
  conditionItem: {
    marginHorizontal: 5,
  },
  conditionSet: {
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center',
  },
})

export default UsersHeaderFilters
