import { useFocusEffect } from '@react-navigation/native'
import React, { useEffect, useState, LegacyRef } from 'react'
import {
  Dimensions,
  FlatListProps,
  Platform,
  ScaledSize,
  StyleSheet,
  FlatList,
} from 'react-native'
import { useSelector } from 'react-redux'

import BaseHttpData from '~/interfaces/BaseHttpData'
import { RootState } from '~/rootReducer'

export type Props<T extends BaseHttpData> = {
  onReachBottom?: () => void
  screenOffset?: number // The value present for part that isn't belong to Flatlist.
  innerRef?: LegacyRef<FlatList<T>>
} & FlatListProps<T>

function InfiniteScrollFlatList<T extends BaseHttpData>(
  props: Props<T>
): React.ReactElement<Props<T>> {
  const { onReachBottom, innerRef, screenOffset = 0 } = props
  const isBottom = useSelector((state: RootState) => state.scroll.isBottom)
  const [height, setHeight] = useState(Dimensions.get('window').height)

  useFocusEffect(
    React.useCallback(() => {
      isBottom && onReachBottom && onReachBottom()
    }, [isBottom])
  )

  useEffect(() => {
    const updateHeight = (e: { window: ScaledSize }): void =>
      setHeight(e.window.height)
    const event = Dimensions.addEventListener('change', updateHeight)
    return () => event.remove()
  }, [])

  const computeReachBottom = (distanceFromEnd: number): void => {
    // NOTE prevent fire onReachBottom from onEndReached when scrollEnable = false.
    // And it only should fire onReachBottom when scrollEnabled as default or equal true
    if (props.scrollEnabled === false) {
      return
    }
    distanceFromEnd <= 1 && onReachBottom && onReachBottom()
  }

  return (
    <FlatList
      {...props}
      ref={innerRef}
      testID="infinite-scroll-flat-list"
      onEndReachedThreshold={props.onEndReachedThreshold || 0.05}
      onEndReached={(info): void => computeReachBottom(info.distanceFromEnd)}
      // To scrollable in web. See https://github.com/react-navigation/react-navigation/issues/8631
      style={StyleSheet.flatten([
        {
          ...Platform.select({ web: { height: height - screenOffset } }),
        },
        props.style,
      ])}
    />
  )
}

export default InfiniteScrollFlatList
