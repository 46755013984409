import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle } from 'react-native'

import JobInfoBaseLayout from '~/components/career/organisms/jobs/JobInfoBaseLayout'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import Recruitment from '~/interfaces/Recruitment'

type Props = {
  isPC: boolean
  job: Partial<Recruitment>
  editable?: boolean
  onSubmit?: (value: string) => void
}

const JobCompanyDescription: React.FC<Props> = ({
  job,
  isPC,
  editable,
  onSubmit,
}: Props) => {
  const defaultLength = 636
  const { t } = useTranslation()
  const [isShowAll, setIsShowAll] = useState(false)

  return isEmpty(job.companyDescription) && !editable ? (
    <></>
  ) : (
    <JobInfoBaseLayout
      isPC={isPC}
      title={t('jobs.companyDescriptionTitle', {
        companyName: job.companyName,
      })}
      editable={editable}
      content={
        isShowAll || editable
          ? job.companyDescription?.substring(0, defaultLength)
          : ''
      }
      actionComponent={
        (job.companyDescription?.length ?? 0) > defaultLength &&
        !isShowAll &&
        !editable ? (
          <Text
            testID="see-more"
            style={styles.seeMore as TextStyle}
            onPress={(): void => setIsShowAll(true)}
          >
            {t('seeMore')}
          </Text>
        ) : undefined
      }
      onSubmit={onSubmit}
    />
  )
}

const styles = {
  seeMore: {
    alignSelf: 'flex-end',
    color: color.pressableText,
  },
}

export default JobCompanyDescription
