import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import api from '~/api'
import { ShareBodyParams } from '~/api/shares'
import PostItemTitle from '~/components/career/molecules/post/PostItemTitle'
import CrossIcon from '~/components/common/atoms/CrossIcon'
import ShareButton from '~/components/common/atoms/socials/ShareButton'
import SharingPopup from '~/components/common/molecules/SharingPopup'
import QuestionItem from '~/components/forum/molecules/questions/QuestionItem'
import useCustomToast from '~/hooks/useCustomToast'
import Question from '~/interfaces/Question'
import Share from '~/interfaces/Share'
import { currentUserSelector } from '~/slices/common/users'

type Props = {
  question: Question
  onShared?: (share: Share) => void
}

const QuestionSharing: React.FC<Props> = ({ onShared, question }: Props) => {
  const toast = useCustomToast()
  const [message, setMessage] = useState('')
  const [isSharing, setIsSharing] = useState(false)
  const currentUser = useSelector(currentUserSelector)
  const [isShowSharingPopup, setIsShowSharingPopup] = useState(false)

  const closePopup = (): void => setIsShowSharingPopup(false)
  const openPopup = (): void => setIsShowSharingPopup(true)

  const shareQuestionToTimeline = async (): Promise<void> => {
    try {
      setIsSharing(true)
      const response = await api.shares.create<ShareBodyParams, Share>({
        message,
        contentId: question.id,
        shareType: 'question',
      })
      setIsSharing(false)
      closePopup()
      onShared && onShared(response)
    } catch (error) {
      toast.showError(error)
      setIsSharing(false)
    }
  }

  const shareContent = <QuestionItem question={question} />

  return (
    <View testID="question-sharing">
      <TouchableOpacity onPress={openPopup} testID="share-button-touchable">
        <ShareButton />
      </TouchableOpacity>
      {isShowSharingPopup && (
        <SharingPopup
          header={
            <>
              <PostItemTitle
                user={currentUser}
                date={new Date().toISOString()}
              />
              <CrossIcon onPress={closePopup} />
            </>
          }
          disabled={isSharing}
          onChange={setMessage}
          onDismiss={closePopup}
          sharingContent={shareContent}
          onSubmit={shareQuestionToTimeline}
        />
      )}
    </View>
  )
}

export default QuestionSharing
