import { FontAwesome as Icon } from '@expo/vector-icons'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Badge, Button } from 'react-native-elements'

import color from '~/constants/common/color'

export type Props = {
  isActive: boolean
  onPress: () => void
  unreadNotificationCount?: number
}

const NotificationIcon: React.FC<Props> = ({
  isActive,
  onPress,
  unreadNotificationCount,
}: Props) => {
  const containerStyle = isActive
    ? styles.activeContainer
    : styles.inactiveContainer
  const iconColor = isActive ? color.primary : color.textGray

  return (
    <View style={containerStyle} testID="notification-icon">
      <Button
        type="clear"
        testID="notification-icon-button"
        onPress={onPress}
        icon={<Icon size={22} name="bell" color={iconColor} />}
      />
      {(unreadNotificationCount ?? 0) > 0 && (
        <View style={styles.badge} testID="badge" pointerEvents="none">
          <Badge status="error" value={unreadNotificationCount} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  activeContainer: {
    backgroundColor: color.white,
    borderRadius: 4,
    margin: 4,
  },
  badge: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
  inactiveContainer: {
    margin: 4,
  },
})

export default NotificationIcon
