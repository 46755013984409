import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { client } from '~/httpClient'
import User from '~/interfaces/User'
import { RootState } from '~/rootReducer'
import { AppThunk } from '~/store'

type FriendSuggestionState = {
  isLoading: boolean
  error: string | null
}

export const friendSuggestionsAdapter = createEntityAdapter<User>()

const friendSuggestionsSlice = createSlice({
  name: 'friendSuggestions',
  initialState: friendSuggestionsAdapter.getInitialState({
    isLoading: false,
    error: null,
  } as FriendSuggestionState),
  reducers: {
    setAll: friendSuggestionsAdapter.setAll,
    removeOne: friendSuggestionsAdapter.removeOne,
    updateOne: friendSuggestionsAdapter.updateOne,
    requestStart(state): void {
      state.isLoading = true
      state.error = null
    },
    requestSuccess(state): void {
      state.isLoading = false
    },
    requestFailure(state, action: PayloadAction<string>): void {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  setAll,
  removeOne,
  updateOne,
  requestStart,
  requestSuccess,
  requestFailure,
} = friendSuggestionsSlice.actions

export const getAllFriendSuggestions = (state: RootState): User[] =>
  friendSuggestionsAdapter.getSelectors().selectAll(state.friendSuggestions)

export const fetchFriendSuggestions = (): AppThunk => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(requestStart())
    const response = await client.get<User[]>('/friend_suggestions')
    dispatch(setAll(response))
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(requestFailure(err))
  }
}

export default friendSuggestionsSlice.reducer
