import React from 'react'
import { View, ViewStyle } from 'react-native'

type Props = {
  isPC: boolean
  children: React.ReactNode
}

const ProfileSummary: React.FC<Props> = ({ isPC, children }: Props) => {
  const wrapperStyle = { gap: isPC ? 18 : 10 } as ViewStyle

  return (
    <View style={wrapperStyle} testID="profile-summary">
      {children}
    </View>
  )
}

export default ProfileSummary
