import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ControlMenu, {
  ControlMenuData,
} from '~/components/common/molecules/ControlMenu'
import Notification from '~/interfaces/Notification'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  notifications: Notification[]
  markAsRead: (notification: Notification) => void
}

export const INDEX_OF_MARK_ALL_AS_READ = 1
export const INDEX_OF_SEE_ALL = 2

const NotificationControlMenu: React.FC<Props> = ({
  notifications,
  markAsRead,
}: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const onSelectItem = (item: ControlMenuData): void => {
    switch (item.id) {
      case INDEX_OF_MARK_ALL_AS_READ:
        notifications.forEach((notification) => {
          markAsRead(notification)
        })
        break
      case INDEX_OF_SEE_ALL:
        navigate('notifications')
        break
    }
  }

  const data: ControlMenuData[] = [
    {
      id: INDEX_OF_MARK_ALL_AS_READ,
      title: t('notification.markAllAsRead'),
    },
    {
      id: INDEX_OF_SEE_ALL,
      title: t('notification.seeAllNotifications'),
    },
  ]

  return (
    <View testID="notification-control-menu">
      <ControlMenu data={data} onSelect={onSelectItem} />
    </View>
  )
}

export default NotificationControlMenu
