import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import ApplicantAction from '~/components/career/molecules/company/ApplicantAction'
import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import UserName from '~/components/common/atoms/users/UserName'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import Applicant from '~/interfaces/Applicant'
import i18n from '~/plugins/i18n'

type Props = {
  applicant: Applicant
}

const ApplicantItem: React.FC<Props> = ({ applicant }: Props) => {
  const { t } = useTranslation()
  const { user, createdAt } = applicant

  return (
    <View style={styles.container} testID="applicant-item">
      <UserAvatar user={user} size={ProfileAvatarSize.LARGE} />
      <View style={styles.profileInfo}>
        <View>
          <UserName size="medium" user={user} />
        </View>
        <Text style={styles.position} numberOfLines={2}>
          {user?.profile?.position}
        </Text>
        <ApplicantAction />
      </View>
      <View style={styles.rightColumn}>
        <Text style={styles.appliedOn}>
          {t('company.applicant.appliedOn', {
            date: moment(createdAt).locale(i18n.language).format('LL'),
          })}
        </Text>
        <Text style={styles.viewResume}>
          {t('company.applicant.viewResume')}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  appliedOn: {
    color: color.textGray,
    fontSize: FontSize.SUB,
  },
  container: {
    backgroundColor: color.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 30,
    width: '100%',
    marginBottom: 10,
  },
  position: {
    color: color.textGray,
    flexWrap: 'wrap',
    fontSize: FontSize.SUB,
  },
  profileInfo: {
    marginLeft: 20,
    flex: 1,
  },
  rightColumn: {
    alignItems: 'flex-end',
  },
  viewResume: {
    color: color.primary,
    textDecorationLine: 'underline',
    fontSize: FontSize.SUB,
    marginTop: 20,
  },
})

export default ApplicantItem
