import React from 'react'

import AuthContainerColumn from '~/components/common/features/auth/AuthContainerColumn'
import ResetPasswordForm from '~/components/common/molecules/auth/ResetPasswordForm'

const ResetPasswordColumn: React.FC = () => (
  <AuthContainerColumn>
    <ResetPasswordForm />
  </AuthContainerColumn>
)

export default ResetPasswordColumn
