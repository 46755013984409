import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Platform } from 'react-native'

import { UsersParams } from '~/api/users'
import SearchInput from '~/components/common/atoms/SearchInput'
import UsersHeaderFilters from '~/components/common/molecules/users/UsersHeaderFilters'
import UsersHeaderLeft from '~/components/common/molecules/users/UsersHeaderLeft'
import color from '~/constants/common/color'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams, navigationRef } from '~/utils/navigation'

type Props = {
  isPC: boolean
}

const HeaderUsers: React.FC<Props> = ({ isPC }: Props) => {
  const { setQueryParams } = useCustomNavigation()
  const isNavigationReady = navigationRef.isReady()
  const containerStyle = isPC ? styles.container : styles.containerMobile
  const params = getRouteParams<UsersParams>()
  const [searchParams, setSearchParams] = useState<UsersParams>({})

  const onChangePicker = (key: string, value: ItemValue): void => {
    const param: any = { ...searchParams } // eslint-disable-line @typescript-eslint/no-explicit-any
    param[key] = value
    setQueryParams(param)
    updateSearchParams(param)
  }

  const searchUsers = (): void => {
    setQueryParams(searchParams)
  }

  const updateSearchParams = (params: UsersParams) => {
    setSearchParams(params)
  }

  useEffect(() => {
    isNavigationReady && setSearchParams(params)
  }, [isNavigationReady])

  return (
    <View style={styles.wrapper}>
      <View style={containerStyle} testID="header-users">
        <ScrollView
          horizontal
          style={styles.searchForm}
          contentContainerStyle={
            isPC
              ? StyleSheet.flatten([styles.scrollContainer, { flex: 1 }])
              : styles.scrollContainer
          }
          testID="header-users-scroll"
        >
          {[
            isPC && (
              <UsersHeaderLeft
                keyword={searchParams.name}
                key="usersHeaderLeft"
                onChangeText={(name) =>
                  updateSearchParams({ ...searchParams, name })
                }
                onPressSearch={searchUsers}
              />
            ),
            <UsersHeaderFilters
              key="usersFilters"
              searchParams={searchParams}
              onChangePicker={onChangePicker}
            />,
          ]}
        </ScrollView>
      </View>
      {!isPC && (
        <View style={styles.search}>
          <SearchInput
            inverted
            placeholderKey=""
            value={searchParams.name}
            onChangeText={(name) =>
              updateSearchParams({ ...searchParams, name })
            }
            onSubmit={searchUsers}
          />
        </View>
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    backgroundColor: color.primary,
    flexDirection: 'row',
    height: 80,
    justifyContent: 'center',
  },
  containerMobile: {
    backgroundColor: color.primary,
    paddingVertical: 20,
    justifyContent: 'center',
  },
  scrollContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    alignItems: 'center',
    minWidth: 300,
    ...Platform.select({
      ios: {
        marginHorizontal: 20,
        marginTop: 20,
      },
      android: {
        marginHorizontal: 20,
        marginTop: 20,
      },
      default: {
        // other platforms, web for example
        alignSelf: 'center',
        marginTop: 10,
      },
    }),
  },
  searchForm: {
    alignContent: 'center',
    flexDirection: 'row',
    maxWidth: 1230,
    width: '100%',
  },
  wrapper: {
    backgroundColor: color.white,
  },
})

export default HeaderUsers
