import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Platform, View, TouchableOpacity } from 'react-native'
import { Image } from 'react-native-elements'

import CloseIcon from '~/components/common/atoms/CloseIcon'
import URLPreviewInfo from '~/components/common/molecules/URLPreview/URLPreviewInfo'
import { fetchPreview as fetchPreviewOnNative } from '~/components/common/molecules/URLPreview/linkPreview'
import Input from '~/components/workarounds/Input'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import URLInspection from '~/interfaces/URLInspection'

type Props = {
  value: string
  onChangeText?: (text: string) => void
  detectedURL?: string
  onChangePreview?: (isShow: boolean) => void
  isShowPreviewPost?: boolean
}

const PostInput: React.FC<Props> = ({
  value,
  onChangeText,
  detectedURL,
  onChangePreview,
  isShowPreviewPost,
}: Props) => {
  const { t } = useTranslation()
  const [urlInspection, setURLInspection] = useState<URLInspection>({ url: '' })

  const getURLPreview = async () => {
    if (!isEmpty(detectedURL)) {
      setURLInspection(await fetchPreviewOnNative(detectedURL!))
      onChangePreview && onChangePreview(true)
    }
  }

  const closePreviewPost = () => {
    onChangePreview && onChangePreview(false)
  }

  useEffect(() => {
    getURLPreview()
  }, [detectedURL])

  return (
    <View style={styles.container}>
      <Input
        multiline
        placeholder={t('postForm.placeholder')}
        placeholderTextColor={color.placeholderText}
        numberOfLines={3}
        style={styles.input}
        value={value}
        inputContainerStyle={styles.inputContainer}
        onChangeText={onChangeText}
        testID="post-input"
        maxLength={1200}
        inputStyle={commonStyles.bodyTextSize}
      />
      {isShowPreviewPost ? (
        <View>
          <TouchableOpacity style={styles.close} onPress={closePreviewPost}>
            <CloseIcon size="medium" inverted={true} />
          </TouchableOpacity>
          <View style={styles.previewPost}>
            {urlInspection.image && (
              <Image
                style={styles.image}
                testID="url-preview-image"
                source={{ uri: urlInspection.image.url }}
                height=""
                width=""
              />
            )}
            <URLPreviewInfo
              url={urlInspection.url}
              title={urlInspection.title}
              description={urlInspection.description}
            />
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
  container: {
    backgroundColor: color.textInputBackground,
  },
  image: {
    height: 200,
    width: '100%',
  },
  input: {
    ...Platform.select({
      web: { outlineColor: color.transparent, outlineStyle: 'none' },
    }),
    backgroundColor: color.textInputBackground,
    borderColor: color.transparent,
    padding: 10,
    ...commonStyles.borderRadiusAllCorner,
    ...commonStyles.bodyTextSize,
  },
  inputContainer: {
    borderColor: color.transparent,
    borderWidth: 0,
  },
  previewPost: {
    margin: 10,
  },
})

export default PostInput
