import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import color from '~/constants/common/color'

type Props = {
  inverted?: boolean
  size?: 'small' | 'medium'
}

const CloseIcon: React.FC<Props> = ({ size, inverted }: Props) => {
  let iconSize = 25
  switch (size) {
    case 'small':
      iconSize = 14
      break
    case 'medium':
    default:
      iconSize = 25
  }

  return (
    <View testID="icon">
      <Svg
        viewBox="0 0 50 50"
        data-supported-dps={`${iconSize}x${iconSize}`}
        fill={inverted ? color.white : color.pressableText}
        width={iconSize}
        height={iconSize}
      >
        <Path
          stroke={color.darkGray}
          strokeWidth="2"
          d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"
        ></Path>
      </Svg>
    </View>
  )
}

export default CloseIcon
