import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

import { UserQuestionType } from '~/api/userQuestions'
import InfiniteScrollContainer from '~/components/common/atoms/InfiniteScrollContainer'
import FollowersList from '~/components/common/features/FollowersList'
import UserForumAnswer from '~/components/common/features/users/UserForumAnswer'
import UserForumQuestion from '~/components/common/features/users/UserForumQuestion'
import ProfileOverview from '~/components/common/features/users/UserProfileOverview'
import UsersForumFilterPopup from '~/components/common/molecules/users/UsersForumFilterPopup'
import SkeletonProfileHeader from '~/components/common/organisms/SkeletonProfileHeader'
import HeaderUser from '~/components/community/features/users/HeaderUser'
import { ForumDataTypeParams } from '~/components/forum/organisms/users/ForumTypeSelectableList'
import { UserSection } from '~/constants/common/navigation'
import useDevice from '~/hooks/commons/useDevice'
import { ProfileSectionParam } from '~/interfaces/RootStackParamList'
import User from '~/interfaces/User'
import { getRouteParams, parseUserSection } from '~/utils/navigation'

type Props = {
  user?: User
  isLoading?: boolean
}

const UserTemplate: React.FC<Props> = ({ user, isLoading }: Props) => {
  const userSection = parseUserSection(
    getRouteParams<ProfileSectionParam>().section
  )

  const forumDataTypeParams = getRouteParams<ForumDataTypeParams>()
  const navigation = useNavigation()
  const { isPC } = useDevice()

  useEffect(() => {
    navigation.setOptions({ title: '' })
  }, [])

  let filterPopupMenu = undefined
  if (userSection === UserSection.FORUM) {
    filterPopupMenu = <UsersForumFilterPopup />
  }

  const contentForumType: { [key: string]: React.ReactNode } = {
    [UserQuestionType.QUESTION]: <UserForumQuestion user={user} />,
    [UserQuestionType.ANSWER]: <UserForumAnswer user={user} />,
  }

  const getContentComponent = (): React.ReactNode => {
    switch (userSection) {
      case UserSection.FORUM:
        return contentForumType[
          forumDataTypeParams.forumType ?? UserQuestionType.QUESTION
        ]
      case UserSection.INFO:
        return <ProfileOverview user={user} />
      case UserSection.FOLLOWERS:
        return <FollowersList user={user!} type="followers" />
      case UserSection.FOLLOWING:
        return <FollowersList user={user!} type="following" />
    }
  }

  return (
    <InfiniteScrollContainer>
      <View
        testID="user-template"
        style={isPC ? styles.container : styles.fullParent}
      >
        {isLoading ? (
          <SkeletonProfileHeader isPC={isPC} />
        ) : (
          <HeaderUser user={user} />
        )}
        <View style={styles.body}>
          {filterPopupMenu && isPC && (
            <View style={styles.filterContainer}>{filterPopupMenu}</View>
          )}
          {
            <View style={styles.horizontalContainer}>
              <View testID="right-column" style={styles.fullParent}>
                {user && getContentComponent()}
              </View>
            </View>
          }
        </View>
      </View>
    </InfiniteScrollContainer>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  container: {
    alignSelf: 'center',
    width: 814,
  },
  filterContainer: {
    marginBottom: 10,
  },
  fullParent: {
    flex: 1,
    marginBottom: 50,
  },
  horizontalContainer: {
    flexDirection: 'row',
  },
})

export default UserTemplate
