import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import type { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import type { RouteProp } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TabMenuButton from '~/components/common/molecules/TabMenuButton'
import AppDrawer from '~/components/common/pages/RootPage/AppDrawer'
import { ServiceType } from '~/constants/common/app'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import {
  MainStackParamList,
  OtherStackParamList,
} from '~/interfaces/RootStackParamList'
import { otherRoutes } from '~/utils/common/routes'
import {
  mainCommunityRoutes,
  otherCommunityRoutes,
} from '~/utils/community/routes'

const Tab = createBottomTabNavigator<MainStackParamList & OtherStackParamList>()
type Route = RouteProp<Record<string, object | undefined>, string>

const tabBarIcon = (
  { focused }: { focused: boolean },
  route: Route
): JSX.Element => <TabMenuButton name={route.name} focused={focused} />

const screenOptions = ({
  route,
  navigation,
}: {
  route: Route
  navigation: { isFocused: Function }
}): BottomTabNavigationOptions => {
  return {
    tabBarStyle: {
      display: 'none',
    },
    tabBarItemStyle: {
      borderTopColor: navigation.isFocused()
        ? color.primary
        : color.transparent,
      borderTopWidth: 3,
    },
    tabBarIcon: ({ focused }: { focused: boolean }): JSX.Element =>
      tabBarIcon({ focused }, route),
    headerShown: false,
    tabBarShowLabel: true,
    tabBarLabelStyle: { fontSize: FontSize.SUB },
  }
}

const MobileTab: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Tab.Navigator
      initialRouteName="community"
      screenOptions={({ route, navigation }) =>
        screenOptions({ route, navigation })
      }
    >
      {mainCommunityRoutes.map((route) => (
        <Tab.Screen
          key={route.name}
          {...route}
          options={{
            tabBarLabel: route.labelTranslationKey
              ? t(route.labelTranslationKey)
              : '',
          }}
        />
      ))}
      {[...otherCommunityRoutes, ...otherRoutes].map((route) => (
        <Tab.Screen
          key={route.name}
          {...route}
          options={{
            tabBarItemStyle: { display: 'none' },
            tabBarIcon: () => null,
          }}
        />
      ))}
    </Tab.Navigator>
  )
}

const MobileLayout: React.FC = () => {
  return (
    <AppDrawer.Navigator
      initialRouteName="community"
      serviceType={ServiceType.COMMUNITY}
    >
      <AppDrawer.Screen name="mobiletab" component={MobileTab} />
    </AppDrawer.Navigator>
  )
}

export default MobileLayout
