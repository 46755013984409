import React from 'react'
import { StyleSheet, View, SectionList, ListRenderItem } from 'react-native'

import SelectableListItem from '~/components/common/molecules/SelectableListItem'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import SelectListItem from '~/interfaces/SelectListItem'

export type ID = number | string

export type SelectableListData = {
  title: string
  data: SelectListItem[]
}

type Props = {
  selectedId: ID
  data: SelectableListData[]
  headerRightIcon?: React.ReactNode
  onSelect: (itemId: ID) => void
  shouldHideHeader?: boolean
}

const SelectableList: React.FC<Props> = ({
  data,
  selectedId,
  onSelect,
  headerRightIcon,
  shouldHideHeader,
}: Props) => {
  const renderItem: ListRenderItem<SelectListItem> = ({
    item,
  }: {
    item: SelectListItem
    index: number
  }): JSX.Element => (
    <SelectableListItem
      item={item}
      onSelect={onSelect}
      isSelected={item.id === selectedId}
    />
  )

  const keyExtractor = (item: SelectListItem): string => item.id.toString()

  return (
    <View style={styles.container} testID="selectable-list">
      <SectionList
        sections={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        renderSectionHeader={({ section: { title } }): JSX.Element =>
          shouldHideHeader ? (
            <></>
          ) : (
            <View
              style={styles.headerContainer}
              testID="selectable-list-header"
            >
              <Text style={styles.headerTitle}>{title}</Text>
              {headerRightIcon}
            </View>
          )
        }
        ItemSeparatorComponent={({ trailingItem, leadingItem }): JSX.Element =>
          !trailingItem?.isPublic && leadingItem?.isPublic ? (
            <View style={styles.wrapSeparator}>
              <View style={styles.separator} />
            </View>
          ) : (
            <></>
          )
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.white,
  },
  headerContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: color.primary,
    paddingHorizontal: 24,
    paddingVertical: 12,
    marginBottom: 3,
  },
  headerTitle: {
    alignSelf: 'center',
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    fontWeight: 'bold',
  },
  separator: {
    backgroundColor: color.transparent,
    borderStyle: 'solid',
    borderTopColor: color.borderSeparator,
    borderTopWidth: 2,
    height: 1,
    marginVertical: 4,
    marginHorizontal: 12,
  },
  wrapSeparator: {
    width: '100%',
  },
})

export default SelectableList
