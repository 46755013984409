import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import Button from '~/components/workarounds/Button'
import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { FontSize } from '~/constants/common/font'
import { RootContext } from '~/contexts/RootContext'
import User from '~/interfaces/User'
import i18n, { SupportedLanguage } from '~/plugins/i18n'
import { navigate } from '~/utils/navigation'

type Props = {
  currentUser: User
  isAdmin?: boolean
}

const CompanySubscription: React.FC<Props> = ({
  currentUser,
  isAdmin,
}: Props) => {
  const { isPC } = useContext(RootContext)
  const { t } = useTranslation()
  const isPaid = !currentUser.subscription?.inactive

  const subscription = currentUser?.subscription
  const subscriptionPlan = subscription?.subscriptionPlan
  const subscriptionPlanName =
    i18n.language === SupportedLanguage.EN
      ? subscriptionPlan?.enName
      : subscriptionPlan?.name

  const navigateToSubscriptionPlan = () => {
    navigate('subscriptionPlan')
  }

  return (
    <View
      testID="company-subscription"
      style={isPC ? styles.container : styles.containerMobile}
    >
      <TitleText style={styles.title}>
        {t('company.subscriptionTitle')}
      </TitleText>
      <View style={isPC ? styles.body : null}>
        {subscriptionPlan && (
          <View testID="subscription-detail">
            <Text style={styles.accountType}>{subscriptionPlanName}</Text>
            <Text style={styles.bodyText}>
              {subscription?.currentPeriodStart &&
                t('company.plan.latestBill', {
                  latestBillTime: moment(
                    subscription?.currentPeriodStart
                  ).format('MM/DD/YYYY'),
                })}
            </Text>
            {subscription?.currentPeriodEnd && (
              <Text style={styles.bodyText}>
                {t('company.plan.billed', {
                  billType: t(
                    `company.plan.billType.${subscriptionPlan.period}`
                  ),
                })}
                {t('company.plan.nextBillTime', {
                  nextBillTime: moment(subscription?.currentPeriodEnd).format(
                    'MM/DD/YYYY'
                  ),
                })}
              </Text>
            )}
          </View>
        )}
        <View style={styles.fullPatent} />
        {isAdmin &&
          (isPaid ? (
            <Link
              to="/career/subscriptions"
              style={StyleSheet.flatten([
                styles.link,
                isPC ? null : styles.linkMobile,
              ])}
            >
              {t('company.manageSubscription')}
            </Link>
          ) : (
            <Button
              buttonStyle={styles.premiumButton}
              onPress={navigateToSubscriptionPlan}
              title={t('subscriptions.goPremium')}
              titleStyle={commonStyles.buttonTextSize}
            />
          ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  accountType: {
    fontSize: FontSize.GENERAL,
    marginBottom: 4,
  },
  body: {
    flexDirection: 'row',
  },
  bodyText: {
    fontSize: FontSize.GENERAL,
    color: color.textGray,
    marginBottom: 4,
  },
  container: {
    alignSelf: 'center',
    width: 814,
    padding: 20,
    backgroundColor: color.white,
  },
  containerMobile: {
    padding: 20,
    backgroundColor: color.white,
  },
  fullPatent: {
    flex: 1,
  },
  link: {
    color: color.darkGray,
    borderBottomColor: color.textGray,
    borderBottomWidth: 1,
    paddingBottom: 4,
    alignSelf: 'baseline',
  },
  linkMobile: {
    marginTop: 20,
  },
  premiumButton: {
    width: 160,
    backgroundColor: color.primary,
    ...commonStyles.borderRadiusAllCorner,
  },
  title: {
    marginBottom: 18,
  },
})

export default CompanySubscription
