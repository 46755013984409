import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { ProfileAvatarSize } from '~/components/common/atoms/ProfileAvatar'
import ProfileUserBanner from '~/components/common/features/users/ProfileUserBanner'
import EditableTextForm from '~/components/common/molecules/EditableTextForm'
import ProfileInfo from '~/components/common/molecules/ProfileInfo'
import UserAvatar from '~/components/common/molecules/UserAvatar'
import ProfileInfoUser from '~/components/common/molecules/users/ProfileInfoUser'
import ProfileHeader from '~/components/common/organisms/ProfileHeader'
import useDevice from '~/hooks/commons/useDevice'
import Media from '~/interfaces/Media'
import User from '~/interfaces/User'
import i18n from '~/plugins/i18n'
import { currentUserSelector, updateCurrentUser } from '~/slices/common/users'
import { setPageTitle } from '~/utils/navigation'

type Props = {
  user?: User
}

const STATUS_MAX_LENGTH = 220

const HeaderUser: React.FC<Props> = ({ user }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const currentUser = useSelector(currentUserSelector)

  const updateAvatar = (media: Media[]): void => {
    dispatch(updateCurrentUser({ userProfile: { imageId: media[0].id } }))
  }

  const updateBanner = (media: Media[]): void => {
    dispatch(
      updateCurrentUser({ userProfile: { banner: media[0]?.signedId || null } })
    )
  }

  const updateUserProfileStatus = (status: string) =>
    new Promise<void>((resolve, reject) => {
      Promise.all([dispatch(updateCurrentUser({ userProfile: { status } }))])
        .then(() => resolve())
        .catch((error) => reject(error))
    })

  const isMe = currentUser?.id === user?.id

  useFocusEffect(() =>
    setPageTitle(
      i18n.language === 'ja'
        ? user?.profile?.name
        : user?.profile?.enName ?? user?.profile?.name
    )
  )

  const infoComponent = (
    <>
      <ProfileInfo
        isPC={isPC}
        avatarComponent={
          <UserAvatar
            user={user}
            viewable={true}
            editable={isMe}
            showBorder={true}
            onUpload={updateAvatar}
            size={isPC ? ProfileAvatarSize.GIGANTIC : ProfileAvatarSize.HUGE}
          />
        }
        rightComponent={
          <View style={styles.rightComponentContainer}>
            <ProfileInfoUser
              user={user}
              followable={false}
              userHeaderStatus={
                <EditableTextForm
                  value={user?.profile.status}
                  editable={isMe}
                  onSubmit={updateUserProfileStatus}
                  maxLength={STATUS_MAX_LENGTH}
                  hintText={isMe ? t('banner.noneStatus') : ''}
                />
              }
            />
          </View>
        }
      />
    </>
  )

  return (
    <View testID="header-user" style={isPC ? styles.container : undefined}>
      <ProfileHeader
        isPC={isPC}
        bannerComponent={
          <ProfileUserBanner
            user={user!}
            isMe={isMe}
            onUpdateBanner={updateBanner}
          />
        }
        infoComponent={infoComponent}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 10,
    width: 814,
  },
  rightComponentContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
})

export default HeaderUser
